import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification, validateAuthInResponse } from './../../utils';
import axios from 'axios';

export const fetchElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let fields = [
        'id',
        'name',
        'colors',
        'perfects',
        'setup',
        'speed',
        'max_speed',
        'labor_rate',
        'labor_markup',
        'plate_cost',
        'burn_time',
        'preflight_time',
        'pms_time',
        'setup_sheets',
        'active'
    ];

    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',');
    }

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.search + '?fields=' + fields.join(',') + '&selectedPage=' + selectedPage
        + '&sort=' + column + '&filter=' + filter + '&summary=1',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENTS_FAILURE));
        });
}

export const saveElement = (values, elements) => (dispatch, getState) => {
    let errors = {};
    let errorMessages = {};
    let attributes = [
        'name',
        'colors',
        'perfects',
        'setup',
        'speed',
        'max_speed',
        'labor_rate',
        'labor_markup',
        'plate_cost',
        'burn_time',
        'preflight_time',
        'pms_time',
        'setup_sheets',
        'active'
    ];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_ELEMENT_FAILURE));
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        const selectedPage = selectors.getSelectedPage(getState());
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());
        const filterFields = selectors.getFilterFields(getState());

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
                dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            })
            .catch(error => {
                dispatch(validateAuthInResponse(error, actionTypes.SAVE_ELEMENT_FAILURE));
            });
    };
}

export const deleteElement = (id, elements) => (dispatch, getState) => {
    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.DELETE_ELEMENT_FAILURE));
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(element) {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function setSpeedTable(speedTable) {
    return {
        type: actionTypes.SET_SPEED_TABLE,
        payload: speedTable
    };
}

export function setDifficultyTable(table) {
    return {
        type: actionTypes.SET_DIFFICULTY_TABLE,
        payload: table
    };
}

export const saveSpeedTable = (speedTable, elements, envelopePressId) => dispatch => {
    for (let i = 0; i < speedTable.length; i++) {
        if (speedTable[i]['start_quantity'] === '' || speedTable[i]['end_quantity'] === '' || speedTable[i]['speed'] === '') {
            showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_SPEED_TABLE, payload: null });
    axios.post(apiUrls.saveSpeedTable, { envelopePressId, speedTable },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_SPEED_TABLE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('speed-table'));
            showNotification('Speed Table Saved!', 'The speed table has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_SPEED_TABLE_FAILURE));
        });
}

export const saveDifficultyTable = (difficultyTable, elements, envelopePressId) => dispatch => {
    for (let i = 0; i < difficultyTable.length; i++) {
        if (difficultyTable[i]['envelope_press_id'] === '' || difficultyTable[i]['envelope_type_id'] === '' || difficultyTable[i]['markup'] === '') {
            showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_DIFFICULTY_TABLE, payload: null });
    axios.post(apiUrls.saveDifficultyTable, { envelopePressId, difficultyTable },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_DIFFICULTY_TABLE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('difficulty-table'));
            showNotification('Difficulty Table Saved!', 'The difficulty table has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_DIFFICULTY_TABLE_FAILURE));
        });
}

export const fetchInformation = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_INFORMATION_FAILURE));
        });
}

export function addActiveData(data, dataName) {
    return {
        type: actionTypes.ADD_ACTIVE_DATA,
        payload: { name: dataName, value: data }
    };
}

export function deleteActiveData(index, dataName) {
    return {
        type: actionTypes.DELETE_ACTIVE_DATA,
        payload: { name: dataName, value: index }
    };
}

export function concatActiveData(name, data) {
    return {
        type: actionTypes.CONCAT_ACTIVE_DATA,
        payload: { name, data }
    };
}

export function addMultipleValue(name, value) {
    return {
        type: actionTypes.ADD_MULTIPLE_VALUE,
        payload: { name, value }
    };
}

export function clearMultipleValue(name) {
    return {
        type: actionTypes.CLEAR_MULTIPLE_VALUE,
        payload: name
    };
}

export function concatMultipleValue(name, data) {
    return {
        type: actionTypes.CONCAT_MULTIPLE_VALUE,
        payload: { name, data }
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function setOptions(name, data) {
    return {
        type: actionTypes.SET_OPTIONS,
        payload: { name, data }
    };
}

export function loadOptions(data) {
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({ label: data[i]['name'], value: data[i]['id'] });
    }
    return options;
}

export function setLoading(name, value) {
    return {
        type: actionTypes.SET_LOADING,
        payload: { name, value }
    };
}

export function resetOptions(name) {
    return {
        type: actionTypes.RESET_OPTIONS,
        payload: name
    };
}

export function updateState(values) {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: values
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export const fetchElement = id => dispatch => {
    let fields = [
        'id',
        'name',
        'colors',
        'perfects',
        'setup',
        'speed',
        'max_speed',
        'labor_rate',
        'labor_markup',
        'plate_cost',
        'burn_time',
        'preflight_time',
        'pms_time',
        'setup_sheets',
        'active',
        'speeds',
        'difficultyTable',
        'overageTable',
    ];

    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id + '?fields=' + fields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement(response.data));
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENT_FAILURE));
        });
}

export function setActivePage(page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: page
    };
}

export function setOverageTable(table) {
    return {
        type: actionTypes.SET_OVERAGE_TABLE,
        payload: table
    };
}

export const saveOverageTable = (overageTable, elements, envelopePressId) => dispatch => {
    for (let i = 0; i < overageTable.length; i++) {
        if (overageTable[i]['start_quantity'] === '' || overageTable[i]['finish_quantity'] === '' || overageTable[i]['overage'] === '') {
            showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_OVERAGE_TABLE, payload: null });
    axios.post(apiUrls.saveOverageTable, { envelopePressId, overageTable },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_OVERAGE_TABLE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('overage-table'));
            showNotification('Overage Table Saved!', 'The overage table has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_OVERAGE_TABLE_FAILURE));
        });
}
