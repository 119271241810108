import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            colors: '',
            perfects: '',
            setup: '',
            speed: '',
            max_speed: '',
            labor_rate: '',
            labor_markup: '',
            plate_cost: '',
            burn_time: '',
            preflight_time: '',
            pms_time: '',
            setup_sheets: '',
            active: 1
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        speedTable: [],
        isSavingSpeedTable: false,
        difficultyTable: [],
        isSavingDifficultyTable: false,
        overageTable: [],
        isSavingOverageTable: false,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'asc'
    },
    information: {
        envelopeTypes: [],
        isFetching: false
    },
    options: {},
    fetching: {},
    filter: {
        fields: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/envelope-presses') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                colors: '',
                perfects: '',
                setup: '',
                speed: '',
                max_speed: '',
                labor_rate: '',
                labor_markup: '',
                plate_cost: '',
                burn_time: '',
                preflight_time: '',
                pms_time: '',
                setup_sheets: '',
                active: 1
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['activeElement', 'speedTable'], action.payload.speeds)
                .setIn(['activeElement', 'difficultyTable'], action.payload.difficultyTable)
                .setIn(['activeElement', 'overageTable'], action.payload.overageTable)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'colors'], action.payload.colors)
                .setIn(['form', 'formValues', 'perfects'], action.payload.perfects)
                .setIn(['form', 'formValues', 'setup'], action.payload.setup)
                .setIn(['form', 'formValues', 'speed'], action.payload.speed)
                .setIn(['form', 'formValues', 'max_speed'], action.payload.max_speed)
                .setIn(['form', 'formValues', 'labor_rate'], action.payload.labor_rate)
                .setIn(['form', 'formValues', 'labor_markup'], action.payload.labor_markup)
                .setIn(['form', 'formValues', 'plate_cost'], action.payload.plate_cost)
                .setIn(['form', 'formValues', 'burn_time'], action.payload.burn_time)
                .setIn(['form', 'formValues', 'preflight_time'], action.payload.preflight_time)
                .setIn(['form', 'formValues', 'pms_time'], action.payload.pms_time)
                .setIn(['form', 'formValues', 'setup_sheets'], action.payload.setup_sheets)
                .setIn(['form', 'formValues', 'active'], action.payload.active);
            break;

        case actionTypes.SET_SPEED_TABLE:
            state = state.setIn(['activeElement', 'speedTable'], action.payload);
            break;

        case actionTypes.SET_DIFFICULTY_TABLE:
            state = state.setIn(['activeElement', 'difficultyTable'], action.payload);
            break;

        case actionTypes.SAVE_SPEED_TABLE:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], true);
            break;

        case actionTypes.SAVE_SPEED_TABLE_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], false);
            break;

        case actionTypes.SAVE_SPEED_TABLE_FAILURE:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], false);
            break;

        case actionTypes.SAVE_DIFFICULTY_TABLE:
            state = state.setIn(['activeElement', 'isSavingDifficultyTable'], true);
            break;

        case actionTypes.SAVE_DIFFICULTY_TABLE_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingDifficultyTable'], false);
            break;

        case actionTypes.SAVE_DIFFICULTY_TABLE_FAILURE:
            state = state.setIn(['activeElement', 'isSavingDifficultyTable'], false);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'envelopeTypes'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'envelopeTypes'], action.payload.envelopeTypes);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.ADD_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.DELETE_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.delete(action.payload.value));
            break;

        case actionTypes.CONCAT_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.ADD_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.CLEAR_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload], (data) => data.clear());
            break;

        case actionTypes.CONCAT_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.SET_OPTIONS:
            state = state.updateIn(['options', action.payload.name], (data) => Immutable.List(action.payload.data));
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.SET_LOADING:
            state = state.setIn(['fetching', action.payload.name], action.payload.value);
            break;

        case actionTypes.RESET_OPTIONS:
            state = state.setIn(['options', action.payload], []);
            break;

        case actionTypes.UPDATE_STATE:
            if (action.payload.formValues) {
                state = state.updateIn(['form', 'formValues'], (formValues) => {
                    for (let [element, value] of Object.entries(action.payload.formValues)) {
                        formValues = formValues.set(element, value);
                    }
                    return formValues;
                });
            }
            if (action.payload.options) {
                state = state.updateIn(['options'], (options) => {
                    for (let [element, value] of Object.entries(action.payload.options)) {
                        options = options.set(element, value);
                    }
                    return options;
                });
            }
            if (action.payload.fetching) {
                state = state.updateIn(['fetching'], (fetching) => {
                    for (let [element, value] of Object.entries(action.payload.fetching)) {
                        fetching = fetching.set(element, value);
                    }
                    return fetching;
                });
            }
            break;

        case actionTypes.FETCH_ELEMENT:
            state = state.setIn(['activeElement', 'isFetching'], true);
            break;

        case actionTypes.FETCH_ELEMENT_SUCCESS:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT_FAILURE:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.SET_OVERAGE_TABLE:
            state = state.setIn(['activeElement', 'overageTable'], action.payload);
            break;

        case actionTypes.SAVE_OVERAGE_TABLE:
            state = state.setIn(['activeElement', 'isSavingOverageTable'], true);
            break;

        case actionTypes.SAVE_OVERAGE_TABLE_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingOverageTable'], false);
            break;

        case actionTypes.SAVE_OVERAGE_TABLE_FAILURE:
            state = state.setIn(['activeElement', 'isSavingOverageTable'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
