// import React, { useEffect, useCallback } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import Table from './Table';
// import Form from './Form';
// import './Invoices.scss';
// import { stringifyEqualityCheck } from './../../utils';
// import * as selectors from './Invoices.selectors';
// import * as actions from './Invoices.actions';
// import Layout from './../Layout';
// import { Helmet } from 'react-helmet';
// import Tabs from './../common/components/Tabs';
// import AdminHeader from './../common/components/AdminHeader';
// import * as loginSelectors from './../Login/Login.selectors';
// import DeleteModal from './DeleteModal';
// import * as dialog from '../common/dialog';

const Invoices = (props) => {
    /* State to props */
    // const invoices = useSelector(selectors.getInvoices, stringifyEqualityCheck);
    // const fetching = useSelector(selectors.getFetching);
    // const totalItems = useSelector(selectors.getTotalItems);
    // const totalPages = useSelector(selectors.getTotalPages);
    // const selectedPage = useSelector(selectors.getSelectedPage);
    // const elementsPerPage = useSelector(selectors.getElementsPerPage);
    // const sortColumn = useSelector(selectors.getSortColumn);
    // const sortType = useSelector(selectors.getSortType);
    // const filterFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    // const editing = useSelector(selectors.getEditing);
    // const data = useSelector(selectors.getData, stringifyEqualityCheck);
    // const fectchingData = useSelector(selectors.getFectchingData);
    // const activeId = useSelector(selectors.getActiveId);
    // const saving = useSelector(selectors.getSaving);
    // const deleting = useSelector(selectors.getDeleting);
    // const pushing = useSelector(selectors.getPushing);
    // const converting = useSelector(selectors.getConverting);
    // const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    // const dispatch = useDispatch();
    // const fetchInvoices = useCallback((selectedPage, sortColumn, sortType, filterFields) => dispatch(actions.fetchInvoices(selectedPage, sortColumn, sortType, filterFields)), [dispatch]);
    // const fetchData = useCallback(() => dispatch(actions.fetchData()), [dispatch]);
    // const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    // const changeFilter = useCallback((name, value) => dispatch(actions.changeFilter(name, value)), [dispatch]);
    // const setActivePage = useCallback((page) => dispatch(actions.setActivePage(page)), [dispatch]);
    // const setEditing = useCallback((value) => dispatch(actions.setEditing(value)), [dispatch]);
    // const setActiveId = useCallback((value) => dispatch(actions.setActiveId(value)), [dispatch]);
    // const saveInvoice = useCallback((id, values, fetchInvoiceData) => dispatch(actions.saveInvoice(id, values, fetchInvoiceData)), [dispatch]);
    // const validateForm = useCallback((values) => dispatch(actions.validateForm(values)), [dispatch]);
    // const pushInvoiceToQB = useCallback((id, fetchInvoiceData) => dispatch(actions.pushInvoiceToQB(id, fetchInvoiceData)), [dispatch]);
    // const deleteInvoice = useCallback((id) => dispatch(actions.deleteInvoice(id)), [dispatch]);
    // const convertToJob = useCallback((values) => dispatch(actions.convertToJob(values)), [dispatch]);
    // const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    // const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);

    return null;

    /* componentDidMount */
    // useEffect(() => {
    //     fetchData();
    // }, [fetchData]);

    // useEffect(() => {
    //     fetchInvoices(selectedPage, sortColumn, sortType, filterFields);
    // }, [fetchInvoices, selectedPage, sortColumn, sortType, filterFields]);

    // const handleNew = (value) => {
    //     if (!value) {
    //         fetchInvoices(selectedPage, sortColumn, sortType, filterFields);
    //     }
    //     setEditing(value)
    //     setActiveId(null);
    // };

    // let content = null;
    // let button = null;
    // if (editing) {
    //     button = <div className="upload-button" onClick={handleNew.bind(this, false)}><i className="fas fa-arrow-left" /> Return</div>;
    //     content = (
    //         <Form
    //             data={data}
    //             fectchingData={fectchingData}
    //             activeId={activeId}
    //             saving={saving}
    //             pushing={pushing}
    //             converting={converting}
    //             saveInvoice={saveInvoice}
    //             validateForm={validateForm}
    //             pushInvoiceToQB={pushInvoiceToQB}
    //             convertToJob={convertToJob}
    //         />
    //     );
    // } else {
    //     button = <div className="upload-button" onClick={handleNew.bind(this, true)}><i className="fas fa-plus" /> New Invoice</div>;
    //     content = (
    //         <Table
    //             history={props.history}
    //             invoices={invoices}
    //             fetching={fetching}
    //             totalItems={totalItems}
    //             totalPages={totalPages}
    //             selectedPage={selectedPage}
    //             elementsPerPage={elementsPerPage}
    //             filterFields={filterFields}
    //             fetchInvoices={fetchInvoices}
    //             setSort={setSort}
    //             changeFilter={changeFilter}
    //             setActivePage={setActivePage}
    //             setEditing={setEditing}
    //             setActiveId={setActiveId}
    //             showDeleteModal={showDeleteModal}
    //         />
    //     );
    // }

    // return (
    //     <Layout>
    //         <div id="admin-dashboard-container" className="container-fluid invoices-container">
    //             <Helmet>
    //                 <title>Invoices</title>
    //             </Helmet>
    //             <AdminHeader
    //                 title="Invoices"
    //             />
    //             <div className="row">
    //                 <div className="col">
    //                     <Tabs
    //                         user={user}
    //                     />
    //                     {button}
    //                     {content}
    //                 </div>
    //             </div>
    //         </div>
    //         <DeleteModal
    //             activeId={activeId}
    //             deleting={deleting}
    //             deleteInvoice={deleteInvoice}
    //             hideDeleteModal={hideDeleteModal}
    //             setActiveId={setActiveId}
    //         />
    //     </Layout>
    // );
};

Invoices.propTypes = {

};

export default Invoices;
