import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getComponentState = state => state[NAME];

/** form **/
export const getForm = createSelector(
    getComponentState,
    (state) => state.form
);
export const getFormValues = createSelector(
    getComponentState,
    (state) => state.form.formValues
);
