import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from './../../common/components/Select';
import { Dialog } from './../../common/dialog';
import moment from 'moment';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        saveElement: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        resetForm: PropTypes.func,
        handleUpdateFormDateValue: PropTypes.func
    };

    renderInput(name, type, required, readOnly = false) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <Input
                type="text"
                name={name}
                required={required}
                formValues={formValues}
                formErrors={formErrors}
                formErrorMessages={formErrorMessages}
                readOnly={readOnly}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
            />
        );
    }

    renderCheckbox(name, type, required) {
        const { formValues, handleUpdateFormValue } = this.props;
        return (
            <input type="checkbox" checked={formValues[name]} id={name} name={name}
                onClick={handleUpdateFormValue.bind(type, required, this)} />
        );
    }

    renderTextarea(name, type, required, readOnly = false) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <TextArea
                name={name}
                required={required}
                formValues={formValues}
                formErrors={formErrors}
                formErrorMessages={formErrorMessages}
                readOnly={readOnly}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
            />
        );
    }

    renderSelect(name, options, required) {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <Select
                name={name}
                required={required}
                options={options}
                formValues={formValues}
                formErrors={formErrors}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
            />
        );
    }

    render() {
        let saveButtonLabel = 'Save';
        if (this.props.isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        const checkedHugh = this.props.formValues['present'].includes('Hugh');
        const checkedNorm = this.props.formValues['present'].includes('Norm');
        const checkedChristy = this.props.formValues['present'].includes('Christy');
        const checkedGeno = this.props.formValues['present'].includes('Geno');

        const checkedEmail = this.props.formValues['method'] === 'Email';
        const checkedMeeting = this.props.formValues['method'] === 'Meeting';
        const checkedConversation = this.props.formValues['method'] === 'Conversation';
        const checkedOther = this.props.formValues['method'] !== '' && this.props.formValues['method'] !== 'Email'
            && this.props.formValues['method'] !== 'Meeting' && this.props.formValues['method'] !== 'Conversation';

        let otherValue = '';
        if (checkedOther) {
            otherValue = this.props.formValues['method'] === '@' ? '' : this.props.formValues['method'];
        }

        let form = (
            <form className="timeline-form">
                <div className="row">
                    <div className="col">
                        <div className="subtitle mt-0 ml-0">New Timeline Log</div>
                        <div className="w-100"></div>
                        <div className="column1">
                            <div className="element">
                                <div className="float-left">Date:</div>
                                <div style={{ float: 'left', width: 100, marginLeft: 28 }}>
                                    <DayPickerInput
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder=""
                                        className="date-picker-field"
                                        onDayChange={this.props.handleUpdateFormDateValue.bind(this, 'date', true)}
                                        value={this.props.formValues['date'] === '0000-00-00' ? 'N/A' : moment(this.props.formValues['date']).format('MM/DD/YYYY')}
                                    />
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="element mt-2">
                                <div className="float-left" style={{ width: 40 }}>
                                    <div>To-do:</div>
                                    <div>{this.renderCheckbox('todo', 'checkbox', true)}</div>
                                </div>
                                <div className="float-left" style={{ width: 162 }}>
                                    <div className="float-left" style={{ marginLeft: 25 }}>Completed By:</div>
                                    <div style={{ float: 'left', width: 100, marginLeft: 20 }}>
                                        <DayPickerInput
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            placeholder=""
                                            className="date-picker-field"
                                            onDayChange={this.props.handleUpdateFormDateValue.bind(this, 'completed_by', true)}
                                            value={this.props.formValues['completed_by'] === '0000-00-00' ? 'N/A' : moment(this.props.formValues['completed_by']).format('MM/DD/YYYY')}
                                        />
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="completed-field">
                                    {this.renderCheckbox('completed', 'checkbox', true)} Completed
                                </div>
                            </div>
                        </div>
                        <div className="column2">
                            <div className="label">Topic:</div>
                            <div className="form-input">{this.renderInput('topic', 'text', false)}</div>
                            <div className="clearfix"></div>
                            <div className="label mt-2">Method:</div>
                            <div className="form-input mt-2" style={{ width: 460 }}>
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedEmail} onClick={(event) => this.props.checkMethod('Email', event.target.checked)} /> Email
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedMeeting} onClick={(event) => this.props.checkMethod('Meeting', event.target.checked)} /> Meeting
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedConversation} onClick={(event) => this.props.checkMethod('Conversation', event.target.checked)} /> Conversation
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedOther} onClick={(event) => this.props.checkMethod('@', event.target.checked)} />
                                <input type="text" placeholder="Other" className="other-check-field" value={otherValue} onChange={(event) => this.props.setOtherMethod(event.target.value)} />
                            </div>
                            <div className="clearfix"></div>
                            <div className="label mt-2">Present:</div>
                            <div className="form-input mt-2" style={{ width: 460 }}>
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedHugh} onClick={(event) => this.props.checkPresent('Hugh', event.target.checked)} /> Hugh
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedNorm} onClick={(event) => this.props.checkPresent('Norm', event.target.checked)} /> Norm
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedChristy} onClick={(event) => this.props.checkPresent('Christy', event.target.checked)} /> Christy
                                <input type="checkbox" style={{ marginLeft: 20 }} checked={checkedGeno} onClick={(event) => this.props.checkPresent('Geno', event.target.checked)} /> Geno
                            </div>
                            <div className="clearfix"></div>
                            <div className="label mt-3">Staff Present:</div>
                            <div className="form-input mt-3" style={{ width: 421 }}>
                                {this.renderInput('staff', 'text', false)}
                            </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="clearfix"></div>
                        <div className="notes-container">
                            Notes:
                            {this.renderTextarea('notes', 'text', true)}
                        </div>
                    </div>
                </div>
            </form>
        );
        if (this.props.isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let sendButtonLabel = 'Send Email';
        if (this.props.isSendingEmail) {
            sendButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={this.props.saveElement} disabled={this.props.isSavingForm}>{saveButtonLabel}</button>,
            this.props.formValues['id'] !== '' && <button key="send-email-button" className="btn btn-light btn-sm action-button" onClick={this.props.sendEmail} disabled={this.props.isSendingEmail}>{sendButtonLabel}</button>,
            this.props.formValues['id'] !== '' && <button key="print-button" className="btn btn-light btn-sm action-button" onClick={this.props.printPdf}>Print</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={this.props.hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800, maxWidth: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
