import Immutable from 'immutable';
import { SHOW_DIALOG, HIDE_DIALOG } from '../actionTypes';

const INITIAL_STATE = {

};

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case SHOW_DIALOG:
            state = state.setIn([action.payload, 'open'], true);
            break;

        case HIDE_DIALOG:
            state = state.setIn([action.payload, 'open'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
