import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class ActiveLaminates extends Component {
    static propTypes = {
        activeLaminates: PropTypes.array,
        hideActiveLaminates: PropTypes.func,
        isSavingActiveLaminates: PropTypes.bool,
        handleSaveActiveLaminates: PropTypes.func,
        handleAddActiveLaminate: PropTypes.func,
        handleDeleteActiveLaminate: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        laminates: PropTypes.array,
        formValues: PropTypes.object,
        handleMultipleChange: PropTypes.func
    };

    renderActiveLaminates() {
        const { activeLaminates, handleDeleteActiveLaminate } = this.props;

        let laminates = [];
        for (let i = 0; i < activeLaminates.length; i++) {
            laminates.push({ id: activeLaminates[i]['id'], name: activeLaminates[i]['name'] });
        }

        laminates.sort(function (a, b) {
            return a['name'] < b['name'] ? -1 : 1;
        });

        return laminates.map((laminate, index) => {
            return (
                <div key={index} className="float-left mr-2 mt-1">
                    <span className="badge badge-info bold">
                        {laminate['name']} <i className="fas fa-times" onClick={handleDeleteActiveLaminate.bind(this, laminate['id'])}></i>
                    </span>
                </div>
            );
        });
    }

    isLaminateActive(laminateId) {
        const { activeLaminates } = this.props;

        for (let i = 0; i < activeLaminates.length; i++) {
            if (activeLaminates[i]['id'] === laminateId) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { hideActiveLaminates, handleSaveActiveLaminates, isSavingActiveLaminates, handleAddActiveLaminate,
            isFetchingInformation, laminates, formValues, handleMultipleChange, isFetchingElement } = this.props;

        let laminateOptions = [];
        for (let i = 0; i < laminates.length; i++) {
            if (!this.isLaminateActive(laminates[i]['id'])) {
                laminateOptions.push(<option value={laminates[i]['id']}>{laminates[i]['name']}</option>);
            }
        }

        let data = (
            <div className="row">
                <div className="col-5">
                    Laminates:
                    <select name="active_laminate_id" multiple size={5} value={formValues['active_laminate_ids']}
                        onChange={handleMultipleChange.bind(this, 'active_laminate_ids')}>
                        {laminateOptions}
                    </select>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="add-button mt-2" onClick={handleAddActiveLaminate} style={{ width: 100, fontSize: 13 }}><i className="fas fa-plus" /> Add</div>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="active-presses">
                        {this.renderActiveLaminates()}
                    </div>
                </div>
            </div>
        );
        if (isFetchingElement) {
            data = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        if (isFetchingInformation) {
            data = null;
        }

        let saveButtonLabel = 'Save';
        if (isSavingActiveLaminates) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveActiveLaminates} disabled={isSavingActiveLaminates}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideActiveLaminates}>Close</button>
        ];
        return (
            <Dialog
                name="active-laminates"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default ActiveLaminates;
