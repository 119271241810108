import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            notes: '',
            estimate_id: '',
            estimate_type: 1,
            estimate_product_category_id: '',
            estimate_bleed: 0.125,
            estimate_multi_page_type: 1,
            estimate_product_description: '',
            estimate_front_inks: 4,
            estimate_front_coating: '',
            estimate_back_inks: 4,
            estimate_back_coating: '',
            estimate_interior_inks: 4,
            estimate_interior_coating: '',
            estimate_digital_inks: 2,
            estimate_digital_sides: 2,
            estimate_digital_machine_id: '',
            estimate_binding_edge: 1,
            estimate_press_id: '',
            estimate_second_press_id: '',
            estimate_cover_press_id: '',
            estimate_pages: '',
            estimate_paper_category_id: '',
            estimate_paper_weight: '',
            estimate_paper_id: '',
            estimate_cover_paper_category_id: '',
            estimate_cover_paper_weight: '',
            estimate_cover_paper_id: '',
            estimate_width: '',
            estimate_height: '',
            estimate_finish_width: '',
            estimate_finish_height: '',
            estimate_running_style: '',
            estimate_second_running_style: '',
            estimate_cover_running_style: '',
            estimate_large_format_machine_id: '',
            estimate_substrate_id: '',
            estimate_laminate_id: '',
            estimate_unit: 2,
            estimate_fold_type_id: '',
            estimate_product_id: '',
            estimate_accessories: [],
            estimate_outside_services: [],
            estimate_requires_scoring: 0,
            estimate_requires_die_cutting: 0,
            estimate_drilling_holes: 0,
            estimate_requires_padding: 0,
            estimate_sheets_to_pad: 10,
            estimate_banding_type_id: '',
            estimate_wrapping_type_id: '',
            estimate_shrink_wrapping_type_id: '',
            estimate_package_id: '',
            estimate_use_hand_gathering: 0,
            estimate_use_counting: 0,
            estimate_coil_option: '',
            estimate_coil_color: '',
            estimate_staples_number: '',
            estimate_staples_location: '',
            estimate_quantity_1: 500,
            estimate_quantity_2: 1000,
            estimate_quantity_3: 2000,
            estimate_press_price_1: 0,  // TODO: Optimize
            estimate_press_price_2: 0,
            estimate_press_price_3: 0,
            estimate_paper_price_1: 0,
            estimate_paper_price_2: 0,
            estimate_paper_price_3: 0,
            estimate_ink_price_1: 0,
            estimate_ink_price_2: 0,
            estimate_ink_price_3: 0,
            estimate_cutting_price_1: 0,
            estimate_cutting_price_2: 0,
            estimate_cutting_price_3: 0,
            estimate_binding_price_1: 0,
            estimate_binding_price_2: 0,
            estimate_binding_price_3: 0,
            estimate_folding_price_1: 0,
            estimate_folding_price_2: 0,
            estimate_folding_price_3: 0,
            estimate_digital_machine_price_1: 0,
            estimate_digital_machine_price_2: 0,
            estimate_digital_machine_price_3: 0,
            estimate_large_format_machine_price_1: 0,
            estimate_large_format_machine_price_2: 0,
            estimate_large_format_machine_price_3: 0,
            estimate_substrate_price_1: 0,
            estimate_substrate_price_2: 0,
            estimate_substrate_price_3: 0,
            estimate_laminate_price_1: 0,
            estimate_laminate_price_2: 0,
            estimate_laminate_price_3: 0,
            estimate_accessories_price_1: 0,
            estimate_accessories_price_2: 0,
            estimate_accessories_price_3: 0,
            estimate_outside_services_price_1: 0,
            estimate_outside_services_price_2: 0,
            estimate_outside_services_price_3: 0,
            estimate_scoring_price_1: 0,
            estimate_scoring_price_2: 0,
            estimate_scoring_price_3: 0,
            estimate_die_cutting_price_1: 0,
            estimate_die_cutting_price_2: 0,
            estimate_die_cutting_price_3: 0,
            estimate_drilling_price_1: 0,
            estimate_drilling_price_2: 0,
            estimate_drilling_price_3: 0,
            estimate_padding_price_1: 0,
            estimate_padding_price_2: 0,
            estimate_padding_price_3: 0,
            estimate_banding_price_1: 0,
            estimate_banding_price_2: 0,
            estimate_banding_price_3: 0,
            estimate_wrapping_price_1: 0,
            estimate_wrapping_price_2: 0,
            estimate_wrapping_price_3: 0,
            estimate_shrink_wrapping_price_1: 0,
            estimate_shrink_wrapping_price_2: 0,
            estimate_shrink_wrapping_price_3: 0,
            estimate_package_price_1: 0,
            estimate_package_price_2: 0,
            estimate_package_price_3: 0,
            estimate_hand_gathering_price_1: 0,
            estimate_hand_gathering_price_2: 0,
            estimate_hand_gathering_price_3: 0,
            estimate_counting_price_1: 0,
            estimate_counting_price_2: 0,
            estimate_counting_price_3: 0,
            estimate_notes: '',
            estimate_customer_discount_id: '',
            estimate_discount: 0,
            estimate_discount_percentage: 0
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        product: null,
        isFetching: false,
        configuration: null
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'id',
        type: 'desc'
    },
    customer: {
        id: null,
        editing: false
    },
    quote: {
        id: null,
        data: null,
        isFetching: false,
        isSending: false
    },
    information: {
        presses: [],
        estimates: [],
        customers: [],
        customerDiscounts: [],
        industries: [],
        products: [],
        largeFormatMachines: [],
        substrates: [],
        laminates: [],
        accessories: [],
        inks: [],
        isFetching: false,
        isFetchingEstimate: false
    },
    estimate: {
        isLoading: false,
        results: [],
        isSaving: false,
        isDeleting: false
    },
    order: {
        isSaving: false
    },
    options: {
        presses: [{ label: 'Select option', value: '' }],
        paperCategories: [{ label: 'Select option', value: '' }],
        paperWeights: [{ label: 'Select option', value: '' }],
        papers: [{ label: 'Select option', value: '' }],
        coverPaperCategories: [{ label: 'Select option', value: '' }],
        coverPaperWeights: [{ label: 'Select option', value: '' }],
        coverPapers: [{ label: 'Select option', value: '' }],
        foldTypes: [{ label: 'Select option', value: '' }],
        digitalMachines: [{ label: 'Select option', value: '' }],
        largeFormatMachines: [{ label: 'Select option', value: '' }],
        substrates: [{ label: 'Select option', value: '' }],
        laminates: [{ label: 'Select option', value: '' }],
        accessories: [{ label: 'Select option', value: '' }],
        inks: [{ label: 'Select option', value: '' }],
        bandingTypes: [{ label: 'Select option', value: '' }],
        wrappingTypes: [{ label: 'Select option', value: '' }],
        shrinkWrappingTypes: [{ label: 'Select option', value: '' }],
        packages: [{ label: 'Select option', value: '' }],
        productCategories: [{ label: 'Select option', value: '' }],
        customerDiscounts: [{ label: 'None', value: '' }]
    },
    fetching: {
        presses: false,
        paperCategories: false,
        paperWeights: false,
        papers: false,
        digitalMachines: false,
        largeFormatMachines: false,
        substrates: false,
        laminates: false,
        accessories: false,
        estimate: false
    },
    productWindow: {
        id: null,
        name: null,
        type: null
    },
    productCustomer: {
        id: null,
        name: ''
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (!window.location['pathname'].includes('/product-quote')) {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (value) => action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                notes: '',
                private_notes: '',
                customer_id: '',
                status_id: 1,
                turnaround_id: 1,
                customer_po: '',
                sales_rep_id: '',
                customer_service_rep_id: '',
                discount: 0,
                estimate_id: '',
                estimate_type: 1,
                estimate_product_category_id: '',
                estimate_bleed: 0.125,
                estimate_multi_page_type: 1,
                estimate_product_description: '',
                estimate_front_inks: 4,
                estimate_front_coating: '',
                estimate_back_inks: 4,
                estimate_back_coating: '',
                estimate_interior_inks: 4,
                estimate_interior_coating: '',
                estimate_digital_inks: 2,
                estimate_digital_sides: 2,
                estimate_digital_machine_id: '',
                estimate_binding_edge: 1,
                estimate_press_id: '',
                estimate_second_press_id: '',
                estimate_cover_press_id: '',
                estimate_pages: '',
                estimate_paper_category_id: '',
                estimate_paper_weight: '',
                estimate_paper_id: '',
                estimate_cover_paper_category_id: '',
                estimate_cover_paper_weight: '',
                estimate_cover_paper_id: '',
                estimate_width: '',
                estimate_height: '',
                estimate_finish_width: '',
                estimate_finish_height: '',
                estimate_running_style: '',
                estimate_second_running_style: '',
                estimate_cover_running_style: '',
                estimate_large_format_machine_id: '',
                estimate_substrate_id: '',
                estimate_laminate_id: '',
                estimate_unit: 2,
                estimate_fold_type_id: '',
                estimate_product_id: '',
                estimate_accessories: [],
                estimate_outside_services: [],
                estimate_requires_scoring: 0,
                estimate_requires_die_cutting: 0,
                estimate_drilling_holes: 0,
                estimate_requires_padding: 0,
                estimate_banding_type_id: '',
                estimate_wrapping_type_id: '',
                estimate_shrink_wrapping_type_id: '',
                estimate_package_id: '',
                estimate_use_hand_gathering: 0,
                estimate_use_counting: 0,
                estimate_coil_option: '',
                estimate_coil_color: '',
                estimate_staples_number: '',
                estimate_staples_location: '',
                estimate_quantity_1: 500,
                estimate_quantity_2: 1000,
                estimate_quantity_3: 2000,
                estimate_press_price_1: 0,  // TODO: Optimize
                estimate_press_price_2: 0,
                estimate_press_price_3: 0,
                estimate_paper_price_1: 0,
                estimate_paper_price_2: 0,
                estimate_paper_price_3: 0,
                estimate_ink_price_1: 0,
                estimate_ink_price_2: 0,
                estimate_ink_price_3: 0,
                estimate_cutting_price_1: 0,
                estimate_cutting_price_2: 0,
                estimate_cutting_price_3: 0,
                estimate_binding_price_1: 0,
                estimate_binding_price_2: 0,
                estimate_binding_price_3: 0,
                estimate_folding_price_1: 0,
                estimate_folding_price_2: 0,
                estimate_folding_price_3: 0,
                estimate_digital_machine_price_1: 0,
                estimate_digital_machine_price_2: 0,
                estimate_digital_machine_price_3: 0,
                estimate_large_format_machine_price_1: 0,
                estimate_large_format_machine_price_2: 0,
                estimate_large_format_machine_price_3: 0,
                estimate_substrate_price_1: 0,
                estimate_substrate_price_2: 0,
                estimate_substrate_price_3: 0,
                estimate_laminate_price_1: 0,
                estimate_laminate_price_2: 0,
                estimate_laminate_price_3: 0,
                estimate_accessories_price_1: 0,
                estimate_accessories_price_2: 0,
                estimate_accessories_price_3: 0,
                estimate_outside_services_price_1: 0,
                estimate_outside_services_price_2: 0,
                estimate_outside_services_price_3: 0,
                estimate_scoring_price_1: 0,
                estimate_scoring_price_2: 0,
                estimate_scoring_price_3: 0,
                estimate_die_cutting_price_1: 0,
                estimate_die_cutting_price_2: 0,
                estimate_die_cutting_price_3: 0,
                estimate_drilling_price_1: 0,
                estimate_drilling_price_2: 0,
                estimate_drilling_price_3: 0,
                estimate_padding_price_1: 0,
                estimate_padding_price_2: 0,
                estimate_padding_price_3: 0,
                estimate_banding_price_1: 0,
                estimate_banding_price_2: 0,
                estimate_banding_price_3: 0,
                estimate_wrapping_price_1: 0,
                estimate_wrapping_price_2: 0,
                estimate_wrapping_price_3: 0,
                estimate_shrink_wrapping_price_1: 0,
                estimate_shrink_wrapping_price_2: 0,
                estimate_shrink_wrapping_price_3: 0,
                estimate_package_price_1: 0,
                estimate_package_price_2: 0,
                estimate_package_price_3: 0,
                estimate_hand_gathering_price_1: 0,
                estimate_hand_gathering_price_2: 0,
                estimate_hand_gathering_price_3: 0,
                estimate_counting_price_1: 0,
                estimate_counting_price_2: 0,
                estimate_counting_price_3: 0,
                estimate_notes: '',
                estimate_customer_discount_id: '',
                estimate_discount: 0,
                estimate_discount_percentage: 0
            })
                .setIn(['estimate', 'results'], [])
                .setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'product'], null);
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'estimate_id'], action.payload.element.id)
                .setIn(['form', 'formValues', 'estimate_product_category_id'], action.payload.element.product_category_id)
                .setIn(['form', 'formValues', 'estimate_bleed'], action.payload.element.bleed)
                .setIn(['form', 'formValues', 'estimate_multi_page_type'], action.payload.element.multi_page_type)
                .setIn(['form', 'formValues', 'estimate_product_description'], action.payload.element.product_description)
                .setIn(['form', 'formValues', 'estimate_front_inks'], action.payload.element.front_inks)
                .setIn(['form', 'formValues', 'estimate_front_coating'], action.payload.element.front_coating)
                .setIn(['form', 'formValues', 'estimate_back_inks'], action.payload.element.back_inks)
                .setIn(['form', 'formValues', 'estimate_back_coating'], action.payload.element.back_coating)
                .setIn(['form', 'formValues', 'estimate_interior_inks'], action.payload.element.interior_inks)
                .setIn(['form', 'formValues', 'estimate_interior_coating'], action.payload.element.interior_coating)
                .setIn(['form', 'formValues', 'estimate_digital_inks'], action.payload.element.digital_inks)
                .setIn(['form', 'formValues', 'estimate_digital_sides'], action.payload.element.digital_sides)
                .setIn(['form', 'formValues', 'estimate_digital_machine_id'], action.payload.element.digital_machine_id)
                .setIn(['form', 'formValues', 'estimate_binding_edge'], action.payload.element.binding_edge)
                .setIn(['form', 'formValues', 'estimate_press_id'], action.payload.element.press_id)
                .setIn(['form', 'formValues', 'estimate_second_press_id'], action.payload.element.second_press_id)
                .setIn(['form', 'formValues', 'estimate_cover_press_id'], action.payload.element.cover_press_id)
                .setIn(['form', 'formValues', 'estimate_pages'], action.payload.element.pages)
                .setIn(['form', 'formValues', 'estimate_paper_category_id'], action.payload.element.paper_category_id)
                .setIn(['form', 'formValues', 'estimate_paper_weight'], action.payload.element.paper_weight)
                .setIn(['form', 'formValues', 'estimate_paper_id'], action.payload.element.paper_id)
                .setIn(['form', 'formValues', 'estimate_cover_paper_category_id'], action.payload.element.cover_paper_category_id)
                .setIn(['form', 'formValues', 'estimate_cover_paper_weight'], action.payload.element.cover_paper_weight)
                .setIn(['form', 'formValues', 'estimate_cover_paper_id'], action.payload.element.cover_paper_id)
                .setIn(['form', 'formValues', 'estimate_width'], action.payload.element.width)
                .setIn(['form', 'formValues', 'estimate_height'], action.payload.element.height)
                .setIn(['form', 'formValues', 'estimate_finish_width'], action.payload.element.finish_width)
                .setIn(['form', 'formValues', 'estimate_finish_height'], action.payload.element.finish_height)
                .setIn(['form', 'formValues', 'estimate_running_style'], action.payload.element.running_style)
                .setIn(['form', 'formValues', 'estimate_second_running_style'], action.payload.element.second_running_style)
                .setIn(['form', 'formValues', 'estimate_cover_running_style'], action.payload.element.cover_running_style)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_id'], action.payload.element.large_format_machine_id)
                .setIn(['form', 'formValues', 'estimate_substrate_id'], action.payload.element.substrate_id)
                .setIn(['form', 'formValues', 'estimate_laminate_id'], action.payload.element.laminate_id)
                .setIn(['form', 'formValues', 'estimate_unit'], action.payload.element.unit)
                .setIn(['form', 'formValues', 'estimate_fold_type_id'], action.payload.element.fold_type_id)
                .setIn(['form', 'formValues', 'estimate_product_id'], action.payload.element.product_id)
                .setIn(['form', 'formValues', 'estimate_accessories'], action.payload.element.accessories_ids === null ? [] : JSON.parse(action.payload.element.accessories_ids))
                .setIn(['form', 'formValues', 'estimate_outside_services'], action.payload.element.outside_services_ids === null ? [] : JSON.parse(action.payload.element.outside_services_ids))
                .setIn(['form', 'formValues', 'estimate_requires_scoring'], action.payload.element.requires_scoring)
                .setIn(['form', 'formValues', 'estimate_requires_die_cutting'], action.payload.element.requires_die_cutting)
                .setIn(['form', 'formValues', 'estimate_drilling_holes'], action.payload.element.drilling_holes)
                .setIn(['form', 'formValues', 'estimate_requires_padding'], action.payload.element.requires_padding)
                .setIn(['form', 'formValues', 'estimate_banding_type_id'], action.payload.element.banding_type_id)
                .setIn(['form', 'formValues', 'estimate_wrapping_type_id'], action.payload.element.wrapping_type_id)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_type_id'], action.payload.element.shrink_wrapping_type_id)
                .setIn(['form', 'formValues', 'estimate_package_id'], action.payload.element.package_id)
                .setIn(['form', 'formValues', 'estimate_use_hand_gathering'], action.payload.element.use_hand_gathering)
                .setIn(['form', 'formValues', 'estimate_use_counting'], action.payload.element.use_counting)
                .setIn(['form', 'formValues', 'estimate_coil_option'], action.payload.element.coil_option)
                .setIn(['form', 'formValues', 'estimate_coil_color'], action.payload.element.coil_color)
                .setIn(['form', 'formValues', 'estimate_staples_number'], action.payload.element.staples_number)
                .setIn(['form', 'formValues', 'estimate_staples_location'], action.payload.element.staples_location)
                .setIn(['form', 'formValues', 'estimate_quantity_1'], action.payload.quantities[0])
                .setIn(['form', 'formValues', 'estimate_quantity_2'], action.payload.quantities[1])
                .setIn(['form', 'formValues', 'estimate_quantity_3'], action.payload.quantities[2])
                .setIn(['form', 'formValues', 'estimate_press_price_1'], action.payload.pressPrices[0])
                .setIn(['form', 'formValues', 'estimate_press_price_2'], action.payload.pressPrices[1])
                .setIn(['form', 'formValues', 'estimate_press_price_3'], action.payload.pressPrices[2])
                .setIn(['form', 'formValues', 'estimate_paper_price_1'], action.payload.paperPrices[0])
                .setIn(['form', 'formValues', 'estimate_paper_price_2'], action.payload.paperPrices[1])
                .setIn(['form', 'formValues', 'estimate_paper_price_3'], action.payload.paperPrices[2])
                .setIn(['form', 'formValues', 'estimate_ink_price_1'], action.payload.inkPrices[0])
                .setIn(['form', 'formValues', 'estimate_ink_price_2'], action.payload.inkPrices[1])
                .setIn(['form', 'formValues', 'estimate_ink_price_3'], action.payload.inkPrices[2])
                .setIn(['form', 'formValues', 'estimate_cutting_price_1'], action.payload.cuttingPrices[0])
                .setIn(['form', 'formValues', 'estimate_cutting_price_2'], action.payload.cuttingPrices[1])
                .setIn(['form', 'formValues', 'estimate_cutting_price_3'], action.payload.cuttingPrices[2])
                .setIn(['form', 'formValues', 'estimate_binding_price_1'], action.payload.bindingPrices[0])
                .setIn(['form', 'formValues', 'estimate_binding_price_2'], action.payload.bindingPrices[1])
                .setIn(['form', 'formValues', 'estimate_binding_price_3'], action.payload.bindingPrices[2])
                .setIn(['form', 'formValues', 'estimate_folding_price_1'], action.payload.foldingPrices[0])
                .setIn(['form', 'formValues', 'estimate_folding_price_2'], action.payload.foldingPrices[1])
                .setIn(['form', 'formValues', 'estimate_folding_price_3'], action.payload.foldingPrices[2])
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_1'], action.payload.digitalMachinePrices[0])
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_2'], action.payload.digitalMachinePrices[1])
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_3'], action.payload.digitalMachinePrices[2])
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_1'], action.payload.largeFormatMachinePrices[0])
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_2'], action.payload.largeFormatMachinePrices[1])
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_3'], action.payload.largeFormatMachinePrices[2])
                .setIn(['form', 'formValues', 'estimate_substrate_price_1'], action.payload.substratePrices[0])
                .setIn(['form', 'formValues', 'estimate_substrate_price_2'], action.payload.substratePrices[1])
                .setIn(['form', 'formValues', 'estimate_substrate_price_3'], action.payload.substratePrices[2])
                .setIn(['form', 'formValues', 'estimate_laminate_price_1'], action.payload.laminatePrices[0])
                .setIn(['form', 'formValues', 'estimate_laminate_price_2'], action.payload.laminatePrices[1])
                .setIn(['form', 'formValues', 'estimate_laminate_price_3'], action.payload.laminatePrices[2])
                .setIn(['form', 'formValues', 'estimate_accessories_price_1'], action.payload.accessoriesPrices[0])
                .setIn(['form', 'formValues', 'estimate_accessories_price_2'], action.payload.accessoriesPrices[1])
                .setIn(['form', 'formValues', 'estimate_accessories_price_3'], action.payload.accessoriesPrices[2])
                .setIn(['form', 'formValues', 'estimate_scoring_price_1'], action.payload.scoringPrices[0])
                .setIn(['form', 'formValues', 'estimate_scoring_price_2'], action.payload.scoringPrices[1])
                .setIn(['form', 'formValues', 'estimate_scoring_price_3'], action.payload.scoringPrices[2])
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_1'], action.payload.dieCuttingPrices[0])
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_2'], action.payload.dieCuttingPrices[1])
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_3'], action.payload.dieCuttingPrices[2])
                .setIn(['form', 'formValues', 'estimate_drilling_price_1'], action.payload.drillingPrices[0])
                .setIn(['form', 'formValues', 'estimate_drilling_price_2'], action.payload.drillingPrices[1])
                .setIn(['form', 'formValues', 'estimate_drilling_price_3'], action.payload.drillingPrices[2])
                .setIn(['form', 'formValues', 'estimate_padding_price_1'], action.payload.paddingPrices[0])
                .setIn(['form', 'formValues', 'estimate_padding_price_2'], action.payload.paddingPrices[1])
                .setIn(['form', 'formValues', 'estimate_padding_price_3'], action.payload.paddingPrices[2])
                .setIn(['form', 'formValues', 'estimate_banding_price_1'], action.payload.bandingPrices[0])
                .setIn(['form', 'formValues', 'estimate_banding_price_2'], action.payload.bandingPrices[1])
                .setIn(['form', 'formValues', 'estimate_banding_price_3'], action.payload.bandingPrices[2])
                .setIn(['form', 'formValues', 'estimate_wrapping_price_1'], action.payload.wrappingPrices[0])
                .setIn(['form', 'formValues', 'estimate_wrapping_price_2'], action.payload.wrappingPrices[1])
                .setIn(['form', 'formValues', 'estimate_wrapping_price_3'], action.payload.wrappingPrices[2])
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_1'], action.payload.shrinkWrappingPrices[0])
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_2'], action.payload.shrinkWrappingPrices[1])
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_3'], action.payload.shrinkWrappingPrices[2])
                .setIn(['form', 'formValues', 'estimate_package_price_1'], action.payload.packagePrices[0])
                .setIn(['form', 'formValues', 'estimate_package_price_2'], action.payload.packagePrices[1])
                .setIn(['form', 'formValues', 'estimate_package_price_3'], action.payload.packagePrices[2])
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_1'], action.payload.handGatheringPrices[0])
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_2'], action.payload.handGatheringPrices[1])
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_3'], action.payload.handGatheringPrices[2])
                .setIn(['form', 'formValues', 'estimate_counting_price_1'], action.payload.countingPrices[0])
                .setIn(['form', 'formValues', 'estimate_counting_price_2'], action.payload.countingPrices[1])
                .setIn(['form', 'formValues', 'estimate_counting_price_3'], action.payload.countingPrices[2])
                .setIn(['form', 'formValues', 'estimate_notes'], action.payload.element.notes)
                .setIn(['form', 'formValues', 'estimate_customer_discount_id'], action.payload.element.customer_discount_id)
                .setIn(['form', 'formValues', 'estimate_discount'], action.payload.element.discount)
                .setIn(['form', 'formValues', 'estimate_discount_percentage'], action.payload.element.discount_percentage)
                .setIn(['estimate', 'results'], action.payload.estimate_results);
            break;

        case actionTypes.SET_CUSTOMER_ID:
            state = state.setIn(['customer', 'id'], action.payload)
                .setIn(['form', 'formValues', 'customer_id'], action.payload);
            break;

        case actionTypes.SET_QUOTE_ID:
            state = state.setIn(['quote', 'id'], action.payload);
            break;

        case actionTypes.FETCH_QUOTE:
            state = state.setIn(['quote', 'isFetching'], true)
                .setIn(['quote', 'data'], null);
            break;

        case actionTypes.FETCH_QUOTE_SUCCESS:
            state = state.setIn(['quote', 'isFetching'], false)
                .setIn(['quote', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'notes'], action.payload.notes)
                .setIn(['form', 'formValues', 'private_notes'], action.payload.private_notes)
                .setIn(['form', 'formValues', 'customer_id'], action.payload.customer_id)
                .setIn(['form', 'formValues', 'status_id'], action.payload.status_id)
                .setIn(['form', 'formValues', 'turnaround_id'], action.payload.turnaround_id)
                .setIn(['form', 'formValues', 'customer_po'], action.payload.customer_po)
                .setIn(['form', 'formValues', 'sales_rep_id'], action.payload.sales_rep_id)
                .setIn(['form', 'formValues', 'customer_service_rep_id'], action.payload.customer_service_rep_id)
                .setIn(['form', 'formValues', 'discount'], action.payload.discount)
                .setIn(['information', 'estimates'], action.payload.estimates)
                .setIn(['customer', 'id'], action.payload.customer_id);
            break;

        case actionTypes.FETCH_QUOTE_FAILURE:
            state = state.setIn(['quote', 'isFetching'], false);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'presses'], [])
                .setIn(['information', 'customers'], [])
                .setIn(['information', 'customerDiscounts'], [])
                .setIn(['information', 'industries'], [])
                .setIn(['information', 'products'], [])
                .setIn(['information', 'largeFormatMachines'], [])
                .setIn(['information', 'substrates'], [])
                .setIn(['information', 'laminates'], [])
                .setIn(['information', 'accessories'], [])
                .setIn(['information', 'inks'], [])
                .setIn(['productCustomer', 'name'], '');
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'presses'], action.payload.presses)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'customerDiscounts'], action.payload.customerDiscounts)
                .setIn(['information', 'industries'], action.payload.industries)
                .setIn(['information', 'foldTypes'], action.payload.foldTypes)
                .setIn(['information', 'products'], action.payload.products)
                .setIn(['information', 'largeFormatMachines'], action.payload.largeFormatMachines)
                .setIn(['information', 'substrates'], action.payload.substrates)
                .setIn(['information', 'laminates'], action.payload.laminates)
                .setIn(['information', 'accessories'], action.payload.accessories)
                .setIn(['information', 'inks'], action.payload.inks)
                .setIn(['productCustomer', 'name'], action.payload.customerName);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.ESTIMATE:
            state = state.setIn(['estimate', 'isLoading'], true)
                .setIn(['estimate', 'results'], []);
            break;

        case actionTypes.ESTIMATE_SUCCESS:
            state = state.setIn(['estimate', 'isLoading'], false)
                .setIn(['estimate', 'results'], action.payload);
            break;

        case actionTypes.ESTIMATE_FAILURE:
            state = state.setIn(['estimate', 'isLoading'], false);
            break;

        case actionTypes.SET_PRICES:
            state = state.setIn(['form', 'formValues', 'estimate_press_price_1'], action.payload.estimate_press_price_1)
                .setIn(['form', 'formValues', 'estimate_press_price_2'], action.payload.estimate_press_price_2)
                .setIn(['form', 'formValues', 'estimate_press_price_3'], action.payload.estimate_press_price_3)
                .setIn(['form', 'formValues', 'estimate_paper_price_1'], action.payload.estimate_paper_price_1)
                .setIn(['form', 'formValues', 'estimate_paper_price_2'], action.payload.estimate_paper_price_2)
                .setIn(['form', 'formValues', 'estimate_paper_price_3'], action.payload.estimate_paper_price_3)
                .setIn(['form', 'formValues', 'estimate_ink_price_1'], action.payload.estimate_ink_price_1)
                .setIn(['form', 'formValues', 'estimate_ink_price_2'], action.payload.estimate_ink_price_2)
                .setIn(['form', 'formValues', 'estimate_ink_price_3'], action.payload.estimate_ink_price_3)
                .setIn(['form', 'formValues', 'estimate_cutting_price_1'], action.payload.estimate_cutting_price_1)
                .setIn(['form', 'formValues', 'estimate_cutting_price_2'], action.payload.estimate_cutting_price_2)
                .setIn(['form', 'formValues', 'estimate_cutting_price_3'], action.payload.estimate_cutting_price_3)
                .setIn(['form', 'formValues', 'estimate_binding_price_1'], action.payload.estimate_binding_price_1)
                .setIn(['form', 'formValues', 'estimate_binding_price_2'], action.payload.estimate_binding_price_2)
                .setIn(['form', 'formValues', 'estimate_binding_price_3'], action.payload.estimate_binding_price_3)
                .setIn(['form', 'formValues', 'estimate_folding_price_1'], action.payload.estimate_folding_price_1)
                .setIn(['form', 'formValues', 'estimate_folding_price_2'], action.payload.estimate_folding_price_2)
                .setIn(['form', 'formValues', 'estimate_folding_price_3'], action.payload.estimate_folding_price_3)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_1'], action.payload.estimate_digital_machine_price_1)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_2'], action.payload.estimate_digital_machine_price_2)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_3'], action.payload.estimate_digital_machine_price_3)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_1'], action.payload.estimate_large_format_machine_price_1)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_2'], action.payload.estimate_large_format_machine_price_2)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_3'], action.payload.estimate_large_format_machine_price_3)
                .setIn(['form', 'formValues', 'estimate_substrate_price_1'], action.payload.estimate_substrate_price_1)
                .setIn(['form', 'formValues', 'estimate_substrate_price_2'], action.payload.estimate_substrate_price_2)
                .setIn(['form', 'formValues', 'estimate_substrate_price_3'], action.payload.estimate_substrate_price_3)
                .setIn(['form', 'formValues', 'estimate_laminate_price_1'], action.payload.estimate_laminate_price_1)
                .setIn(['form', 'formValues', 'estimate_laminate_price_2'], action.payload.estimate_laminate_price_2)
                .setIn(['form', 'formValues', 'estimate_laminate_price_3'], action.payload.estimate_laminate_price_3)
                .setIn(['form', 'formValues', 'estimate_accessories_price_1'], action.payload.estimate_accessories_price_1)
                .setIn(['form', 'formValues', 'estimate_accessories_price_2'], action.payload.estimate_accessories_price_2)
                .setIn(['form', 'formValues', 'estimate_accessories_price_3'], action.payload.estimate_accessories_price_3)
                .setIn(['form', 'formValues', 'estimate_scoring_price_1'], action.payload.estimate_scoring_price_1)
                .setIn(['form', 'formValues', 'estimate_scoring_price_2'], action.payload.estimate_scoring_price_2)
                .setIn(['form', 'formValues', 'estimate_scoring_price_3'], action.payload.estimate_scoring_price_3)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_1'], action.payload.estimate_die_cutting_price_1)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_2'], action.payload.estimate_die_cutting_price_2)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_3'], action.payload.estimate_die_cutting_price_3)
                .setIn(['form', 'formValues', 'estimate_drilling_price_1'], action.payload.estimate_drilling_price_1)
                .setIn(['form', 'formValues', 'estimate_drilling_price_2'], action.payload.estimate_drilling_price_2)
                .setIn(['form', 'formValues', 'estimate_drilling_price_3'], action.payload.estimate_drilling_price_3)
                .setIn(['form', 'formValues', 'estimate_padding_price_1'], action.payload.estimate_padding_price_1)
                .setIn(['form', 'formValues', 'estimate_padding_price_2'], action.payload.estimate_padding_price_2)
                .setIn(['form', 'formValues', 'estimate_padding_price_3'], action.payload.estimate_padding_price_3)
                .setIn(['form', 'formValues', 'estimate_banding_price_1'], action.payload.estimate_banding_price_1)
                .setIn(['form', 'formValues', 'estimate_banding_price_2'], action.payload.estimate_banding_price_2)
                .setIn(['form', 'formValues', 'estimate_banding_price_3'], action.payload.estimate_banding_price_3)
                .setIn(['form', 'formValues', 'estimate_wrapping_price_1'], action.payload.estimate_wrapping_price_1)
                .setIn(['form', 'formValues', 'estimate_wrapping_price_2'], action.payload.estimate_wrapping_price_2)
                .setIn(['form', 'formValues', 'estimate_wrapping_price_3'], action.payload.estimate_wrapping_price_3)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_1'], action.payload.estimate_shrink_wrapping_price_1)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_2'], action.payload.estimate_shrink_wrapping_price_2)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_3'], action.payload.estimate_shrink_wrapping_price_3)
                .setIn(['form', 'formValues', 'estimate_package_price_1'], action.payload.estimate_package_price_1)
                .setIn(['form', 'formValues', 'estimate_package_price_2'], action.payload.estimate_package_price_2)
                .setIn(['form', 'formValues', 'estimate_package_price_3'], action.payload.estimate_package_price_3)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_1'], action.payload.estimate_hand_gathering_price_1)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_2'], action.payload.estimate_hand_gathering_price_2)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_3'], action.payload.estimate_hand_gathering_price_3)
                .setIn(['form', 'formValues', 'estimate_counting_price_1'], action.payload.estimate_counting_price_1)
                .setIn(['form', 'formValues', 'estimate_counting_price_2'], action.payload.estimate_counting_price_2)
                .setIn(['form', 'formValues', 'estimate_counting_price_3'], action.payload.estimate_counting_price_3);
            break;

        case actionTypes.SAVE_ESTIMATE:
            state = state.setIn(['estimate', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ESTIMATE_SUCCESS:
            state = state.setIn(['estimate', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ESTIMATE_FAILURE:
            state = state.setIn(['estimate', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ESTIMATE:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ESTIMATE_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ESTIMATE_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.CONVERT_QUOTE:
            state = state.setIn(['order', 'isSaving'], true);
            break;

        case actionTypes.CONVERT_QUOTE_SUCCESS:
            state = state.setIn(['order', 'isSaving'], false);
            break;

        case actionTypes.CONVERT_QUOTE_FAILURE:
            state = state.setIn(['order', 'isSaving'], false);
            break;

        case actionTypes.SET_ESTIMATES:
            state = state.setIn(['information', 'estimates'], action.payload);
            break;

        case actionTypes.SET_ITEM_QUANTITY:
            state = state.setIn(['information', 'estimates'], action.payload);
            break;

        case actionTypes.SET_EDITING_CUSTOMER:
            state = state.setIn(['customer', 'editing'], action.payload);
            break;

        case actionTypes.CLEAR_RESULTS:
            state = state.setIn(['form', 'formValues', 'estimate_press_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_press_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_press_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_press_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_paper_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_paper_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_paper_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_ink_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_ink_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_ink_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_cutting_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_cutting_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_cutting_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_binding_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_binding_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_binding_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_folding_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_folding_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_folding_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_substrate_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_substrate_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_substrate_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_laminate_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_laminate_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_laminate_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_accessories_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_accessories_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_accessories_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_outside_services_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_outside_services_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_outside_services_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_scoring_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_scoring_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_scoring_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_drilling_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_drilling_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_drilling_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_padding_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_padding_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_padding_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_banding_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_banding_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_banding_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_wrapping_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_wrapping_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_wrapping_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_package_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_package_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_package_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_counting_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_counting_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_counting_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_notes'], '')
                .setIn(['form', 'formValues', 'estimate_customer_discount_id'], '')
                .setIn(['form', 'formValues', 'estimate_discount'], 0)
                .setIn(['form', 'formValues', 'estimate_discount_percentage'], 0)
                .setIn(['activeElement', 'product'], null)
                .setIn(['estimate', 'results'], []);

            if (parseInt(action.payload) === 1) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity_1'], 500)
                    .setIn(['form', 'formValues', 'estimate_quantity_2'], 1000)
                    .setIn(['form', 'formValues', 'estimate_quantity_3'], 2000);
            } else if (parseInt(action.payload) === 2) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity_1'], 500)
                    .setIn(['form', 'formValues', 'estimate_quantity_2'], 1000)
                    .setIn(['form', 'formValues', 'estimate_quantity_3'], 2000);
            } else if (parseInt(action.payload) === 3) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity_1'], 250)
                    .setIn(['form', 'formValues', 'estimate_quantity_2'], 500)
                    .setIn(['form', 'formValues', 'estimate_quantity_3'], 1000);
            } else if (parseInt(action.payload) === 4) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity_1'], 1)
                    .setIn(['form', 'formValues', 'estimate_quantity_2'], 2)
                    .setIn(['form', 'formValues', 'estimate_quantity_3'], 5);
            }

            break;

        case actionTypes.SET_ACCESSORIES:
            state = state.setIn(['form', 'formValues', 'estimate_accessories'], action.payload == null ? [] : action.payload);
            break;

        case actionTypes.SET_OPTIONS:
            state = state.updateIn(['options', action.payload.name], (data) => Immutable.List(action.payload.data));
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.SET_LOADING:
            state = state.setIn(['fetching', action.payload.name], action.payload.value);
            break;

        case actionTypes.RESET_OPTIONS:
            state = state.setIn(['options', action.payload], []);
            break;

        case actionTypes.UPDATE_STATE:
            if (action.payload.formValues) {
                state = state.updateIn(['form', 'formValues'], (formValues) => {
                    for (let [element, value] of Object.entries(action.payload.formValues)) {
                        formValues = formValues.set(element, value);
                    }
                    return formValues;
                });
            }
            if (action.payload.options) {
                state = state.updateIn(['options'], (options) => {
                    for (let [element, value] of Object.entries(action.payload.options)) {
                        options = options.set(element, value);
                    }
                    return options;
                });
            }
            if (action.payload.fetching) {
                state = state.updateIn(['fetching'], (fetching) => {
                    for (let [element, value] of Object.entries(action.payload.fetching)) {
                        fetching = fetching.set(element, value);
                    }
                    return fetching;
                });
            }
            if (action.payload.information) {
                state = state.updateIn(['information'], (information) => {
                    for (let [element, value] of Object.entries(action.payload.information)) {
                        information = information.set(element, value);
                    }
                    return information;
                });
            }
            break;

        case actionTypes.FETCH_ESTIMATE:
            state = state.setIn(['fetching', 'estimate'], true);
            break;

        case actionTypes.FETCH_ESTIMATE_SUCCESS:
            break;

        case actionTypes.FETCH_ESTIMATE_FAILURE:
            state = state.setIn(['fetching', 'estimate'], false);
            break;

        case actionTypes.FETCH_PRODUCT_WINDOW:
            break;

        case actionTypes.FETCH_PRODUCT_WINDOW_SUCCESS:
            state = state.setIn(['form', 'formValues', 'estimate_type'], action.payload.product_type)
                .setIn(['productWindow', 'name'], action.payload.name)
                .setIn(['productWindow', 'type'], action.payload.product_type)
                .setIn(['activeElement', 'configuration'], JSON.parse(action.payload.configuration_json));
            break;

        case actionTypes.FETCH_PRODUCT_WINDOW_FAILURE:
            break;

        case actionTypes.RESET_ESTIMATE_FORM:
            state = state.setIn(['estimate', 'results'], [])
                .setIn(['form', 'formErrors'], {})
                .setIn(['quote', 'data'], null);
            break;

        case actionTypes.SET_PRODUCT_WINDOW_ID:
            state = state.setIn(['productWindow', 'id'], action.payload);
            break;

        case actionTypes.SET_PRODUCT_CUSTOMER_ID:
            state = state.setIn(['productCustomer', 'id'], action.payload)
                .setIn(['customer', 'id'], action.payload)
                .setIn(['form', 'formValues', 'customer_id'], action.payload);
            break;

        case actionTypes.CHECK_ACCESSORY:
            state = state.updateIn(['form', 'formValues', 'estimate_accessories'], (accessories) => {
                const accessoryId = accessories.find((accessory) => parseInt(accessory) === action.payload);
                if (accessoryId === undefined) {
                    return accessories.push(action.payload);
                } else {
                    const index = accessories.indexOf(accessoryId);
                    return accessories.delete(index);
                }
            });
            break;

        case actionTypes.FETCH_ESTIMATE_INFORMATION:
            state = state.setIn(['information', 'isFetchingEstimate'], true)
                .setIn(['information', 'presses'], [])
                .setIn(['information', 'customerDiscounts'], [])
                .setIn(['information', 'industries'], [])
                .setIn(['information', 'products'], [])
                .setIn(['information', 'largeFormatMachines'], [])
                .setIn(['information', 'substrates'], [])
                .setIn(['information', 'laminates'], [])
                .setIn(['information', 'accessories'], [])
                .setIn(['information', 'inks'], []);
            break;

        case actionTypes.FETCH_ESTIMATE_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetchingEstimate'], false)
                .setIn(['information', 'presses'], action.payload.data.presses)
                .setIn(['information', 'customerDiscounts'], action.payload.data.customerDiscounts)
                .setIn(['information', 'foldTypes'], action.payload.data.foldTypes)
                .setIn(['information', 'products'], action.payload.data.products)
                .setIn(['information', 'largeFormatMachines'], action.payload.data.largeFormatMachines)
                .setIn(['information', 'substrates'], action.payload.data.substrates)
                .setIn(['information', 'laminates'], action.payload.data.laminates)
                .setIn(['information', 'accessories'], action.payload.data.accessories)
                .setIn(['information', 'inks'], action.payload.data.inks);
            break;

        case actionTypes.FETCH_ESTIMATE_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetchingEstimate'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
