import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            customer_id: '',
            name: '',
            status_id: 1,
            invoice_date: '',
            sales_rep_id: '',
            customer_service_rep_id: '',
            due_date: '',
            shipping_method: '',
            web_id: '',
            turnaround_id: 1,
            subtotal: '',
            rush_fee: '',
            tax: '',
            total: '',
            notes: '',
            discount: 0,
            production_status_id: '',
            customer_po: '',
            shipping: null,
            estimate_id: '',
            estimate_type: 1,
            estimate_product_category_id: '',
            estimate_bleed: 0.125,
            estimate_multi_page_type: 1,
            estimate_product_description: '',
            estimate_front_inks: 4,
            estimate_front_coating: '',
            estimate_back_inks: 4,
            estimate_back_coating: '',
            estimate_interior_inks: 4,
            estimate_interior_coating: '',
            estimate_digital_inks: 2,
            estimate_digital_sides: 2,
            estimate_digital_machine_id: '',
            estimate_binding_edge: 1,
            estimate_press_id: '',
            estimate_second_press_id: '',
            estimate_cover_press_id: '',
            estimate_pages: '',
            estimate_paper_category_id: '',
            estimate_paper_weight: '',
            estimate_paper_id: '',
            estimate_cover_paper_category_id: '',
            estimate_cover_paper_weight: '',
            estimate_cover_paper_id: '',
            estimate_width: '',
            estimate_height: '',
            estimate_finish_width: '',
            estimate_finish_height: '',
            estimate_running_style: '',
            estimate_second_running_style: '',
            estimate_cover_running_style: '',
            estimate_large_format_machine_id: '',
            estimate_substrate_id: '',
            estimate_laminate_id: '',
            estimate_unit: 2,
            estimate_fold_type_id: '',
            estimate_product_id: '',
            estimate_accessories: [],
            estimate_outside_services: [],
            estimate_requires_scoring: 0,
            estimate_requires_die_cutting: 0,
            estimate_drilling_holes: 0,
            estimate_requires_padding: 0,
            estimate_sheets_to_pad: 10,
            estimate_banding_type_id: '',
            estimate_wrapping_type_id: '',
            estimate_shrink_wrapping_type_id: '',
            estimate_package_id: '',
            estimate_use_hand_gathering: 0,
            estimate_use_counting: 0,
            estimate_coil_option: '',
            estimate_coil_color: '',
            estimate_staples_number: '',
            estimate_staples_location: '',
            estimate_data_processing_elements: [],
            estimate_inkjet_elements: [],
            estimate_inserting_elements: [],
            estimate_perfect_match_elements: [],
            estimate_art_elements: [],
            estimate_handwork_elements: [],
            estimate_pressure_seal_elements: [],
            estimate_index_tab_elements: [],
            estimate_label_elements: [],
            estimate_ncr_form_elements: [],
            estimate_envelope_press_id: '',
            estimate_envelope_size: '',
            estimate_envelope_paper_type: '',
            estimate_envelope_paper_style: '',
            estimate_envelope_paper_weight: '',
            estimate_envelope_paper_id: '',
            estimate_customer_provide: '',
            estimate_print_on_flap: '',
            estimate_quantity: 500,
            estimate_press_price: 0,
            estimate_paper_price: 0,
            estimate_ink_price: 0,
            estimate_cutting_price: 0,
            estimate_binding_price: 0,
            estimate_folding_price: 0,
            estimate_digital_machine_price: 0,
            estimate_large_format_machine_price: 0,
            estimate_substrate_price: 0,
            estimate_laminate_price: 0,
            estimate_accessories_price: 0,
            estimate_outside_services_price: 0,
            estimate_scoring_price: 0,
            estimate_die_cutting_price: 0,
            estimate_drilling_price: 0,
            estimate_padding_price: 0,
            estimate_banding_price: 0,
            estimate_wrapping_price: 0,
            estimate_shrink_wrapping_price: 0,
            estimate_package_price: 0,
            estimate_hand_gathering_price: 0,
            estimate_counting_price: 0,
            estimate_data_processing_price_1: 0,
            estimate_data_processing_price_2: 0,
            estimate_data_processing_price_3: 0,
            estimate_inkjet_price: 0,
            estimate_inserting_price: 0,
            estimate_perfect_match_price: 0,
            estimate_art_price: 0,
            estimate_handwork_price: 0,
            estimate_pressure_seal_price_1: 0,
            estimate_pressure_seal_price_2: 0,
            estimate_pressure_seal_price_3: 0,
            estimate_pressure_seal_price_4: 0,
            estimate_pressure_seal_price_5: 0,
            estimate_pressure_seal_price_6: 0,
            estimate_pressure_seal_price_7: 0,
            estimate_pressure_seal_price_8: 0,
            estimate_index_tab_price: 0,
            estimate_label_price: 0,
            estimate_ncr_form_price: 0,
            estimate_notes: '',
            estimate_customer_discount_id: '',
            estimate_discount: 0,
            estimate_discount_percentage: 0,
            estimate_envelope_press_price: 0,
            estimate_envelope_paper_price: 0,
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        product: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'id',
        type: 'desc'
    },
    customer: {
        id: null,
        data: null,
        editing: false
    },
    order: {
        id: null,
        data: null,
        isFetching: false,
        isSendingToProduction: false
    },
    information: {
        presses: [],
        estimates: [],
        customers: [],
        customerDiscounts: [],
        industries: [],
        products: [],
        largeFormatMachines: [],
        substrates: [],
        laminates: [],
        accessories: [],
        inks: [],
        envelopePresses: [],
        envelopePapers: [],
        isFetching: false,
        isFetchingEstimate: false
    },
    estimate: {
        isLoading: false,
        results: [],
        isSaving: false,
        isDeleting: false
    },
    uploadFile: {
        isUploading: false,
        progress: 0,
        files: [],
        filename: '',
        id: null,
        isDeleting: false,
        idToDelete: null
    },
    options: {
        turnaround: [{ label: 'Select option', value: '' }],
        salesReps: [{ label: 'Select option', value: '' }],
        customerServiceReps: [{ label: 'Select option', value: '' }],
        presses: [{ label: 'Select option', value: '' }],
        paperCategories: [{ label: 'Select option', value: '' }],
        paperWeights: [{ label: 'Select option', value: '' }],
        papers: [{ label: 'Select option', value: '' }],
        coverPaperCategories: [{ label: 'Select option', value: '' }],
        coverPaperWeights: [{ label: 'Select option', value: '' }],
        coverPapers: [{ label: 'Select option', value: '' }],
        customers: [{ label: 'Select option', value: '' }],
        customerDiscounts: [{ label: 'None', value: '' }],
        industries: [{ label: 'Select option', value: '' }],
        foldTypes: [{ label: 'None', value: '' }],
        products: [{ label: 'Custom', value: '' }],
        digitalMachines: [{ label: 'Select option', value: '' }],
        largeFormatMachines: [{ label: 'Select option', value: '' }],
        substrates: [{ label: 'Select option', value: '' }],
        laminates: [{ label: 'None', value: '' }],
        accessories: [{ label: 'None', value: '' }],
        inks: [{ label: 'Select option', value: '' }],
        statuses: [{ label: 'Select option', value: '' }],
        productionStatuses: [{ label: 'Select option', value: '' }],
        bandingTypes: [{ label: 'None', value: '' }],
        wrappingTypes: [{ label: 'None', value: '' }],
        shrinkWrappingTypes: [{ label: 'None', value: '' }],
        packages: [{ label: 'None', value: '' }],
        productCategories: [{ label: 'Select option', value: '' }],
        dataProcessingElements: [{ label: 'Select option', value: '' }],
        inkjetElements: [{ label: 'Select option', value: '' }],
        insertingElements: [{ label: 'Select option', value: '' }],
        perfectMatchElements: [{ label: 'Select option', value: '' }],
        artElements: [{ label: 'Select option', value: '' }],
        handworkElements: [{ label: 'Select option', value: '' }],
        pressureSealElements: [{ label: 'Select option', value: '' }],
        indexTabElements: [{ label: 'Select option', value: '' }],
        labelElements: [{ label: 'Select option', value: '' }],
        ncrFormElements: [{ label: 'Select option', value: '' }],
        envelopePresses: [{ label: 'Select option', value: '' }],
        envelopePapers: [{ label: 'Select option', value: '' }],
        envelopePaperTypes: [{ label: 'Select option', value: '' }],
        envelopePaperSizes: [{ label: 'Select option', value: '' }],
        envelopePaperStyles: [{ label: 'Select option', value: '' }],
        envelopePaperWeights: [{ label: 'Select option', value: '' }],
    },
    shipping: {
        total: null,
        isCalculating: false,
        prices: []
    },
    fetching: {
        presses: false,
        paperCategories: false,
        paperWeights: false,
        papers: false,
        digitalMachines: false,
        largeFormatMachines: false,
        substrates: false,
        laminates: false,
        accessories: false,
        estimate: false,
        customers: false,
        customer: false
    },
    history: null
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (!window.location['pathname'].includes('/order')) {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (value) => action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false)
                .setIn(['information', 'estimates'], action.payload.estimates);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                customer_id: '',
                name: '',
                status_id: 1,
                invoice_date: '',
                sales_rep_id: '',
                customer_service_rep_id: '',
                due_date: '',
                shipping_method: '',
                web_id: '',
                turnaround_id: 1,
                subtotal: '',
                rush_fee: '',
                tax: '',
                total: '',
                notes: '',
                discount: 0,
                production_status_id: '',
                customer_po: '',
                shipping: null,
                estimate_id: '',
                estimate_type: 1,
                estimate_product_category_id: '',
                estimate_bleed: 0.125,
                estimate_multi_page_type: 1,
                estimate_product_description: '',
                estimate_front_inks: 4,
                estimate_front_coating: '',
                estimate_back_inks: 4,
                estimate_back_coating: '',
                estimate_interior_inks: 4,
                estimate_interior_coating: '',
                estimate_digital_inks: 2,
                estimate_digital_sides: 2,
                estimate_digital_machine_id: '',
                estimate_binding_edge: 1,
                estimate_press_id: '',
                estimate_second_press_id: '',
                estimate_cover_press_id: '',
                estimate_pages: '',
                estimate_paper_category_id: '',
                estimate_paper_weight: '',
                estimate_paper_id: '',
                estimate_cover_paper_category_id: '',
                estimate_cover_paper_weight: '',
                estimate_cover_paper_id: '',
                estimate_width: '',
                estimate_height: '',
                estimate_finish_width: '',
                estimate_finish_height: '',
                estimate_running_style: '',
                estimate_second_running_style: '',
                estimate_cover_running_style: '',
                estimate_large_format_machine_id: '',
                estimate_substrate_id: '',
                estimate_laminate_id: '',
                estimate_unit: 2,
                estimate_fold_type_id: '',
                estimate_product_id: '',
                estimate_accessories: [],
                estimate_outside_services: [],
                estimate_requires_scoring: 0,
                estimate_requires_die_cutting: 0,
                estimate_drilling_holes: 0,
                estimate_requires_padding: 0,
                estimate_banding_type_id: '',
                estimate_wrapping_type_id: '',
                estimate_shrink_wrapping_type_id: '',
                estimate_package_id: '',
                estimate_use_hand_gathering: 0,
                estimate_use_counting: 0,
                estimate_coil_option: '',
                estimate_coil_color: '',
                estimate_staples_number: '',
                estimate_staples_location: '',
                estimate_envelope_press_id: '',
                estimate_envelope_size: '',
                estimate_envelope_paper_type: '',
                estimate_envelope_paper_style: '',
                estimate_envelope_paper_weight: '',
                estimate_envelope_paper_id: '',
                estimate_customer_provide: '',
                estimate_print_on_flap: '',
                estimate_quantity: 500,
                estimate_press_price: 0,
                estimate_paper_price: 0,
                estimate_ink_price: 0,
                estimate_cutting_price: 0,
                estimate_binding_price: 0,
                estimate_folding_price: 0,
                estimate_digital_machine_price: 0,
                estimate_large_format_machine_price: 0,
                estimate_substrate_price: 0,
                estimate_laminate_price: 0,
                estimate_accessories_price: 0,
                estimate_outside_services_price_1: 0,
                estimate_scoring_price: 0,
                estimate_die_cutting_price: 0,
                estimate_drilling_price: 0,
                estimate_padding_price: 0,
                estimate_banding_price: 0,
                estimate_wrapping_price: 0,
                estimate_shrink_wrapping_price: 0,
                estimate_package_price: 0,
                estimate_hand_gathering_price: 0,
                estimate_counting_price: 0,
                estimate_data_processing_price_1: 0,
                estimate_data_processing_price_2: 0,
                estimate_data_processing_price_3: 0,
                estimate_inkjet_price: 0,
                estimate_inserting_price: 0,
                estimate_perfect_match_price: 0,
                estimate_art_price: 0,
                estimate_handwork_price: 0,
                estimate_pressure_seal_price_1: 0,
                estimate_pressure_seal_price_2: 0,
                estimate_pressure_seal_price_3: 0,
                estimate_pressure_seal_price_4: 0,
                estimate_pressure_seal_price_5: 0,
                estimate_pressure_seal_price_6: 0,
                estimate_pressure_seal_price_7: 0,
                estimate_pressure_seal_price_8: 0,
                estimate_index_tab_price: 0,
                estimate_label_price: 0,
                estimate_ncr_form_price: 0,
                estimate_envelope_press_price: 0,
                estimate_envelope_paper_price: 0,
                estimate_notes: '',
                estimate_customer_discount_id: '',
                estimate_discount: 0,
                estimate_discount_percentage: 0
            })
                .setIn(['estimate', 'results'], [])
                .setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'product'], null);
            break;

        case actionTypes.RESET_ESTIMATE_FORM:
            state = state.setIn(['form', 'formValues', 'estimate_id'], '')
                .setIn(['form', 'formValues', 'estimate_type'], 1)
                .setIn(['form', 'formValues', 'estimate_product_category_id'], '')
                .setIn(['form', 'formValues', 'estimate_bleed'], 0.125)
                .setIn(['form', 'formValues', 'estimate_multi_page_type'], 1)
                .setIn(['form', 'formValues', 'estimate_product_description'], '')
                .setIn(['form', 'formValues', 'estimate_front_inks'], 4)
                .setIn(['form', 'formValues', 'estimate_front_coating'], '')
                .setIn(['form', 'formValues', 'estimate_back_inks'], 4)
                .setIn(['form', 'formValues', 'estimate_back_coating'], '')
                .setIn(['form', 'formValues', 'estimate_interior_inks'], 4)
                .setIn(['form', 'formValues', 'estimate_interior_coating'], '')
                .setIn(['form', 'formValues', 'estimate_digital_inks'], 2)
                .setIn(['form', 'formValues', 'estimate_digital_sides'], 2)
                .setIn(['form', 'formValues', 'estimate_digital_machine_id'], '')
                .setIn(['form', 'formValues', 'estimate_binding_edge'], 1)
                .setIn(['form', 'formValues', 'estimate_press_id'], '')
                .setIn(['form', 'formValues', 'estimate_second_press_id'], '')
                .setIn(['form', 'formValues', 'estimate_cover_press_id'], '')
                .setIn(['form', 'formValues', 'estimate_pages'], '')
                .setIn(['form', 'formValues', 'estimate_paper_category_id'], '')
                .setIn(['form', 'formValues', 'estimate_paper_weight'], '')
                .setIn(['form', 'formValues', 'estimate_paper_id'], '')
                .setIn(['form', 'formValues', 'estimate_cover_paper_description'], '')
                .setIn(['form', 'formValues', 'estimate_cover_paper_category_id'], '')
                .setIn(['form', 'formValues', 'estimate_cover_paper_weight'], '')
                .setIn(['form', 'formValues', 'estimate_width'], '')
                .setIn(['form', 'formValues', 'estimate_height'], '')
                .setIn(['form', 'formValues', 'estimate_finish_width'], '')
                .setIn(['form', 'formValues', 'estimate_finish_height'], '')
                .setIn(['form', 'formValues', 'estimate_running_style'], '')
                .setIn(['form', 'formValues', 'estimate_second_running_style'], '')
                .setIn(['form', 'formValues', 'estimate_cover_running_style'], '')
                .setIn(['form', 'formValues', 'estimate_large_format_machine_id'], '')
                .setIn(['form', 'formValues', 'estimate_substrate_id'], '')
                .setIn(['form', 'formValues', 'estimate_laminate_id'], '')
                .setIn(['form', 'formValues', 'estimate_unit'], 2)
                .setIn(['form', 'formValues', 'estimate_fold_type_id'], '')
                .setIn(['form', 'formValues', 'estimate_product_id'], '')
                .setIn(['form', 'formValues', 'estimate_accessories'], [])
                .setIn(['form', 'formValues', 'estimate_outside_services'], [])
                .setIn(['form', 'formValues', 'estimate_requires_scoring'], 0)
                .setIn(['form', 'formValues', 'estimate_requires_die_cutting'], 0)
                .setIn(['form', 'formValues', 'estimate_drilling_holes'], 0)
                .setIn(['form', 'formValues', 'estimate_requires_padding'], 0)
                .setIn(['form', 'formValues', 'estimate_banding_type_id'], '')
                .setIn(['form', 'formValues', 'estimate_wrapping_type_id'], '')
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_type_id'], '')
                .setIn(['form', 'formValues', 'estimate_package_id'], '')
                .setIn(['form', 'formValues', 'estimate_use_hand_gathering'], 0)
                .setIn(['form', 'formValues', 'estimate_use_counting'], 0)
                .setIn(['form', 'formValues', 'estimate_coil_option'], 0)
                .setIn(['form', 'formValues', 'estimate_coil_color'], 0)
                .setIn(['form', 'formValues', 'estimate_staples_number'], 0)
                .setIn(['form', 'formValues', 'estimate_staples_location'], 0)
                .setIn(['form', 'formValues', 'estimate_envelope_press_id'], '')
                .setIn(['form', 'formValues', 'estimate_envelope_size'], '')
                .setIn(['form', 'formValues', 'estimate_envelope_paper_type'], '')
                .setIn(['form', 'formValues', 'estimate_envelope_paper_style'], '')
                .setIn(['form', 'formValues', 'estimate_envelope_paper_weight'], '')
                .setIn(['form', 'formValues', 'estimate_envelope_paper_id'], '')
                .setIn(['form', 'formValues', 'estimate_customer_provide'], '')
                .setIn(['form', 'formValues', 'estimate_print_on_flap'], '')
                .setIn(['form', 'formValues', 'estimate_quantity'], 500)
                .setIn(['form', 'formValues', 'estimate_press_price'], 0)
                .setIn(['form', 'formValues', 'estimate_paper_price'], 0)
                .setIn(['form', 'formValues', 'estimate_ink_price'], 0)
                .setIn(['form', 'formValues', 'estimate_cutting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_binding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_folding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price'], 0)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price'], 0)
                .setIn(['form', 'formValues', 'estimate_substrate_price'], 0)
                .setIn(['form', 'formValues', 'estimate_laminate_price'], 0)
                .setIn(['form', 'formValues', 'estimate_accessories_price'], 0)
                .setIn(['form', 'formValues', 'estimate_outside_services_price'], 0)
                .setIn(['form', 'formValues', 'estimate_scoring_price'], 0)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_drilling_price'], 0)
                .setIn(['form', 'formValues', 'estimate_padding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_banding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_wrapping_price'], 0)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price'], 0)
                .setIn(['form', 'formValues', 'estimate_package_price'], 0)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price'], 0)
                .setIn(['form', 'formValues', 'estimate_counting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_inkjet_price'], 0)
                .setIn(['form', 'formValues', 'estimate_inserting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_perfect_match_price'], 0)
                .setIn(['form', 'formValues', 'estimate_art_price'], 0)
                .setIn(['form', 'formValues', 'estimate_handwork_price'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_4'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_5'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_6'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_7'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_8'], 0)
                .setIn(['form', 'formValues', 'estimate_index_tab_price'], 0)
                .setIn(['form', 'formValues', 'estimate_label_price'], 0)
                .setIn(['form', 'formValues', 'estimate_ncr_form_price'], 0)
                .setIn(['form', 'formValues', 'estimate_notes'], '')
                .setIn(['form', 'formValues', 'estimate_customer_discount_id'], '')
                .setIn(['form', 'formValues', 'estimate_discount'], 0)
                .setIn(['form', 'formValues', 'estimate_discount_percentage'], 0)
                .setIn(['estimate', 'results'], [])
                .setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'product'], null);
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'estimate_id'], action.payload.element.id)
                .setIn(['form', 'formValues', 'estimate_type'], action.payload.element.type)
                .setIn(['form', 'formValues', 'estimate_product_category_id'], action.payload.element.product_category_id)
                .setIn(['form', 'formValues', 'estimate_bleed'], action.payload.element.bleed)
                .setIn(['form', 'formValues', 'estimate_multi_page_type'], action.payload.element.multi_page_type)
                .setIn(['form', 'formValues', 'estimate_product_description'], action.payload.element.product_description)
                .setIn(['form', 'formValues', 'estimate_front_inks'], action.payload.element.front_inks)
                .setIn(['form', 'formValues', 'estimate_front_coating'], action.payload.element.front_coating)
                .setIn(['form', 'formValues', 'estimate_back_inks'], action.payload.element.back_inks)
                .setIn(['form', 'formValues', 'estimate_back_coating'], action.payload.element.back_coating)
                .setIn(['form', 'formValues', 'estimate_interior_inks'], action.payload.element.interior_inks)
                .setIn(['form', 'formValues', 'estimate_interior_coating'], action.payload.element.interior_coating)
                .setIn(['form', 'formValues', 'estimate_digital_inks'], action.payload.element.digital_inks)
                .setIn(['form', 'formValues', 'estimate_digital_sides'], action.payload.element.digital_sides)
                .setIn(['form', 'formValues', 'estimate_digital_machine_id'], action.payload.element.digital_machine_id)
                .setIn(['form', 'formValues', 'estimate_binding_edge'], action.payload.element.binding_edge)
                .setIn(['form', 'formValues', 'estimate_press_id'], action.payload.element.press_id)
                .setIn(['form', 'formValues', 'estimate_second_press_id'], action.payload.element.second_press_id)
                .setIn(['form', 'formValues', 'estimate_cover_press_id'], action.payload.element.cover_press_id)
                .setIn(['form', 'formValues', 'estimate_pages'], action.payload.element.pages)
                .setIn(['form', 'formValues', 'estimate_paper_category_id'], action.payload.element.paper_category_id)
                .setIn(['form', 'formValues', 'estimate_paper_weight'], action.payload.element.paper_weight)
                .setIn(['form', 'formValues', 'estimate_paper_id'], action.payload.element.paper_id)
                .setIn(['form', 'formValues', 'estimate_cover_paper_category_id'], action.payload.element.cover_paper_category_id)
                .setIn(['form', 'formValues', 'estimate_cover_paper_weight'], action.payload.element.cover_paper_weight)
                .setIn(['form', 'formValues', 'estimate_cover_paper_id'], action.payload.element.cover_paper_id)
                .setIn(['form', 'formValues', 'estimate_width'], action.payload.element.width)
                .setIn(['form', 'formValues', 'estimate_height'], action.payload.element.height)
                .setIn(['form', 'formValues', 'estimate_finish_width'], action.payload.element.finish_width)
                .setIn(['form', 'formValues', 'estimate_finish_height'], action.payload.element.finish_height)
                .setIn(['form', 'formValues', 'estimate_running_style'], action.payload.element.running_style)
                .setIn(['form', 'formValues', 'estimate_second_running_style'], action.payload.element.second_running_style)
                .setIn(['form', 'formValues', 'estimate_cover_running_style'], action.payload.element.cover_running_style)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_id'], action.payload.element.large_format_machine_id)
                .setIn(['form', 'formValues', 'estimate_substrate_id'], action.payload.element.substrate_id)
                .setIn(['form', 'formValues', 'estimate_laminate_id'], action.payload.element.laminate_id)
                .setIn(['form', 'formValues', 'estimate_unit'], action.payload.element.unit)
                .setIn(['form', 'formValues', 'estimate_fold_type_id'], action.payload.element.fold_type_id)
                .setIn(['form', 'formValues', 'estimate_product_id'], action.payload.element.product_id)
                .setIn(['form', 'formValues', 'estimate_accessories'], action.payload.element.accessories_ids == null ? [] : JSON.parse(action.payload.element.accessories_ids))
                .setIn(['form', 'formValues', 'estimate_outside_services'], action.payload.element.outside_services_ids == null ? [] : JSON.parse(action.payload.element.outside_services_ids))
                .setIn(['form', 'formValues', 'estimate_requires_scoring'], action.payload.element.requires_scoring)
                .setIn(['form', 'formValues', 'estimate_requires_die_cutting'], action.payload.element.requires_die_cutting)
                .setIn(['form', 'formValues', 'estimate_drilling_holes'], action.payload.element.drilling_holes)
                .setIn(['form', 'formValues', 'estimate_requires_padding'], action.payload.element.requires_padding)
                .setIn(['form', 'formValues', 'estimate_banding_type_id'], action.payload.element.banding_type_id)
                .setIn(['form', 'formValues', 'estimate_wrapping_type_id'], action.payload.element.wrapping_type_id)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_type_id'], action.payload.element.shrink_wrapping_type_id)
                .setIn(['form', 'formValues', 'estimate_package_id'], action.payload.element.package_id)
                .setIn(['form', 'formValues', 'estimate_use_hand_gathering'], action.payload.element.use_hand_gathering)
                .setIn(['form', 'formValues', 'estimate_use_counting'], action.payload.element.use_counting)
                .setIn(['form', 'formValues', 'estimate_coil_option'], action.payload.element.coil_option)
                .setIn(['form', 'formValues', 'estimate_coil_color'], action.payload.element.coil_color)
                .setIn(['form', 'formValues', 'estimate_staples_number'], action.payload.element.staples_number)
                .setIn(['form', 'formValues', 'estimate_staples_location'], action.payload.element.staples_location)
                .setIn(['form', 'formValues', 'estimate_data_processing_elements'], action.payload.element.data_processing_elements_ids == null ? [] : JSON.parse(action.payload.element.data_processing_elements_ids))
                .setIn(['form', 'formValues', 'estimate_inkjet_elements'], action.payload.element.inkjet_elements_ids == null ? [] : JSON.parse(action.payload.element.inkjet_elements_ids))
                .setIn(['form', 'formValues', 'estimate_inserting_elements'], action.payload.element.inserting_elements_ids == null ? [] : JSON.parse(action.payload.element.inserting_elements_ids))
                .setIn(['form', 'formValues', 'estimate_perfect_match_elements'], action.payload.element.perfect_match_elements_ids == null ? [] : JSON.parse(action.payload.element.perfect_match_elements_ids))
                .setIn(['form', 'formValues', 'estimate_art_elements'], action.payload.element.art_elements_ids == null ? [] : JSON.parse(action.payload.element.art_elements_ids))
                .setIn(['form', 'formValues', 'estimate_handwork_elements'], action.payload.element.handwork_elements_ids == null ? [] : JSON.parse(action.payload.element.handwork_elements_ids))
                .setIn(['form', 'formValues', 'estimate_pressure_seal_elements'], action.payload.element.pressure_seal_elements_ids == null ? [] : JSON.parse(action.payload.element.pressure_seal_elements_ids))
                .setIn(['form', 'formValues', 'estimate_index_tab_elements'], action.payload.element.index_tab_elements_ids == null ? [] : JSON.parse(action.payload.element.index_tab_elements_ids))
                .setIn(['form', 'formValues', 'estimate_label_elements'], action.payload.element.label_elements_ids == null ? [] : JSON.parse(action.payload.element.label_elements_ids))
                .setIn(['form', 'formValues', 'estimate_ncr_form_elements'], action.payload.element.ncr_form_elements_ids == null ? [] : JSON.parse(action.payload.element.ncr_form_elements_ids))
                .setIn(['form', 'formValues', 'estimate_envelope_press_id'], action.payload.element.envelope_press_id)
                .setIn(['form', 'formValues', 'estimate_envelope_size'], action.payload.element.envelope_size)
                .setIn(['form', 'formValues', 'estimate_envelope_paper_type'], action.payload.element.envelope_paper_type)
                .setIn(['form', 'formValues', 'estimate_envelope_paper_style'], action.payload.element.envelope_paper_style)
                .setIn(['form', 'formValues', 'estimate_envelope_paper_weight'], action.payload.element.envelope_paper_weight)
                .setIn(['form', 'formValues', 'estimate_envelope_paper_id'], action.payload.element.envelope_paper_id)
                .setIn(['form', 'formValues', 'estimate_customer_provide'], action.payload.element.customer_provide)
                .setIn(['form', 'formValues', 'estimate_print_on_flap'], action.payload.element.print_on_flap)
                .setIn(['form', 'formValues', 'estimate_quantity'], action.payload.quantity)
                .setIn(['form', 'formValues', 'estimate_press_price'], action.payload.press_price)
                .setIn(['form', 'formValues', 'estimate_paper_price'], action.payload.paper_price)
                .setIn(['form', 'formValues', 'estimate_ink_price'], action.payload.ink_price)
                .setIn(['form', 'formValues', 'estimate_cutting_price'], action.payload.cutting_price)
                .setIn(['form', 'formValues', 'estimate_binding_price'], action.payload.binding_price)
                .setIn(['form', 'formValues', 'estimate_folding_price'], action.payload.folding_price)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price'], action.payload.digital_machine_price)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price'], action.payload.large_format_machine_price)
                .setIn(['form', 'formValues', 'estimate_substrate_price'], action.payload.substrate_price)
                .setIn(['form', 'formValues', 'estimate_laminate_price'], action.payload.laminate_price)
                .setIn(['form', 'formValues', 'estimate_accessories_price'], action.payload.accessories_price)
                .setIn(['form', 'formValues', 'estimate_outside_services_price'], action.payload.outside_services_price)
                .setIn(['form', 'formValues', 'estimate_scoring_price'], action.payload.scoring_price)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price'], action.payload.die_cutting_price)
                .setIn(['form', 'formValues', 'estimate_drilling_price'], action.payload.drilling_price)
                .setIn(['form', 'formValues', 'estimate_padding_price'], action.payload.padding_price)
                .setIn(['form', 'formValues', 'estimate_banding_price'], action.payload.banding_price)
                .setIn(['form', 'formValues', 'estimate_wrapping_price'], action.payload.wrapping_price)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price'], action.payload.shrink_wrapping_price)
                .setIn(['form', 'formValues', 'estimate_package_price'], action.payload.package_price)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price'], action.payload.hand_gathering_price)
                .setIn(['form', 'formValues', 'estimate_counting_price'], action.payload.counting_price)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_1'], action.payload.dataProcessingPrice1)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_2'], action.payload.dataProcessingPrice2)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_3'], action.payload.dataProcessingPrice3)
                .setIn(['form', 'formValues', 'estimate_inkjet_price'], action.payload.inkjetPrice)
                .setIn(['form', 'formValues', 'estimate_perfect_match_price'], action.payload.perfectMatchPrice)
                .setIn(['form', 'formValues', 'estimate_inserting_price'], action.payload.insertingPrice)
                .setIn(['form', 'formValues', 'estimate_art_price'], action.payload.artPrice)
                .setIn(['form', 'formValues', 'estimate_handwork_price'], action.payload.handworkPrice)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_1'], action.payload.pressureSealPrice1)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_2'], action.payload.pressureSealPrice2)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_3'], action.payload.pressureSealPrice3)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_4'], action.payload.pressureSealPrice4)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_5'], action.payload.pressureSealPrice5)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_6'], action.payload.pressureSealPrice6)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_7'], action.payload.pressureSealPrice7)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_8'], action.payload.pressureSealPrice8)
                .setIn(['form', 'formValues', 'estimate_index_tab_price'], action.payload.indexTabPrice)
                .setIn(['form', 'formValues', 'estimate_label_price'], action.payload.labelPrice)
                .setIn(['form', 'formValues', 'estimate_ncr_form_price'], action.payload.ncrFormPrice)
                .setIn(['form', 'formValues', 'estimate_envelope_press_price'], action.payload.envelopePressPrice)
                .setIn(['form', 'formValues', 'estimate_envelope_paper_price'], action.payload.envelopePaperPrice)
                .setIn(['form', 'formValues', 'estimate_notes'], action.payload.element.notes)
                .setIn(['form', 'formValues', 'estimate_customer_discount_id'], action.payload.element.customer_discount_id)
                .setIn(['form', 'formValues', 'estimate_discount'], action.payload.element.discount)
                .setIn(['form', 'formValues', 'estimate_discount_percentage'], action.payload.element.discount_percentage)
                .setIn(['estimate', 'results'], action.payload.estimate_results);
            break;

        case actionTypes.SET_CUSTOMER_ID:
            state = state.setIn(['customer', 'id'], action.payload)
                .setIn(['form', 'formValues', 'customer_id'], action.payload);
            break;

        case actionTypes.SET_ORDER_ID:
            state = state.setIn(['order', 'id'], action.payload);
            break;

        case actionTypes.FETCH_ORDER:
            state = state.setIn(['order', 'isFetching'], true)
                .setIn(['order', 'data'], null);
            break;

        case actionTypes.FETCH_ORDER_SUCCESS:
            state = state.setIn(['order', 'isFetching'], false)
                .setIn(['order', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'customer_id'], action.payload.customer_id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'status_id'], action.payload.status_id)
                .setIn(['form', 'formValues', 'invoice_date'], action.payload.invoice_date)
                .setIn(['form', 'formValues', 'sales_rep_id'], action.payload.sales_rep_id)
                .setIn(['form', 'formValues', 'customer_service_rep_id'], action.payload.customer_service_rep_id)
                .setIn(['form', 'formValues', 'due_date'], action.payload.due_date)
                .setIn(['form', 'formValues', 'shipping_method'], action.payload.shipping_method)
                .setIn(['form', 'formValues', 'web_id'], action.payload.web_id)
                .setIn(['form', 'formValues', 'turnaround_id'], action.payload.turnaround_id)
                .setIn(['form', 'formValues', 'subtotal'], action.payload.subtotal)
                .setIn(['form', 'formValues', 'rush_fee'], action.payload.rush_fee)
                .setIn(['form', 'formValues', 'tax'], action.payload.tax)
                .setIn(['form', 'formValues', 'total'], action.payload.total)
                .setIn(['form', 'formValues', 'notes'], action.payload.notes)
                .setIn(['form', 'formValues', 'discount'], action.payload.discount)
                .setIn(['form', 'formValues', 'production_status_id'], action.payload.production_status_id)
                .setIn(['form', 'formValues', 'customer_po'], action.payload.customer_po)
                .setIn(['form', 'formValues', 'shipping'], action.payload.shipping)
                .setIn(['shipping', 'total'], action.payload.shipping)
                .setIn(['information', 'estimates'], action.payload.estimates)
                .setIn(['customer', 'id'], action.payload.customer_id);
            break;

        case actionTypes.FETCH_ORDER_FAILURE:
            state = state.setIn(['order', 'isFetching'], false);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'industries'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'industries'], action.payload.industries);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.ESTIMATE:
            state = state.setIn(['estimate', 'isLoading'], true)
                .setIn(['estimate', 'results'], []);
            break;

        case actionTypes.ESTIMATE_SUCCESS:
            state = state.setIn(['estimate', 'isLoading'], false)
                .setIn(['estimate', 'results'], action.payload);
            break;

        case actionTypes.ESTIMATE_FAILURE:
            state = state.setIn(['estimate', 'isLoading'], false);
            break;

        case actionTypes.SET_PRICES:
            state = state.setIn(['form', 'formValues', 'estimate_press_price'], action.payload.estimate_press_price)
                .setIn(['form', 'formValues', 'estimate_paper_price'], action.payload.estimate_paper_price)
                .setIn(['form', 'formValues', 'estimate_ink_price'], action.payload.estimate_ink_price)
                .setIn(['form', 'formValues', 'estimate_cutting_price'], action.payload.estimate_cutting_price)
                .setIn(['form', 'formValues', 'estimate_binding_price'], action.payload.estimate_binding_price)
                .setIn(['form', 'formValues', 'estimate_folding_price'], action.payload.estimate_folding_price)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price'], action.payload.estimate_digital_machine_price)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price'], action.payload.estimate_large_format_machine_price)
                .setIn(['form', 'formValues', 'estimate_substrate_price'], action.payload.estimate_substrate_price)
                .setIn(['form', 'formValues', 'estimate_laminate_price'], action.payload.estimate_laminate_price)
                .setIn(['form', 'formValues', 'estimate_accessories_price'], action.payload.estimate_accessories_price)
                .setIn(['form', 'formValues', 'estimate_outside_services_price'], action.payload.estimate_outside_services_price)
                .setIn(['form', 'formValues', 'estimate_scoring_price'], action.payload.estimate_scoring_price)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price'], action.payload.estimate_die_cutting_price)
                .setIn(['form', 'formValues', 'estimate_drilling_price'], action.payload.estimate_drilling_price)
                .setIn(['form', 'formValues', 'estimate_padding_price'], action.payload.estimate_padding_price)
                .setIn(['form', 'formValues', 'estimate_banding_price'], action.payload.estimate_banding_price)
                .setIn(['form', 'formValues', 'estimate_wrapping_price'], action.payload.estimate_wrapping_price)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price'], action.payload.estimate_shrink_wrapping_price)
                .setIn(['form', 'formValues', 'estimate_package_price'], action.payload.estimate_package_price)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price'], action.payload.estimate_hand_gathering_price)
                .setIn(['form', 'formValues', 'estimate_counting_price'], action.payload.estimate_counting_price)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_1'], action.payload.estimate_data_processing_price_1)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_2'], action.payload.estimate_data_processing_price_2)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_3'], action.payload.estimate_data_processing_price_3)
                .setIn(['form', 'formValues', 'estimate_inkjet_price'], action.payload.estimate_inkjet_price)
                .setIn(['form', 'formValues', 'estimate_inserting_price'], action.payload.estimate_inserting_price)
                .setIn(['form', 'formValues', 'estimate_perfect_match_price'], action.payload.estimate_perfect_match_price)
                .setIn(['form', 'formValues', 'estimate_art_price'], action.payload.estimate_art_price)
                .setIn(['form', 'formValues', 'estimate_handwork_price'], action.payload.estimate_handwork_price)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_1'], action.payload.estimate_pressure_seal_price_1)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_2'], action.payload.estimate_pressure_seal_price_2)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_3'], action.payload.estimate_pressure_seal_price_3)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_4'], action.payload.estimate_pressure_seal_price_4)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_5'], action.payload.estimate_pressure_seal_price_5)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_6'], action.payload.estimate_pressure_seal_price_6)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_7'], action.payload.estimate_pressure_seal_price_7)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_8'], action.payload.estimate_pressure_seal_price_8)
                .setIn(['form', 'formValues', 'estimate_index_tab_price'], action.payload.estimate_index_tab_price)
                .setIn(['form', 'formValues', 'estimate_label_price'], action.payload.estimate_label_price)
                .setIn(['form', 'formValues', 'estimate_ncr_form_price'], action.payload.estimate_ncr_form_price)
                .setIn(['form', 'formValues', 'estimate_envelope_press_price'], action.payload.estimate_envelope_press_price)
                .setIn(['form', 'formValues', 'estimate_envelope_paper_price'], action.payload.estimate_envelope_paper_price);
            break;

        case actionTypes.SAVE_ESTIMATE:
            state = state.setIn(['estimate', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ESTIMATE_SUCCESS:
            state = state.setIn(['estimate', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ESTIMATE_FAILURE:
            state = state.setIn(['estimate', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ESTIMATE:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ESTIMATE_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ESTIMATE_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ESTIMATES:
            state = state.setIn(['information', 'estimates'], action.payload);
            break;

        case actionTypes.SET_ITEM_QUANTITY:
            state = state.setIn(['information', 'estimates'], action.payload);
            break;

        case actionTypes.SET_EDITING_CUSTOMER:
            state = state.setIn(['customer', 'editing'], action.payload);
            break;

        case actionTypes.CLEAR_RESULTS:
            state = state.setIn(['form', 'formValues', 'estimate_press_price'], 0)
                .setIn(['form', 'formValues', 'estimate_paper_price'], 0)
                .setIn(['form', 'formValues', 'estimate_ink_price'], 0)
                .setIn(['form', 'formValues', 'estimate_cutting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_binding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_folding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_digital_machine_price'], 0)
                .setIn(['form', 'formValues', 'estimate_large_format_machine_price'], 0)
                .setIn(['form', 'formValues', 'estimate_substrate_price'], 0)
                .setIn(['form', 'formValues', 'estimate_laminate_price'], 0)
                .setIn(['form', 'formValues', 'estimate_accessories_price'], 0)
                .setIn(['form', 'formValues', 'estimate_outside_services_price'], 0)
                .setIn(['form', 'formValues', 'estimate_scoring_price'], 0)
                .setIn(['form', 'formValues', 'estimate_die_cutting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_drilling_price'], 0)
                .setIn(['form', 'formValues', 'estimate_padding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_banding_price'], 0)
                .setIn(['form', 'formValues', 'estimate_wrapping_price'], 0)
                .setIn(['form', 'formValues', 'estimate_shrink_wrapping_price'], 0)
                .setIn(['form', 'formValues', 'estimate_package_price'], 0)
                .setIn(['form', 'formValues', 'estimate_hand_gathering_price'], 0)
                .setIn(['form', 'formValues', 'estimate_counting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_inkjet_price'], 0)
                .setIn(['form', 'formValues', 'estimate_inserting_price'], 0)
                .setIn(['form', 'formValues', 'estimate_perfect_match_price'], 0)
                .setIn(['form', 'formValues', 'estimate_art_price'], 0)
                .setIn(['form', 'formValues', 'estimate_handwork_price'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_1'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_2'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_3'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_4'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_5'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_6'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_7'], 0)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_8'], 0)
                .setIn(['form', 'formValues', 'estimate_index_tab_price'], 0)
                .setIn(['form', 'formValues', 'estimate_label_price'], 0)
                .setIn(['form', 'formValues', 'estimate_ncr_form_price'], 0)
                .setIn(['form', 'formValues', 'estimate_envelope_press_price'], 0)
                .setIn(['form', 'formValues', 'estimate_envelope_paper_price'], 0)
                .setIn(['form', 'formValues', 'estimate_notes'], '')
                .setIn(['form', 'formValues', 'estimate_customer_discount_id'], '')
                .setIn(['form', 'formValues', 'estimate_discount'], 0)
                .setIn(['form', 'formValues', 'estimate_discount_percentage'], 0)
                .setIn(['form', 'formValues', 'estimate_data_processing_elements'], [])
                .setIn(['form', 'formValues', 'estimate_inkjet_elements'], [])
                .setIn(['form', 'formValues', 'estimate_inserting_elements'], [])
                .setIn(['form', 'formValues', 'estimate_perfect_match_elements'], [])
                .setIn(['form', 'formValues', 'estimate_art_elements'], [])
                .setIn(['form', 'formValues', 'estimate_handwork_elements'], [])
                .setIn(['form', 'formValues', 'estimate_pressure_seal_elements'], [])
                .setIn(['form', 'formValues', 'estimate_index_tab_elements'], [])
                .setIn(['form', 'formValues', 'estimate_label_elements'], [])
                .setIn(['form', 'formValues', 'estimate_ncr_form_elements'], [])
                .setIn(['activeElement', 'product'], null)
                .setIn(['estimate', 'results'], []);

            if (parseInt(action.payload) === 1) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity'], 500);
            } else if (parseInt(action.payload) === 2) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity'], 500);
            } else if (parseInt(action.payload) === 3) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity'], 250);
            } else if (parseInt(action.payload) === 4) {
                state = state.setIn(['form', 'formValues', 'estimate_quantity'], 1);
            }

            break;

        case actionTypes.SET_PRODUCT:
            if (action.payload == null) {
                state = state.setIn(['activeElement', 'product'], null);
            } else {
                state = state.setIn(['form', 'formValues', 'estimate_type'], action.payload.type)
                    .setIn(['form', 'formValues', 'estimate_product_category_id'], action.payload.product_category_id)
                    .setIn(['form', 'formValues', 'estimate_bleed'], action.payload.bleed)
                    .setIn(['form', 'formValues', 'estimate_multi_page_type'], action.payload.multi_page_type)
                    .setIn(['form', 'formValues', 'estimate_product_description'], action.payload.name)
                    .setIn(['form', 'formValues', 'estimate_front_inks'], action.payload.front_inks)
                    .setIn(['form', 'formValues', 'estimate_front_coating'], action.payload.front_coating)
                    .setIn(['form', 'formValues', 'estimate_back_inks'], action.payload.back_inks)
                    .setIn(['form', 'formValues', 'estimate_back_coating'], action.payload.back_coating)
                    .setIn(['form', 'formValues', 'estimate_interior_inks'], action.payload.interior_inks)
                    .setIn(['form', 'formValues', 'estimate_interior_coating'], action.payload.interior_coating)
                    .setIn(['form', 'formValues', 'estimate_digital_inks'], action.payload.digital_inks)
                    .setIn(['form', 'formValues', 'estimate_digital_sides'], action.payload.digital_sides)
                    .setIn(['form', 'formValues', 'estimate_digital_machine_id'], action.payload.digital_machine_id)
                    .setIn(['form', 'formValues', 'estimate_binding_edge'], action.payload.binding_edge)
                    .setIn(['form', 'formValues', 'estimate_press_id'], action.payload.press_id)
                    .setIn(['form', 'formValues', 'estimate_second_press_id'], action.payload.second_press_id)
                    .setIn(['form', 'formValues', 'estimate_cover_press_id'], action.payload.cover_press_id)
                    .setIn(['form', 'formValues', 'estimate_pages'], action.payload.pages)
                    .setIn(['form', 'formValues', 'estimate_paper_category_id'], action.payload.paper_category_id)
                    .setIn(['form', 'formValues', 'estimate_paper_weight'], action.payload.paper_weight)
                    .setIn(['form', 'formValues', 'estimate_paper_id'], action.payload.paper_id)
                    .setIn(['form', 'formValues', 'estimate_cover_paper_category_id'], action.payload.cover_paper_category_id)
                    .setIn(['form', 'formValues', 'estimate_cover_paper_weight'], action.payload.cover_paper_weight)
                    .setIn(['form', 'formValues', 'estimate_cover_paper_id'], action.payload.cover_paper_id)
                    .setIn(['form', 'formValues', 'estimate_width'], action.payload.width)
                    .setIn(['form', 'formValues', 'estimate_height'], action.payload.height)
                    .setIn(['form', 'formValues', 'estimate_finish_width'], action.payload.finish_width)
                    .setIn(['form', 'formValues', 'estimate_finisyh_height'], action.payload.finish_height)
                    .setIn(['form', 'formValues', 'estimate_running_style'], action.payload.running_style)
                    .setIn(['form', 'formValues', 'estimate_second_running_style'], action.payload.second_running_style)
                    .setIn(['form', 'formValues', 'estimate_cover_running_style'], action.payload.cover_running_style)
                    .setIn(['form', 'formValues', 'estimate_large_format_machine_id'], action.payload.large_format_machine_id)
                    .setIn(['form', 'formValues', 'estimate_substrate_id'], action.payload.substrate_id)
                    .setIn(['form', 'formValues', 'estimate_laminate_id'], action.payload.laminate_id)
                    .setIn(['form', 'formValues', 'estimate_unit'], action.payload.unit)
                    .setIn(['form', 'formValues', 'estimate_fold_type_id'], action.payload.fold_type_id)
                    .setIn(['form', 'formValues', 'estimate_accessories'], action.payload.accessories_ids == null ? [] : JSON.parse(action.payload.accessories_ids))
                    .setIn(['form', 'formValues', 'estimate_outside_services'], [])
                    .setIn(['form', 'formValues', 'estimate_requires_scoring'], action.payload.requires_scoring)
                    .setIn(['form', 'formValues', 'estimate_requires_die_cutting'], action.payload.requires_die_cutting)
                    .setIn(['form', 'formValues', 'estimate_drilling_holes'], action.payload.drilling_holes)
                    .setIn(['form', 'formValues', 'estimate_requires_padding'], action.payload.requires_padding)
                    .setIn(['form', 'formValues', 'estimate_banding_type_id'], action.payload.banding_type_id)
                    .setIn(['form', 'formValues', 'estimate_wrapping_type_id'], action.payload.wrapping_type_id)
                    .setIn(['form', 'formValues', 'estimate_shrink_wrapping_type_id'], action.payload.shrink_wrapping_type_id)
                    .setIn(['form', 'formValues', 'estimate_package_id'], action.payload.package_id)
                    .setIn(['form', 'formValues', 'estimate_use_hand_gathering'], action.payload.use_hand_gathering)
                    .setIn(['form', 'formValues', 'estimate_use_counting'], action.payload.use_counting)
                    .setIn(['form', 'formValues', 'estimate_coil_option'], action.payload.coil_option)
                    .setIn(['form', 'formValues', 'estimate_coil_color'], action.payload.coil_color)
                    .setIn(['form', 'formValues', 'estimate_staples_number'], action.payload.staples_number)
                    .setIn(['form', 'formValues', 'estimate_staples_location'], action.payload.staples_location)
                    .setIn(['activeElement', 'product'], action.payload);
            }
            break;

        case actionTypes.SET_ACCESSORIES:
            state = state.setIn(['form', 'formValues', 'estimate_accessories'], action.payload);
            break;

        case actionTypes.UPLOAD_FILE:
            state = state.setIn(['uploadFile', 'isUploading'], true);
            break;

        case actionTypes.UPLOAD_FILE_SUCCESS:
            state = state.setIn(['uploadFile', 'isUploading'], false)
                .setIn(['uploadFile', 'files'], [])
                .setIn(['uploadFile', 'id'], null);
            break;

        case actionTypes.UPLOAD_FILE_FAILURE:
            state = state.setIn(['uploadFile', 'isUploading'], false);
            break;

        case actionTypes.SET_PROGRESS:
            state = state.setIn(['uploadFile', 'progress'], action.payload.progress);
            break;

        case actionTypes.SET_FILES:
            state = state.setIn(['uploadFile', 'files'], action.payload);
            break;

        case actionTypes.SET_UPLOADING_FILE:
            state = state.setIn(['uploadFile', 'isUploading'], action.payload);
            break;

        case actionTypes.CLEAR_FILES:
            state = state.setIn(['uploadFile', 'files'], [])
                .setIn(['uploadFile', 'filename'], '')
                .setIn(['uploadFile', 'id'], null);
            break;

        case actionTypes.SET_FILE_ESTIMATE_ID:
            state = state.setIn(['uploadFile', 'id'], action.payload);
            break;

        case actionTypes.DELETE_FILE:
            state = state.setIn(['uploadFile', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_FILE_SUCCESS:
            state = state.setIn(['uploadFile', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_FILE_FAILURE:
            state = state.setIn(['uploadFile', 'isDeleting'], false);
            break;

        case actionTypes.SED_FILE_ID_TO_DELETE:
            state = state.setIn(['uploadFile', 'idToDelete'], action.payload);
            break;

        case actionTypes.ADD_ESTIMATE_ARTWORK:
            state = state.setIn(['information', 'estimates', action.payload.index, 'estimateFiles'], action.payload.files);
            break;

        case actionTypes.DELETE_ESTIMATE_ARTWORK:
            state = state.setIn(['information', 'estimates', action.payload.index, 'estimateFiles'], action.payload.files);
            break;

        case actionTypes.SEND_TO_PRODUCTION:
            state = state.setIn(['order', 'isSendingToProduction'], true);
            break;

        case actionTypes.SEND_TO_PRODUCTION_SUCCESS:
            state = state.setIn(['order', 'isSendingToProduction'], false);
            break;

        case actionTypes.SEND_TO_PRODUCTION_FAILURE:
            state = state.setIn(['order', 'isSendingToProduction'], false);
            break;

        case actionTypes.ADD_OUTSIDE_SERVICE:
            state = state.updateIn(['form', 'formValues', 'estimate_outside_services'], (outsideServices) => outsideServices.push(action.payload));
            break;

        case actionTypes.DELETE_OUTSIDE_SERVICE:
            state = state.updateIn(['form', 'formValues', 'estimate_outside_services'], (outsideServices) => outsideServices.delete(action.payload));
            break;

        case actionTypes.UPDATE_OUTSIDE_SERVICE:
            state = state.updateIn(['form', 'formValues', 'estimate_outside_services'], (outsideServices) => outsideServices.set(action.payload.index, action.payload.value));
            break;

        case actionTypes.SET_OPTIONS:
            state = state.updateIn(['options', action.payload.name], (data) => Immutable.List(action.payload.data));
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CALCULATE_SHIPPING:
            state = state.setIn(['shipping', 'isCalculating'], true);
            break;

        case actionTypes.CALCULATE_SHIPPING_SUCCESS:
            state = state.setIn(['shipping', 'isCalculating'], false)
                .setIn(['shipping', 'total'], action.payload.total)
                .setIn(['shipping', 'prices'], action.payload.prices);
            break;

        case actionTypes.CALCULATE_SHIPPING_FAILURE:
            state = state.setIn(['shipping', 'isCalculating'], false);
            break;

        case actionTypes.SET_LOADING:
            state = state.setIn(['fetching', action.payload.name], action.payload.value);
            break;

        case actionTypes.RESET_OPTIONS:
            state = state.setIn(['options', action.payload], []);
            break;

        case actionTypes.UPDATE_STATE:
            if (action.payload.formValues) {
                state = state.updateIn(['form', 'formValues'], (formValues) => {
                    for (let [element, value] of Object.entries(action.payload.formValues)) {
                        formValues = formValues.set(element, value);
                    }
                    return formValues;
                });
            }
            if (action.payload.options) {
                state = state.updateIn(['options'], (options) => {
                    for (let [element, value] of Object.entries(action.payload.options)) {
                        options = options.set(element, value);
                    }
                    return options;
                });
            }
            if (action.payload.fetching) {
                state = state.updateIn(['fetching'], (fetching) => {
                    for (let [element, value] of Object.entries(action.payload.fetching)) {
                        fetching = fetching.set(element, value);
                    }
                    return fetching;
                });
            }
            if (action.payload.information) {
                state = state.updateIn(['information'], (information) => {
                    for (let [element, value] of Object.entries(action.payload.information)) {
                        information = information.set(element, value);
                    }
                    return information;
                });
            }
            break;

        case actionTypes.FETCH_ESTIMATE:
            state = state.setIn(['fetching', 'estimate'], true);
            break;

        case actionTypes.FETCH_ESTIMATE_SUCCESS:
            break;

        case actionTypes.FETCH_ESTIMATE_FAILURE:
            state = state.setIn(['fetching', 'estimate'], false);
            break;

        case actionTypes.UPDATE_ESTIMATE_STATUS:
            state = state.updateIn(['information', 'estimates'], (estimates) => {
                let estimate = estimates.get(action.payload.index);
                estimate = estimate.setIn(['status_id'], action.payload.value);
                return estimates.set(action.payload.index, estimate);
            });
            break;

        case actionTypes.UPDATE_ESTIMATE_HAVE_ARTWORKS:
            state = state.updateIn(['information', 'estimates'], (estimates) => {
                let estimate = estimates.get(action.payload.index);
                estimate = estimate.setIn(['have_artworks'], action.payload.value);
                return estimates.set(action.payload.index, estimate);
            });
            break;

        case actionTypes.ADD_MAILING_ELEMENT:
            state = state.updateIn(['form', 'formValues', action.payload.name], (elements) => elements.push(action.payload.value));
            break;

        case actionTypes.UPDATE_MAILING_ELEMENT:
            state = state.updateIn(['form', 'formValues', action.payload.name], (elements) => {
                const element = elements.get(action.payload.index);
                return elements.set(action.payload.index, element.setIn([action.payload.attribute], action.payload.value));
            });
            break;

        case actionTypes.REMOVE_MAILING_ELEMENT:
            state = state.updateIn(['form', 'formValues', action.payload.name], (elements) => elements.delete(action.payload.index));
            break;

        case actionTypes.UPDATE_MAILING_PRICES:
            state = state.setIn(['form', 'formValues', 'estimate_data_processing_price_1'], action.payload.estimate_data_processing_price_1)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_2'], action.payload.estimate_data_processing_price_2)
                .setIn(['form', 'formValues', 'estimate_data_processing_price_3'], action.payload.estimate_data_processing_price_3)
                .setIn(['form', 'formValues', 'estimate_inkjet_price'], action.payload.estimate_inkjet_price)
                .setIn(['form', 'formValues', 'estimate_perfect_match_price'], action.payload.estimate_perfect_match_price)
                .setIn(['form', 'formValues', 'estimate_inserting_price'], action.payload.estimate_inserting_price)
                .setIn(['form', 'formValues', 'estimate_art_price'], action.payload.estimate_art_price)
                .setIn(['form', 'formValues', 'estimate_handwork_price'], action.payload.estimate_handwork_price)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_1'], action.payload.estimate_pressure_seal_price_1)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_2'], action.payload.estimate_pressure_seal_price_2)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_3'], action.payload.estimate_pressure_seal_price_3)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_4'], action.payload.estimate_pressure_seal_price_4)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_5'], action.payload.estimate_pressure_seal_price_5)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_6'], action.payload.estimate_pressure_seal_price_6)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_7'], action.payload.estimate_pressure_seal_price_7)
                .setIn(['form', 'formValues', 'estimate_pressure_seal_price_8'], action.payload.estimate_pressure_seal_price_8)
                .setIn(['form', 'formValues', 'estimate_index_tab_price'], action.payload.estimate_index_tab_price)
                .setIn(['form', 'formValues', 'estimate_label_price'], action.payload.estimate_label_price)
                .setIn(['form', 'formValues', 'estimate_ncr_form_price'], action.payload.estimate_ncr_form_price)
                .setIn(['form', 'formValues', 'estimate_outside_services_price'], action.payload.estimate_outside_services_price)
                .setIn(['form', 'formValues', 'estimate_discount'], action.payload.estimate_discount)
                .setIn(['form', 'formValues', 'estimate_discount_percentage'], action.payload.estimate_discount_percentage);
            break;

        case actionTypes.SET_HISTORY:
            state = state.setIn(['history'], action.payload);
            break;

        case actionTypes.FETCH_CUSTOMERS:
            state = state.setIn(['fetching', 'customers'], true)
                .setIn(['information', 'customers'], []);
            break;

        case actionTypes.FETCH_CUSTOMERS_SUCCESS:
            state = state.setIn(['fetching', 'customers'], false)
                .setIn(['information', 'customers'], action.payload);
            break;

        case actionTypes.FETCH_CUSTOMERS_FAILURE:
            state = state.setIn(['fetching', 'customers'], false);
            break;

        case actionTypes.FETCH_CUSTOMER:
            state = state.setIn(['fetching', 'customer'], true)
                .setIn(['customer', 'data'], null);
            break;

        case actionTypes.FETCH_CUSTOMER_SUCCESS:
            state = state.setIn(['fetching', 'customer'], false)
                .setIn(['customer', 'data'], action.payload);
            break;

        case actionTypes.FETCH_CUSTOMER_FAILURE:
            state = state.setIn(['fetching', 'customer'], false);
            break;

        case actionTypes.FETCH_ESTIMATE_INFORMATION:
            state = state.setIn(['information', 'isFetchingEstimate'], true)
                .setIn(['information', 'presses'], [])
                .setIn(['information', 'customerDiscounts'], [])
                .setIn(['information', 'industries'], [])
                .setIn(['information', 'products'], [])
                .setIn(['information', 'largeFormatMachines'], [])
                .setIn(['information', 'substrates'], [])
                .setIn(['information', 'laminates'], [])
                .setIn(['information', 'accessories'], [])
                .setIn(['information', 'inks'], []);
            break;

        case actionTypes.FETCH_ESTIMATE_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetchingEstimate'], false)
                .setIn(['information', 'presses'], action.payload.data.presses)
                .setIn(['information', 'customerDiscounts'], action.payload.data.customerDiscounts)
                .setIn(['information', 'foldTypes'], action.payload.data.foldTypes)
                .setIn(['information', 'products'], action.payload.data.products)
                .setIn(['information', 'largeFormatMachines'], action.payload.data.largeFormatMachines)
                .setIn(['information', 'substrates'], action.payload.data.substrates)
                .setIn(['information', 'laminates'], action.payload.data.laminates)
                .setIn(['information', 'accessories'], action.payload.data.accessories)
                .setIn(['information', 'inks'], action.payload.data.inks);
            break;

        case actionTypes.FETCH_ESTIMATE_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetchingEstimate'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
