import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

class DialogComponent extends Component {
    static propTypes = {
        open: PropTypes.bool,
        modal: PropTypes.bool,
        title: PropTypes.string,
        actions: PropTypes.array,
        onRequestClose: PropTypes.func,
        children: PropTypes.object,
        actionsContainerStyle: PropTypes.object,
        contentStyle: PropTypes.object,
        bodyStyle: PropTypes.object,
        style: PropTypes.object
    };

    render() {
        const { open, actions, onRequestClose, className } = this.props;
        return (
            <Modal show={open} onHide={onRequestClose} size="lg" className={className}>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    {actions}
                </Modal.Footer>
            </Modal>
        );
    }
}

DialogComponent.childContextTypes = {
    muiTheme: PropTypes.object.isRequired
};

export default DialogComponent;
