import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { stringifyEqualityCheck } from './../../utils';
import Calculator from './Calculator';
import SaveInvoiceModal from './SaveInvoiceModal';
import * as actions from './DptCalculator.actions';
import * as selectors from './DptCalculator.selectors';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import AdminHeader from './../common/components/AdminHeader';
import Tabs from './../common/components/Tabs';
import * as loginSelectors from './../Login/Login.selectors';
import './DptCalculator.scss';
import * as dialog from '../common/dialog';

const DptCalculator = (props) => {
    /* State to props */
    const data = useSelector(selectors.getData, stringifyEqualityCheck);
    const isFetchingData = useSelector(selectors.getIsFetchingData);
    const show = useSelector(selectors.getShow, stringifyEqualityCheck);
    const summary = useSelector(selectors.getSummary, stringifyEqualityCheck);
    const accounting = useSelector(selectors.getAccounting, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchData = useCallback(() => dispatch(actions.fetchData()), [dispatch]);
    const checkElement = useCallback((name, id, value) => dispatch(actions.checkElement(name, id, value)), [dispatch]);
    const setQuantity = useCallback((name, id, value) => dispatch(actions.setQuantity(name, id, value)), [dispatch]);
    const setInchesLong = useCallback((name, id, value) => dispatch(actions.setInchesLong(name, id, value)), [dispatch]);
    const setWaferSeals = useCallback((name, id, value) => dispatch(actions.setWaferSeals(name, id, value)), [dispatch]);
    const setInserts = useCallback((name, id, value) => dispatch(actions.setInserts(name, id, value)), [dispatch]);
    const setShow = useCallback((name, value) => dispatch(actions.setShow(name, value)), [dispatch]);
    const toggleShow = useCallback((name) => dispatch(actions.toggleShow(name)), [dispatch]);
    const setDepartment = useCallback((name, value) => dispatch(actions.setDepartment(name, value)), [dispatch]);
    const setDepartmentElement = useCallback((name, id, value) => dispatch(actions.setDepartmentElement(name, id, value)), [dispatch]);
    const setEntireDepartment = useCallback((name, showName, indexes, values) => dispatch(actions.setEntireDepartment(name, showName, indexes, values)), [dispatch]);
    const replaceDepartment = useCallback((name, showName, value) => dispatch(actions.replaceDepartment(name, showName, value)), [dispatch]);
    const resetData = useCallback(() => dispatch(actions.resetData()), [dispatch]);
    const hideAll = useCallback(() => dispatch(actions.hideAll()), [dispatch]);
    const setSummaryElement = useCallback((departmentName, id, name, quantity, totalTime, tooltipInfo, department) => dispatch(actions.setSummaryElement(departmentName, id, name, quantity, totalTime, tooltipInfo, department)), [dispatch]);
    const removeSummaryElement = useCallback((departmentName, id) => dispatch(actions.removeSummaryElement(departmentName, id)), [dispatch]);
    const setDifficultyLevelDp = useCallback((id, name, totalTime, tooltipInfo) => dispatch(actions.setDifficultyLevelDp(id, name, totalTime, tooltipInfo)), [dispatch]);
    const setPresortDp = useCallback((id, name, totalTime, tooltipInfo) => dispatch(actions.setPresortDp(id, name, totalTime, tooltipInfo)), [dispatch]);
    const resetSummaryDepartments = useCallback((departments) => dispatch(actions.resetSummaryDepartments(departments)), [dispatch]);
    const resetDepartment = useCallback((departmentName) => dispatch(actions.resetDepartment(departmentName)), [dispatch]);
    const resetEstimateDepartment = useCallback((departmentName) => dispatch(actions.resetEstimateDepartment(departmentName)), [dispatch]);
    const setDepartmentMainQuantity = useCallback((department, quantity, ids) => dispatch(actions.setDepartmentMainQuantity(department, quantity, ids)), [dispatch]);
    const changeAccountField = useCallback((department, field, value) => dispatch(actions.changeAccountField(department, field, value)), [dispatch]);
    const changeAccountItem = useCallback((department, index, field, value) => dispatch(actions.changeAccountItem(department, index, field, value)), [dispatch]);
    const addAccountItem = useCallback((department) => dispatch(actions.addAccountItem(department)), [dispatch]);
    const removeAccountItem = useCallback((department, index) => dispatch(actions.removeAccountItem(department, index)), [dispatch]);
    const showSaveInvoiceModal = useCallback(() => dispatch(dialog.actions.showDialog('save-invoice-modal')), [dispatch]);
    const hideSaveInvoiceModal = useCallback(() => dispatch(dialog.actions.hideDialog('save-invoice-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Layout
            title={'DPT Calculator'}
            history={props.history}
        >
            <div id="dpt-calculator-container" className="container-fluid">
                <Helmet>
                    <title>Departmental Processing Time Calculator</title>
                    <meta name="description" content="" />
                </Helmet>
                <AdminHeader
                    title="Departmental Processing Time Calculator"
                />
                <div className="row">
                    <div className="col">
                        <Tabs
                            user={user}
                        />
                        <Calculator
                            data={data}
                            isFetchingData={isFetchingData}
                            show={show}
                            summary={summary}
                            accounting={accounting}
                            checkElement={checkElement}
                            setQuantity={setQuantity}
                            setInchesLong={setInchesLong}
                            setWaferSeals={setWaferSeals}
                            setInserts={setInserts}
                            setShow={setShow}
                            setDepartment={setDepartment}
                            setDepartmentElement={setDepartmentElement}
                            setEntireDepartment={setEntireDepartment}
                            replaceDepartment={replaceDepartment}
                            resetData={resetData}
                            fetchData={fetchData}
                            hideAll={hideAll}
                            setSummaryElement={setSummaryElement}
                            removeSummaryElement={removeSummaryElement}
                            setDifficultyLevelDp={setDifficultyLevelDp}
                            setPresortDp={setPresortDp}
                            resetSummaryDepartments={resetSummaryDepartments}
                            toggleShow={toggleShow}
                            resetDepartment={resetDepartment}
                            resetEstimateDepartment={resetEstimateDepartment}
                            setDepartmentMainQuantity={setDepartmentMainQuantity}
                            changeAccountField={changeAccountField}
                            changeAccountItem={changeAccountItem}
                            addAccountItem={addAccountItem}
                            removeAccountItem={removeAccountItem}
                            showSaveInvoiceModal={showSaveInvoiceModal}
                        />
                    </div>
                </div>
            </div>
            <SaveInvoiceModal
                hideSaveInvoiceModal={hideSaveInvoiceModal}
                accounting={accounting}
            />
        </Layout>
    );
};

DptCalculator.propTypes = {

};

export default DptCalculator;
