import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FlatConfiguration extends Component {
    static propTypes = {
        configuration: PropTypes.array,
        hideConfiguration: PropTypes.func,
        handleUpdateConfiguration: PropTypes.func,
        isSavingForm: PropTypes.bool,
        handleSaveElement: PropTypes.func,
        handleAddConfiguration: PropTypes.func,
        handleDeleteConfiguration: PropTypes.func
    };

    renderProductCategories(type) {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [<option value="">Select Option</option>];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === type) {
                selectOptions.push(<option value={options['productCategories'][i]['value']}>{options['productCategories'][i]['label']}</option>);
            }
        }

        let show = configurationJson['product_options']['product_category_id']['show'];
        let value = configurationJson['product_options']['product_category_id']['default'];
        let lock = configurationJson['product_options']['product_category_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'product_category_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'product_category_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'product_category_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="product-category">Product Category:</label></div>
                <select id="product-category" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderProductDescription() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_description']['show'];
        let value = configurationJson['product_description']['default'];
        let lock = configurationJson['product_description']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_description', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_description', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_description', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="product-description">Product Description:</label></div>
                <input id="product-description" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPresses() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['presses'].length; i++) {
            selectOptions.push(<option value={options['presses'][i]['value']}>{options['presses'][i]['label']}</option>);
        }

        let show = configurationJson['product_options']['press_id']['show'];
        let value = configurationJson['product_options']['press_id']['default'];
        let lock = configurationJson['product_options']['press_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'press_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'press_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'press_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="press-id">Press:</label></div>
                <select id="press-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderRunningStyle() {
        const { configurationJson, handleUpdateConfiguration, information } = this.props;

        let selectOptions = [<option value="">Select Option</option>];
        if (configurationJson['product_options']['press_id']['default'] !== '') {
            for (let i = 0; i < information['presses'].length; i++) {
                const press = information['presses'][i];
                if (press['id'] === configurationJson['product_options']['press_id']['default']) {
                    if (parseInt(press['is_perfecting'])) {
                        selectOptions.push(<option value="Perfecting">Perfecting</option>);
                    } else {
                        selectOptions.push(<option value="SW">SheetWise</option>);
                        selectOptions.push(<option value="WT">Work and Turn</option>);
                    }
                }
            }
        }

        let show = configurationJson['product_options']['running_style']['show'];
        let value = configurationJson['product_options']['running_style']['default'];
        let lock = configurationJson['product_options']['running_style']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'running_style', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'running_style', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'running_style', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="running-style">Running Style:</label></div>
                <select id="running-style" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFlatWidth() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['flat_width']['show'];
        let value = configurationJson['product_options']['flat_width']['default'];
        let lock = configurationJson['product_options']['flat_width']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'flat_width', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'flat_width', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'flat_width', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="flat-width">Flat Width:</label></div>
                <input id="flat-width" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFlatHeight() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['flat_height']['show'];
        let value = configurationJson['product_options']['flat_height']['default'];
        let lock = configurationJson['product_options']['flat_height']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'flat_height', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'flat_height', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'flat_height', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="flat-height">Flat Height:</label></div>
                <input id="flat-height" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFinishWidth() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['finish_width']['show'];
        let value = configurationJson['product_options']['finish_width']['default'];
        let lock = configurationJson['product_options']['finish_width']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'finish_width', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'finish_width', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'finish_width', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="finish-width">Finish Width:</label></div>
                <input id="finish-width" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFinishHeight() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['finish_height']['show'];
        let value = configurationJson['product_options']['finish_height']['default'];
        let lock = configurationJson['product_options']['finish_height']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'finish_height', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'finish_height', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'finish_height', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="finish-height">Finish Height:</label></div>
                <input id="finish-height" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFrontInks() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['front_inks']['show'];
        let value = configurationJson['product_options']['front_inks']['default'];
        let lock = configurationJson['product_options']['front_inks']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'front_inks', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'front_inks', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'front_inks', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="front-inks">Front Inks:</label></div>
                <select id="front-inks" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderBackInks() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['back_inks']['show'];
        let value = configurationJson['product_options']['back_inks']['default'];
        let lock = configurationJson['product_options']['back_inks']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'back_inks', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'back_inks', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'back_inks', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="back-inks">Back Inks:</label></div>
                <select id="back-inks" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderBleed() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['bleed']['show'];
        let value = configurationJson['product_options']['bleed']['default'];
        let lock = configurationJson['product_options']['bleed']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'bleed', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'bleed', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'bleed', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="bleed">Bleed:</label></div>
                <select id="bleed" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="0">None</option>
                    <option value="0.125">1/8</option>
                    <option value="0.25">1/4</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPaperCategories() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['paperCategories'].length; i++) {
            selectOptions.push(<option value={options['paperCategories'][i]['value']}>{options['paperCategories'][i]['label']}</option>);
        }

        let show = configurationJson['product_options']['paper_category_id']['show'];
        let value = configurationJson['product_options']['paper_category_id']['default'];
        let lock = configurationJson['product_options']['paper_category_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'paper_category_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'paper_category_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'paper_category_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="paper-category-id">Paper Category:</label></div>
                <select id="paper-category-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPaperWeights() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['paperWeights'].length; i++) {
            selectOptions.push(<option value={options['paperWeights'][i]['value']}>{options['paperWeights'][i]['label']}</option>);
        }

        let show = configurationJson['product_options']['paper_weight']['show'];
        let value = configurationJson['product_options']['paper_weight']['default'];
        let lock = configurationJson['product_options']['paper_weight']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'paper_weight', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'paper_weight', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'paper_weight', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="paper-weight">Paper Weight:</label></div>
                <select id="paper-weight" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPapers() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        if (configurationJson['product_options']['front_coating']['default'] !== '' && configurationJson['product_options']['back_coating']['default'] !== '') {
            for (let i = 0; i < options['papers'].length; i++) {
                if (options['papers'][i]['coating_one_side'] === 0) {
                    selectOptions.push(options['papers'][i]);
                }
            }
        } else {
            for (let i = 0; i < options['papers'].length; i++) {
                selectOptions.push(<option value={options['papers'][i]['value']}>{options['papers'][i]['label']}</option>);
            }
        }

        let show = configurationJson['product_options']['paper_id']['show'];
        let value = configurationJson['product_options']['paper_id']['default'];
        let lock = configurationJson['product_options']['paper_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'paper_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'paper_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'paper_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="paper-id">Paper:</label></div>
                <select id="paper-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoatingFront() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['inks'].length; i++) {
            selectOptions.push(<option value={options['inks'][i]['value']}>{options['inks'][i]['label']}</option>);
        }

        let show = configurationJson['product_options']['front_coating']['show'];
        let value = configurationJson['product_options']['front_coating']['default'];
        let lock = configurationJson['product_options']['front_coating']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'front_coating', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'front_coating', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'front_coating', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="front-coating">Coating Front:</label></div>
                <select id="front-coating" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoatingBack() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['inks'].length; i++) {
            selectOptions.push(<option value={options['inks'][i]['value']}>{options['inks'][i]['label']}</option>);
        }

        let show = configurationJson['product_options']['back_coating']['show'];
        let value = configurationJson['product_options']['back_coating']['default'];
        let lock = configurationJson['product_options']['back_coating']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'back_coating', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'back_coating', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'back_coating', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="back-coating">Coating Back:</label></div>
                <select id="back-coating" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFoldTypes() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['foldTypes'].length; i++) {
            selectOptions.push(<option value={options['foldTypes'][i]['value']}>{options['foldTypes'][i]['label']}</option>);
        }

        let show = configurationJson['bindery_options']['fold_type_id']['show'];
        let value = configurationJson['bindery_options']['fold_type_id']['default'];
        let lock = configurationJson['bindery_options']['fold_type_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'fold_type_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'fold_type_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'fold_type_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="fold-type-id">Fold Type:</label></div>
                <select id="fold-type-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderScoring() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['bindery_options']['requires_scoring']['show'];
        let value = configurationJson['bindery_options']['requires_scoring']['default'];
        let lock = configurationJson['bindery_options']['requires_scoring']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'requires_scoring', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'requires_scoring', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'requires_scoring', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="requires-scoring">Scoring:</label></div>
                <select id="requires-scoring" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderDrillingHoles() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['bindery_options']['drilling_holes']['show'];
        let value = configurationJson['bindery_options']['drilling_holes']['default'];
        let lock = configurationJson['bindery_options']['drilling_holes']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'drilling_holes', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'drilling_holes', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'drilling_holes', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="drilling-holes">Drilling Holes:</label></div>
                <select id="drilling-holes" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="0">None</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderShrinkWrappingTypes() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['shrinkWrappingTypes'].length; i++) {
            selectOptions.push(<option value={options['shrinkWrappingTypes'][i]['value']}>{options['shrinkWrappingTypes'][i]['label']}</option>);
        }

        let show = configurationJson['bindery_options']['shrink_wrapping_type_id']['show'];
        let value = configurationJson['bindery_options']['shrink_wrapping_type_id']['default'];
        let lock = configurationJson['bindery_options']['shrink_wrapping_type_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'shrink_wrapping_type_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'shrink_wrapping_type_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'shrink_wrapping_type_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="shrink-wrapping-type-id">Shrink-Wrapping:</label></div>
                <select id="shrink-wrapping-type-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPadding() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['bindery_options']['requires_padding']['show'];
        let value = configurationJson['bindery_options']['requires_padding']['default'];
        let lock = configurationJson['bindery_options']['requires_padding']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'requires_padding', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'requires_padding', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'requires_padding', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="requires-padding">Padding:</label></div>
                <select id="requires-padding" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderDieCutting() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['bindery_options']['requires_die_cutting']['show'];
        let value = configurationJson['bindery_options']['requires_die_cutting']['default'];
        let lock = configurationJson['bindery_options']['requires_die_cutting']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'requires_die_cutting', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'requires_die_cutting', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'requires_die_cutting', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="requires-die-cutting">Die-Cutting:</label></div>
                <select id="requires-die-cutting" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderSheetsToPad() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['bindery_options']['sheets_to_pad']['show'];
        let value = configurationJson['bindery_options']['sheets_to_pad']['default'];
        let lock = configurationJson['bindery_options']['sheets_to_pad']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'sheets_to_pad', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'sheets_to_pad', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'sheets_to_pad', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="requires-sheets-to-pad">Sheets To Pad:</label></div>
                <select id="requires-sheets-to-pad" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderBandingTypes() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['bandingTypes'].length; i++) {
            selectOptions.push(<option value={options['bandingTypes'][i]['value']}>{options['bandingTypes'][i]['label']}</option>);
        }

        let show = configurationJson['handling_options']['banding_type_id']['show'];
        let value = configurationJson['handling_options']['banding_type_id']['default'];
        let lock = configurationJson['handling_options']['banding_type_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'banding_type_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'banding_type_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'banding_type_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="banding-type-id">Banding Type:</label></div>
                <select id="banding-type-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderWrappingTypes() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['wrappingTypes'].length; i++) {
            selectOptions.push(<option value={options['wrappingTypes'][i]['value']}>{options['wrappingTypes'][i]['label']}</option>);
        }

        let show = configurationJson['handling_options']['wrapping_type_id']['show'];
        let value = configurationJson['handling_options']['wrapping_type_id']['default'];
        let lock = configurationJson['handling_options']['wrapping_type_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'wrapping_type_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'wrapping_type_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'wrapping_type_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="wrapping-type-id">Wrapping Type:</label></div>
                <select id="wrapping-type-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPackages() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['packages'].length; i++) {
            selectOptions.push(<option value={options['packages'][i]['value']}>{options['packages'][i]['label']}</option>);
        }

        let show = configurationJson['handling_options']['package_id']['show'];
        let value = configurationJson['handling_options']['package_id']['default'];
        let lock = configurationJson['handling_options']['package_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'package_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'package_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'package_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="package-id">Package:</label></div>
                <select id="package-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderHandGathering() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['handling_options']['use_hand_gathering']['show'];
        let value = configurationJson['handling_options']['use_hand_gathering']['default'];
        let lock = configurationJson['handling_options']['use_hand_gathering']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'use_hand_gathering', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'use_hand_gathering', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'use_hand_gathering', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="use-hand-gathering">Die-Cutting:</label></div>
                <select id="use-hand-gathering" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderProductionNotes() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['production_notes']['show'];
        let value = configurationJson['production_notes']['default'];
        let lock = configurationJson['production_notes']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['production_notes', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['production_notes', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['production_notes', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="production-notes">Production Notes:</label></div>
                <input id="production-notes" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCustomerDiscounts() {
        const { configurationJson, handleUpdateConfiguration, options } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['customerDiscounts'].length; i++) {
            selectOptions.push(<option value={options['customerDiscounts'][i]['value']}>{options['customerDiscounts'][i]['label']}</option>);
        }

        let show = 1;
        let value = '';
        let lock = false;
        if (configurationJson['customer_discount']) {
            show = configurationJson['customer_discount']['show'];
            value = configurationJson['customer_discount']['default'];
            lock = configurationJson['customer_discount']['lock'];
        }
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['customer_discount', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['customer_discount', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['customer_discount', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="package-id">Discount Table:</label></div>
                <select id="package-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    render() {
        const { handleSaveElement, isSavingForm, activeElement, setEditing, configurationJson } = this.props;

        if (configurationJson == null) {
            return (
                <div id="tables-container" className="production-container">
                    <div id="configuration-container" className="table-container table-hover">
                        <div className="form-container" style={{ maxHeight: 'none' }}>
                            <i className="fas fa-circle-notch fa-spin"></i> Loading...
                        </div>
                    </div>
                </div>
            );
        }

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let data = (
            <div>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col"><h4 className="mt-1 mb-2">{activeElement.name}</h4></div>
                            <div className="w-100"></div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Product Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderProductCategories(1)}
                                                <div className="w-100"></div>
                                                {this.renderProductDescription()}
                                                <div className="w-100"></div>
                                                {this.renderPresses()}
                                                <div className="w-100"></div>
                                                {this.renderRunningStyle()}
                                                <div className="w-100"></div>
                                                {this.renderFlatWidth()}
                                                <div className="w-100"></div>
                                                {this.renderFlatHeight()}
                                                <div className="w-100"></div>
                                                {this.renderFinishWidth()}
                                                <div className="w-100"></div>
                                                {this.renderFinishHeight()}
                                                <div className="w-100"></div>
                                                {this.renderFrontInks()}
                                                <div className="w-100"></div>
                                                {this.renderBackInks()}
                                                <div className="w-100"></div>
                                                {this.renderBleed()}
                                                <div className="w-100"></div>
                                                {this.renderPaperCategories()}
                                                <div className="w-100"></div>
                                                {this.renderPaperWeights()}
                                                <div className="w-100"></div>
                                                {this.renderPapers()}
                                                <div className="w-100"></div>
                                                {this.renderCoatingFront()}
                                                <div className="w-100"></div>
                                                {this.renderCoatingBack()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Bindery Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderFoldTypes()}
                                                <div className="w-100"></div>
                                                {this.renderScoring()}
                                                <div className="w-100"></div>
                                                {this.renderDrillingHoles()}
                                                <div className="w-100"></div>
                                                {this.renderShrinkWrappingTypes()}
                                                <div className="w-100"></div>
                                                {this.renderPadding()}
                                                <div className="w-100"></div>
                                                {this.renderSheetsToPad()}
                                                <div className="w-100"></div>
                                                {this.renderDieCutting()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Handling Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderBandingTypes()}
                                                <div className="w-100"></div>
                                                {this.renderWrappingTypes()}
                                                <div className="w-100"></div>
                                                {this.renderPackages()}
                                                <div className="w-100"></div>
                                                {this.renderHandGathering()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header">
                                        Other Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderProductionNotes()}
                                                <div className="w-100"></div>
                                                {this.renderCustomerDiscounts()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div>
                    <button key="save-button" className="btn btn-light btn-sm action-button"
                        onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>
                    <button key="close-button" className="btn btn-light btn-sm action-button-cancel"
                        onClick={setEditing.bind(this, false)} disabled={isSavingForm}>Close</button>
                </div>
            </div>
        );

        return (
            <div id="tables-container" className="production-container">
                <div id="configuration-container" className="table-container table-hover">
                    <div className="form-container" style={{ maxHeight: 'none' }}>{data}</div>
                </div>
            </div>
        );
    }
}

export default FlatConfiguration;
