import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class DifficultyTable extends Component {
    static propTypes = {
        difficultyTable: PropTypes.array,
        hideDifficultyTable: PropTypes.func,
        handleUpdateDifficultyTable: PropTypes.func,
        isSavingDifficultyTable: PropTypes.bool,
        handleSaveDifficultyTable: PropTypes.func,
        handleAddDifficultyTable: PropTypes.func,
        handleDeleteDifficultyTable: PropTypes.func
    };

    renderData() {
        const { difficultyTable, handleUpdateDifficultyTable, handleDeleteDifficultyTable, information } = this.props;

        let options = [<option value="">Select option</option>];
        for (let i = 0; i < information['envelopeTypes'].length; i++) {
            options.push(<option value={information['envelopeTypes'][i]['id']}>{information['envelopeTypes'][i]['name']}</option>);
            
        }

        return difficultyTable.map((speed, index) => {
            return (
                <tr key={index}>
                    <td>
                        <select value={speed.envelope_type_id} onChange={handleUpdateDifficultyTable.bind(this, index, 'envelope_type_id')}>
                            {options}
                        </select>
                    </td>
                    <td>
                        <input type="text" value={speed.markup}
                            onChange={handleUpdateDifficultyTable.bind(this, index, 'markup')} />
                    </td>
                    <td>
                        <div className="action-icon">
                            <i className="fas fa-trash" onClick={handleDeleteDifficultyTable.bind(this, index)} />
                        </div>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { hideDifficultyTable, handleSaveDifficultyTable, isSavingDifficultyTable, handleAddDifficultyTable, isFetchingElement } = this.props;

        let data = (
            <div>
                <table className="table table-sm table-striped data-table">
                    <thead>
                        <tr>
                            <th>Envelope Type</th>
                            <th>Difficulty</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                </table>
                <div className="add-button" onClick={handleAddDifficultyTable} style={{ width: 120, fontSize: 13 }}><i className="fas fa-plus" /> Add Row</div>
            </div>
        );
        if (isFetchingElement) {
            data = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let saveButtonLabel = 'Save';
        if (isSavingDifficultyTable) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveDifficultyTable} disabled={isSavingDifficultyTable}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideDifficultyTable}>Close</button>
        ];
        return (
            <Dialog
                name="difficulty-table"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default DifficultyTable;
