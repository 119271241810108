import React from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const InsertingForm = (props) => {
    const checkElementHandler = (id, event) => {
        props.checkElement('dptInserting', id, event.target.checked ? 1 : 0);

        if (!event.target.checked) {
            props.removeSummaryElement('dptInserting', id);
            props.setQuantity('dptInserting', id, '');
            props.setInserts('dptInserting', id, '');
        }
    };

    const validateIsReady = (id, quantity, inserts) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element.checked || !quantity || !inserts) {
            props.removeSummaryElement('dptInserting', id);
            return;
        }

        let pm = 0;
        if (parseInt(inserts) === 1) {
            pm = element.prodution_rate_1_insert / 60;
        } else if (parseInt(inserts) === 2) {
            pm = element.prodution_rate_2_insert / 60;
        } else if (parseInt(inserts) > 2) {
            pm = element.prodution_rate_2_insert / 60;
            for (let i = 0; i < inserts - 2; i++) {
                pm *= (1 - element.rate_drop_per_extra_insert);
            }
        }

        const timeInMins = quantity / pm + element.setup_time;

        const tooltipInfo = (
            <div className="text-left">
                <div>{numeral(element.prodution_rate_1_insert).format('0,0')} per hour 1 insert.</div>
                <div>{numeral(element.prodution_rate_2_insert).format('0,0')} per hour 2 inserts.</div>
                <div>After 2 inserts, production rate drops {numeral(element.rate_drop_per_extra_insert).format('0.[00]%')} per additional insert.</div>
                <div>Setup time: {element.setup_time} minutes.</div>
            </div>
        );

        props.setSummaryElement('dptInserting', id, element.size, quantity, timeInMins, tooltipInfo);
    };

    const setInsertsHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setInserts('dptInserting', id, value);

        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        validateIsReady(id, element.selected_quantity, value);
    };

    const setQuantityHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setQuantity('dptInserting', id, value);

        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        validateIsReady(id, value, element.inserts);
    };

    const renderElement = (id) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element) {
            return null;
        }

        let value = '';
        if (element.selected_quantity) {
            value = element.selected_quantity;
        }

        let inserts = '';
        if (element.inserts) {
            inserts = element.inserts;
        }

        return (
            <div className="field">
                <input
                    type="checkbox"
                    checked={element.checked ? 1 : 0}
                    onChange={checkElementHandler.bind(this, id)}
                />
                <input
                    type="number"
                    min="0"
                    placeholder="Qty"
                    className="quantity-field"
                    value={value}
                    disabled={!element.checked}
                    onChange={setQuantityHandler.bind(this, id)}
                />
                <input
                    type="number"
                    min="0"
                    placeholder="Inserts"
                    value={inserts}
                    disabled={!element.checked}
                    onChange={setInsertsHandler.bind(this, element.id)}
                    className="inserts"
                />
                {element.size}
            </div>
        );
    };

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.inserting) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    return (
        <div className="department-configuration inserting-form">
            <h2>
                <div>Inserting</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={props.resetDepartment.bind(this, 'dptInserting')}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'inserting')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.inserting ? {} : { 'display': 'none' }}>
                <div className="form">
                    <div className="form-section">
                        <h6>Envelope Size:</h6>
                        {renderElement(1)}
                        {renderElement(2)}
                        {renderElement(3)}
                    </div>
                    <div className="form-section">
                        <h6>Matching Machine:</h6>
                        {renderElement(4)}
                    </div>
                </div>
                <AccountingForm
                    department="inserting"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default InsertingForm;