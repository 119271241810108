const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/papers`,
    search: `${API_URL}/paper/search`,
    information: `${API_URL}/paper/information`,
    saveActivePresses: `${API_URL}/paper/save-active-presses`
};

export default apiUrls;
