import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as loginActions from './../Login/Login.actions';
import * as loginSelectors from './../Login/Login.selectors';
import './Layout.scss';
import { Redirect } from 'react-router-dom';
import Favicon from 'react-favicon';
import Header from './Header';

class Layout extends Component {
    render() {
        if (!this.props.isAuthenticated) {
            return <Redirect to="/login" />;
        }

        const logoutHandler = (event) => {
            this.props.logout();
            event.preventDefault();
        }

        const pathname = window.location.pathname;
        let header = (
            <Header
                user={this.props.user}
                handleClickLogout={logoutHandler}
            />
        );
        if (pathname.includes('uwb-demo')) {
            header = null;
        }

        return (
            <div>
                <Favicon url="https://s3.amazonaws.com/ac-pqn/favicon.ico" />
                {header}
                <div className="content-wrapper">
                    <section className="content">
                        {this.props.children}
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: loginSelectors.getIsAuthenticated(state),
    user: loginSelectors.getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(loginActions.logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
