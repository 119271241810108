import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const FoldingTypes = (props) => {
    const [fetchingFoldingTypes, setFetchingFoldingTypes] = useState(false);

    const productType = props.productType;
    const setFoldingTypes = props.setFoldingTypes;
    useEffect(() => {
        if (parseInt(productType) !== 1 && parseInt(productType) !== 3) {
            return;
        }

        setFetchingFoldingTypes(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/folding-types',
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setFoldingTypes(response.data);
                setFetchingFoldingTypes(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingFoldingTypes(false);
            });
    }, [productType, setFoldingTypes]);

    const changeHandler = (event) => {
        props.setFoldingTypeId(event.target.value);
    };

    return (
        <div className="field">
            <div className="label">Folding Type:</div>
            <select value={props.foldingTypeId} onChange={changeHandler}>
                <option value="">No Folding</option>
                {props.foldingTypes.map((foldingType) => <option key={foldingType.id} value={foldingType.id}>{foldingType.name}</option>)}
            </select>
            {fetchingFoldingTypes && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default FoldingTypes;