const API_URL = process.env.REACT_APP_API_URL;

let apiUrls = {
    elements: `${API_URL}/presses`,
    search: `${API_URL}/press/search`,
    saveSpeedTable: `${API_URL}/press/save-speed-table`,
    saveMakeReadyMarkups: `${API_URL}/press/save-make-ready-markups`,
    saveBhrMarkups: `${API_URL}/press/save-bhr-markups`,
    saveActivePapers: `${API_URL}/press/save-active-papers`,
    information: `${API_URL}/press/information`,
    paperCategories: `${API_URL}/paper-categories`,
    papers: `${API_URL}/papers`
};

export default apiUrls;
