import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        data: PropTypes.object,
        handleNotificationClick: PropTypes.func,
        handleDropFile: PropTypes.func,
        activeElement: PropTypes.object
    };

    renderInput(label, name, type, required, readOnly = false, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={'field-' + name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    readOnly={readOnly}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={'field-' + name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    getUsersByType(roleId) {
        const { data, formValues, handleNotificationClick } = this.props;

        let users = [];
        for (let i = 0; i < data['users'].length; i++) {
            if (parseInt(data['users'][i]['user_type']) !== parseInt(roleId)) {
                continue;
            }

            let checked = formValues['notifications'].includes(data['users'][i]['id']);
            users.push(
                <div className="user-notification" key={i}>
                    <input type="checkbox" checked={checked} onClick={handleNotificationClick.bind(this, data['users'][i]['id'])} /> {data['users'][i]['name']}
                </div>
            );
        }
        return users;
    }

    renderUsers() {
        const { formValues } = this.props;

        if (parseInt(formValues['status']) === 4) {
            return null;
        }

        let csrs = this.getUsersByType(1);
        let csrRow = null;
        if (csrs.length > 0) {
            csrRow = (
                <div className="col">
                    <div className="in-out-role-name">Accounting</div>
                    {csrs}
                </div>
            );
        }

        let saless = this.getUsersByType(2);
        let salesRow = null;
        if (saless.length > 0) {
            salesRow = (
                <div className="col">
                    <div className="in-out-role-name">CSR</div>
                    {saless}
                </div>
            );
        }

        let accountings = this.getUsersByType(3);
        let accountingRow = null;
        if (accountings.length > 0) {
            accountingRow = (
                <div className="col">
                    <div className="in-out-role-name">Data</div>
                    {accountings}
                </div>
            );
        }

        let datas = this.getUsersByType(4);
        let dataRow = null;
        if (datas.length > 0) {
            dataRow = (
                <div className="col">
                    <div className="in-out-role-name">Sales</div>
                    {datas}
                </div>
            );
        }

        let purchasings = this.getUsersByType(5);
        let purchasingRow = null;
        if (purchasings.length > 0) {
            purchasingRow = (
                <div className="col">
                    <div className="in-out-role-name">Purchasing</div>
                    {purchasings}
                </div>
            );
        }

        let prepresses = this.getUsersByType(6);
        let prepressRow = null;
        if (prepresses.length > 0) {
            prepressRow = (
                <div className="col">
                    <div className="in-out-role-name">PrePress</div>
                    {prepresses}
                </div>
            );
        }

        let graphics = this.getUsersByType(7);
        let graphicsRow = null;
        if (graphics.length > 0) {
            graphicsRow = (
                <div className="col">
                    <div className="in-out-role-name">Graphics</div>
                    {graphics}
                </div>
            );
        }

        let largeFormats = this.getUsersByType(8);
        let largeFormatRow = null;
        if (largeFormats.length > 0) {
            largeFormatRow = (
                <div className="col">
                    <div className="in-out-role-name">Large Format</div>
                    {largeFormats}
                </div>
            );
        }

        let productions = this.getUsersByType(9);
        let productionRow = null;
        if (productions.length > 0) {
            productionRow = (
                <div className="col">
                    <div className="in-out-role-name">Operation</div>
                    {productions}
                </div>
            );
        }

        let operations = this.getUsersByType(10);
        let operationsRow = null;
        if (operations.length > 0) {
            operationsRow = (
                <div className="col">
                    <div className="in-out-role-name">Production</div>
                    {operations}
                </div>
            );
        }

        let lettershops = this.getUsersByType(11);
        let lettershopRow = null;
        if (lettershops.length > 0) {
            lettershopRow = (
                <div className="col">
                    <div className="in-out-role-name">Lettershop</div>
                    {lettershops}
                </div>
            );
        }

        let manages = this.getUsersByType(12);
        let manageRow = null;
        if (manages.length > 0) {
            manageRow = (
                <div className="col">
                    <div className="in-out-role-name">Management</div>
                    {manages}
                </div>
            );
        }

        return (
            <div className="row">
                {csrRow}
                <div className="w-100"></div>
                {salesRow}
                <div className="w-100"></div>
                {accountingRow}
                <div className="w-100"></div>
                {dataRow}
                <div className="w-100"></div>
                {purchasingRow}
                <div className="w-100"></div>
                {prepressRow}
                <div className="w-100"></div>
                {graphicsRow}
                <div className="w-100"></div>
                {largeFormatRow}
                <div className="w-100"></div>
                {productionRow}
                <div className="w-100"></div>
                {operationsRow}
                <div className="w-100"></div>
                {lettershopRow}
                <div className="w-100"></div>
                {manageRow}
            </div>
        );
    }

    hasChange(json, attribute) {
        if (json['is_new']) {
            return false;
        }

        return json['new_values'][attribute] !== json['old_values'][attribute];
    }

    renderAuditHistory() {
        const { activeElement } = this.props;

        if (activeElement === null) {
            return null;
        }

        return activeElement['audit_history'].map((element, index) => {
            let json = JSON.parse(element['description']);
            let newValues = json['new_values'];

            let record = [];

            if (parseInt(json['type']) === 1 || parseInt(json['type']) === 5) {
                record.push(<span className={this.hasChange(json, 'created_by_name') ? 'changed' : ''}>Created By: {newValues['created_by_name']}, </span>);
            } else if (parseInt(json['type']) === 2) {
                record.push(<span className={this.hasChange(json, 'updated_by_name') ? 'changed' : ''}>Updated By: {newValues['updated_by_name']}, </span>);
            } else if (parseInt(json['type']) === 3) {
                record.push(<span className="changed">Received By: {newValues['updated_by_name']}, </span>);
            } else if (parseInt(json['type']) === 4) {
                record.push(<span className="changed">Accepted By: {newValues['updated_by_name']}, </span>);
            }

            record.push(<span className={this.hasChange(json, 'customer') ? 'changed' : ''}>Customer: {newValues['customer']}, </span>);
            record.push(<span className={this.hasChange(json, 'description') ? 'changed' : ''}>Description: {newValues['description']}, </span>);
            record.push(<span className={this.hasChange(json, 'quantity') ? 'changed' : ''}>Quantity: {newValues['quantity']}, </span>);
            record.push(<span className={this.hasChange(json, 'pm_number') ? 'changed' : ''}>PM#: {newValues['pm_number']}, </span>);
            record.push(<span className={this.hasChange(json, 'ps_number') ? 'changed' : ''}>PS#: {newValues['ps_number']}, </span>);
            record.push(<span className={this.hasChange(json, 'notes') ? 'changed' : ''}>Notes: {newValues['notes']}, </span>);
            record.push(<span className={this.hasChange(json, 'notifications_users') ? 'changed' : ''}>Users: {newValues['notifications_users']}</span>);

            return (
                <div key={'audit-' + index} className="audit-element">
                    {record}
                </div>
            );
        });
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, formValues } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">{formValues['id'] === '' ? 'New request' : 'Request'}</div>
                    <div className="w-100"></div>
                    {this.renderInput('Customer', 'customer', 'text', true)}
                    {this.renderInput('Description', 'description', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Quantity', 'quantity', 'number', true)}
                    {this.renderInput('PM #', 'pm_number', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('PS #', 'ps_number', 'text', true)}
                    {parseInt(formValues['status']) === 4 ? this.renderInput('Location', 'location', 'text', true) : this.renderInput('Notes', 'notes', 'text', true)}
                    <div className="w-100"></div>
                    {parseInt(formValues['status']) === 4 ? this.renderInput('Notes', 'notes', 'text', true) : null}
                    <div className="w-100"></div>
                    {parseInt(formValues['status']) !== 4 ? <div className="subtitle mt-2">Notifications</div> : null}
                    <div className="w-100"></div>
                    <div className="col">{this.renderUsers()}</div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 1100, maxWidth: 1100 }}
                bodyStyle={{ overflow: 'initial' }}
                className="large-dialog"
            >
                <div className="form-container">
                    <div className="float-left" style={{ width: '60%' }}>{form}</div>
                    <div className={'float-left' + (isFetchingElement ? ' hidden' : '')} style={{ width: '40%', paddingLeft: 15 }}>
                        Audit History
                        <br />
                        {this.renderAuditHistory()}
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default Form;
