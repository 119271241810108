import React from 'react';
import { Dialog } from './../../common/dialog';
import Input from './../../Form/Input';
import Select from './../../Form/Select';

const FrontForm = React.memo((props) => {
    let content = (
        <form action="">
            <Input
                fields={props.fields}
                label="Name"
                field="name"
                required
                setField={props.setField}
            />
            <Input
                fields={props.fields}
                label="Size"
                field="size"
                required
                setField={props.setField}
            />
            <Input
                type="number"
                fields={props.fields}
                label="Prodution Rate 1 insert"
                field="prodution_rate_1_insert"
                required
                setField={props.setField}
            />
            <Input
                type="number"
                fields={props.fields}
                label="Prodution Rate 2 insert"
                field="prodution_rate_2_insert"
                required
                setField={props.setField}
            />
            <Input
                type="number"
                fields={props.fields}
                label="Rate Drop per extra insert"
                field="rate_drop_per_extra_insert"
                required
                setField={props.setField}
            />
            <Input
                type="number"
                fields={props.fields}
                label="Setup Time (minutes)"
                field="setup_time"
                required
                setField={props.setField}
            />
            <Select
                fields={props.fields}
                label="Active"
                field="active"
                required
                setField={props.setField}
                options={[
                    { value: '', label: 'Select option' },
                    { value: 1, label: 'Yes' },
                    { value: 0, label: 'No' },
                ]}
            />
        </form>
    )
    if (props.isFetchingElement) {
        content = <div><i className="fas fa-circle-notch fa-spin" /> Loading Data...</div>;
    }

    let label = 'Save';
    if (props.isSaving) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideForm}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" disabled={props.isSaving} onClick={props.save}>{label}</button>
    ];
    return (
        <Dialog
            name="form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="form-modal"
        >
            {content}
        </Dialog >
    );
});

FrontForm.propTypes = {

}

export default FrontForm;
