import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Select extends Component
{
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        name: PropTypes.string,
        options: PropTypes.array,
        required: PropTypes.bool,
        formErrorMessages: PropTypes.object
    };

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps['formValues'][nextProps['name']] !== this.props['formValues'][this.props['name']]
            || nextProps['formErrors'][nextProps['name']] !== this.props['formErrors'][this.props['name']]
            || JSON.stringify(nextProps['options']) !== JSON.stringify(this.props['options']);
    }

    render() {
        const { handleUpdateFormValue, formValues, formErrors, name, options, required, formErrorMessages, disabled } = this.props;

        let errorMessage = 'This field is required';
        let hasErrorMessage = false;
        if(formErrorMessages && formErrorMessages[name] && formErrorMessages[name] !== '') {
            errorMessage = formErrorMessages[name];
            hasErrorMessage = true;
        }

        return (
            <div>
                <select value={formValues[name]} name={name} onChange={handleUpdateFormValue.bind(this)} disabled={disabled ? disabled : false} >
                    {options.map((option, index) => {
                        return <option key={name + '-option-' + index} value={option.value}>{option.label}</option>;
                    })}
                </select>
                {required || hasErrorMessage ? <div className="error-message" hidden={!formErrors[name]}>{errorMessage}</div> : null}
            </div>
        );
    }
}

export default Select;
