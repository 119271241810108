import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const Accessories = (props) => {
    const [fetchingAccessories, setFetchingAccessories] = useState(false);

    const productType = props.productType;
    const setAccessories = props.setAccessories;
    const largeFormatMachineId = props.largeFormatMachineId;
    const substrateId = props.substrateId;
    const setAccessoriesIds = props.setAccessoriesIds;
    useEffect(() => {
        if (parseInt(productType) !== 4 || largeFormatMachineId === '' || substrateId === '') {
            return;
        }

        setAccessories([]);
        setAccessoriesIds([]);
        setFetchingAccessories(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/accessories?substrate-id=' + substrateId,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setAccessories(response.data);
                setFetchingAccessories(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingAccessories(false);
            });
    }, [productType, largeFormatMachineId, substrateId, setAccessories, setAccessoriesIds]);

    const changeHandler = (id, event) => {
        let ids = [];

        if(event.target.checked) {
            ids = [...props.accessoriesIds, parseInt(id)];
        } else {
            ids = props.accessoriesIds.filter((accessoriesId) => parseInt(accessoriesId) !== parseInt(id));
        }

        props.setAccessoriesIds(ids);
    };

    const renderAccessories = () => {
        return props.accessories.map((accessory) => {
            const checked = props.accessoriesIds.some((accessoriesId) => parseInt(accessoriesId) === parseInt(accessory.id));
            return (
                <div key={accessory.id} className="accessory-element">
                    <input type="checkbox" value={checked} onChange={changeHandler.bind(this, accessory.id)} /> {accessory.name}
                </div>
            );
        });
    };

    return (
        <div className="accessories">
            {renderAccessories()}
            {fetchingAccessories && <div className="accessories-loading">Loading... <i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default Accessories;