import React from 'react';

const Input = React.memo((props) => {
    let required = null;
    if (props.required) {
        required = <span className="required"> *</span>;
    }

    let value = null;
    if (props.fields[props.field] != null) {
        value = props.fields[props.field];
    }

    const setFieldHandler = (event) => {
        props.setField(props.field, event.target.value);
    };

    return (
        <div className="select-field">
            <div className="label">{props.label}{required}</div>
            <select value={value} onChange={setFieldHandler}>
                {props.options.map((option, index) => {
                    return (<option key={props.field + '-' + option.value} value={option.value}>{option.label}</option>);
                })}
            </select>
        </div>
    );
});

Input.propTypes = {

}

export default Input;
