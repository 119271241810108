import React, { useState } from 'react';
import { showNotification } from './../../../utils';
import LargeFormatMachines from './../Form/LargeFormatMachines';
import Substrates from './../Form/Substrates';
import Laminates from './../Form/Laminates';
import Accessories from './../Form/Accessories';
import axios from 'axios';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const LargeFormatForm = (props) => {
    const [largeFormatCount, setLargeFormatCount] = useState(1);

    const [quantity, setQuantity] = useState('');
    const [largeFormatMachineId, setLargeFormatMachineId] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [unit, setUnit] = useState('1');
    const [substrateId, setSubstrateId] = useState('');
    const [laminateId, setLaminateId] = useState('');
    const [accessoriesIds, setAccessoriesIds] = useState([]);

    const [quantity2, setQuantity2] = useState('');
    const [largeFormatMachineId2, setLargeFormatMachineId2] = useState('');
    const [width2, setWidth2] = useState('');
    const [height2, setHeight2] = useState('');
    const [unit2, setUnit2] = useState('1');
    const [substrateId2, setSubstrateId2] = useState('');
    const [laminateId2, setLaminateId2] = useState('');
    const [accessoriesIds2, setAccessoriesIds2] = useState([]);

    const [largeFormatMachines, setLargeFormatMachines] = useState([]);
    const [substrates, setSubstrates] = useState([]);
    const [laminates, setLaminates] = useState([]);
    const [accessories, setAccessories] = useState([]);
    const [isEstimating, setIsEstimating] = useState(false);

    const [substrates2, setSubstrates2] = useState([]);
    const [laminates2, setLaminates2] = useState([]);
    const [accessories2, setAccessories2] = useState([]);

    const onChangeHandler = (callback, event) => {
        callback(event.target.value);
    };

    const estimateHandler = () => {
        if (
            quantity === '' ||
            largeFormatMachineId === '' ||
            width === '' ||
            height === '' ||
            substrateId === ''
        ) {
            showNotification('Complete Fields', 'Please complete all the fields before continue', 'info');
            return;
        }

        setIsEstimating(true);
        props.resetEstimateDepartment('large-format');
        axios.post(process.env.REACT_APP_API_URL + '/dpt-estimate/estimate',
            {
                product_type: props.productType,
                quantity: quantity,
                large_format_machine_id: largeFormatMachineId,
                width: width,
                height: height,
                unit: unit,
                substrate_id: substrateId,
                laminate_id: laminateId,
                accessories_ids: accessoriesIds,
                quantity2: quantity2,
                large_format_machine_id2: largeFormatMachineId2,
                width2: width2,
                height2: height2,
                unit2: unit2,
                substrate_id2: substrateId2,
                laminate_id2: laminateId2,
                accessories_ids2: accessoriesIds2,
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                let result1 = null;
                let result2 = null;
                let label1 = '';
                let label2 = '';

                if (response.data.length === 1) {
                    result1 = response.data[0].results.length > 0 ? response.data[0].results[0] : null;
                } else if (response.data.length === 2) {
                    result1 = response.data[0].results.length > 0 ? response.data[0].results[0] : null;
                    result2 = response.data[1].results.length > 0 ? response.data[1].results[0] : null;
                }

                if (result1 != null && result2 != null) {
                    label1 = ' (Machine 1)';
                    label2 = ' (Machine 2)';
                }

                if (result1 == null && result2 == null) {
                    showNotification('No Results', 'There are no results for this job configuration, please try again with a different one.', 'info');
                }

                let largeFormatCountTemp = largeFormatCount;
                if (result1 != null) {
                    if (result1.large_format_machine_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result1.large_format_machine_information.id}.</div>
                                <div>Name: {result1.large_format_machine_information.name.trim()}.</div>
                                <div>Quantity: {numeral(quantity).format('0,0')}.</div>
                                <div>Setup time: {numeral(result1.large_format_machine_information.setup_time * 60).format('0,0')} minutes.</div>
                                <div>Total time: {numeral(Math.ceil(result1.large_format_machine_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptLargeFormat',
                            largeFormatCountTemp + result1.large_format_machine_information.id,
                            result1.large_format_machine_information.name.trim() + label1 + ' [Large Format]',
                            quantity,
                            Math.ceil(result1.large_format_machine_information.total_time * 60),
                            tooltipInfo,
                            'large-format'
                        );
                        largeFormatCountTemp++;
                    }
                }

                if (result2 != null) {
                    if (result2.large_format_machine_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result2.large_format_machine_information.id}.</div>
                                <div>Name: {result2.large_format_machine_information.name.trim()}.</div>
                                <div>Quantity: {numeral(quantity2).format('0,0')}.</div>
                                <div>Setup time: {numeral(result2.large_format_machine_information.setup_time * 60).format('0,0')} minutes.</div>
                                <div>Total time: {numeral(Math.ceil(result2.large_format_machine_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptLargeFormat',
                            largeFormatCountTemp + result2.large_format_machine_information.id,
                            result2.large_format_machine_information.name.trim() + label2 + ' [Large Format]',
                            quantity2,
                            Math.ceil(result2.large_format_machine_information.total_time * 60),
                            tooltipInfo,
                            'large-format'
                        );
                        largeFormatCountTemp++;
                    }
                }

                setLargeFormatCount(largeFormatCountTemp);
                setIsEstimating(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setIsEstimating(false);
            });
    };

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.largeFormat) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    const resetDepartmentHandler = () => {
        props.resetEstimateDepartment('large-format');

        setLargeFormatCount(1);

        setQuantity('');
        setLargeFormatMachineId('');
        setWidth('');
        setHeight('');
        setUnit('1');
        setSubstrateId('');
        setLaminateId('');
        setAccessoriesIds([]);

        setQuantity2('');
        setLargeFormatMachineId2('');
        setWidth2('');
        setHeight2('');
        setUnit2('1');
        setSubstrateId2('');
        setLaminateId2('');
        setAccessoriesIds2([]);

        setSubstrates([]);
        setLaminates([]);
        setAccessories([]);

        setSubstrates2([]);
        setLaminates2([]);
        setAccessories2([]);
    };

    return (
        <div className="estimate-form large-format-form">
            <h2>
                <div>Large Format</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={resetDepartmentHandler}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'largeFormat')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.largeFormat ? {} : { 'display': 'none' }}>
                <div className="form">
                    <div className="machines-container">
                        <div className="machine-configuration machine1-configuration">
                            <h3>Machine 1</h3>
                            <div className="field">
                                <div className="label">Quantity:</div>
                                <input type="number" min="0" value={quantity} onChange={onChangeHandler.bind(this, setQuantity)} />
                            </div>
                            <LargeFormatMachines
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId}
                                largeFormatMachines={largeFormatMachines}
                                setLargeFormatMachineId={setLargeFormatMachineId}
                                setLargeFormatMachines={setLargeFormatMachines}
                                setSubstrates={setSubstrates}
                                setSubstrateId={setSubstrateId}
                                setLaminates={setLaminates}
                                setLaminateId={setLaminateId}
                                setAccessories={setAccessories}
                                setAccessoriesIds={setAccessoriesIds}
                            />
                            <div className="multi-field-container">
                                <div className="third-field">
                                    <div className="label">Width:</div>
                                    <input type="number" min="0" value={width} onChange={onChangeHandler.bind(this, setWidth)} />
                                </div>
                                <div className="third-field">
                                    <div className="label">Height:</div>
                                    <input type="number" min="0" value={height} onChange={onChangeHandler.bind(this, setHeight)} />
                                </div>
                                <div className="third-field">
                                    <div className="label">Unit:</div>
                                    <select value={unit} onChange={onChangeHandler.bind(this, setUnit)}>
                                        <option value="1">Inches</option>
                                        <option value="2">Feet</option>
                                    </select>
                                </div>
                            </div>
                            <Substrates
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId}
                                substrateId={substrateId}
                                substrates={substrates}
                                setSubstrateId={setSubstrateId}
                                setSubstrates={setSubstrates}
                                setLaminates={setLaminates}
                                setLaminateId={setLaminateId}
                            />
                            <Laminates
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId}
                                substrateId={substrateId}
                                laminateId={laminateId}
                                laminates={laminates}
                                setLaminateId={setLaminateId}
                                setLaminates={setLaminates}
                            />
                            <h3>Accessories</h3>
                            <Accessories
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId}
                                substrateId={substrateId}
                                accessoriesIds={accessoriesIds}
                                accessories={accessories}
                                setAccessoriesIds={setAccessoriesIds}
                                setAccessories={setAccessories}
                            />
                        </div>
                        <div className="machine-configuration machine2-configuration">
                            <h3>Machine 2</h3>
                            <div className="field">
                                <div className="label">Quantity:</div>
                                <input type="number" min="0" value={quantity2} onChange={onChangeHandler.bind(this, setQuantity2)} />
                            </div>
                            <LargeFormatMachines
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId2}
                                largeFormatMachines={largeFormatMachines}
                                setLargeFormatMachineId={setLargeFormatMachineId2}
                                setLargeFormatMachines={setLargeFormatMachines}
                                setSubstrates={setSubstrates2}
                                setSubstrateId={setSubstrateId2}
                                setLaminates={setLaminates2}
                                setLaminateId={setLaminateId2}
                                setAccessories={setAccessories2}
                                setAccessoriesIds={setAccessoriesIds2}
                            />
                            <div className="multi-field-container">
                                <div className="third-field">
                                    <div className="label">Width:</div>
                                    <input type="number" min="0" value={width2} onChange={onChangeHandler.bind(this, setWidth2)} />
                                </div>
                                <div className="third-field">
                                    <div className="label">Height:</div>
                                    <input type="number" min="0" value={height2} onChange={onChangeHandler.bind(this, setHeight2)} />
                                </div>
                                <div className="third-field">
                                    <div className="label">Unit:</div>
                                    <select value={unit2} onChange={onChangeHandler.bind(this, setUnit2)}>
                                        <option value="1">Inches</option>
                                        <option value="2">Feet</option>
                                    </select>
                                </div>
                            </div>
                            <Substrates
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId2}
                                substrateId={substrateId2}
                                substrates={substrates2}
                                setSubstrateId={setSubstrateId2}
                                setSubstrates={setSubstrates2}
                                setLaminates={setLaminates2}
                                setLaminateId={setLaminateId2}
                            />
                            <Laminates
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId2}
                                substrateId={substrateId2}
                                laminateId={laminateId2}
                                laminates={laminates2}
                                setLaminateId={setLaminateId2}
                                setLaminates={setLaminates2}
                            />
                            <h3>Accessories</h3>
                            <Accessories
                                productType={props.productType}
                                largeFormatMachineId={largeFormatMachineId2}
                                substrateId={substrateId2}
                                accessoriesIds={accessoriesIds2}
                                accessories={accessories2}
                                setAccessoriesIds={setAccessoriesIds2}
                                setAccessories={setAccessories2}
                            />
                        </div>
                    </div>
                </div>
                <div className="button-container">
                    <button onClick={estimateHandler} disabled={isEstimating}>{isEstimating ? <i className="fas fa-circle-notch fa-spin" /> : 'Estimate'}</button>
                </div>
                <AccountingForm
                    department="largeFormat"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default LargeFormatForm;