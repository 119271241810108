import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class SpeedTable extends Component {
    static propTypes = {
        speedTable: PropTypes.array,
        hideSpeedTable: PropTypes.func,
        handleUpdateSpeedTable: PropTypes.func,
        isSavingSpeedTable: PropTypes.bool,
        handleSaveSpeedTable: PropTypes.func,
        handleAddSpeedTable: PropTypes.func,
        handleDeleteSpeedTable: PropTypes.func
    };

    renderData() {
        const { speedTable, handleUpdateSpeedTable, handleDeleteSpeedTable } = this.props;
        return speedTable.map((speed, index) => {
            return (
                <tr key={index}>
                    <td>
                        <input type="text" value={speed.start_quantity}
                            onChange={handleUpdateSpeedTable.bind(this, index, 'start_quantity')} />
                    </td>
                    <td>
                        <input type="text" value={speed.end_quantity}
                            onChange={handleUpdateSpeedTable.bind(this, index, 'end_quantity')} />
                    </td>
                    <td>
                        <input type="text" value={speed.speed}
                            onChange={handleUpdateSpeedTable.bind(this, index, 'speed')} />
                    </td>
                    <td>
                        <div className="action-icon">
                            <i className="fas fa-trash" onClick={handleDeleteSpeedTable.bind(this, index)} />
                        </div>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { hideSpeedTable, handleSaveSpeedTable, isSavingSpeedTable, handleAddSpeedTable, isFetchingElement } = this.props;

        let data = (
            <div>
                <table className="table table-sm table-striped data-table">
                    <thead>
                        <tr>
                            <th>Start Quantity</th>
                            <th>End Quantity</th>
                            <th>Speed</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                </table>
                <div className="add-button" onClick={handleAddSpeedTable} style={{ width: 120, fontSize: 13 }}><i className="fas fa-plus" /> Add Row</div>
            </div>
        );
        if (isFetchingElement) {
            data = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let saveButtonLabel = 'Save';
        if (isSavingSpeedTable) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveSpeedTable} disabled={isSavingSpeedTable}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideSpeedTable}>Close</button>
        ];
        return (
            <Dialog
                name="speed-table"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default SpeedTable;
