import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Table extends Component {
    static propTypes = {
        user: PropTypes.object
    };

    redirect(url) {
        this.props.history.push(url);
    }

    hasPermission(name) {
        const { user } = this.props;
        for (let i = 0; i < user['permissions'].length; i++) {
            if (user['permissions'][i]['name'] === name) {
                for (let j = 0; j < user['permissions'][i]['subnames'].length; j++) {
                    if (user['permissions'][i]['subnames'][j]['selected']) {
                        return true;
                    }
                }
            }
        }
    }

    render() {
        const { user } = this.props;
        let usersIcon = null;
        if (this.hasPermission('Users') || user['type'] === 1) {
            usersIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/users')}>
                    <div><i className="fas fa-users" /></div>
                    <div>Users</div>
                </div>
            );
        }

        let customerDiscountsIcon = null;
        if (this.hasPermission('Customer Discounts') || user['type'] === 1) {
            customerDiscountsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/customer-discounts')}>
                    <div><i className="fas fa-users" /></div>
                    <div>Customer Discounts</div>
                </div>
            );
        }

        let industriesIcon = null;
        if (this.hasPermission('Industries') || user['type'] === 1) {
            industriesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/industries')}>
                    <div><i className="fas fa-database" /></div>
                    <div>Industries</div>
                </div>
            );
        }

        let departmentsIcon = null;
        if (this.hasPermission('Departments') || user['type'] === 1) {
            departmentsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/departments')}>
                    <div><i className="fas fa-database" /></div>
                    <div>Departments</div>
                </div>
            );
        }

        let turnaroundIcon = null;
        if (this.hasPermission('Departments') || user['type'] === 1) {
            turnaroundIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/turnaround')}>
                    <div><i className="far fa-calendar-check" /></div>
                    <div>Turnaround</div>
                </div>
            );
        }

        let employeesIcon = null;
        if (this.hasPermission('Employees') || user['type'] === 1) {
            employeesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/employees')}>
                    <div><i className="fas fa-users" /></div>
                    <div>Employees</div>
                </div>
            );
        }

        let acSalesReportIcon = null;
        if (this.hasPermission('AC Sales Report') || user['type'] === 1) {
            acSalesReportIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/ac-sales-report')}>
                    <div><i className="fas fa-file-excel" /></div>
                    <div>AC Sales Report</div>
                </div>
            );
        }

        let artIcon = null;
        if (this.hasPermission('Art') || user['type'] === 1) {
            artIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/art-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Art</div>
                </div>
            );
        }

        let vendorsIcon = null;
        if (this.hasPermission('Vendors') || user['type'] === 1) {
            vendorsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/vendors')}>
                    <div><i className="fas fa-address-book" /></div>
                    <div>Vendors</div>
                </div>
            );
        }

        let utHealthIcon = null;
        if (this.hasPermission('UTHealth') || user['type'] === 1) {
            utHealthIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/ut-health-orders')}>
                    <div><i className="fas fa-hospital" /></div>
                    <div>UTHealth Orders</div>
                </div>
            );
        }

        let invoicesIcon = null;
        if (this.hasPermission('Invoices') || user['type'] === 1) {
            invoicesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/invoices')}>
                    <div><i className="fas fa-file-invoice-dollar" /></div>
                    <div>Invoices</div>
                </div>
            );
        }

        let administrativeSection = null;
        if (usersIcon != null || industriesIcon != null || departmentsIcon != null || turnaroundIcon != null
            || acSalesReportIcon != null || artIcon != null || vendorsIcon != null || utHealthIcon != null
            || invoicesIcon != null) {
            administrativeSection = (
                <div className="col-9">
                    <div className="card mb-3">
                        <div className="card-header">
                            Administrative Configuration
                        </div>
                        <div className="card-body p-2 text-center">
                            {usersIcon}
                            {customerDiscountsIcon}
                            {industriesIcon}
                            {departmentsIcon}
                            {turnaroundIcon}
                            {employeesIcon}
                            {acSalesReportIcon}
                            {artIcon}
                            {vendorsIcon}
                            {utHealthIcon}
                            {invoicesIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let productCategoriesIcon = null;
        if (this.hasPermission('Product Categories') || user['type'] === 1) {
            productCategoriesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/product-categories')}>
                    <div><i className="fas fa-database" /></div>
                    <div>Categories</div>
                </div>
            );
        }

        let productsIcon = null;
        /*if(this.hasPermission('Products') || user['type'] === 1) {
            productsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/products')}>
                    <div><i className="fas fa-database" /></div>
                    <div>Products</div>
                </div>
            );
        }*/

        let productDashboardsIcon = null;
        if (this.hasPermission('Product Dashboards') || user['type'] === 1) {
            productDashboardsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/products')}>
                    <div><i className="fas fa-columns" /></div>
                    <div>Products</div>
                </div>
            );
        }

        let productsSection = null;
        if (productCategoriesIcon != null || productsIcon != null) {
            productsSection = (
                <div className="col-3">
                    <div className="card mb-3">
                        <div className="card-header">
                            Products
                        </div>
                        <div className="card-body p-2 text-center">
                            {productCategoriesIcon}
                            {productsIcon}
                            {productDashboardsIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let pressesIcon = null;
        if (this.hasPermission('Presses') || user['type'] === 1) {
            pressesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/presses')}>
                    <div><i className="fas fa-print" /></div>
                    <div>Presses</div>
                </div>
            );
        }

        let inksIcon = null;
        if (this.hasPermission('Inks') || user['type'] === 1) {
            inksIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/inks')}>
                    <div><i className="fas fa-fill-drip" /></div>
                    <div>Inks</div>
                </div>
            );
        }

        let digitalIcon = null;
        if (this.hasPermission('Digital') || user['type'] === 1) {
            digitalIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/digital-machines')}>
                    <div><i className="fas fa-laptop" /></div>
                    <div>Digital</div>
                </div>
            );
        }

        let cuttingIcon = null;
        if (this.hasPermission('Cutting') || user['type'] === 1) {
            cuttingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/cutting-machines')}>
                    <div><i className="fas fa-cut" /></div>
                    <div>Cutting</div>
                </div>
            );
        }

        let dieCuttingIcon = null;
        if (this.hasPermission('Die-Cutting') || user['type'] === 1) {
            dieCuttingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/die-cutting-machines')}>
                    <div><i className="fas fa-cut" /></div>
                    <div>Die-Cutting</div>
                </div>
            );
        }

        let drillingIcon = null;
        if (this.hasPermission('Drilling') || user['type'] === 1) {
            drillingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/drilling-machines')}>
                    <div><i className="fas fa-level-down-alt" /></div>
                    <div>Drilling</div>
                </div>
            );
        }

        let scoringIcon = null;
        if (this.hasPermission('Scoring') || user['type'] === 1) {
            scoringIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/scoring-machines')}>
                    <div><i className="fas fa-book-open" /></div>
                    <div>Scoring</div>
                </div>
            );
        }

        let paddingIcon = null;
        if (this.hasPermission('Padding') || user['type'] === 1) {
            paddingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/padding-machines')}>
                    <div><i className="fas fa-clipboard" /></div>
                    <div>Padding</div>
                </div>
            );
        }

        let machinesSection = null;
        if (pressesIcon != null || inksIcon != null || digitalIcon != null
            || cuttingIcon != null || dieCuttingIcon != null || drillingIcon != null
            || scoringIcon != null || paddingIcon != null) {
            machinesSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Machines Configuration
                        </div>
                        <div className="card-body p-2 text-center">
                            {pressesIcon}
                            {inksIcon}
                            {digitalIcon}
                            {cuttingIcon}
                            {dieCuttingIcon}
                            {drillingIcon}
                            {scoringIcon}
                            {paddingIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let paperVendorsIcon = null;
        if (this.hasPermission('Paper Vendors') || user['type'] === 1) {
            paperVendorsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/paper-vendors')}>
                    <div><i className="fas fa-address-book" /></div>
                    <div>Vendors</div>
                </div>
            );
        }

        let paperCategoriesIcon = null;
        if (this.hasPermission('Paper Categories') || user['type'] === 1) {
            paperCategoriesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/paper-categories')}>
                    <div><i className="fas fa-clipboard-list" /></div>
                    <div>Categories</div>
                </div>
            );
        }

        let papersIcon = null;
        if (this.hasPermission('Papers') || user['type'] === 1) {
            papersIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/papers')}>
                    <div><i className="fas fa-file" /></div>
                    <div>Papers</div>
                </div>
            );
        }

        let paperSection = null;
        if (paperVendorsIcon != null || paperCategoriesIcon != null || papersIcon != null) {
            paperSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Paper Configuration
                        </div>
                        <div className="card-body p-2 text-center">
                            {paperVendorsIcon}
                            {paperCategoriesIcon}
                            {papersIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let foldingMachinesIcon = null;
        if (this.hasPermission('Folding Machines') || user['type'] === 1) {
            foldingMachinesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/folding-machines')}>
                    <div><i className="fas fa-industry" /></div>
                    <div>Machines</div>
                </div>
            );
        }

        let foldingTypesIcon = null;
        if (this.hasPermission('Folding Types') || user['type'] === 1) {
            foldingTypesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/fold-types')}>
                    <div><i className="fas fa-book-open" /></div>
                    <div>Types</div>
                </div>
            );
        }

        let foldingSection = null;
        if (foldingMachinesIcon != null || foldingTypesIcon != null) {
            foldingSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Folding Configuration
                        </div>
                        <div className="card-body p-2 text-center">
                            {foldingMachinesIcon}
                            {foldingTypesIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let saddleStitchingIcon = null;
        if (this.hasPermission('Saddle Stitching Machines') || user['type'] === 1) {
            saddleStitchingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/saddle-stitching-machines')}>
                    <div><i className="fas fa-industry" /></div>
                    <div>Saddle Stitching</div>
                </div>
            );
        }

        let perfectBindingIcon = null;
        if (this.hasPermission('Perfect Binding Machines') || user['type'] === 1) {
            perfectBindingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/perfect-binding-machines')}>
                    <div><i className="fas fa-book" /></div>
                    <div>Perfect Binding</div>
                </div>
            );
        }

        let coilBindingIcon = null;
        if (this.hasPermission('Coil Binding Machines') || user['type'] === 1) {
            coilBindingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/coil-binding-machines')}>
                    <div><i className="fas fa-industry" /></div>
                    <div>Coil Binding</div>
                </div>
            );
        }

        let cornerStapleIcon = null;
        if (this.hasPermission('Corner Staple Machines') || user['type'] === 1) {
            cornerStapleIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/corner-staple-machines')}>
                    <div><i className="fas fa-industry" /></div>
                    <div>Corner Staple</div>
                </div>
            );
        }

        let sideStapleIcon = null;
        if (this.hasPermission('Side Staple Machines') || user['type'] === 1) {
            sideStapleIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/side-staple-machines')}>
                    <div><i className="fas fa-industry" /></div>
                    <div>Side Staple</div>
                </div>
            );
        }

        let spiralBindingIcon = null;
        if (this.hasPermission('Spiral Binding Machines') || user['type'] === 1) {
            spiralBindingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/spiral-binding-machines')}>
                    <div><i className="fas fa-industry" /></div>
                    <div>Spiral Binding</div>
                </div>
            );
        }

        let bindingSection = null;
        if (saddleStitchingIcon != null || perfectBindingIcon != null || coilBindingIcon != null
            || cornerStapleIcon != null || sideStapleIcon != null || spiralBindingIcon != null) {
            bindingSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Binding Configuration
                        </div>
                        <div className="card-body p-2 text-center">
                            {saddleStitchingIcon}
                            {perfectBindingIcon}
                            {coilBindingIcon}
                            {cornerStapleIcon}
                            {sideStapleIcon}
                            {spiralBindingIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let bandingTypesIcon = null;
        if (this.hasPermission('Banding Types') || user['type'] === 1) {
            bandingTypesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/banding-types')}>
                    <div><i className="fas fa-box-open" /></div>
                    <div>Banding Types</div>
                </div>
            );
        }

        let bandingMachinesIcon = null;
        if (this.hasPermission('Banding Machines') || user['type'] === 1) {
            bandingMachinesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/banding-machines')}>
                    <div><i className="fas fa-box-open" /></div>
                    <div>Banding Machines</div>
                </div>
            );
        }

        let wrappingTypesIcon = null;
        if (this.hasPermission('Wrapping Types') || user['type'] === 1) {
            wrappingTypesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/wrapping-types')}>
                    <div><i className="fas fa-box-open" /></div>
                    <div>Wrapping Types</div>
                </div>
            );
        }

        let wrappingMachinesIcon = null;
        if (this.hasPermission('Wrapping Machines') || user['type'] === 1) {
            wrappingMachinesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/wrapping-machines')}>
                    <div><i className="fas fa-box-open" /></div>
                    <div>Wrapping Machines</div>
                </div>
            );
        }

        let shrinkWrappingCategoriesIcon = null;
        if (this.hasPermission('Shrink Wrapping Categories') || user['type'] === 1) {
            shrinkWrappingCategoriesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/shrink-wrapping-categories')}>
                    <div><i className="fas fa-box-open" /></div>
                    <div>Shrink Wrapping Categories</div>
                </div>
            );
        }

        let shrinkWrappingTypesIcon = null;
        if (this.hasPermission('Shrink Wrapping Types') || user['type'] === 1) {
            shrinkWrappingTypesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/shrink-wrapping-types')}>
                    <div><i className="fas fa-box-open" /></div>
                    <div>Shrink Wrapping Types</div>
                </div>
            );
        }

        let shrinkWrappingMachinesIcon = null;
        if (this.hasPermission('Shrink Wrapping Machines') || user['type'] === 1) {
            shrinkWrappingMachinesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/shrink-wrapping-machines')}>
                    <div><i className="fas fa-box-open" /></div>
                    <div>Shrink Wrapping Machines</div>
                </div>
            );
        }

        let packagesIcon = null;
        if (this.hasPermission('Packages') || user['type'] === 1) {
            packagesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/packages')}>
                    <div><i className="fas fa-boxes" /></div>
                    <div>Packages</div>
                </div>
            );
        }

        let mailingOperationsIcon = null;
        if (this.hasPermission('Mailing Operations') || user['type'] === 1) {
            mailingOperationsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/mailing-operations')}>
                    <div><i className="fas fa-dolly" /></div>
                    <div>Mailing Operations</div>
                </div>
            );
        }

        let flatHandGatheringIcon = null;
        if (this.hasPermission('Flat Hand Gathering') || user['type'] === 1) {
            flatHandGatheringIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/flat-hand-gathering')}>
                    <div><i className="fas fa-hands" /></div>
                    <div>Flat Hand Gathering</div>
                </div>
            );
        }

        let multiPageHandGatheringIcon = null;
        if (this.hasPermission('Multi-Page Hand Gathering') || user['type'] === 1) {
            multiPageHandGatheringIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/multi-page-hand-gathering')}>
                    <div><i className="fas fa-hands" /></div>
                    <div>Multi-Page Hand Gathering</div>
                </div>
            );
        }

        let countingIcon = null;
        if (this.hasPermission('Counting') || user['type'] === 1) {
            countingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/counting')}>
                    <div><i className="fas fa-search-plus" /></div>
                    <div>Counting</div>
                </div>
            );
        }

        let finishingSection = null;
        if (bandingTypesIcon != null || bandingMachinesIcon != null || wrappingTypesIcon != null
            || wrappingMachinesIcon != null || shrinkWrappingCategoriesIcon != null || shrinkWrappingTypesIcon != null
            || shrinkWrappingMachinesIcon != null || packagesIcon != null || mailingOperationsIcon != null
            || flatHandGatheringIcon != null || multiPageHandGatheringIcon != null || countingIcon != null) {
            finishingSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Finishing and Mailing Configuration
                        </div>
                        <div className="card-body p-2 text-center">
                            {bandingTypesIcon}
                            {bandingMachinesIcon}
                            {wrappingTypesIcon}
                            {wrappingMachinesIcon}
                            {shrinkWrappingCategoriesIcon}
                            {shrinkWrappingTypesIcon}
                            {shrinkWrappingMachinesIcon}
                            {packagesIcon}
                            {mailingOperationsIcon}
                            {flatHandGatheringIcon}
                            {multiPageHandGatheringIcon}
                            {countingIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let largeFormatMachinesIcon = null;
        if (this.hasPermission('Large Format Machines') || user['type'] === 1) {
            largeFormatMachinesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/large-format-machines')}>
                    <div><i className="fas fa-industry" /></div>
                    <div>Machines</div>
                </div>
            );
        }

        let substratesIcon = null;
        if (this.hasPermission('Substrates') || user['type'] === 1) {
            substratesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/substrates')}>
                    <div><i className="fas fa-layer-group" /></div>
                    <div>Substrates</div>
                </div>
            );
        }

        let laminatesIcon = null;
        if (this.hasPermission('Laminates') || user['type'] === 1) {
            laminatesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/laminates')}>
                    <div><i className="fas fa-brush" /></div>
                    <div>Laminates</div>
                </div>
            );
        }

        let accessoriesIcon = null;
        if (this.hasPermission('Accessories') || user['type'] === 1) {
            accessoriesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/accessories')}>
                    <div><i className="fas fa-boxes" /></div>
                    <div>Accessories</div>
                </div>
            );
        }

        let largeFormatSection = null;
        if (largeFormatMachinesIcon != null || substratesIcon != null || laminatesIcon != null
            || accessoriesIcon != null) {
            largeFormatSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Large Format
                        </div>
                        <div className="card-body p-2 text-center">
                            {largeFormatMachinesIcon}
                            {substratesIcon}
                            {laminatesIcon}
                            {accessoriesIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let envelopePressesIcon = null;
        if (this.hasPermission('Envelope Presses') || user['type'] === 1) {
            envelopePressesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/envelope-presses')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Presses</div>
                </div>
            );
        }

        let envelopePapersIcon = null;
        if (this.hasPermission('Envelope Papers') || user['type'] === 1) {
            envelopePapersIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/envelope-papers')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Papers</div>
                </div>
            );
        }

        let envelopesTypesIcon = null;
        if (this.hasPermission('Envelope Types') || user['type'] === 1) {
            envelopesTypesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/envelope-types')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Types</div>
                </div>
            );
        }

        let envelopesSection = null;
        if (envelopePressesIcon != null || envelopePapersIcon != null || envelopesTypesIcon != null) {
            envelopesSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Envelopes
                        </div>
                        <div className="card-body p-2 text-center">
                            {envelopesTypesIcon}
                            {envelopePressesIcon}
                            {envelopePapersIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let dataProcessingIcon = null;
        if (this.hasPermission('Data Processing') || user['type'] === 1) {
            dataProcessingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/data-processing-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Data Processing</div>
                </div>
            );
        }

        let inkjetIcon = null;
        if (this.hasPermission('Inkjet') || user['type'] === 1) {
            inkjetIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/inkjet-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Inkjet</div>
                </div>
            );
        }

        let insertingIcon = null;
        if (this.hasPermission('Inserting') || user['type'] === 1) {
            insertingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/inserting-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Inserting</div>
                </div>
            );
        }

        let perfectMatchIcon = null;
        if (this.hasPermission('Perfect Match') || user['type'] === 1) {
            perfectMatchIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/perfect-match-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Perfect Match</div>
                </div>
            );
        }

        // let waferSealIcon = null;
        // if (this.hasPermission('Wafer Seal') || user['type'] === 1) {
        //     waferSealIcon = (
        //         <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/wafer-seal-elements')}>
        //             <div><i className="fas fa-mail-bulk" /></div>
        //             <div>Wafer Seal</div>
        //         </div>
        //     );
        // }

        // let affixIcon = null;
        // if (this.hasPermission('Affix Labels') || user['type'] === 1) {
        //     affixIcon = (
        //         <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/affix-elements')}>
        //             <div><i className="fas fa-mail-bulk" /></div>
        //             <div>Affix Labels</div>
        //         </div>
        //     );
        // }

        let certMailIcon = null;
        if (this.hasPermission('Certified Mail') || user['type'] === 1) {
            certMailIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/cert-mail-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Certified Mail</div>
                </div>
            );
        }

        let handworkIcon = null;
        if (this.hasPermission('Handwork') || user['type'] === 1) {
            handworkIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/handwork-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Handwork</div>
                </div>
            );
        }

        let pressureSealIcon = null;
        if (this.hasPermission('Pressure Seal Forms') || user['type'] === 1) {
            pressureSealIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/pressure-seal-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Pressure Seal Forms</div>
                </div>
            );
        }

        let indexTabIcon = null;
        if (this.hasPermission('Index Tabs') || user['type'] === 1) {
            indexTabIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/index-tab-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Index Tabs</div>
                </div>
            );
        }

        let labelsIcon = null;
        if (this.hasPermission('Labels') || user['type'] === 1) {
            labelsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/label-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>Labels</div>
                </div>
            );
        }

        let ncrFormsIcon = null;
        if (this.hasPermission('NCR Forms') || user['type'] === 1) {
            ncrFormsIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/ncr-form-elements')}>
                    <div><i className="fas fa-mail-bulk" /></div>
                    <div>NCR Forms</div>
                </div>
            );
        }

        let mailingSection = null;
        if (dataProcessingIcon != null || inkjetIcon != null || insertingIcon != null
            || perfectMatchIcon != null /*|| waferSealIcon != null || affixIcon != null*/
            || certMailIcon != null || artIcon != null || handworkIcon != null) {
            mailingSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Mailing
                        </div>
                        <div className="card-body p-2 text-center">
                            {dataProcessingIcon}
                            {inkjetIcon}
                            {insertingIcon}
                            {perfectMatchIcon}
                            {/* {waferSealIcon} */}
                            {/* {affixIcon} */}
                            {certMailIcon}
                            {handworkIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let digitalSection = null;
        if (pressureSealIcon != null || indexTabIcon != null || labelsIcon != null || ncrFormsIcon != null) {
            digitalSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Digital
                        </div>
                        <div className="card-body p-2 text-center">
                            {pressureSealIcon}
                            {indexTabIcon}
                            {labelsIcon}
                            {ncrFormsIcon}
                        </div>
                    </div>
                </div>
            );
        }

        let dptCalculator = null;
        if (this.hasPermission('DPT Calculator') || user['type'] === 1) {
            dptCalculator = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/dpt-calculator')}>
                    <div><i className="fas fa-calculator" /></div>
                    <div>Calculator</div>
                </div>
            );
        }

        let dptDataProcessingIcon = null;
        if (this.hasPermission('DPT Data Processing') || user['type'] === 1) {
            dptDataProcessingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/dpt-data-processing')}>
                    <div><i className="fas fa-database" /></div>
                    <div>Data Processing</div>
                </div>
            );
        }

        let dptInkjetIcon = null;
        if (this.hasPermission('DPT Inkjet') || user['type'] === 1) {
            dptInkjetIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/dpt-inkjet')}>
                    <div><i className="fas fa-print" /></div>
                    <div>Inkjet</div>
                </div>
            );
        }

        let dptInkjetMaterialIcon = null;
        if (this.hasPermission('DPT Inkjet Material') || user['type'] === 1) {
            dptInkjetMaterialIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/dpt-inkjet-materials')}>
                    <div><i className="fas fa-layer-group" /></div>
                    <div>Inkjet Materials</div>
                </div>
            );
        }

        let dptInsertingIcon = null;
        if (this.hasPermission('DPT Inserting') || user['type'] === 1) {
            dptInsertingIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/dpt-inserting')}>
                    <div><i className="fas fa-envelope-open-text" /></div>
                    <div>Inserting</div>
                </div>
            );
        }

        let dptOutsideServicesIcon = null;
        if (this.hasPermission('DPT Outside Service') || user['type'] === 1) {
            dptOutsideServicesIcon = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/dpt-outside-services')}>
                    <div><i className="fas fa-sign-out-alt" /></div>
                    <div>Outside Services</div>
                </div>
            );
        }

        let dptHand = null;
        if (this.hasPermission('DPT Hand') || user['type'] === 1) {
            dptHand = (
                <div className="admin-icon text-center" onClick={this.redirect.bind(this, '/dpt-hand')}>
                    <div><i className="fas fa-box" /></div>
                    <div>Hand</div>
                </div>
            );
        }

        let dptSection = null;
        if (dptDataProcessingIcon != null || dptInkjetIcon != null || dptInkjetMaterialIcon != null ||
            dptInsertingIcon != null || dptOutsideServicesIcon != null ||
            dptHand != null) {
            dptSection = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Departmental Processing Time
                        </div>
                        <div className="card-body p-2 text-center">
                            {dptCalculator}
                            {dptDataProcessingIcon}
                            {dptInkjetIcon}
                            {dptInkjetMaterialIcon}
                            {dptInsertingIcon}
                            {dptOutsideServicesIcon}
                            {dptHand}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <div className="row">
                        {administrativeSection}
                        {productsSection}
                        <div className="w-100"></div>
                        {/* {machinesSection} */}
                        <div className="w-100"></div>
                        {/* {paperSection} */}
                        {/* {foldingSection} */}
                        <div className="w-100"></div>
                        {/* {bindingSection} */}
                        <div className="w-100"></div>
                        {/* {finishingSection} */}
                        <div className="w-100"></div>
                        {/* {largeFormatSection} */}
                        {/* {envelopesSection} */}
                        <div className="w-100"></div>
                        {/* {mailingSection} */}
                        {/* {digitalSection} */}
                        <div className="w-100"></div>
                        {/* {dptSection} */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Table;
