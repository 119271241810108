import React, { Fragment, useState } from 'react';
import { showNotification } from './../../../utils';
import PaperCategories from './../Form/PaperCategories';
import PaperWeights from './../Form/PaperWeights';
import Papers from './../Form/Papers';
import Presses from './../Form/Presses';
import Coatings from './../Form/Coatings';
import axios from 'axios';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const MultiPageForm = (props) => {
    const [pressCount, setPressCount] = useState(100);
    const [binderyCount, setBinderyCount] = useState(100);

    const [quantity, setQuantity] = useState('');
    const [publicationType, setPublicationType] = useState('1');
    const [pages, setPages] = useState('');
    const [bindingEdge, setBindingEdge] = useState('1');
    const [coverPressId, setCoverPressId] = useState('');
    const [coverRunningStyle, setCoverRunningStyle] = useState('');
    const [pressId, setPressId] = useState('');
    const [runningStyle, setRunningStyle] = useState('');
    const [secondPressId, setSecondPressId] = useState('');
    const [secondRunningStyle, setSecondRunningStyle] = useState('');
    const [finishWidth, setFinishWidth] = useState('');
    const [finishHeight, setFinishHeight] = useState('');
    const [interiorInks, setInteriorInks] = useState('4');
    const [frontInks, setFrontInks] = useState('4');
    const [backInks, setBackInks] = useState('4');
    const [paperCategoryId, setPaperCategoryId] = useState('');
    const [paperWeight, setPaperWeight] = useState('');
    const [paperId, setPaperId] = useState('');
    const [coverPaperCategoryId, setCoverPaperCategoryId] = useState('');
    const [coverPaperWeight, setCoverPaperWeight] = useState('');
    const [coverPaperId, setCoverPaperId] = useState('');
    const [frontCoatingId, setCoatingFrontId] = useState('');
    const [backCoatingId, setCoatingBackId] = useState('');
    const [interiorCoatingId, setInteriorCoatingId] = useState('');

    const [presses, setPresses] = useState([]);
    const [paperCategories, setPaperCategories] = useState([]);
    const [paperWeights, setPaperWeights] = useState([]);
    const [papers, setPapers] = useState([]);
    const [coverPaperCategories, setCoverPaperCategories] = useState([]);
    const [coverPaperWeights, setCoverPaperWeights] = useState([]);
    const [coverPapers, setCoverPapers] = useState([]);
    const [coatings, setCoatings] = useState([]);
    const [isEstimating, setIsEstimating] = useState(false);

    const onChangeHandler = (callback, event) => {
        callback(event.target.value);
    };

    const requiresCover = () => {
        const publicationTypeInt = parseInt(publicationType);
        return publicationTypeInt === 2 ||
            publicationTypeInt === 3 ||
            publicationTypeInt === 6 ||
            publicationTypeInt === 7;
    };

    const getPressTooltipInfo = (result, isCover = false) => {
        return (
            <div className="text-left">
                <div>ID: {result.press_information.id}.</div>
                <div>Name: {result.press_information.name.trim()}.</div>
                <div>Number-Up: {numeral(isCover ? result.paper_information.pss.up : result.paper_information.imposition_out).format('0,0')}.</div>
                <div>Pss Required: {numeral(result.paper_information.pss.number_pss).format('0,0')}.</div>
                <div>Make Ready: {numeral(result.paper_information.pss.make_ready_spoilage).format('0,0')}.</div>
                <div>Bindery Make Ready: {numeral(result.paper_information.pss.bindery_make_ready).format('0,0')}.</div>
                <div>Total Pss: {numeral(result.paper_information.pss.total_pss).format('0,0')}.</div>
                <div>Total Pars: {numeral(result.paper_information.total_pars).format('0,0')}.</div>
                <div>Speed: {numeral(result.press_information.speed).format('0,0')}.</div>
                <div>Total Plates: {numeral(result.press_information.total_plates).format('0,0')}.</div>
                <div>Running Style: {isCover ? result.paper_information.running_style_name : result.paper_information.imposition_name}.</div>
                <div>Setup Time: {numeral(result.press_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total Time: {numeral(Math.ceil(result.press_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getCuttingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.cutting_information.id}.</div>
                <div>Name: {result.cutting_information.name}.</div>
                <div>Quantity: {numeral(result.cutting_information.quantity * result.paper_information.pss.up).format('0,0')}.</div>
                <div>Setup Time: {numeral(result.cutting_information.makeready_time * 60).format('0,0')} minutes.</div>
                <div>Total Time: {numeral(Math.ceil(result.cutting_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getFoldingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.folding_information.id}.</div>
                <div>Name: {result.folding_information.name}.</div>
                <div>Quantity: {numeral(result.folding_information.quantity).format('0,0')}.</div>
                <div>Speed: {numeral(result.folding_information.speed).format('0,0')}.</div>
                <div>Setup Time: {numeral(result.folding_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total Time: {numeral(Math.ceil(result.folding_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getStitchingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.binding_information.id}.</div>
                <div>Name: {result.binding_information.name}.</div>
                <div>Quantity: {numeral(result.binding_information.books).format('0,0')}.</div>
                <div>Speed: {numeral(result.binding_information.quantity_per_hour).format('0,0')} per hour.</div>
                <div>Setup Time: {numeral(result.binding_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total Time: {numeral(Math.ceil(result.binding_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const estimateHandler = () => {
        if (
            quantity === '' ||
            pages === '' ||
            pressId === '' ||
            runningStyle === '' ||
            secondPressId === '' ||
            secondRunningStyle === '' ||
            finishWidth === '' ||
            finishHeight === '' ||
            interiorInks === '' ||
            paperCategoryId === '' ||
            paperWeight === '' ||
            paperId === ''
        ) {
            showNotification('Complete Fields', 'Please complete all the fields before continue', 'info');
            return;
        }

        if (requiresCover() && (
            coverPaperCategoryId === '' ||
            coverPaperWeight === '' ||
            coverPaperId === '' ||
            coverPressId === '' ||
            coverRunningStyle === '' ||
            frontInks === '' ||
            backInks === ''
        )) {
            showNotification('Complete Fields', 'Please complete all the fields before continue', 'info');
            return;
        }

        setIsEstimating(true);
        props.resetEstimateDepartment('multi-page');
        axios.post(process.env.REACT_APP_API_URL + '/dpt-estimate/estimate',
            {
                product_type: props.productType,
                quantity: quantity,
                publication_type: publicationType,
                pages: pages,
                binding_edge: bindingEdge,
                interior_inks: interiorInks,
                front_inks: frontInks,
                back_inks: backInks,
                fss_width: finishWidth,
                fss_length: finishHeight,
                cover_press_id: coverPressId,
                cover_running_style: coverRunningStyle,
                press_id: pressId,
                running_style: runningStyle,
                second_press_id: secondPressId,
                second_running_style: secondRunningStyle,
                paper_category_id: paperCategoryId,
                paper_weight: paperWeight,
                paper_id: paperId,
                cover_paper_category_id: coverPaperCategoryId,
                cover_paper_weight: coverPaperWeight,
                cover_paper_id: coverPaperId,
                use_counting: 0,
                use_shrink_wrapping: 0,
                front_coating: frontCoatingId,
                back_coating: backCoatingId,
                interior_coating: interiorCoatingId,
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                let pressCountTemp = pressCount;
                let binderyCountTemp = binderyCount;

                if (response.data.results.length > 0) {
                    const result = response.data.results[0];
                    const coverResult = response.data.cover_results && response.data.cover_results.length > 0 ? response.data.cover_results[0] : null;

                    if (coverResult && coverResult.press_information.total_time) {
                        props.setSummaryElement(
                            'dptPress',
                            pressCountTemp + coverResult.press_information.id,
                            coverResult.press_information.name.trim() + ' (Cover) [Multi-Page]',
                            coverResult.press_information.quantity,
                            Math.ceil(coverResult.press_information.total_time * 60),
                            getPressTooltipInfo(coverResult, true),
                            'multi-page'
                        );
                        pressCountTemp++;
                    }

                    let i = 0;
                    for (const imposition of result.impositions_information) {
                        i++;
                        let label = '';
                        if (coverResult && result.impositions_information.length === 1) {
                            label = ' (Interior)';
                        } else if (result.impositions_information.length > 1) {
                            label = ' (Interior ' + i + ')';
                        }

                        props.setSummaryElement(
                            'dptPress',
                            pressCountTemp + imposition.press_information.id,
                            imposition.press_information.name.trim() + label + ' [Multi-Page]',
                            imposition.press_information.quantity,
                            Math.ceil(imposition.press_information.total_time * 60),
                            getPressTooltipInfo(imposition),
                            'multi-page'
                        );
                        pressCountTemp++;
                    }
                    setPressCount(pressCountTemp);

                    if (coverResult && coverResult.cutting_information.total_time) {
                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + coverResult.cutting_information.id,
                            'Cutting (Cover) [Multi-Page]',
                            coverResult.cutting_information.quantity * coverResult.paper_information.pss.up,
                            Math.ceil(coverResult.cutting_information.total_time * 60),
                            getCuttingTooltipInfo(coverResult),
                            'multi-page'
                        );
                        binderyCountTemp++;
                    }

                    i = 0;
                    for (const imposition of result.impositions_information) {
                        i++;
                        let label = '';
                        if (coverResult && result.impositions_information.length === 1) {
                            label = ' (Interior)';
                        } else if (result.impositions_information.length > 1) {
                            label = ' (Interior ' + i + ')';
                        }

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + imposition.cutting_information.id,
                            'Cutting' + label + ' [Multi-Page]',
                            imposition.cutting_information.quantity * imposition.paper_information.pss.up,
                            Math.ceil(imposition.cutting_information.total_time * 60),
                            getCuttingTooltipInfo(imposition),
                            'multi-page'
                        );
                        binderyCountTemp++;
                    }

                    if (coverResult && coverResult.folding_information.total_time) {
                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + coverResult.folding_information.id,
                            'Folding (Cover) [Multi-Page]',
                            coverResult.folding_information.quantity,
                            Math.ceil(coverResult.folding_information.total_time * 60),
                            getFoldingTooltipInfo(coverResult),
                            'multi-page'
                        );
                        binderyCountTemp++;
                    }

                    i = 0;
                    for (const imposition of result.impositions_information) {
                        i++;
                        let label = '';
                        if (coverResult && result.impositions_information.length === 1) {
                            label = ' (Interior)';
                        } else if (result.impositions_information.length > 1) {
                            label = ' (Interior ' + i + ')';
                        }

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + imposition.folding_information.id,
                            'Folding' + label + ' [Multi-Page]',
                            imposition.folding_information.quantity,
                            Math.ceil(imposition.folding_information.total_time * 60),
                            getFoldingTooltipInfo(imposition),
                            'multi-page'
                        );
                        binderyCountTemp++;
                    }

                    if (result.binding_information.total_time) {
                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + result.binding_information.id,
                            'Stitching [Multi-Page]',
                            result.binding_information.books,
                            Math.ceil(result.binding_information.total_time * 60),
                            getStitchingTooltipInfo(result),
                            'multi-page'
                        );
                        binderyCountTemp++;
                    }

                    setBinderyCount(binderyCountTemp);
                } else {
                    showNotification('No Results', 'There are no results for this job configuration, please try again with a different one.', 'info');
                }
                setIsEstimating(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setIsEstimating(false);
            });
    };

    let runningStyleOptions = [<option key="0" value="">Select Press</option>];
    if (pressId !== '') {
        const press = presses.find((press) => parseInt(press.id) === parseInt(pressId));
        runningStyleOptions = [];
        if (parseInt(press.is_perfecting)) {
            runningStyleOptions.push(<option key="0" value="">Select Option</option>);
            runningStyleOptions.push(<option key="perfecting" value="Perfecting">Perfect</option>);
        } else {
            runningStyleOptions.push(<option key="0" value="">Select Option</option>);
            runningStyleOptions.push(<option key="sw" value="SW">SW</option>);
            runningStyleOptions.push(<option key="wt" value="WT">WT</option>);
        }
    }

    let secondRunningStyleOptions = [<option key="0" value="">Select Press</option>];
    if (secondPressId !== '') {
        const press = presses.find((press) => parseInt(press.id) === parseInt(secondPressId));
        secondRunningStyleOptions = [];
        if (parseInt(press.is_perfecting)) {
            secondRunningStyleOptions.push(<option key="0" value="">Select Option</option>);
            secondRunningStyleOptions.push(<option key="perfecting" value="Perfecting">Perfect</option>);
        } else {
            secondRunningStyleOptions.push(<option key="0" value="">Select Option</option>);
            secondRunningStyleOptions.push(<option key="sw" value="SW">SW</option>);
            secondRunningStyleOptions.push(<option key="wt" value="WT">WT</option>);
        }
    }

    let coverRunningStyleOptions = [<option key="0" value="">Select Press</option>];
    if (coverPressId !== '') {
        const press = presses.find((press) => parseInt(press.id) === parseInt(coverPressId));
        coverRunningStyleOptions = [];
        if (parseInt(press.is_perfecting)) {
            coverRunningStyleOptions.push(<option key="0" value="">Select Option</option>);
            coverRunningStyleOptions.push(<option key="perfecting" value="Perfecting">Perfect</option>);
        } else {
            coverRunningStyleOptions.push(<option key="0" value="">Select Option</option>);
            coverRunningStyleOptions.push(<option key="sw" value="SW">SW</option>);
            coverRunningStyleOptions.push(<option key="wt" value="WT">WT</option>);
        }
    }

    const pressField = (
        <Presses
            productType={props.productType}
            pressId={pressId}
            presses={presses}
            setPresses={setPresses}
            setPressId={setPressId}
            setPaperCategories={setPaperCategories}
            setPaperCategoryId={setPaperCategoryId}
            setPaperWeights={setPaperWeights}
            setPaperWeight={setPaperWeight}
            setPapers={setPapers}
            setPaperId={setPaperId}
            setRunningStyle={setRunningStyle}
        />
    );
    const pressRunningStyleField = (
        <div className="field">
            <div className="label">Style:</div>
            <select value={runningStyle} onChange={onChangeHandler.bind(this, setRunningStyle)} disabled={pressId === ''}>
                {runningStyleOptions}
            </select>
        </div>
    );
    const secondPressField = (
        <Presses
            productType={props.productType}
            pressId={secondPressId}
            presses={presses}
            setPresses={() => { }}
            setPressId={setSecondPressId}
            setPaperCategories={() => { }}
            setPaperCategoryId={() => { }}
            setPaperWeights={() => { }}
            setPaperWeight={() => { }}
            setPapers={() => { }}
            setPaperId={() => { }}
            setRunningStyle={setSecondRunningStyle}
            noFetchPresses={true}
        />
    );
    const secondPressRunningStyleField = (
        <div className="field">
            <div className="label">Style:</div>
            <select value={secondRunningStyle} onChange={onChangeHandler.bind(this, setSecondRunningStyle)} disabled={secondPressId === ''}>
                {secondRunningStyleOptions}
            </select>
        </div>
    );
    const paperCategoryField = (
        <PaperCategories
            productType={props.productType}
            pressId={pressId}
            secondPressId={pressId}
            paperCategoryId={paperCategoryId}
            paperCategories={paperCategories}
            setPaperCategories={setPaperCategories}
            setPaperCategoryId={setPaperCategoryId}
            setPaperWeights={setPaperWeights}
            setPaperWeight={setPaperWeight}
            setPapers={setPapers}
            setPaperId={setPaperId}
        />
    );
    const paperWeightField = (
        <PaperWeights
            productType={props.productType}
            pressId={pressId}
            secondPressId={pressId}
            paperCategoryId={paperCategoryId}
            paperWeight={paperWeight}
            paperWeights={paperWeights}
            setPaperWeights={setPaperWeights}
            setPaperWeight={setPaperWeight}
            setPapers={setPapers}
            setPaperId={setPaperId}
        />
    );
    const paperField = (
        <Papers
            productType={props.productType}
            pressId={pressId}
            secondPressId={pressId}
            paperCategoryId={paperCategoryId}
            paperWeight={paperWeight}
            paperId={paperId}
            papers={papers}
            setPapers={setPapers}
            setPaperId={setPaperId}
        />
    );
    const coverPressField = (
        <Presses
            productType={1}
            pressId={coverPressId}
            presses={presses}
            setPresses={setPresses}
            setPressId={setCoverPressId}
            setPaperCategories={setCoverPaperCategories}
            setPaperCategoryId={setCoverPaperCategoryId}
            setPaperWeights={setCoverPaperWeights}
            setPaperWeight={setCoverPaperWeight}
            setPapers={setCoverPapers}
            setPaperId={setCoverPaperId}
            setRunningStyle={setCoverRunningStyle}
        />
    );
    const coverPressRunningStyle = (
        <div className="field">
            <div className="label">Style:</div>
            <select value={coverRunningStyle} onChange={onChangeHandler.bind(this, setCoverRunningStyle)} disabled={coverPressId === ''}>
                {coverRunningStyleOptions}
            </select>
        </div>
    );
    const coverPaperCategoryField = (
        <PaperCategories
            productType={1}
            pressId={coverPressId}
            paperCategoryId={coverPaperCategoryId}
            paperCategories={coverPaperCategories}
            setPaperCategories={setCoverPaperCategories}
            setPaperCategoryId={setCoverPaperCategoryId}
            setPaperWeights={setCoverPaperWeights}
            setPaperWeight={setCoverPaperWeight}
            setPapers={setCoverPapers}
            setPaperId={setCoverPaperId}
        />
    );
    const coverPaperWeightField = (
        <PaperWeights
            productType={1}
            pressId={coverPressId}
            paperCategoryId={coverPaperCategoryId}
            paperWeight={coverPaperWeight}
            paperWeights={coverPaperWeights}
            setPaperWeights={setCoverPaperWeights}
            setPaperWeight={setCoverPaperWeight}
            setPapers={setCoverPapers}
            setPaperId={setCoverPaperId}
        />
    );
    const coverPaperField = (
        <Papers
            productType={1}
            pressId={coverPressId}
            paperCategoryId={coverPaperCategoryId}
            paperWeight={coverPaperWeight}
            paperId={coverPaperId}
            papers={coverPapers}
            setPapers={setCoverPapers}
            setPaperId={setCoverPaperId}
        />
    );

    let interiorInkTitle = '';
    let coverInkFields = null;
    let bottomPart = null;
    if (parseInt(publicationType) === 1) {
        interiorInkTitle = 'Ink';
        bottomPart = (
            <div className="columns-container">
                <div className="press-configuration">
                    <h3>Press Configuration</h3>
                    {pressField}
                    {pressRunningStyleField}
                    <h3 className="second-h3">Odd Form</h3>
                    {secondPressField}
                    {secondPressRunningStyleField}
                </div>
                <div className="stock-configuration">
                    <h3>Stock Configuration</h3>
                    {paperCategoryField}
                    {paperWeightField}
                    {paperField}
                    <div className="notes-container">
                        <div className="field">
                            <div className="label">Notes:</div>
                            <input type="text" />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        interiorInkTitle = 'Interior Ink';
        coverInkFields = (
            <Fragment>
                <div className="divider"></div>
                <div className="form-section cover-inks-configuration">
                    <h3>Cover Ink</h3>
                    <div className="multiple-fields">
                        <div className="field">
                            <div className="label">Front Inks</div>
                            <select value={frontInks} onChange={onChangeHandler.bind(this, setFrontInks)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Back Inks</div>
                            <select value={backInks} onChange={onChangeHandler.bind(this, setBackInks)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                    </div>
                    <div className="field pms-color">
                        <div className="label">PMS Color</div>
                        <input type="text" />
                    </div>
                    <Coatings
                        label="Coating Front"
                        productType={props.productType}
                        coatingId={frontCoatingId}
                        coatings={coatings}
                        setCoatings={setCoatings}
                        setCoatingId={setCoatingFrontId}
                    />
                    <Coatings
                        label="Coating Back"
                        productType={props.productType}
                        coatingId={backCoatingId}
                        coatings={coatings}
                        setCoatings={setCoatings}
                        setCoatingId={setCoatingBackId}
                    />
                </div>
            </Fragment>
        );
        bottomPart = (
            <Fragment>
                <div className="columns-container">
                    <div className="press-configuration">
                        <h3>Cover Press</h3>
                        {coverPressField}
                        {coverPressRunningStyle}
                        {coverPaperCategoryField}
                        {coverPaperWeightField}
                        {coverPaperField}
                    </div>
                    <div className="stock-configuration">
                        <h3>Interior Press</h3>
                        {pressField}
                        {pressRunningStyleField}
                        {paperCategoryField}
                        {paperWeightField}
                        {paperField}
                    </div>
                </div>
                <div className="cover-odd">
                    <h3>Odd Form</h3>
                    {secondPressField}
                    {secondPressRunningStyleField}
                </div>
                <div className="cover-notes-container">
                    <div className="field">
                        <div className="label">Notes:</div>
                        <input type="text" />
                    </div>
                </div>
            </Fragment>
        );
    }

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.multiPage) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    const resetDepartmentHandler = () => {
        props.resetEstimateDepartment('multi-page');

        setPressCount(100);
        setBinderyCount(100);

        setQuantity('');
        setPublicationType('1');
        setPages('');
        setBindingEdge('1');
        setCoverPressId('');
        setCoverRunningStyle('');
        setPressId('');
        setRunningStyle('');
        setFinishWidth('');
        setFinishHeight('');
        setInteriorInks('4');
        setFrontInks('4');
        setBackInks('4');
        setPaperCategoryId('');
        setPaperWeight('');
        setPaperId('');
        setCoverPaperCategoryId('');
        setCoverPaperWeight('');
        setCoverPaperId('');
        setCoatingFrontId('');
        setCoatingBackId('');
        setInteriorCoatingId('');

        setPaperCategories([]);
        setPaperWeights([]);
        setPapers([]);
        setCoverPaperCategories([]);
        setCoverPaperWeights([]);
        setCoverPapers([]);
    };

    return (
        <div className="estimate-form multi-page-form">
            <h2>
                <div>Multi-Page</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={resetDepartmentHandler}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'multiPage')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.multiPage ? {} : { 'display': 'none' }}>
                <h3>
                    <div>Product Configuration</div>
                    <div>
                        <div className="field">
                            <div className="label">Type:</div>
                            <select value={publicationType} onChange={onChangeHandler.bind(this, setPublicationType)}>
                                <option value="1">Self-Cover</option>
                                <option value="2">Plus-Cover</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className="field">
                            <div className="label">Quantity:</div>
                            <input type="number" min="0" value={quantity} onChange={onChangeHandler.bind(this, setQuantity)} />
                        </div>
                    </div>
                </h3>
                <div className="form">
                    <div className="divider"></div>
                    <div className="form-section product-configuration">
                        <div className="field">
                            <div className="label">Pages Count</div>
                            <input type="number" min="0" value={pages} onChange={onChangeHandler.bind(this, setPages)} />
                        </div>
                        <div className="field">
                            <div className="label">Binding Edge</div>
                            <select value={bindingEdge} onChange={onChangeHandler.bind(this, setBindingEdge)}>
                                <option value="1">Width</option>
                                <option value="2">Height</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Binding Type</div>
                            <select>
                                <option value="1">Saddle</option>
                            </select>
                        </div>
                        <div className="arrows"><i className="fas fa-angle-double-right" /></div>
                        <div className="field">
                            <div className="label">Finish Width</div>
                            <input type="number" min="0" value={finishWidth} onChange={onChangeHandler.bind(this, setFinishWidth)} />
                        </div>
                        <div className="field">
                            <div className="label">Finish Height</div>
                            <input type="number" min="0" value={finishHeight} onChange={onChangeHandler.bind(this, setFinishHeight)} />
                        </div>
                    </div>
                    {coverInkFields}
                    <div className="divider"></div>
                    <div className="form-section inks-configuration">
                        <h3>{interiorInkTitle}</h3>
                        <div className="field">
                            <div className="label">Inks</div>
                            <select value={interiorInks} onChange={onChangeHandler.bind(this, setInteriorInks)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="field pms-color">
                            <div className="label">PMS Color</div>
                            <input type="text" />
                        </div>
                        <Coatings
                            label="Coating"
                            productType={props.productType}
                            coatingId={interiorCoatingId}
                            coatings={coatings}
                            setCoatings={setCoatings}
                            setCoatingId={setInteriorCoatingId}
                        />
                    </div>
                    {bottomPart}
                </div>
                <div className="button-container">
                    <button onClick={estimateHandler} disabled={isEstimating}>{isEstimating ? <i className="fas fa-circle-notch fa-spin" /> : 'Estimate'}</button>
                </div>
                <AccountingForm
                    department="multiPage"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default MultiPageForm;