import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification, validateAuthInResponse } from './../../utils';
import axios from 'axios';

export const fetchElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let fields = [
        'id',
        'date',
        'description',
        'invoice_size_name',
        'invoice_stock_name',
        'ink_name',
        'invoice_machine_name',
        'quantity',
        'landrys',
        'per_unit',
        'larry',
        'larry_per_unit',
        'ac',
        'ac_per_unit',
        'robert',
        'ps',
        'pm',
    ];

    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',')
    }

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.search + '?fields=' + fields.join(',') + '&selectedPage=' + selectedPage
        + '&sort=' + column + '&filter=' + filter + '&summary=1',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENTS_FAILURE));
        });
}

export const saveElement = values => (dispatch, getState) => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['landrys_invoice_size_id', 'landrys_invoice_stock_id', 'ink',
        'quantity', 'landrys', 'larry', 'ps', 'pm', 'date',
        'landrys_invoice_machine_id', 'description'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_ELEMENT_FAILURE));
        });
}

export const deleteElement = (id) => (dispatch, getState) => {
    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.DELETE_ELEMENT_FAILURE));
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function setActiveElement(element) {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export const fetchData = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_DATA, payload: null });
    axios.get(apiUrls.data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_DATA_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_DATA_FAILURE));
        });
}

export const addSize = values => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ADD_SIZE, payload: null });
    axios.get(apiUrls.sizes, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ADD_SIZE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('add-size'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(fetchData());
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.ADD_SIZE_FAILURE));
        });
}

export const addStock = values => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ADD_STOCK, payload: null });
    axios.get(apiUrls.stocks, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ADD_STOCK_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('add-stock'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(fetchData());
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.ADD_STOCK_FAILURE));
        });
}

export const addMachine = values => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ADD_MACHINE, payload: null });
    axios.get(apiUrls.machines, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ADD_MACHINE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('add-machine'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(fetchData());
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.ADD_MACHINE_FAILURE));
        });
}

export function exportExcel() {
    return (dispatch, getState) => {
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());
        const filterFields = selectors.getFilterFields(getState());

        let filterNames = [];
        let filterValues = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterNames.push(filterFields[i]['name']);
            filterValues.push(filterFields[i]['value']);
        }

        window.location.href = apiUrls.export + '?sort-column=' + sortColumn + '&sort-type='
            + sortType + '&filter-names=' + filterNames.join(',') + '&filter-values='
            + filterValues.join(',');
    };
}

export function setActivePage(page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: page
    };
}

export const fetchElement = id => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement(response.data));
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENT_FAILURE));
        });
}
