import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const PaperCategories = (props) => {
    const [fetchingPaperCategories, setFetchingPaperCategories] = useState(false);

    const productType = props.productType;
    const pressId = props.pressId;
    const secondPressId = props.secondPressId;
    const digitalMachineId = props.digitalMachineId;
    const setPaperCategories = props.setPaperCategories;
    useEffect(() => {
        const productTypeInt = parseInt(productType);
        if (productTypeInt === 1 && pressId === '') {
            return;
        } else if (productTypeInt === 2 && (pressId === '' || secondPressId === '')) {
            return;
        } else if (productTypeInt === 3 && digitalMachineId === '') {
            return;
        }

        let url = process.env.REACT_APP_API_URL + '/dpt-estimate/paper-categories?type=' + productType;
        if (productTypeInt === 1) {
            url += '&pressId=' + pressId;
        } else if (productTypeInt === 2) {
            url += '&isCover=0&pressId=' + pressId + '&secondPressId=' + secondPressId;
        } else if (productTypeInt === 3) {
            url += '&digitalMachineId=' + digitalMachineId;
        }

        setFetchingPaperCategories(true);
        axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setPaperCategories(response.data);
                setFetchingPaperCategories(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingPaperCategories(false);
            });
    }, [productType, pressId, secondPressId, digitalMachineId, setPaperCategories]);

    const changeHandler = (event) => {
        props.setPaperCategoryId(event.target.value);

        props.setPaperWeights([]);
        props.setPaperWeight('');

        props.setPapers([]);
        props.setPaperId('');
    };

    let options = [];
    if (props.pressId === '') {
        options.push(<option key="0" value="">Select a Press</option>);
    } else {
        options = props.paperCategories.map((paperCategory) => <option key={paperCategory.id} value={paperCategory.id}>{paperCategory.category_name}</option>);
        options.unshift(<option key="0" value="">Select option</option>);
    }

    let disabled = false;
    if (parseInt(productType) === 1 && pressId === '') {
        disabled = true;
    } else if (parseInt(productType) === 2 && (pressId === '' || secondPressId === '')) {
        disabled = true;
    } else if (parseInt(productType) === 3 && digitalMachineId === '') {
        disabled = true;
    }

    return (
        <div className="field">
            <div className="label">{props.label ? props.label : 'Category'}:</div>
            <select value={props.paperCategoryId} onChange={changeHandler} disabled={disabled}>
                {options}
            </select>
            {fetchingPaperCategories && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default PaperCategories;