import Immutable from 'immutable';
import * as types from './DptInkjetMaterials.types';

const initialState = {
    fields: {},
    isSaving: false,
    isFetchingData: false,
    isFetchingElement: false,
    data: [],
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_DATA:
            return state.setIn(['isFetchingData'], true).toJS();

        case types.FETCH_DATA_SUCCESS:
            return state.setIn(['isFetchingData'], false)
                .setIn(['data'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_DATA_ERROR:
            return state.setIn(['isFetchingData'], false).toJS();

        case types.SAVE:
            return state.setIn(['isSaving'], true).toJS();

        case types.SAVE_SUCCESS:
            return state.setIn(['isSaving'], false)
                .setIn(['fields'], action.payload)
                .toJS();

        case types.SAVE_ERROR:
            return state.setIn(['isSaving'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['fields', action.payload.name], action.payload.value).toJS();

        case types.FETCH_ELEMENT:
            return state.setIn(['isFetchingElement'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['isFetchingElement'], false)
                .setIn(['fields'], action.payload)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['isFetchingElement'], false).toJS();

        case types.RESET_FORM:
            return state.setIn(['fields'], {}).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        default:
            return state.toJS();
    }
}