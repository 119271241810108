import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class DeleteFile extends Component {
    static propTypes = {
        handleDeleteFile: PropTypes.func,
        hideDeleteFile: PropTypes.func,
        isDeletingFile: PropTypes.bool
    };

    render() {
        const { hideDeleteFile, handleDeleteFile, isDeletingFile } = this.props;

        let deleteButtonLabel = 'Delete';
        if (isDeletingFile) {
            deleteButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleDeleteFile}
                disabled={isDeletingFile}>{deleteButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel"
                onClick={hideDeleteFile}>Cancel</button>
        ];
        return (
            <Dialog
                name="delete-file"
                modal={false}
                actions={actions}
            >
                <div className="delete-container">
                    Are you sure to delete this item? This action cannot be undone
                </div>
            </Dialog>
        );
    }
}

export default DeleteFile;
