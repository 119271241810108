import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    static propTypes = {

    };

    renderInput(label, name, type, required, className = '') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={'input-container ' + className} key={name}>
                <div className="label-input"><label>{label} {required ? <span className="required">*</span> : null}</label></div>
                <div className="field-input">
                    <Input
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                    />
                </div>
            </div>
        );
    }

    renderSelect(label, name, options, required, className = '') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={'input-container ' + className} key={name}>
                <div className="label-input"><label>{label} {required ? <span className="required">*</span> : null}</label></div>
                <div className="field-input">
                    <Select
                        name={name}
                        required={required}
                        options={options}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="subtitle">Customer Discount Data</div>
                <div className="card mb-2">
                    <div className="card-header p-2">Customer Discount Information</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderInput('Name', 'name', 'text', true)}
                        {this.renderSelect('Type', 'type', [
                            { label: 'Discount', value: 1 },
                            { label: 'Markup', value: 2 }
                        ], true, 'half-field')}
                        {this.renderInput('Value', 'value', 'text', true, 'half-field')}
                    </div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
                className="small-dialog"
            >
                <div className="form-container form-modal-container customer-discounts-form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
