import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getModel = state => state[NAME];

/** activeElement **/
export const getActiveElementModel = createSelector(
    getModel,
    (model) => model.activeElement
);

/** activeElement **/
export const getActiveElement = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.data
);
export const getActiveProduct = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.product
);
export const getIsFetchingElement = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.isFetching
);
export const getConfiguration = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.configuration
);

/** form **/
export const getForm = createSelector(
    getModel,
    (model) => model.form
);
export const getFormValues = createSelector(
    getForm,
    (form) => form.formValues
);
export const getIsSavingForm = createSelector(
    getForm,
    (form) => form.isSaving
);
export const getFormErrors = createSelector(
    getForm,
    (form) => form.formErrors
);
export const getFormErrorsMessages = createSelector(
    getForm,
    (form) => form.formErrorMessages
);
export const getEstimateAccessories = createSelector(
    getForm,
    (form) => form.formValues.estimate_accessories
);

/** elements **/
export const getElements = createSelector(
    getModel,
    (model) => model.elements.data
);
export const getIsFetchingElements = createSelector(
    getModel,
    (model) => model.elements.isFetching
);

/** delete **/
export const getIsDeleting = createSelector(
    getModel,
    (model) => model.delete.isDeleting
);
export const getIdForDelete = createSelector(
    getModel,
    (model) => model.delete.id
);

/** pagination **/
export const getTotalPages = createSelector(
    getModel,
    (model) => model.pagination.totalPages
);
export const getSelectedPage = createSelector(
    getModel,
    (model) => model.pagination.selectedPage
);
export const getElementsPerPage = createSelector(
    getModel,
    (model) => model.pagination.elementsPerPage
);

/** sort **/
export const getSortColumn = createSelector(
    getModel,
    (model) => model.sort.column
);
export const getSortType = createSelector(
    getModel,
    (model) => model.sort.type
);

/** customer **/
export const getCustomerId = createSelector(
    getModel,
    (model) => model.customer.id
);
export const getEditingCustomer = createSelector(
    getModel,
    (model) => model.customer.editing
);

/** quote **/
export const getQuoteId = createSelector(
    getModel,
    (model) => model.quote.id
);
export const getQuote = createSelector(
    getModel,
    (model) => model.quote.data
);
export const getIsFetchingQuote = createSelector(
    getModel,
    (model) => model.quote.isFetching
);
export const getIsSending = createSelector(
    getModel,
    (model) => model.quote.isSending
);

/** information **/
export const getIsFetchingInformation = createSelector(
    getModel,
    (model) => model.information.isFetching
);
export const getInformation = createSelector(
    getModel,
    (model) => model.information
);

/** estimate **/
export const getIsLoadingEstimate = createSelector(
    getModel,
    (model) => model.estimate.isLoading
);
export const getEstimateResults = createSelector(
    getModel,
    (model) => model.estimate.results
);
export const getIsSavingEstimate = createSelector(
    getModel,
    (model) => model.estimate.isSaving
);

/** order **/
export const getIsSavingOrder = createSelector(
    getModel,
    (model) => model.order.isSaving
);

/** options **/
export const getOptions = createSelector(
    getModel,
    (model) => model.options
);

/** fetching **/
export const getFetching = createSelector(
    getModel,
    (model) => model.fetching
);

export const getProductWindowId = createSelector(
    getModel,
    (model) => model.productWindow.id
);
export const getProductWindowName = createSelector(
    getModel,
    (model) => model.productWindow.name
);
export const getProductWindowType = createSelector(
    getModel,
    (model) => model.productWindow.type
);

export const getProductCustomerId = createSelector(
    getModel,
    (model) => model.productCustomer.id
);
export const getProductCustomerName = createSelector(
    getModel,
    (model) => model.productCustomer.name
);
