const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/in-out-registers`,
    getElements: `${API_URL}/in-out-register/elements`,
    getAcceptElements: `${API_URL}/in-out-register/accept-elements`,
    getReceiveElements: `${API_URL}/in-out-register/receive-elements`,
    getUnclaimElements: `${API_URL}/in-out-register/unclaim-elements`,
    getHistoryElements: `${API_URL}/in-out-register/history-elements`,
    data: `${API_URL}/in-out-register/data`,
    accept: `${API_URL}/in-out-register/accept`,
    receive: `${API_URL}/in-out-register/receive`,
    export: `${API_URL}/in-out-register/export`
};

export default apiUrls;
