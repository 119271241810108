import React, { Component } from 'react';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import PropTypes from 'prop-types';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool
    };

    render() {
        const { formValues, formErrors, hideForm, handleUpdateFormValue, handleSaveElement, isSavingForm,
            isFetchingElement, formErrorMessages } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="row">
                    <div className="col">
                        <label>Name <span className="required">*</span></label>
                        <Input
                            name="name"
                            required
                            formValues={formValues}
                            formErrors={formErrors}
                            formErrorMessages={formErrorMessages}
                            handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', true)}
                        />
                    </div>
                    <div className="col">
                        <label>Address <span className="required">*</span></label>
                        <Input
                            name="address"
                            required
                            formValues={formValues}
                            formErrors={formErrors}
                            formErrorMessages={formErrorMessages}
                            handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', true)}
                        />
                    </div>
                    <div className="w-100"></div>
                    <div className="col">
                        <label>City <span className="required">*</span></label>
                        <Input
                            name="city"
                            required
                            formValues={formValues}
                            formErrors={formErrors}
                            formErrorMessages={formErrorMessages}
                            handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', true)}
                        />
                    </div>
                    <div className="col">
                        <label>State <span className="required">*</span></label>
                        <Input
                            name="state"
                            required
                            formValues={formValues}
                            formErrors={formErrors}
                            formErrorMessages={formErrorMessages}
                            handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', true)}
                        />
                    </div>
                    <div className="w-100"></div>
                    <div className="col-6">
                        <label>Zip <span className="required">*</span></label>
                        <Input
                            name="zip"
                            required
                            formValues={formValues}
                            formErrors={formErrors}
                            formErrorMessages={formErrorMessages}
                            handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', true)}
                        />
                    </div>
                    <div className="w-100"></div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
