import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        envelopeTypes: PropTypes.array,
        paperVendors: PropTypes.array
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, envelopeTypes, paperVendors, paperCategories } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let paperCategoriesOptions = [{ label: 'Select an option', value: '' }];
        for (let i = 0; i < paperCategories.length; i++) {
            paperCategoriesOptions.push({ label: paperCategories[i]['category_name'], value: paperCategories[i]['id'] });
        }

        let envelopeTypesOptions = [{ label: 'Select an option', value: '' }];
        for (let i = 0; i < envelopeTypes.length; i++) {
            envelopeTypesOptions.push({ label: envelopeTypes[i]['name'], value: envelopeTypes[i]['id'] });
        }

        let vendorsOptions = [{ label: 'Select an option', value: '' }];
        for (let i = 0; i < paperVendors.length; i++) {
            vendorsOptions.push({ label: paperVendors[i]['name'], value: paperVendors[i]['id'] });
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">Paper</div>
                    <div className="w-100"></div>
                    {this.renderSelect('Paper Category', 'paper_category_id', paperCategoriesOptions, true)}
                    {this.renderSelect('Vendor', 'paper_vendor_id', vendorsOptions, true)}
                    <div className="w-100"></div>
                    {this.renderInput('Style', 'style', 'text', true)}
                    {this.renderSelect('Envelope Type', 'envelope_type_id', envelopeTypesOptions, true)}
                    <div className="w-100"></div>
                    {this.renderInput('Width (Size)', 'size_width', 'text', true)}
                    {this.renderInput('Height (Size)', 'size_height', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Width (Window Size)', 'window_size_width', 'text', false)}
                    {this.renderInput('Height (Window Size)', 'window_size_height', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Pose Left', 'pos_left', 'text', false)}
                    {this.renderInput('Pos Bottom', 'pos_bottom', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Description', 'description', 'decimal', true)}
                    {this.renderInput('Base Weight', 'base_weight', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Per Box', 'per_box', 'decimal', true)}
                    {this.renderInput('Per Cnt', 'per_cnt', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderInput('10 Cnt', 'ten_cnt', 'decimal', true)}
                    {this.renderInput('5 Cnt', 'five_cnt', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderInput('1 Cnt', 'one_cnt', 'decimal', true)}
                    {this.renderInput('Box', 'box', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Active', 'active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'col-6')}
                    {this.renderInput('Markup', 'markup', 'decimal', true)}
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
