import React from 'react';
import { Dialog } from './../../common/dialog';

const Form = React.memo((props) => {
    let saveButtonLabel = 'Save';
    if (props.isSavingForm) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let salesRepsOptions = [{ label: 'Select Option', value: '' }];
    for (let i = 0; i < props.salesReps.length; i++) {
        salesRepsOptions.push({ label: props.salesReps[i]['name'], value: props.salesReps[i]['id'] });
    }

    let csrOptions = [{ label: 'Select Option', value: '' }];
    for (let i = 0; i < props.csrs.length; i++) {
        csrOptions.push({ label: props.csrs[i]['name'], value: props.csrs[i]['id'] });
    }

    let customersOptions = [{ label: 'Select Option', value: '' }];
    for (let i = 0; i < props.customers.length; i++) {
        customersOptions.push({ label: props.customers[i]['display_name'], value: props.customers[i]['id'] });
    }

    let statesOptions = [{ label: 'Select', value: '' }];
    let states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas',
        'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia',
        'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana',
        'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
        'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
        'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
        'Oklahoma', 'Oregon', 'Other Links', 'Pennsylvania', 'Puerto Rico',
        'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas',
        'Tribal Governments', 'U.S. Virgin Islands', 'Utah', 'Vermont', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
    for (let i = 0; i < states.length; i++) {
        statesOptions.push({ label: states[i], value: states[i] });
    }

    let termsOptions = [{ label: 'Select Option', value: '' }];
    for (let i = 0; i < props.terms.length; i++) {
        termsOptions.push({ label: props.terms[i].label, value: props.terms[i].value });
    }

    let taxCodesOptions = [{ label: 'Select Option', value: '' }];
    for (let i = 0; i < props.taxCodes.length; i++) {
        taxCodesOptions.push({ label: props.taxCodes[i].label, value: props.taxCodes[i].value });
    }

    let paymentMethodsOptions = [{ label: 'Select Option', value: '' }];
    for (let i = 0; i < props.paymentMethods.length; i++) {
        paymentMethodsOptions.push({ label: props.paymentMethods[i].label, value: props.paymentMethods[i].value });
    }

    const handleFieldChange = (event) => {
        props.handleUpdateFormValue('text', false, event);

        if (event.target.name === 'billing_address' && props.formValues['use_billing_address']) {
            props.handleUpdateFormValueSimple('shipping_address', event.target.value);
        }
        if (event.target.name === 'billing_city' && props.formValues['use_billing_address']) {
            props.handleUpdateFormValueSimple('shipping_city', event.target.value);
        }
        if (event.target.name === 'billing_state' && props.formValues['use_billing_address']) {
            props.handleUpdateFormValueSimple('shipping_state', event.target.value);
        }
        if (event.target.name === 'billing_zip' && props.formValues['use_billing_address']) {
            props.handleUpdateFormValueSimple('shipping_zip', event.target.value);
        }
        if (event.target.name === 'billing_country' && props.formValues['use_billing_address']) {
            props.handleUpdateFormValueSimple('shipping_country', event.target.value);
        }
        if (event.target.name === 'use_billing_address' && event.target.value) {
            props.handleUpdateFormValueSimple('shipping_address', props.formValues['billing_address']);
            props.handleUpdateFormValueSimple('shipping_city', props.formValues['billing_city']);
            props.handleUpdateFormValueSimple('shipping_state', props.formValues['billing_state']);
            props.handleUpdateFormValueSimple('shipping_zip', props.formValues['billing_zip']);
            props.handleUpdateFormValueSimple('shipping_country', props.formValues['billing_country']);
        }

        if (event.target.name === 'display_name' && props.formValues['use_display_name_for_check']) {
            props.handleUpdateFormValueSimple('print_on_check_name', event.target.value);
        }
        if (event.target.name === 'use_display_name_for_check' && event.target.value) {
            props.handleUpdateFormValueSimple('print_on_check_name', props.formValues['display_name']);
        }

        if (event.target.name === 'is_sub_customer' && event.target.value) {
            props.handleUpdateFormValueSimple('parent_customer_id', '');
        }
    };

    const renderTextField = (name, options = {}) => {
        let classes = ['text-field'];
        if (options.className != null) {
            classes.push(options.className);
        }

        return (
            <div className={classes.join(' ')}>
                <input
                    type="text"
                    name={name}
                    disabled={options.disabled}
                    placeholder={options.placeholder}
                    value={props.formValues[name]}
                    onChange={handleFieldChange}
                />
            </div>
        );
    };

    const renderCheckboxField = (name, config = {}) => {
        let classes = ['checkbox-field'];
        if (config.className != null) {
            classes.push(config.className);
        }

        return (
            <input
                type="checkbox"
                name={name}
                className={classes.join(' ')}
                value={props.formValues[name]}
                checked={props.formValues[name]}
                disabled={config.disabled}
                onChange={handleFieldChange}
            />
        );
    };

    const renderSelectField = (name, options = [], config = {}) => {
        let classes = ['text-field'];
        if (config.className != null) {
            classes.push(config.className);
        }

        return (
            <div className={classes.join(' ')}>
                <select name={name} value={props.formValues[name]} onChange={handleFieldChange} disabled={config.disabled}>
                    {options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
                </select>
            </div>
        );
    };


    const renderTextareaField = (name, options = {}) => {
        let classes = ['text-field'];
        if (options.className != null) {
            classes.push(options.className);
        }

        return (
            <div className={classes.join(' ')}>
                <textarea
                    type="text"
                    name={name}
                    disabled={options.disabled}
                    placeholder={options.placeholder}
                    value={props.formValues[name]}
                    onChange={handleFieldChange}
                />
            </div>
        );
    };

    let tabsContent = null;
    if (parseInt(props.selectedTab) === 1) {
        tabsContent = (
            <div className="address-tab-container">
                <div className="billing">
                    <div className="title">Billing Address</div>
                    {renderTextField('billing_address', { className: 'address-field', placeholder: 'Street' })}
                    {renderTextField('billing_city', { className: 'city-field', placeholder: 'City/Town' })}
                    {renderTextField('billing_state', { className: 'state-field', placeholder: 'State/Province' })}
                    {renderTextField('billing_zip', { className: 'zip-field', placeholder: 'ZIP Code' })}
                    {renderTextField('billing_country', { className: 'country-field', placeholder: 'Country' })}
                </div>
                <div className="shipping">
                    <div className="title">
                        Ship To / Work For
                        <label>{renderCheckboxField('use_billing_address')} Same as billing address</label>
                    </div>
                    {renderTextField('shipping_address', { className: 'address-field', placeholder: 'Street', disabled: props.formValues['use_billing_address'] })}
                    {renderTextField('shipping_city', { className: 'city-field', placeholder: 'City/Town', disabled: props.formValues['use_billing_address'] })}
                    {renderTextField('shipping_state', { className: 'state-field', placeholder: 'State/Province', disabled: props.formValues['use_billing_address'] })}
                    {renderTextField('shipping_zip', { className: 'zip-field', placeholder: 'ZIP Code', disabled: props.formValues['use_billing_address'] })}
                    {renderTextField('shipping_country', { className: 'country-field', placeholder: 'Country', disabled: props.formValues['use_billing_address'] })}
                </div>
            </div>
        );
    } else if (parseInt(props.selectedTab) === 2) {
        tabsContent = (
            <div className="notes-tab-container">
                {renderTextareaField('notes', { placeholder: 'Notes...' })}
            </div>
        );
    } else if (parseInt(props.selectedTab) === 3) {
        tabsContent = (
            <div className="tax-tab-container">
                <div className="is-taxable-field">
                    {renderCheckboxField('is_taxable')} This customer is taxable
                </div>
                <div className="field-container default-tax-code-field">
                    <label>Default tax code</label>
                    {renderSelectField('default_tax_code', taxCodesOptions)}
                </div>
            </div>
        );
    } else if (parseInt(props.selectedTab) === 4) {
        tabsContent = (
            <div className="payment-tab-container">
                <div className="field-container preferred-payment-field">
                    <label>Preferred payment method</label>
                    {renderSelectField('preferred_payment_method', paymentMethodsOptions)}
                </div>
                <div className="field-container terms-field">
                    <label>Terms</label>
                    {renderSelectField('terms', termsOptions)}
                </div>
                <div className="field-container preferred-delivery-field">
                    <label>Preferred delivery method</label>
                    {renderSelectField('preferred_delivery_method', [
                        { value: 'Print', label: 'Print later' },
                        { value: 'Email', label: 'Send later' },
                        { value: 'None', label: 'None' },
                    ])}
                </div>
            </div>
        );
    } else if (parseInt(props.selectedTab) === 5) {
        tabsContent = (
            <div className="internal-tab-container">
                <div className="field-container sales-rep-field">
                    <label>Sales Rep</label>
                    {renderSelectField('sales_rep_id', salesRepsOptions)}
                </div>
                <div className="field-container csr-field">
                    <label>CSR</label>
                    {renderSelectField('csr_id', csrOptions)}
                </div>
                <div className="field-container shipping-method-field">
                    <label>Shipping Method</label>
                    {renderSelectField('shipping_method', [
                        { label: 'Select Option', value: '' },
                        { label: 'FedEx', value: 'FedEx' },
                        { label: 'UPS', value: 'UPS' },
                        { label: 'USPO', value: 'USPO' },
                        { label: 'DHL', value: 'DHL' },
                        { label: 'Customer Pickup', value: 'Customer Pickup' },
                        { label: 'Other', value: 'Other' }
                    ])}
                </div>
                <div className="field-container po-required-field">
                    <label>PO Required</label>
                    {renderSelectField('po_required', [
                        { label: 'Yes', value: 1 },
                        { label: 'No', value: 0 },
                    ])}
                </div>
                <div className="field-container po-required-field">
                    <label>Postage Payment exempt</label>
                    {renderSelectField('is_postage_payment_exempt', [
                        { label: 'Yes', value: 1 },
                        { label: 'No', value: 0 },
                    ])}
                </div>
                <div className="field-container discount-field">
                    <label>Discount</label>
                    {renderTextField('discount')}
                </div>
            </div>
        );
    }

    let form = (
        <form>
            <div className="subtitle">Customer Information</div>
            <div className="main-container">
                <div className="column1">
                    <div className="field-container title-field">
                        <label>Title</label>
                        {renderTextField('title')}
                    </div>
                    <div className="field-container first-name-field">
                        <label>First Name <span className="required">*</span></label>
                        {renderTextField('first_name')}
                    </div>
                    <div className="field-container middle-name-field">
                        <label>Middle Name</label>
                        {renderTextField('middle_name')}
                    </div>
                    <div className="field-container last-name-field">
                        <label>Last Name</label>
                        {renderTextField('last_name')}
                    </div>
                    <div className="field-container suffix-field">
                        <label>Suffix</label>
                        {renderTextField('suffix')}
                    </div>
                    <div className="field-container company-field">
                        <label>Company <span className="required">*</span></label>
                        {renderTextField('company')}
                    </div>
                    <div className="field-container display-name-field">
                        <label>Display name as <span className="required">*</span></label>
                        {renderTextField('display_name')}
                    </div>
                    <div className="field-container use-display-name-field">
                        <label>{renderCheckboxField('use_display_name_for_check')} Use display name</label>
                    </div>
                    <div className="field-container print-on-check-name-field">
                        <label>Print on check as</label>
                        {renderTextField('print_on_check_name', { disabled: props.formValues['use_display_name_for_check'] })}
                    </div>
                </div>
                <div className="column2">
                    <div className="field-container email-field">
                        <label>Email</label>
                        {renderTextField('email')}
                    </div>
                    <div className="field-container phone-field">
                        <label>Phone</label>
                        {renderTextField('phone')}
                    </div>
                    <div className="field-container mobile-field">
                        <label>Mobile</label>
                        {renderTextField('mobile')}
                    </div>
                    <div className="field-container fax-field">
                        <label>Fax</label>
                        {renderTextField('fax')}
                    </div>
                    <div className="field-container other-field">
                        <label>Other</label>
                        {renderTextField('other')}
                    </div>
                    <div className="field-container website-field">
                        <label>Website</label>
                        {renderTextField('website')}
                    </div>
                    <div className="field-container is-sub-customer-field">
                        <label>{renderCheckboxField('is_sub_customer', { disabled: true })} Is sub-customer</label>
                    </div>
                    <div className="field-container parent-customer-field">
                        {renderSelectField('parent_customer_id', customersOptions, { disabled: !props.formValues['is_sub_customer'] })}
                    </div>
                    <div className="field-container bill-with-field">
                        {renderSelectField('bill_with', [
                            { value: 1, label: 'Bill with parent' },
                            { value: 0, label: 'Bill this customer' },
                        ], { disabled: !props.formValues['is_sub_customer'] })}
                    </div>
                </div>
            </div>
            <div className="tabs-container">
                <div className={'tab' + (parseInt(props.selectedTab) === 1 ? ' active' : '')} onClick={props.setSelectedTab.bind(this, 1)}>Address</div>
                <div className={'tab' + (parseInt(props.selectedTab) === 2 ? ' active' : '')} onClick={props.setSelectedTab.bind(this, 2)}>Notes</div>
                <div className={'tab' + (parseInt(props.selectedTab) === 3 ? ' active' : '')} onClick={props.setSelectedTab.bind(this, 3)}>Tax Info</div>
                <div className={'tab' + (parseInt(props.selectedTab) === 4 ? ' active' : '')} onClick={props.setSelectedTab.bind(this, 4)}>Payment and Billing</div>
                <div className={'tab' + (parseInt(props.selectedTab) === 5 ? ' active' : '')} onClick={props.setSelectedTab.bind(this, 5)}>Internal</div>
            </div>
            <div className="tabs-content">
                {tabsContent}
            </div>
        </form>
    );
    if (props.isFetchingElement) {
        form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    }

    let actions = [
        <button key="save-button" className="btn btn-light btn-sm action-button" onClick={props.handleSaveElement} disabled={props.isSavingForm}>{saveButtonLabel}</button>,
        <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideForm}>Close</button>
    ];
    return (
        <Dialog
            name="form"
            modal={false}
            actions={actions}
            contentStyle={{ width: 1100, maxWidth: 1100 }}
            bodyStyle={{ overflow: 'initial' }}
            className="large-dialog customer-form"
        >
            <div className="form-container customers-form-container">{form}</div>
        </Dialog>
    );
});

Form.propTypes = {

};

export default Form;