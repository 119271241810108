import React, { Component } from 'react';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { processedElements, isFetchingElements, handleShowForm, handleShowDelete, selectedPage, elementsPerPage,
            handleChangeAttribute, handleShowSpeedTable } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (processedElements.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        let data = [];
        let startIndex = (selectedPage - 1) * elementsPerPage;
        for (let i = startIndex; i < processedElements.length && i < startIndex + elementsPerPage; i++) {
            data.push(
                <tr key={i}>
                    <td>{processedElements[i].id}</td>
                    <td>{processedElements[i].name}</td>
                    <td>{numeral(processedElements[i].labor_rate).format('$0,0.00')}</td>
                    <td>{numeral(processedElements[i].labor_markup_rate).format('0.[00]%')}</td>
                    <td>{processedElements[i].max_paper_width}x{processedElements[i].max_paper_length}</td>
                    <td>{processedElements[i].max_folds}</td>
                    <td className="editable">
                        <select name="is_active" value={processedElements[i].is_active} onChange={(event) => handleChangeAttribute(i, 'is_active', 'text', event.target.value)}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, processedElements[i].id, null)} />
                            </Tooltip>
                            <Tooltip title="Speed Table" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="far fa-clock" onClick={handleShowSpeedTable.bind(this, processedElements[i].id)} />
                            </Tooltip>
                            {/* <Tooltip title="Make Ready" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-chart-line" onClick={this.props.handleShowMakeReadyTable.bind(this, processedElements[i].id, null)} />
                            </Tooltip> */}
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, processedElements[i].id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { processedElements, totalPages, selectedPage, elementsPerPage } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('ID', 'id', { width: '6%' })}
                                {this.renderTableHeader('Name', 'name', { width: 'calc(31% - 120px)' })}
                                {this.renderTableHeader('Labor Rate', 'labor_rate', { width: '13%' })}
                                {this.renderTableHeader('Labor Rate Markup', 'labor_markup_rate', { width: '13%' })}
                                {this.renderTableHeader('Max. Paper Size', 'max_paper_width', { width: '13%' })}
                                {this.renderTableHeader('Max. Folds', 'max_folds', { width: '13%' })}
                                {this.renderTableHeader('Active', 'is_active', { width: '13%' })}
                                <th style={{ width: 110 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {this.renderSearchField('id')}
                                {this.renderSearchField('name')}
                                {this.renderSearchField('labor_rate')}
                                {this.renderSearchField('labor_markup_rate')}
                                {this.renderSearchField('max_paper_width')}
                                {this.renderSearchField('max_folds')}
                                {this.renderSearchSelect('is_active', [{ label: '', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={processedElements.length}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
