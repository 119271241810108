import React, { useState } from 'react';
import { showNotification } from './../../../utils';
import PaperCategories from './../Form/PaperCategories';
import PaperWeights from './../Form/PaperWeights';
import Papers from './../Form/Papers';
import Presses from './../Form/Presses';
import FoldingTypes from './../Form/FoldingTypes';
import Coatings from './../Form/Coatings';
import axios from 'axios';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const FlatForm = (props) => {
    const [pressCount, setPressCount] = useState(1);
    const [binderyCount, setBinderyCount] = useState(1);

    const [quantity, setQuantity] = useState('');
    const [pressId, setPressId] = useState('');
    const [runningStyle, setRunningStyle] = useState('');
    const [flatWidth, setFlatWidth] = useState('');
    const [flatHeight, setFlatHeight] = useState('');
    const [finishWidth, setFinishWidth] = useState('');
    const [finishHeight, setFinishHeight] = useState('');
    const [frontInks, setFrontInks] = useState('4');
    const [backInks, setBackInks] = useState('4');
    const [frontCoatingId, setCoatingFrontId] = useState('');
    const [backCoatingId, setCoatingBackId] = useState('');
    const [paperCategoryId, setPaperCategoryId] = useState('');
    const [paperWeight, setPaperWeight] = useState('');
    const [paperId, setPaperId] = useState('');
    const [foldingTypeId, setFoldingTypeId] = useState('');
    const [useShrinkWrapping, setUseShrinkWrapping] = useState('0');
    const [perBundle, setPerBundle] = useState('');
    const [useCounting, setUseCounting] = useState('0');
    const [useDieCutting, setUseDieCutting] = useState('0');
    const [drillingHoles, setDrillingHoles] = useState('0');
    const [drillingHolesLocation, setDrillingHolesLocation] = useState('');
    const [usePadding, setUsePadding] = useState('0');
    const [sheetsToPad, setSheetsToPad] = useState('');

    const [presses, setPresses] = useState([]);
    const [paperCategories, setPaperCategories] = useState([]);
    const [paperWeights, setPaperWeights] = useState([]);
    const [papers, setPapers] = useState([]);
    const [foldingTypes, setFoldingTypes] = useState([]);
    const [coatings, setCoatings] = useState([]);
    const [isEstimating, setIsEstimating] = useState(false);

    const getUnit = (unit, plural = true) => {
        if (parseInt(unit) === 1) {
            return plural ? 'seconds' : 'second';
        } else if (parseInt(unit) === 2) {
            return plural ? 'minutes' : 'minute';
        } else if (parseInt(unit) === 3) {
            return plural ? 'hours' : 'hour';
        } else if (parseInt(unit) === 4) {
            return plural ? 'days' : 'day';
        } else if (parseInt(unit) === 5) {
            return plural ? 'min per boxes' : 'min per box';
        } else {
            return 'N/A';
        }
    };

    const onChangeHandler = (callback, event) => {
        callback(event.target.value);
    };

    const estimateHandler = () => {
        if (
            quantity === '' ||
            pressId === '' ||
            runningStyle === '' ||
            flatWidth === '' ||
            flatHeight === '' ||
            finishWidth === '' ||
            finishHeight === '' ||
            frontInks === '' ||
            backInks === '' ||
            paperCategoryId === '' ||
            paperWeight === '' ||
            paperId === '' ||
            (drillingHoles !== '0' && drillingHolesLocation === '') ||
            (parseInt(usePadding) && sheetsToPad === '') ||
            (parseInt(useShrinkWrapping) && perBundle === '')
        ) {
            showNotification('Complete Fields', 'Please complete all the fields before continue', 'info');
            return;
        }

        setIsEstimating(true);
        props.resetEstimateDepartment('flat');
        axios.post(process.env.REACT_APP_API_URL + '/dpt-estimate/estimate',
            {
                product_type: props.productType,
                quantity: quantity,
                front_inks: frontInks,
                back_inks: backInks,
                fss_width: finishWidth,
                fss_length: finishHeight,
                flat_width: flatWidth,
                flat_length: flatHeight,
                press_id: pressId,
                paper_category_id: paperCategoryId,
                paper_weight: paperWeight,
                paper_id: paperId,
                fold_type_id: foldingTypeId,
                use_counting: useCounting,
                use_shrink_wrapping: useShrinkWrapping,
                running_style: runningStyle,
                drilling_holes: drillingHoles,
                use_padding: usePadding,
                sheets_to_pad: sheetsToPad,
                front_coating: frontCoatingId,
                back_coating: backCoatingId,
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                if (response.data.results.length > 0) {
                    const result = response.data.results[0];

                    let pressCountTemp = pressCount;
                    if (result.press_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result.press_information.id}.</div>
                                <div>Name: {result.press_information.name.trim()}.</div>
                                <div>Number-Up: {numeral(result.paper_information.pss.up).format('0,0')}.</div>
                                <div>Pss Required: {numeral(result.paper_information.pss.number_pss).format('0,0')}.</div>
                                <div>Make Ready: {numeral(result.paper_information.pss.make_ready_spoilage).format('0,0')}.</div>
                                <div>Bindery Make Ready: {numeral(result.paper_information.pss.bindery_make_ready).format('0,0')}.</div>
                                <div>Total Pss: {numeral(result.paper_information.pss.total_pss).format('0,0')}.</div>
                                <div>Total Pars: {numeral(result.paper_information.total_pars).format('0,0')}.</div>
                                <div>Speed: {numeral(result.press_information.speed).format('0,0')}.</div>
                                <div>Total Plates: {numeral(result.press_information.total_plates).format('0,0')}.</div>
                                <div>Running Style: {result.press_information.running_style}.</div>
                                <div>Setup Time: {numeral(result.press_information.setup_time * 60).format('0,0')} minutes.</div>
                                <div>Total Time: {numeral(Math.ceil(result.press_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptPress',
                            pressCountTemp + result.press_information.id,
                            result.press_information.name.trim() + ' [Flat]',
                            result.press_information.quantity,
                            Math.ceil(result.press_information.total_time * 60),
                            tooltipInfo,
                            'flat'
                        );
                        pressCountTemp++;
                    }
                    setPressCount(pressCountTemp);

                    let binderyCountTemp = binderyCount;
                    if (result.cutting_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result.cutting_information.id}.</div>
                                <div>Name: {result.cutting_information.name}.</div>
                                <div>Quantity: {numeral(result.cutting_information.quantity * result.paper_information.pss.up).format('0,0')}.</div>
                                <div>Setup Time: {numeral(result.cutting_information.makeready_time * 60).format('0,0')} minutes.</div>
                                <div>Total Time: {numeral(Math.ceil(result.cutting_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + result.cutting_information.id,
                            'Cutting [Flat]',
                            result.cutting_information.quantity * result.paper_information.pss.up,
                            Math.ceil(result.cutting_information.total_time * 60),
                            tooltipInfo,
                            'flat'
                        );
                        binderyCountTemp++;
                    }

                    if (result.folding_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result.folding_information.id}.</div>
                                <div>Name: {result.folding_information.name}.</div>
                                <div>Quantity: {numeral(result.folding_information.quantity).format('0,0')}.</div>
                                <div>Speed: {numeral(result.folding_information.speed).format('0,0')}.</div>
                                <div>Setup Time: {numeral(result.folding_information.setup_time * 60).format('0,0')} minutes.</div>
                                <div>Total Time: {numeral(Math.ceil(result.folding_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + result.folding_information.id,
                            'Folding [Flat]',
                            result.folding_information.quantity,
                            Math.ceil(result.folding_information.total_time * 60),
                            tooltipInfo,
                            'flat'
                        );
                        binderyCountTemp++;
                    }

                    if (result.counting_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result.counting_information.id}.</div>
                                <div>Quantity: {numeral(result.counting_information.total_pieces).format('0,0')}.</div>
                                <div>Timer/M-Pieces: {numeral(result.counting_information.time_per_m_pieces * 60).format('0,0')} minutes.</div>
                                <div>Setup time: {numeral(result.counting_information.setup_time * 60).format('0,0')} minutes.</div>
                                <div>Total time: {numeral(Math.ceil(result.counting_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + result.counting_information.id,
                            'Counting [Flat]',
                            result.counting_information.total_pieces,
                            Math.ceil(result.counting_information.total_time * 60),
                            tooltipInfo,
                            'flat'
                        );
                        binderyCountTemp++;
                    }

                    if (result.drilling_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result.drilling_information.id}.</div>
                                <div>Quantity: {numeral(result.drilling_information.quantity).format('0,0')}.</div>
                                <div>Paper Thickness: {result.drilling_information.thickness}.</div>
                                <div>Time/Hole: {numeral(result.drilling_information.time_per_hole * 60).format('0,0')} minutes.</div>
                                <div>Time/Additional Hole: {numeral(result.drilling_information.time_per_additional_hole * 60).format('0,0')} minutes.</div>
                                <div>Setup time: {numeral(result.drilling_information.setup_time * 60).format('0,0')} minutes.</div>
                                <div>Total time: {numeral(Math.ceil(result.drilling_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + result.drilling_information.id,
                            'Drilling [Flat]',
                            result.drilling_information.quantity,
                            Math.ceil(result.drilling_information.total_time * 60),
                            tooltipInfo,
                            'flat'
                        );
                        binderyCountTemp++;
                    }

                    if (result.padding_information.total_time) {
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ID: {result.padding_information.id}.</div>
                                <div>Quantity: {numeral(result.padding_information.quantity).format('0,0')}.</div>
                                <div>Time/M-Sheets: {numeral(result.padding_information.time_per_m_sheets * 60).format('0,0')} minutes.</div>
                                <div>Setup time: {numeral(result.padding_information.setup_time * 60).format('0,0')} minutes.</div>
                                <div>Total time: {numeral(Math.ceil(result.padding_information.total_time * 60)).format('0,0')} minutes.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + result.padding_information.id,
                            'Padding [Flat]',
                            result.padding_information.quantity,
                            Math.ceil(result.padding_information.total_time * 60),
                            tooltipInfo,
                            'flat'
                        );
                        binderyCountTemp++;
                    }

                    if (parseInt(useShrinkWrapping)) {
                        const element = props.dptBindery.find((element) => parseInt(element.id) === 5);

                        let unit = 0;
                        if (parseInt(element.unit) === 1) {     // Seconds
                            unit = (1 / 60) / element.time_per;
                        } else if (parseInt(element.unit) === 2) {      // Minutes
                            unit = 1 / element.time_per;
                        } else if (parseInt(element.unit) === 3) {      // Hours
                            unit = 60 / element.time_per;
                        } else if (parseInt(element.unit) === 4) {      // Days
                            unit = 480 / element.time_per;     // 8 Hours a day
                        }

                        let makeReadyTime = 0;
                        if (parseInt(element.makeready_unit) === 1) {     // Seconds
                            makeReadyTime = element.makeready_time * 1 / 60;
                        } else if (parseInt(element.makeready_unit) === 2) {      // Minutes
                            makeReadyTime = element.makeready_time * 1;
                        } else if (parseInt(element.makeready_unit) === 3) {      // Hours
                            makeReadyTime = element.makeready_time * 60;
                        } else if (parseInt(element.makeready_unit) === 4) {      // Days
                            makeReadyTime = element.makeready_time * 480;     // 8 Hours a day
                        }

                        const quantity = result.cutting_information.quantity * result.paper_information.pss.up;
                        const timeInMins = quantity * unit + makeReadyTime;

                        let timePerLabel = null;
                        if (parseInt(element.unit) === 5) {
                            timePerLabel = numeral(element.time_per).format('0,0') + ' ' + getUnit(element.unit, false);
                        } else {
                            timePerLabel = numeral(element.time_per).format('0,0') + ' per ' + getUnit(element.unit, false);
                        }

                        const tooltipInfo = (
                            <div className="text-left">
                                <div>Make Ready: {element.makeready_time} {getUnit(element.makeready_unit, false)}.</div>
                                <div>Production Time: {timePerLabel}.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptBindery',
                            binderyCountTemp + 50,
                            'Shrink Wrap [Flat]',
                            quantity,
                            timeInMins,
                            tooltipInfo,
                            'flat'
                        );
                        binderyCountTemp++;
                    }
                    setBinderyCount(binderyCountTemp);

                    if (parseInt(useDieCutting)) {
                        const element = props.dptOutsideService.find((element) => parseInt(element.id) === 3);
                        let unit = 0;
                        if (parseInt(element.unit) === 1) {     // Seconds
                            unit = element.time_per * 1 / 60;
                        } else if (parseInt(element.unit) === 2) {      // Minutes
                            unit = element.time_per * 1;
                        } else if (parseInt(element.unit) === 3) {      // Hours
                            unit = element.time_per * 60;
                        } else if (parseInt(element.unit) === 4) {      // Days
                            unit = element.time_per * 480;     // 8 Hours a day
                        }

                        let unitTooltip = getUnit(element.unit);
                        const tooltipInfo = (
                            <div className="text-left">
                                <div>ProProduction Time: {numeral(element.time_per).format('0,0')} {unitTooltip}.</div>
                            </div>
                        );

                        props.setSummaryElement(
                            'dptOutsideService',
                            3,
                            'Die Cutting [Flat]',
                            1,
                            unit,
                            tooltipInfo,
                            'flat'
                        );
                    }
                } else {
                    showNotification('No Results', 'There are no results for this job configuration, please try again with a different one.', 'info');
                }
                setIsEstimating(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setIsEstimating(false);
            });
    };

    let runningStyleOptions = [<option key="0" value="">Select Press</option>];
    if (pressId !== '') {
        const press = presses.find((press) => parseInt(press.id) === parseInt(pressId));
        runningStyleOptions = [];
        if (parseInt(press.is_perfecting)) {
            runningStyleOptions.push(<option key="0" value="">Select Option</option>);
            runningStyleOptions.push(<option key="perfecting" value="Perfecting">Perfect</option>);
        } else {
            runningStyleOptions.push(<option key="0" value="">Select Option</option>);
            runningStyleOptions.push(<option key="sw" value="SW">SW</option>);
            runningStyleOptions.push(<option key="wt" value="WT">WT</option>);
        }
    }

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.flat) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    const resetDepartmentHandler = () => {
        props.resetEstimateDepartment('flat');

        setPressCount(1);
        setBinderyCount(1);

        setQuantity('');
        setPressId('');
        setRunningStyle('');
        setFlatWidth('');
        setFlatHeight('');
        setFinishWidth('');
        setFinishHeight('');
        setFrontInks('4');
        setBackInks('4');
        setCoatingFrontId('');
        setCoatingBackId('');
        setPaperCategoryId('');
        setPaperWeight('');
        setPaperId('');
        setFoldingTypeId('');
        setUseShrinkWrapping('0');
        setPerBundle('');
        setUseCounting('0');
        setUseDieCutting('0');
        setDrillingHoles('0');
        setDrillingHolesLocation('');
        setUsePadding('0');
        setSheetsToPad('');

        setPaperCategories([]);
        setPaperWeights([]);
        setPapers([]);
    };

    return (
        <div className="estimate-form flat-form">
            <h2>
                <div>Flat</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={resetDepartmentHandler}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'flat')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.flat ? {} : { 'display': 'none' }}>
                <h3>
                    <div>Product Configuration</div>
                    <div>
                        <div className="field">
                            <div className="label">Quantity:</div>
                            <input type="number" min="0" value={quantity} onChange={onChangeHandler.bind(this, setQuantity)} />
                        </div>
                    </div>
                </h3>
                <div className="form">
                    <div className="divider"></div>
                    <div className="form-section product-configuration">
                        <div className="field">
                            <div className="label">Flat Width:</div>
                            <input type="number" min="0" value={flatWidth} onChange={onChangeHandler.bind(this, setFlatWidth)} />
                        </div>
                        <div className="field">
                            <div className="label">Flat Height:</div>
                            <input type="number" min="0" value={flatHeight} onChange={onChangeHandler.bind(this, setFlatHeight)} />
                        </div>
                        <div className="arrows"><i className="fas fa-angle-double-right" /></div>
                        <div className="field">
                            <div className="label">Finish Width:</div>
                            <input type="number" min="0" value={finishWidth} onChange={onChangeHandler.bind(this, setFinishWidth)} />
                        </div>
                        <div className="field">
                            <div className="label">Finish Height:</div>
                            <input type="number" min="0" value={finishHeight} onChange={onChangeHandler.bind(this, setFinishHeight)} />
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="form-section product-configuration inks-configuration">
                        <div className="field">
                            <div className="label">Front Inks</div>
                            <select value={frontInks} onChange={onChangeHandler.bind(this, setFrontInks)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Back Inks</div>
                            <select value={backInks} onChange={onChangeHandler.bind(this, setBackInks)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="field pms-color">
                            <div className="label">PMS Color</div>
                            <input type="text" />
                        </div>
                        <Coatings
                            label="Coating Front"
                            productType={props.productType}
                            coatingId={frontCoatingId}
                            coatings={coatings}
                            setCoatings={setCoatings}
                            setCoatingId={setCoatingFrontId}
                        />
                        <Coatings
                            label="Coating Back"
                            productType={props.productType}
                            coatingId={backCoatingId}
                            coatings={coatings}
                            setCoatings={setCoatings}
                            setCoatingId={setCoatingBackId}
                        />
                    </div>
                    <div className="press-stock-container">
                        <div className="press-configuration">
                            <h3>Press Configuration</h3>
                            <Presses
                                productType={props.productType}
                                pressId={pressId}
                                presses={presses}
                                setPresses={setPresses}
                                setPressId={setPressId}
                                setPaperCategories={setPaperCategories}
                                setPaperCategoryId={setPaperCategoryId}
                                setPaperWeights={setPaperWeights}
                                setPaperWeight={setPaperWeight}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                                setRunningStyle={setRunningStyle}
                            />
                            <div className="field">
                                <div className="label">Style:</div>
                                <select value={runningStyle} onChange={onChangeHandler.bind(this, setRunningStyle)} disabled={pressId === ''}>
                                    {runningStyleOptions}
                                </select>
                            </div>
                        </div>
                        <div className="stock-configuration">
                            <h3>Stock Configuration</h3>
                            <PaperCategories
                                productType={props.productType}
                                pressId={pressId}
                                paperCategoryId={paperCategoryId}
                                paperCategories={paperCategories}
                                setPaperCategories={setPaperCategories}
                                setPaperCategoryId={setPaperCategoryId}
                                setPaperWeights={setPaperWeights}
                                setPaperWeight={setPaperWeight}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                            />
                            <PaperWeights
                                productType={props.productType}
                                pressId={pressId}
                                paperCategoryId={paperCategoryId}
                                paperWeight={paperWeight}
                                paperWeights={paperWeights}
                                setPaperWeights={setPaperWeights}
                                setPaperWeight={setPaperWeight}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                            />
                            <Papers
                                productType={props.productType}
                                pressId={pressId}
                                paperCategoryId={paperCategoryId}
                                paperWeight={paperWeight}
                                paperId={paperId}
                                papers={papers}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                            />
                        </div>
                    </div>
                    <h3>Additional Bindery</h3>
                    <div className="form-section bindery-configuration">
                        <FoldingTypes
                            productType={props.productType}
                            foldingTypeId={foldingTypeId}
                            foldingTypes={foldingTypes}
                            setFoldingTypeId={setFoldingTypeId}
                            setFoldingTypes={setFoldingTypes}
                        />
                        <div className="field">
                            <div className="label">Shrink Wrap:</div>
                            <select value={useShrinkWrapping} onChange={onChangeHandler.bind(this, setUseShrinkWrapping)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Per Bundle:</div>
                            <select value={perBundle} onChange={onChangeHandler.bind(this, setPerBundle)}>
                                <option value="">Select option</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="250">250</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Counting:</div>
                            <select value={useCounting} onChange={onChangeHandler.bind(this, setUseCounting)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Drilling Holes:</div>
                            <select value={drillingHoles} onChange={onChangeHandler.bind(this, setDrillingHoles)}>
                                <option value="0">None</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Location:</div>
                            <select value={drillingHolesLocation} onChange={onChangeHandler.bind(this, setDrillingHolesLocation)}>
                                <option value="">Select option</option>
                                <option value="1">Top Left</option>
                                <option value="2">Top Center</option>
                                <option value="3">Top Right</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Die Cutting:</div>
                            <select value={useDieCutting} onChange={onChangeHandler.bind(this, setUseDieCutting)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Padding:</div>
                            <select value={usePadding} onChange={onChangeHandler.bind(this, setUsePadding)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Sht/per pad:</div>
                            <select value={sheetsToPad} onChange={onChangeHandler.bind(this, setSheetsToPad)}>
                                <option value="">Select option</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="button-container">
                    <button onClick={estimateHandler} disabled={isEstimating}>{isEstimating ? <i className="fas fa-circle-notch fa-spin" /> : 'Estimate'}</button>
                </div>
                <AccountingForm
                    department="flat"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default FlatForm;