import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.getElements, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchElement = id => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement([response.data], response.data.id));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchAcceptElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',')
    }

    dispatch({ type: actionTypes.FETCH_ACCEPT_ELEMENTS, payload: null });
    axios.get(apiUrls.getAcceptElements + '?selectedPage=' + selectedPage + '&sort=' + column + '&filter=' + filter,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ACCEPT_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ACCEPT_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchReceiveElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',')
    }

    dispatch({ type: actionTypes.FETCH_RECEIVE_ELEMENTS, payload: null });
    axios.get(apiUrls.getReceiveElements + '?selectedPage=' + selectedPage + '&sort=' + column + '&filter=' + filter,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_RECEIVE_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_RECEIVE_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchUnclaimElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',')
    }

    dispatch({ type: actionTypes.FETCH_UNCLAIM_ELEMENTS, payload: null });
    axios.get(apiUrls.getUnclaimElements + '?selectedPage=' + selectedPage + '&sort=' + column + '&filter=' + filter,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_UNCLAIM_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_UNCLAIM_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchHistoryElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',')
    }

    dispatch({ type: actionTypes.FETCH_HISTORY_ELEMENTS, payload: null });
    axios.get(apiUrls.getHistoryElements + '?selectedPage=' + selectedPage + '&sort=' + column + '&filter=' + filter,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_HISTORY_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_HISTORY_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const saveElement = (values) => (dispatch, getState) => {
    let errors = {};
    let errorMessages = {};

    let attributes = ['customer', 'description', 'quantity', 'notes'];
    if (values['status'] === 4) {
        attributes.push('location');
    }

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });

            const pagination = selectors.getPagination(getState());
            const sortColumn = selectors.getSortColumn(getState());
            const sortType = selectors.getSortType(getState());
            const filterFields = selectors.getFilterFields(getState());
            dispatch(fetchReceiveElements(
                pagination['receive']['selectedPage'],
                sortColumn['receive']['column'],
                sortType['receive']['type'],
                filterFields['receive']['fields'])
            );
            dispatch(fetchAcceptElements(
                pagination['accept']['selectedPage'],
                sortColumn['accept']['column'],
                sortType['accept']['type'],
                filterFields['accept']['fields'])
            );
            dispatch(fetchUnclaimElements(
                pagination['unclaim']['selectedPage'],
                sortColumn['unclaim']['column'],
                sortType['unclaim']['type'],
                filterFields['unclaim']['fields'])
            );
            dispatch(fetchHistoryElements(
                pagination['receiveHistory']['selectedPage'],
                sortColumn['receiveHistory']['column'],
                sortType['receiveHistory']['type'],
                filterFields['receiveHistory']['fields'])
            );

            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const deleteElement = id => (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });

            const pagination = selectors.getPagination(getState());
            const sortColumn = selectors.getSortColumn(getState());
            const sortType = selectors.getSortType(getState());
            const filterFields = selectors.getFilterFields(getState());
            dispatch(fetchReceiveElements(
                pagination['receive']['selectedPage'],
                sortColumn['receive']['column'],
                sortType['receive']['type'],
                filterFields['receive']['fields'])
            );
            dispatch(fetchAcceptElements(
                pagination['accept']['selectedPage'],
                sortColumn['accept']['column'],
                sortType['accept']['type'],
                filterFields['accept']['fields'])
            );
            dispatch(fetchUnclaimElements(
                pagination['unclaim']['selectedPage'],
                sortColumn['unclaim']['column'],
                sortType['unclaim']['type'],
                filterFields['unclaim']['fields'])
            );
            dispatch(fetchHistoryElements(
                pagination['receiveHistory']['selectedPage'],
                sortColumn['receiveHistory']['column'],
                sortType['receiveHistory']['type'],
                filterFields['receiveHistory']['fields'])
            );

            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function setIdToReceive(value) {
    return {
        type: actionTypes.SET_ID_TO_RECEIVE,
        payload: value
    };
}

export function setIdToAccept(value) {
    return {
        type: actionTypes.SET_ID_TO_ACCEPT,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type, name) {
    return {
        type: actionTypes.SET_SORT,
        payload: { name, column, type }
    };
}

export function setElements(name, elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: { name, elements }
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value, filterName) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value, filterName }
    };
}

export function processElements(type) {
    return (dispatch, getState) => {
        const elements = selectors.getElements(getState());
        const filterFields = selectors.getFilterFields(getState());
        const sortColumns = selectors.getSortColumn(getState());
        const sortTypes = selectors.getSortType(getState());

        if (type === 'accept') {
            let accept = filterElements('accept', elements, filterFields, sortColumns, sortTypes);
            dispatch({
                type: actionTypes.PROCESS_ACCEPT_ELEMENTS,
                payload: accept
            });
        } else if (type === 'receive') {
            let receive = filterElements('receive', elements, filterFields, sortColumns, sortTypes);
            dispatch({
                type: actionTypes.PROCESS_RECEIVE_ELEMENTS,
                payload: receive
            });
        } else if (type === 'unclaim') {
            let unclaim = filterElements('unclaim', elements, filterFields, sortColumns, sortTypes);
            dispatch({
                type: actionTypes.PROCESS_UNCLAIM_ELEMENTS,
                payload: unclaim
            });
        } else if (type === 'history') {
            let receiveHistory = filterElements('receiveHistory', elements, filterFields, sortColumns, sortTypes);
            dispatch({
                type: actionTypes.PROCESS_HISTORY_ELEMENTS,
                payload: receiveHistory
            });
        }
    };
}

function filterElements(elementsName, elements, filterFields, sortColumns, sortTypes) {
    let filteredElements = [];
    for (let i = 0; i < elements[elementsName].length; i++) {
        let add = true;
        for (let j = 0; j < filterFields[elementsName]['fields'].length; j++) {
            let attribute = String(elements[elementsName][i][filterFields[elementsName]['fields'][j]['name']]).toLowerCase();
            let filterValue = String(filterFields[elementsName]['fields'][j]['value']).toLowerCase();
            if (!attribute.includes(filterValue)) {
                add = false;
                break;
            }
        }

        if (add) {
            filteredElements.push(elements[elementsName][i]);
        }
    }

    let sortedElements = filteredElements.sort((a, b) => {
        let sortColumn = sortColumns[elementsName]['column'];
        let sortType = sortTypes[elementsName]['type'];
        if (typeof a[sortColumn] === 'number') {
            if (sortType === 'asc') {
                if (a[sortColumn] < b[sortColumn]) return -1;
                if (a[sortColumn] > b[sortColumn]) return 1;
                if (a['id'] < b['id']) return -1;
                if (a['id'] > b['id']) return 1;
                return 0;
            } else {
                if (b[sortColumn] < a[sortColumn]) return -1;
                if (b[sortColumn] > a[sortColumn]) return 1;
                if (b['id'] < a['id']) return -1;
                if (b['id'] > a['id']) return 1;
                return 0;
            }
        } else {
            if (sortType === 'asc') {
                if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) return -1;
                if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) return 1;
                if (b['id'] < a['id']) return -1;
                if (b['id'] > a['id']) return 1;
                return 0;
            } else {
                if (b[sortColumn].toLowerCase() < a[sortColumn].toLowerCase()) return -1;
                if (b[sortColumn].toLowerCase() > a[sortColumn].toLowerCase()) return 1;
                if (b['id'] < a['id']) return -1;
                if (b['id'] > a['id']) return 1;
                return 0;
            }
        }
    });

    return sortedElements;
}

export const fetchData = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_DATA, payload: null });
    axios.get(apiUrls.data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_DATA_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_DATA_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function addNotification(element) {
    return {
        type: actionTypes.ADD_NOTIFICATION,
        payload: element
    };
}

export function removeNotification(element) {
    return {
        type: actionTypes.REMOVE_NOTIFICATION,
        payload: element
    };
}

export const receive = () => (dispatch, getState) => {
    let idToReceive = selectors.getIdToReceive(getState());
    let formValues = selectors.getFormValues(getState());

    let errors = {};
    let errorMessages = {};
    let attributes = ['location', 'receive_description', 'containers_number',
        'containers_type', 'containers_pieces'];
    for (let i = 0; i < attributes.length; i++) {
        if (formValues[attributes[i]] === '' || formValues[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.RECEIVE, payload: null });
    axios.post(apiUrls.receive,
        {
            id: idToReceive,
            location: formValues['location'],
            receive_description: formValues['receive_description'],
            containers_number: formValues['containers_number'],
            containers_type: formValues['containers_type'],
            containers_pieces: formValues['containers_pieces'],
            aisle: formValues['aisle'],
            rack: formValues['rack'],
            level: formValues['level']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.RECEIVE_SUCCESS, payload: response.data });

            const pagination = selectors.getPagination(getState());
            const sortColumn = selectors.getSortColumn(getState());
            const sortType = selectors.getSortType(getState());
            const filterFields = selectors.getFilterFields(getState());
            dispatch(fetchReceiveElements(
                pagination['receive']['selectedPage'],
                sortColumn['receive']['column'],
                sortType['receive']['type'],
                filterFields['receive']['fields'])
            );
            dispatch(fetchAcceptElements(
                pagination['accept']['selectedPage'],
                sortColumn['accept']['column'],
                sortType['accept']['type'],
                filterFields['accept']['fields'])
            );
            dispatch(fetchUnclaimElements(
                pagination['unclaim']['selectedPage'],
                sortColumn['unclaim']['column'],
                sortType['unclaim']['type'],
                filterFields['unclaim']['fields'])
            );
            dispatch(fetchHistoryElements(
                pagination['receiveHistory']['selectedPage'],
                sortColumn['receiveHistory']['column'],
                sortType['receiveHistory']['type'],
                filterFields['receiveHistory']['fields'])
            );

            dispatch(dialog.actions.hideDialog('receive'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.RECEIVE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const accept = () => (dispatch, getState) => {
    let idToAccept = selectors.getIdToAccept(getState());
    let formValues = selectors.getFormValues(getState());

    let errors = {};
    let errorMessages = {};
    let attributes = ['accept_description'];
    for (let i = 0; i < attributes.length; i++) {
        if (formValues[attributes[i]] === '' || formValues[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ACCEPT, payload: null });
    axios.post(apiUrls.accept,
        {
            id: idToAccept,
            location: formValues['location'],
            accept_description: formValues['accept_description']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ACCEPT_SUCCESS, payload: response.data });

            const pagination = selectors.getPagination(getState());
            const sortColumn = selectors.getSortColumn(getState());
            const sortType = selectors.getSortType(getState());
            const filterFields = selectors.getFilterFields(getState());
            dispatch(fetchReceiveElements(
                pagination['receive']['selectedPage'],
                sortColumn['receive']['column'],
                sortType['receive']['type'],
                filterFields['receive']['fields'])
            );
            dispatch(fetchAcceptElements(
                pagination['accept']['selectedPage'],
                sortColumn['accept']['column'],
                sortType['accept']['type'],
                filterFields['accept']['fields'])
            );
            dispatch(fetchUnclaimElements(
                pagination['unclaim']['selectedPage'],
                sortColumn['unclaim']['column'],
                sortType['unclaim']['type'],
                filterFields['unclaim']['fields'])
            );
            dispatch(fetchHistoryElements(
                pagination['receiveHistory']['selectedPage'],
                sortColumn['receiveHistory']['column'],
                sortType['receiveHistory']['type'],
                filterFields['receiveHistory']['fields'])
            );

            dispatch(dialog.actions.hideDialog('accept'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.ACCEPT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setActivePage(name, page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: { name, page }
    };
}
