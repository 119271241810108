import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import EstimatePricing from './EstimatePricing';

class EstimageLargeFormat extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object
    };

    renderInput(label, name, type, required, disabled = false, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    disabled={disabled}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, disabled = false, colClass = 'col', loadingName = null) {
        const { formValues, formErrors, handleUpdateFormValue, fetching } = this.props;

        let loadingIcon = null;
        if (loadingName != null && fetching[loadingName]) {
            loadingIcon = <div className="loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    disabled={disabled}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
                {loadingIcon}
            </div>
        );
    }

    renderProductCategories(type, lock) {
        const { options } = this.props;

        let categoriesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === parseInt(type)) {
                categoriesOptions.push(options['productCategories'][i]);
            }
        }

        return this.renderSelect('Product Category', 'estimate_product_category_id', categoriesOptions, true, lock);
    }

    renderProductCategoriesElements() {
        const { configuration } = this.props;

        const productCategoryId = configuration['product_options']['product_category_id'];
        if (parseInt(productCategoryId['show']) === 1) {
            return [
                this.renderProductCategories(4, productCategoryId['lock']),
                <div className="w-100"></div>
            ];
        }

        return null;
    }

    renderProductDescriptionElement() {
        const { configuration } = this.props;

        const productDescription = configuration['product_description'];
        if (parseInt(productDescription['show']) === 1) {
            return [
                this.renderInput('Description', 'estimate_product_description', 'text', true, productDescription['lock']),
                <div className="w-100"></div>
            ];
        }

        return null;
    }

    renderLargeFormatMachineElements() {
        const { configuration, information } = this.props;

        const largeFormatMachineId = configuration['product_options']['large_format_machine_id'];
        if (parseInt(largeFormatMachineId['show']) === 0) {
            return null;
        }

        let largeFormatMachinesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            largeFormatMachinesOptions.push({
                label: information['largeFormatMachines'][i]['name'],
                value: information['largeFormatMachines'][i]['id']
            });
        }

        let elements = [];
        if (parseInt(largeFormatMachineId['show']) === 1) {
            elements.push(this.renderSelect('Large Format Machine', 'estimate_large_format_machine_id', largeFormatMachinesOptions, true, largeFormatMachineId['lock']));
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderSizeElements() {
        const { configuration } = this.props;

        const flatWidth = configuration['substrate_options']['flat_width'];
        const flatHeight = configuration['substrate_options']['flat_height'];
        const unit = configuration['substrate_options']['unit'];
        if (parseInt(flatWidth['show']) === 0 && parseInt(flatHeight['show']) === 0 &&
            parseInt(unit['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(flatWidth['show']) === 1) {
            elements.push(this.renderInput('Width', 'estimate_width', 'decimal', true, flatWidth['lock']));
        }
        if (parseInt(flatHeight['show']) === 1) {
            elements.push(this.renderInput('Height', 'estimate_height', 'decimal', true, flatHeight['lock']));
        }
        if (parseInt(unit['show']) === 1) {
            elements.push(
                this.renderSelect('Unit', 'estimate_unit', [
                    { label: 'Inches', value: 1 },
                    { label: 'Feet', value: 2 }
                ], true, unit['lock'])
            );
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderSubstrateElements() {
        const { configuration, information } = this.props;

        const substrateId = configuration['substrate_options']['substrate_id'];
        if (parseInt(substrateId['show']) === 0) {
            return null;
        }

        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            if (parseInt(information['largeFormatMachines'][i]['id']) === parseInt(configuration['product_options']['large_format_machine_id']['default'])) {
                largeFormatMachine = information['largeFormatMachines'][i];
                break;
            }
        }

        let substratesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; largeFormatMachine !== null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            substratesOptions.push({
                label: largeFormatMachine['activeSubstrates'][i]['name'],
                value: largeFormatMachine['activeSubstrates'][i]['id']
            });
        }

        let elements = [];
        if (parseInt(substrateId['show']) === 1) {
            elements.push(this.renderSelect('Substrate', 'estimate_substrate_id', substratesOptions, true, substrateId['lock']));
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderLaminateElements() {
        const { configuration, information } = this.props;

        const laminateId = configuration['substrate_options']['laminate_id'];
        if (parseInt(laminateId['show']) === 0) {
            return null;
        }

        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            if (parseInt(information['largeFormatMachines'][i]['id']) === parseInt(configuration['product_options']['large_format_machine_id']['default'])) {
                largeFormatMachine = information['largeFormatMachines'][i];
                break;
            }
        }

        let substrate = null;
        for (let i = 0; largeFormatMachine != null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            if (parseInt(largeFormatMachine['activeSubstrates'][i]['id']) === parseInt(configuration['substrate_options']['substrate_id']['default'])) {
                substrate = largeFormatMachine['activeSubstrates'][i];
                break;
            }
        }

        let laminateOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; substrate != null && i < substrate['activeLaminates'].length; i++) {
            laminateOptions.push({
                label: substrate['activeLaminates'][i]['name'],
                value: substrate['activeLaminates'][i]['id']
            });
        }

        let elements = [];
        if (parseInt(laminateId['show']) === 1) {
            elements.push(this.renderSelect('Laminate', 'estimate_laminate_id', laminateOptions, true, laminateId['lock']));
        }

        return elements;
    }

    renderProductionNotesElements() {
        const { configuration, handleUpdateFormValue, formValues, options } = this.props;

        const productionNotes = configuration['production_notes'];
        const showDiscountTable = configuration['customer_discount'] && configuration['customer_discount']['show'] ? configuration['customer_discount']['show'] : 1;
        const lockDiscountTable = configuration['customer_discount'] && configuration['customer_discount']['lock'] ? configuration['customer_discount']['lock'] : 0;
        if (parseInt(productionNotes['show']) === 0 && parseInt(showDiscountTable) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(productionNotes['show']) === 1) {
            elements.push(<label>Production Notes:</label>);
            elements.push(
                <textarea name="estimate_notes" onChange={handleUpdateFormValue.bind(this, 'text', false)} style={{ height: 83 }} disabled={productionNotes['lock']}>
                    {formValues['estimate_notes']}
                </textarea>
            );
        }
        if (parseInt(showDiscountTable) === 1) {
            elements.push(this.renderSelect('Discount Table', 'estimate_customer_discount_id', options['customerDiscounts'], false, lockDiscountTable));
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Production Notes
                </div>
                <div className="card-body pt-2 pb-0 pl-2 pr-2">
                    {elements}
                </div>
            </div>
        );
    }

    renderAccessories() {
        const { information, configuration, formValues, checkAccessory } = this.props;

        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            if (parseInt(information['largeFormatMachines'][i]['id']) === parseInt(configuration['product_options']['large_format_machine_id']['default'])) {
                largeFormatMachine = information['largeFormatMachines'][i];
                break;
            }
        }

        let substrate = null;
        for (let i = 0; largeFormatMachine != null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            if (parseInt(largeFormatMachine['activeSubstrates'][i]['id']) === parseInt(configuration['substrate_options']['substrate_id']['default'])) {
                substrate = largeFormatMachine['activeSubstrates'][i];
                break;
            }
        }

        let accessories = [];
        const lock = configuration['accessories']['lock'];
        for (let i = 0; substrate != null && i < substrate['activeAccessories'].length; i++) {
            const accessory = substrate['activeAccessories'][i];

            let checked = false;
            if (formValues['estimate_accessories'].includes(accessory['id'])) {
                checked = true;
            }

            if (!lock || checked) {
                accessories.push(
                    <div className="col accessory-checkbox">
                        <input type="checkbox" checked={checked} onClick={checkAccessory.bind(this, accessory['id'])} disabled={lock} /> {accessory['name']}
                    </div>
                );
                accessories.push(<div className="w-100"></div>);
            }
        }

        return (
            <div className="col">
                {accessories}
            </div>
        );
    }

    render() {
        const { formValues, handleUpdateFormValue, information, handleAccessoryClick, estimateAccessories, estimateResults } = this.props;

        let largeFormatMachinesOptions = [{ label: 'Select option', value: '' }];
        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            largeFormatMachinesOptions.push({
                label: information['largeFormatMachines'][i]['name'],
                value: information['largeFormatMachines'][i]['id']
            });

            if (parseInt(information['largeFormatMachines'][i]['id']) === parseInt(formValues['estimate_large_format_machine_id'])) {
                largeFormatMachine = information['largeFormatMachines'][i];
            }
        }

        let substratesOptions = [{ label: 'Select option', value: '' }];
        let substrate = null;
        for (let i = 0; largeFormatMachine != null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            substratesOptions.push({
                label: largeFormatMachine['activeSubstrates'][i]['name'],
                value: largeFormatMachine['activeSubstrates'][i]['id']
            });

            if (parseInt(largeFormatMachine['activeSubstrates'][i]['id']) === parseInt(formValues['estimate_substrate_id'])) {
                substrate = largeFormatMachine['activeSubstrates'][i];
            }
        }

        let laminatesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; substrate !== null && i < substrate['activeLaminates'].length; i++) {
            laminatesOptions.push({
                label: substrate['activeLaminates'][i]['name'],
                value: substrate['activeLaminates'][i]['id']
            });
        }

        let accessories = [];
        for (let i = 0; substrate !== null && i < substrate['activeAccessories'].length; i++) {
            let checked = false;
            if (estimateAccessories.includes(substrate['activeAccessories'][i]['id'])) {
                checked = true;
            }
            accessories.push(
                <div className="accessory-checkbox">
                    <input type="checkbox" checked={checked}
                        onClick={handleAccessoryClick.bind(this, substrate['activeAccessories'][i]['id'])} /> {substrate['activeAccessories'][i]['name']}
                </div>
            );
        }

        return (
            <form>
                <div className="row no-gutters mb-2">
                    <div className="col-4" style={{ color: '#0d47a1', fontWeight: 'bold' }}>PQN Estimate</div>
                </div>
                <div className="row no-gutters">
                    <div className="col">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Product Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderProductCategoriesElements()}
                                    {this.renderProductDescriptionElement()}
                                    {this.renderLargeFormatMachineElements()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Substrate Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderSizeElements()}
                                    {this.renderSubstrateElements()}
                                    {this.renderLaminateElements()}
                                </div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                        {this.renderProductionNotesElements()}
                    </div>
                    <div className="col">
                        <div className="card mb-2 mr-1 pb-2">
                            <div className="card-header">
                                Accessories
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0" style={{ minHeight: 284 }}>
                                <div className="row no-gutters">
                                    {this.renderAccessories()}
                                </div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                    </div>
                    <div className="w-100"></div>
                    <EstimatePricing
                        formValues={formValues}
                        estimateResults={estimateResults}
                        information={information}
                        handleUpdateFormValue={handleUpdateFormValue}
                        renderSheets={false}
                    />
                </div>
            </form>
        );
    }
}

export default EstimageLargeFormat;
