import { NAME } from './constants';

export const FETCH_ELEMENTS = `${NAME}/FETCH_ELEMENTS`;
export const FETCH_ELEMENTS_SUCCESS = `${NAME}/FETCH_ELEMENTS_SUCCESS`;
export const FETCH_ELEMENTS_FAILURE = `${NAME}/FETCH_ELEMENTS_FAILURE`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_ELEMENT = `${NAME}/DELETE_ELEMENT`;
export const DELETE_ELEMENT_SUCCESS = `${NAME}/DELETE_ELEMENT_SUCCESS`;
export const DELETE_ELEMENT_FAILURE = `${NAME}/DELETE_ELEMENT_FAILURE`;

export const SAVE_ELEMENT = `${NAME}/SAVE_ELEMENT`;
export const SAVE_ELEMENT_SUCCESS = `${NAME}/SAVE_ELEMENT_SUCCESS`;
export const SAVE_ELEMENT_FAILURE = `${NAME}/SAVE_ELEMENT_FAILURE`;

export const FETCH_INFORMATION = `${NAME}/FETCH_INFORMATION`;
export const FETCH_INFORMATION_SUCCESS = `${NAME}/FETCH_INFORMATION_SUCCESS`;
export const FETCH_INFORMATION_FAILURE = `${NAME}/FETCH_INFORMATION_FAILURE`;

export const INTERSECT_PAPER_CATEGORIES = `${NAME}/INTERSECT_PAPER_CATEGORIES`;
export const INTERSECT_PAPER_CATEGORIES_SUCCESS = `${NAME}/INTERSECT_PAPER_CATEGORIES_SUCCESS`;
export const INTERSECT_PAPER_CATEGORIES_FAILURE = `${NAME}/INTERSECT_PAPER_CATEGORIES_FAILURE`;

export const INTERSECT_PAPER_WEIGHTS = `${NAME}/INTERSECT_PAPER_WEIGHTS`;
export const INTERSECT_PAPER_WEIGHTS_SUCCESS = `${NAME}/INTERSECT_PAPER_WEIGHTS_SUCCESS`;
export const INTERSECT_PAPER_WEIGHTS_FAILURE = `${NAME}/INTERSECT_PAPER_WEIGHTS_FAILURE`;

export const INTERSECT_PAPERS = `${NAME}/INTERSECT_PAPERS`;
export const INTERSECT_PAPERS_SUCCESS = `${NAME}/INTERSECT_PAPERS_SUCCESS`;
export const INTERSECT_PAPERS_FAILURE = `${NAME}/INTERSECT_PAPERS_FAILURE`;

export const FETCH_CONFIGURATION = `${NAME}/FETCH_CONFIGURATION`;
export const FETCH_CONFIGURATION_SUCCESS = `${NAME}/FETCH_CONFIGURATION_SUCCESS`;
export const FETCH_CONFIGURATION_FAILURE = `${NAME}/FETCH_CONFIGURATION_FAILURE`;

export const FETCH_PRODUCT_CATEGORIES = `${NAME}/FETCH_PRODUCT_CATEGORIES`;
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = `${NAME}/FETCH_PRODUCT_CATEGORIES_SUCCESS`;
export const FETCH_PRODUCT_CATEGORIES_FAILURE = `${NAME}/FETCH_PRODUCT_CATEGORIES_FAILURE`;

export const UPDATE_FORM_VALUE = `${NAME}/UPDATE_FORM_VALUE`;
export const SET_SORT = `${NAME}/SET_SORT`;
export const SET_ELEMENTS = `${NAME}/SET_ELEMENTS`;
export const SET_ACTIVE_ELEMENT = `${NAME}/SET_ACTIVE_ELEMENT`;
export const SET_ID_FOR_DELETE = `${NAME}/SET_ID_FOR_DELETE`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_FORM_ERRORS = `${NAME}/SET_FORM_ERRORS`;
export const SET_FORM_ERROR = `${NAME}/SET_FORM_ERROR`;
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const CHANGE_FILTER = `${NAME}/CHANGE_FILTER`;
export const PROCESS_ELEMENTS = `${NAME}/PROCESS_ELEMENTS`;
export const SET_EDITING = `${NAME}/SET_EDITING`;
export const UPDATE_CONFIGURATION = `${NAME}/UPDATE_CONFIGURATION`;
export const SET_OPTIONS = `${NAME}/SET_OPTIONS`;
export const RESET_OPTIONS = `${NAME}/RESET_OPTIONS`;
export const UPDATE_STATE = `${NAME}/UPDATE_STATE`;
export const SET_INITIAL_CONFIGURATION = `${NAME}/SET_INITIAL_CONFIGURATION`;
export const CHECK_ACCESSORY = `${NAME}/CHECK_ACCESSORY`;
