import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import numeral from 'numeral';
import moment from 'moment';

class Form extends Component {
    static propTypes = {

    };

    renderFiles() {
        let files = [];
        if (this.props.formValues.referenceFiles && this.props.formValues.referenceFiles != null) {
            files = JSON.parse(this.props.formValues.referenceFiles);
        }

        if (files.length === 0) {
            return null;
        }

        return (
            <Fragment>
                <div><b>Photo of previous card:</b></div>
                <ul>
                    {files.map((file, index) => {
                        let number = index + 1;
                        if (files.length === 1) {
                            number = '';
                        }

                        return (
                            <li key={file}>
                                <a href={file} target="_blank" rel="noopener noreferrer">Download file {number}</a>
                            </li>
                        );
                    })}
                </ul>
            </Fragment>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, handleUpdateFormValue } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let cardFor = null;
        if (this.props.formValues.variables && this.props.formValues.variables !== '') {
            let json = JSON.parse(this.props.formValues.variables);
            cardFor = json.name;
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">Order Information</div>
                    <div className="w-100"></div>
                    Status:
                    <select name="status" value={this.props.formValues.status} onChange={handleUpdateFormValue.bind(this, 'text', true)}>
                        <option value="1">Pending</option>
                        <option value="2">On Production</option>
                        <option value="3">Finished</option>
                        <option value="4">Delivered</option>
                    </select>
                    <div className="w-100"></div>
                    <div className="information">
                        <div className="ship-to">
                            <h5>Summary</h5>
                            <div><b>Quantity:</b> {numeral(this.props.formValues.quantity).format('0,0')}</div>
                            <div><b>Date:</b> {moment(this.props.formValues.created_at).format('MM/DD/YYYY')}</div>
                            <div><b>Card for:</b> {cardFor}</div>
                            <div><b>High Resolution File:</b> <a href={this.props.formValues.pdf_url} target="_blank" rel="noopener noreferrer">Click here to download</a></div>
                            {this.renderFiles()}
                            <h5>Ship To</h5>
                            <div><b>First Name:</b> {this.props.formValues.shipFirstName}</div>
                            <div><b>Last Name:</b> {this.props.formValues.shipLastName}</div>
                            <div><b>Address 1:</b> {this.props.formValues.shipAddress1}</div>
                            <div><b>Address 2:</b> {this.props.formValues.shipAddress2}</div>
                            <div><b>City:</b> {this.props.formValues.shipCity}</div>
                            <div><b>State:</b> {this.props.formValues.shipState}</div>
                            <div><b>Zip:</b> {this.props.formValues.shipZip}</div>
                            <div><b>Phone:</b> {this.props.formValues.shipPhone}</div>
                            <div><b>Email:</b> {this.props.formValues.shipEmail}</div>
                            <div><b>Notes:</b> {this.props.formValues.shipNotes}</div>
                        </div>
                        <div className="bill-to">
                            <h5>Bill To</h5>
                            <div><b>First Name:</b> {this.props.formValues.billFirstName}</div>
                            <div><b>Last Name:</b> {this.props.formValues.billLastName}</div>
                            <div><b>Address 1:</b> {this.props.formValues.billAddress1}</div>
                            <div><b>Address 2:</b> {this.props.formValues.billAddress2}</div>
                            <div><b>City:</b> {this.props.formValues.billCity}</div>
                            <div><b>State:</b> {this.props.formValues.billState}</div>
                            <div><b>Zip:</b> {this.props.formValues.billZip}</div>
                            <div><b>Phone:</b> {this.props.formValues.billPhone}</div>
                            <div><b>Email:</b> {this.props.formValues.billEmail}</div>
                            <div><b>PO#:</b> {this.props.formValues.billPo}</div>
                            <div><b>Dept. Info:</b> {this.props.formValues.billDepartment}</div>
                            <div><b>Call for CC#:</b> {parseInt(this.props.formValues.billCall) ? 'Yes' : 'No'}</div>
                            <div><b>Notes:</b> {this.props.formValues.billNotes}</div>
                        </div>
                    </div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="ut-health-form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
