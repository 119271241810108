const API_URL = process.env.REACT_APP_API_URL;

let apiUrls = {
    elements: `${API_URL}/digital-machines`,
    search: `${API_URL}/digital-machine/search`,
    saveActivePapers: `${API_URL}/digital-machine/save-active-papers`,
    saveSpeedTable: `${API_URL}/digital-machine/save-speed-table`,
    information: `${API_URL}/digital-machine/information`,
    paperCategories: `${API_URL}/paper-categories`,
    papers: `${API_URL}/papers`,
    saveDiscountTable: `${API_URL}/digital-machine/save-discount-table`
};

export default apiUrls;
