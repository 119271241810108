import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Form } from './components';
import * as selectors from './selectors';
import * as actions from './actions';

class Container extends Component {
    static propTypes = {
        isSaving: PropTypes.bool,
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        setIdForDelete: PropTypes.func,
        setFormError: PropTypes.func,
        savePassword: PropTypes.func,
        updateFormValue: PropTypes.func,
        infoNotification: PropTypes.func,
        restorePassword: PropTypes.func
    };

    componentWillMount() {
        this.props.setHistory(this.props.history);
        this.props.setToken(this.props.match.params.token);
    }

    handleUpdateFormValue(event) {
        const { updateFormValue, setFormError } = this.props;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const error = event.target.type === 'checkbox' ? !event.target.checked : (event.target.value === '');
        updateFormValue(event.target.name, value);
        setFormError(event.target.name, error);
    }

    handleSavePassword() {
        const { savePassword, formValues } = this.props;
        savePassword(formValues);
    }

    render() {
        const { formValues, formErrors, isSaving } = this.props;
        return (
            <div id="restore-container" className="container-fluid">
                <div className="row">
                    <div className="w-100"></div>
                    <div className="col">
                        <Form
                            formValues={formValues}
                            formErrors={formErrors}
                            isSaving={isSaving}
                            handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                            handleSavePassword={this.handleSavePassword.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    isSaving: selectors.getIsSaving
});

const mapDispatchToProps = (dispatch) => {
    return {
        savePassword: (values) => dispatch(actions.savePassword(values)),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setFormError: (name, value) => dispatch(actions.setFormError(name, value)),
        setHistory: (history) => dispatch(actions.setHistory(history)),
        setToken: (token) => dispatch(actions.setToken(token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
