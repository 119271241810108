import React, { Component } from 'react';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import EstimateMailingPricing from './EstimateMailingPricing';

class EstimateIndexTab extends Component {
    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col', loadingName = null) {
        const { formValues, formErrors, handleUpdateFormValue, fetching } = this.props;

        let loadingIcon = null;
        if (loadingName != null && fetching[loadingName]) {
            loadingIcon = <div className="loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
                {loadingIcon}
            </div>
        );
    }

    renderTypes() {
        return this.renderSelect('Product Type', 'estimate_type', [
            { label: 'Flat', value: 1 },
            { label: 'Multi-Page', value: 2 },
            { label: 'Digital', value: 3 },
            { label: 'Large Format', value: 4 },
            { label: 'Data Processing', value: 5 },
            { label: 'Inkjet', value: 6 },
            { label: 'Inserting', value: 7 },
            { label: 'Art Department', value: 9 },
            { label: 'Handwork Department', value: 10 },
            { label: 'Pressure Seal Forms', value: 11 },
            { label: 'Index Tabs', value: 12 },
            { label: 'Standard Labels', value: 13 },
            { label: 'NCR Forms', value: 14 },
            { label: 'Envelopes', value: 15 },
        ], true);
    }

    renderProductCategories(type) {
        const { options } = this.props;

        let categoriesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === parseInt(type)) {
                categoriesOptions.push(options['productCategories'][i]);
            }
        }

        return this.renderSelect('Product Category', 'estimate_product_category_id', categoriesOptions, true);
    }

    renderIndexTabElements() {
        const { formValues, options, handleUpdateMailingElement, handleRemoveMailingElement } = this.props;
        return formValues['estimate_index_tab_elements'].map((dataElement, index1) => {
            let selectedElement = null;
            for (let i = 0; i < options['indexTabElements'].length; i++) {
                let element = options['indexTabElements'][i]['element'];
                if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            let total = null;
            let costElement = null;
            if (selectedElement != null && dataElement['quantity'] !== '') {
                for (let i = 0; i < selectedElement['costs'].length; i++) {
                    if (dataElement['quantity'] >= parseInt(selectedElement['costs'][i]['quantity_start'])
                        && dataElement['quantity'] <= parseInt(selectedElement['costs'][i]['quantity_end'])) {
                        costElement = selectedElement['costs'][i];
                        break;
                    }
                }

                if (costElement != null) {
                    total = costElement['cost'];

                    for (let i = 0; i < selectedElement['discounts'].length; i++) {
                        if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                            && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                            total -= total * selectedElement['discounts'][i]['discount'];
                            break;
                        }
                    }
                }
            }

            return (
                <tr key={'index-tab-element-' + index1}>
                    <td>
                        <select value={dataElement['element_id']} onChange={handleUpdateMailingElement.bind(this, 'estimate_index_tab_elements', 'element_id', index1)}>
                            {options['indexTabElements'].map((element, index2) => {
                                return <option key={'index-tab-element2-' + index2} value={element['value']}>{element['label']}</option>;
                            })}
                        </select>
                    </td>
                    <td>{selectedElement != null ? (selectedElement['ink_type'] !== '' ? selectedElement['ink_type'] : 'None') : 'N/A'}</td>
                    <td>{selectedElement != null ? (selectedElement['paper_type'] !== '' ? selectedElement['paper_type'] : 'None') : 'N/A'}</td>
                    <td>{selectedElement != null ? (selectedElement['drill_type'] !== '' ? selectedElement['drill_type'] : 'None') : 'N/A'}</td>
                    <td>{selectedElement != null ? (selectedElement['mylar_type'] !== '' ? selectedElement['mylar_type'] : 'None') : 'N/A'}</td>
                    <td>
                        <input type="text" value={dataElement['quantity']}
                            onChange={handleUpdateMailingElement.bind(this, 'estimate_index_tab_elements', 'quantity', index1)} />
                        Total: {costElement != null ? numeral(total).format('$0,0.00') : 'N/A'}
                    </td>
                    <td>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="remove-mailing-tooltip">
                            <i className="fas fa-trash" onClick={handleRemoveMailingElement.bind(this, 'estimate_index_tab_elements', index1)} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    }

    renderOutsideServices() {
        const { handleAddOutsideService, handleDeleteOutsideService, formValues, handleUpdateOutsideService } = this.props;
        let table = null;
        if (formValues['estimate_outside_services'].length !== 0) {
            table = (
                <table id="outside-services-table" className="table table-sm table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th style={{ width: 80 }}>Cost</th>
                            <th style={{ width: 60 }}>Markup</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {formValues['estimate_outside_services'].map((service, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <input type="text" value={service['description']}
                                            onChange={handleUpdateOutsideService.bind(this, 'description', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['cost']}
                                            onChange={handleUpdateOutsideService.bind(this, 'cost', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['percentage_markup']}
                                            onChange={handleUpdateOutsideService.bind(this, 'percentage_markup', index)} />
                                    </td>
                                    <td>
                                        <i className="fas fa-trash" onClick={handleDeleteOutsideService.bind(this, index)} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
        return (
            <div className="card mb-2 mr-1 pb-0">
                <div className="card-header">
                    Outside Services
                </div>
                <div className="card-body pt-1 pb-0">
                    <div className="row">
                        <div className="col">
                            <div className="add-outside-services" onClick={handleAddOutsideService}><i className="fas fa-plus" /> Add</div>
                            <div className="clearfix"></div>
                            {table}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderHeaders(name) {
        const { formValues } = this.props;

        if (formValues[name].length === 0) {
            return null;
        }

        return (
            <tr>
                <td>Selected Process</td>
                <td>Ink</td>
                <td>Paper</td>
                <td>Drill</td>
                <td>Mylar</td>
                <td style={{ width: 140 }}>Quantity</td>
                <td style={{ width: 30 }}></td>
            </tr>
        );
    }

    render() {
        const { formValues, handleUpdateFormValue, options, information, addMailingElement } = this.props;
        return (
            <form>
                <div className="row no-gutters mb-2">
                    <div className="col-4" style={{ color: '#0d47a1', fontWeight: 'bold' }}>PQN - Index Tabs</div>
                </div>
                <div className="row no-gutters">
                    <div className="col-4">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Product Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderTypes()}
                                    {this.renderProductCategories(12)}
                                    <div className="w-100"></div>
                                    <div className="estimate-divider"></div>
                                    <div className="w-100"></div>
                                    {this.renderInput('Description', 'estimate_product_description', 'text', true)}
                                    <div className="w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Production Notes
                            </div>
                            <div className="card-body pt-2 pb-0 pl-2 pr-2">
                                <textarea
                                    name="estimate_notes"
                                    onChange={handleUpdateFormValue.bind(this, 'text', false)}
                                    style={{ height: 52 }}
                                    value={formValues['estimate_notes']}
                                />
                                {this.renderSelect('Discount Table', 'estimate_customer_discount_id', options['customerDiscounts'], false, '')}
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        {this.renderOutsideServices()}
                    </div>
                    <div className="col-12">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Index Tabs
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <table className="table table-sm table-hover table-borderless card-table">
                                    <thead>
                                        {this.renderHeaders('estimate_index_tab_elements')}
                                    </thead>
                                    <tbody>
                                        {this.renderIndexTabElements()}
                                    </tbody>
                                </table>
                                <div className="add-element-button" onClick={addMailingElement.bind(this, 'estimate_index_tab_elements', { id: '', element_id: '', quantity: '', type: 1 })}>
                                    <i className="fas fa-plus" /> Add
                                </div>
                            </div>
                        </div>
                    </div>
                    <EstimateMailingPricing
                        formValues={formValues}
                        options={options}
                        information={information}
                        handleUpdateFormValue={handleUpdateFormValue}
                    />
                </div>
            </form>
        );
    }
}

export default EstimateIndexTab;
