import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class SpeedTable extends Component {
    static propTypes = {
        speedTable: PropTypes.array,
        hideSpeedTable: PropTypes.func,
        handleUpdateSpeedTable: PropTypes.func,
        isSavingSpeedTable: PropTypes.bool,
        handleSaveSpeedTable: PropTypes.func,
        handleAddSpeedTable: PropTypes.func,
        handleDeleteSpeedTable: PropTypes.func,
        bandingTypes: PropTypes.array
    };

    renderData() {
        const { speedTable, handleUpdateSpeedTable, handleDeleteSpeedTable, bandingTypes } = this.props;

        let options = [];
        for (let i = 0; i < bandingTypes.length; i++) {
            options.push(<option value={bandingTypes[i]['id']}>{bandingTypes[i]['name']}</option>);
        }

        return speedTable.map((speed, index) => {
            return (
                <tr key={index}>
                    <td>
                        <select name="" value={speed.banding_type_id} onChange={handleUpdateSpeedTable.bind(this, index, 'banding_type_id')}>
                            <option value="">Select option</option>
                            {options}
                        </select>
                    </td>
                    <td>
                        <input type="text" value={speed.start_length}
                            onChange={handleUpdateSpeedTable.bind(this, index, 'start_length')} />
                    </td>
                    <td>
                        <input type="text" value={speed.end_length}
                            onChange={handleUpdateSpeedTable.bind(this, index, 'end_length')} />
                    </td>
                    <td>
                        <input type="text" value={speed.time_per_m_sheets}
                            onChange={handleUpdateSpeedTable.bind(this, index, 'time_per_m_sheets')} />
                    </td>
                    <td>
                        <div className="action-icon">
                            <i className="fas fa-trash" onClick={handleDeleteSpeedTable.bind(this, index)} />
                        </div>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { hideSpeedTable, handleSaveSpeedTable, isSavingSpeedTable, handleAddSpeedTable } = this.props;

        let data = (
            <div>
                <table className="table table-sm table-striped data-table">
                    <thead>
                        <tr>
                            <th>Banding Type</th>
                            <th>Start Length</th>
                            <th>End Length</th>
                            <th>Time/M-Sheets</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                </table>
                <div className="add-button" onClick={handleAddSpeedTable} style={{ width: 120, fontSize: 13 }}><i className="fas fa-plus" /> Add Row</div>
            </div>
        );

        let saveButtonLabel = 'Save';
        if (isSavingSpeedTable) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveSpeedTable} disabled={isSavingSpeedTable}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideSpeedTable}>Close</button>
        ];
        return (
            <Dialog
                name="speed-table"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default SpeedTable;
