import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import Pagination from './../../Pagination';

const Table = React.memo((props) => {
    const renderData = () => {
        if (props.isFetchingData) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.data.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        return props.data.map((element) => {
            return (
                <tr key={element.id}>
                    <td>{element.id}</td>
                    <td>{element.name}</td>
                    <td>{element.size}</td>
                    <td>{element.prodution_rate_1_insert} per hour</td>
                    <td>{element.prodution_rate_2_insert} per hour</td>
                    <td>{element.rate_drop_per_extra_insert * 100}%</td>
                    <td>{element.active ? 'Yes' : 'No'}</td>
                    <td className="actions">
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={props.showFormHandler.bind(this, element.id, null)} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="table-container table-hover">
            <table id="titles-table" className="table table-sm table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Size</th>
                        <th>Prodution Rate 1 insert</th>
                        <th>Prodution Rate 2 inserts</th>
                        <th>Prodution Rate drop percentage per each additional insert</th>
                        <th>Active</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{renderData()}</tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
});

Table.propTypes = {

}

export default Table;