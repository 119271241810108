import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from './../../common/components/Select';
import numeral from 'numeral';
import moment from 'moment';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        saveElement: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        data: PropTypes.object,
        addPm: PropTypes.func,
        handleShowList: PropTypes.func,
        setPm: PropTypes.func,
        resetForm: PropTypes.func,
        handleUpdateFormDateValue: PropTypes.func
    };

    renderInput(name, type, required, readOnly = false) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <Input
                type="text"
                name={name}
                required={required}
                formValues={formValues}
                formErrors={formErrors}
                formErrorMessages={formErrorMessages}
                readOnly={readOnly}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
            />
        );
    }

    renderCheckbox(name, type, required) {
        const { formValues, handleUpdateFormValue } = this.props;
        return (
            <input type="checkbox" checked={formValues[name]} id={name} name={name}
                onClick={handleUpdateFormValue.bind(type, required, this)} />
        );
    }

    renderTextarea(name, type, required, readOnly = false) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <TextArea
                name={name}
                required={required}
                formValues={formValues}
                formErrors={formErrors}
                formErrorMessages={formErrorMessages}
                readOnly={readOnly}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
            />
        );
    }

    renderSelect(name, options, required) {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <Select
                name={name}
                required={required}
                options={options}
                formValues={formValues}
                formErrors={formErrors}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
            />
        );
    }

    renderPms() {
        const { formValues, addPm, setPm } = this.props;
        return (
            <div style={{ marginTop: 10 }}>
                {formValues['pm_invoices'].map((pm, index) => {
                    return (
                        <div key={'pm-invoice-' + index}>
                            <input type="text" className="pm-input" value={pm} onChange={(event) => setPm(index, event.target.value)} />
                        </div>
                    );
                })}
                <a href="/" style={{ marginTop: 15 }} onClick={(event) => {
                    event.preventDefault();
                    addPm();
                }}><i className="fas fa-plus-square"></i> Add QB#</a>
            </div>
        );
    }

    renderAuditHistory() {
        const { formValues } = this.props;
        return formValues['audit_history'].map((element, index) => {
            let json = JSON.parse(element['description']);
            let newValues = json['new_values'];

            let type = null;
            if (newValues['type'] === 1) {
                type = 'ACH';
            } else if (newValues['type'] === 2) {
                type = 'Cash';
            } else if (newValues['type'] === 3) {
                type = 'Check';
            } else if (newValues['type'] === 4) {
                type = 'Paypal';
            } else if (newValues['type'] === 5) {
                type = 'Wire-Transfer';
            } else if (newValues['type'] === 6) {
                type = 'Visa/Master';
            } else if (newValues['type'] === 7) {
                type = 'AMEX';
            }

            let record = [<span key={'record-1'}>{moment(newValues['date']).format('MM/DD/YYYY')}: <span className={this.hasChange(json, 'updated_by_name') ? 'changed' : ''}>{newValues['updated_by_name']}</span> -
                <span className={this.hasChange(json, 'type') ? 'changed' : ''}>Type: {type}</span>, </span>];

            if (newValues['type'] === 3) {
                record.push(<span key={'record-2'} className={this.hasChange(json, 'check') ? 'changed' : ''}>Check#: {newValues['check']}, </span>);
            } else if (newValues['type'] === 6 || newValues['type'] === 7) {
                record.push(<span key={'record-2'} className={this.hasChange(json, 'approval_code') ? 'changed' : ''}>Approval Code: {newValues['approval_code']}, </span>);
                record.push(<span key={'record-9'} className={this.hasChange(json, 'convenience_fee') ? 'changed' : ''}>Approval Code: {newValues['convenience_fee']}, </span>);
                record.push(<span key={'record-10'} className={this.hasChange(json, 'ac_bank') ? 'changed' : ''}>Approval Code: {newValues['ac_bank']}, </span>);
            }

            record.push(<span key={'record-3'} className={this.hasChange(json, 'payor_name') ? 'changed' : ''}>Payor: {newValues['payor_name']}, </span>);
            record.push(<span key={'record-4'} className={this.hasChange(json, 'csr_name') ? 'changed' : ''}>CSR: {newValues['csr_name']}, </span>);
            record.push(<span key={'record-5'} className={this.hasChange(json, 'sales_name') ? 'changed' : ''}>Salesperson: {newValues['sales_name']}, </span>);

            if (newValues['has_pm']) {
                let invoices = JSON.parse(newValues['pm_invoices']);
                record.push(<span key={'record-6'} className={this.hasChange(json, 'pm_invoices') ? 'changed' : ''}>QB#: {invoices.join(', ')}, </span>);
            }

            record.push(<span key={'record-7'} className={this.hasChange(json, 'amount') ? 'changed' : ''}>Amount: {numeral(newValues['amount']).format('$0,0.00')}, </span>);
            record.push(<span key={'record-8'} className={this.hasChange(json, 'acct_rec') ? 'changed' : ''}>ACCT. REC.: {numeral(newValues['acct_rec']).format('$0,0.00')}, </span>);
            record.push(<span key={'record-9'} className={this.hasChange(json, 'pstg') ? 'changed' : ''}>ADVANCED PSTG.: {numeral(newValues['pstg']).format('$0,0.00')}</span>);

            return (
                <div className="audit-element" key={'audit-' + index}>
                    {record}
                </div>
            );
        });
    }

    hasChange(json, attribute) {
        if (json['is_new']) {
            return false;
        }

        return json['new_values'][attribute] !== json['old_values'][attribute];
    }

    saveElementHandler() {
        if (this.props.isSavingForm) {
            return;
        }

        this.props.saveElement();
    }

    render() {
        const { isSavingForm, data, formValues, handleShowList, resetForm, handleUpdateFormDateValue } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let payorsOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['payors'].length; i++) {
            payorsOptions.push({ label: data['payors'][i]['name'], value: data['payors'][i]['id'] });
        }

        let salesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['sales'].length; i++) {
            salesOptions.push({ label: data['sales'][i]['name'], value: data['sales'][i]['id'] });
        }

        let csrOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['csrs'].length; i++) {
            csrOptions.push({ label: data['csrs'][i]['name'], value: data['csrs'][i]['id'] });
        }

        return (
            <form id="deposits-form">
                <div className="column1">
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        Deposit Date
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={handleUpdateFormDateValue.bind(this, 'date', true)}
                            value={formValues['date'] === '0000-00-00' ? 'N/A' : moment(formValues['date']).format('MM/DD/YYYY')}
                        />
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        Type:
                        {this.renderSelect('type', [
                            { label: 'Select option', value: '' },
                            { label: 'ACH', value: 1 },
                            { label: 'Cash', value: 2 },
                            { label: 'Check', value: 3 },
                            { label: 'Paypal', value: 4 },
                            { label: 'Wire-Transfer', value: 5 },
                            { label: 'Visa/Master', value: 6 },
                            { label: 'AMEX', value: 7 }
                        ], false)}
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        Check #:
                        {this.renderInput('check', 'text', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        Approval Code:
                        {this.renderInput('approval_code', 'text', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        Convenience Fee:
                        {this.renderInput('convenience_fee', 'text', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        AC or ACM Bank:
                        {this.renderInput('ac_bank', 'text', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        Payor:
                        {this.renderSelect('payor_id', payorsOptions, false)}
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        CSR:
                        {this.renderSelect('csr_id', csrOptions, false)}
                    </div>
                    <div className="element" style={{ width: 'calc(20% - 15px)' }}>
                        Salesperson:
                        {this.renderSelect('sales_id', salesOptions, false)}
                    </div>
                    <div className="element" style={{ width: 'calc(100% - 15px)', marginTop: 15 }}>
                        Notes:
                        {this.renderTextarea('notes', false)}
                    </div>
                </div>
                <div className="column2">
                    <div className="element" style={{ width: 'calc(100% - 15px)', marginTop: 18 }}>
                        {this.renderCheckbox('has_postage', 'text', false)} Postage
                        <br />
                        {this.renderCheckbox('has_pm', 'text', false)} QB Invoice
                        <br />
                        {this.renderPms()}
                    </div>
                </div>
                <div className="column3">
                    <div className="element" style={{ width: 'calc(25% - 15px)' }}>
                        Amount:
                        {this.renderInput('amount', 'decimal', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(25% - 15px)' }}>
                        ACCT. REC.:
                        {this.renderInput('acct_rec', 'decimal', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(25% - 15px)' }}>
                        ADVANCED PSTG.:
                        {this.renderInput('pstg', 'decimal', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(25% - 15px)' }}>
                        Other:
                        {this.renderInput('other', 'decimal', false)}
                    </div>
                    <div className="clearfix"></div>
                    <div style={{ marginTop: 10 }}>
                        AUDIT HISTORY:
                        {this.renderAuditHistory()}
                    </div>
                    <div style={{ marginTop: 120 }}>
                        <div className="upload-button" onClick={handleShowList}>Exit</div>
                        <div className="upload-button" onClick={resetForm}>Reset</div>
                        <div className="upload-button" onClick={this.saveElementHandler.bind(this)}>{saveButtonLabel}</div>
                    </div>
                </div>
            </form>
        );
    }
}

export default Form;
