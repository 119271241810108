import axios from 'axios';
import * as types from './DptCalculator.types';
// import * as selectors from './DptCalculator.selectors';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = () => (dispatch) => {
    dispatch({ type: types.FETCH_DATA, payload: null });
    axios.get(apiUrl + '/dpt-estimate/data',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_DATA_ERROR));
        });
}

export const checkElement = (name, id, value) => (dispatch) => {
    dispatch({
        type: types.CHECK_ELEMENT,
        payload: { name, id, value },
    });
}

export const setQuantity = (name, id, value) => (dispatch) => {
    dispatch({
        type: types.SET_QUANTITY,
        payload: { name, id, value },
    });
}

export const setInchesLong = (name, id, value) => (dispatch) => {
    dispatch({
        type: types.SET_INCHES_LONG,
        payload: { name, id, value },
    });
}

export const setWaferSeals = (name, id, value) => (dispatch) => {
    dispatch({
        type: types.SET_WAFER_SEALS,
        payload: { name, id, value },
    });
}

export const setInserts = (name, id, value) => (dispatch) => {
    dispatch({
        type: types.SET_INSERTS,
        payload: { name, id, value },
    });
}

export const setShow = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_SHOW,
        payload: { name, value },
    });
}

export const toggleShow = (name) => (dispatch) => {
    dispatch({
        type: types.TOGGLE_SHOW,
        payload: name,
    });
}

export const setDepartment = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_DEPARTMENT,
        payload: { name, value },
    });
}

export const setDepartmentElement = (name, id, value) => (dispatch) => {
    dispatch({
        type: types.SET_DEPARTMENT_ELEMENT,
        payload: { name, id, value },
    });
}

export const setEntireDepartment = (name, showName, indexes, values) => (dispatch) => {
    dispatch({
        type: types.SET_ENTIRE_DEPARTMENT,
        payload: { name, showName, indexes, values },
    });
}

export const replaceDepartment = (name, showName, value) => (dispatch) => {
    dispatch({
        type: types.REPLACE_DEPARTMENT,
        payload: { name, showName, value },
    });
}

export const resetData = () => (dispatch) => {
    dispatch({
        type: types.RESET_DATA,
    });
}

export const hideAll = () => (dispatch) => {
    dispatch({
        type: types.HIDE_ALL,
    });
}

export const setSummaryElement = (departmentName, id, name, quantity, totalTime, tooltipInfo, department) => (dispatch) => {
    dispatch({
        type: types.SET_SUMMARY_ELEMENT,
        payload: { departmentName, id, name, quantity, totalTime, tooltipInfo, department }
    });
}

export const removeSummaryElement = (departmentName, id) => (dispatch) => {
    dispatch({
        type: types.REMOVE_SUMMARY_ELEMENT,
        payload: { departmentName, id }
    });
}

export const setDifficultyLevelDp = (id, name, totalTime, tooltipInfo) => (dispatch) => {
    dispatch({
        type: types.SET_DIFFICULTY_LEVEL_DP,
        payload: { id, name, totalTime, tooltipInfo }
    });
}

export const setPresortDp = (id, name, totalTime, tooltipInfo) => (dispatch) => {
    dispatch({
        type: types.SET_PRESORT_DP,
        payload: { id, name, totalTime, tooltipInfo }
    });
}

export const resetSummaryDepartments = (departments) => (dispatch) => {
    dispatch({
        type: types.RESET_SUMMARY_DEPARTMENTS,
        payload: departments
    });
}

export const resetDepartment = (departmentName) => (dispatch) => {
    dispatch({
        type: types.RESET_DEPARTMENT,
        payload: departmentName
    });
}

export const resetEstimateDepartment = (departmentName) => (dispatch) => {
    dispatch({
        type: types.RESET_ESTIMATE_DEPARTMENT,
        payload: departmentName
    });
}

export const setDepartmentMainQuantity = (department, quantity, ids) => (dispatch) => {
    dispatch({
        type: types.SET_DEPARTMENT_MAIN_QUANTITY,
        payload: { department, quantity, ids }
    });
}

export const changeAccountField = (department, field, value) => (dispatch) => {
    dispatch({
        type: types.CHANGE_ACCOUNT_FIELD,
        payload: { department, field, value }
    });
}

export const changeAccountItem = (department, index, field, value) => (dispatch) => {
    dispatch({
        type: types.CHANGE_ACCOUNT_ITEM,
        payload: { department, index, field, value }
    });
}

export const addAccountItem = (department) => (dispatch) => {
    dispatch({
        type: types.ADD_ACCOUNT_ITEM,
        payload: { department }
    });
}

export const removeAccountItem = (department, index) => (dispatch) => {
    dispatch({
        type: types.REMOVE_ACCOUNT_ITEM,
        payload: { department, index }
    });
}