export const SET_FIELD = 'DPT_DIGITAL/SET_FIELD';
export const FETCH_DATA = 'DPT_DIGITAL/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'DPT_DIGITAL/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'DPT_DIGITAL/FETCH_DATA_ERROR';
export const SAVE = 'DPT_DIGITAL/SAVE';
export const SAVE_SUCCESS = 'DPT_DIGITAL/SAVE_SUCCESS';
export const SAVE_ERROR = 'DPT_DIGITAL/SAVE_ERROR';
export const FETCH_ELEMENT = 'DPT_DIGITAL/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'DPT_DIGITAL/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'DPT_DIGITAL/FETCH_ELEMENT_ERROR';
export const SET_ACTIVE_PAGE = 'DPT_DIGITAL/SET_ACTIVE_PAGE';
export const RESET_FORM = 'DPT_DIGITAL/RESET_FORM';