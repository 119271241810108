import React, { Component } from 'react';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import TextArea from './../../common/components/TextArea';

class SendEmail extends Component {
    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    renderTextarea(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <TextArea
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderEmails() {
        const { data, formValues, setEmail } = this.props;
        const userFields = data['mainList'].map((user, index) => {
            return (
                <div className="deposit-email-element" key={'email-element-' + index}>
                    <input type="checkbox" checked={formValues['emails'].includes(user['email'])}
                        onClick={(event) => setEmail(user['email'], event.target.checked ? 1 : 0)} /> {user['name']}
                </div>
            );
        });

        userFields.push(
            <div className="deposit-email-element" key="email-element-nyke">
                <input type="checkbox" checked={formValues['emails'].includes('nykeshia@acmailplex.com')}
                    onClick={(event) => setEmail('nykeshia@acmailplex.com', event.target.checked ? 1 : 0)} /> Nykeshia (CSR)
            </div>
        );
        userFields.push(
            <div className="deposit-email-element" key="email-element-nyke">
                <input type="checkbox" checked={formValues['emails'].includes('beatriz@acmailplex.com')}
                    onClick={(event) => setEmail('beatriz@acmailplex.com', event.target.checked ? 1 : 0)} /> Beatriz (CSR)
            </div>
        );
        userFields.push(
            <div className="deposit-email-element" key="email-element-nyke">
                <input type="checkbox" checked={formValues['emails'].includes('randy@acmailplex.com')}
                    onClick={(event) => setEmail('randy@acmailplex.com', event.target.checked ? 1 : 0)} /> Randy (CSR)
            </div>
        );
        userFields.push(
            <div className="deposit-email-element" key="email-element-nyke">
                <input type="checkbox" checked={formValues['emails'].includes('terri@acmailplex.com')}
                    onClick={(event) => setEmail('terri@acmailplex.com', event.target.checked ? 1 : 0)} /> Terri (CSR)
            </div>
        );
        userFields.push(
            <div className="deposit-email-element" key="email-element-nyke">
                <input type="checkbox" checked={formValues['emails'].includes('john@acmailplex.com')}
                    onClick={(event) => setEmail('john@acmailplex.com', event.target.checked ? 1 : 0)} /> John (CSR)
            </div>
        );
        userFields.push(
            <div className="deposit-email-element" key="email-element-nyke">
                <input type="checkbox" checked={formValues['emails'].includes('skip@acmailplex.com')}
                    onClick={(event) => setEmail('skip@acmailplex.com', event.target.checked ? 1 : 0)} /> Skip (CSR)
            </div>
        );

        return userFields;
    }

    render() {
        const { hideSendEmail, sendEmail, isSendingEmail, formValues, handleUpdateFormValue } = this.props;

        let saveButtonLabel = 'Send';
        if (isSendingEmail) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">Email</div>
                    <div className="w-100"></div>
                    {this.renderInput('Subject line', 'email_subject', 'text', false)}
                    <div className="w-100"></div>
                    <div className="col mt-2">
                        {this.renderEmails()}
                        <div className="deposit-email-element">
                            <input type="checkbox" name="email_csr" checked={formValues['email_csr']}
                                onClick={handleUpdateFormValue.bind(this, 'number', true)} /> AR
                        </div>
                        <div className="deposit-email-element">
                            <input type="checkbox" name="email_sales" checked={formValues['email_sales']}
                                onClick={handleUpdateFormValue.bind(this, 'number', true)} /> <b>Send to Sales</b>
                        </div>
                    </div>
                    <div className="w-100"></div>
                    {this.renderTextarea('Notes', 'email_notes', 'text', false)}
                    <div className="w-100"></div>
                </div>
            </form>
        );

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={sendEmail} disabled={isSendingEmail}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideSendEmail}>Close</button>
        ];
        return (
            <Dialog
                name="send-email"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default SendEmail;
