import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {

    };

    handleUpdateFormValue(event) {
        const { updateFormValue } = this.props;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        updateFormValue(event.target.name, value);
    }

    render() {
        const { user, formValues } = this.props;
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>AC Sales Report</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="AC Sales Report"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs user={user} />
                            <Table
                                formValues={formValues}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    user: loginSelectors.getUser,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
