import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import moment from 'moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { processedElements, isFetchingElements, handleShowForm, handleShowDelete, selectedPage, elementsPerPage,
            statuses, isFetchingStatuses } = this.props;

        if (isFetchingElements || isFetchingStatuses) {
            return (
                <tr>
                    <td colSpan="7"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (processedElements.length === 0) {
            return (
                <tr>
                    <td colSpan="7">No Results</td>
                </tr>
            );
        }

        let data = [];
        let startIndex = (selectedPage - 1) * elementsPerPage;
        for (let i = startIndex; i < processedElements.length && i < startIndex + elementsPerPage; i++) {
            let status = '';
            for (let j = 0; j < statuses.length; j++) {
                if(parseInt(processedElements[i]['status_id']) === parseInt(statuses[j]['id'])) {
                    status = statuses[j]['name'];
                }
            }

            data.push(
                <tr key={i}>
                    <td>{processedElements[i]['id']}</td>
                    <td>{processedElements[i]['name']}</td>
                    <td>{processedElements[i]['customer_name']}</td>
                    <td>{status}</td>
                    <td>{processedElements[i]['due_date'] == null ? 'N/A' : moment(processedElements[i]['due_date']).format('MM/DD/YYYY')}</td>
                    <td>{moment(processedElements[i]['created_at']).format('MM/DD/YYYY')}</td>
                    <td>{numeral(processedElements[i]['total']).format('$0,0.00')}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, processedElements[i]['id'], null)} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, processedElements[i]['id'])} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { processedElements, totalPages, selectedPage, elementsPerPage } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm">
                        <thead>
                            <tr>
                                {this.renderTableHeader('ID', 'id', { width: '7%' }, false)}
                                {this.renderTableHeader('Title', 'name', { width: 'calc(25% - 110px)' })}
                                {this.renderTableHeader('Customer', 'customer_name', { width: '20%' })}
                                {this.renderTableHeader('Status', 'status', { width: '12%' })}
                                {this.renderTableHeader('Due Date', 'due_date', { width: '12%' })}
                                {this.renderTableHeader('Order Date', 'created_at', { width: '12%' })}
                                {this.renderTableHeader('Total', 'total', { width: '12%' })}
                                <th style={{ width: 110 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {this.renderSearchField('id')}
                                {this.renderSearchField('name')}
                                {this.renderSearchField('customer_name')}
                                {this.renderSearchField('status')}
                                {this.renderSearchField('due_date')}
                                {this.renderSearchField('created_at')}
                                {this.renderSearchField('total')}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={processedElements.length}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
