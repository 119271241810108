import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getModel = state => state[NAME];

/** form **/
export const getForm = createSelector(
    getModel,
    (model) => model.form
);
export const getFormValues = createSelector(
    getForm,
    (form) => form.formValues
);
export const getFormErrors = createSelector(
    getForm,
    (form) => form.formErrors
);
export const getIsSaving = createSelector(
    getForm,
    (form) => form.isSaving
);

/** history **/
export const getHistory = createSelector(
    getModel,
    (model) => model.history
);
