import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import moment from 'moment';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const saveElement = () => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());
    const costs = selectors.getCosts(getState());

    if (formValues['date'] === '' || formValues['date'] === null) {
        showNotification('Complete the Information', 'Enter the date', 'info');
        return;
    }
    if (formValues['pm_number'] === '' || formValues['pm_number'] === null) {
        showNotification('Complete the Information', 'Enter the PM #', 'info');
        return;
    }
    if (formValues['customer'] === '' || formValues['customer'] === null) {
        showNotification('Complete the Information', 'Enter the Customer', 'info');
        return;
    }
    if (formValues['sales_id'] === '' || formValues['sales_id'] === null) {
        showNotification('Complete the Information', 'Select the sales', 'info');
        return;
    }
    if (formValues['csr_id'] === '' || formValues['csr_id'] === null) {
        showNotification('Complete the Information', 'Select the CSR', 'info');
        return;
    }
    if (formValues['department_id'] === '' || formValues['department_id'] === null) {
        showNotification('Complete the Information', 'Select the department', 'info');
        return;
    }
    if (formValues['chargeable'] === '' || formValues['chargeable'] === null) {
        showNotification('Complete the Information', 'Select if it is chargeable or not', 'info');
        return;
    }
    if (formValues['quantity'] === '' || formValues['quantity'] === null) {
        showNotification('Complete the Information', 'Enter the Quantity', 'info');
        return;
    }
    if (formValues['reason'] === '' || formValues['reason'] === null) {
        showNotification('Complete the Information', 'Enter the Reason for re-work', 'info');
        return;
    }

    let endpoint = null;
    let method = null;
    if (formValues['id'] === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + formValues['id'];
        method = 'PUT';
    }

    formValues['date'] = moment(formValues['date']).format('YYYY-MM-DD');
    formValues['costs'] = costs;

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: formValues,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements());
            if (formValues['id'] === '') {
                dispatch(resetForm());
            } else {
                dispatch(setActiveElement([response.data], formValues['id']));
            }
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
                dispatch(processElements());
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export function processElements() {
    return (dispatch, getState) => {
        const elements = selectors.getElements(getState());
        const filterFields = selectors.getFilterFields(getState());
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());

        let filteredElements = [];
        for (let i = 0; i < elements.length; i++) {
            let add = true;
            let startDate = null;
            let endDate = null;
            for (let j = 0; j < filterFields.length; j++) {
                if (filterFields[j]['name'] === 'start_date') {
                    startDate = filterFields[j]['value'];
                }
                if (filterFields[j]['name'] === 'end_date') {
                    endDate = filterFields[j]['value'];
                }

                if (filterFields[j]['name'] !== 'start_date' && filterFields[j]['name'] !== 'end_date') {
                    let attribute = String(elements[i][filterFields[j]['name']]).toLowerCase();
                    let filterValue = String(filterFields[j]['value']).toLowerCase();
                    if (!attribute.includes(filterValue)) {
                        add = false;
                        break;
                    }
                }
            }

            if (startDate !== null && startDate !== '' && (endDate == null || endDate === '')) {
                let attribute = elements[i]['date'];
                if (moment(attribute).diff(startDate, 'minutes') < 0) {
                    add = false;
                }
            } else if (endDate !== null && endDate !== '' && (startDate === null || startDate === '')) {
                let attribute = elements[i]['date'];
                if (moment(endDate).diff(attribute, 'minutes') < 0) {
                    add = false;
                }
            } else if (startDate !== null && startDate !== '' && endDate !== null && endDate !== '') {
                let attribute = elements[i]['date'];
                if (moment(attribute).diff(startDate, 'minutes') < 0 || moment(endDate).diff(attribute, 'minutes') < 0) {
                    add = false;
                }
            }

            if (add) {
                filteredElements.push(elements[i]);
            }
        }

        let sortedElements = filteredElements.sort((a, b) => {
            if (typeof a[sortColumn] === 'number') {
                if (sortType === 'asc') {
                    if (a[sortColumn] < b[sortColumn]) return -1;
                    if (a[sortColumn] > b[sortColumn]) return 1;
                    if (a['id'] < b['id']) return -1;
                    if (a['id'] > b['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn] < a[sortColumn]) return -1;
                    if (b[sortColumn] > a[sortColumn]) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            } else {
                if (sortType === 'asc') {
                    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) return -1;
                    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn].toLowerCase() < a[sortColumn].toLowerCase()) return -1;
                    if (b[sortColumn].toLowerCase() > a[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            }
        });

        dispatch({
            type: actionTypes.PROCESS_ELEMENTS,
            payload: sortedElements
        });
    };
}

export const fetchData = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_DATA, payload: null });
    axios.get(apiUrls.data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_DATA_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_DATA_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function showList(name, value) {
    return {
        type: actionTypes.SHOW_LIST,
        payload: null
    };
}

export function showForm(name, value) {
    return {
        type: actionTypes.SHOW_FORM,
        payload: null
    };
}

export function addNotification(element) {
    return {
        type: actionTypes.ADD_NOTIFICATION,
        payload: element
    };
}

export function removeNotification(element) {
    return {
        type: actionTypes.REMOVE_NOTIFICATION,
        payload: element
    };
}

export function setCost(name, type, value) {
    return {
        type: actionTypes.SET_COST,
        payload: { name, type, value }
    };
}
