import React from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const InkjetForm = (props) => {
    const checkElementHandler = (id, event) => {
        props.checkElement('dptInkjet', id, event.target.checked ? 1 : 0);

        if (!event.target.checked) {
            props.removeSummaryElement('dptInkjet', id);
            props.setQuantity('dptInkjet', id, '');
            props.setInchesLong('dptInkjet', id, '');
            props.setWaferSeals('dptInkjet', id, '');
        }
    };

    const validateIsReady = (id, quantity, inchesLong, waferSeals) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element.checked || !quantity || !inchesLong || typeof waferSeals == 'undefined' || waferSeals === '' || isNaN(waferSeals)) {
            props.removeSummaryElement('dptInkjet', id);
            return;
        }

        let ipm = element.ips * 60;
        if (parseInt(waferSeals) === 2) {
            ipm *= (1 - element.two_wafer_seals_reduction);
        } if (parseInt(waferSeals) >= 3) {
            ipm *= (1 - element.three_wafer_seals_reduction);
        }

        const timeInMins = quantity * inchesLong / ipm + element.setup_time;
        const material = props.materials.find((material) => parseInt(material.dpt_inkjet_id) === parseInt(element.id));

        let tooltipInfo = (
            <div className="text-left">
                <div>Production Time: {element.ips + ' ips'}.</div>
                <div>2 Waffer seals reduce production rate by {numeral(element.two_wafer_seals_reduction).format('0%')}.</div>
                <div>3 Waffer seals reduce production rate by {numeral(element.three_wafer_seals_reduction).format('0%')}.</div>
                <div>Setup time: {element.setup_time} minutes.</div>
            </div>
        );

        props.setSummaryElement('dptInkjet', id, material.description, quantity, timeInMins, tooltipInfo);
    };

    const setInchesLongHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setInchesLong('dptInkjet', id, value);

        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        validateIsReady(id, element.selected_quantity, value, element.wafer_seals);
    };

    const setWaferSealsHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setWaferSeals('dptInkjet', id, value);

        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        validateIsReady(id, element.selected_quantity, element.inches_long, value);
    };

    const setQuantityHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setQuantity('dptInkjet', id, value);

        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        validateIsReady(id, value, element.inches_long, element.wafer_seals);
    };

    const renderElement = (id) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element) {
            return null;
        }

        let value = '';
        if (element.selected_quantity) {
            value = element.selected_quantity;
        }

        let inchesLong = '';
        if (element.inches_long) {
            inchesLong = element.inches_long;
        }

        let waferSeals = '';
        if (element.wafer_seals || parseInt(element.wafer_seals) === 0) {
            waferSeals = element.wafer_seals;
        }

        const material = props.materials.find((material) => parseInt(material.dpt_inkjet_id) === parseInt(element.id));
        return (
            <div className="field">
                <input
                    type="checkbox"
                    checked={element.checked ? 1 : 0}
                    onChange={checkElementHandler.bind(this, id)}
                />
                <input
                    type="number"
                    min="0"
                    placeholder="Qty"
                    className="quantity-field"
                    value={value}
                    disabled={!element.checked}
                    onChange={setQuantityHandler.bind(this, id)}
                />
                <input
                    type="number"
                    min="0"
                    placeholder="Inches"
                    value={inchesLong}
                    disabled={!element.checked}
                    onChange={setInchesLongHandler.bind(this, element.id)}
                    className="inches-long"
                />
                <input
                    type="number"
                    min="0"
                    placeholder="Tabs"
                    value={waferSeals}
                    disabled={!element.checked}
                    onChange={setWaferSealsHandler.bind(this, element.id)}
                    className="tabs"
                />
                {material.description}
            </div>
        );
    };

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.inkjet) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    return (
        <div className="department-configuration inkjet-form">
            <h2>
                <div>Inkjet</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={props.resetDepartment.bind(this, 'dptInkjet')}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'inkjet')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.inkjet ? {} : { 'display': 'none' }}>
                <div className="form">
                    <div className="form-section">
                        <h6>Postcards:</h6>
                        {renderElement(1)}
                        {renderElement(2)}
                        {renderElement(4)}
                    </div>
                    <div className="form-section">
                        <h6>Publications:</h6>
                        {renderElement(3)}
                        {renderElement(5)}
                    </div>
                </div>
                <AccountingForm
                    department="inkjet"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default InkjetForm;