import { NAME } from './constants';

export const SAVE_PASSWORD = `${NAME}/SAVE_PASSWORD`;
export const SAVE_PASSWORD_SUCCESS = `${NAME}/SAVE_PASSWORD_SUCCESS`;
export const SAVE_PASSWORD_FAILURE = `${NAME}/SAVE_PASSWORD_FAILURE`;

export const UPDATE_FORM_VALUE = `${NAME}/UPDATE_FORM_VALUE`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_FORM_ERRORS = `${NAME}/SET_FORM_ERRORS`;
export const SET_FORM_ERROR = `${NAME}/SET_FORM_ERROR`;
export const SET_TOKEN = `${NAME}/SET_TOKEN`;
export const SET_HISTORY = `${NAME}/SET_HISTORY`;
