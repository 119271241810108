import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const Papers = (props) => {
    const [fetchingPapers, setFetchingPapers] = useState(false);

    const productType = props.productType;
    const pressId = props.pressId;
    const secondPressId = props.secondPressId;
    const digitalMachineId = props.digitalMachineId;
    const paperCategoryId = props.paperCategoryId;
    const paperWeight = props.paperWeight;
    const setPapers = props.setPapers;
    useEffect(() => {
        const productTypeInt = parseInt(productType);
        if (productTypeInt === 1 && pressId === '') {
            return;
        } else if (productTypeInt === 2 && (pressId === '' || secondPressId === '')) {
            return;
        } else if (productTypeInt === 3 && digitalMachineId === '') {
            return;
        }

        if (paperCategoryId === '' || paperWeight === '') {
            return;
        }

        let url = process.env.REACT_APP_API_URL + '/dpt-estimate/papers?type=' + productType + '&paperCategoryId=' + paperCategoryId + '&paperWeight=' + paperWeight;
        if(productTypeInt === 1) {
            url += '&pressId=' + pressId;
        } else if (productTypeInt === 2) {
            url += '&isCover=0&pressId=' + pressId + '&secondPressId=' + secondPressId;
        } else if(productTypeInt === 3) {
            url += '&digitalMachineId=' + digitalMachineId;
        }

        setFetchingPapers(true);
        axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setPapers(response.data);
                setFetchingPapers(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingPapers(false);
            });
    }, [productType, pressId, secondPressId, digitalMachineId, paperCategoryId, paperWeight, setPapers]);

    const changeHandler = (event) => {
        props.setPaperId(event.target.value);
    };

    let options = [];
    if (props.paperWeight === '') {
        options.push(<option key="0" value="">Select a Weight</option>);
    } else {
        options = props.papers.map((paper) => <option key={paper.id} value={paper.id}>{paper.paper_name} {paper.width}x{paper.length}</option>);
        options.unshift(<option key="0" value="">Select option</option>);
    }

    return (
        <div className="field">
            <div className="label">{props.label ? props.label : 'Type'}:</div>
            <select value={props.paperId} onChange={changeHandler} disabled={props.paperWeight === ''}>
                {options}
            </select>
            {fetchingPapers && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default Papers;