import React, { Component } from 'react';

class Form extends Component {
    static propTypes = {

    };

    render() {
        const { formValues, handleUpdateFormValue } = this.props;

        const exportHandler = () => {
            window.open(process.env.REACT_APP_API_URL + '/order/export-ac-report?month=' + formValues.month + '&year=' + formValues.year);
        };

        return (
            <div style={{ marginTop: 15 }}>
                <div className="row">
                    <div className="col">
                        <label>Month:</label>
                        <select name="month" value={formValues.month} onChange={handleUpdateFormValue.bind(this)}
                            style={{ width: 100, marginRight: 15, height: 28 }}>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <label>Year:</label>
                        <select name="year" value={formValues.year} onChange={handleUpdateFormValue.bind(this)}
                            style={{ width: 100, height: 28 }}>
                            <option value="2010">2010</option>
                            <option value="2011">2011</option>
                            <option value="2012">2012</option>
                            <option value="2013">2013</option>
                            <option value="2014">2014</option>
                            <option value="2015">2015</option>
                            <option value="2016">2016</option>
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                        </select>
                        <button key="save-button" className="btn btn-light btn-sm action-button" onClick={exportHandler}><i className="fas fa-file-excel"></i> Export</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
