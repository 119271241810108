const API_URL = process.env.REACT_APP_API_URL;

let apiUrls = {
    elements: `${API_URL}/envelope-presses`,
    search: `${API_URL}/envelope-press/search`,
    saveSpeedTable: `${API_URL}/envelope-press/save-speed-table`,
    saveDifficultyTable: `${API_URL}/envelope-press/save-difficulty-table`,
    saveOverageTable: `${API_URL}/envelope-press/save-overage-table`,
    information: `${API_URL}/envelope-press/information`
};

export default apiUrls;
