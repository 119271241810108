import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const DigitalMachines = (props) => {
    const [fetchingDigitalMachines, setFetchingDigitalMachines] = useState(false);

    const productType = props.productType;
    const setDigitalMachines = props.setDigitalMachines;
    useEffect(() => {
        if (parseInt(productType) !== 3) {
            return;
        }

        setFetchingDigitalMachines(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/digital-machines',
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setDigitalMachines(response.data);
                setFetchingDigitalMachines(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingDigitalMachines(false);
            });
    }, [productType, setDigitalMachines]);

    const changeHandler = (event) => {
        props.setDigitalMachineId(event.target.value);

        props.setPaperCategories([]);
        props.setPaperCategoryId('');

        props.setPaperWeights([]);
        props.setPaperWeight('');

        props.setPapers([]);
        props.setPaperId('');

        const machine = props.digitalMachines.find((machine) => parseInt(machine.id) === parseInt(event.target.value));
        if(machine && parseFloat(machine.cost_click_color) === 0) {
            props.setColor('1');
        }
    };

    return (
        <div className="field">
            <div className="label">Digital:</div>
            <select value={props.digitalMachineId} onChange={changeHandler}>
                <option value="">Select option</option>
                {props.digitalMachines.map((digitalMachine) => <option key={digitalMachine.id} value={digitalMachine.id}>{digitalMachine.name}</option>)}
            </select>
            {fetchingDigitalMachines && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default DigitalMachines;