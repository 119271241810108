import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AdminHeader from './../common/components/AdminHeader';
import { Table } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import Tabs from './../common/components/Tabs';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        user: PropTypes.object,
        elements: PropTypes.array,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        resetState: PropTypes.func
    };

    componentDidMount() {
        const { fetchData } = this.props;
        fetchData();
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, fetchElements, productCategoryId } = this.props;
        let type = 'asc';
        if (sortColumn === column) {
            type = sortType === 'asc' ? 'desc' : 'asc';
            setSort(column, type);
        } else {
            setSort(column, 'asc');
        }
        fetchElements(1, column, type, productCategoryId);
    }

    handleSetActivePage(page) {
        const { fetchElements, setActivePage, sortColumn, sortType, productCategoryId } = this.props;
        setActivePage(page);
        fetchElements(page, sortColumn, sortType, productCategoryId);
    }

    changeProductTypeHandler(event) {
        const { fetchElements, setProductType, selectedPage, sortColumn, sortType } = this.props;
        let data = event.target.value.split('-');
        setProductType(data[0], data[1]);
        fetchElements(selectedPage, sortColumn, sortType, data[0]);
    }

    quoteClickHandler(id) {
        const { customerId } = this.props;
        if (customerId !== null) {
            this.props.history.push('/product-quote/' + id + '/' + customerId);
        } else {
            this.props.history.push('/product-quote/' + id);
        }
    }

    render() {
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Products Catalog</title>
                        <meta name="description" content="Products Catalog" />
                    </Helmet>
                    <AdminHeader
                        title="Products Catalog"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={this.props.user}
                            />
                            <Table
                                elements={this.props.elements}
                                isFetchingElements={this.props.isFetchingElements}
                                totalItems={this.props.totalItems}
                                totalPages={this.props.totalPages}
                                selectedPage={this.props.selectedPage}
                                elementsPerPage={this.props.elementsPerPage}
                                sortColumn={this.props.sortColumn}
                                sortType={this.props.sortType}
                                user={this.props.user}
                                productCategoryId={this.props.productCategoryId}
                                productType={this.props.productType}
                                productCategories={this.props.productCategories}
                                customers={this.props.customers}
                                customerId={this.props.customerId}
                                isFetchingData={this.props.isFetchingData}
                                setCustomerId={this.props.setCustomerId.bind(this)}
                                handleSort={this.handleSort.bind(this)}
                                changeProductTypeHandler={this.changeProductTypeHandler.bind(this)}
                                quoteClickHandler={this.quoteClickHandler.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    elements: selectors.getElements,
    isFetchingElements: selectors.getIsFetchingElements,
    totalItems: selectors.getTotalItems,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    productCategories: selectors.getProductCategories,
    productCategoryId: selectors.getProductCategoryId,
    productType: selectors.getProductType,
    customerId: selectors.getCustomerId,
    customers: selectors.getCustomers,
    isFetchingData: selectors.getIsFetchingData,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: (selectedPage, sortColumn, sortType, productCategoryId) => dispatch(actions.fetchElements(selectedPage, sortColumn, sortType, productCategoryId)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        resetState: () => dispatch(actions.resetState()),
        setActivePage: (page) => dispatch(actions.setActivePage(page)),
        fetchData: () => dispatch(actions.fetchData()),
        setProductType: (productCategoryId, productType) => dispatch(actions.setProductType(productCategoryId, productType)),
        setCustomerId: (id) => dispatch(actions.setCustomerId(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
