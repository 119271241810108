import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification, validateAuthInResponse } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';
import numeral from 'numeral';

export const fetchElements = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function resetEstimate() {
    return {
        type: actionTypes.RESET_ESTIMATE_FORM,
        payload: null
    };
}

export function resetEstimateForm() {
    return (dispatch, getState) => {
        const configuration = selectors.getConfiguration(getState());
        const formValues = selectors.getFormValues(getState());

        let values = {};
        if (parseInt(formValues['estimate_type']) === 1) {    // Flat
            values.estimate_product_category_id = configuration['product_options']['product_category_id']['default'];
            values.estimate_bleed = configuration['product_options']['bleed']['default'];
            values.estimate_product_description = configuration['product_description']['default'];
            values.estimate_front_inks = configuration['product_options']['front_inks']['default'];
            values.estimate_front_coating = configuration['product_options']['front_coating']['default'];
            values.estimate_back_inks = configuration['product_options']['back_inks']['default'];
            values.estimate_back_coating = configuration['product_options']['back_coating']['default'];
            values.estimate_press_id = configuration['product_options']['press_id']['default'];
            values.estimate_paper_category_id = configuration['product_options']['paper_category_id']['default'];
            values.estimate_paper_weight = configuration['product_options']['paper_weight']['default'];
            values.estimate_paper_id = configuration['product_options']['paper_id']['default'];
            values.estimate_width = configuration['product_options']['flat_width']['default'];
            values.estimate_height = configuration['product_options']['flat_height']['default'];
            values.estimate_finish_width = configuration['product_options']['finish_width']['default'];
            values.estimate_finish_height = configuration['product_options']['finish_height']['default'];
            values.estimate_running_style = configuration['product_options']['running_style']['default'];
            values.estimate_fold_type_id = configuration['bindery_options']['fold_type_id']['default'];
            values.estimate_requires_scoring = configuration['bindery_options']['requires_scoring']['default'];
            values.estimate_requires_die_cutting = configuration['bindery_options']['requires_die_cutting']['default'];
            values.estimate_drilling_holes = configuration['bindery_options']['drilling_holes']['default'];
            values.estimate_requires_padding = configuration['bindery_options']['requires_padding']['default'];
            values.estimate_sheets_to_pad = configuration['bindery_options']['sheets_to_pad']['default'];
            values.estimate_banding_type_id = configuration['handling_options']['banding_type_id']['default'];
            values.estimate_wrapping_type_id = configuration['handling_options']['wrapping_type_id']['default'];
            values.estimate_shrink_wrapping_type_id = configuration['bindery_options']['shrink_wrapping_type_id']['default'];
            values.estimate_package_id = configuration['handling_options']['package_id']['default'];
            values.estimate_use_hand_gathering = configuration['handling_options']['use_hand_gathering']['default'];
            values.estimate_notes = configuration['production_notes']['default'];
            values.estimate_customer_discount_id = configuration['customer_discount'] && configuration['customer_discount']['default'] ? configuration['customer_discount']['default'] : '';
        } else if (parseInt(formValues['estimate_type']) === 2) {    // Multi-page
            values.estimate_multi_page_type = configuration['product_options']['multi_page_type']['default'];
            values.estimate_pages = configuration['product_options']['pages']['default'];
            values.estimate_product_category_id = configuration['product_options']['product_category_id']['default'];
            values.estimate_bleed = configuration['product_options']['bleed']['default'];
            values.estimate_product_description = configuration['product_description']['default'];
            values.estimate_front_inks = configuration['product_options']['front_inks']['default'];
            values.estimate_back_inks = configuration['product_options']['back_inks']['default'];
            values.estimate_interior_inks = configuration['product_options']['interior_inks']['default'];
            values.estimate_width = configuration['product_options']['finish_width']['default'];
            values.estimate_height = configuration['product_options']['finish_height']['default'];
            values.estimate_binding_edge = configuration['product_options']['binding_edge']['default'];
            values.estimate_coil_option = configuration['product_options']['coil_option']['default'];
            values.estimate_coil_color = configuration['product_options']['coil_color']['default'];
            values.estimate_staples_number = configuration['product_options']['staples_number']['default'];
            values.estimate_staples_location = configuration['product_options']['staples_location']['default'];
            values.estimate_cover_press_id = configuration['press_options']['cover_press_id']['default'];
            values.estimate_cover_running_style = configuration['press_options']['cover_running_style']['default'];
            values.estimate_press_id = configuration['press_options']['press_id']['default'];
            values.estimate_running_style = configuration['press_options']['running_style']['default'];
            values.estimate_second_press_id = configuration['press_options']['second_press_id']['default'];
            values.estimate_second_running_style = configuration['press_options']['second_running_style']['default'];
            values.estimate_cover_paper_category_id = configuration['paper_options']['cover_paper_category_id']['default'];
            values.estimate_cover_paper_weight = configuration['paper_options']['cover_paper_weight']['default'];
            values.estimate_cover_paper_id = configuration['paper_options']['cover_paper_id']['default'];
            values.estimate_paper_category_id = configuration['paper_options']['paper_category_id']['default'];
            values.estimate_paper_weight = configuration['paper_options']['paper_weight']['default'];
            values.estimate_paper_id = configuration['paper_options']['paper_id']['default'];
            values.estimate_front_coating = configuration['paper_options']['front_coating']['default'];
            values.estimate_back_coating = configuration['paper_options']['back_coating']['default'];
            values.estimate_interior_coating = configuration['paper_options']['interior_coating']['default'];
            values.estimate_drilling_holes = configuration['bindery_options']['drilling_holes']['default'];
            values.estimate_shrink_wrapping_type_id = configuration['bindery_options']['shrink_wrapping_type_id']['default'];
            values.estimate_banding_type_id = configuration['handling_options']['banding_type_id']['default'];
            values.estimate_wrapping_type_id = configuration['handling_options']['wrapping_type_id']['default'];
            values.estimate_package_id = configuration['handling_options']['package_id']['default'];
            values.estimate_use_hand_gathering = configuration['handling_options']['use_hand_gathering']['default'];
            values.estimate_notes = configuration['production_notes']['default'];
            values.estimate_customer_discount_id = configuration['customer_discount'] && configuration['customer_discount']['default'] ? configuration['customer_discount']['default'] : '';
        } else if (parseInt(formValues['estimate_type']) === 3) {    // Digital
            values.estimate_product_category_id = configuration['product_options']['product_category_id']['default'];
            values.estimate_bleed = configuration['product_options']['bleed']['default'];
            values.estimate_product_description = configuration['product_description']['default'];
            values.estimate_digital_inks = configuration['product_options']['digital_inks']['default'];
            values.estimate_digital_sides = configuration['product_options']['digital_sides']['default'];
            values.estimate_digital_machine_id = configuration['product_options']['digital_machine_id']['default'];
            values.estimate_paper_category_id = configuration['product_options']['paper_category_id']['default'];
            values.estimate_paper_weight = configuration['product_options']['paper_weight']['default'];
            values.estimate_paper_id = configuration['product_options']['paper_id']['default'];
            values.estimate_width = configuration['product_options']['flat_width']['default'];
            values.estimate_height = configuration['product_options']['flat_height']['default'];
            values.estimate_finish_width = configuration['product_options']['finish_width']['default'];
            values.estimate_finish_height = configuration['product_options']['finish_height']['default'];
            values.estimate_fold_type_id = configuration['bindery_options']['fold_type_id']['default'];
            values.estimate_requires_scoring = configuration['bindery_options']['requires_scoring']['default'];
            values.estimate_requires_die_cutting = configuration['bindery_options']['requires_die_cutting']['default'];
            values.estimate_drilling_holes = configuration['bindery_options']['drilling_holes']['default'];
            values.estimate_requires_padding = configuration['bindery_options']['requires_padding']['default'];
            values.estimate_sheets_to_pad = configuration['bindery_options']['sheets_to_pad']['default'];
            values.estimate_banding_type_id = configuration['handling_options']['banding_type_id']['default'];
            values.estimate_wrapping_type_id = configuration['handling_options']['wrapping_type_id']['default'];
            values.estimate_shrink_wrapping_type_id = configuration['bindery_options']['shrink_wrapping_type_id']['default'];
            values.estimate_package_id = configuration['handling_options']['package_id']['default'];
            values.estimate_use_hand_gathering = configuration['handling_options']['use_hand_gathering']['default'];
            values.estimate_customer_discount_id = configuration['customer_discount'] ? configuration['customer_discount']['default'] : '';
            values.estimate_notes = configuration['production_notes']['default'];
            values.estimate_customer_discount_id = configuration['customer_discount'] && configuration['customer_discount']['default'] ? configuration['customer_discount']['default'] : '';
        } else if (parseInt(formValues['estimate_type']) === 4) {    // Large Format
            values.estimate_product_category_id = configuration['product_options']['product_category_id']['default'];
            values.estimate_product_description = configuration['product_description']['default'];
            values.estimate_large_format_machine_id = configuration['product_options']['large_format_machine_id']['default'];
            values.estimate_width = configuration['substrate_options']['flat_width']['default'];
            values.estimate_height = configuration['substrate_options']['flat_height']['default'];
            values.estimate_unit = configuration['substrate_options']['unit']['default'];
            values.estimate_substrate_id = configuration['substrate_options']['substrate_id']['default'];
            values.estimate_laminate_id = configuration['substrate_options']['laminate_id']['default'];
            values.estimate_notes = configuration['production_notes']['default'];
            values.estimate_accessories = configuration['accessories']['default'];
            values.estimate_customer_discount_id = configuration['customer_discount'] && configuration['customer_discount']['default'] ? configuration['customer_discount']['default'] : '';
        }

        dispatch(updateState({ formValues: values }));
        dispatch(resetEstimate());
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function setActiveElement(element) {
    let estimate_results = [];
    let quantities = [0, 0, 0];
    let pressPrices = [0, 0, 0];
    let paperPrices = [0, 0, 0];
    let inkPrices = [0, 0, 0];
    let cuttingPrices = [0, 0, 0];
    let bindingPrices = [0, 0, 0];
    let foldingPrices = [0, 0, 0];
    let digitalMachinePrices = [0, 0, 0];
    let largeFormatMachinePrices = [0, 0, 0];
    let substratePrices = [0, 0, 0];
    let laminatePrices = [0, 0, 0];
    let accessoriesPrices = [0, 0, 0];
    let scoringPrices = [0, 0, 0];
    let dieCuttingPrices = [0, 0, 0];
    let drillingPrices = [0, 0, 0];
    let paddingPrices = [0, 0, 0];
    let bandingPrices = [0, 0, 0];
    let wrappingPrices = [0, 0, 0];
    let shrinkWrappingPrices = [0, 0, 0];
    let packagePrices = [0, 0, 0];
    let handGatheringPrices = [0, 0, 0];
    let countingPrices = [0, 0, 0];
    for (let i = 0; i < element.estimateResults.length; i++) {
        estimate_results.push(JSON.parse(element.estimateResults[i]['json']));
        quantities[i] = element.estimateResults[i]['quantity'];
        pressPrices[i] = element.estimateResults[i]['press_price'];
        paperPrices[i] = element.estimateResults[i]['paper_price'];
        inkPrices[i] = element.estimateResults[i]['ink_price'];
        cuttingPrices[i] = element.estimateResults[i]['cutting_price'];
        bindingPrices[i] = element.estimateResults[i]['binding_price'];
        foldingPrices[i] = element.estimateResults[i]['folding_price'];
        digitalMachinePrices[i] = element.estimateResults[i]['digital_machine_price'];
        largeFormatMachinePrices[i] = element.estimateResults[i]['large_format_machine_price'];
        substratePrices[i] = element.estimateResults[i]['substrate_price'];
        laminatePrices[i] = element.estimateResults[i]['laminate_price'];
        accessoriesPrices[i] = element.estimateResults[i]['accessory_price'];
        scoringPrices[i] = element.estimateResults[i]['scoring_price'];
        dieCuttingPrices[i] = element.estimateResults[i]['die_cutting_price'];
        drillingPrices[i] = element.estimateResults[i]['drilling_price'];
        paddingPrices[i] = element.estimateResults[i]['padding_price'];
        bandingPrices[i] = element.estimateResults[i]['banding_price'];
        wrappingPrices[i] = element.estimateResults[i]['wrapping_price'];
        shrinkWrappingPrices[i] = element.estimateResults[i]['shrink_wrapping_price'];
        packagePrices[i] = element.estimateResults[i]['package_price'];
        handGatheringPrices[i] = element.estimateResults[i]['hand_gathering_price'];
        countingPrices[i] = element.estimateResults[i]['counting_price'];
    }

    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: {
            element, estimate_results, quantities, pressPrices, paperPrices, inkPrices, cuttingPrices, bindingPrices,
            foldingPrices, digitalMachinePrices, largeFormatMachinePrices, substratePrices, laminatePrices,
            accessoriesPrices, scoringPrices, dieCuttingPrices, drillingPrices, paddingPrices,
            bandingPrices, wrappingPrices, shrinkWrappingPrices, packagePrices, handGatheringPrices, countingPrices
        }
    };
}

export function setCustomerId(id) {
    return {
        type: actionTypes.SET_CUSTOMER_ID,
        payload: id
    };
}

export function setQuoteId(id) {
    return {
        type: actionTypes.SET_QUOTE_ID,
        payload: id
    };
}

export const fetchQuote = (id) => dispatch => {
    let fields = [
        '*',
        'estimates.id',
        'estimates.type',
        'estimates.front_inks',
        'estimates.back_inks',
        'estimates.interior_inks',
        'estimates.multi_page_type',
        'estimates.product_category_name',
        'estimates.selected_quantity',
        'estimates.presses_names',
        'estimates.digital_machine_name',
        'estimates.large_format_machine_name',
        'estimates.substrate_name',
        'estimates.laminate_name',
        'estimates.paper_names',
        'estimates.folding_machine_name',
        'estimates.accessories_names',
        'estimates.binding_machine_name',
        'estimates.data_processing_elements_ids',
        'estimates.inkjet_elements_ids',
        'estimates.inserting_elements_ids',
        'estimates.perfect_match_elements_ids',
        'estimates.wafer_seal_elements_ids',
        'estimates.affix_elements_ids',
        'estimates.cert_mail_elements_ids',
        'estimates.art_elements_ids',
        'estimates.handwork_elements_ids',
        'estimates.pressure_seal_elements_ids',
        'estimates.index_tab_elements_ids',
        'estimates.label_elements_ids',
        'estimates.ncr_form_elements_ids',
        'estimates.estimateResults.quantity',
        'estimates.estimateResults.tax',
        'estimates.estimateResults.rush_fee',
        'estimates.estimateResults.subtotal',
        'estimates.estimateResults.customer_discount',
        'estimates.estimateResults.quote_discount',
        'estimates.estimateResults.discount',
        'estimates.estimateResults.total',
        'estimates.product_description',
        'estimates.digital_sides',
        'estimates.customer_discount_id',
        'estimates.discount',
        'estimates.discount_type',
        'estimates.discount_percentage'
    ];
    let extraFields = ['estimates', 'estimates.estimateResults'];

    dispatch({ type: actionTypes.FETCH_QUOTE, payload: null });
    axios.get(apiUrls.elements + '/' + id + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_QUOTE_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_QUOTE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchInformation = customerId => dispatch => {
    let customerParam = '';
    if (customerId !== null && customerId !== '') {
        customerParam = '&customer_id=' + customerId;
    }

    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information + '?is_product_window=1' + customerParam,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
            dispatch(updateState({
                options: {
                    foldTypes: loadOptions(response.data.foldTypes),
                    bandingTypes: loadOptions(response.data.bandingTypes),
                    wrappingTypes: loadOptions(response.data.wrappingTypes),
                    shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes),
                    productCategories: loadProductCategoriesOptions(response.data.productCategories),
                    digitalMachines: loadOptions(response.data.digitalMachines),
                    inks: loadOptions(response.data.inks),
                    packages: loadPackagesOptions(response.data.packages),
                    customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts)
                }
            }));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_INFORMATION_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchEstimateInformation = type => dispatch => {
    dispatch({ type: actionTypes.FETCH_ESTIMATE_INFORMATION, payload: null });
    axios.get(apiUrls.information + '?type=' + type,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ESTIMATE_INFORMATION_SUCCESS, payload: { data: response.data, type } });
            if (parseInt(type) === 1) {  // Flat
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        foldTypes: loadOptions(response.data.foldTypes, true),
                        bandingTypes: loadOptions(response.data.bandingTypes, true),
                        wrappingTypes: loadOptions(response.data.wrappingTypes, true),
                        shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes, true),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        inks: loadOptions(response.data.inks, true),
                        packages: loadPackagesOptions(response.data.packages)
                    }
                }));
                dispatch(intersectPresses());
            } else if (parseInt(type) === 2) {   // Multi-Page
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        bandingTypes: loadOptions(response.data.bandingTypes, true),
                        wrappingTypes: loadOptions(response.data.wrappingTypes, true),
                        shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes, true),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        inks: loadOptions(response.data.inks, true),
                        packages: loadPackagesOptions(response.data.packages)
                    }
                }));
                dispatch(intersectPresses());
            } else if (parseInt(type) === 3) {   // Digital
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        foldTypes: loadOptions(response.data.foldTypes, true),
                        bandingTypes: loadOptions(response.data.bandingTypes, true),
                        wrappingTypes: loadOptions(response.data.wrappingTypes, true),
                        shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes, true),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        digitalMachines: loadOptions(response.data.digitalMachines),
                        inks: loadOptions(response.data.inks, true),
                        packages: loadPackagesOptions(response.data.packages)
                    }
                }));
            } else if (parseInt(type) === 4) {   // Large Format
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories)
                    }
                }));
            }
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ESTIMATE_INFORMATION_FAILURE));
        });
}

export const estimate = values => dispatch => {
    let errors = {};
    let errorMessages = {};
    if (values['estimate_product_description'] === '' || values['estimate_product_description'] === null) {
        errors['estimate_product_description'] = true;
    }
    if (values['estimate_width'] === '' || values['estimate_width'] === null) {
        errors['estimate_width'] = true;
    }
    if (values['estimate_height'] === '' || values['estimate_height'] === null) {
        errors['estimate_height'] = true;
    }
    if (values['estimate_product_category_id'] === '' || values['estimate_product_category_id'] === null) {
        errors['estimate_product_category_id'] = true;
    }
    if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2) {
        if (values['estimate_press_id'] === '' || values['estimate_press_id'] === null) {
            errors['estimate_press_id'] = true;
        }
        if (values['estimate_running_style'] === '' || values['estimate_running_style'] === null) {
            errors['estimate_running_style'] = true;
        }
    }
    if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2 || parseInt(values['estimate_type']) === 3) {
        if (values['estimate_paper_category_id'] === '' || values['estimate_paper_category_id'] === null) {
            errors['estimate_paper_category_id'] = true;
        }
        if (values['estimate_paper_weight'] === '' || values['estimate_paper_weight'] === null) {
            errors['estimate_paper_weight'] = true;
        }
        if (values['estimate_paper_id'] === '' || values['estimate_paper_id'] === null) {
            errors['estimate_paper_id'] = true;
        }
    }
    if (parseInt(values['estimate_type']) === 2) {
        if (values['estimate_multi_page_type'] === '' || values['estimate_multi_page_type'] === null) {
            errors['estimate_multi_page_type'] = true;
        }
        if (values['estimate_pages'] === '' || values['estimate_pages'] === null) {
            errors['estimate_pages'] = true;
        }
        if ((parseInt(values['estimate_multi_page_type']) === 1 || parseInt(values['estimate_multi_page_type']) === 2)
            && parseInt(values['estimate_pages']) % 4 !== 0) {
            errors['estimate_pages'] = true;
            errorMessages['estimate_pages'] = 'The pages must be divisible by 4';
        }
        if (values['estimate_second_press_id'] === '' || values['estimate_second_press_id'] === null) {
            errors['estimate_second_press_id'] = true;
        }
        if (values['estimate_second_running_style'] === '' || values['estimate_second_running_style'] === null) {
            errors['estimate_second_running_style'] = true;
        }
        if ([2, 3, 6, 7].includes(parseInt(values['estimate_multi_page_type']))) {
            if (values['estimate_cover_paper_category_id'] === '' || values['estimate_cover_paper_category_id'] === null) {
                errors['estimate_cover_paper_category_id'] = true;
            }
            if (values['estimate_cover_paper_weight'] === '' || values['estimate_cover_paper_weight'] === null) {
                errors['estimate_cover_paper_weight'] = true;
            }
            if (values['estimate_cover_paper_id'] === '' || values['estimate_cover_paper_id'] === null) {
                errors['estimate_cover_paper_id'] = true;
            }
            if (values['estimate_cover_press_id'] === '' || values['estimate_cover_press_id'] === null) {
                errors['estimate_cover_press_id'] = true;
            }
            if (values['estimate_cover_running_style'] === '' || values['estimate_cover_running_style'] === null) {
                errors['estimate_cover_running_style'] = true;
            }
        }
    } else if (parseInt(values['estimate_type']) === 3) {
        if (values['estimate_digital_machine_id'] === '' || values['estimate_digital_machine_id'] === null) {
            errors['estimate_digital_machine_id'] = true;
        }
    } else if (parseInt(values['estimate_type']) === 4) {
        if (values['estimate_large_format_machine_id'] === '' || values['estimate_large_format_machine_id'] === null) {
            errors['estimate_large_format_machine_id'] = true;
        }
        if (values['estimate_substrate_id'] === '' || values['estimate_substrate_id'] === null) {
            errors['estimate_substrate_id'] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ESTIMATE, payload: null });
    axios.post(apiUrls.estimate, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ESTIMATE_SUCCESS, payload: response.data });
            if (response.data.length > 0) {
                showNotification('Results obtained', 'Results calculated', 'success');
            } else {
                showNotification('No results obtained', 'There are no results', 'success');
            }
            dispatch(setPrices(response.data, values));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function hasCover(values) {
    return parseInt(values['estimate_multi_page_type']) === 2 || parseInt(values['estimate_multi_page_type']) === 3
        || parseInt(values['estimate_multi_page_type']) === 6 || parseInt(values['estimate_multi_page_type']) === 7;
}

export function setPrices(results, values) {
    let pressPrice = [0, 0, 0];
    let paperPrice = [0, 0, 0];
    let inkPrice = [0, 0, 0];
    let cuttingPrice = [0, 0, 0];
    let bindingPrice = [0, 0, 0];
    let foldingPrice = [0, 0, 0];
    let digitalMachinePrice = [0, 0, 0];
    let largeFormatMachinePrice = [0, 0, 0];
    let substratePrice = [0, 0, 0];
    let laminatePrice = [0, 0, 0];
    let accessoryPrice = [0, 0, 0];
    let scoringPrices = [0, 0, 0];
    let dieCuttingPrices = [0, 0, 0];
    let drillingPrices = [0, 0, 0];
    let paddingPrices = [0, 0, 0];
    let bandingPrices = [0, 0, 0];
    let wrappingPrices = [0, 0, 0];
    let shrinkWrappingPrices = [0, 0, 0];
    let packagePrices = [0, 0, 0];
    let handGatheringPrices = [0, 0, 0];
    let countingPrices = [0, 0, 0];
    if (results.length === 3) {
        for (let i = 0; i < 3; i++) {
            if (results[i]['results'].length > 0) {
                let result1 = results[i]['results'][0];
                if (parseInt(values['estimate_type']) === 1) {
                    pressPrice[i] = numeral(result1['press_information']['total_cost']
                        * result1['press_information']['markup_multiplier']).format('0.00');
                    paperPrice[i] = numeral(result1['paper_information']['total_cost']
                        * result1['paper_information']['markup_multiplier']).format('0.00');
                    inkPrice[i] = numeral(result1['ink_information']['total_cost']
                        * result1['ink_information']['markup_multiplier']).format('0.00');
                    cuttingPrice[i] = numeral(result1['cutting_information']['total_cost']
                        * result1['cutting_information']['markup_multiplier']).format('0.00');

                    if (result1['folding_information']['id'] != null) {
                        foldingPrice[i] = numeral(result1['folding_information']['total_cost']
                            * result1['folding_information']['markup_multiplier']).format('0.00');
                    }
                } else if (parseInt(values['estimate_type']) === 2) {
                    let pressCost = 0;
                    let paperCost = 0;
                    let inkCost = 0;
                    let cuttingCost = 0;
                    let foldingCost = 0;
                    for (let j = 0; j < result1['impositions_information'].length; j++) {
                        pressCost += result1['impositions_information'][j]['press_information']['total_cost']
                            * result1['impositions_information'][j]['press_information']['markup_multiplier'];
                        paperCost += result1['impositions_information'][j]['paper_information']['total_cost']
                            * result1['impositions_information'][j]['paper_information']['markup_multiplier'];
                        inkCost += result1['impositions_information'][j]['ink_information']['total_cost']
                            * result1['impositions_information'][j]['ink_information']['markup_multiplier'];
                        cuttingCost += result1['impositions_information'][j]['cutting_information']['total_cost']
                            * result1['impositions_information'][j]['cutting_information']['markup_multiplier'];
                        foldingCost += result1['impositions_information'][j]['folding_information']['total_cost']
                            * result1['impositions_information'][j]['folding_information']['markup_multiplier'];
                    }

                    if (hasCover(values)) {
                        pressCost += results[i]['cover_results'][0]['press_information']['total_cost']
                            * results[i]['cover_results'][0]['press_information']['markup_multiplier'];
                        paperCost += results[i]['cover_results'][0]['paper_information']['total_cost']
                            * results[i]['cover_results'][0]['paper_information']['markup_multiplier'];
                        inkCost += results[i]['cover_results'][0]['ink_information']['total_cost']
                            * results[i]['cover_results'][0]['ink_information']['markup_multiplier'];
                        cuttingCost += results[i]['cover_results'][0]['cutting_information']['total_cost']
                            * results[i]['cover_results'][0]['cutting_information']['markup_multiplier'];
                        foldingCost += results[i]['cover_results'][0]['folding_information']['total_cost']
                            * results[i]['cover_results'][0]['folding_information']['markup_multiplier'];
                    }

                    pressPrice[i] = numeral(pressCost).format('0.00');
                    paperPrice[i] = numeral(paperCost).format('0.00');
                    inkPrice[i] = numeral(inkCost).format('0.00');
                    cuttingPrice[i] = numeral(cuttingCost).format('0.00');
                    bindingPrice[i] = numeral(result1['binding_information']['total_cost']).format('0.00');
                    foldingPrice[i] = numeral(foldingCost).format('0.00');
                } else if (parseInt(values['estimate_type']) === 3) {
                    digitalMachinePrice[i] = numeral(result1['digital_machine_information']['total_cost']
                        * result1['digital_machine_information']['markup_multiplier']).format('0.00');
                    paperPrice[i] = numeral(result1['paper_information']['total_cost']
                        * result1['paper_information']['markup_multiplier']).format('0.00');
                    inkPrice[i] = numeral(result1['ink_information']['total_cost']
                        * result1['ink_information']['markup_multiplier']).format('0.00');
                    cuttingPrice[i] = numeral(result1['cutting_information']['total_cost']
                        * result1['cutting_information']['markup_multiplier']).format('0.00');

                    if (result1['folding_information']['id'] != null) {
                        foldingPrice[i] = numeral(result1['folding_information']['total_cost']
                            * result1['folding_information']['markup_multiplier']).format('0.00');
                    }
                } else if (parseInt(values['estimate_type']) === 4) {
                    largeFormatMachinePrice[i] = numeral(result1['large_format_machine_information']['total_cost']
                        * result1['large_format_machine_information']['markup_multiplier']).format('0.00');
                    substratePrice[i] = numeral(result1['substrate_information']['total_cost']
                        * result1['substrate_information']['markup_multiplier']).format('0.00');

                    if (result1['laminate_information'] != null) {
                        laminatePrice[i] = numeral(result1['laminate_information']['total_cost']
                            * result1['laminate_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['accessories_information'] != null && result1['accessories_information']['accessories'].length > 0) {
                        let totalAccessoriesCost = 0;
                        for (let j = 0; j < result1['accessories_information']['accessories'].length; j++) {
                            totalAccessoriesCost += parseFloat(result1['accessories_information']['accessories'][j]['total_cost'])
                                * parseFloat(result1['accessories_information']['accessories'][j]['markup_multiplier']);
                        }
                        accessoryPrice[i] = numeral(totalAccessoriesCost).format('0.00');
                    }
                }

                if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2 || parseInt(values['estimate_type']) === 3) {
                    if (result1['scoring_information'] && result1['scoring_information']['id'] != null) {
                        scoringPrices[i] = numeral(result1['scoring_information']['total_cost']
                            * result1['scoring_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['die_cutter_information'] && result1['die_cutter_information']['id'] != null) {
                        dieCuttingPrices[i] = numeral(result1['die_cutter_information']['total_cost']
                            * result1['die_cutter_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['drilling_information'] && result1['drilling_information']['id'] != null) {
                        drillingPrices[i] = numeral(result1['drilling_information']['total_cost']
                            * result1['drilling_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['padding_information'] && result1['padding_information']['id'] != null) {
                        paddingPrices[i] = numeral(result1['padding_information']['total_cost']
                            * result1['padding_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['banding_information'] && result1['banding_information']['id'] != null) {
                        bandingPrices[i] = numeral(result1['banding_information']['total_cost']
                            * result1['banding_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['wrapping_information'] && result1['wrapping_information']['id'] != null) {
                        wrappingPrices[i] = numeral(result1['wrapping_information']['total_cost']
                            * result1['wrapping_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['shrink_wrapping_information'] && result1['shrink_wrapping_information']['id'] != null) {
                        shrinkWrappingPrices[i] = numeral(result1['shrink_wrapping_information']['total_cost']
                            * result1['shrink_wrapping_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['package_information'] && result1['package_information']['id'] != null) {
                        packagePrices[i] = numeral(result1['package_information']['total_cost']
                            * result1['package_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['hand_gathering_information'] && result1['hand_gathering_information']['id'] != null) {
                        handGatheringPrices[i] = numeral(result1['hand_gathering_information']['total_cost']
                            * result1['hand_gathering_information']['markup_multiplier']).format('0.00');
                    }

                    if (result1['counting_information'] && result1['counting_information']['id'] != null) {
                        countingPrices[i] = numeral(result1['counting_information']['total_cost']
                            * result1['counting_information']['markup_multiplier']).format('0.00');
                    }
                }
            }
        }
    }

    return {
        type: actionTypes.SET_PRICES,
        payload: {
            estimate_press_price_1: pressPrice[0],  // TODO: Optimize
            estimate_press_price_2: pressPrice[1],
            estimate_press_price_3: pressPrice[2],
            estimate_paper_price_1: paperPrice[0],
            estimate_paper_price_2: paperPrice[1],
            estimate_paper_price_3: paperPrice[2],
            estimate_ink_price_1: inkPrice[0],
            estimate_ink_price_2: inkPrice[1],
            estimate_ink_price_3: inkPrice[2],
            estimate_cutting_price_1: cuttingPrice[0],
            estimate_cutting_price_2: cuttingPrice[1],
            estimate_cutting_price_3: cuttingPrice[2],
            estimate_binding_price_1: bindingPrice[0],
            estimate_binding_price_2: bindingPrice[1],
            estimate_binding_price_3: bindingPrice[2],
            estimate_folding_price_1: foldingPrice[0],
            estimate_folding_price_2: foldingPrice[1],
            estimate_folding_price_3: foldingPrice[2],
            estimate_digital_machine_price_1: digitalMachinePrice[0],
            estimate_digital_machine_price_2: digitalMachinePrice[1],
            estimate_digital_machine_price_3: digitalMachinePrice[2],
            estimate_large_format_machine_price_1: largeFormatMachinePrice[0],
            estimate_large_format_machine_price_2: largeFormatMachinePrice[1],
            estimate_large_format_machine_price_3: largeFormatMachinePrice[2],
            estimate_substrate_price_1: substratePrice[0],
            estimate_substrate_price_2: substratePrice[1],
            estimate_substrate_price_3: substratePrice[2],
            estimate_laminate_price_1: laminatePrice[0],
            estimate_laminate_price_2: laminatePrice[1],
            estimate_laminate_price_3: laminatePrice[2],
            estimate_accessories_price_1: accessoryPrice[0],
            estimate_accessories_price_2: accessoryPrice[1],
            estimate_accessories_price_3: accessoryPrice[2],
            estimate_scoring_price_1: scoringPrices[0],
            estimate_scoring_price_2: scoringPrices[1],
            estimate_scoring_price_3: scoringPrices[2],
            estimate_die_cutting_price_1: dieCuttingPrices[0],
            estimate_die_cutting_price_2: dieCuttingPrices[1],
            estimate_die_cutting_price_3: dieCuttingPrices[2],
            estimate_drilling_price_1: drillingPrices[0],
            estimate_drilling_price_2: drillingPrices[1],
            estimate_drilling_price_3: drillingPrices[2],
            estimate_padding_price_1: paddingPrices[0],
            estimate_padding_price_2: paddingPrices[1],
            estimate_padding_price_3: paddingPrices[2],
            estimate_banding_price_1: bandingPrices[0],
            estimate_banding_price_2: bandingPrices[1],
            estimate_banding_price_3: bandingPrices[2],
            estimate_wrapping_price_1: wrappingPrices[0],
            estimate_wrapping_price_2: wrappingPrices[1],
            estimate_wrapping_price_3: wrappingPrices[2],
            estimate_shrink_wrapping_price_1: shrinkWrappingPrices[0],
            estimate_shrink_wrapping_price_2: shrinkWrappingPrices[1],
            estimate_shrink_wrapping_price_3: shrinkWrappingPrices[2],
            estimate_package_price_1: packagePrices[0],
            estimate_package_price_2: packagePrices[1],
            estimate_package_price_3: packagePrices[2],
            estimate_hand_gathering_price_1: handGatheringPrices[0],
            estimate_hand_gathering_price_2: handGatheringPrices[1],
            estimate_hand_gathering_price_3: handGatheringPrices[2],
            estimate_counting_price_1: countingPrices[0],
            estimate_counting_price_2: countingPrices[1],
            estimate_counting_price_3: countingPrices[2]
        }
    };
}

export const saveEstimate = (values, results, estimates, quoteId) => dispatch => {
    let errors = {};
    let errorMessages = {};

    if ([1, 2, 3, 4].includes(parseInt(values['estimate_type']))) {
        if (results.length < 3) {
            Notification('No Results', 'There are not results to save', 'info');
            return;
        }

        if (values['estimate_product_category_id'] === '' || values['estimate_product_category_id'] === null) {
            errors['estimate_product_category_id'] = true;
        }
        if (values['estimate_product_description'] === '' || values['estimate_product_description'] === null) {
            errors['estimate_product_description'] = true;
        }
        if (values['estimate_width'] === '' || values['estimate_width'] === null) {
            errors['estimate_width'] = true;
        }
        if (values['estimate_height'] === '' || values['estimate_height'] === null) {
            errors['estimate_height'] = true;
        }
        if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2) {
            if (values['estimate_press_id'] === '' || values['estimate_press_id'] === null) {
                errors['estimate_press_id'] = true;
            }
            if (values['estimate_running_style'] === '' || values['estimate_running_style'] === null) {
                errors['estimate_running_style'] = true;
            }
        }
        if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2 || parseInt(values['estimate_type']) === 3) {
            if (values['estimate_paper_category_id'] === '' || values['estimate_paper_category_id'] === null) {
                errors['estimate_paper_category_id'] = true;
            }
            if (values['estimate_paper_weight'] === '' || values['estimate_paper_weight'] === null) {
                errors['estimate_paper_weight'] = true;
            }
            if (values['estimate_paper_id'] === '' || values['estimate_paper_id'] === null) {
                errors['estimate_paper_id'] = true;
            }
        }
        if (parseInt(values['estimate_type']) === 2) {
            if (values['estimate_multi_page_type'] === '' || values['estimate_multi_page_type'] === null) {
                errors['estimate_multi_page_type'] = true;
            }
            if (values['estimate_pages'] === '' || values['estimate_pages'] === null) {
                errors['estimate_pages'] = true;
            }
            if (values['estimate_second_press_id'] === '' || values['estimate_second_press_id'] === null) {
                errors['estimate_second_press_id'] = true;
            }
            if (values['estimate_second_running_style'] === '' || values['estimate_second_running_style'] === null) {
                errors['estimate_second_running_style'] = true;
            }
            if ([2, 3, 6, 7].includes(parseInt(values['estimate_multi_page_type']))) {
                if (values['estimate_cover_paper_category_id'] === '' || values['estimate_cover_paper_category_id'] === null) {
                    errors['estimate_cover_paper_category_id'] = true;
                }
                if (values['estimate_cover_paper_weight'] === '' || values['estimate_cover_paper_weight'] === null) {
                    errors['estimate_cover_paper_weight'] = true;
                }
                if (values['estimate_cover_paper_id'] === '' || values['estimate_cover_paper_id'] === null) {
                    errors['estimate_cover_paper_id'] = true;
                }
                if (values['estimate_cover_press_id'] === '' || values['estimate_cover_press_id'] === null) {
                    errors['estimate_cover_press_id'] = true;
                }
                if (values['estimate_cover_running_style'] === '' || values['estimate_cover_running_style'] === null) {
                    errors['estimate_cover_running_style'] = true;
                }
            }
        } else if (parseInt(values['estimate_type']) === 3) {
            if (values['estimate_digital_machine_id'] === '' || values['estimate_digital_machine_id'] === null) {
                errors['estimate_digital_machine_id'] = true;
            }
        } else if (parseInt(values['estimate_type']) === 4) {
            if (values['estimate_large_format_machine_id'] === '' || values['estimate_large_format_machine_id'] === null) {
                errors['estimate_large_format_machine_id'] = true;
            }
            if (values['estimate_substrate_id'] === '' || values['estimate_substrate_id'] === null) {
                errors['estimate_substrate_id'] = true;
            }
        }
    } else {
        if (values['estimate_product_category_id'] === '' || values['estimate_product_category_id'] === null) {
            errors['estimate_product_category_id'] = true;
        }
        if (values['estimate_product_description'] === '' || values['estimate_product_description'] === null) {
            errors['estimate_product_description'] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let fields = [
        'id',
        'type',
        'front_inks',
        'back_inks',
        'interior_inks',
        'multi_page_type',
        'product_category_name',
        'selected_quantity',
        'presses_names',
        'digital_machine_name',
        'large_format_machine_name',
        'substrate_name',
        'laminate_name',
        'paper_names',
        'folding_machine_name',
        'accessories_names',
        'binding_machine_name',
        'data_processing_elements_ids',
        'inkjet_elements_ids',
        'inserting_elements_ids',
        'perfect_match_elements_ids',
        'wafer_seal_elements_ids',
        'affix_elements_ids',
        'cert_mail_elements_ids',
        'art_elements_ids',
        'handwork_elements_ids',
        'pressure_seal_elements_ids',
        'index_tab_elements_ids',
        'label_elements_ids',
        'ncr_form_elements_ids',
        'estimateResults.quantity',
        'estimateResults.tax',
        'estimateResults.rush_fee',
        'estimateResults.subtotal',
        'estimateResults.customer_discount',
        'estimateResults.quote_discount',
        'estimateResults.discount',
        'estimateResults.total',
        'product_description',
        'digital_sides'
    ];
    let extraFields = ['estimateResults'];

    let method = 'POST';
    let endpoint = apiUrls.estimates + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(',');
    if (values['estimate_id'] !== '') {
        method = 'PUT';
        endpoint = apiUrls.estimates + '/' + values['estimate_id'] + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(',');
    }

    dispatch({ type: actionTypes.SAVE_ESTIMATE, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: { values, results, quoteId },
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ESTIMATE_SUCCESS, payload: response.data });
            let newElements = processNewData(estimates, response.data, values['estimate_id'] === '' ? 1 : 2);
            dispatch(setEstimates(newElements));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Estimate Saved!', 'The estimate has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setEstimates(estimates) {
    return {
        type: actionTypes.SET_ESTIMATES,
        payload: estimates
    };
}

export const deleteEstimate = (id, estimates) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ESTIMATE, payload: null });
    axios.delete(apiUrls.elements + '/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ESTIMATE_SUCCESS, payload: response.data });
            let newElements = processNewData(estimates, id, 3);
            dispatch(setEstimates(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Estimate Deleted!', 'The estimate has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const convertToQuote = (id, estimates) => (dispatch, getState) => {
    const information = selectors.getInformation(getState());
    const formValues = selectors.getFormValues(getState());
    const productWindowId = selectors.getProductWindowId(getState());
    const productCustomerId = selectors.getProductCustomerId(getState());

    let errors = {};
    let errorMessages = {};
    if (formValues['name'] === '' || formValues['name'] === null) {
        errors['name'] = true;
    }
    if (formValues['notes'] === '' || formValues['notes'] === null) {
        errors['notes'] = true;
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let estimatesToSave = [];
    for (let i = 0; i < information['estimates'].length; i++) {
        if (!information['estimates'][i]['selected_quantity']) {
            Notification('Select Quantities', 'Select all of the item quantities', 'info');
            return;
        }

        estimatesToSave.push({ id: information['estimates'][i]['id'], selectedQuantity: information['estimates'][i]['selected_quantity'] });
    }

    dispatch({ type: actionTypes.CONVERT_QUOTE, payload: null });
    axios.post(apiUrls.convertToQuote,
        {
            name: formValues['name'],
            notes: formValues['notes'],
            estimates: estimatesToSave,
            productCustomerId
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.CONVERT_QUOTE_SUCCESS, payload: response.data });
            showNotification('Quote Created!', 'Quote created successfully! We will contact you soon!', 'success');
            dispatch(dialog.actions.hideDialog('quote-information'));
            dispatch(resetState());
            dispatch(setProductWindowId(productWindowId));
            dispatch(setProductCustomerId(productCustomerId));
            dispatch(fetchInformation(productCustomerId));
            dispatch(fetchProductWindow(productWindowId));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.CONVERT_QUOTE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setItemQuantity(estimates, index, quantity) {
    estimates[index]['selected_quantity'] = quantity;
    return {
        type: actionTypes.SET_ITEM_QUANTITY,
        payload: estimates
    };
}

export function setEditingCustomer(editing) {
    return {
        type: actionTypes.SET_EDITING_CUSTOMER,
        payload: editing
    };
}

export function setProductWindowId(id) {
    return {
        type: actionTypes.SET_PRODUCT_WINDOW_ID,
        payload: id
    };
}

export function setProductCustomerId(id) {
    return {
        type: actionTypes.SET_PRODUCT_CUSTOMER_ID,
        payload: id
    };
}

export function clearResults(value) {
    return {
        type: actionTypes.CLEAR_RESULTS,
        payload: value
    };
}

export function setAccessories(accessories, id, checked) {
    if (checked) {
        accessories.push(id);
    } else {
        for (let i = 0; i < accessories.length; i++) {
            if (parseInt(accessories[i]) === parseInt(id)) {
                accessories.splice(i, 1);
                break;
            }
        }
    }

    return {
        type: actionTypes.SET_ACCESSORIES,
        payload: accessories
    };
}

export function setOptions(name, data) {
    return {
        type: actionTypes.SET_OPTIONS,
        payload: { name, data }
    };
}

export function loadOptions(data) {
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['name'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadCustomerDiscountOptions(data) {
    let options = [{ label: 'None', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['long_name'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadPackagesOptions(data) {
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['width'] + 'x' + data[i]['length'] + 'x' + data[i]['height'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadProductCategoriesOptions(data) {
    let options = [{ label: 'Select option', value: '', type: 0 }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['name'],
            value: data[i]['id'],
            type: data[i]['type'],
            element: data[i]
        });
    }
    return options;
}

export function loadPaperCategoriesOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i]['category_name'],
                value: data[i]['id'],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPaperCategories' : 'paperCategories', options));
    };
}

export function loadPaperWeightsOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i] + '#',
                value: data[i],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPaperWeights' : 'paperWeights', options));
    };
}

export function loadPapersOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '', coating_one_side: 0 }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i]['paper_name'] + ' ' + data[i]['width'] + 'x' + data[i]['length'],
                value: data[i]['id'],
                coating_one_side: data[i]['coating_one_side'],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPapers' : 'papers', options));
    };
}

export function intersectPresses() {
    return (dispatch, getState) => {
        let information = selectors.getInformation(getState());
        let activeProduct = selectors.getActiveProduct(getState());
        let presses = information['presses'];

        let pressesList = [];
        if (activeProduct == null) {
            pressesList = presses;
        } else {
            for (let i = 0; i < activeProduct.activePresses.length; i++) {
                for (let j = 0; j < presses.length; j++) {
                    if (parseInt(activeProduct.activePresses[i]['id']) === parseInt(presses[j]['id'])) {
                        pressesList.push(presses[j]);
                    }
                }
            }
        }

        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < pressesList.length; i++) {
            options.push({ label: pressesList[i]['name'], value: pressesList[i]['id'] });
        }

        dispatch(setOptions('presses', options));
    };
}

export const intersectPaperCategories = (formValues, isCover = false) => dispatch => {
    dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES, payload: null });
    axios.post(apiUrls.intersectPaperCategories,
        {
            isCover: isCover ? 1 : 0,
            type: formValues['estimate_type'],
            pressId: isCover ? formValues['estimate_cover_press_id'] : formValues['estimate_press_id'],
            secondPressId: isCover ? null : formValues['estimate_second_press_id'],
            digitalMachineId: formValues['estimate_digital_machine_id'],
            productId: formValues['estimate_product_id']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES_SUCCESS, payload: response.data });
            dispatch(setLoading(isCover ? 'coverPaperCategories' : 'paperCategories', false));
            dispatch(loadPaperCategoriesOptions(response.data, isCover));
        })
        .catch(error => {
            dispatch(setLoading(isCover ? 'coverPaperCategories' : 'paperCategories', false));
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const intersectPaperWeights = (formValues, isCover = false) => dispatch => {
    dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS, payload: null });
    axios.post(apiUrls.intersectPaperWeights,
        {
            isCover: isCover ? 1 : 0,
            type: formValues['estimate_type'],
            pressId: isCover ? formValues['estimate_cover_press_id'] : formValues['estimate_press_id'],
            secondPressId: isCover ? null : formValues['estimate_second_press_id'],
            digitalMachineId: formValues['estimate_digital_machine_id'],
            productId: formValues['estimate_product_id'],
            paperCategoryId: isCover ? formValues['estimate_cover_paper_category_id'] : formValues['estimate_paper_category_id']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS_SUCCESS, payload: response.data });
            dispatch(setLoading(isCover ? 'coverPaperWeights' : 'paperWeights', false));
            dispatch(loadPaperWeightsOptions(response.data, isCover));
        })
        .catch(error => {
            dispatch(setLoading(isCover ? 'coverPaperWeights' : 'paperWeights', false));
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const intersectPapers = (formValues, isCover = false) => dispatch => {
    dispatch({ type: actionTypes.INTERSECT_PAPERS, payload: null });
    axios.post(apiUrls.intersectPapers,
        {
            isCover: isCover ? 1 : 0,
            type: formValues['estimate_type'],
            pressId: isCover ? formValues['estimate_cover_press_id'] : formValues['estimate_press_id'],
            secondPressId: isCover ? null : formValues['estimate_second_press_id'],
            digitalMachineId: formValues['estimate_digital_machine_id'],
            productId: formValues['estimate_product_id'],
            paperCategoryId: isCover ? formValues['estimate_cover_paper_category_id'] : formValues['estimate_paper_category_id'],
            paperWeight: isCover ? formValues['estimate_cover_paper_weight'] : formValues['estimate_paper_weight']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPERS_SUCCESS, payload: response.data });
            dispatch(setLoading(isCover ? 'coverPapers' : 'papers', false));
            dispatch(loadPapersOptions(response.data, isCover));
        })
        .catch(error => {
            dispatch(setLoading(isCover ? 'coverPapers' : 'papers', false));
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPERS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function setLoading(name, value) {
    return {
        type: actionTypes.SET_LOADING,
        payload: { name, value }
    };
}

export function resetOptions(name) {
    return {
        type: actionTypes.RESET_OPTIONS,
        payload: name
    };
}

export function updateState(values) {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: values
    };
}

export function checkAccessory(id) {
    return {
        type: actionTypes.CHECK_ACCESSORY,
        payload: id
    };
}

export const fetchEstimate = id => (dispatch, getState) => {
    let formValues = selectors.getFormValues(getState());
    let extraFields = ['estimateResults'];

    dispatch({ type: actionTypes.FETCH_ESTIMATE, payload: null });
    axios.get(apiUrls.estimates + '/' + id + '?expand=' + extraFields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ESTIMATE_SUCCESS, payload: response.data });
            dispatch(fetchEstimateInformation(response.data.type));

            let fetching = {};
            let options = {};
            let newState = {};

            fetching.estimate = false;
            dispatch(setActiveElement(response.data));

            if ([1, 2, 3].includes(response.data.type)) {
                formValues = selectors.getFormValues(getState());
                dispatch(intersectPresses());
                dispatch(intersectPaperCategories(formValues));
                dispatch(intersectPaperWeights(formValues));
                dispatch(intersectPapers(formValues));
                fetching.paperCategories = true;
                fetching.paperWeights = true;
                fetching.papers = true;
                options.paperCategories = [];
                options.paperWeights = [];
                options.papers = [];

                if ([2, 3, 6, 7].includes(response.data['multi_page_type'])) {
                    dispatch(intersectPaperCategories(formValues, true));
                    dispatch(intersectPaperWeights(formValues, true));
                    dispatch(intersectPapers(formValues, true));
                    fetching.coverPaperCategories = true;
                    fetching.coverPaperWeights = true;
                    fetching.coverPapers = true;
                    options.coverPaperCategories = [];
                    options.coverPaperWeights = [];
                    options.coverPapers = [];
                }
            }

            let keys = Object.keys(fetching);
            if (keys.length > 0) {
                newState.fetching = fetching;
            }

            keys = Object.keys(options);
            if (keys.length > 0) {
                newState.options = options;
            }

            dispatch(updateState(newState));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchProductWindow = id => dispatch => {
    let fields = [
        'id',
        'name',
        'configuration_json',
        'product_type'
    ];

    dispatch({ type: actionTypes.FETCH_PRODUCT_WINDOW, payload: null });
    axios.get(apiUrls.productWindows + '/' + id + '?fields=' + fields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PRODUCT_WINDOW_SUCCESS, payload: response.data });
            dispatch(resetEstimateForm());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_PRODUCT_WINDOW_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}
