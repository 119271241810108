import axios from 'axios';
import * as types from './Login.types';
import * as selectors from './Login.selectors';
import { showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const login = loginType => (dispatch, getState) => {
    const form = selectors.getForm(getState());
    if (form.email === '' || form.password === '') {
        showNotification('Complete the information', 'Complete all the required fields of the form', 'info');
        return;
    }

    dispatch({ type: types.LOGIN, payload: null });
    axios.post(apiUrl.replace('/v1', '') + '/oauth2/token',
        {
            grant_type: 'password',
            client_id: 'testclient',  // TODO: Add this to environment variables
            client_secret: 'testpass',  // TODO: Add this to environment variables
            username: form.email,
            password: form.password,
            loginType,
        })
        .then(response => {
            dispatch({
                type: types.LOGIN_SUCCESS,
                payload: response.data
            });
            dispatch(setTokens(response.data));
        })
        .catch(error => {
            dispatch({ type: types.LOGIN_ERROR, payload: null });
            showNotification('Incorrect Credentials', 'The email or password are incorrect', 'info');
        });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const setTokens = data => dispatch => {
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    dispatch({
        type: types.SET_TOKENS,
        payload: data
    });
}

export const clearTokens = () => dispatch => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    dispatch({
        type: types.CLEAR_TOKENS,
        payload: null
    });
}

export const setRedirect = (value) => dispatch => {
    dispatch({
        type: types.SET_REDIRECT,
        payload: value
    });
}

export const logout = () => (dispatch) => {
    dispatch(clearTokens());
}

export const restorePassword = () => (dispatch, getState) => {
    const form = selectors.getForm(getState());
    if (form.email === '') {
        showNotification('Complete the information', 'Enter your email to start the process of restore the password', 'info');
        return;
    }

    dispatch({ type: types.RESTORE_PASSWORD, payload: null });
    axios.post(apiUrl + '/user/restore-password', { email: form.email })
        .then(response => {
            showNotification('Instructions sent by email', 'An email has been sent to your email with instructions to restore your password', 'success');
        })
        .catch(error => {
            dispatch({ type: types.RESTORE_PASSWORD_ERROR, payload: null });
            showNotification('Error', 'An error has occurred', 'danger');
        });
}

export const getToken = () => {
    return dispatch => {
        let tokenPromise = new Promise((resolve, reject) => {
            dispatch(verifyToken(resolve));
        });
    
        return tokenPromise;
    };
}

export const verifyToken = (resolve) => {
    return dispatch => {
        resolve('Not expired');
    };
}