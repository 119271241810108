import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class MileageSchedule extends Component {
    static propTypes = {
        mileageSchedule: PropTypes.array,
        paperCategories: PropTypes.array,
        activeElement: PropTypes.object,
        hideMileageSchedule: PropTypes.func,
        handleUpdateMileageSchedule: PropTypes.func,
        isSavingMileageSchedule: PropTypes.bool,
        handleSaveMileageSchedule: PropTypes.func,
        handleAddMileageSchedule: PropTypes.func,
        handleDeleteMileageSchedule: PropTypes.func
    };

    renderData() {
        const { mileageSchedule, handleUpdateMileageSchedule, paperCategories } = this.props;
        return mileageSchedule.map((element, index) => {
            let categoryName = null;
            for (let i = 0; i < paperCategories.length; i++) {
                if (paperCategories[i]['id'] === element['paper_category_id']) {
                    categoryName = paperCategories[i]['category_name'];
                    break;
                }
            }

            if (categoryName == null) {
                return null;
            }

            return (
                <tr key={index}>
                    <td>{categoryName}</td>
                    <td>
                        <input type="text" value={element['square_inches_per_pound']}
                            onChange={handleUpdateMileageSchedule.bind(this, index, 'square_inches_per_pound')} />
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { hideMileageSchedule, handleSaveMileageSchedule, isSavingMileageSchedule } = this.props;

        let data = (
            <div>
                <table className="table table-sm table-striped data-table">
                    <thead>
                        <tr>
                            <th style={{ width: '50%' }}>Paper Category</th>
                            <th style={{ width: '50%' }}>Sq. Inches/Pound</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                </table>
            </div>
        );

        let saveButtonLabel = 'Save';
        if (isSavingMileageSchedule) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveMileageSchedule} disabled={isSavingMileageSchedule}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideMileageSchedule}>Close</button>
        ];
        return (
            <Dialog
                name="mileage-schedule"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default MileageSchedule;
