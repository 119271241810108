import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer, Line, Circle, Text } from 'react-konva';

class Table extends Component {
    static propTypes = {
        user: PropTypes.object,
        isFetchingInformation: PropTypes.bool,
        tags: PropTypes.array,
        points: PropTypes.array,
        anchors: PropTypes.array,
        distances: PropTypes.array,
        lines: PropTypes.array,
        showDistances: PropTypes.bool
    };

    render() {
        const { tags, anchors, distances, showDistances, lines } = this.props;

        //let scale = 19;     // Room 4
        let scale = 25;     // Room 5
        let translateX = 200;
        let translateY = 120;
        let textsElements = [];

        let tagsComponents = [];
        for (let i = 0; i < tags.length; i++) {
            //if(tags[i]['x'] != 0 && tags[i]['y'] != 0) {
            tagsComponents.push(<Circle x={tags[i]['x'] * scale + translateX} y={tags[i]['y'] * scale + translateY} radius={8} fill="red" />);
            textsElements.push(<Text x={(tags[i]['x'] - 0) * scale + translateX} y={(tags[i]['y'] + 0.4) * scale + translateY} text={tags[i]['name'] + '(' + tags[i]['z'] + ')'} fontSize={15} fill="red" />);
            //}
        }

        let anchorsComponents = [];
        let circles = [];
        for (let i = 0; i < anchors.length; i++) {
            let color = 'blue';
            if (i === 0) {
                color = 'green';
            } else if (i === 1) {
                color = 'pink';
            } else if (i === 2) {
                color = 'blue';
            } else if (i === 3) {
                color = 'orange';
            } else if (i === 4) {
                color = 'brown';
            } else if (i === 5) {
                color = 'purple';
            } else if (i === 6) {
                color = 'indigo';
            }

            anchorsComponents.push(<Circle x={anchors[i]['x'] * scale + translateX} y={anchors[i]['y'] * scale + translateY} radius={8} fill={color} />);
            textsElements.push(<Text x={(anchors[i]['x'] - 0) * scale + translateX} y={(anchors[i]['y'] + 0.4) * scale + translateY} text={anchors[i]['name']} fontSize={15} fill={color} />);
            for (let j = 0; showDistances === 1 && j < distances.length; j++) {
                if (parseInt(distances[j]['uwb_anchor_id']) === parseInt(anchors[i]['id'])) {
                    circles.push(<Circle x={anchors[i]['x'] * scale + translateX} y={anchors[i]['y'] * scale + translateY} radius={distances[j]['distance'] * scale} stroke={color} />);
                    break;
                }
            }
        }

        let linesComponents = [];
        for (let i = 0; i < lines.length; i++) {
            linesComponents.push(<Line x={0} y={0} points={[lines[i]['x1'] * scale + translateX, lines[i]['y1'] * scale + translateY,
            lines[i]['x2'] * scale + translateX, lines[i]['y2'] * scale + translateY]} stroke="black" />);
        }

        return (
            <div>
                <Stage width={window.innerWidth - 45} height={window.innerHeight - 180}>
                    <Layer>
                        {linesComponents}
                        {anchorsComponents}
                        {tagsComponents}
                        {circles}
                        {textsElements}
                    </Layer>
                </Stage>
            </div>
        );
    }
}

export default Table;
