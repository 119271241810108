import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class ActiveSubstrates extends Component {
    static propTypes = {
        activeSubstrates: PropTypes.array,
        hideActiveSubstrates: PropTypes.func,
        isSavingActiveSubstrates: PropTypes.bool,
        handleSaveActiveSubstrates: PropTypes.func,
        handleAddActiveSubstrate: PropTypes.func,
        handleDeleteActiveSubstrate: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        substrates: PropTypes.array,
        formValues: PropTypes.object,
        handleMultipleChange: PropTypes.object
    };

    renderActiveSubstrates() {
        const { activeSubstrates, handleDeleteActiveSubstrate } = this.props;

        let substrates = [];
        for (let i = 0; i < activeSubstrates.length; i++) {
            substrates.push({ id: activeSubstrates[i]['id'], name: activeSubstrates[i]['name'] });
        }

        substrates.sort(function (a, b) {
            return a['name'] < b['name'] ? -1 : 1;
        });

        return substrates.map((substrate, index) => {
            return (
                <div key={index} className="float-left mr-2 mt-1">
                    <span className="badge badge-info bold">
                        {substrate['name']} <i className="fas fa-times" onClick={handleDeleteActiveSubstrate.bind(this, substrate['id'])}></i>
                    </span>
                </div>
            );
        });
    }

    isSubstrateActive(substrateId) {
        const { activeSubstrates } = this.props;

        for (let i = 0; i < activeSubstrates.length; i++) {
            if (activeSubstrates[i]['id'] === substrateId) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { hideActiveSubstrates, handleSaveActiveSubstrates, isSavingActiveSubstrates, handleAddActiveSubstrate,
            isFetchingInformation, substrates, formValues, handleMultipleChange, isFetchingElement } = this.props;

        let substrateOptions = [];
        for (let i = 0; i < substrates.length; i++) {
            if (!this.isSubstrateActive(substrates[i]['id'])) {
                substrateOptions.push(<option value={substrates[i]['id']}>{substrates[i]['name']}</option>);
            }
        }

        let data = (
            <div className="row">
                <div className="col-5">
                    Substrate:
                    <select name="active_substrate_ids" multiple size={5} value={formValues['active_substrate_ids']}
                        onChange={handleMultipleChange.bind(this, 'active_substrate_ids')}>
                        {substrateOptions}
                    </select>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="add-button mt-2" onClick={handleAddActiveSubstrate} style={{ width: 100, fontSize: 13 }}><i className="fas fa-plus" /> Add</div>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="active-presses">
                        {this.renderActiveSubstrates()}
                    </div>
                </div>
            </div>
        );
        if (isFetchingElement) {
            data = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        if (isFetchingInformation) {
            data = null;
        }

        let saveButtonLabel = 'Save';
        if (isSavingActiveSubstrates) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveActiveSubstrates} disabled={isSavingActiveSubstrates}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideActiveSubstrates}>Close</button>
        ];
        return (
            <Dialog
                name="active-substrates"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default ActiveSubstrates;
