import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import { showNotification } from './../../../utils';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const DeleteModal = React.memo((props) => {
    const [customerId, setCustomerId] = useState('');
    const [terms, setTerms] = useState('');
    const [salesRepId, setSalesRepId] = useState('');
    const [csrId, setCsrId] = useState('');

    const [customers, setCustomers] = useState([]);
    const [salesReps, setSalesReps] = useState([]);
    const [csrs, setCsrs] = useState([]);
    const [fetchingInvoiceInformation, setFetchingInvoiceInformation] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const fetchInformation = (id) => {
        setFetchingInvoiceInformation(true);
        axios.get(apiUrl + '/dpt-estimate/invoice-information',
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setCustomers(response.data.customers);
                setSalesReps(response.data.salesReps);
                setCsrs(response.data.csrs);

                setFetchingInvoiceInformation(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred!', 'danger');
                setFetchingInvoiceInformation(false);
            });
    };

    const handleOnChange = (setFunction, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        } else {
            value = event.target.value;
        }

        setFunction(value);
    };

    const handleChangeCustomer = (event) => {
        const value = event.target.value;
        setCustomerId(value);

        const customer = customers.find((customer) => parseInt(customer.id) === parseInt(event.target.value));
        setSalesRepId(customer.sales_rep_id);
        setCsrId(customer.csr_id);
    };

    useEffect(() => {
        fetchInformation();
    }, []);

    const clearForm = () => {
        setCustomerId('');
        setTerms('');
        setSalesRepId('');
        setCsrId('');
    };

    const hideHandler = () => {
        clearForm();
        props.hideSaveInvoiceModal();
    };

    const saveHandler = () => {
        if (
            customerId === '' ||
            terms === '' ||
            salesRepId === '' ||
            csrId === ''
        ) {
            return showNotification('Complete Data', 'Enter all the required information to continue', 'info');
        }

        setIsSaving(true);
        axios.post(apiUrl + '/dpt-estimate/save-invoice',
            {
                customerId,
                terms,
                salesRepId,
                csrId,
                accounting: props.accounting,
            },
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setIsSaving(false);
                clearForm();
                showNotification('Invoice Created Successfully', 'The invoice has been created successfully', 'success');
                props.hideSaveInvoiceModal();
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred!', 'danger');
                setIsSaving(false);
            });
    };

    let label = 'Save';
    if (isSaving) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" onClick={saveHandler} disabled={props.deleting}>{label}</button>
    ];
    return (
        <Dialog
            name="save-invoice-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="save-invoice-dialog"
        >
            <div className="save-invoice-container">
                <h1>New Invoice</h1>
                <p className="info">Complete the following information to create the invoice</p>
                <div className="fields">
                    <div className="field">
                        <div className="label">Customer</div>
                        <select value={customerId} onChange={handleChangeCustomer}>
                            <option value="">Select option</option>
                            {customers.map((customer) => (
                                <option value={customer.id} key={customer.id}>{customer.display_name}</option>
                            ))}
                        </select>
                        {fetchingInvoiceInformation && <i className="fas fa-circle-notch fa-spin" />}
                    </div>
                    <div className="field">
                        <div className="label">Terms</div>
                        <select value={terms} onChange={handleOnChange.bind(this, setTerms)}>
                            <option value="">Select option</option>
                            <option value="1">Due on receipt</option>
                            <option value="2">Net 10</option>
                            <option value="3">Net 15</option>
                            <option value="4">Net 30</option>
                            <option value="5">Net 60</option>
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Sales Rep.</div>
                        <select value={salesRepId} onChange={handleOnChange.bind(this, setSalesRepId)}>
                            <option value="">Select option</option>
                            {salesReps.map((salesRep) => (
                                <option value={salesRep.id} key={salesRep.id}>{salesRep.name}</option>
                            ))}
                        </select>
                        {fetchingInvoiceInformation && <i className="fas fa-circle-notch fa-spin" />}
                    </div>
                    <div className="field">
                        <div className="label">CSR</div>
                        <select value={csrId} onChange={handleOnChange.bind(this, setCsrId)}>
                            <option value="">Select option</option>
                            {csrs.map((csr) => (
                                <option value={csr.id} key={csr.id}>{csr.name}</option>
                            ))}
                        </select>
                        {fetchingInvoiceInformation && <i className="fas fa-circle-notch fa-spin" />}
                    </div>
                </div>
            </div>
        </Dialog >
    );
});

DeleteModal.propTypes = {

};

export default DeleteModal;
