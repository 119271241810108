import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table, Form, Delete } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import numeral from 'numeral';
import { Helmet } from 'react-helmet';
import { showNotification } from './../../utils';
import Layout from './../Layout';

class Container extends Component {
    componentDidMount() {
        const { fetchElements, sortColumn, sortType, filterFields, fetchInformation } = this.props;
        fetchInformation();
        fetchElements(1, sortColumn, sortType, filterFields);
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { updateFormValue, setFormError } = this.props;
        const value = event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;
        updateFormValue(event.target.name, value);

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        setFormError(event.target.name, error, errorMessage);
    }

    handleUpdateFormValueSimple(name, value) {
        this.props.updateFormValue(name, value);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, fetchElements, filterFields } = this.props;
        let type = 'asc';
        if (sortColumn === column) {
            type = sortType === 'asc' ? 'desc' : 'asc';
            setSort(column, type);
        } else {
            setSort(column, 'asc');
        }
        fetchElements(1, column, type, filterFields);
    }

    handleInlineChange(index, attribute, value) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(value, elements, index, attribute);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleSaveElement() {
        const { saveElement, formValues, elements } = this.props;
        saveElement(formValues, elements);
    }

    handleShowForm(id) {
        const { showForm, resetForm, fetchElement } = this.props;

        if (id === null) {
            resetForm(id);
        } else {
            fetchElement(id);
        }

        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete, elements } = this.props;
        deleteElement(idForDelete, elements);
    }

    handleChangeAttribute(index, attribute, type, value) {
        const { processedElements, changeAttribute, updateAttribute } = this.props;

        let valueToSave = value;
        if (type === 'money') {
            valueToSave = numeral(value).format('0.00');
            value = numeral(value).format('$0,0.00');
        } else if (type === 'number') {
            if (parseInt(value).toString().match(/^[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        } else if (type === 'date') {
            valueToSave = moment(value).format('YYYY-MM-DD');
            value = valueToSave;
        } else if (type === 'decimal') {
            if (parseInt(value).toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        }

        changeAttribute(value, processedElements, index, attribute);
        updateAttribute(processedElements[index].id, attribute, valueToSave, processedElements, index);
    }

    handleChangeFilter(name, event) {
        const { changeFilter, fetchElements, sortColumn, sortType, filterFields } = this.props;

        let value = null;
        let filter = filterFields;
        if (name === 'date') {
            value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else {
            value = event.target.value;
            changeFilter(name, value);
        }

        let fetch = false;
        const index = filter.findIndex(element => element['name'] === name);
        if (index === -1 && value !== '') {
            filter.push({ name: name, value: value });
            fetch = true;
        } else if (index !== -1 && value === '') {
            filter.splice(index, 1);
            fetch = true;
        } else if (index !== -1 && value !== '' && filter[index]['value'] !== value) {
            filter[index]['value'] = value;
            fetch = true;
        }

        if (fetch) {
            fetchElements(1, sortColumn, sortType, filter);
        }
    }

    handleSetActivePage(page) {
        const { fetchElements, setActivePage, sortColumn, sortType, filterFields } = this.props;
        setActivePage(page);
        fetchElements(page, sortColumn, sortType, filterFields);
    }

    render() {
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Dashboard</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Vendors"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={this.props.user}
                            />
                            <div className="upload-button" onClick={this.handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add</div>
                            <Table
                                elements={this.props.elements}
                                isFetchingElements={this.props.isFetchingElements}
                                totalItems={this.props.totalItems}
                                totalPages={this.props.totalPages}
                                selectedPage={this.props.selectedPage}
                                elementsPerPage={this.props.elementsPerPage}
                                sortColumn={this.props.sortColumn}
                                sortType={this.props.sortType}
                                user={this.props.user}
                                processedElements={this.props.processedElements}
                                handleSort={this.handleSort.bind(this)}
                                handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                handleInlineChange={this.handleInlineChange.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleDayChange={this.handleDayChange.bind(this)}
                                handleChangeFilter={this.handleChangeFilter.bind(this)}
                                handleSetActivePage={this.handleSetActivePage.bind(this)}
                            />
                            <Form
                                formValues={this.props.formValues}
                                formErrors={this.props.formErrors}
                                formErrorMessages={this.props.formErrorMessages}
                                isSavingForm={this.props.isSavingForm}
                                isFetchingElement={this.props.isFetchingElement}
                                terms={this.props.terms}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateFormValueSimple={this.handleUpdateFormValueSimple.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={this.props.hideForm.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                            />
                            <Delete
                                isDeleting={this.props.isDeleting}
                                hideDelete={this.props.hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isFetchingElement: selectors.getIsFetchingElement,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalItems: selectors.getTotalItems,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    filterFields: selectors.getFilterFields,
    terms: selectors.getTerms,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: (selectedPage, sortColumn, sortType, filterFields) => dispatch(actions.fetchElements(selectedPage, sortColumn, sortType, filterFields)),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (elements, index) => dispatch(actions.deleteElement(elements, index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: (values, elements) => dispatch(actions.saveElement(values, elements)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        changeAttribute: (value, elements, index, attribute) => dispatch(actions.changeAttribute(value, elements, index, attribute)),
        updateAttribute: (id, attribute, value, elements, index) => dispatch(actions.updateAttribute(id, attribute, value, elements, index)),
        fetchInformation: () => dispatch(actions.fetchInformation()),
        resetState: () => dispatch(actions.resetState()),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        fetchElement: (id) => dispatch(actions.fetchElement(id)),
        setActivePage: (page) => dispatch(actions.setActivePage(page)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
