import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    information: {
        points: [],
        anchors: [],
        tags: [],
        distances: [],
        lines: [],
        isFetching: false
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
            /*.setIn(['information', 'points'], [])
            .setIn(['information', 'anchors'], [])
            .setIn(['information', 'tags'], [])
            .setIn(['information', 'lines'], [])
            .setIn(['information', 'distances'], []);*/
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'points'], action.payload.points)
                .setIn(['information', 'anchors'], action.payload.anchors)
                .setIn(['information', 'tags'], action.payload.tags)
                .setIn(['information', 'distances'], action.payload.distances)
                .setIn(['information', 'lines'], action.payload.lines);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
