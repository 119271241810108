import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class QuoteInformation extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        hideQuoteInformation: PropTypes.func,
        isSending: PropTypes.bool,
        convertToQuote: PropTypes.array
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    render() {
        const { convertToQuote, hideQuoteInformation, isSending, isSavingOrder } = this.props;

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle">Quote Information</div>
                    <div className="w-100"></div>
                    {this.renderInput('Title', 'name', 'text', true)}
                    {this.renderInput('Notes', 'notes', 'text', true)}
                    <div className="w-100"></div>
                </div>
            </form>
        );

        let saveButtonLabel = <div><i className="fas fa-mail-bulk" /> Send</div>;
        if (isSavingOrder) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={convertToQuote} disabled={isSending}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideQuoteInformation}>Close</button>
        ];
        return (
            <Dialog
                name="quote-information"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default QuoteInformation;
