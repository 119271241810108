import { NAME } from './constants';

export const FETCH_ELEMENTS = `${NAME}/FETCH_ELEMENTS`;
export const FETCH_ELEMENTS_SUCCESS = `${NAME}/FETCH_ELEMENTS_SUCCESS`;
export const FETCH_ELEMENTS_FAILURE = `${NAME}/FETCH_ELEMENTS_FAILURE`;

export const FETCH_ELEMENT = `${NAME}/FETCH_ELEMENT`;
export const FETCH_ELEMENT_SUCCESS = `${NAME}/FETCH_ELEMENT_SUCCESS`;
export const FETCH_ELEMENT_FAILURE = `${NAME}/FETCH_ELEMENT_FAILURE`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_ELEMENT = `${NAME}/DELETE_ELEMENT`;
export const DELETE_ELEMENT_SUCCESS = `${NAME}/DELETE_ELEMENT_SUCCESS`;
export const DELETE_ELEMENT_FAILURE = `${NAME}/DELETE_ELEMENT_FAILURE`;

export const SAVE_ELEMENT = `${NAME}/SAVE_ELEMENT`;
export const SAVE_ELEMENT_SUCCESS = `${NAME}/SAVE_ELEMENT_SUCCESS`;
export const SAVE_ELEMENT_FAILURE = `${NAME}/SAVE_ELEMENT_FAILURE`;

export const SAVE_SPEED_TABLE = `${NAME}/SAVE_SPEED_TABLE`;
export const SAVE_SPEED_TABLE_SUCCESS = `${NAME}/SAVE_SPEED_TABLE_SUCCESS`;
export const SAVE_SPEED_TABLE_FAILURE = `${NAME}/SAVE_SPEED_TABLE_FAILURE`;

export const SAVE_MAKE_READY_MARKUPS = `${NAME}/SAVE_MAKE_READY_MARKUPS`;
export const SAVE_MAKE_READY_MARKUPS_SUCCESS = `${NAME}/SAVE_MAKE_READY_MARKUPS_SUCCESS`;
export const SAVE_MAKE_READY_MARKUPS_FAILURE = `${NAME}/SAVE_MAKE_READY_MARKUPS_FAILURE`;

export const SAVE_BHR_MARKUPS = `${NAME}/SAVE_BHR_MARKUPS`;
export const SAVE_BHR_MARKUPS_SUCCESS = `${NAME}/SAVE_BHR_MARKUPS_SUCCESS`;
export const SAVE_BHR_MARKUPS_FAILURE = `${NAME}/SAVE_BHR_MARKUPS_FAILURE`;

export const SAVE_ACTIVE_PAPERS = `${NAME}/SAVE_ACTIVE_PAPERS`;
export const SAVE_ACTIVE_PAPERS_SUCCESS = `${NAME}/SAVE_ACTIVE_PAPERS_SUCCESS`;
export const SAVE_ACTIVE_PAPERS_FAILURE = `${NAME}/SAVE_ACTIVE_PAPERS_FAILURE`;

export const FETCH_INFORMATION = `${NAME}/FETCH_INFORMATION`;
export const FETCH_INFORMATION_SUCCESS = `${NAME}/FETCH_INFORMATION_SUCCESS`;
export const FETCH_INFORMATION_FAILURE = `${NAME}/FETCH_INFORMATION_FAILURE`;

export const FETCH_PAPER_CATEGORIES = `${NAME}/FETCH_PAPER_CATEGORIES`;
export const FETCH_PAPER_CATEGORIES_SUCCESS = `${NAME}/FETCH_PAPER_CATEGORIES_SUCCESS`;
export const FETCH_PAPER_CATEGORIES_FAILURE = `${NAME}/FETCH_PAPER_CATEGORIES_FAILURE`;

export const FETCH_PAPER_WEIGHTS = `${NAME}/FETCH_PAPER_WEIGHTS`;
export const FETCH_PAPER_WEIGHTS_SUCCESS = `${NAME}/FETCH_PAPER_WEIGHTS_SUCCESS`;
export const FETCH_PAPER_WEIGHTS_FAILURE = `${NAME}/FETCH_PAPER_WEIGHTS_FAILURE`;

export const FETCH_PAPERS = `${NAME}/FETCH_PAPERS`;
export const FETCH_PAPERS_SUCCESS = `${NAME}/FETCH_PAPERS_SUCCESS`;
export const FETCH_PAPERS_FAILURE = `${NAME}/FETCH_PAPERS_FAILURE`;

export const UPDATE_FORM_VALUE = `${NAME}/UPDATE_FORM_VALUE`;
export const SET_SORT = `${NAME}/SET_SORT`;
export const SET_ELEMENTS = `${NAME}/SET_ELEMENTS`;
export const CHANGE_ATTRIBUTE = `${NAME}/CHANGE_ATTRIBUTE`;
export const SET_ACTIVE_ELEMENT = `${NAME}/SET_ACTIVE_ELEMENT`;
export const SET_ID_FOR_DELETE = `${NAME}/SET_ID_FOR_DELETE`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_FORM_ERRORS = `${NAME}/SET_FORM_ERRORS`;
export const SET_FORM_ERROR = `${NAME}/SET_FORM_ERROR`;
export const SET_SPEED_TABLE = `${NAME}/SET_SPEED_TABLE`;
export const SET_MAKE_READY_MARKUPS = `${NAME}/SET_MAKE_READY_MARKUPS`;
export const SET_BHR_MARKUPS = `${NAME}/SET_BHR_MARKUPS`;
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const SET_LOADING = `${NAME}/SET_LOADING`;
export const RESET_OPTIONS = `${NAME}/RESET_OPTIONS`;
export const UPDATE_STATE = `${NAME}/UPDATE_STATE`;
export const SET_OPTIONS = `${NAME}/SET_OPTIONS`;
export const SET_ACTIVE_PAGE = `${NAME}/SET_ACTIVE_PAGE`;

/**** Arrays ****/
export const ADD_ACTIVE_DATA = `${NAME}/ADD_ACTIVE_DATA`;
export const DELETE_ACTIVE_DATA = `${NAME}/DELETE_ACTIVE_DATA`;
export const CONCAT_ACTIVE_DATA = `${NAME}/CONCAT_ACTIVE_DATA`;
export const ADD_MULTIPLE_VALUE = `${NAME}/ADD_MULTIPLE_VALUE`;
export const CONCAT_MULTIPLE_VALUE = `${NAME}/CONCAT_MULTIPLE_VALUE`;
export const CLEAR_MULTIPLE_VALUE = `${NAME}/CLEAR_MULTIPLE_VALUE`;
export const CHANGE_FILTER = `${NAME}/CHANGE_FILTER`;
