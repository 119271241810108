import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const Laminates = (props) => {
    const [fetchingLaminates, setFetchingLaminates] = useState(false);

    const productType = props.productType;
    const setLaminates = props.setLaminates;
    const largeFormatMachineId = props.largeFormatMachineId;
    const substrateId = props.substrateId;
    useEffect(() => {
        if (parseInt(productType) !== 4 || largeFormatMachineId === '' || substrateId === '') {
            return;
        }

        setFetchingLaminates(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/laminates?substrate-id=' + substrateId,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setLaminates(response.data);
                setFetchingLaminates(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingLaminates(false);
            });
    }, [productType, largeFormatMachineId, substrateId, setLaminates]);

    const changeHandler = (event) => {
        props.setLaminateId(event.target.value);
    };

    return (
        <div className="field">
            <div className="label">Laminate:</div>
            <select value={props.laminateId} onChange={changeHandler}>
                <option value="">Select option</option>
                {props.laminates.map((laminate) => <option key={laminate.id} value={laminate.id}>{laminate.name}</option>)}
            </select>
            {fetchingLaminates && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default Laminates;