import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            password: '',
            confirm_password: '',
            token: ''
        },
        formErrors: {},
        isSaving: false
    },
    history: null
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.SAVE_PASSWORD:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_PASSWORD_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_PASSWORD_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                password: '',
                confirm_password: ''
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_TOKEN:
            state = state.setIn(['form', 'formValues', 'token'], action.payload);
            break;

        case actionTypes.SET_HISTORY:
            state = state.setIn(['history'], action.payload);
            break;

        default:
            break;
    }

    return state.toJS();
}
