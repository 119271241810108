import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import store from './store';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import './index.scss';
import Accessories from './components/Accessories';
import AcSalesReport from './components/AcSalesReport';
import Admin from './components/Admin';
import AffixElements from './components/AffixElements';
import ArtElements from './components/ArtElements';
import BandingMachines from './components/BandingMachines';
import BandingTypes from './components/BandingTypes';
import CertMailElements from './components/CertMailElements';
import ChangeOrder from './components/ChangeOrder';
import CoilBindingMachines from './components/CoilBindingMachines';
import CornerStapleMachines from './components/CornerStapleMachines';
import Counting from './components/Counting';
import CustomerDiscounts from './components/CustomerDiscounts';
import Customers from './components/Customers';
import CuttingMachines from './components/CuttingMachines';
import Dashboard from './components/Dashboard';
import DataProcessingElements from './components/DataProcessingElements';
import Departments from './components/Departments';
import Deposits from './components/Deposits';
import DieCuttingMachines from './components/DieCuttingMachines';
import DigitalMachines from './components/DigitalMachines';
import DptBindery from './components/DptBindery';
import DptCalculator from './components/DptCalculator';
import DptDataProcessing from './components/DptDataProcessing';
import DptDigital from './components/DptDigital';
import DptHand from './components/DptHand';
import DptInkjet from './components/DptInkjet';
import DptInkjetMaterials from './components/DptInkjetMaterials';
import DptInserting from './components/DptInserting';
import DptOutsideServices from './components/DptOutsideServices';
import DptPress from './components/DptPress';
import DrillingMachines from './components/DrillingMachines';
import Employees from './components/Employees';
import EnvelopePapers from './components/EnvelopePapers';
import EnvelopePresses from './components/EnvelopePresses';
import EnvelopeTypes from './components/EnvelopeTypes';
import FlatHandGathering from './components/FlatHandGathering';
import FoldingMachines from './components/FoldingMachines';
import FoldTypes from './components/FoldTypes';
import HandworkElements from './components/HandworkElements';
import IndexTabElements from './components/IndexTabElements';
import InkjetElements from './components/InkjetElements'
import Inks from './components/Inks';
import InOutRegisters from './components/InOutRegisters';
import InsertingElements from './components/InsertingElements';
import Invoices from './components/Invoices';
import LandrysInvoices from './components/LandrysInvoices';
import LabelElements from './components/LabelElements';
import Laminates from './components/Laminates';
import LargeFormatMachines from './components/LargeFormatMachines';
import Login from './components/Login';
import MailingOperations from './components/MailingOperations';
import MultiPageHandGathering from './components/MultiPageHandGathering';
import NcrFormElements from './components/NcrFormElements';
import Order from './components/Order';
import Orders from './components/Orders';
import Packages from './components/Packages';
import PaddingMachines from './components/PaddingMachines';
import PaperCategories from './components/PaperCategories';
import Papers from './components/Papers';
import PaperVendors from './components/PaperVendors';
import PerfectBindingMachines from './components/PerfectBindingMachines';
import PerfectMatchElements from './components/PerfectMatchElements';
import Presses from './components/Presses';
import PressureSealElements from './components/PressureSealElements';
import ProductCategories from './components/ProductCategories';
import ProductionOrder from './components/ProductionOrder';
import ProductionOrders from './components/ProductionOrders';
import ProductQuote from './components/ProductQuote';
import Products from './components/Products';
import ProductsCatalog from './components/ProductsCatalog';
import Quote from './components/Quote';
import Quotes from './components/Quotes';
import RestorePassword from './components/RestorePassword';
import SaddleStitchingMachines from './components/SaddleStitchingMachines';
import ScoringMachines from './components/ScoringMachines';
import Shipping from './components/Shipping';
import ShrinkWrappingCategories from './components/ShrinkWrappingCategories';
import ShrinkWrappingMachines from './components/ShrinkWrappingMachines';
import ShrinkWrappingTypes from './components/ShrinkWrappingTypes';
import SideStapleMachines from './components/SideStapleMachines';
import SpiralBindingMachines from './components/SpiralBindingMachines';
import Subdepartments from './components/Subdepartments';
import Substrates from './components/Substrates';
import TimelineLog from './components/TimelineLog';
import Turnaround from './components/Turnaround';
import Users from './components/Users';
import UthealthOrders from './components/UthealthOrders';
import UwbDemo from './components/UwbDemo';
import UwbRoom from './components/UwbRoom';
import vendors from './components/Vendors';
import WaferSealElements from './components/WaferSealElements';
import WrappingMachines from './components/WrappingMachines';
import WrappingTypes from './components/WrappingTypes';

require('dotenv').config();

if(process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({dsn: 'https://98e65af6436449d7a587bf62f0b3bff3@o382029.ingest.sentry.io/5210267'});
}

ReactDOM.render(
    <Provider store={store}>
        <ReactNotification />
        <Router>
            <Route path="/" exact component={Dashboard} />
            <Route path="/accessories" exact component={Accessories} />
            <Route path="/ac-sales-report" exact component={AcSalesReport} />
            <Route path="/admin" exact component={Admin} />
            <Route path="/affix-elements" exact component={AffixElements} />
            <Route path="/art-elements" exact component={ArtElements} />
            <Route path="/banding-machines" exact component={BandingMachines} />
            <Route path="/banding-types" exact component={BandingTypes} />
            <Route path="/cert-mail-elements" exact component={CertMailElements} />
            <Route path="/change-order" exact component={ChangeOrder} />
            <Route path="/coil-binding-machines" exact component={CoilBindingMachines} />
            <Route path="/corner-staple-machines" exact component={CornerStapleMachines} />
            <Route path="/counting" exact component={Counting} />
            <Route path="/customer-discounts" exact component={CustomerDiscounts} />
            <Route path="/customers" exact component={Customers} />
            <Route path="/cutting-machines" exact component={CuttingMachines} />
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/data-processing-elements" exact component={DataProcessingElements} />
            <Route path="/departments" exact component={Departments} />
            <Route path="/deposits" exact component={Deposits} />
            <Route path="/die-cutting-machines" exact component={DieCuttingMachines} />
            <Route path="/digital-machines" exact component={DigitalMachines} />
            <Route path="/drilling-machines" exact component={DrillingMachines} />
            <Route path="/flat-hand-gathering" exact component={FlatHandGathering} />
            <Route path="/folding-machines" exact component={FoldingMachines} />
            <Route path="/fold-types" exact component={FoldTypes} />
            <Route path="/employees" exact component={Employees} />
            <Route path="/envelope-papers" exact component={EnvelopePapers} />
            <Route path="/envelope-presses" exact component={EnvelopePresses} />
            <Route path="/envelope-types" exact component={EnvelopeTypes} />
            <Route path="/handwork-elements" exact component={HandworkElements} />
            <Route path="/index-tab-elements" exact component={IndexTabElements} />
            <Route path="/inkjet-elements" exact component={InkjetElements} />
            <Route path="/inks" exact component={Inks} />
            <Route path="/shipping-receiving" exact component={InOutRegisters} />
            <Route path="/inserting-elements" exact component={InsertingElements} />
            <Route path="/invoices" exact component={Invoices} />
            <Route path="/label-elements" exact component={LabelElements} />
            <Route path="/laminates" exact component={Laminates} />
            <Route path="/landrys-invoices" exact component={LandrysInvoices} />
            <Route path="/large-format-machines" exact component={LargeFormatMachines} />
            <Route path="/login" exact component={Login} />
            <Route path="/mailing-operations" exact component={MailingOperations} />
            <Route path="/multi-page-hand-gathering" exact component={MultiPageHandGathering} />
            <Route path="/ncr-form-elements" exact component={NcrFormElements} />
            <Route path="/orders/create" exact component={Order} />
            <Route path="/orders/update/:orderId" exact component={Order} />
            {/*<Route path="/customers/:customerId/orders" exact component={Orders} />*/}
            <Route path="/orders" exact component={Orders} />
            <Route path="/packages" exact component={Packages} />
            <Route path="/padding-machines" exact component={PaddingMachines} />
            <Route path="/paper-categories" exact component={PaperCategories} />
            <Route path="/papers" exact component={Papers} />
            <Route path="/paper-vendors" exact component={PaperVendors} />
            <Route path="/perfect-binding-machines" exact component={PerfectBindingMachines} />
            <Route path="/perfect-match-elements" exact component={PerfectMatchElements} />
            <Route path="/presses" exact component={Presses} />
            <Route path="/pressure-seal-elements" exact component={PressureSealElements} />
            <Route path="/product-categories" exact component={ProductCategories} />
            <Route path="/production-order/update/:orderId" exact component={ProductionOrder} />
            <Route path="/production-orders" exact component={ProductionOrders} />
            <Route path="/product-quote/:productWindowId" exact component={ProductQuote} />
            <Route path="/product-quote/:productWindowId/:customerId" exact component={ProductQuote} />
            <Route path="/products" exact component={Products} />
            <Route path="/products-catalog" exact component={ProductsCatalog} />
            <Route path="/quote" exact component={Quote} />
            <Route path="/quotes/create" exact component={Quote} />
            <Route path="/quotes/update/:quoteId" exact component={Quote} />
            {/*<Route path="/customers/:customerId/quotes/create" exact component={Quote} />*/}
            {/*<Route path="/customers/:customerId/quotes/update/:quoteId" exact component={Quote} />*/}
            {/*<Route path="/customers/:customerId/quotes" exact component={Quotes} />*/}
            <Route path="/quotes" exact component={Quotes} />
            <Route path="/restore-password/:token" exact component={RestorePassword} />
            <Route path="/saddle-stitching-machines" exact component={SaddleStitchingMachines} />
            <Route path="/scoring-machines" exact component={ScoringMachines} />
            <Route path="/shipping" exact component={Shipping} />
            <Route path="/shrink-wrapping-categories" exact component={ShrinkWrappingCategories} />
            <Route path="/shrink-wrapping-machines" exact component={ShrinkWrappingMachines} />
            <Route path="/shrink-wrapping-types" exact component={ShrinkWrappingTypes} />
            <Route path="/side-staple-machines" exact component={SideStapleMachines} />
            <Route path="/spiral-binding-machines" exact component={SpiralBindingMachines} />
            <Route path="/subdepartments/:id" exact component={Subdepartments} />
            <Route path="/substrates" exact component={Substrates} />
            <Route path="/timeline-log" exact component={TimelineLog} />
            <Route path="/turnaround" exact component={Turnaround} />
            <Route path="/users" exact component={Users} />
            <Route path="/ut-health-orders" exact component={UthealthOrders} />
            <Route path="/uwb-room/:roomId/:interval/:showDistances" exact component={UwbRoom} />
            <Route path="/uwb-demo/:roomId" exact component={UwbDemo} />
            <Route path="/vendors" exact component={vendors} />
            <Route path="/wafer-seal-elements" exact component={WaferSealElements} />
            <Route path="/wrapping-machines" exact component={WrappingMachines} />
            <Route path="/wrapping-types" exact component={WrappingTypes} />

            <Route path="/dpt-bindery" exact component={DptBindery} />
            <Route path="/dpt-calculator" exact component={DptCalculator} />
            <Route path="/dpt-data-processing" exact component={DptDataProcessing} />
            <Route path="/dpt-digital" exact component={DptDigital} />
            <Route path="/dpt-hand" exact component={DptHand} />
            <Route path="/dpt-inkjet" exact component={DptInkjet} />
            <Route path="/dpt-inkjet-materials" exact component={DptInkjetMaterials} />
            <Route path="/dpt-inserting" exact component={DptInserting} />
            <Route path="/dpt-outside-services" exact component={DptOutsideServices} />
            <Route path="/dpt-press" exact component={DptPress} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
