import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            labor_rate: '',
            labor_markup_rate: '',
            min_width: '',
            min_length: '',
            max_width: '',
            max_length: '',
            setup_time: '',
            speed: '',
            is_active: 1,
            active_substrate_ids: []
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        activeSubstrates: [],
        isSavingActiveSubstrates: false,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'asc'
    },
    information: {
        substrates: [],
        isFetching: false
    },
    filter: {
        fields: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/large-format-machines') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                labor_rate: '',
                labor_markup_rate: '',
                min_width: '',
                min_length: '',
                max_width: '',
                max_length: '',
                setup_time: '',
                speed: '',
                is_active: 1,
                active_substrate_ids: []
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['activeElement', 'activeSubstrates'], action.payload.activeSubstrates)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'labor_rate'], action.payload.labor_rate)
                .setIn(['form', 'formValues', 'labor_markup_rate'], action.payload.labor_markup_rate)
                .setIn(['form', 'formValues', 'min_width'], action.payload.min_width)
                .setIn(['form', 'formValues', 'min_length'], action.payload.min_length)
                .setIn(['form', 'formValues', 'max_width'], action.payload.max_width)
                .setIn(['form', 'formValues', 'max_length'], action.payload.max_length)
                .setIn(['form', 'formValues', 'setup_time'], action.payload.setup_time)
                .setIn(['form', 'formValues', 'speed'], action.payload.speed)
                .setIn(['form', 'formValues', 'is_active'], action.payload.is_active)
                .setIn(['form', 'formValues', 'active_substrate_ids'], []);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'substrates'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'substrates'], action.payload.substrates);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.SAVE_ACTIVE_SUBSTRATES:
            state = state.setIn(['activeElement', 'isSavingActiveSubstrates'], true);
            break;

        case actionTypes.SAVE_ACTIVE_SUBSTRATES_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingActiveSubstrates'], false);
            break;

        case actionTypes.SAVE_ACTIVE_SUBSTRATES_FAILURE:
            state = state.setIn(['activeElement', 'isSavingActiveSubstrates'], false);
            break;

        case actionTypes.ADD_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.DELETE_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.delete(action.payload.value));
            break;

        case actionTypes.CONCAT_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.ADD_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.CLEAR_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload], (data) => data.clear());
            break;

        case actionTypes.CONCAT_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.FETCH_ELEMENT:
            state = state.setIn(['activeElement', 'isFetching'], true);
            break;

        case actionTypes.FETCH_ELEMENT_SUCCESS:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT_FAILURE:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
