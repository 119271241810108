const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/quotes`,
    customers: `${API_URL}/customers`,
    turnarounds: `${API_URL}/turnarounds`,
    information: `${API_URL}/quote/information`,
    estimate: `${API_URL}/quote/estimate`,
    estimates: `${API_URL}/estimates`,
    convertToOrder: `${API_URL}/quote/convert-to-order`,
    exportPdf: `${API_URL}/quote/export-pdf`,
    calculateShipping: `${API_URL}/quote/calculate-shipping`,
    intersectPaperCategories: `${API_URL}/paper-category/intersect`,
    intersectPaperWeights: `${API_URL}/paper/intersect-weights`,
    intersectPapers: `${API_URL}/paper/intersect-papers`
};

export default apiUrls;
