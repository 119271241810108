import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import FlatDrawImposition from './FlatDrawImposition';
import EstimatePricing from './EstimatePricing';

class EstimateFlat extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object
    };

    renderInput(label, name, type, required, disabled = false, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    disabled={disabled}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, disabled = false, colClass = 'col', loadingName = null) {
        const { formValues, formErrors, handleUpdateFormValue, fetching } = this.props;

        let loadingIcon = null;
        if (loadingName != null && fetching[loadingName]) {
            loadingIcon = <div className="loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    disabled={disabled}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
                {loadingIcon}
            </div>
        );
    }

    renderProductCategories(type, lock) {
        const { options } = this.props;

        let categoriesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === parseInt(type)) {
                categoriesOptions.push(options['productCategories'][i]);
            }
        }

        return this.renderSelect('Product Category', 'estimate_product_category_id', categoriesOptions, true, lock);
    }

    renderFrontInks(lock) {
        const { formValues } = this.props;

        let label = 'Front Inks';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Front Inks';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_front_inks', [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true, lock)
        );
    }

    renderFrontCoatings(lock) {
        const { formValues, information } = this.props;

        let label = 'Coating Front';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Coating Front';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_front_coating', coatingOptions, false, lock)
        );
    }

    renderBackInks(lock) {
        const { formValues } = this.props;

        let label = 'Back Inks';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Back Inks';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_back_inks', [
                { label: '0', value: 0 },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true, lock)
        );
    }

    renderBackCoatings(lock) {
        const { formValues, information } = this.props;

        let label = 'Coating Back';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Coating Back';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_back_coating', coatingOptions, false, lock)
        );
    }

    renderProductCategoriesElements() {
        const { configuration } = this.props;

        const productCategoryId = configuration['product_options']['product_category_id'];
        if (parseInt(productCategoryId['show']) === 1) {
            return [
                this.renderProductCategories(1, productCategoryId['lock']),
                <div className="w-100"></div>,
                <div className="estimate-divider"></div>,
                <div className="w-100"></div>
            ];
        }

        return null;
    }

    renderProductDescriptionElement() {
        const { configuration } = this.props;

        const productDescription = configuration['product_description'];
        if (parseInt(productDescription['show']) === 1) {
            return [
                this.renderInput('Description', 'estimate_product_description', 'text', true, productDescription['lock']),
                <div className="w-100"></div>,
                <div className="estimate-divider"></div>,
                <div className="w-100"></div>
            ];
        }

        return null;
    }

    renderPressElements() {
        const { configuration, formValues, information, options } = this.props;

        const pressId = configuration['product_options']['press_id'];
        const runningStyle = configuration['product_options']['running_style'];
        if (parseInt(pressId['show']) === 0 && parseInt(runningStyle['show']) === 0) {
            return null;
        }

        let activePress = null;
        for (let i = 0; i < information['presses'].length; i++) {
            if (parseInt(formValues['estimate_press_id']) === parseInt(information['presses'][i]['id'])) {
                activePress = information['presses'][i];
            }
        }

        let runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'SheetWise', value: 'SW' }, { label: 'Work and Turn', value: 'WT' }];
        if (activePress != null && parseInt(activePress['is_perfecting'])) {
            runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'Perfecting', value: 'Perfecting' }];
        }

        let elements = [];
        if (parseInt(pressId['show']) === 1) {
            elements.push(this.renderSelect('Press', 'estimate_press_id', options['presses'], true, pressId['lock']));
        }
        if (parseInt(runningStyle['show']) === 1) {
            elements.push(this.renderSelect('Running Style', 'estimate_running_style', runningStyleOptions, false, runningStyle['lock']));
        }

        elements.push(<div className="w-100"></div>);
        elements.push(<div className="estimate-divider"></div>);
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderSizesElements() {
        const { configuration } = this.props;

        const flatWidth = configuration['product_options']['flat_width'];
        const flatHeigh = configuration['product_options']['flat_height'];
        const finishWidth = configuration['product_options']['finish_width'];
        const finishHeight = configuration['product_options']['finish_height'];
        if (parseInt(flatWidth['show']) === 0 && parseInt(flatHeigh['show']) === 0 &&
            parseInt(finishWidth['show']) === 0 && parseInt(finishHeight['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(flatWidth['show']) === 1) {
            elements.push(this.renderInput('Flat Width', 'estimate_width', 'decimal', true, flatWidth['lock']));
        }
        if (parseInt(flatHeigh['show']) === 1) {
            elements.push(this.renderInput('Flat Height', 'estimate_height', 'decimal', true, flatHeigh['lock']));
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(finishWidth['show']) === 1) {
            elements.push(this.renderInput('Finish Width', 'estimate_finish_width', 'decimal', true, finishWidth['lock']));
        }
        if (parseInt(finishHeight['show']) === 1) {
            elements.push(this.renderInput('Finish Height', 'estimate_finish_height', 'decimal', true, finishHeight['lock']));
        }

        elements.push(<div className="w-100"></div>);
        elements.push(<div className="estimate-divider"></div>);
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderInkElements() {
        const { configuration } = this.props;

        const frontInks = configuration['product_options']['front_inks'];
        const backInks = configuration['product_options']['back_inks'];
        const bleed = configuration['product_options']['bleed'];
        if (parseInt(frontInks['show']) === 0 && parseInt(backInks['show']) === 0
            && parseInt(configuration['product_options']['bleed']['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(frontInks['show']) === 1) {
            elements.push(this.renderFrontInks(frontInks['lock']));
        }
        if (parseInt(backInks['show']) === 1) {
            elements.push(this.renderBackInks(backInks['lock']));
        }
        if (parseInt(bleed['show']) === 1) {
            elements.push(
                this.renderSelect('Bleed', 'estimate_bleed', [
                    { label: 'None', value: 0 },
                    { label: '1/8', value: 0.125 },
                    { label: '1/4', value: 0.25 }
                ], true, bleed['lock'])
            );
        }

        elements.push(<div className="w-100"></div>);
        elements.push(<div className="estimate-divider"></div>);
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderPaperElements() {
        const { configuration, formValues, options } = this.props;

        const paperCategoryId = configuration['product_options']['paper_category_id'];
        const paperWeight = configuration['product_options']['paper_weight'];
        const paperId = configuration['product_options']['paper_id'];
        if (parseInt(paperCategoryId['show']) === 0 && parseInt(paperWeight['show']) === 0
            && parseInt(paperId['show']) === 0) {
            return null;
        }

        let paperOptions = [];
        if (formValues['estimate_front_coating'] !== '' && formValues['estimate_back_coating'] !== '') {
            for (let i = 0; i < options['papers'].length; i++) {
                if (options['papers'][i]['coating_one_side'] === 0) {
                    paperOptions.push(options['papers'][i]);
                }
            }
        } else {
            paperOptions = options['papers'];
        }

        let elements = [];
        if (parseInt(paperCategoryId['show']) === 1) {
            elements.push(this.renderSelect('Paper Category', 'estimate_paper_category_id', options['paperCategories'], true,
                paperCategoryId['lock'], 'col', 'paperCategories'));
        }
        if (parseInt(paperWeight['show']) === 1) {
            elements.push(this.renderSelect('Paper Weight', 'estimate_paper_weight', options['paperWeights'], true,
                paperWeight['lock'], 'col', 'paperWeights'));
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(paperId['show']) === 1) {
            elements.push(this.renderSelect('Paper', 'estimate_paper_id', paperOptions, true,
                paperId['lock'], 'col', 'papers'));
        }

        elements.push(<div className="w-100"></div>);
        elements.push(<div className="estimate-divider"></div>);
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderCoatingElements() {
        const { configuration } = this.props;

        const frontCoating = configuration['product_options']['front_coating'];
        const backCoating = configuration['product_options']['back_coating'];
        if (parseInt(frontCoating['show']) === 0 && parseInt(backCoating['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(frontCoating['show']) === 1) {
            elements.push(this.renderFrontCoatings(frontCoating['lock']));
        }
        if (parseInt(backCoating['show']) === 1) {
            elements.push(this.renderBackCoatings(backCoating['lock']));
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderHandlingElements() {
        const { configuration, options } = this.props;

        const bandingTypeId = configuration['handling_options']['banding_type_id'];
        const wrappingTypeId = configuration['handling_options']['wrapping_type_id'];
        const packageId = configuration['handling_options']['package_id'];
        const useHandGathering = configuration['handling_options']['use_hand_gathering'];
        if (parseInt(bandingTypeId['show']) === 0 && parseInt(wrappingTypeId['show']) === 0
            && parseInt(packageId['show']) === 0 && parseInt(useHandGathering['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(bandingTypeId['show']) === 1) {
            elements.push(this.renderSelect('Banding', 'estimate_banding_type_id', options['bandingTypes'], false, bandingTypeId['lock']));
        }
        if (parseInt(wrappingTypeId['show']) === 1) {
            elements.push(this.renderSelect('Wrapping', 'estimate_wrapping_type_id', options['wrappingTypes'], false, wrappingTypeId['lock']));
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(packageId['show']) === 1) {
            elements.push(this.renderSelect('Package', 'estimate_package_id', options['packages'], false, packageId['lock']));
        }
        if (parseInt(useHandGathering['show']) === 1) {
            elements.push(
                this.renderSelect('Hand Gathering', 'estimate_use_hand_gathering', [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ], false, useHandGathering['lock'])
            );
        }
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderProductionNotesElements() {
        const { configuration, handleUpdateFormValue, formValues, options } = this.props;

        const productionNotes = configuration['production_notes'];
        const showDiscountTable = configuration['customer_discount'] && configuration['customer_discount']['show'] ? configuration['customer_discount']['show'] : 1;
        const lockDiscountTable = configuration['customer_discount'] && configuration['customer_discount']['lock'] ? configuration['customer_discount']['lock'] : 0;
        if (parseInt(productionNotes['show']) === 0 && parseInt(showDiscountTable) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(productionNotes['show']) === 1) {
            elements.push(<label>Production Notes:</label>);
            elements.push(
                <textarea name="estimate_notes" onChange={handleUpdateFormValue.bind(this, 'text', false)} style={{ height: 83 }} disabled={productionNotes['lock']}>
                    {formValues['estimate_notes']}
                </textarea>
            );
        }
        if (parseInt(showDiscountTable) === 1) {
            elements.push(this.renderSelect('Discount Table', 'estimate_customer_discount_id', options['customerDiscounts'], false, lockDiscountTable));
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Production Notes
                </div>
                <div className="card-body pt-2 pb-0 pl-2 pr-2">
                    {elements}
                </div>
            </div>
        );
    }

    renderBinderyElements() {
        const { configuration, formValues, options } = this.props;

        const foldTypeId = configuration['bindery_options']['fold_type_id'];
        const requiresScoring = configuration['bindery_options']['requires_scoring'];
        const drillingHoles = configuration['bindery_options']['drilling_holes'];
        const shrinkWrappingTypeId = configuration['bindery_options']['shrink_wrapping_type_id'];
        const requiresPadding = configuration['bindery_options']['requires_padding'];
        const requiresDieCutting = configuration['bindery_options']['requires_die_cutting'];
        const sheetsToPad = configuration['bindery_options']['sheets_to_pad'];

        if (parseInt(foldTypeId['show']) === 0 && parseInt(requiresScoring['show']) === 0
            && parseInt(drillingHoles['show']) === 0 && parseInt(shrinkWrappingTypeId['show']) === 0
            && parseInt(requiresPadding['show']) === 0 && parseInt(requiresDieCutting['show']) === 0
            && parseInt(sheetsToPad['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(foldTypeId['show']) === 1) {
            elements.push(this.renderSelect('Folding Type', 'estimate_fold_type_id', options['foldTypes'], false, foldTypeId['lock']));
        }
        if (parseInt(requiresScoring['show']) === 1) {
            elements.push(
                this.renderSelect('Scoring', 'estimate_requires_scoring', [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ], false, requiresScoring['lock'])
            );
        }
        if (parseInt(foldTypeId['show']) === 1 || parseInt(requiresScoring['show']) === 1) {
            elements.push(<div className="w-100"></div>);
        }

        if (parseInt(drillingHoles['show']) === 1) {
            elements.push(
                this.renderSelect('Drilling Holes', 'estimate_drilling_holes', [
                    { label: 'None', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 }
                ], false, drillingHoles['lock'])
            );
        }
        if (parseInt(shrinkWrappingTypeId['show']) === 1) {
            elements.push(this.renderSelect('Shrink-Wrapping', 'estimate_shrink_wrapping_type_id', options['shrinkWrappingTypes'], false, shrinkWrappingTypeId['lock']));
        }
        if (parseInt(drillingHoles['show']) === 1 || parseInt(shrinkWrappingTypeId['show']) === 1) {
            elements.push(<div className="w-100"></div>);
        }

        if (parseInt(requiresPadding['show']) === 1) {
            elements.push(
                this.renderSelect('Padding', 'estimate_requires_padding', [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ], false, requiresPadding['lock'])
            );
        }
        if (parseInt(requiresDieCutting['show']) === 1) {
            elements.push(
                this.renderSelect('Die-Cutting', 'estimate_requires_die_cutting', [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ], false, requiresDieCutting['lock'])
            );
        }
        if (parseInt(requiresPadding['show']) === 1 || parseInt(requiresDieCutting['show']) === 1) {
            elements.push(<div className="w-100"></div>);
        }

        if (formValues['estimate_requires_padding'] === 1 && parseInt(sheetsToPad['show']) === 1) {
            elements.push(
                this.renderSelect('Sheets To Pad', 'estimate_sheets_to_pad', [
                    { label: '10', value: 10 },
                    { label: '25', value: 25 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                ], false, sheetsToPad['lock'], 'col-6')
            );
        }

        if (elements.length === 0) {
            return null;
        }

        return elements;
    }

    render() {
        const { formValues, handleUpdateFormValue, estimateResults, information } = this.props;

        let numberUp = null;
        let numberOut = null;
        let imposition = null;
        if (estimateResults.length > 0 && estimateResults[0]['results'].length > 0) {
            let results = estimateResults[0]['results'];
            imposition = (
                <div className="col">
                    <FlatDrawImposition
                        result={results[0]}
                    />
                </div>
            );
        }

        let handlingElements = this.renderHandlingElements();
        let binderyElements = this.renderBinderyElements();

        return (
            <form>
                <div className="row no-gutters mb-2">
                    <div className="col-4" style={{ color: '#0d47a1', fontWeight: 'bold' }}>PQN Estimate</div>
                </div>
                <div className="row no-gutters">
                    <div className="col">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Product Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderProductCategoriesElements()}
                                    {this.renderProductDescriptionElement()}
                                    {this.renderPressElements()}
                                    {this.renderSizesElements()}
                                    {this.renderInkElements()}
                                    {this.renderPaperElements()}
                                    {this.renderCoatingElements()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {binderyElements != null && <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Bindery Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {binderyElements}
                                </div>
                            </div>
                        </div>}
                        {handlingElements != null && <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Handling Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {handlingElements}
                                </div>
                            </div>
                        </div>}
                        {this.renderProductionNotesElements()}
                    </div>
                    <div className="col">
                        <div className="card mb-2 mr-1 pb-2">
                            <div className="card-header">
                                Imposition
                            </div>
                            <div className="card-body pt-3 pb-0">
                                <div className="row">
                                    {numberUp}
                                    <div className="w-100"></div>
                                    {numberOut}
                                    <div className="w-100"></div>
                                    {imposition}
                                </div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                    </div>
                    <div className="w-100"></div>
                    <EstimatePricing
                        formValues={formValues}
                        estimateResults={estimateResults}
                        information={information}
                        handleUpdateFormValue={handleUpdateFormValue}
                        renderSheets
                    />
                </div>
            </form>
        );
    }
}

export default EstimateFlat;
