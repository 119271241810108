import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import moment from 'moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import apiUrls from './../apiUrls';

class ReceivedHistory extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        fetching: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        selectedPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleChangeFilter: PropTypes.func,
        handleShowReceive: PropTypes.func,
        user: PropTypes.object
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn['receiveHistory']['column'] === name) {
            if (sortType['receiveHistory']['type'] === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name, 'receiveHistory')}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, fetching, handleShowForm } = this.props;

        if (fetching['receiveHistory']) {
            return (
                <tr>
                    <td colSpan="12"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements['receiveHistory'].length === 0) {
            return (
                <tr>
                    <td colSpan="12">No Results</td>
                </tr>
            );
        }

        let data = [];
        for (let i = 0; i < elements['receiveHistory'].length; i++) {
            data.push(
                <tr key={i}>
                    <td>{elements['receiveHistory'][i]['customer']}</td>
                    <td>{elements['receiveHistory'][i]['description']}</td>
                    <td>{numeral(elements['receiveHistory'][i]['quantity']).format('0,0')}</td>
                    <td>{elements['receiveHistory'][i]['ps_number']}</td>
                    <td>{elements['receiveHistory'][i]['pm_number']}</td>
                    <td>{moment(elements['receiveHistory'][i]['created_at']).subtract(4, 'hours').format('MM/DD/YYYY h:mm a')}</td>
                    <td>{elements['receiveHistory'][i]['created_by_name']}</td>
                    <td>{elements['receiveHistory'][i]['received_by_name']}</td>
                    <td>{elements['receiveHistory'][i]['accepted_by_name']}</td>
                    <td>{elements['receiveHistory'][i]['notification_names']}</td>
                    <td>{elements['receiveHistory'][i]['notes']}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements['receiveHistory'][i].id, null, 'receiveHistory')} />
                            </Tooltip>
                            <Tooltip title="Export PDF" position="bottom" arrow size="small" className="action-tooltip">
                                <a href={apiUrls.export + '?id=' + elements['receiveHistory'][i]['id']} target="_blank" rel="noopener noreferrer" style={{color: '#000'}}><i className="fas fa-file-pdf" style={{ fontSize: 18 }} /></a>
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    handleKey(name, event) {
        const { handleChangeFilter } = this.props;
        if (event.key === 'Enter') {
            handleChangeFilter(name, 'receiveHistory', event);
        }
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onBlur={handleChangeFilter.bind(this, name, 'receiveHistory')} onKeyPress={this.handleKey.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name, 'receiveHistory')} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { pagination, handleSetActivePage } = this.props;
        return (
            <div className="table-container table-hover" style={{ borderTop: '1px solid #cfd3d7' }}>
                <div className="inout-table-title">Receiving History</div>
                <table id="titles-table" className="table table-sm table-hover">
                    <thead>
                        <tr>
                            {this.renderTableHeader('Customer', 'customer')}
                            {this.renderTableHeader('Description', 'description')}
                            {this.renderTableHeader('Quantity', 'quantity', { width: 90 })}
                            {this.renderTableHeader('PS #', 'ps_number', { width: 90 })}
                            {this.renderTableHeader('PM #', 'pm_number', { width: 90 })}
                            {this.renderTableHeader('Created At', 'created_at', { width: 160 })}
                            {this.renderTableHeader('Created By', 'created_by_name')}
                            {this.renderTableHeader('Received By', 'received_by_name')}
                            {this.renderTableHeader('Accepted By', 'accepted_by_name')}
                            {this.renderTableHeader('Notify', 'notification_names')}
                            {this.renderTableHeader('Notes', 'notes')}
                            <th style={{ width: 110 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.renderSearchField('customer')}
                            {this.renderSearchField('description')}
                            {this.renderSearchField('quantity')}
                            {this.renderSearchField('ps_number')}
                            {this.renderSearchField('pm_number')}
                            {this.renderSearchField('created_at')}
                            {this.renderSearchField('created_by_name')}
                            {this.renderSearchField('received_by_name')}
                            {this.renderSearchField('accepted_by_name')}
                            {this.renderSearchField('notification_names')}
                            {this.renderSearchField('notes')}
                            <th className="search-th"></th>
                        </tr>
                        {this.renderData()}
                    </tbody>
                </table>
                <Pagination.Container
                    totalItems={pagination.receiveHistory.totalItems}
                    totalPages={pagination.receiveHistory.totalPages}
                    selectedPage={pagination.receiveHistory.selectedPage}
                    elementsPerPage={10}
                    handleSetActivePage={handleSetActivePage.bind(this, 'receiveHistory')}
                />
                <div className="clearfix"></div>
            </div>
        );
    }
}

export default ReceivedHistory;
