const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/orders`,
    customers: `${API_URL}/customers`,
    turnarounds: `${API_URL}/turnarounds`,
    information: `${API_URL}/order/information`,
    estimate: `${API_URL}/order/estimate`,
    estimates: `${API_URL}/estimates`,
    exportJobTicket: `${API_URL}/order/export-job-ticket`,
    uploadFiles: `${API_URL}/order/upload-files`,
    progress: `${API_URL}/order/progress`,
    downloadFile: `${API_URL}/order/download-file`,
    deleteFile: `${API_URL}/order/delete-file`,
    sendToProduction: `${API_URL}/order/send-to-production`,
    intersectPaperCategories: `${API_URL}/paper-category/intersect`,
    intersectPaperWeights: `${API_URL}/paper/intersect-weights`,
    intersectPapers: `${API_URL}/paper/intersect-papers`
};

export default apiUrls;
