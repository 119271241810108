import * as actionTypes from './actionTypes';
import * as loginActions from './../Login/Login.actions';
import apiUrls from './apiUrls';
import { CALL_API } from 'redux-api-middleware';
import * as dialog from './../common/dialog';
import { showNotification } from './../../utils';
import axios from 'axios';

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export function saveElement(values, elements, projectId) {
    return (dispatch, getState) => {
        let errors = {};
        let errorMessages = {};
        if (values.name === '' || values.name === null) {
            errors.name = true;
        }
        if (values.address === '' || values.address === null) {
            errors.address = true;
        }
        if (values.city === '' || values.city === null) {
            errors.city = true;
        }
        if (values.state === '' || values.state === null) {
            errors.state = true;
        }
        if (values.zip === '' || values.zip === null) {
            errors.zip = true;
        }

        if (Object.keys(errors).length > 0) {
            dispatch(setFormErrors(errors, errorMessages));
            return;
        }

        let endpoint = null;
        let method = null;
        if (values.id === '') {
            endpoint = apiUrls.elements;
            method = 'POST';
            values.status = 1;
        } else {
            endpoint = apiUrls.elements + '/' + values.id;
            method = 'PUT';
        }

        values.project_id = projectId;
        values.all = values.all ? 1 : 0;

        let promise = dispatch(loginActions.getToken());
        return promise.then((result) => {
            return dispatch({
                [CALL_API]: {
                    endpoint: endpoint,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    },
                    method: method,
                    body: JSON.stringify(values),
                    bailout: (state) => {
                        return !state.oauth2.isAuthenticated;
                    },
                    types: [
                        actionTypes.SAVE_ELEMENT,
                        actionTypes.SAVE_ELEMENT_SUCCESS,
                        actionTypes.SAVE_ELEMENT_FAILURE
                    ]
                }
            }).then(response => {
                if (response.error) {
                    showNotification('Error', 'An error has occurred!', 'danger');
                } else {
                    let newElements = processNewData(elements, response.payload, values.id === '' ? 1 : 2);
                    dispatch(setElements(newElements));
                    dispatch(dialog.actions.hideDialog('form'));
                    showNotification('Element Saved!', 'The element has been saved successfully', 'success');
                }
            });
        });
    };
}

export function updateAttribute(id, attribute, value, elements, index) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        let promise = dispatch(loginActions.getToken());
        return promise.then((result) => {
            return dispatch({
                [CALL_API]: {
                    endpoint: apiUrls.elements + '/' + id,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    },
                    method: 'PUT',
                    body: JSON.stringify(values),
                    bailout: (state) => {
                        return !state.oauth2.isAuthenticated;
                    },
                    types: [
                        actionTypes.SAVE_ELEMENT,
                        actionTypes.SAVE_ELEMENT_SUCCESS,
                        actionTypes.SAVE_ELEMENT_FAILURE
                    ]
                }
            }).then(response => {
                if (response.error) {
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
        });
    };
}

export function deleteElement(id, elements) {
    return (dispatch, getState) => {
        let promise = dispatch(loginActions.getToken());
        return promise.then((result) => {
            return dispatch({
                [CALL_API]: {
                    endpoint: apiUrls.elements + '/' + id,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    },
                    method: 'DELETE',
                    bailout: (state) => {
                        return !state.oauth2.isAuthenticated;
                    },
                    types: [
                        actionTypes.DELETE_ELEMENT,
                        actionTypes.DELETE_ELEMENT_SUCCESS,
                        actionTypes.DELETE_ELEMENT_FAILURE
                    ]
                }
            }).then(response => {
                if (response.error) {
                    showNotification('Error', 'An error has occurred!', 'danger');
                } else {
                    let newElements = processNewData(elements, id, 3);
                    dispatch(setElements(newElements));
                    dispatch(dialog.actions.hideDialog('delete'));
                    showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
                }
            });
        });
    };
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export const fetchStatistics = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_STATISTICS, payload: null });
    axios.get(apiUrls.statistics, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_STATISTICS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_STATISTICS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}