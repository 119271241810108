import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import moment from 'moment';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let fields = [
        'id',
        'date',
        'method',
        'todo',
        'topic',
        'present',
        'staff',
        'notes',
        'completed_by',
        'completed',
        'expired'
    ];

    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',');
    }

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.search + '?fields=' + fields.join(',') + '&selectedPage=' + selectedPage
        + '&sort=' + column + '&filter=' + filter + '&summary=1',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const saveElement = () => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());

    if (formValues['date'] === '' || formValues['date'] === null) {
        showNotification('Complete the Information', 'Enter the date', 'info');
        return;
    }
    if (formValues['todo'] !== '' && formValues['todo'] !== null && formValues['todo'] === 1) {
        if (formValues['completed_by'] === '' || formValues['completed_by'] === null) {
            showNotification('Complete the Information', 'Enter the completed by', 'info');
            return;
        }
    }
    if (formValues['topic'] === '' || formValues['topic'] === null) {
        showNotification('Complete the Information', 'Enter the topic', 'info');
        return;
    }
    if (formValues['method'] === '' || formValues['method'] === null) {
        showNotification('Complete the Information', 'Enter the method', 'info');
        return;
    }
    if (formValues['present'].length === 0) {
        showNotification('Complete the Information', 'Enter who is present', 'info');
        return;
    }
    if (formValues['staff'] === '' || formValues['staff'] === null) {
        showNotification('Complete the Information', 'Enter the staff', 'info');
        return;
    }
    if (formValues['notes'] === '' || formValues['notes'] === null) {
        showNotification('Complete the Information', 'Enter the notes', 'info');
        return;
    }

    let endpoint = null;
    let method = null;
    if (formValues['id'] === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + formValues['id'];
        method = 'PUT';
    }

    formValues['date'] = moment(formValues['date']).format('YYYY-MM-DD');
    formValues['completed_by'] = moment(formValues['completed_by']).format('YYYY-MM-DD');
    formValues['present'] = formValues['present'].join(', ');

    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: formValues,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            if (formValues['id'] === '') {
                dispatch(resetForm());
            } else {
                dispatch(setActiveElement(response.data));
            }
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(dispatch(dialog.actions.hideDialog('form')));

        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const sendEmail = () => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());

    if (formValues['date'] === '' || formValues['date'] === null) {
        showNotification('Complete the Information', 'Enter the date', 'info');
        return;
    }
    if (formValues['todo'] !== '' && formValues['todo'] !== null && formValues['todo'] === 1) {
        if (formValues['completed_by'] === '' || formValues['completed_by'] === null) {
            showNotification('Complete the Information', 'Enter the completed by', 'info');
            return;
        }
    }
    if (formValues['topic'] === '' || formValues['topic'] === null) {
        showNotification('Complete the Information', 'Enter the topic', 'info');
        return;
    }
    if (formValues['method'] === '' || formValues['method'] === null) {
        showNotification('Complete the Information', 'Enter the method', 'info');
        return;
    }
    if (formValues['present'].length === 0) {
        showNotification('Complete the Information', 'Enter who is present', 'info');
        return;
    }
    if (formValues['staff'] === '' || formValues['staff'] === null) {
        showNotification('Complete the Information', 'Enter the staff', 'info');
        return;
    }
    if (formValues['notes'] === '' || formValues['notes'] === null) {
        showNotification('Complete the Information', 'Enter the notes', 'info');
        return;
    }

    formValues['date'] = moment(formValues['date']).format('YYYY-MM-DD');
    formValues['completed_by'] = moment(formValues['completed_by']).format('YYYY-MM-DD');
    formValues['present'] = formValues['present'].join(', ');

    dispatch({ type: actionTypes.SEND_EMAIL, payload: null });
    axios.post(apiUrls.sendEmail, formValues,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SEND_EMAIL_SUCCESS, payload: response.data });
            showNotification('Email Sent!', 'The email has been sent successfully', 'success');
            dispatch(dispatch(dialog.actions.hideDialog('form')));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SEND_EMAIL_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const deleteElement = (id) => (dispatch, getState) => {
    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function setActiveElement(element) {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export function exportExcel() {
    return (dispatch, getState) => {
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());
        const filterFields = selectors.getFilterFields(getState());

        let filterNames = [];
        let filterValues = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterNames.push(filterFields[i]['name']);
            filterValues.push(filterFields[i]['value']);
        }

        window.location.href = apiUrls.export + '?sort-column=' + sortColumn + '&sort-type='
            + sortType + '&filter-names=' + filterNames.join(',') + '&filter-values='
            + filterValues.join(',');
    };
}

export function setActivePage(page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: page
    };
}

export function resetFilter() {
    return {
        type: actionTypes.RESET_FILTER,
        payload: null
    };
}

export const fetchElement = id => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement(response.data));
            dispatch(dispatch(dialog.actions.showDialog('form')));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function checkPresent(label, checked) {
    return {
        type: actionTypes.CHECK_PRESENT,
        payload: { label, checked }
    };
}

export function checkMethod(label, checked) {
    return {
        type: actionTypes.CHECK_METHOD,
        payload: { label, checked }
    };
}

export function setOtherMethod(value) {
    return {
        type: actionTypes.SET_OTHER_METHOD,
        payload: value
    };
}

export function printPdf() {
    return (dispatch, getState) => {
        let formValues = selectors.getFormValues(getState());
        window.open(apiUrls.printPdf + '?id=' + formValues.id, '_blank');
    };
}
