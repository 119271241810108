import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification, validateAuthInResponse } from './../../utils';
import axios from 'axios';

export const fetchElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let fields = [
        'id',
        'name',
        'email',
        'department',
        'job_title',
        'shift',
        'hourly_pay',
        'annual_pay',
        'payment_type',
        'departments_ids',
    ];

    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',')
    }

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.search + '?fields=' + fields.join(',') + '&selectedPage=' + selectedPage
        + '&sort=' + column + '&filter=' + filter + '&summary=1',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENTS_FAILURE));
        });
}

export const saveElement = values => (dispatch, getState) => {
    let errors = {};
    let errorMessages = {};
    let attributes = [
        'code',
        'name',
        'department',
        'job_title',
        'shift',
        'age',
        'gender',
        'birth_date',
        'hire_date',
        'annual_pay',
        'payment_type',
        'type',
    ];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_ELEMENT_FAILURE));
        });
}

export const deleteElement = (id) => (dispatch, getState) => {
    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.DELETE_ELEMENT_FAILURE));
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function setActiveElement(element) {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export function setActivePage(page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: page
    };
}

export const fetchElement = id => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement(response.data));
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENT_FAILURE));
        });
}

export const fetchInformation = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_INFORMATION_FAILURE));
        });
}

export function updateDepartmentSelect(id) {
    return {
        type: actionTypes.UPDATE_DEPARTMENT_SELECT,
        payload: parseInt(id)
    };
}

export function checkDepartment(id, value) {
    return {
        type: actionTypes.CHECK_DEPARTMENT,
        payload: { id, value }
    };
}

export function clearDepartments() {
    return {
        type: actionTypes.CLEAR_DEPARTMENTS,
        payload: null
    };
}
