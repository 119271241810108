import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const Coatings = (props) => {
    const [fetchingCoatings, setFetchingCoatings] = useState(false);

    const productType = props.productType;
    const setCoatings = props.setCoatings;
    useEffect(() => {
        if (parseInt(productType) !== 1 && parseInt(productType) !== 2) {
            return;
        }

        setFetchingCoatings(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/coatings',
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setCoatings(response.data);
                setFetchingCoatings(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingCoatings(false);
            });
    }, [productType, setCoatings]);

    const changeHandler = (event) => {
        props.setCoatingId(event.target.value);
    };

    return (
        <div className="field">
            <div className="label">{props.label ? props.label : 'Coating'}:</div>
            <select value={props.coatingId} onChange={changeHandler}>
                <option value="">Select option</option>
                {props.coatings.map((coating) => <option key={coating.id} value={coating.id}>{coating.name}</option>)}
            </select>
            {fetchingCoatings && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default Coatings;