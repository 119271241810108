import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import {
    Received, Accepted, Form, Delete, Accept, Receive, Unclaimed,
    ReceivedHistory
} from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import numeral from 'numeral';
import { Helmet } from 'react-helmet';
import { showNotification } from './../../utils';
import Layout from './../Layout';
import './styles.scss';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        selectedPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        showAccept: PropTypes.func,
        hideAccept: PropTypes.func,
        showReceive: PropTypes.func,
        hideReceive: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        changeAttribute: PropTypes.func,
        updateAttribute: PropTypes.func,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        activeElement: PropTypes.func,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        processElements: PropTypes.func,
        fetchData: PropTypes.func,
        setIdToAccept: PropTypes.func,
        accept: PropTypes.func,
        isAccepting: PropTypes.bool,
        setIdToReceive: PropTypes.func,
        receive: PropTypes.func,
        isReceiving: PropTypes.bool,
        data: PropTypes.func,
        addNotification: PropTypes.func,
        removeNotification: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchAcceptElements(1, '-', '-', []);
        this.props.fetchReceiveElements(1, '-', '-', []);
        this.props.fetchUnclaimElements(1, '-', '-', []);
        this.props.fetchHistoryElements(1, '-', '-', []);
        this.props.fetchData();
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { updateFormValue, setFormError } = this.props;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        updateFormValue(event.target.name, value);

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        setFormError(event.target.name, error, errorMessage);
    }

    handleSort(column, filterName) {
        const { setSort, sortColumn, sortType, filterFields } = this.props;
        let type = 'asc';
        if (sortColumn[filterName]['column'] === column) {
            type = sortType[filterName]['type'] === 'asc' ? 'desc' : 'asc';
            setSort(column, type, filterName);
        } else {
            setSort(column, 'asc', filterName);
        }

        if(filterName === 'accept') {
            this.props.fetchAcceptElements(1, column, type, filterFields[filterName]['fields']);
        } else if(filterName === 'receive') {
            this.props.fetchReceiveElements(1, column, type, filterFields[filterName]['fields']);
        } else if(filterName === 'unclaim') {
            this.props.fetchUnclaimElements(1, column, type, filterFields[filterName]['fields']);
        } else if(filterName === 'receiveHistory') {
            this.props.fetchHistoryElements(1, column, type, filterFields[filterName]['fields']);
        }
    }

    handleInlineChange(index, attribute, value) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(value, elements, index, attribute);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleSaveElement() {
        const { saveElement, formValues } = this.props;
        saveElement(formValues);
    }

    handleShowForm(id, uncomplained = false, elementName) {
        const { showForm, resetForm, updateFormValue, fetchElement } = this.props;

        if (id == null) {
            resetForm(id);
            if (uncomplained) {
                updateFormValue('status', 4);
            }
        } else {
            fetchElement(id);
        }

        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement(elementName) {
        const { deleteElement, idForDelete } = this.props;
        deleteElement(idForDelete);
    }

    handleChangeAttribute(index, attribute, type, value) {
        const { processedElements, changeAttribute, updateAttribute } = this.props;

        let valueToSave = value;
        if (type === 'money') {
            valueToSave = numeral(value).format('0.00');
            value = numeral(value).format('$0,0.00');
        } else if (type === 'number') {
            if (parseInt(value).toString().match(/^[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        } else if (type === 'date') {
            valueToSave = moment(value).format('YYYY-MM-DD');
            value = valueToSave;
        } else if (type === 'decimal') {
            if (parseInt(value).toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        }

        changeAttribute(value, processedElements, index, attribute);
        updateAttribute(processedElements[index].id, attribute, valueToSave, processedElements, index);
    }

    handleChangeFilter(name, filterName, event) {
        const { changeFilter, sortColumn, filterFields } = this.props;
        let filter = filterFields[filterName].fields;
        let value = event.target.value;
        changeFilter(name, value, filterName);

        let fetch = false;
        const index = filter.findIndex(element => element['name'] === name);
        if (index === -1 && value !== '') {
            filter.push({ name: name, value: value });
            fetch = true;
        } else if (index !== -1 && value === '') {
            filter.splice(index, 1);
            fetch = true;
        } else if (index !== -1 && value !== '' && filter[index]['value'] !== value) {
            filter[index]['value'] = value;
            fetch = true;
        }

        if (fetch) {
            if(filterName === 'accept') {
                this.props.fetchAcceptElements(1, sortColumn.accept.column, sortColumn.accept.type, filter);
            } else if(filterName === 'receive') {
                this.props.fetchReceiveElements(1, sortColumn.receive.column, sortColumn.receive.type, filter);
            } else if(filterName === 'unclaim') {
                this.props.fetchUnclaimElements(1, sortColumn.unclaim.column, sortColumn.unclaim.type, filter);
            } else if(filterName === 'receiveHistory') {
                this.props.fetchHistoryElements(1, sortColumn.receiveHistory.column, sortColumn.receiveHistory.type, filter);
            }
        }
    }

    handleNotificationClick(id, event) {
        const { addNotification, removeNotification } = this.props;

        if (event.target.checked) {
            addNotification(id);
        } else {
            removeNotification(id);
        }
    }

    handleShowReceive(id) {
        const { showReceive, setIdToReceive, fetchElement } = this.props;
        fetchElement(id);
        showReceive();
        setIdToReceive(id);
    }

    handleReceive() {
        const { receive } = this.props;
        receive();
    }

    handleShowAccept(id, name) {
        const { showAccept, setIdToAccept, fetchElement } = this.props;
        fetchElement(id);
        showAccept();
        setIdToAccept(id);
    }

    handleAccept() {
        const { accept } = this.props;
        accept();
    }

    handleSetActivePage(name, page) {
        const { setActivePage, sortColumn, filterFields } = this.props;
        setActivePage(name, page);
        
        if(name === 'accept') {
            this.props.fetchAcceptElements(page, sortColumn.accept.column, sortColumn.accept.type, filterFields);
        } else if(name === 'receive') {
            this.props.fetchReceiveElements(page, sortColumn.receive.column, sortColumn.receive.type, filterFields);
        } else if(name === 'unclaim') {
            this.props.fetchUnclaimElements(page, sortColumn.unclaim.column, sortColumn.unclaim.type, filterFields);
        } else if(name === 'receiveHistory') {
            this.props.fetchHistoryElements(page, sortColumn.receiveHistory.column, sortColumn.receiveHistory.type, filterFields);
        }
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, selectedPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, hideForm, isDeleting,
            hideDelete, formErrorMessages, processedElements, data, isAccepting, hideAccept,
            isReceiving, hideReceive, activeElement, fetching, pagination } = this.props;

        let addButton = null;
        let addUnclaimedButton = null;
        if (user !== null && user['is_in_out_sales']) {
            addButton = <div className="upload-button" onClick={this.handleShowForm.bind(this, null, false)}><i className="fas fa-plus" /> Add</div>;
        }

        if (user !== null && user['is_in_out_warehouse']) {
            addUnclaimedButton = <div className="upload-button" onClick={this.handleShowForm.bind(this, null, true)} style={{ marginTop: 0, marginBottom: 5 }}><i className="fas fa-plus" /> Add</div>;
        }

        let accepted = null;
        if (user !== null && user['is_in_out_sales']) {
            accepted = (
                <Accepted
                    elements={elements}
                    isFetchingElements={isFetchingElements}
                    selectedPage={selectedPage}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    user={user}
                    processedElements={processedElements}
                    fetching={fetching}
                    pagination={pagination}
                    handleSort={this.handleSort.bind(this)}
                    handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                    handleInlineChange={this.handleInlineChange.bind(this)}
                    handleShowDelete={this.handleShowDelete.bind(this)}
                    handleShowForm={this.handleShowForm.bind(this)}
                    handleDayChange={this.handleDayChange.bind(this)}
                    handleChangeFilter={this.handleChangeFilter.bind(this)}
                    handleShowAccept={this.handleShowAccept.bind(this)}
                    handleSetActivePage={this.handleSetActivePage.bind(this)}
                />
            );
        }

        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Shipping/Receiving</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Shipping/Receiving"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            {addButton}
                            <div id="tables-container" className="production-container">
                                <Received
                                    elements={elements}
                                    isFetchingElements={isFetchingElements}
                                    selectedPage={selectedPage}
                                    sortColumn={sortColumn}
                                    sortType={sortType}
                                    user={user}
                                    processedElements={processedElements}
                                    fetching={fetching}
                                    pagination={pagination}
                                    handleSort={this.handleSort.bind(this)}
                                    handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                    handleInlineChange={this.handleInlineChange.bind(this)}
                                    handleShowDelete={this.handleShowDelete.bind(this)}
                                    handleShowForm={this.handleShowForm.bind(this)}
                                    handleDayChange={this.handleDayChange.bind(this)}
                                    handleChangeFilter={this.handleChangeFilter.bind(this)}
                                    handleShowReceive={this.handleShowReceive.bind(this)}
                                    handleSetActivePage={this.handleSetActivePage.bind(this)}
                                />
                                {accepted}
                                <div className="divider-in-out"></div>
                                <div className="clearfix"></div>
                                {addUnclaimedButton}
                                <div className="clearfix"></div>
                                <Unclaimed
                                    elements={elements}
                                    isFetchingElements={isFetchingElements}
                                    selectedPage={selectedPage}
                                    sortColumn={sortColumn}
                                    sortType={sortType}
                                    user={user}
                                    processedElements={processedElements}
                                    fetching={fetching}
                                    pagination={pagination}
                                    handleSort={this.handleSort.bind(this)}
                                    handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                    handleInlineChange={this.handleInlineChange.bind(this)}
                                    handleShowDelete={this.handleShowDelete.bind(this)}
                                    handleShowForm={this.handleShowForm.bind(this)}
                                    handleDayChange={this.handleDayChange.bind(this)}
                                    handleChangeFilter={this.handleChangeFilter.bind(this)}
                                    handleShowAccept={this.handleShowAccept.bind(this)}
                                    handleSetActivePage={this.handleSetActivePage.bind(this)}
                                />
                                <div className="divider-in-out"></div>
                                <ReceivedHistory
                                    elements={elements}
                                    isFetchingElements={isFetchingElements}
                                    selectedPage={selectedPage}
                                    sortColumn={sortColumn}
                                    sortType={sortType}
                                    user={user}
                                    processedElements={processedElements}
                                    fetching={fetching}
                                    pagination={pagination}
                                    handleSort={this.handleSort.bind(this)}
                                    handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                    handleInlineChange={this.handleInlineChange.bind(this)}
                                    handleShowDelete={this.handleShowDelete.bind(this)}
                                    handleShowForm={this.handleShowForm.bind(this)}
                                    handleDayChange={this.handleDayChange.bind(this)}
                                    handleChangeFilter={this.handleChangeFilter.bind(this)}
                                    handleShowReceive={this.handleShowReceive.bind(this)}
                                    handleSetActivePage={this.handleSetActivePage.bind(this)}
                                />
                            </div>
                            <Form
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                data={data}
                                activeElement={activeElement}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={hideForm.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                                handleNotificationClick={this.handleNotificationClick.bind(this)}
                            />
                            <Accept
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isAccepting={isAccepting}
                                isFetchingElement={isFetchingElement}
                                handleAccept={this.handleAccept.bind(this)}
                                hideAccept={hideAccept.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                            />
                            <Receive
                                isReceiving={isReceiving}
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isFetchingElement={isFetchingElement}
                                handleReceive={this.handleReceive.bind(this)}
                                hideReceive={hideReceive.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                            />
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    isReceiving: selectors.getIsReceiving,
    isAccepting: selectors.getIsAccepting,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isFetchingElement: selectors.getIsFetchingElement,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    idToReceive: selectors.getIdToReceive,
    idToAccept: selectors.getIdToAccept,
    selectedPage: selectors.getSelectedPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    activeElement: selectors.getActiveElement,
    data: selectors.getData,
    fetching: selectors.getFetching,
    pagination: selectors.getPagination,
    filterFields: selectors.getFilterFields,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: () => dispatch(actions.fetchElements()),
        fetchElement: (id) => dispatch(actions.fetchElement(id)),
        fetchAcceptElements: (selectedPage, column, type, filter) => dispatch(actions.fetchAcceptElements(selectedPage, column, type, filter)),
        fetchReceiveElements: (selectedPage, column, type, filter) => dispatch(actions.fetchReceiveElements(selectedPage, column, type, filter)),
        fetchUnclaimElements: (selectedPage, column, type, filter) => dispatch(actions.fetchUnclaimElements(selectedPage, column, type, filter)),
        fetchHistoryElements: (selectedPage, column, type, filter) => dispatch(actions.fetchHistoryElements(selectedPage, column, type, filter)),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type, filterName) => dispatch(actions.setSort(column, type, filterName)),
        deleteElement: (index) => dispatch(actions.deleteElement(index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        showAccept: () => dispatch(dialog.actions.showDialog('accept')),
        hideAccept: () => dispatch(dialog.actions.hideDialog('accept')),
        showReceive: () => dispatch(dialog.actions.showDialog('receive')),
        hideReceive: () => dispatch(dialog.actions.hideDialog('receive')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: (values) => dispatch(actions.saveElement(values)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        resetState: () => dispatch(actions.resetState()),
        changeFilter: (name, value, filterName) => dispatch(actions.changeFilter(name, value, filterName)),
        processElements: (type) => dispatch(actions.processElements(type)),
        fetchData: () => dispatch(actions.fetchData()),
        addNotification: (element) => dispatch(actions.addNotification(element)),
        removeNotification: (element) => dispatch(actions.removeNotification(element)),
        setIdToReceive: (id) => dispatch(actions.setIdToReceive(id)),
        setIdToAccept: (id) => dispatch(actions.setIdToAccept(id)),
        accept: () => dispatch(actions.accept()),
        receive: () => dispatch(actions.receive()),
        setActivePage: (name, page) => dispatch(actions.setActivePage(name, page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
