import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import FlatDrawImposition from './FlatDrawImposition';
import MultiPageDrawImposition from './MultiPageDrawImposition';
import EstimatePricing from './EstimatePricing';

class EstimateMultiPage extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object,
        configuration: PropTypes.object
    };

    renderInput(label, name, type, required, disabled = false, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    disabled={disabled}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, disabled = false, colClass = 'col', loadingName = null) {
        const { formValues, formErrors, handleUpdateFormValue, fetching } = this.props;

        let loadingIcon = null;
        if (loadingName != null && fetching[loadingName]) {
            loadingIcon = <div className="loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    disabled={disabled}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
                {loadingIcon}
            </div>
        );
    }

    renderProductCategories(type, lock) {
        const { options } = this.props;

        let categoriesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === parseInt(type)) {
                categoriesOptions.push(options['productCategories'][i]);
            }
        }

        return this.renderSelect('Product Category', 'estimate_product_category_id', categoriesOptions, true, lock);
    }

    renderInteriorInks(lock) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2) {
            return null;
        }

        return (
            this.renderSelect('Interior Inks', 'estimate_interior_inks', [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true, lock)
        );
    }

    renderInteriorCoating(lock) {
        const { formValues, information } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect('Coating', 'estimate_interior_coating', coatingOptions, false, lock)
        );
    }

    renderFrontInks(lock) {
        const { formValues } = this.props;

        let label = 'Front Ink';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Front Ink';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_front_inks', [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true, lock)
        );
    }

    renderFrontCoatings(lock) {
        const { formValues, information } = this.props;

        let label = 'Coating Front';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Coating Front';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_front_coating', coatingOptions, false, lock)
        );
    }

    renderBackInks(lock) {
        const { formValues } = this.props;

        let label = 'Back Ink';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Back Ink';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_back_inks', [
                { label: '0', value: 0 },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true, lock)
        );
    }

    renderBackCoatings(lock) {
        const { formValues, information } = this.props;

        let label = 'Coating Back';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Coating Back';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_back_coating', coatingOptions, false, lock)
        );
    }

    renderCoverPress(pressesOptions, lock) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            return (
                this.renderSelect('Press Cover', 'estimate_cover_press_id', pressesOptions, true, lock)
            );
        }

        return null;
    }

    renderSecondRunningStyle(activePress, lock) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 2) {
            let runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'SheetWise', value: 'SW' }, { label: 'Work and Turn', value: 'WT' }];
            if (activePress !== null && parseInt(activePress['is_perfecting'])) {
                runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'Perfecting', value: 'Perfecting' }];
            }

            return (
                this.renderSelect('Running Style Leftover', 'estimate_second_running_style', runningStyleOptions, true, lock)
            );
        }

        return null;
    }

    renderCoverRunningStyle(activePress, lock) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            let runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'SheetWise', value: 'SW' }, { label: 'Work and Turn', value: 'WT' }];
            if (activePress !== null && parseInt(activePress['is_perfecting'])) {
                runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'Perfecting', value: 'Perfecting' }];
            }

            return (
                this.renderSelect('Running Style Cover', 'estimate_cover_running_style', runningStyleOptions, true, lock)
            );
        }

        return null;
    }

    hasCover() {
        const { formValues } = this.props;
        return [2, 5, 7, 9].includes(parseInt(formValues['estimate_multi_page_type']));
    }

    renderProductCategoriesElements() {
        const { configuration } = this.props;

        const productCategoryId = configuration['product_options']['product_category_id'];
        if (parseInt(productCategoryId['show']) === 1) {
            return [
                this.renderProductCategories(2, productCategoryId['lock']),
                <div className="w-100"></div>,
                <div className="estimate-divider"></div>,
                <div className="w-100"></div>
            ];
        }

        return null;
    }

    renderProductDescriptionElement() {
        const { configuration } = this.props;

        const productDescription = configuration['product_description'];
        if (parseInt(configuration['product_description']['show']) === 1) {
            return [
                this.renderInput('Description', 'estimate_product_description', 'text', true, productDescription['lock']),
                <div className="w-100"></div>,
                <div className="estimate-divider"></div>,
                <div className="w-100"></div>
            ];
        }

        return null;
    }

    renderPublicationTypeElements() {
        const { configuration } = this.props;

        const multiPageType = configuration['product_options']['multi_page_type'];
        const pages = configuration['product_options']['pages'];
        if (parseInt(multiPageType['show']) === 0 && parseInt(pages['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(multiPageType['show']) === 1) {
            elements.push(
                this.renderSelect('Publication Type', 'estimate_multi_page_type', [
                    { label: 'Self-Cover', value: 1 },
                    { label: 'Plus-Cover', value: 2 },
                    { label: 'Perfect Bind', value: 3 },
                    { label: 'Staple Binding No Cover', value: 4 },
                    { label: 'Staple Binding With Cover', value: 5 },
                    { label: 'Coil Binding No Cover', value: 6 },
                    { label: 'Coil Binding With Cover', value: 7 },
                    { label: 'Spiral Binding No Cover', value: 8 },
                    { label: 'Spiral Binding With Cover', value: 9 }
                ], true, multiPageType['lock'])
            );
        }
        if (parseInt(pages['show']) === 1) {
            elements.push(this.renderInput('Pages', 'estimate_pages', 'number', true, pages['lock']));
        }
        elements.push(<div className="w-100"></div>);
        elements.push(<div className="estimate-divider"></div>);
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderSizesElements() {
        const { configuration } = this.props;

        const finishWidth = configuration['product_options']['finish_width'];
        const finishHeight = configuration['product_options']['finish_height'];
        const bindingEdge = configuration['product_options']['binding_edge'];
        if (parseInt(finishWidth['show']) === 0 && parseInt(finishHeight['show']) === 0 &&
            parseInt(bindingEdge['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(finishWidth['show']) === 1) {
            elements.push(this.renderInput('Flat Width', 'estimate_width', 'decimal', true, finishWidth['lock']));
        }
        if (parseInt(finishHeight['show']) === 1) {
            elements.push(this.renderInput('Flat Height', 'estimate_height', 'decimal', true, finishHeight['lock']));
        }
        if (parseInt(bindingEdge['show']) === 1) {
            elements.push(
                this.renderSelect('Binding Edge', 'estimate_binding_edge', [
                    { label: 'Width', value: 1 },
                    { label: 'Height', value: 2 }
                ], true, bindingEdge['lock'])
            );
        }

        elements.push(<div className="w-100"></div>);
        elements.push(<div className="estimate-divider"></div>);
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderInteriorInksElements() {
        const { configuration } = this.props;

        const interiorInks = configuration['product_options']['interior_inks'];
        const bleed = configuration['product_options']['bleed'];
        if (parseInt(interiorInks['show']) === 0 && parseInt(bleed['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(interiorInks['show']) === 1) {
            elements.push(this.renderInteriorInks(interiorInks['lock']));
        }
        if (parseInt(bleed['show']) === 1) {
            elements.push(
                this.renderSelect('Bleed', 'estimate_bleed', [
                    { label: 'None', value: 0 },
                    { label: '1/8', value: 0.125 },
                    { label: '1/4', value: 0.25 }
                ], true, bleed['lock'])
            );
        }
        elements.push(<div className="w-100"></div>);
        elements.push(<div className="estimate-divider"></div>);
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderInksElements() {
        const { configuration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        const frontInks = configuration['product_options']['front_inks'];
        const backInks = configuration['product_options']['back_inks'];
        if (parseInt(frontInks['show']) === 0 && parseInt(backInks['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(frontInks['show']) === 1) {
            elements.push(this.renderFrontInks(frontInks['lock']));
        }
        if (parseInt(backInks['show']) === 1) {
            elements.push(this.renderBackInks(backInks['lock']));
        }
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderProductOptionsCard() {
        const productCategoriesElements = this.renderProductCategoriesElements();
        const productDescriptionElement = this.renderProductDescriptionElement();
        const publicationTypeElements = this.renderPublicationTypeElements();
        const sizesElements = this.renderSizesElements();
        const interiorInksElements = this.renderInteriorInksElements();
        const inksElements = this.renderInksElements();

        if (productCategoriesElements === null && productDescriptionElement === null && publicationTypeElements === null
            && sizesElements === null && interiorInksElements === null && inksElements === null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Product Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {productCategoriesElements}
                        {productDescriptionElement}
                        {publicationTypeElements}
                        {sizesElements}
                        {interiorInksElements}
                        {inksElements}
                    </div>
                </div>
            </div>
        );
    }

    renderCoverPressElements() {
        const { options, configuration, information, formValues } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let coverActivePress = null;
        for (let i = 0; i < information['presses'].length; i++) {
            if (parseInt(formValues['estimate_cover_press_id']) === parseInt(information['presses'][i]['id'])) {
                coverActivePress = information['presses'][i];
            }
        }

        const coverPressId = configuration['press_options']['cover_press_id'];
        const coverRunningStyle = configuration['press_options']['cover_running_style'];
        if (parseInt(coverPressId['show']) === 0 && parseInt(coverRunningStyle['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(coverPressId['show']) === 1) {
            elements.push(this.renderCoverPress(options['presses'], coverPressId['lock']));
        }
        if (parseInt(coverRunningStyle['show']) === 1) {
            elements.push(this.renderCoverRunningStyle(coverActivePress, coverRunningStyle['lock']));
        }
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderPressElements() {
        const { options, configuration, information, formValues } = this.props;

        const pressId = configuration['press_options']['press_id'];
        const runningStyle = configuration['press_options']['running_style'];
        if (parseInt(pressId['show']) === 0 && parseInt(runningStyle['show']) === 0) {
            return null;
        }

        let activePress = null;
        for (let i = 0; i < information['presses'].length; i++) {
            if (parseInt(formValues['estimate_press_id']) === parseInt(information['presses'][i]['id'])) {
                activePress = information['presses'][i];
            }
        }

        let runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'SheetWise', value: 'SW' }, { label: 'Work and Turn', value: 'WT' }];
        if (activePress != null && parseInt(activePress['is_perfecting'])) {
            runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'Perfecting', value: 'Perfecting' }];
        }

        let elements = [];
        if (parseInt(pressId['show']) === 1) {
            elements.push(this.renderSelect('Press Interior', 'estimate_press_id', options['presses'], true, pressId['lock']));
        }
        if (parseInt(runningStyle['show']) === 1) {
            elements.push(this.renderSelect('Running Style Interior', 'estimate_running_style', runningStyleOptions, true, runningStyle['lock']));
        }
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderSecondPressElements() {
        const { options, configuration, information, formValues } = this.props;

        const secondPressId = configuration['press_options']['second_press_id'];
        const secondRunningStyle = configuration['press_options']['second_running_style'];
        if (parseInt(secondPressId['show']) === 0 && parseInt(secondRunningStyle['show']) === 0) {
            return null;
        }

        let secondActivePress = null;
        for (let i = 0; i < information['presses'].length; i++) {
            if (parseInt(formValues['estimate_second_press_id']) === parseInt(information['presses'][i]['id'])) {
                secondActivePress = information['presses'][i];
            }
        }

        let elements = [];
        if (parseInt(secondPressId['show']) === 1) {
            elements.push(this.renderSelect('Press Leftover', 'estimate_second_press_id', options['presses'], true, secondPressId['lock']));
        }
        if (parseInt(secondRunningStyle['show']) === 1) {
            elements.push(this.renderSecondRunningStyle(secondActivePress, secondRunningStyle['lock']));
        }
        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderPressCard() {
        const coverPressElements = this.renderCoverPressElements();
        const pressElements = this.renderPressElements();
        const secondPressElements = this.renderSecondPressElements();

        if (coverPressElements === null && pressElements === null && secondPressElements === null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Press
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {coverPressElements}
                        {pressElements}
                        {secondPressElements}
                    </div>
                </div>
            </div>
        );
    }

    renderInteriorPaperElements() {
        const { configuration, formValues, options } = this.props;

        const paperCategoryId = configuration['paper_options']['paper_category_id'];
        const paperWeight = configuration['paper_options']['paper_weight'];
        const paperId = configuration['paper_options']['paper_id'];
        const interiorCoating = configuration['paper_options']['interior_coating'];
        if (parseInt(paperCategoryId['show']) === 0 && parseInt(paperWeight['show']) === 0
            && parseInt(paperId['show']) === 0 && parseInt(interiorCoating['show']) === 0) {
            return null;
        }

        let paperOptions = [];
        if (formValues['estimate_interior_coating'] !== '') {
            for (let i = 0; i < options['papers'].length; i++) {
                if (options['papers'][i]['coating_one_side'] === 0) {
                    paperOptions.push(options['papers'][i]);
                }
            }
        } else {
            paperOptions = options['papers'];
        }

        let elements = [];
        if (parseInt(paperCategoryId['show']) === 1) {
            elements.push(this.renderSelect('Paper Category', 'estimate_paper_category_id', options['paperCategories'], true,
                paperCategoryId['lock'], 'col', 'paperCategories'));
        }
        if (parseInt(paperWeight['show']) === 1) {
            elements.push(this.renderSelect('Paper Weight', 'estimate_paper_weight', options['paperWeights'], true,
                paperWeight['lock'], 'col', 'paperWeights'));
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(paperId['show']) === 1) {
            elements.push(this.renderSelect('Paper', 'estimate_paper_id', paperOptions, true,
                paperId['lock'], 'col', 'papers'));
        }
        if (parseInt(interiorCoating['show']) === 1) {
            elements.push(this.renderInteriorCoating(interiorCoating['lock']));
        }

        return elements;
    }

    renderInteriorPaperCard() {
        const interiorPaperElements = this.renderInteriorPaperElements();

        if (interiorPaperElements === null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Paper Interior
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {interiorPaperElements}
                    </div>
                </div>
            </div>
        );
    }

    renderCoverPaperElements() {
        const { configuration, formValues, options } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        const coverPaperCategoryId = configuration['paper_options']['cover_paper_category_id'];
        const coverPaperWeight = configuration['paper_options']['cover_paper_weight'];
        const coverPaperId = configuration['paper_options']['cover_paper_id'];
        const frontCoating = configuration['paper_options']['front_coating'];
        const backCoating = configuration['paper_options']['back_coating'];
        if (parseInt(coverPaperCategoryId['show']) === 0 && parseInt(coverPaperWeight['show']) === 0
            && parseInt(coverPaperId['show']) === 0 && parseInt(frontCoating['show']) === 0
            && parseInt(backCoating['show']) === 0) {
            return null;
        }

        let paperOptions = [];
        if (formValues['estimate_front_coating'] !== '' && formValues['estimate_back_coating'] !== '') {
            for (let i = 0; i < options['coverPapers'].length; i++) {
                if (options['coverPapers'][i]['coating_one_side'] === 0) {
                    paperOptions.push(options['coverPapers'][i]);
                }
            }
        } else {
            paperOptions = options['coverPapers'];
        }

        let elements = [];
        if (parseInt(coverPaperCategoryId['show']) === 1) {
            elements.push(this.renderSelect('Paper Category', 'estimate_cover_paper_category_id', options['coverPaperCategories'], true,
                coverPaperCategoryId['lock'], 'col', 'coverPaperCategories'));
        }
        if (parseInt(coverPaperWeight['show']) === 1) {
            elements.push(this.renderSelect('Paper Weight', 'estimate_cover_paper_weight', options['coverPaperWeights'], true,
                coverPaperWeight['lock'], 'col', 'coverPaperWeights'));
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(coverPaperId['show']) === 1) {
            elements.push(this.renderSelect('Paper', 'estimate_cover_paper_id', paperOptions, true,
                coverPaperId['lock'], 'col', 'coverPapers'));
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(frontCoating['show']) === 1) {
            elements.push(this.renderFrontCoatings(frontCoating['lock']));
        }
        if (parseInt(backCoating['show']) === 1) {
            elements.push(this.renderBackCoatings(backCoating['lock']));
        }

        return elements;
    }

    renderCoverPaperCard() {
        const coverPaperElements = this.renderCoverPaperElements();
        if (coverPaperElements === null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Paper Cover
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {coverPaperElements}
                    </div>
                </div>
            </div>
        );
    }

    renderBinderyElements() {
        const { configuration, options } = this.props;

        const drillingHoles = configuration['bindery_options']['drilling_holes'];
        const shrinkWrappingTypeId = configuration['bindery_options']['shrink_wrapping_type_id'];
        if (parseInt(drillingHoles['show']) === 0 && parseInt(shrinkWrappingTypeId['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(drillingHoles['show']) === 1) {
            elements.push(
                this.renderSelect('Drilling Holes', 'estimate_drilling_holes', [
                    { label: 'None', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 }
                ], false, drillingHoles['lock'])
            );
        }
        if (parseInt(shrinkWrappingTypeId['show']) === 1) {
            elements.push(this.renderSelect('Shrink-Wrapping', 'estimate_shrink_wrapping_type_id', options['shrinkWrappingTypes'], false, shrinkWrappingTypeId['lock']));
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderBinderyOptionsCard() {
        const binderyElements = this.renderBinderyElements();

        if (binderyElements === null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Bindery Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {binderyElements}
                        <div className="w-100"></div>
                    </div>
                </div>
            </div>
        );
    }

    renderHandlingElements() {
        const { configuration, options } = this.props;

        const bandingTypeId = configuration['handling_options']['banding_type_id'];
        const wrappingTypeId = configuration['handling_options']['wrapping_type_id'];
        const packageId = configuration['handling_options']['package_id'];
        const useHandGathering = configuration['handling_options']['use_hand_gathering'];
        if (parseInt(bandingTypeId['show']) === 0 && parseInt(wrappingTypeId['show']) === 0
            && parseInt(packageId['show']) === 0 && parseInt(useHandGathering['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(bandingTypeId['show']) === 1) {
            elements.push(this.renderSelect('Banding', 'estimate_banding_type_id', options['bandingTypes'], false, bandingTypeId['lock']));
        }
        if (parseInt(wrappingTypeId['show']) === 1) {
            elements.push(this.renderSelect('Wrapping', 'estimate_wrapping_type_id', options['wrappingTypes'], false, wrappingTypeId['lock']));
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(packageId['show']) === 1) {
            elements.push(this.renderSelect('Package', 'estimate_package_id', options['packages'], false, packageId['lock']));
        }
        if (parseInt(useHandGathering['show']) === 1) {
            elements.push(
                this.renderSelect('Hand Gathering', 'estimate_use_hand_gathering', [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ], false, useHandGathering['lock'])
            );
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderHandlingOptionsCard() {
        const handlingElements = this.renderHandlingElements();

        if (handlingElements === null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Handling Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {handlingElements}
                    </div>
                </div>
            </div>
        );
    }

    renderImpositions() {
        const { estimateResults, formValues } = this.props;

        let numberUp = null;
        let numberOut = null;
        let imposition = null;
        if (estimateResults.length > 0 && estimateResults[0]['results'].length > 0) {
            let results = estimateResults[0]['results'];
            if (parseInt(formValues['estimate_type']) === 2) {
                let impositions = [];
                if (this.hasCover()) {
                    impositions.push(
                        <div>
                            <div className="imposition-name">Cover:</div>
                            <FlatDrawImposition
                                result={estimateResults[0]['cover_results'][0]}
                            />
                        </div>
                    );
                }

                for (let i = 0; i < results[0]['impositions_information'].length; i++) {
                    let impositionName = results[0]['impositions_information'][i]['paper_information']['imposition_name']
                        + ' (' + results[0]['impositions_information'][i]['paper_information']['imposition_out'] + '-Up)';
                    impositions.push(
                        <div>
                            <div className="imposition-name">{impositionName}</div>
                            <MultiPageDrawImposition
                                imposition={results[0]['impositions_information'][i]}
                                quoteInformation={estimateResults[0]['quote_information']}
                            />
                        </div>
                    );
                }

                imposition = (
                    <div className="col">
                        {impositions}
                    </div>
                );
            } else {
                imposition = (
                    <div className="col">
                        <FlatDrawImposition
                            result={results[0]}
                        />
                    </div>
                );
            }
        }

        return (
            <div className="card mb-2 mr-1 pb-2">
                <div className="card-header">
                    Imposition
                </div>
                <div className="card-body pt-1 pb-0">
                    <div className="row">
                        {numberUp}
                        <div className="w-100"></div>
                        {numberOut}
                        <div className="w-100"></div>
                        {imposition}
                    </div>
                </div>
            </div>
        );
    }

    renderCoilOptionsElements() {
        const { configuration, formValues } = this.props;

        if (![6, 7].includes(parseInt(formValues['estimate_multi_page_type']))) {
            return null;
        }

        const coilOption = configuration['product_options']['coil_option'];
        const coilColor = configuration['product_options']['coil_color'];
        if (parseInt(coilOption['show']) === 0 && parseInt(coilColor['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(coilOption['show']) === 1) {
            elements.push(
                this.renderSelect('Coil Diameter - Sheet Cap. - Thickness', 'estimate_coil_option', [
                    { label: 'Select option', value: '' },
                    { label: '5/16 - 60 sht - 1/4"', value: '5/16 - 60 sht - 1/4"' },
                    { label: '3/8 - 75 sht - 5/16"', value: '3/8 - 75 sht - 5/16"' },
                    { label: '7/16 - 90 sht - 3/8"', value: '7/16 - 90 sht - 3/8"' },
                    { label: '1/2 - 105 sht - 7/16"', value: '1/2 - 105 sht - 7/16"' },
                    { label: '9/16 - 120 sht - 1/2"', value: '9/16 - 120 sht - 1/2"' },
                    { label: '5/8 - 135 sht - 9/16"', value: '5/8 - 135 sht - 9/16"' },
                    { label: '3/4 - 160 sht - 5/8"', value: '3/4 - 160 sht - 5/8"' },
                    { label: '7/8 - 190 sht - 3/4"', value: '7/8 - 190 sht - 3/4"' },
                    { label: '1 - 220 sht - 7/8"', value: '1 - 220 sht - 7/8"' }
                ], false, coilOption['lock'])
            );
        }
        elements.push(<div className="w-100"></div>);
        if (parseInt(coilColor['show']) === 1) {
            elements.push(
                this.renderSelect('Coil Color', 'estimate_coil_color', [
                    { label: 'Select option', value: '' },
                    { label: 'Black', value: 'Black' },
                    { label: 'Blue', value: 'Blue' },
                    { label: 'Clear', value: 'Clear' },
                    { label: 'White', value: 'White' },
                    { label: 'Red', value: 'Red' },
                    { label: 'Navy', value: 'Navy' },
                    { label: 'Maroon', value: 'Maroon' }
                ], false, coilColor['lock'])
            );
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderCoilOptionsCard() {
        const coilOptionsElements = this.renderCoilOptionsElements();

        if (coilOptionsElements === null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Coil Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {coilOptionsElements}
                    </div>
                </div>
            </div>
        );
    }

    renderStaplingOptionsElements() {
        const { configuration, formValues } = this.props;

        if (![4, 5].includes(parseInt(formValues['estimate_multi_page_type']))) {
            return null;
        }

        const staplesNumber = configuration['product_options']['staples_number'];
        const staplesLocation = configuration['product_options']['staples_location'];
        if (parseInt(staplesNumber['show']) === 0 && parseInt(staplesLocation['show']) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(staplesNumber['show']) === 1) {
            elements.push(
                this.renderSelect('Number of Staples', 'estimate_staples_number', [
                    { label: 'Select option', value: '' },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 }
                ], false, staplesNumber['lock'])
            );
        }
        if (parseInt(staplesLocation['show']) === 1) {
            elements.push(
                this.renderSelect('Location', 'estimate_staples_location', [
                    { label: 'Select option', value: '' },
                    { label: 'Left Side', value: 'Left Side' },
                    { label: 'Top left corner', value: 'Top left corner' }
                ], false, staplesLocation['lock'])
            );
        }

        elements.push(<div className="w-100"></div>);
        return elements;
    }

    renderStaplingOptionsCard() {
        const staplingOptionsElements = this.renderStaplingOptionsElements();

        if (staplingOptionsElements == null) {
            return null;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Stapling Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {staplingOptionsElements}
                    </div>
                </div>
            </div>
        );
    }

    renderProductionNotesElements() {
        const { configuration, handleUpdateFormValue, formValues, options } = this.props;

        const productionNotes = configuration['production_notes'];
        const showDiscountTable = configuration['customer_discount'] && configuration['customer_discount']['show'] ? configuration['customer_discount']['show'] : 1;
        const lockDiscountTable = configuration['customer_discount'] && configuration['customer_discount']['lock'] ? configuration['customer_discount']['lock'] : 0;
        if (parseInt(productionNotes['show']) === 0 && parseInt(showDiscountTable) === 0) {
            return null;
        }

        let elements = [];
        if (parseInt(productionNotes['show']) === 1) {
            elements.push(<label>Production Notes:</label>);
            elements.push(
                <textarea name="estimate_notes" onChange={handleUpdateFormValue.bind(this, 'text', false)} style={{ height: 83 }} disabled={productionNotes['lock']}>
                    {formValues['estimate_notes']}
                </textarea>
            );
        }
        if (parseInt(showDiscountTable) === 1) {
            elements.push(this.renderSelect('Discount Table', 'estimate_customer_discount_id', options['customerDiscounts'], false, lockDiscountTable));
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Production Notes
                </div>
                <div className="card-body pt-2 pb-0 pl-2 pr-2">
                    {elements}
                </div>
            </div>
        );
    }

    render() {
        const { formValues, handleUpdateFormValue, estimateResults, information } = this.props;
        return (
            <form>
                <div className="row no-gutters mb-2">
                    <div className="col-4" style={{ color: '#0d47a1', fontWeight: 'bold' }}>PQN Estimate</div>
                </div>
                <div className="row no-gutters">
                    <div className="col">
                        {this.renderProductOptionsCard()}
                        {this.renderPressCard()}
                    </div>
                    <div className="col">
                        {this.renderCoverPaperCard()}
                        {this.renderInteriorPaperCard()}
                        {this.renderCoilOptionsCard()}
                        {this.renderStaplingOptionsCard()}
                        {this.renderBinderyOptionsCard()}
                        {this.renderHandlingOptionsCard()}
                        {this.renderProductionNotesElements()}
                    </div>
                    <div className="col">
                        {this.renderImpositions()}
                        <div className="w-100"></div>
                    </div>
                    <div className="w-100"></div>
                    <EstimatePricing
                        formValues={formValues}
                        estimateResults={estimateResults}
                        information={information}
                        handleUpdateFormValue={handleUpdateFormValue}
                        renderSheets
                    />
                </div>
            </form>
        );
    }
}

export default EstimateMultiPage;
