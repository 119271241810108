import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LargeFormatConfiguration extends Component {
    static propTypes = {
        configuration: PropTypes.array,
        hideConfiguration: PropTypes.func,
        handleUpdateConfiguration: PropTypes.func,
        isSavingForm: PropTypes.bool,
        handleSaveElement: PropTypes.func,
        handleAddConfiguration: PropTypes.func,
        handleDeleteConfiguration: PropTypes.func
    };

    renderProductCategories(type) {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [<option value="">Select Option</option>];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === type) {
                selectOptions.push(<option value={options['productCategories'][i]['value']}>{options['productCategories'][i]['label']}</option>);
            }
        }

        let show = configurationJson['product_options']['product_category_id']['show'];
        let value = configurationJson['product_options']['product_category_id']['default'];
        let lock = configurationJson['product_options']['product_category_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'product_category_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'product_category_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'product_category_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="product-category">Product Category:</label></div>
                <select id="product-category" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderProductDescription() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_description']['show'];
        let value = configurationJson['product_description']['default'];
        let lock = configurationJson['product_description']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_description', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_description', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_description', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="product-description">Product Description:</label></div>
                <input id="product-description" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderLargeFormatMachines() {
        const { information, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [<option value="">Select Option</option>];
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            selectOptions.push(<option value={information['largeFormatMachines'][i]['id']}>{information['largeFormatMachines'][i]['name']}</option>);
        }

        let show = configurationJson['product_options']['large_format_machine_id']['show'];
        let value = configurationJson['product_options']['large_format_machine_id']['default'];
        let lock = configurationJson['product_options']['large_format_machine_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'large_format_machine_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'large_format_machine_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'large_format_machine_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="large-format-machine-id">Large Format Machine:</label></div>
                <select id="large-format-machine-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderWidth() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['substrate_options']['flat_width']['show'];
        let value = configurationJson['substrate_options']['flat_width']['default'];
        let lock = configurationJson['substrate_options']['flat_width']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['substrate_options', 'flat_width', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['substrate_options', 'flat_width', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['substrate_options', 'flat_width', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="flat-width">Width:</label></div>
                <input id="flat-width" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderHeight() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['substrate_options']['flat_height']['show'];
        let value = configurationJson['substrate_options']['flat_height']['default'];
        let lock = configurationJson['substrate_options']['flat_height']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['substrate_options', 'flat_height', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['substrate_options', 'flat_height', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['substrate_options', 'flat_height', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="flat-height">Height:</label></div>
                <input id="flat-height" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderProductionNotes() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['production_notes']['show'];
        let value = configurationJson['production_notes']['default'];
        let lock = configurationJson['production_notes']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['production_notes', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['production_notes', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['production_notes', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="production-notes">Production Notes:</label></div>
                <input id="production-notes" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderUnits() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['substrate_options']['unit']['show'];
        let value = configurationJson['substrate_options']['unit']['default'];
        let lock = configurationJson['substrate_options']['unit']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['substrate_options', 'unit', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['substrate_options', 'unit', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['substrate_options', 'unit', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="unit">Unit:</label></div>
                <select id="unit" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Inches</option>
                    <option value="2">Feet</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderSubstrates() {
        const { information, configurationJson, handleUpdateConfiguration } = this.props;

        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            if (information['largeFormatMachines'][i]['id'] === configurationJson['product_options']['large_format_machine_id']['default']) {
                largeFormatMachine = information['largeFormatMachines'][i];
                break;
            }
        }

        let selectOptions = [<option value="">Select Option</option>];
        for (let i = 0; largeFormatMachine != null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            selectOptions.push(<option value={largeFormatMachine['activeSubstrates'][i]['id']}>{largeFormatMachine['activeSubstrates'][i]['name']}</option>);
        }

        let show = configurationJson['substrate_options']['substrate_id']['show'];
        let value = configurationJson['substrate_options']['substrate_id']['default'];
        let lock = configurationJson['substrate_options']['substrate_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['substrate_options', 'substrate_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['substrate_options', 'substrate_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['substrate_options', 'substrate_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="substrate-id">Substrate:</label></div>
                <select id="substrate-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderLaminates() {
        const { information, configurationJson, handleUpdateConfiguration } = this.props;

        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            if (information['largeFormatMachines'][i]['id'] === configurationJson['product_options']['large_format_machine_id']['default']) {
                largeFormatMachine = information['largeFormatMachines'][i];
                break;
            }
        }

        let substrate = null;
        for (let i = 0; largeFormatMachine !== null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            if (largeFormatMachine['activeSubstrates'][i]['id'] === configurationJson['substrate_options']['substrate_id']['default']) {
                substrate = largeFormatMachine['activeSubstrates'][i];
                break;
            }
        }

        let selectOptions = [<option value="">None</option>];
        for (let i = 0; substrate !== null && i < substrate['activeLaminates'].length; i++) {
            selectOptions.push(<option value={substrate['activeLaminates'][i]['id']}>{substrate['activeLaminates'][i]['name']}</option>);
        }

        let show = configurationJson['substrate_options']['laminate_id']['show'];
        let value = configurationJson['substrate_options']['laminate_id']['default'];
        let lock = configurationJson['substrate_options']['laminate_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['substrate_options', 'laminate_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['substrate_options', 'laminate_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['substrate_options', 'laminate_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="substrate-id">Laminate:</label></div>
                <select id="substrate-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderAccessories() {
        const { information, configurationJson, handleUpdateConfiguration, checkAccessory } = this.props;

        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            if (information['largeFormatMachines'][i]['id'] === configurationJson['product_options']['large_format_machine_id']['default']) {
                largeFormatMachine = information['largeFormatMachines'][i];
                break;
            }
        }

        let substrate = null;
        for (let i = 0; largeFormatMachine != null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            if (largeFormatMachine['activeSubstrates'][i]['id'] === configurationJson['substrate_options']['substrate_id']['default']) {
                substrate = largeFormatMachine['activeSubstrates'][i];
                break;
            }
        }

        let accessories = [];
        for (let i = 0; substrate != null && i < substrate['activeAccessories'].length; i++) {
            const accessory = substrate['activeAccessories'][i];

            let checked = false;
            if (configurationJson['accessories']['default'].includes(accessory['id'])) {
                checked = true;
            }
            accessories.push(
                <div className="col accessory-checkbox">
                    <input type="checkbox" checked={checked} onClick={checkAccessory.bind(this, accessory['id'])} /> {accessory['name']}
                </div>
            );
            accessories.push(<div className="w-100"></div>);
        }

        let show = configurationJson['accessories']['show'];
        let lock = configurationJson['accessories']['lock'];
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['accessories', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['accessories', 'lock'], value);

        let lockIcon = <div className="accessories-lock"><i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} /></div>;
        if (lock !== undefined && lock === 1) {
            lockIcon = <div className="accessories-lock"><i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} /></div>;
        }

        return (
            <div>
                <div style={{ marginLeft: 15 }}>
                    Show:
                    <select value={show} onChange={updateConfigurationShow} style={{ marginLeft: 5, width: 60 }}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                {lockIcon}
                {accessories}
            </div>
        );
    }

    renderCustomerDiscounts() {
        const { configurationJson, handleUpdateConfiguration, options } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['customerDiscounts'].length; i++) {
            selectOptions.push(<option value={options['customerDiscounts'][i]['value']}>{options['customerDiscounts'][i]['label']}</option>);
        }

        let show = 1;
        let value = '';
        let lock = false;
        if (configurationJson['customer_discount']) {
            show = configurationJson['customer_discount']['show'];
            value = configurationJson['customer_discount']['default'];
            lock = configurationJson['customer_discount']['lock'];
        }
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['customer_discount', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['customer_discount', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['customer_discount', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="package-id">Discount Table:</label></div>
                <select id="package-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    render() {
        const { handleSaveElement, isSavingForm, activeElement, setEditing, configurationJson } = this.props;

        if (configurationJson == null) {
            return (
                <div id="tables-container" className="production-container">
                    <div id="configuration-container" className="table-container table-hover">
                        <div className="form-container" style={{ maxHeight: 'none' }}>
                            <i className="fas fa-circle-notch fa-spin"></i> Loading...
                        </div>
                    </div>
                </div>
            );
        }

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let data = (
            <div>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col"><h4 className="mt-1 mb-2">{activeElement.name}</h4></div>
                            <div className="w-100"></div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Product Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderProductCategories(4)}
                                                <div className="w-100"></div>
                                                {this.renderProductDescription()}
                                                <div className="w-100"></div>
                                                {this.renderLargeFormatMachines()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header">
                                        Other Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderProductionNotes()}
                                                <div className="w-100"></div>
                                                {this.renderCustomerDiscounts()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Substate Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderWidth()}
                                                <div className="w-100"></div>
                                                {this.renderHeight()}
                                                <div className="w-100"></div>
                                                {this.renderUnits()}
                                                <div className="w-100"></div>
                                                {this.renderSubstrates()}
                                                <div className="w-100"></div>
                                                {this.renderLaminates()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Accessories
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderAccessories()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div>
                    <button key="save-button" className="btn btn-light btn-sm action-button"
                        onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>
                    <button key="close-button" className="btn btn-light btn-sm action-button-cancel"
                        onClick={setEditing.bind(this, false)} disabled={isSavingForm}>Close</button>
                </div>
            </div>
        );

        return (
            <div id="tables-container" className="production-container">
                <div id="configuration-container" className="table-container table-hover">
                    <div className="form-container" style={{ maxHeight: 'none' }}>{data}</div>
                </div>
            </div>
        );
    }
}

export default LargeFormatConfiguration;
