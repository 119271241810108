import React, { Component } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from './../../common/components/Select';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';
import apiUrls from './../apiUrls';
import { dateToTimezone } from './../../../utils';

class Order extends Component {
    renderInput(label, name, type, required, className = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'order-information-' + name} className={className}>
                <div className="label-column align-top bold"><label>{label}{required ? <span className="required">*</span> : null}</label></div>
                <div className="input-column">
                    <Input
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                    />
                </div>
            </div>
        );
    }

    renderTextArea(label, name, required, className = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'order-information-' + name} className={className}>
                <div className="label-column bold"><label>{label}{required ? <span className="required">*</span> : null}</label></div>
                <div className="input-column">
                    <TextArea
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    renderSelect(label, name, options, required, className = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div key={'order-information-' + name} className={className}>
                <div className="label-column bold"><label>{label}{required ? <span className="required">*</span> : null}</label></div>
                <div className="input-column">
                    <Select
                        name={name}
                        required={required}
                        options={options}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    getMailingNames(estimate, name, optionsName) {
        const { options } = this.props;

        if (estimate[name] == null) {
            return null;
        }

        let names = [];
        let elements = JSON.parse(estimate[name]);
        for (let i = 0; i < elements.length; i++) {
            let selectedElement = null;
            for (let j = 0; j < options[optionsName].length; j++) {
                let element = options[optionsName][j]['element'];
                if (element && element != null && parseInt(element['id']) === parseInt(elements[i]['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement != null) {
                names.push(selectedElement['name']);
            }
        }
        return names;
    }

    renderPrintingEstimates() {
        const { information, handleShowDelete, handleShowForm, formValues, handleChangeEstimateHaveArtworks,
            handleChangeEstimateStatus, handleShowDeleteFile, handleShowUploadArtworks } = this.props;
        const rows = information['estimates'].map((estimate, index) => {
            let pressNames = [];
            let digitalMachineName = null;
            let largeFormatMachineName = null;
            let paperNames = [];
            let inkNames = [];
            let foldingNames = [];
            let bindingName = null;
            let substrateName = null;
            let laminateName = null;
            let digitalSides = null;
            let accessoriesNames = [];
            let envelopePressName = null;
            let envelopePaperName = null;
            let quantity = null;
            let total = null;
            let shippingRow = null;

            let estimateResult = estimate['estimateResults'][0];
            total = estimateResult['subtotal'] + estimateResult['discount'];

            if (parseInt(estimate['type']) === 1) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                inkNames.push(estimate['front_inks'] + '/' + estimate['back_inks']);
                foldingNames = estimate['folding_machine_name'];

                quantity = estimateResult['quantity'];
            } else if (parseInt(estimate['type']) === 2) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];
                bindingName = estimate['binding_machine_name'];

                let auxInkName = estimate['interior_inks'] + '/' + estimate['interior_inks'];
                if (!inkNames.includes(auxInkName)) {
                    inkNames.push(auxInkName);
                }

                if ([2, 3, 6, 7].includes(parseInt(estimate['multi_page_type']))) {
                    auxInkName = estimate['front_inks'] + '/' + estimate['back_inks'];
                    if (!inkNames.includes(auxInkName)) {
                        inkNames.push(auxInkName);
                    }
                }

                quantity = estimateResult['quantity'];
            } else if (parseInt(estimate['type']) === 3) {
                digitalMachineName = estimate['digital_machine_name'];
                digitalSides = estimate['digital_sides'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];

                quantity = estimateResult['quantity'];
            } else if (parseInt(estimate['type']) === 4) {
                largeFormatMachineName = estimate['large_format_machine_name'];
                substrateName = estimate['substrate_name'];
                laminateName = estimate['laminate_name'];
                accessoriesNames = estimate['accessories_names'];

                quantity = estimateResult['quantity'];
                if (formValues['shipping_method'] != null && formValues['shipping_method'] !== '') {
                    shippingRow = <div style={{ color: '#f44336' }}>Shipping to be determined</div>;
                }
            } else if (parseInt(estimate['type']) === 15) {     // Envelopes
                envelopePressName = estimate['envelope_press_name'];
                envelopePaperName = estimate['envelope_paper_name'];
                quantity = estimateResult['quantity'];
            } else {
                return null;
            }

            let pressRow = null;
            if (pressNames != null && pressNames.length > 0) {
                pressRow = <div>Press: {pressNames.join(', ')}</div>;
            }

            let digitalMachineRow = null;
            if (digitalMachineName != null) {
                digitalMachineRow = <div>Digital Machine: {digitalMachineName}</div>;
            }

            let inkRow = null;
            if (inkNames != null && inkNames.length > 0) {
                inkRow = <div>Inks: {inkNames.join(', ')}</div>;
            }

            let paperRow = null;
            if (paperNames != null && paperNames.length > 0) {
                paperRow = <div>Paper: {paperNames.join(', ')}</div>;
            }

            let foldingRow = null;
            if (foldingNames != null && foldingNames.length > 0) {
                foldingRow = <div>Folding: {foldingNames.join(', ')}</div>;
            }

            let bindingRow = null;
            if (bindingName != null) {
                bindingRow = <div>Binding: {bindingName}</div>;
            }

            let largeFormatMachineRow = null;
            if (largeFormatMachineName != null) {
                largeFormatMachineRow = <div>Large Format Machine: {largeFormatMachineName}</div>;
            }

            let substrateRow = null;
            if (substrateName != null) {
                substrateRow = <div>Substrate: {substrateName}</div>;
            }

            let laminateRow = null;
            if (laminateName != null) {
                laminateRow = <div>Laminate: {laminateName}</div>;
            }

            let digitalSidesRow = null;
            if (digitalSides != null) {
                digitalSidesRow = <div>Sides: {digitalSides}</div>;
            }

            let accessoriesRow = null;
            if (accessoriesNames != null && accessoriesNames.length > 0) {
                accessoriesRow = <div>Accessories: {accessoriesNames.join(', ')}</div>;
            }

            let envelopePressRow = null;
            if (envelopePressName != null) {
                envelopePressRow = <div>Envelope Press: {envelopePressName}</div>;
            }

            let envelopePaperRow = null;
            if (envelopePaperName != null) {
                envelopePaperRow = <div>Envelope Paper: {envelopePaperName}</div>;
            }

            let estimateFilesLabel = <div>No Artworks Uploaded</div>;
            let estimateFilesThumbnails = [];
            let estimateFiles = [];
            if (estimate['estimateFiles'].length > 0) {
                for (let i = 0; i < estimate['estimateFiles'].length; i++) {
                    if (estimate['estimateFiles'][i]['thumbnail_url']) {
                        estimateFilesThumbnails.push(
                            <div className="artwork-link">
                                <a href={apiUrls.downloadFile + '?id=' + estimate['estimateFiles'][i]['id']}>
                                    <img className="thumbnail" src={estimate['estimateFiles'][i]['thumbnail_url']} alt="Artwork" />
                                </a>
                                <i className="fas fa-trash float-trash" onClick={handleShowDeleteFile.bind(this, estimate['estimateFiles'][i]['id'])} />
                            </div>
                        );
                    } else {
                        estimateFiles.push(
                            <li className="artwork-li">
                                <a href={apiUrls.downloadFile + '?id=' + estimate['estimateFiles'][i]['id']}>
                                    {estimate['estimateFiles'][i]['filename']}
                                </a>
                                <i className="fas fa-trash" onClick={handleShowDeleteFile.bind(this, estimate['estimateFiles'][i]['id'])} />
                            </li>
                        );
                    }
                }
                estimateFilesLabel = (
                    <div>
                        <div>Uploaded Artworks:</div>
                        <ol>{estimateFiles}</ol>
                        <div>{estimateFilesThumbnails}</div>
                    </div>
                );
            }

            let approvedBy = null;
            if (estimate['approved_by_name'] != null && estimate['approved_by_name'] !== '') {
                approvedBy = <div>Approved By: {estimate['approved_by_name']}</div>;
            }

            let approvedAt = null;
            if (estimate['approved_at'] != null && estimate['approved_at'] !== '') {
                approvedAt = <div>Approved At: {moment(estimate['approved_at']).format('MM/DD/YYYY')}</div>;
            }

            return (
                <tr key={index}>
                    <td>
                        <div className="bold"><b>{estimate['product_category_name']}</b></div>
                        <div className="bold">{estimate['product_description']}</div>
                    </td>
                    <td>{estimate['notes']}</td>
                    <td className="artworks">
                        <input type="checkbox" checked={estimate['have_artworks']}
                            onClick={handleChangeEstimateHaveArtworks.bind(this, estimate['id'], index)} /> We have the artworks
                        {estimateFilesLabel}
                    </td>
                    <td className="approval">
                        Status: <br />
                        <select value={estimate['status_id']} onChange={handleChangeEstimateStatus.bind(this, estimate['id'], index)}>
                            <option value="1">Waiting</option>
                            <option value="2">Approved</option>
                            <option value="3">Rejected</option>
                        </select>
                        {approvedBy}
                        {approvedAt}
                    </td>
                    <td>
                        {pressRow}
                        {digitalMachineRow}
                        {largeFormatMachineRow}
                        {paperRow}
                        {digitalSidesRow}
                        {inkRow}
                        {foldingRow}
                        {bindingRow}
                        {substrateRow}
                        {laminateRow}
                        {accessoriesRow}
                        {shippingRow}
                        {envelopePressRow}
                        {envelopePaperRow}
                    </td>
                    <td className="price text-right">
                        <div>{numeral(quantity).format('0,0')}</div>
                        <div>{numeral(total).format('$0,0.00')}</div>
                    </td>
                    <td className="actions" style={{ width: 110 }}>
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Upload Artworks" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-file-upload" onClick={handleShowUploadArtworks.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleShowDelete.bind(this, estimate['id'])} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
        return rows.filter(row => row !== null);
    }

    renderMailingEstimates() {
        const { information, handleShowDelete, handleShowForm, handleShowDeleteFile, handleChangeEstimateHaveArtworks,
            handleShowUploadArtworks, handleChangeEstimateStatus } = this.props;
        const rows = information['estimates'].map((estimate, index) => {
            let dataProcessingDescriptions = [];
            let dataProcessingQuantities = [];
            let dataProcessingPrices = [];
            let inkjetDescriptions = [];
            let inkjetQuantities = [];
            let inkjetPrices = [];
            let insertingDescriptions = [];
            let insertingQuantities = [];
            let insertingPrices = [];
            let artDescriptions = [];
            let artQuantities = [];
            let artPrices = [];
            let handworkDescriptions = [];
            let handworkQuantities = [];
            let handworkPrices = [];
            let pressureSealDescriptions = [];
            let pressureSealQuantities = [];
            let pressureSealPrices = [];
            let indexTabDescriptions = [];
            let indexTabQuantities = [];
            let indexTabPrices = [];
            let labelDescriptions = [];
            let labelQuantities = [];
            let labelPrices = [];
            let ncrFormDescriptions = [];
            let ncrFormQuantities = [];
            let ncrFormPrices = [];
            let total = null;

            let estimateResult = estimate['estimateResults'][0];
            total = estimateResult['subtotal'] + estimateResult['discount'];

            if (parseInt(estimate['type']) === 5) {
                for (let i = 0; i < estimate['data_processing_elements'].length; i++) {
                    dataProcessingDescriptions.push(<div>{estimate['data_processing_elements'][i]['name']}</div>);
                    dataProcessingQuantities.push(<div>{numeral(estimate['data_processing_elements'][i]['quantity']).format('0,0')}</div>);
                    dataProcessingPrices.push(<div>{numeral(estimate['data_processing_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 6) {
                for (let i = 0; i < estimate['inkjet_elements'].length; i++) {
                    inkjetDescriptions.push(<div>{estimate['inkjet_elements'][i]['name']}</div>);
                    inkjetQuantities.push(<div>{numeral(estimate['inkjet_elements'][i]['quantity']).format('0,0')}</div>);
                    inkjetPrices.push(<div>{numeral(estimate['inkjet_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 7) {
                for (let i = 0; i < estimate['inserting_elements'].length; i++) {
                    insertingDescriptions.push(<div>{estimate['inserting_elements'][i]['name']}</div>);
                    insertingQuantities.push(<div>{numeral(estimate['inserting_elements'][i]['quantity']).format('0,0')}</div>);
                    insertingPrices.push(<div>{numeral(estimate['inserting_elements'][i]['price']).format('$0,0.00')}</div>);
                }
                for (let i = 0; i < estimate['perfect_match_elements'].length; i++) {
                    insertingDescriptions.push(<div>{estimate['perfect_match_elements'][i]['name']}</div>);
                    insertingQuantities.push(<div>{numeral(estimate['perfect_match_elements'][i]['quantity']).format('0,0')}</div>);
                    insertingPrices.push(<div>{numeral(estimate['perfect_match_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 9) {
                for (let i = 0; i < estimate['art_elements'].length; i++) {
                    artDescriptions.push(<div>{estimate['art_elements'][i]['name']}</div>);
                    artQuantities.push(<div>{numeral(estimate['art_elements'][i]['quantity']).format('0,0')}</div>);
                    artPrices.push(<div>{numeral(estimate['art_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 10) {
                for (let i = 0; i < estimate['handwork_elements'].length; i++) {
                    handworkDescriptions.push(<div>{estimate['handwork_elements'][i]['name']}</div>);
                    handworkQuantities.push(<div>{numeral(estimate['handwork_elements'][i]['quantity']).format('0,0')}</div>);
                    handworkPrices.push(<div>{numeral(estimate['handwork_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 11) {
                for (let i = 0; i < estimate['pressure_seal_elements'].length; i++) {
                    pressureSealDescriptions.push(<div>{estimate['pressure_seal_elements'][i]['name']}</div>);
                    pressureSealQuantities.push(<div>{numeral(estimate['pressure_seal_elements'][i]['quantity']).format('0,0')}</div>);
                    //pressureSealPrices.push(<div>{numeral(estimate['pressure_seal_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 12) {
                for (let i = 0; i < estimate['index_tab_elements'].length; i++) {
                    indexTabDescriptions.push(<div>{estimate['index_tab_elements'][i]['name']}</div>);
                    indexTabQuantities.push(<div>{numeral(estimate['index_tab_elements'][i]['quantity']).format('0,0')}</div>);
                    indexTabPrices.push(<div>{numeral(estimate['index_tab_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 13) {
                for (let i = 0; i < estimate['label_elements'].length; i++) {
                    labelDescriptions.push(<div>{estimate['label_elements'][i]['name']}</div>);
                    labelQuantities.push(<div>{numeral(estimate['label_elements'][i]['quantity']).format('0,0')}</div>);
                    labelPrices.push(<div>{numeral(estimate['label_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 14) {
                for (let i = 0; i < estimate['ncr_form_elements'].length; i++) {
                    ncrFormDescriptions.push(<div>{estimate['ncr_form_elements'][i]['name']}</div>);
                    ncrFormQuantities.push(<div>{numeral(estimate['ncr_form_elements'][i]['quantity']).format('0,0')}</div>);
                    ncrFormPrices.push(<div>{numeral(estimate['ncr_form_elements'][i]['price']).format('$0,0.00')}</div>);
                }
            } else {
                return null;
            }

            let estimateFilesLabel = <div>No Artworks Uploaded</div>;
            let estimateFilesThumbnails = [];
            let estimateFiles = [];
            if (estimate['estimateFiles'].length > 0) {
                for (let i = 0; i < estimate['estimateFiles'].length; i++) {
                    if (estimate['estimateFiles'][i]['thumbnail_url']) {
                        estimateFilesThumbnails.push(
                            <div className="artwork-link">
                                <a href={apiUrls.downloadFile + '?id=' + estimate['estimateFiles'][i]['id']}>
                                    <img className="thumbnail" src={estimate['estimateFiles'][i]['thumbnail_url']} alt="Artwork" />
                                </a>
                                <i className="fas fa-trash float-trash" onClick={handleShowDeleteFile.bind(this, estimate['estimateFiles'][i]['id'])} />
                            </div>
                        );
                    } else {
                        estimateFiles.push(
                            <li className="artwork-li">
                                <a href={apiUrls.downloadFile + '?id=' + estimate['estimateFiles'][i]['id']}>
                                    {estimate['estimateFiles'][i]['filename']}
                                </a>
                                <i className="fas fa-trash" onClick={handleShowDeleteFile.bind(this, estimate['estimateFiles'][i]['id'])} />
                            </li>
                        );
                    }
                }
                estimateFilesLabel = (
                    <div>
                        <div>Uploaded Artworks:</div>
                        <ol>{estimateFiles}</ol>
                        <div>{estimateFilesThumbnails}</div>
                    </div>
                );
            }

            let approvedBy = null;
            if (estimate['approved_by_name'] != null && estimate['approved_by_name'] !== '') {
                approvedBy = <div>Approved By: {estimate['approved_by_name']}</div>;
            }

            let approvedAt = null;
            if (estimate['approved_at'] != null && estimate['approved_at'] !== '') {
                approvedAt = <div>Approved At: {moment(estimate['approved_at']).format('MM/DD/YYYY')}</div>;
            }

            return (
                <tr key={index}>
                    <td>
                        <div className="bold"><b>{estimate['product_category_name']}</b></div>
                        <div className="bold">{estimate['product_description']}</div>
                    </td>
                    <td>{estimate['notes']}</td>
                    <td className="artworks">
                        <input type="checkbox" checked={estimate['have_artworks']}
                            onClick={handleChangeEstimateHaveArtworks.bind(this, estimate['id'], index)} /> We have the artworks
                        {estimateFilesLabel}
                    </td>
                    <td className="approval">
                        Status: <br />
                        <select value={estimate['status_id']} onChange={handleChangeEstimateStatus.bind(this, estimate['id'], index)}>
                            <option value="1">Waiting</option>
                            <option value="2">Approved</option>
                            <option value="3">Rejected</option>
                        </select>
                        {approvedBy}
                        {approvedAt}
                    </td>
                    <td>
                        {dataProcessingDescriptions}
                        {inkjetDescriptions}
                        {insertingDescriptions}
                        {artDescriptions}
                        {handworkDescriptions}
                        {pressureSealDescriptions}
                        {indexTabDescriptions}
                        {labelDescriptions}
                        {ncrFormDescriptions}
                    </td>
                    <td>
                        {dataProcessingQuantities}
                        {inkjetQuantities}
                        {insertingQuantities}
                        {artQuantities}
                        {handworkQuantities}
                        {pressureSealQuantities}
                        {indexTabQuantities}
                        {labelQuantities}
                        {ncrFormQuantities}
                    </td>
                    <td className="text-right" style={{ width: 120 }}>
                        {dataProcessingPrices}
                        {inkjetPrices}
                        {insertingPrices}
                        {artPrices}
                        {handworkPrices}
                        {pressureSealPrices}
                        {indexTabPrices}
                        {labelPrices}
                        {ncrFormPrices}
                        <div><b>{numeral(total).format('$0,0.00')}</b></div>
                    </td>
                    <td className="actions" style={{ width: 110 }}>
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Upload Artworks" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-file-upload" onClick={handleShowUploadArtworks.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleShowDelete.bind(this, estimate['id'])} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
        return rows.filter(row => row !== null);
    }

    renderPrintingTotals() {
        const { information, shipping } = this.props;

        let totals = null;
        if (information['estimates'].length > 0) {
            let subtotal = 0;
            let customerDiscount = 0;
            let orderDiscount = 0;
            let rushFee = 0;
            let tax = 0;
            let totalPrice = 0;
            for (let i = 0; i < information['estimates'].length; i++) {
                if (![1, 2, 3, 4, 15].includes(parseInt(information['estimates'][i]['type']))) {
                    continue;
                }

                subtotal += information['estimates'][i]['estimateResults'][0]['subtotal'] + information['estimates'][i]['estimateResults'][0]['discount'];
                customerDiscount += information['estimates'][i]['estimateResults'][0]['customer_discount'];
                orderDiscount += information['estimates'][i]['estimateResults'][0]['order_discount'];
                rushFee += information['estimates'][i]['estimateResults'][0]['rush_fee'];
                tax += information['estimates'][i]['estimateResults'][0]['tax'];
                totalPrice += information['estimates'][i]['estimateResults'][0]['total'];
            }

            let shippingRow = null;
            let shippingTotal = 0;
            if (shipping['isCalculating']) {
                shippingRow = <div><i className="fas fa-circle-notch fa-spin" /></div>;
            } else if (shipping['total'] == null) {
                shippingRow = <div className="calculate-shipping">N/A</div>;
            } else {
                shippingRow = <div>{numeral(shipping['total']).format('$0,0.00')}</div>;
                shippingTotal = shipping['total'];
            }

            totals = (
                <tr>
                    <td></td>
                    <td></td>
                    <td className="bold text-right" colSpan="3">
                        Subtotal <br />
                        Shipping <br />
                        {rushFee > 0 ? <div>Rush Fee</div> : null}
                        {tax > 0 ? <div>Tax</div> : null}
                        {customerDiscount > 0 ? <div>Customer Discount</div> : null}
                        {customerDiscount < 0 ? <div>Customer Markup</div> : null}
                        {orderDiscount > 0 ? <div>Order Discount</div> : null}
                        Total
                    </td>
                    <td className="text-right">
                        {numeral(subtotal).format('$0,0.00')} <br />
                        {shippingRow}
                        {rushFee > 0 ? <div>{numeral(rushFee).format('$0,0.00')}</div> : null}
                        {tax > 0 ? <div>{numeral(tax).format('$0,0.00')}</div> : null}
                        {customerDiscount > 0 ? <div>-{numeral(customerDiscount).format('$0,0.00')}</div> : null}
                        {customerDiscount < 0 ? <div>{numeral(-customerDiscount).format('$0,0.00')}</div> : null}
                        {orderDiscount > 0 ? <div>-{numeral(orderDiscount).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrice + shippingTotal).format('$0,0.00')}</b>
                    </td>
                    <td></td>
                </tr>
            );
        }
        return totals;
    }

    renderMailingTotals() {
        const { information } = this.props;

        let totals = null;
        if (information['estimates'].length > 0) {
            let subtotal = 0;
            let customerDiscount = 0;
            let orderDiscount = 0;
            let rushFee = 0;
            let tax = 0;
            let totalPrice = 0;
            for (let i = 0; i < information['estimates'].length; i++) {
                if ([1, 2, 3, 4, 15].includes(parseInt(information['estimates'][i]['type']))) {
                    continue;
                }

                subtotal += information['estimates'][i]['estimateResults'][0]['subtotal'] + information['estimates'][i]['estimateResults'][0]['discount'];
                customerDiscount += information['estimates'][i]['estimateResults'][0]['customer_discount'];
                orderDiscount += information['estimates'][i]['estimateResults'][0]['order_discount'];
                rushFee += information['estimates'][i]['estimateResults'][0]['rush_fee'];
                tax += information['estimates'][i]['estimateResults'][0]['tax'];
                totalPrice += information['estimates'][i]['estimateResults'][0]['total'];
            }

            totals = (
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="bold text-right" colSpan="3">
                        Subtotal <br />
                        {rushFee > 0 ? <div>Rush Fee</div> : null}
                        {tax > 0 ? <div>Tax</div> : null}
                        {customerDiscount > 0 ? <div>Customer Discount</div> : null}
                        {customerDiscount < 0 ? <div>Customer Markup</div> : null}
                        {orderDiscount > 0 ? <div>Order Discount</div> : null}
                        Total
                    </td>
                    <td className="text-right">
                        {numeral(subtotal).format('$0,0.00')} <br />
                        {rushFee > 0 ? <div>{numeral(rushFee).format('$0,0.00')}</div> : null}
                        {tax > 0 ? <div>{numeral(tax).format('$0,0.00')}</div> : null}
                        {customerDiscount > 0 ? <div>-{numeral(customerDiscount).format('$0,0.00')}</div> : null}
                        {customerDiscount < 0 ? <div>{numeral(-customerDiscount).format('$0,0.00')}</div> : null}
                        {orderDiscount > 0 ? <div>-{numeral(orderDiscount).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrice).format('$0,0.00')}</b>
                    </td>
                    <td></td>
                </tr>
            );
        }
        return totals;
    }

    renderCustomers() {
        const { information } = this.props;
        return information['customers'].map((customer, index) => {
            return <option key={index} value={customer['id']}>{customer['name']}</option>;
        });
    }

    render() {
        const { information, order, handleShowForm, formValues, handleSaveElement,
            isFetchingOrder, isFetchingInformation, isSavingForm, orderId, handleProduction, customerData,
            isSendingToProduction, options, handleUpdateFormDateValue } = this.props;

        if (isFetchingOrder || isFetchingInformation) {
            return (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin" /> Loading...
                </div>
            );
        }

        let shippingMethodOptions = [{ label: 'Select option', value: '' }, { label: 'Local Pickup', value: 'local-pickup' },
        { label: 'UPS Ground', value: 'S_GROUND' }, { label: 'UPS 3 Days Select', value: 'S_3DAYSELECT' },
        { label: 'UPS Second Day Air', value: 'S_AIR_2DAY' }, { label: 'UPS Second Day Air Early AM', value: 'S_AIR_2DAYAM' },
        { label: 'UPS Next Day Air', value: 'S_AIR_1DAY' }, { label: 'UPS Next Day Air Early AM', value: 'S_AIR_1DAYEARLYAM' },
        { label: 'FedDex Ground', value: 'FEDEX_GROUND' }, { label: 'FedDex Express Saver', value: 'FEDEX_EXPRESS_SAVER' },
        { label: 'FedDex Second Day', value: 'FEDEX_2_DAY' }, { label: 'FedDex Second Day AM', value: 'FEDEX_2_DAY_AM' },
        { label: 'FedDex Standard Overnight', value: 'STANDARD_OVERNIGHT' }, { label: 'FedDex Priority Overnight', value: 'PRIORITY_OVERNIGHT' },
        { label: 'FedDex First Overnight', value: 'FIRST_OVERNIGHT' }];

        let items = null;
        if (formValues['id'] !== '') {
            let productionButtonLabel = <div><i className="fas fa-industry" /> Production</div>;
            if (isSendingToProduction) {
                productionButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
            }

            let exportButton = null;
            if (formValues['id'] !== '') {
                exportButton = (
                    <div className="add-button float-right mr-2 mb-2"
                        onClick={() => window.open(apiUrls.exportPdf + '?id=' + orderId, '_blank')}>
                        <div><i className="far fa-file-pdf" /> Export PDF</div>
                    </div>
                );
            }

            let orderButton = null;
            orderButton = (
                <div className="add-button float-right mr-2 mb-2" onClick={handleProduction} style={{ width: 140 }}>
                    {productionButtonLabel}
                </div>
            );

            if (information['estimates'].length === 0) {
                orderButton = null;
                exportButton = null;
            }

            items = (
                <div className="items-container">
                    <div className="clearfix"></div>
                    <div className="printing-estimates-table">
                        <b>Printing Items:</b>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Items/Name</th>
                                    <th>Production Notes</th>
                                    <th>Artworks</th>
                                    <th>Status</th>
                                    <th>Process Description</th>
                                    <th className="text-right">Total Price</th>
                                    <th className="add">
                                        <div className="add-button float-right mr-0 mb-0" onClick={handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add Item</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderPrintingEstimates()}
                                {this.renderPrintingTotals()}
                            </tbody>
                        </table>
                    </div>
                    <div className="mailing-estimates-table">
                        <b>Mailing Items:</b>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Items/Name</th>
                                    <th>Production Notes</th>
                                    <th>Artworks</th>
                                    <th>Status</th>
                                    <th>Process Description</th>
                                    <th>Quantity</th>
                                    <th className="text-right">Total Price</th>
                                    <th className="add">
                                        <div className="add-button float-right mr-0 mb-0" onClick={handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add Item</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderMailingEstimates()}
                                {this.renderMailingTotals()}
                            </tbody>
                        </table>
                    </div>
                    {orderButton}
                    {exportButton}
                </div>
            );
        }

        let saveButtonLabel = <div><i className="fas fa-save" /> Save</div>;
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let resellerId = null;
        let taxCode = null;
        let discountTable = null;
        let discountType = null;
        if (customerData != null) {
            if (customerData['reseller']) {
                resellerId = <div><b>Reseller ID:</b> {customerData == null ? null : customerData['reseller_id']}</div>;
                taxCode = <div><b>Tax Code:</b> {customerData == null ? null : customerData['tax_code']}</div>;
            }

            if (parseFloat(customerData['discount']) !== 0) {
                discountTable = <div><b>Discount Table:</b> {customerData['discountName']}</div>;
                discountType = <div><b>{customerData['discount'] < 0 ? 'Markup' : 'Discount'}:</b> {numeral(Math.abs(customerData['discount'])).format('0.[00]%')}</div>;
            }
        }

        let billingAddress = null;
        let shippingAddress = null;
        if (formValues['id'] === '' && customerData != null) {
            billingAddress = customerData['billingAddress']['street'] + ' '
                + customerData['billingAddress']['city'] + ', '
                + customerData['billingAddress']['state'] + ', '
                + customerData['billingAddress']['zip'];

            shippingAddress = customerData['shippingAddress']['street'] + ' '
                + customerData['shippingAddress']['city'] + ', '
                + customerData['shippingAddress']['state'] + ', '
                + customerData['shippingAddress']['zip'];
        } else if (formValues['id'] !== '' && order != null) {
            billingAddress = order['billingAddress']['street'] + ' '
                + order['billingAddress']['city'] + ', '
                + order['billingAddress']['state'] + ', '
                + order['billingAddress']['zip'];

            shippingAddress = order['shippingAddress']['street'] + ' '
                + order['shippingAddress']['city'] + ', '
                + order['shippingAddress']['state'] + ', '
                + order['shippingAddress']['zip'];
        }

        const totalQuotes = customerData == null ? null : customerData['total_quotes'];
        const totalOrders = customerData == null ? null : customerData['total_orders'];
        return (
            <div className="order-container">
                <div className="statistics-container">
                    <div className="text-left title">Order Information</div>
                    <div className="text-right">Total Quotes: {totalQuotes} | Total Orders: {totalOrders} | In Production: N/A</div>
                </div>
                <div className="order-information-container">
                    <div className="order-information">
                        <div className="card mb-2">
                            <div className="card-header">Order Information</div>
                            <div className="card-body">
                                <div id="order-information" className="row">
                                    {this.renderInput('Title', 'name', 'text', true, 'col-8 title-field')}
                                    {this.renderInput('PO', 'customer_po', 'text', false, 'col-4 po-field')}
                                    <div className="w-100"></div>
                                    {this.renderSelect('CSR', 'customer_service_rep_id', options['customerServiceReps'], true, 'col csr-field')}
                                    {this.renderSelect('Sales Rep', 'sales_rep_id', options['salesReps'], true, 'col sales-field')}
                                    {this.renderSelect('Status', 'status_id', options['statuses'], true, 'col status-field')}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Turnaround', 'turnaround_id', options['turnaround'], true, 'col turnaround-field')}
                                    <div key="due-date" className="col mail-date-field">
                                        <div className="label-column align-top bold"><label>Mail Date</label><span className="required">*</span></div>
                                        <div className="input-column">
                                            <DayPickerInput
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                placeholder=""
                                                className="date-picker-field"
                                                onDayChange={handleUpdateFormDateValue.bind(this, 'due_date', true)}
                                                value={formValues['due_date'] == null || formValues['due_date'] === '0000-00-00' ? '' : moment(formValues['due_date']).format('MM/DD/YYYY')}
                                            />
                                        </div>
                                    </div>
                                    {this.renderSelect('Shipping Method', 'shipping_method', shippingMethodOptions, true, 'col shipping-method-field')}
                                    <div className="w-100"></div>
                                    <div className="completion-date">
                                        <div>Completion Date: N/A</div>
                                        <div>Number of days to Mail Date: N/A</div>
                                    </div>
                                    <div className="col">
                                        <div className="add-button float-right mb-0" onClick={handleSaveElement}>
                                            {saveButtonLabel}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-information">
                        <div className="card mb-2">
                            <div className="card-header">Customer Information</div>
                            <div className="card-body">
                                <div id="customer-information">
                                    <div><b>ID:</b> {customerData == null ? null : customerData['id']}</div>
                                    <div><b>Contact:</b> {customerData == null ? null : customerData['name']}</div>
                                    <div><b>Company:</b> {customerData == null ? null : customerData['display_name']}</div>
                                    <div><b>Email:</b> {customerData == null ? null : customerData['email']}</div>
                                    <div><b>Address:</b> {customerData == null ? null : customerData['street']}</div>
                                    <div><b>City:</b> {customerData == null ? null : customerData['city']}</div>
                                    <div><b>State:</b> {customerData == null ? null : customerData['state']} <b>Zip:</b> {customerData == null ? null : customerData['zip']}</div>
                                    <div><b>Phone:</b> {customerData == null ? null : customerData['phone']}</div>
                                    {discountTable}
                                    {discountType}
                                    {resellerId}
                                    {taxCode}
                                    <div><b>Billing Address:</b> {billingAddress}</div>
                                    <div><b>Shipping Address:</b> {shippingAddress}</div>
                                    <button style={{ width: 160, height: 30 }} onClick={this.props.showCustomersHandler}><i className="fas fa-user-cog"></i> Change Customer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="order-log">
                        <div className="card mb-2">
                            <div className="card-header">Order Log</div>
                            <div className="card-body">
                                <div><b>Order ID:</b> {order == null ? null : order['id']}</div>
                                <div><b>Created At:</b> {order == null ? null : dateToTimezone(order['created_at'], 'MM/DD/YYYY hh:mm a')}</div>
                                <div><b>Last Updated At:</b> {order == null ? null : dateToTimezone(order['updated_at'], 'MM/DD/YYYY hh:mm a')}</div>
                                <div><b>Created By:</b> {order == null ? null : order['created_by_name']}</div>
                                <div><b>Last Updated By:</b> {order == null ? null : order['updated_by_name']}</div>
                                <div><b>Price Matched:</b> N/A</div>
                                <div><b>Original Price:</b> N/A</div>
                                <div><b>Approved By:</b> N/A</div>
                                <div><b>Converted To Order At:</b> N/A</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100"></div>
                    {items}
                    <div className="w-100"></div>
                    {/*this.renderTextArea('Notes', 'notes', false, 'col notes-field')*/}
                    {/*this.renderTextArea('Private Notes', 'private_notes', false, 'col private-notes-field')*/}
                    <div className="w-100"></div>
                </div>
            </div>
        );
    }
}

export default Order;
