import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const Presses = (props) => {
    const [fetchingPresses, setFetchingPresses] = useState(false);

    const productType = props.productType;
    const setPresses = props.setPresses;
    const noFetchPresses = props.noFetchPresses;
    useEffect(() => {
        if(noFetchPresses) {
            return;
        }
        
        if (parseInt(productType) !== 1 && parseInt(productType) !== 2) {
            return;
        }

        setFetchingPresses(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/presses',
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setPresses(response.data);
                setFetchingPresses(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingPresses(false);
            });
    }, [productType, setPresses, noFetchPresses]);

    const changeHandler = (event) => {
        props.setPressId(event.target.value);
        props.setRunningStyle('');

        props.setPaperCategories([]);
        props.setPaperCategoryId('');

        props.setPaperWeights([]);
        props.setPaperWeight('');

        props.setPapers([]);
        props.setPaperId('');
    };

    return (
        <div className="field">
            <div className="label">{props.label ? props.label : 'Press'}:</div>
            <select value={props.pressId} onChange={changeHandler}>
                <option value="">Select option</option>
                {props.presses.map((press) => <option key={press.id} value={press.id}>{press.name}</option>)}
            </select>
            {fetchingPresses && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default Presses;