import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getComponentState = state => state[NAME];

/** activeElement **/
export const getActiveElementModel = createSelector(
    getComponentState,
    (state) => state.activeElement
);
export const getActiveElement = createSelector(
    getComponentState,
    (state) => state.activeElement.data
);
export const getIsFetchingElement = createSelector(
    getComponentState,
    (state) => state.activeElement.isFetching
);

/** form **/
export const getForm = createSelector(
    getComponentState,
    (state) => state.form
);
export const getFormValues = createSelector(
    getComponentState,
    (state) => state.form.formValues
);
export const getIsSavingForm = createSelector(
    getComponentState,
    (state) => state.form.isSaving
);
export const getFormErrors = createSelector(
    getComponentState,
    (state) => state.form.formErrors
);
export const getFormErrorsMessages = createSelector(
    getComponentState,
    (state) => state.form.formErrorMessages
);

/** elements **/
export const getElements = createSelector(
    getComponentState,
    (state) => state.elements.data
);
export const getIsFetchingElements = createSelector(
    getComponentState,
    (state) => state.elements.isFetching
);

/** delete **/
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);

/** pagination **/
export const getTotalPages = createSelector(
    getComponentState,
    (state) => state.pagination.totalPages
);
export const getSelectedPage = createSelector(
    getComponentState,
    (state) => state.pagination.selectedPage
);
export const getElementsPerPage = createSelector(
    getComponentState,
    (state) => state.pagination.elementsPerPage
);

/** sort **/
export const getSortColumn = createSelector(
    getComponentState,
    (state) => state.sort.column
);
export const getSortType = createSelector(
    getComponentState,
    (state) => state.sort.type
);

/** statistics **/
export const getStatistics = createSelector(
    getComponentState,
    (state) => state.statistics.data
);
export const getIsLoadingStatistics = createSelector(
    getComponentState,
    (state) => state.statistics.isLoading
);