import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextArea extends Component
{
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        name: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        readOnly: PropTypes.bool
    };

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps['formValues'][nextProps['name']] !== this.props['formValues'][this.props['name']] ||
            nextProps['formErrors'][nextProps['name']] !== this.props['formErrors'][this.props['name']];
    }

    render() {
        const { handleUpdateFormValue, formValues, formErrors, name, label, required, formErrorMessages,
            readOnly } = this.props;

        let errorMessage = 'This field is required';
        let hasErrorMessage = false;
        if(formErrorMessages && formErrorMessages[name] && formErrorMessages[name] !== '') {
            errorMessage = formErrorMessages[name];
            hasErrorMessage = true;
        }

        return (
            <div>
                <textarea value={formValues[name]} id={name} name={name} placeholder={label}
                       onChange={handleUpdateFormValue.bind(this)} readOnly={readOnly} />
                {required || hasErrorMessage ? <div className="error-message" hidden={!formErrors[name]}>{errorMessage}</div> : null}
            </div>
        );
    }
}

export default TextArea;
