import React, { Component } from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';

class Table extends Component {
    static propTypes = {
        statistics: PropTypes.object,
        isLoadingStatistics: PropTypes.bool
    };

    render() {
        const { statistics, isLoadingStatistics } = this.props;

        let content = null;
        if (isLoadingStatistics || statistics == null) {
            content = <div><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        } else {
            content = (
                <div className="row">
                    <div className="col">
                        <div className="clearfix"></div>
                        <div className="section-title">Orders</div>
                        <div className="clearfix"></div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalProductionOrders']).format('0,0')}</div>
                                <div className="title">Total Orders in Production</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrders']).format('0,0')}</div>
                                <div className="title">Total Orders not in Production</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersOnPress']).format('0,0')}</div>
                                <div className="title">Total Orders On Press</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersOnDigital']).format('0,0')}</div>
                                <div className="title">Total Orders On Digital</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersOnCutting']).format('0,0')}</div>
                                <div className="title">Total Orders On Cutting</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersOnFolding']).format('0,0')}</div>
                                <div className="title">Total Orders Folding</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersOnBinding']).format('0,0')}</div>
                                <div className="title">Total Orders On Binding</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersOnStitching']).format('0,0')}</div>
                                <div className="title">Total Orders On Stitching</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersShipped']).format('0,0')}</div>
                                <div className="title">Total Orders Shipped</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersClosed']).format('0,0')}</div>
                                <div className="title">Total Orders Closed</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalOrdersReady']).format('0,0')}</div>
                                <div className="title">Total Orders Ready</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalPrice']).format('$0,0.00')}</div>
                                <div className="title">Total Printing Price</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalCost']).format('$0,0.00')}</div>
                                <div className="title">Total Printing Cost</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalProfit']).format('$0,0.00')}</div>
                                <div className="title">Total Printing Profit</div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="section-title">Quotes</div>
                        <div className="clearfix"></div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalQuotes']).format('0,0')}</div>
                                <div className="title">Total Quotes</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalQuotesWon']).format('0,0')}</div>
                                <div className="title">Total Quotes Won</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalQuotesLost']).format('0,0')}</div>
                                <div className="title">Total Quotes Lost</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalQuotesCanceled']).format('0,0')}</div>
                                <div className="title">Total Quotes Canceled</div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="section-title">Jobs</div>
                        <div className="clearfix"></div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalJobsProduction']).format('0,0')}</div>
                                <div className="title">Total Jobs in Production</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalFlatJobs']).format('0,0')}</div>
                                <div className="title">Total Flat Jobs</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalMultiPageJobs']).format('0,0')}</div>
                                <div className="title">Total Multi-Page Jobs</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalDigitalJobs']).format('0,0')}</div>
                                <div className="title">Total Digital Jobs</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalLargeFormatJobs']).format('0,0')}</div>
                                <div className="title">Total Large Format Jobs</div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="section-title">Other</div>
                        <div className="clearfix"></div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalProducts']).format('0,0')}</div>
                                <div className="title">Total Products in Catalog</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalCustomers']).format('0,0')}</div>
                                <div className="title">Total Customers</div>
                            </div>
                        </div>
                        <div className="card mb-3 mr-3 pb-1 dashboard-card">
                            <div className="card-body pt-1 pb-0">
                                <div className="content">{numeral(statistics['totalVendors']).format('0,0')}</div>
                                <div className="title">Total Vendors</div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            );
        }

        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover dashboard-container">
                    {content}
                </div>
            </div>
        );
    }
}

export default Table;
