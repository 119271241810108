import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const LargeFormatMachines = (props) => {
    const [fetchingLargeFormatMachines, setFetchingLargeFormatMachines] = useState(false);

    const productType = props.productType;
    const setLargeFormatMachines = props.setLargeFormatMachines;
    useEffect(() => {
        if (parseInt(productType) !== 4) {
            return;
        }

        setFetchingLargeFormatMachines(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/large-format-machines',
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setLargeFormatMachines(response.data);
                setFetchingLargeFormatMachines(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingLargeFormatMachines(false);
            });
    }, [productType, setLargeFormatMachines]);

    const changeHandler = (event) => {
        props.setLargeFormatMachineId(event.target.value);

        props.setSubstrates([]);
        props.setSubstrateId('');

        props.setLaminates([]);
        props.setLaminateId('');

        props.setAccessories([]);
        props.setAccessoriesIds([]);
    };

    return (
        <div className="field">
            <div className="label">Machine:</div>
            <select value={props.largeFormatMachineId} onChange={changeHandler}>
                <option value="">Select option</option>
                {props.largeFormatMachines.map((largeFormatMachine) => <option key={largeFormatMachine.id} value={largeFormatMachine.id}>{largeFormatMachine.name}</option>)}
            </select>
            {fetchingLargeFormatMachines && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default LargeFormatMachines;