import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleOrdersClick: PropTypes.func,
        handleQuotesClick: PropTypes.func,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleDayChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleShowActivePapers: PropTypes.func,
        handleShowSpeedTable: PropTypes.func,
        handleChangeFilter: PropTypes.func
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, isFetchingElements, handleShowForm, handleShowDelete, 
            handleShowActivePapers, handleShowSpeedTable, handleShowDiscountTable } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        let data = [];
        for (let i = 0; i < elements.length; i++) {
            data.push(
                <tr key={i}>
                    <td>{elements[i].id}</td>
                    <td>{elements[i].name}</td>
                    <td>{numeral(elements[i].labor_rate).format('$0,0.00')}</td>
                    <td>{elements[i].setup_time}</td>
                    <td>{elements[i].max_paper_width}x{elements[i].max_paper_length}</td>
                    <td>{elements[i].min_paper_width}x{elements[i].min_paper_length}</td>
                    <td>{elements[i].is_active ? 'Yes' : 'No'}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements[i].id, null)} />
                            </Tooltip>
                            <Tooltip title="Discount Table" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-percentage" onClick={handleShowDiscountTable.bind(this, elements[i].id)} />
                            </Tooltip>
                            <Tooltip title="Active Papers" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-file" onClick={handleShowActivePapers.bind(this, elements[i].id)} />
                            </Tooltip>
                            <Tooltip title="Speed Table" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="far fa-clock" onClick={handleShowSpeedTable.bind(this, elements[i].id)} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, elements[i].id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    handleKey(name, event) {
        const { handleChangeFilter } = this.props;
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onBlur={handleChangeFilter.bind(this, name)} onKeyPress={this.handleKey.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { totalItems, handleSetActivePage, totalPages, selectedPage, elementsPerPage } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('ID', 'id', { width: '6%' })}
                                {this.renderTableHeader('Name', 'name', { width: 'calc(31% - 150px)' })}
                                {this.renderTableHeader('Labor Rate', 'labor_rate', { width: '13%' })}
                                {this.renderTableHeader('Setup Time', 'setup_time', { width: '13%' })}
                                {this.renderTableHeader('Max. Paper Size', 'max_paper_width', { width: '13%' })}
                                {this.renderTableHeader('Min. Paper Size', 'min_paper_width', { width: '13%' })}
                                {this.renderTableHeader('Active', 'is_active', { width: '13%' })}
                                <th style={{ width: 180 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {this.renderSearchField('id')}
                                {this.renderSearchField('name')}
                                {this.renderSearchField('labor_rate')}
                                {this.renderSearchField('setup_time')}
                                {this.renderSearchField('max_paper_width')}
                                {this.renderSearchField('min_paper_width')}
                                {this.renderSearchSelect('is_active', [{ label: '', value: '' }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }])}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={totalItems}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                        handleSetActivePage={handleSetActivePage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
