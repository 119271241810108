const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/quotes`,
    information: `${API_URL}/quote/information`,
    estimate: `${API_URL}/quote/estimate`,
    estimates: `${API_URL}/estimates`,
    convertToQuote: `${API_URL}/quote/convert-to-quote`,
    intersectPaperCategories: `${API_URL}/paper-category/intersect`,
    intersectPaperWeights: `${API_URL}/paper/intersect-weights`,
    intersectPapers: `${API_URL}/paper/intersect-papers`,
    productWindows: `${API_URL}/product-windows`
};

export default apiUrls;
