import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class ActivePresses extends Component {
    static propTypes = {
        activePresses: PropTypes.array,
        hideActivePresses: PropTypes.func,
        isSavingActivePresses: PropTypes.bool,
        handleSaveActivePresses: PropTypes.func,
        handleAddActivePress: PropTypes.func,
        handleDeleteActivePress: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        presses: PropTypes.array,
        formValues: PropTypes.object,
        handleUpdateFormValue: PropTypes.object
    };

    renderActivePresses() {
        const { activePresses, handleDeleteActivePress } = this.props;

        let presses = [];
        for (let i = 0; i < activePresses.length; i++) {
            presses.push({ id: activePresses[i]['id'], name: activePresses[i]['name'] });
        }

        presses.sort(function (a, b) {
            return a['name'] < b['name'] ? -1 : 1;
        });

        return presses.map((paper, index) => {
            return (
                <div key={index} className="float-left mr-2 mt-1">
                    <span className="badge badge-info bold">
                        {paper['name']} <i className="fas fa-times" onClick={handleDeleteActivePress.bind(this, paper['id'])}></i>
                    </span>
                </div>
            );
        });
    }

    isPressActive(pressId) {
        const { activePresses } = this.props;

        for (let i = 0; i < activePresses.length; i++) {
            if (parseInt(activePresses[i]['id']) === parseInt(pressId)) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { hideActivePresses, handleSaveActivePresses, isSavingActivePresses, handleAddActivePress,
            isFetchingInformation, presses, formValues, handleUpdateFormValue, isFetchingElement } = this.props;

        let pressOptions = [<option key="select-paper-category" value="">Select option</option>];
        for (let i = 0; i < presses.length; i++) {
            if (!this.isPressActive(presses[i]['id'])) {
                pressOptions.push(<option value={presses[i]['id']}>{presses[i]['name']}</option>);
            }
        }

        let data = (
            <div className="row">
                <div className="col-4">
                    Press:
                    <select name="press_id" value={formValues['press_id']} onChange={handleUpdateFormValue.bind(this, 'text', true)}>
                        {pressOptions}
                    </select>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="add-button mt-2" onClick={handleAddActivePress} style={{ width: 100, fontSize: 13 }}><i className="fas fa-plus" /> Add</div>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="active-presses">
                        {this.renderActivePresses()}
                    </div>
                </div>
            </div>
        );
        if (isFetchingElement) {
            data = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        if (isFetchingInformation) {
            data = null;
        }

        let saveButtonLabel = 'Save';
        if (isSavingActivePresses) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveActivePresses} disabled={isSavingActivePresses}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideActivePresses}>Close</button>
        ];
        return (
            <Dialog
                name="active-presses"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default ActivePresses;
