import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import EstimatePricing from './EstimatePricing';

class EstimageLargeFormat extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        handleUpdateOutsideService: PropTypes.func,
        handleAddOutsideService: PropTypes.func,
        handleDeleteOutsideService: PropTypes.func,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col', loadingName = null) {
        const { formValues, formErrors, handleUpdateFormValue, fetching } = this.props;

        let loadingIcon = null;
        if (loadingName != null && fetching[loadingName]) {
            loadingIcon = <div className="loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
                {loadingIcon}
            </div>
        );
    }

    renderTypes() {
        return this.renderSelect('Product Type', 'estimate_type', [
            { label: 'Flat', value: 1 },
            { label: 'Multi-Page', value: 2 },
            { label: 'Digital', value: 3 },
            { label: 'Large Format', value: 4 },
            { label: 'Data Processing', value: 5 },
            { label: 'Inkjet', value: 6 },
            { label: 'Inserting', value: 7 },
            // { label: 'Certified Mail Program', value: 8 },
            { label: 'Art Department', value: 9 },
            { label: 'Handwork Department', value: 10 },
            { label: 'Pressure Seal Forms', value: 11 },
            { label: 'Index Tabs', value: 12 },
            { label: 'Standard Labels', value: 13 },
            { label: 'NCR Forms', value: 14 },
            { label: 'Envelopes', value: 15 },
        ], true);
    }

    renderProductCategories(type) {
        const { options } = this.props;

        let categoriesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === parseInt(type)) {
                categoriesOptions.push(options['productCategories'][i]);
            }
        }

        return this.renderSelect('Product Category', 'estimate_product_category_id', categoriesOptions, true);
    }

    renderOutsideServices() {
        const { formValues, handleUpdateOutsideService } = this.props;
        let table = null;
        if (formValues['estimate_outside_services'].length !== 0) {
            table = (
                <table id="outside-services-table" className="table table-sm table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Cost</th>
                            <th>Markup</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formValues['estimate_outside_services'].map((service, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <input type="text" value={service['description']}
                                            onChange={handleUpdateOutsideService.bind(this, 'description', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['cost']}
                                            onChange={handleUpdateOutsideService.bind(this, 'cost', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['percentage_markup']}
                                            onChange={handleUpdateOutsideService.bind(this, 'percentage_markup', index)} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
        return (
            <div className="card mb-2 mr-1 pb-0">
                <div className="card-header">
                    Outside Services
                </div>
                <div className="card-body pt-1 pb-0">
                    <div className="row">
                        <div className="col">
                            <div className="clearfix"></div>
                            {table}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { formValues, handleUpdateFormValue, information, handleAccessoryClick, estimateAccessories,
            activeProduct, options, estimateResults } = this.props;

        let largeFormatMachinesOptions = [{ label: 'Select option', value: '' }];
        let largeFormatMachine = null;
        for (let i = 0; i < information['largeFormatMachines'].length; i++) {
            if (activeProduct == null) {
                largeFormatMachinesOptions.push({
                    label: information['largeFormatMachines'][i]['name'],
                    value: information['largeFormatMachines'][i]['id']
                });
            } else {
                for (let j = 0; j < activeProduct['activeLargeFormatMachines'].length; j++) {
                    if (parseInt(activeProduct['activeLargeFormatMachines'][j]['id']) === parseInt(information['largeFormatMachines'][i]['id'])) {
                        largeFormatMachinesOptions.push({
                            label: information['largeFormatMachines'][i]['name'],
                            value: information['largeFormatMachines'][i]['id']
                        });
                        break;
                    }
                }
            }

            if (parseInt(information['largeFormatMachines'][i]['id']) === parseInt(formValues['estimate_large_format_machine_id'])) {
                largeFormatMachine = information['largeFormatMachines'][i];
            }
        }

        let substratesOptions = [{ label: 'Select option', value: '' }];
        let substrate = null;
        for (let i = 0; largeFormatMachine != null && i < largeFormatMachine['activeSubstrates'].length; i++) {
            if (activeProduct == null) {
                substratesOptions.push({
                    label: largeFormatMachine['activeSubstrates'][i]['name'],
                    value: largeFormatMachine['activeSubstrates'][i]['id']
                });
            } else {
                for (let j = 0; j < activeProduct['activeSubstrates'].length; j++) {
                    if (parseInt(activeProduct['activeSubstrates'][j]['id']) === parseInt(largeFormatMachine['activeSubstrates'][i]['id'])) {
                        substratesOptions.push({
                            label: largeFormatMachine['activeSubstrates'][i]['name'],
                            value: largeFormatMachine['activeSubstrates'][i]['id']
                        });
                        break;
                    }
                }
            }

            if (parseInt(largeFormatMachine['activeSubstrates'][i]['id']) === parseInt(formValues['estimate_substrate_id'])) {
                substrate = largeFormatMachine['activeSubstrates'][i];
            }
        }

        let laminatesOptions = [{ label: 'None', value: '' }];
        for (let i = 0; substrate != null && i < substrate['activeLaminates'].length; i++) {
            if (activeProduct == null) {
                laminatesOptions.push({
                    label: substrate['activeLaminates'][i]['name'],
                    value: substrate['activeLaminates'][i]['id']
                });
            } else {
                for (let j = 0; j < activeProduct['activeLaminates'].length; j++) {
                    if (parseInt(activeProduct['activeLaminates'][j]['id']) === parseInt(substrate['activeLaminates'][i]['id'])) {
                        laminatesOptions.push({
                            label: substrate['activeLaminates'][i]['name'],
                            value: substrate['activeLaminates'][i]['id']
                        });
                        break;
                    }
                }
            }
        }

        let accessories = [];
        for (let i = 0; substrate != null && i < substrate['activeAccessories'].length; i++) {
            if (activeProduct == null) {
                let checked = false;
                if (estimateAccessories.includes(substrate['activeAccessories'][i]['id'])) {
                    checked = true;
                }
                accessories.push(
                    <div className="accessory-checkbox">
                        <input type="checkbox" checked={checked}
                            onClick={handleAccessoryClick.bind(this, substrate['activeAccessories'][i]['id'])} /> {substrate['activeAccessories'][i]['name']}
                    </div>
                );
            } else {
                for (let j = 0; j < activeProduct['activeAccessories'].length; j++) {
                    if (parseInt(activeProduct['activeAccessories'][j]['id']) === parseInt(substrate['activeAccessories'][i]['id'])) {
                        let checked = false;
                        if (estimateAccessories.includes(substrate['activeAccessories'][i]['id'])) {
                            checked = true;
                        }
                        accessories.push(
                            <div className="accessory-checkbox">
                                <input type="checkbox" checked={checked}
                                    onClick={handleAccessoryClick.bind(this, substrate['activeAccessories'][i]['id'])} /> {substrate['activeAccessories'][i]['name']}
                            </div>
                        );
                        break;
                    }
                }
            }
        }

        return (
            <form>
                <div className="row no-gutters mb-2">
                    <div className="col-4" style={{ color: '#0d47a1', fontWeight: 'bold' }}>PQN - Large Format</div>
                </div>
                <div className="row no-gutters">
                    <div className="col">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Product Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderTypes()}
                                    <div className="w-100"></div>
                                    {this.renderProductCategories(4)}
                                    <div className="w-100"></div>
                                    {this.renderInput('Description', 'estimate_product_description', 'text', true)}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Large Format Machine', 'estimate_large_format_machine_id', largeFormatMachinesOptions, true)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Substrate Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderInput('Width', 'estimate_width', 'decimal', true)}
                                    {this.renderInput('Height', 'estimate_height', 'decimal', true)}
                                    {this.renderSelect('Unit', 'estimate_unit', [
                                        { label: 'Inches', value: 1 },
                                        { label: 'Feet', value: 2 }
                                    ], true)}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Substrate', 'estimate_substrate_id', substratesOptions, true)}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Laminate', 'estimate_laminate_id', laminatesOptions, false)}
                                </div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Production Notes
                            </div>
                            <div className="card-body pt-2 pb-0 pl-2 pr-2">
                                <textarea name="estimate_notes" onChange={handleUpdateFormValue.bind(this, 'text', false)} style={{ height: 28 }}>
                                    {formValues['estimate_notes']}
                                </textarea>
                                {this.renderSelect('Discount Table', 'estimate_customer_discount_id', options['customerDiscounts'], false)}
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-2 mr-1 pb-2">
                            <div className="card-header">
                                Accessories
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0" style={{ minHeight: 284 }}>
                                <div className="row no-gutters">
                                    <div className="col">{accessories}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                    </div>
                    <div className="w-100"></div>
                    <EstimatePricing
                        formValues={formValues}
                        estimateResults={estimateResults}
                        information={information}
                        handleUpdateFormValue={handleUpdateFormValue}
                        renderSheets={false}
                    />
                </div>
            </form>
        );
    }
}

export default EstimageLargeFormat;
