import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            max_paper_width: '',
            max_paper_length: '',
            min_paper_width: '',
            min_paper_length: '',
            labor_rate: '',
            labor_markup_rate: '',
            setup_cost: '',
            ink_units: '',
            wash_up_cost: '',
            gripper_margin: '',
            left_right_tail_trim: '',
            plate_change_cost: '',
            plate_cost: '',
            is_active: 1,
            is_perfecting: 0,
            is_default: 0,
            color_bar_margin: '',
            setup_make_ready: '',
            paper_category_id: '',
            paper_weight: '',
            setup_time: '',
            running_spoilage_flat: '',
            makeready_spoilage_flat: '',
            running_spoilage_wt: '',
            makeready_spoilage_wt: '',
            running_spoilage_sw: '',
            makeready_spoilage_sw: '',
            active_paper_ids: []
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        speedTable: [],
        isSavingSpeedTable: false,
        makeReadyMarkups: [],
        isMakeReadyMarkups: false,
        bhrMarkups: [],
        isSavingBhrMarkups: false,
        activePapers: [],
        isSavingActivePapers: false,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'asc'
    },
    information: {
        paperCategories: [],
        paperWeights: [],
        papers: [],
        isFetching: false
    },
    options: {
        activePaperCategories: [{ label: 'Select option', value: '' }],
        activePaperWeights: [{ label: 'Select option', value: '' }],
        activePapers: [{ label: 'Select option', value: '' }]
    },
    fetching: {
        activePaperCategories: false,
        activePaperWeights: false,
        activePapers: false
    },
    filter: {
        fields: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/presses') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                max_paper_width: '',
                max_paper_length: '',
                min_paper_width: '',
                min_paper_length: '',
                labor_rate: '',
                labor_markup_rate: '',
                setup_cost: '',
                ink_units: '',
                wash_up_cost: '',
                gripper_margin: '',
                left_right_tail_trim: '',
                plate_change_cost: '',
                plate_cost: '',
                is_active: 1,
                is_perfecting: 0,
                is_default: 0,
                color_bar_margin: '',
                setup_make_ready: '',
                paper_category_id: '',
                paper_weight: '',
                setup_time: '',
                running_spoilage_flat: '',
                makeready_spoilage_flat: '',
                running_spoilage_wt: '',
                makeready_spoilage_wt: '',
                running_spoilage_sw: '',
                makeready_spoilage_sw: '',
                active_paper_ids: []
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['activeElement', 'speedTable'], action.payload.speeds)
                .setIn(['activeElement', 'makeReadyMarkups'], action.payload.makeReadyMarkups)
                .setIn(['activeElement', 'bhrMarkups'], action.payload.bhrMarkups)
                .setIn(['activeElement', 'activePapers'], action.payload.activePapers)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'max_paper_width'], action.payload.max_paper_width)
                .setIn(['form', 'formValues', 'max_paper_length'], action.payload.max_paper_length)
                .setIn(['form', 'formValues', 'min_paper_width'], action.payload.min_paper_width)
                .setIn(['form', 'formValues', 'min_paper_length'], action.payload.min_paper_length)
                .setIn(['form', 'formValues', 'labor_rate'], action.payload.labor_rate)
                .setIn(['form', 'formValues', 'labor_markup_rate'], action.payload.labor_markup_rate)
                .setIn(['form', 'formValues', 'setup_cost'], action.payload.setup_cost)
                .setIn(['form', 'formValues', 'ink_units'], action.payload.ink_units)
                .setIn(['form', 'formValues', 'wash_up_cost'], action.payload.wash_up_cost)
                .setIn(['form', 'formValues', 'gripper_margin'], action.payload.gripper_margin)
                .setIn(['form', 'formValues', 'left_right_tail_trim'], action.payload.left_right_tail_trim)
                .setIn(['form', 'formValues', 'plate_change_cost'], action.payload.plate_change_cost)
                .setIn(['form', 'formValues', 'plate_cost'], action.payload.plate_cost)
                .setIn(['form', 'formValues', 'is_active'], action.payload.is_active)
                .setIn(['form', 'formValues', 'is_perfecting'], action.payload.is_perfecting)
                .setIn(['form', 'formValues', 'is_default'], action.payload.is_default)
                .setIn(['form', 'formValues', 'color_bar_margin'], action.payload.color_bar_margin)
                .setIn(['form', 'formValues', 'setup_make_ready'], action.payload.setup_make_ready)
                .setIn(['form', 'formValues', 'paper_category_id'], action.payload.paper_category_id)
                .setIn(['form', 'formValues', 'paper_weight'], action.payload.paper_weight)
                .setIn(['form', 'formValues', 'setup_time'], action.payload.setup_time)
                .setIn(['form', 'formValues', 'running_spoilage_flat'], action.payload.running_spoilage_flat)
                .setIn(['form', 'formValues', 'makeready_spoilage_flat'], action.payload.makeready_spoilage_flat)
                .setIn(['form', 'formValues', 'running_spoilage_wt'], action.payload.running_spoilage_wt)
                .setIn(['form', 'formValues', 'makeready_spoilage_wt'], action.payload.makeready_spoilage_wt)
                .setIn(['form', 'formValues', 'running_spoilage_sw'], action.payload.running_spoilage_sw)
                .setIn(['form', 'formValues', 'makeready_spoilage_sw'], action.payload.makeready_spoilage_sw)
                .setIn(['form', 'formValues', 'active_paper_ids'], []);
            break;

        case actionTypes.SET_SPEED_TABLE:
            state = state.setIn(['activeElement', 'speedTable'], action.payload);
            break;

        case actionTypes.SET_MAKE_READY_MARKUPS:
            state = state.setIn(['activeElement', 'makeReadyMarkups'], action.payload);
            break;

        case actionTypes.SET_BHR_MARKUPS:
            state = state.setIn(['activeElement', 'bhrMarkups'], action.payload);
            break;

        case actionTypes.SAVE_SPEED_TABLE:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], true);
            break;

        case actionTypes.SAVE_SPEED_TABLE_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], false);
            break;

        case actionTypes.SAVE_SPEED_TABLE_FAILURE:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], false);
            break;

        case actionTypes.SAVE_MAKE_READY_MARKUPS:
            state = state.setIn(['activeElement', 'isSavingMakeReadyMarkups'], true);
            break;

        case actionTypes.SAVE_MAKE_READY_MARKUPS_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingMakeReadyMarkups'], false);
            break;

        case actionTypes.SAVE_MAKE_READY_MARKUPS_FAILURE:
            state = state.setIn(['activeElement', 'isSavingMakeReadyMarkups'], false);
            break;

        case actionTypes.SAVE_BHR_MARKUPS:
            state = state.setIn(['activeElement', 'isSavingBhrMarkups'], true);
            break;

        case actionTypes.SAVE_BHR_MARKUPS_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingBhrMarkups'], false);
            break;

        case actionTypes.SAVE_BHR_MARKUPS_FAILURE:
            state = state.setIn(['activeElement', 'isSavingBhrMarkups'], false);
            break;

        case actionTypes.SAVE_ACTIVE_PAPERS:
            state = state.setIn(['activeElement', 'isSavingActivePapers'], true);
            break;

        case actionTypes.SAVE_ACTIVE_PAPERS_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingActivePapers'], false);
            break;

        case actionTypes.SAVE_ACTIVE_PAPERS_FAILURE:
            state = state.setIn(['activeElement', 'isSavingActivePapers'], false);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'paperCategories'], [])
                .setIn(['information', 'paperWeights'], [])
                .setIn(['information', 'papers'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'paperCategories'], action.payload.paperCategories)
                .setIn(['information', 'paperWeights'], action.payload.paperWeights)
                .setIn(['information', 'papers'], action.payload.papers);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.ADD_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.DELETE_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.delete(action.payload.value));
            break;

        case actionTypes.CONCAT_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.ADD_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.CLEAR_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload], (data) => data.clear());
            break;

        case actionTypes.CONCAT_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.SET_OPTIONS:
            state = state.updateIn(['options', action.payload.name], (data) => Immutable.List(action.payload.data));
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.SET_LOADING:
            state = state.setIn(['fetching', action.payload.name], action.payload.value);
            break;

        case actionTypes.RESET_OPTIONS:
            state = state.setIn(['options', action.payload], []);
            break;

        case actionTypes.FETCH_PAPER_CATEGORIES:
            state = state.setIn(['fetching', 'activePaperCategories'], true);
            break;

        case actionTypes.FETCH_PAPER_CATEGORIES_SUCCESS:
            state = state.setIn(['fetching', 'activePaperCategories'], false);
            break;

        case actionTypes.FETCH_PAPER_CATEGORIES_FAILURE:
            state = state.setIn(['fetching', 'activePaperCategories'], false);
            break;

        case actionTypes.FETCH_PAPER_WEIGHTS:
            state = state.setIn(['fetching', 'activePaperWeights'], true);
            break;

        case actionTypes.FETCH_PAPER_WEIGHTS_SUCCESS:
            state = state.setIn(['fetching', 'activePaperWeights'], false);
            break;

        case actionTypes.FETCH_PAPER_WEIGHTS_FAILURE:
            state = state.setIn(['fetching', 'activePaperWeights'], false);
            break;

        case actionTypes.FETCH_PAPERS:
            state = state.setIn(['fetching', 'activePapers'], true)
                .setIn(['information', 'papers'], []);
            break;

        case actionTypes.FETCH_PAPERS_SUCCESS:
            state = state.setIn(['fetching', 'activePapers'], false)
                .setIn(['information', 'papers'], action.payload);
            break;

        case actionTypes.FETCH_PAPERS_FAILURE:
            state = state.setIn(['fetching', 'activePapers'], false);
            break;

        case actionTypes.UPDATE_STATE:
            if (action.payload.formValues) {
                state = state.updateIn(['form', 'formValues'], (formValues) => {
                    for (let [element, value] of Object.entries(action.payload.formValues)) {
                        formValues = formValues.set(element, value);
                    }
                    return formValues;
                });
            }
            if (action.payload.options) {
                state = state.updateIn(['options'], (options) => {
                    for (let [element, value] of Object.entries(action.payload.options)) {
                        options = options.set(element, value);
                    }
                    return options;
                });
            }
            if (action.payload.fetching) {
                state = state.updateIn(['fetching'], (fetching) => {
                    for (let [element, value] of Object.entries(action.payload.fetching)) {
                        fetching = fetching.set(element, value);
                    }
                    return fetching;
                });
            }
            break;

        case actionTypes.FETCH_ELEMENT:
            state = state.setIn(['activeElement', 'isFetching'], true);
            break;

        case actionTypes.FETCH_ELEMENT_SUCCESS:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT_FAILURE:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
