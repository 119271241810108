import React from 'react';

const Input = React.memo((props) => {
    let required = null;
    if (props.required) {
        required = <span className="required"> *</span>;
    }

    let value = null;
    if (props.fields[props.field]) {
        value = props.fields[props.field];
    }

    const setFieldHandler = (event) => {
        props.setField(props.field, event.target.value);
    };

    let type = 'text';
    if (props.type) {
        type = props.type;
    }

    return (
        <div className="input-field">
            <div className="label">{props.label}{required}</div>
            <input type={type} value={value} onChange={setFieldHandler} />
        </div>
    );
});

Input.propTypes = {

}

export default Input;
