import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification, validateAuthInResponse } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';
import numeral from 'numeral';

let interval = null; // TODO: Improve this

export const fetchElements = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const saveElement = (values, elements, customerId) => (dispatch, getState) => {
    let errors = {};
    let errorMessages = {};
    if (values.name === '' || values.name === null) {
        errors.name = true;
    }
    if (values.status === '' || values.status === null) {
        errors.status = true;
    }
    if (values.turnaround_id === '' || values.turnaround_id === null) {
        errors.turnaround_id = true;
    }
    if (values.sales_rep_id === '' || values.sales_rep_id === null) {
        errors.sales_rep_id = true;
    }
    if (values.customer_service_rep_id === '' || values.customer_service_rep_id === null) {
        errors.customer_service_rep_id = true;
    }
    if (values.discount === '' || values.discount === null) {
        errors.discount = true;
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    if (values.customer_id === '' || values.customer_id == null) {
        showNotification('Complete the Information', 'Select a customer', 'info');
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
        values.status = 1;
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    values.customer_id = customerId;
    values.all = values.all ? 1 : 0;

    let shipping = selectors.getShipping(getState());
    values['shipping'] = shipping['total'];
    values['shippingPrices'] = shipping['prices'];

    let fields = [
        '*',
        'estimates.id',
        'estimates.type',
        'estimates.notes',
        'estimates.front_inks',
        'estimates.back_inks',
        'estimates.interior_inks',
        'estimates.multi_page_type',
        'estimates.product_category_name',
        'estimates.selected_quantity',
        'estimates.presses_names',
        'estimates.digital_machine_name',
        'estimates.large_format_machine_name',
        'estimates.substrate_name',
        'estimates.laminate_name',
        'estimates.paper_names',
        'estimates.folding_machine_name',
        'estimates.status_id',
        'estimates.approved_by',
        'estimates.approved_by_name',
        'estimates.approved_at',
        'estimates.accessories_names',
        'estimates.binding_machine_name',
        'estimates.have_artworks',
        'estimates.product_description',
        'estimates.data_processing_elements_ids',
        'estimates.data_processing_elements',
        'estimates.inkjet_elements_ids',
        'estimates.inkjet_elements',
        'estimates.inserting_elements_ids',
        'estimates.inserting_elements',
        'estimates.perfect_match_elements_ids',
        'estimates.perfect_match_elements',
        'estimates.art_elements_ids',
        'estimates.art_elements',
        'estimates.handwork_elements_ids',
        'estimates.handwork_elements',
        'estimates.pressure_seal_elements_ids',
        'estimates.pressure_seal_elements',
        'estimates.index_tab_elements_ids',
        'estimates.index_tab_elements',
        'estimates.label_elements_ids',
        'estimates.label_elements',
        'estimates.ncr_form_elements_ids',
        'estimates.ncr_form_elements',
        'estimates.estimateResults.quantity',
        'estimates.estimateResults.subtotal',
        'estimates.estimateResults.customer_discount',
        'estimates.estimateResults.tax',
        'estimates.estimateResults.rush_fee',
        'estimates.estimateResults.order_discount',
        'estimates.estimateResults.discount',
        'estimates.estimateResults.total',
        'estimates.customer_discount_id',
        'estimates.discount',
        'estimates.discount_type',
        'estimates.discount_percentage',
        'estimates.estimateFiles.*',
        'estimates.envelope_press_id',
        'estimates.envelope_press_name',
        'estimates.envelope_size',
        'estimates.envelope_paper_style',
        'estimates.envelope_paper_weight',
        'estimates.envelope_paper_id',
        'estimates.envelope_paper_name',
        'estimates.customer_provide',
        'estimates.print_on_flap',
    ];
    let extraFields = ['estimates', 'estimates.estimateResults', 'estimates.estimateFiles'];

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            if (values.id === '') {
                const history = selectors.getHistory(getState());
                history.push('/orders/update/' + response.data.id);
            } else {
                showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            }
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function resetEstimateForm() {
    return {
        type: actionTypes.RESET_ESTIMATE_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(element) {
    let estimate_results = [];
    let quantity = 0;
    let press_price = 0;
    let paper_price = 0;
    let ink_price = 0;
    let cutting_price = 0;
    let binding_price = 0;
    let folding_price = 0;
    let digital_machine_price = 0;
    let large_format_machine_price = 0;
    let substrate_price = 0;
    let laminate_price = 0;
    let accessories_price = 0;
    let outside_services_price = 0;
    let scoring_price = 0;
    let die_cutting_price = 0;
    let drilling_price = 0;
    let padding_price = 0;
    let banding_price = 0;
    let wrapping_price = 0;
    let shrink_wrapping_price = 0;
    let package_price = 0;
    let hand_gathering_price = 0;
    let counting_price = 0;
    let envelopePressPrice = 0;
    let envelopePaperPrice = 0;
    let dataProcessingPrice1 = 0;
    let dataProcessingPrice2 = 0;
    let dataProcessingPrice3 = 0;
    let inkjetPrice = 0;
    let insertingPrice = 0;
    let perfectMatchPrice = 0;
    let artPrice = 0;
    let handworkPrice = 0;
    let pressureSealPrice1 = 0;
    let pressureSealPrice2 = 0;
    let pressureSealPrice3 = 0;
    let pressureSealPrice4 = 0;
    let pressureSealPrice5 = 0;
    let pressureSealPrice6 = 0;
    let pressureSealPrice7 = 0;
    let pressureSealPrice8 = 0;
    let indexTabPrice = 0;
    let labelPrice = 0;
    let ncrFormPrice = 0;
    if (element.estimateResults.length === 1) {
        estimate_results.push(JSON.parse(element.estimateResults[0]['json']));
        quantity = element.estimateResults[0]['quantity'];
        press_price = element.estimateResults[0]['press_price'];
        paper_price = element.estimateResults[0]['paper_price'];
        ink_price = element.estimateResults[0]['ink_price'];
        cutting_price = element.estimateResults[0]['cutting_price'];
        binding_price = element.estimateResults[0]['binding_price'];
        folding_price = element.estimateResults[0]['folding_price'];
        digital_machine_price = element.estimateResults[0]['digital_machine_price'];
        large_format_machine_price = element.estimateResults[0]['large_format_machine_price'];
        substrate_price = element.estimateResults[0]['substrate_price'];
        laminate_price = element.estimateResults[0]['laminate_price'];
        accessories_price = element.estimateResults[0]['accessory_price'];
        outside_services_price = element.estimateResults[0]['outside_services_price'];
        scoring_price = element.estimateResults[0]['scoring_price'];
        die_cutting_price = element.estimateResults[0]['die_cutting_price'];
        drilling_price = element.estimateResults[0]['drilling_price'];
        padding_price = element.estimateResults[0]['padding_price'];
        banding_price = element.estimateResults[0]['banding_price'];
        wrapping_price = element.estimateResults[0]['wrapping_price'];
        shrink_wrapping_price = element.estimateResults[0]['shrink_wrapping_price'];
        package_price = element.estimateResults[0]['package_price'];
        hand_gathering_price = element.estimateResults[0]['hand_gathering_price'];
        counting_price = element.estimateResults[0]['counting_price'];
        envelopePressPrice = element.estimateResults[0]['envelope_press_price'];
        envelopePaperPrice = element.estimateResults[0]['envelope_paper_price'];
        dataProcessingPrice1 = element.estimateResults[0]['data_processing_price_1'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['data_processing_price_1'])).format('0.00');
        dataProcessingPrice2 = element.estimateResults[0]['data_processing_price_2'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['data_processing_price_2'])).format('0.00');
        dataProcessingPrice3 = element.estimateResults[0]['data_processing_price_3'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['data_processing_price_3'])).format('0.00');
        inkjetPrice = element.estimateResults[0]['inkjet_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['inkjet_price'])).format('0.00');
        insertingPrice = element.estimateResults[0]['inserting_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['inserting_price'])).format('0.00');
        perfectMatchPrice = element.estimateResults[0]['perfect_match_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['perfect_match_price'])).format('0.00');
        artPrice = element.estimateResults[0]['art_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['art_price'])).format('0.00');
        handworkPrice = element.estimateResults[0]['handwork_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['handwork_price'])).format('0.00');
        pressureSealPrice1 = element.estimateResults[0]['pressure_seal_price_1'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_1'])).format('0.00');
        pressureSealPrice2 = element.estimateResults[0]['pressure_seal_price_2'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_2'])).format('0.00');
        pressureSealPrice3 = element.estimateResults[0]['pressure_seal_price_3'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_3'])).format('0.00');
        pressureSealPrice4 = element.estimateResults[0]['pressure_seal_price_4'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_4'])).format('0.00');
        pressureSealPrice5 = element.estimateResults[0]['pressure_seal_price_5'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_5'])).format('0.00');
        pressureSealPrice6 = element.estimateResults[0]['pressure_seal_price_6'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_6'])).format('0.00');
        pressureSealPrice7 = element.estimateResults[0]['pressure_seal_price_7'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_7'])).format('0.00');
        pressureSealPrice8 = element.estimateResults[0]['pressure_seal_price_8'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['pressure_seal_price_8'])).format('0.00');
        indexTabPrice = element.estimateResults[0]['index_tab_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['index_tab_price'])).format('0.00');
        labelPrice = element.estimateResults[0]['label_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['label_price'])).format('0.00');
        ncrFormPrice = element.estimateResults[0]['ncr_form_price'] == null ? 0 : numeral(parseFloat(element.estimateResults[0]['ncr_form_price'])).format('0.00');
    }

    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: {
            element, estimate_results, press_price, paper_price, ink_price, cutting_price, quantity,
            binding_price, folding_price, digital_machine_price, large_format_machine_price,
            substrate_price, laminate_price, accessories_price, outside_services_price,
            scoring_price, die_cutting_price, drilling_price, padding_price, banding_price,
            wrapping_price, shrink_wrapping_price, package_price, hand_gathering_price, counting_price,
            dataProcessingPrice1, dataProcessingPrice2, dataProcessingPrice3, inkjetPrice,
            insertingPrice, perfectMatchPrice, artPrice, handworkPrice, indexTabPrice,
            labelPrice, ncrFormPrice, pressureSealPrice1, pressureSealPrice2, pressureSealPrice3, pressureSealPrice4,
            pressureSealPrice5, pressureSealPrice6, pressureSealPrice7, pressureSealPrice8, envelopePressPrice, envelopePaperPrice
        }
    };
}

export function setCustomerId(id) {
    return {
        type: actionTypes.SET_CUSTOMER_ID,
        payload: id
    };
}

export function setOrderId(id) {
    return {
        type: actionTypes.SET_ORDER_ID,
        payload: id
    };
}

export const fetchOrder = (id) => dispatch => {
    let fields = [
        '*',
        'estimates.id',
        'estimates.type',
        'estimates.notes',
        'estimates.front_inks',
        'estimates.back_inks',
        'estimates.interior_inks',
        'estimates.multi_page_type',
        'estimates.product_category_name',
        'estimates.selected_quantity',
        'estimates.presses_names',
        'estimates.digital_machine_name',
        'estimates.large_format_machine_name',
        'estimates.substrate_name',
        'estimates.laminate_name',
        'estimates.paper_names',
        'estimates.folding_machine_name',
        'estimates.approved_by',
        'estimates.approved_by_name',
        'estimates.approved_at',
        'estimates.have_artworks',
        'estimates.product_description',
        'estimates.accessories_names',
        'estimates.binding_machine_name',
        'estimates.status_id',
        'estimates.data_processing_elements_ids',
        'estimates.data_processing_elements',
        'estimates.inkjet_elements_ids',
        'estimates.inkjet_elements',
        'estimates.inserting_elements_ids',
        'estimates.inserting_elements',
        'estimates.perfect_match_elements_ids',
        'estimates.perfect_match_elements',
        'estimates.art_elements_ids',
        'estimates.art_elements',
        'estimates.handwork_elements_ids',
        'estimates.handwork_elements',
        'estimates.pressure_seal_elements_ids',
        'estimates.pressure_seal_elements',
        'estimates.index_tab_elements_ids',
        'estimates.index_tab_elements',
        'estimates.label_elements_ids',
        'estimates.label_elements',
        'estimates.ncr_form_elements_ids',
        'estimates.ncr_form_elements',
        'customer.*',
        'estimates.estimateResults.quantity',
        'estimates.estimateResults.subtotal',
        'estimates.estimateResults.customer_discount',
        'estimates.estimateResults.tax',
        'estimates.estimateResults.rush_fee',
        'estimates.estimateResults.order_discount',
        'estimates.estimateResults.discount',
        'estimates.estimateResults.total',
        'estimates.customer_discount_id',
        'estimates.discount',
        'estimates.discount_type',
        'estimates.discount_percentage',
        'billingAddress.*',
        'shippingAddress.*',
        'estimates.estimateFiles.*',
        'estimates.envelope_press_id',
        'estimates.envelope_press_name',
        'estimates.envelope_size',
        'estimates.envelope_paper_style',
        'estimates.envelope_paper_weight',
        'estimates.envelope_paper_id',
        'estimates.envelope_paper_name',
        'estimates.customer_provide',
        'estimates.print_on_flap',
    ];
    let extraFields = ['estimates', 'estimates.estimateResults', 'customer', 'billingAddress', 'shippingAddress',
        'estimates.estimateFiles'];

    dispatch({ type: actionTypes.FETCH_ORDER, payload: null });
    axios.get(apiUrls.elements + '/' + id + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ORDER_SUCCESS, payload: response.data });
            dispatch(fetchCustomer(response.data.customer_id));
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ORDER_FAILURE));
        });
}

export const fetchInformation = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
            dispatch(updateState({
                options: {
                    turnaround: loadOptions(response.data.turnaround),
                    statuses: loadOptions(response.data.statuses),
                    productionStatuses: loadOptions(response.data.productionStatuses),
                    salesReps: loadOptions(response.data.salesReps),
                    customerServiceReps: loadOptions(response.data.customerServiceReps),
                    industries: loadOptions(response.data.industries),
                }
            }));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_INFORMATION_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchEstimateInformation = (type, data = null) => dispatch => {
    dispatch({ type: actionTypes.FETCH_ESTIMATE_INFORMATION, payload: null });
    axios.get(apiUrls.information + '?type=' + type,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ESTIMATE_INFORMATION_SUCCESS, payload: { data: response.data, type } });
            if (parseInt(type) === 1) {  // Flat
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        foldTypes: loadOptions(response.data.foldTypes, true),
                        bandingTypes: loadOptions(response.data.bandingTypes, true),
                        wrappingTypes: loadOptions(response.data.wrappingTypes, true),
                        shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes, true),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        inks: loadOptions(response.data.inks, true),
                        packages: loadPackagesOptions(response.data.packages)
                    }
                }));
                dispatch(intersectPresses());
            } else if (parseInt(type) === 2) {   // Multi-Page
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        bandingTypes: loadOptions(response.data.bandingTypes, true),
                        wrappingTypes: loadOptions(response.data.wrappingTypes, true),
                        shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes, true),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        inks: loadOptions(response.data.inks, true),
                        packages: loadPackagesOptions(response.data.packages)
                    }
                }));
                dispatch(intersectPresses());
            } else if (parseInt(type) === 3) {   // Digital
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        foldTypes: loadOptions(response.data.foldTypes, true),
                        bandingTypes: loadOptions(response.data.bandingTypes, true),
                        wrappingTypes: loadOptions(response.data.wrappingTypes, true),
                        shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes, true),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        digitalMachines: loadOptions(response.data.digitalMachines),
                        inks: loadOptions(response.data.inks, true),
                        packages: loadPackagesOptions(response.data.packages)
                    }
                }));
            } else if (parseInt(type) === 4) {   // Large Format
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories)
                    }
                }));
            } else if (parseInt(type) === 5) {   // Data Processing
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        dataProcessingElements: loadDataProcessingOptions(response.data.dataProcessingElements)
                    }
                }));
            } else if (parseInt(type) === 6) {   // Inkjet
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        inkjetElements: loadOptions(response.data.inkjetElements),
                    }
                }));
            } else if (parseInt(type) === 7) {   // Inserting
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        insertingElements: loadOptions(response.data.insertingElements),
                        perfectMatchElements: loadOptions(response.data.perfectMatchElements)
                    }
                }));
            } else if (parseInt(type) === 9) {   // Art
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        artElements: loadOptions(response.data.artElements)
                    }
                }));
            } else if (parseInt(type) === 10) {  // Handwork
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        handworkElements: loadOptions(response.data.handworkElements)
                    }
                }));
            } else if (parseInt(type) === 11) {  // Pressure Seals
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        pressureSealElements: loadOptions(response.data.pressureSealElements)
                    }
                }));
            } else if (parseInt(type) === 12) {  // Index Tabs
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        indexTabElements: loadOptions(response.data.indexTabElements)
                    }
                }));
            } else if (parseInt(type) === 13) {  // Labels
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        labelElements: loadOptions(response.data.labelElements)
                    }
                }));
            } else if (parseInt(type) === 14) {  // NCR Forms
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        ncrFormElements: loadOptions(response.data.ncrFormElements)
                    }
                }));
            } else if (parseInt(type) === 15) {  // Envelopes
                dispatch(updateState({
                    options: {
                        customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts),
                        productCategories: loadProductCategoriesOptions(response.data.productCategories),
                        envelopePresses: loadOptions(response.data.envelopePresses),
                        envelopePaperTypes: loadEnvelopePaperTypesOptions(response.data.envelopePapers)
                    },
                    information: {
                        envelopePapers: response.data.envelopePapers
                    }
                }));

                if (data !== null) {
                    dispatch(filterEnvelopePaperStyles(data['envelope_paper_type']));
                    dispatch(filterEnvelopePaperSizes(data['envelope_paper_style']));
                    dispatch(filterEnvelopePaperWeights(data['envelope_size']));
                }
            }
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ESTIMATE_INFORMATION_FAILURE));
        });
}

export const estimate = values => dispatch => {
    let errors = {};
    let errorMessages = {};
    if (values['estimate_product_description'] === '' || values['estimate_product_description'] === null) {
        errors['estimate_product_description'] = true;
    }
    if (values['estimate_product_category_id'] === '' || values['estimate_product_category_id'] === null) {
        errors['estimate_product_category_id'] = true;
    }
    if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2) {
        if (values['estimate_press_id'] === '' || values['estimate_press_id'] === null) {
            errors['estimate_press_id'] = true;
        }
        if (values['estimate_running_style'] === '' || values['estimate_running_style'] === null) {
            errors['estimate_running_style'] = true;
        }
    }
    if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2 || parseInt(values['estimate_type']) === 3) {
        if (values['estimate_width'] === '' || values['estimate_width'] === null) {
            errors['estimate_width'] = true;
        }
        if (values['estimate_height'] === '' || values['estimate_height'] === null) {
            errors['estimate_height'] = true;
        }
        if (values['estimate_paper_category_id'] === '' || values['estimate_paper_category_id'] === null) {
            errors['estimate_paper_category_id'] = true;
        }
        if (values['estimate_paper_weight'] === '' || values['estimate_paper_weight'] === null) {
            errors['estimate_paper_weight'] = true;
        }
        if (values['estimate_paper_id'] === '' || values['estimate_paper_id'] === null) {
            errors['estimate_paper_id'] = true;
        }
    }
    if (parseInt(values['estimate_type']) === 2) {
        if (values['estimate_multi_page_type'] === '' || values['estimate_multi_page_type'] === null) {
            errors['estimate_multi_page_type'] = true;
        }
        if (values['estimate_pages'] === '' || values['estimate_pages'] === null) {
            errors['estimate_pages'] = true;
        }
        if ((parseInt(values['estimate_multi_page_type']) === 1 || parseInt(values['estimate_multi_page_type']) === 2)
            && parseInt(values['estimate_pages']) % 4 !== 0) {
            errors['estimate_pages'] = true;
            errorMessages['estimate_pages'] = 'The pages must be divisible by 4';
        }
        if (values['estimate_second_press_id'] === '' || values['estimate_second_press_id'] === null) {
            errors['estimate_second_press_id'] = true;
        }
        if (values['estimate_second_running_style'] === '' || values['estimate_second_running_style'] === null) {
            errors['estimate_second_running_style'] = true;
        }
        if ([2, 3, 6, 7].includes(parseInt(values['estimate_multi_page_type']))) {
            if (values['estimate_cover_paper_category_id'] === '' || values['estimate_cover_paper_category_id'] === null) {
                errors['estimate_cover_paper_category_id'] = true;
            }
            if (values['estimate_cover_paper_weight'] === '' || values['estimate_cover_paper_weight'] === null) {
                errors['estimate_cover_paper_weight'] = true;
            }
            if (values['estimate_cover_paper_id'] === '' || values['estimate_cover_paper_id'] === null) {
                errors['estimate_cover_paper_id'] = true;
            }
            if (values['estimate_cover_press_id'] === '' || values['estimate_cover_press_id'] === null) {
                errors['estimate_cover_press_id'] = true;
            }
            if (values['estimate_cover_running_style'] === '' || values['estimate_cover_running_style'] === null) {
                errors['estimate_cover_running_style'] = true;
            }
        }
    } else if (parseInt(values['estimate_type']) === 3) {
        if (values['estimate_digital_machine_id'] === '' || values['estimate_digital_machine_id'] === null) {
            errors['estimate_digital_machine_id'] = true;
        }
    } else if (parseInt(values['estimate_type']) === 4) {
        if (values['estimate_width'] === '' || values['estimate_width'] === null) {
            errors['estimate_width'] = true;
        }
        if (values['estimate_height'] === '' || values['estimate_height'] === null) {
            errors['estimate_height'] = true;
        }
        if (values['estimate_large_format_machine_id'] === '' || values['estimate_large_format_machine_id'] === null) {
            errors['estimate_large_format_machine_id'] = true;
        }
        if (values['estimate_substrate_id'] === '' || values['estimate_substrate_id'] === null) {
            errors['estimate_substrate_id'] = true;
        }
    } else if (parseInt(values['estimate_type']) === 15) {       // Envelopes
        if (values['estimate_envelope_press_id'] === '' || values['estimate_envelope_press_id'] === null) {
            errors['estimate_envelope_press_id'] = true;
        }
        if (values['estimate_envelope_paper_type'] === '' || values['estimate_envelope_paper_type'] === null) {
            errors['estimate_envelope_paper_type'] = true;
        }
        if (values['estimate_envelope_paper_style'] === '' || values['estimate_envelope_paper_style'] === null) {
            errors['estimate_envelope_paper_style'] = true;
        }
        if (values['estimate_envelope_size'] === '' || values['estimate_envelope_size'] === null) {
            errors['estimate_envelope_size'] = true;
        }
        if (values['estimate_envelope_paper_weight'] === '' || values['estimate_envelope_paper_weight'] === null) {
            errors['estimate_envelope_paper_weight'] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ESTIMATE, payload: null });
    axios.post(apiUrls.estimate, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ESTIMATE_SUCCESS, payload: response.data });
            if (response.data.length > 0) {
                showNotification('Results obtained', 'Results calculated', 'success');
            } else {
                showNotification('No results obtained', 'There are no results', 'success');
            }
            dispatch(setPrices(response.data, values));
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function hasCover(values) {
    return parseInt(values['estimate_multi_page_type']) === 2 || parseInt(values['estimate_multi_page_type']) === 3
        || parseInt(values['estimate_multi_page_type']) === 6 || parseInt(values['estimate_multi_page_type']) === 7;
}

export function setPrices(results, values) {
    let pressPrice = 0;
    let paperPrice = 0;
    let inkPrice = 0;
    let cuttingPrice = 0;
    let bindingPrice = 0;
    let foldingPrice = 0;
    let digitalMachinePrice = 0;
    let largeFormatMachinePrice = 0;
    let substratePrice = 0;
    let laminatePrice = 0;
    let accessoryPrice = 0;
    let outsideServicesPrice = 0;
    let scoringPrice = 0;
    let dieCuttingPrice = 0;
    let drillingPrice = 0;
    let paddingPrice = 0;
    let bandingPrice = 0;
    let wrappingPrice = 0;
    let shrinkWrappingPrice = 0;
    let packagePrice = 0;
    let handGatheringPrice = 0;
    let countingPrice = 0;
    let envelopePressPrice = 0;
    let envelopePaperPrice = 0;

    if (results.length === 1) {
        if ([1, 2, 3, 4].includes(parseInt(values['estimate_type']))) {
            if (results[0]['results'].length > 0) {
                let result = results[0]['results'][0];
                if (parseInt(values['estimate_type']) === 1) {
                    pressPrice = numeral(result['press_information']['total_cost']
                        * result['press_information']['markup_multiplier']).format('0.00');
                    paperPrice = numeral(result['paper_information']['total_cost']
                        * result['paper_information']['markup_multiplier']).format('0.00');
                    inkPrice = numeral(result['ink_information']['total_cost']
                        * result['ink_information']['markup_multiplier']).format('0.00');
                    cuttingPrice = numeral(result['cutting_information']['total_cost']
                        * result['cutting_information']['markup_multiplier']).format('0.00');

                    if (result['folding_information']['id'] != null) {
                        foldingPrice = numeral(result['folding_information']['total_cost']
                            * result['folding_information']['markup_multiplier']).format('0.00');
                    }
                } else if (parseInt(values['estimate_type']) === 2) {
                    let pressCost = 0;
                    let paperCost = 0;
                    let inkCost = 0;
                    let cuttingCost = 0;
                    let foldingCost = 0;
                    for (let j = 0; j < result['impositions_information'].length; j++) {
                        pressCost += result['impositions_information'][j]['press_information']['total_cost']
                            * result['impositions_information'][j]['press_information']['markup_multiplier'];
                        paperCost += result['impositions_information'][j]['paper_information']['total_cost']
                            * result['impositions_information'][j]['paper_information']['markup_multiplier'];
                        inkCost += result['impositions_information'][j]['ink_information']['total_cost']
                            * result['impositions_information'][j]['ink_information']['markup_multiplier'];
                        cuttingCost += result['impositions_information'][j]['cutting_information']['total_cost']
                            * result['impositions_information'][j]['cutting_information']['markup_multiplier'];
                        foldingCost += result['impositions_information'][j]['folding_information']['total_cost']
                            * result['impositions_information'][j]['folding_information']['markup_multiplier'];
                    }

                    if (hasCover(values)) {
                        pressCost += results[0]['cover_results'][0]['press_information']['total_cost']
                            * results[0]['cover_results'][0]['press_information']['markup_multiplier'];
                        paperCost += results[0]['cover_results'][0]['paper_information']['total_cost']
                            * results[0]['cover_results'][0]['paper_information']['markup_multiplier'];
                        inkCost += results[0]['cover_results'][0]['ink_information']['total_cost']
                            * results[0]['cover_results'][0]['ink_information']['markup_multiplier'];
                        cuttingCost += results[0]['cover_results'][0]['cutting_information']['total_cost']
                            * results[0]['cover_results'][0]['cutting_information']['markup_multiplier'];
                        foldingCost += results[0]['cover_results'][0]['folding_information']['total_cost']
                            * results[0]['cover_results'][0]['folding_information']['markup_multiplier'];
                    }

                    pressPrice = numeral(pressCost).format('0.00');
                    paperPrice = numeral(paperCost).format('0.00');
                    inkPrice = numeral(inkCost).format('0.00');
                    cuttingPrice = numeral(cuttingCost).format('0.00');
                    bindingPrice = numeral(result['binding_information']['total_cost']).format('0.00');
                    foldingPrice = numeral(foldingCost).format('0.00');
                } else if (parseInt(values['estimate_type']) === 3) {
                    digitalMachinePrice = numeral(result['digital_machine_information']['total_cost']
                        * result['digital_machine_information']['markup_multiplier']).format('0.00');
                    paperPrice = numeral(result['paper_information']['total_cost']
                        * result['paper_information']['markup_multiplier']).format('0.00');
                    inkPrice = numeral(result['ink_information']['total_cost']
                        * result['ink_information']['markup_multiplier']).format('0.00');
                    cuttingPrice = numeral(result['cutting_information']['total_cost']
                        * result['cutting_information']['markup_multiplier']).format('0.00');

                    if (result['folding_information']['id'] != null) {
                        foldingPrice = numeral(result['folding_information']['total_cost']
                            * result['folding_information']['markup_multiplier']).format('0.00');
                    }
                } else if (parseInt(values['estimate_type']) === 4) {
                    largeFormatMachinePrice = numeral(result['large_format_machine_information']['total_cost']
                        * result['large_format_machine_information']['markup_multiplier']).format('0.00');
                    substratePrice = numeral(result['substrate_information']['total_cost']
                        * result['substrate_information']['markup_multiplier']).format('0.00');

                    if (result['laminate_information'] != null) {
                        laminatePrice = numeral(result['laminate_information']['total_cost']
                            * result['laminate_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['accessories_information']['accessories'].length > 0) {
                        let totalAccessoriesCost = 0;
                        for (let j = 0; j < result['accessories_information']['accessories'].length; j++) {
                            totalAccessoriesCost += parseFloat(result['accessories_information']['accessories'][j]['total_cost'])
                                * parseFloat(result['accessories_information']['accessories'][j]['markup_multiplier']);
                        }
                        accessoryPrice = numeral(totalAccessoriesCost).format('0.00');
                    }
                }

                if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2 || parseInt(values['estimate_type']) === 3) {
                    if (result['scoring_information'] && result['scoring_information']['id'] != null) {
                        scoringPrice = numeral(result['scoring_information']['total_cost']
                            * result['scoring_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['die_cutter_information'] && result['die_cutter_information']['id'] != null) {
                        dieCuttingPrice = numeral(result['die_cutter_information']['total_cost']
                            * result['die_cutter_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['drilling_information'] && result['drilling_information']['id'] != null) {
                        drillingPrice = numeral(result['drilling_information']['total_cost']
                            * result['drilling_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['padding_information'] && result['padding_information']['id'] != null) {
                        paddingPrice = numeral(result['padding_information']['total_cost']
                            * result['padding_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['banding_information'] && result['banding_information']['id'] != null) {
                        bandingPrice = numeral(result['banding_information']['total_cost']
                            * result['banding_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['wrapping_information'] && result['wrapping_information']['id'] != null) {
                        wrappingPrice = numeral(result['wrapping_information']['total_cost']
                            * result['wrapping_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['shrink_wrapping_information'] && result['shrink_wrapping_information']['id'] != null) {
                        shrinkWrappingPrice = numeral(result['shrink_wrapping_information']['total_cost']
                            * result['shrink_wrapping_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['package_information'] && result['package_information']['id'] != null) {
                        packagePrice = numeral(result['package_information']['total_cost']
                            * result['package_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['hand_gathering_information'] && result['hand_gathering_information']['id'] != null) {
                        handGatheringPrice = numeral(result['hand_gathering_information']['total_cost']
                            * result['hand_gathering_information']['markup_multiplier']).format('0.00');
                    }

                    if (result['counting_information'] && result['counting_information']['id'] != null) {
                        countingPrice = numeral(result['counting_information']['total_cost']
                            * result['counting_information']['markup_multiplier']).format('0.00');
                    }
                }

                if (result['outside_services_information'] && result['outside_services_information']['services'].length > 0) {
                    outsideServicesPrice = numeral(result['outside_services_information']['total_cost']
                        * result['outside_services_information']['markup_multiplier']).format('0.00');
                }
            }
        } else if (parseInt(values['estimate_type']) === 15) {       // Envelopes
            const pressCost = results[0]['envelope_press']['press_cost'] + results[0]['envelope_press']['pre_press_cost'];
            envelopePressPrice = numeral(pressCost * (1 + parseFloat(results[0]['envelope_press']['labor_markup']))).format('0.00');
            envelopePaperPrice = numeral(results[0]['envelope_paper']['cost'] * (1 + parseFloat(results[0]['envelope_paper']['markup']))).format('0.00');
        }
    }

    return {
        type: actionTypes.SET_PRICES,
        payload: {
            estimate_press_price: pressPrice,
            estimate_paper_price: paperPrice,
            estimate_ink_price: inkPrice,
            estimate_cutting_price: cuttingPrice,
            estimate_binding_price: bindingPrice,
            estimate_folding_price: foldingPrice,
            estimate_digital_machine_price: digitalMachinePrice,
            estimate_large_format_machine_price: largeFormatMachinePrice,
            estimate_substrate_price: substratePrice,
            estimate_laminate_price: laminatePrice,
            estimate_accessories_price: accessoryPrice,
            estimate_outside_services_price: outsideServicesPrice,
            estimate_scoring_price: scoringPrice,
            estimate_die_cutting_price: dieCuttingPrice,
            estimate_drilling_price: drillingPrice,
            estimate_padding_price: paddingPrice,
            estimate_banding_price: bandingPrice,
            estimate_wrapping_price: wrappingPrice,
            estimate_shrink_wrapping_price: shrinkWrappingPrice,
            estimate_package_price: packagePrice,
            estimate_hand_gathering_price: handGatheringPrice,
            estimate_counting_price: countingPrice,
            estimate_envelope_press_price: envelopePressPrice,
            estimate_envelope_paper_price: envelopePaperPrice,
        }
    };
}

export const saveEstimate = (values, results, estimates, orderId) => dispatch => {
    let errors = {};
    let errorMessages = {};

    if ([1, 2, 3, 4].includes(parseInt(values['estimate_type']))) {
        if (results.length < 1) {
            showNotification('No results obtained', 'There are no results to save', 'success');
            return;
        }

        if (values['estimate_product_description'] === '' || values['estimate_product_description'] === null) {
            errors['estimate_product_description'] = true;
        }
        if (values['estimate_width'] === '' || values['estimate_width'] === null) {
            errors['estimate_width'] = true;
        }
        if (values['estimate_height'] === '' || values['estimate_height'] === null) {
            errors['estimate_height'] = true;
        }
        if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2) {
            if (values['estimate_press_id'] === '' || values['estimate_press_id'] === null) {
                errors['estimate_press_id'] = true;
            }
            if (values['estimate_running_style'] === '' || values['estimate_running_style'] === null) {
                errors['estimate_running_style'] = true;
            }
        }
        if (parseInt(values['estimate_type']) === 1 || parseInt(values['estimate_type']) === 2 || parseInt(values['estimate_type']) === 3) {
            if (values['estimate_paper_category_id'] === '' || values['estimate_paper_category_id'] === null) {
                errors['estimate_paper_category_id'] = true;
            }
            if (values['estimate_paper_weight'] === '' || values['estimate_paper_weight'] === null) {
                errors['estimate_paper_weight'] = true;
            }
            if (values['estimate_paper_id'] === '' || values['estimate_paper_id'] === null) {
                errors['estimate_paper_id'] = true;
            }
        }
        if (parseInt(values['estimate_type']) === 2) {
            if (values['estimate_multi_page_type'] === '' || values['estimate_multi_page_type'] === null) {
                errors['estimate_multi_page_type'] = true;
            }
            if (values['estimate_pages'] === '' || values['estimate_pages'] === null) {
                errors['estimate_pages'] = true;
            }
            if (values['estimate_second_press_id'] === '' || values['estimate_second_press_id'] === null) {
                errors['estimate_second_press_id'] = true;
            }
            if (values['estimate_second_running_style'] === '' || values['estimate_second_running_style'] === null) {
                errors['estimate_second_running_style'] = true;
            }
            if ([2, 3, 6, 7].includes(parseInt(values['estimate_multi_page_type']))) {
                if (values['estimate_cover_paper_category_id'] === '' || values['estimate_cover_paper_category_id'] === null) {
                    errors['estimate_cover_paper_category_id'] = true;
                }
                if (values['estimate_cover_paper_weight'] === '' || values['estimate_cover_paper_weight'] === null) {
                    errors['estimate_cover_paper_weight'] = true;
                }
                if (values['estimate_cover_paper_id'] === '' || values['estimate_cover_paper_id'] === null) {
                    errors['estimate_cover_paper_id'] = true;
                }
                if (values['estimate_cover_press_id'] === '' || values['estimate_cover_press_id'] === null) {
                    errors['estimate_cover_press_id'] = true;
                }
                if (values['estimate_cover_running_style'] === '' || values['estimate_cover_running_style'] === null) {
                    errors['estimate_cover_running_style'] = true;
                }
            }
        } else if (parseInt(values['estimate_type']) === 3) {
            if (values['estimate_digital_machine_id'] === '' || values['estimate_digital_machine_id'] === null) {
                errors['estimate_digital_machine_id'] = true;
            }
        } else if (parseInt(values['estimate_type']) === 4) {
            if (values['estimate_large_format_machine_id'] === '' || values['estimate_large_format_machine_id'] === null) {
                errors['estimate_large_format_machine_id'] = true;
            }
            if (values['estimate_substrate_id'] === '' || values['estimate_substrate_id'] === null) {
                errors['estimate_substrate_id'] = true;
            }
        }
    } else if (parseInt(values['estimate_type']) === 15) {
        if (results.length < 1) {
            showNotification('No Results', 'There are no results to save', 'info');
            return;
        }

        if (values['estimate_product_category_id'] === '' || values['estimate_product_category_id'] === null) {
            errors['estimate_product_category_id'] = true;
        }
        if (values['estimate_product_description'] === '' || values['estimate_product_description'] === null) {
            errors['estimate_product_description'] = true;
        }
        if (values['estimate_envelope_press_id'] === '' || values['estimate_envelope_press_id'] === null) {
            errors['estimate_envelope_press_id'] = true;
        }
        if (values['estimate_envelope_size'] === '' || values['estimate_envelope_size'] === null) {
            errors['estimate_envelope_size'] = true;
        }
        if (values['estimate_envelope_paper_style'] === '' || values['estimate_envelope_paper_style'] === null) {
            errors['estimate_envelope_paper_style'] = true;
        }
        if (values['estimate_envelope_paper_weight'] === '' || values['estimate_envelope_paper_weight'] === null) {
            errors['estimate_envelope_paper_weight'] = true;
        }
    } else {
        if (values['estimate_product_category_id'] === '' || values['estimate_product_category_id'] === null) {
            errors['estimate_product_category_id'] = true;
        }
        if (values['estimate_product_description'] === '' || values['estimate_product_description'] === null) {
            errors['estimate_product_description'] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let method = 'POST';
    let endpoint = apiUrls.estimates;
    if (values['estimate_id'] !== '') {
        method = 'PUT';
        endpoint = apiUrls.estimates + '/' + values['estimate_id'];
    }

    let fields = [
        'id',
        'type',
        'notes',
        'front_inks',
        'back_inks',
        'interior_inks',
        'multi_page_type',
        'product_category_name',
        'selected_quantity',
        'presses_names',
        'digital_machine_name',
        'large_format_machine_name',
        'substrate_name',
        'laminate_name',
        'paper_names',
        'folding_machine_name',
        'accessories_names',
        'binding_machine_name',
        'status_id',
        'approved_by',
        'approved_by_name',
        'approved_at',
        'have_artworks',
        'product_description',
        'data_processing_elements_ids',
        'data_processing_elements',
        'inkjet_elements_ids',
        'inkjet_elements',
        'inserting_elements_ids',
        'inserting_elements',
        'perfect_match_elements_ids',
        'perfect_match_elements',
        'art_elements_ids',
        'art_elements',
        'handwork_elements_ids',
        'handwork_elements',
        'pressure_seal_elements_ids',
        'pressure_seal_elements',
        'index_tab_elements_ids',
        'index_tab_elements',
        'label_elements_ids',
        'label_elements',
        'ncr_form_elements_ids',
        'ncr_form_elements',
        'estimateResults.quantity',
        'estimateResults.subtotal',
        'estimateResults.customer_discount',
        'estimateResults.tax',
        'estimateResults.rush_fee',
        'estimateResults.order_discount',
        'estimateResults.discount',
        'estimateResults.total',
        'customer_discount_id',
        'discount',
        'discount_type',
        'discount_percentage',
        'estimateFiles.*',
        'envelope_press_id',
        'envelope_press_name',
        'envelope_size',
        'envelope_paper_style',
        'envelope_paper_weight',
        'envelope_paper_id',
        'envelope_paper_name',
        'customer_provide',
        'print_on_flap'
    ];
    let extraFields = ['estimateResults', 'estimateFiles'];

    dispatch({ type: actionTypes.SAVE_ESTIMATE, payload: null });
    axios(
        {
            method: method,
            url: endpoint + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
            data: { values, results, orderId },
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ESTIMATE_SUCCESS, payload: response.data });
            let newElements = processNewData(estimates, response.data, values['estimate_id'] === '' ? 1 : 2);
            dispatch(setEstimates(newElements));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Estimate Saved!', 'The estimate has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setEstimates(estimates) {
    return {
        type: actionTypes.SET_ESTIMATES,
        payload: estimates
    };
}

export const deleteEstimate = (id, estimates) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ESTIMATE, payload: null });
    axios.delete(apiUrls.estimates + '/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ESTIMATE_SUCCESS, payload: response.data });
            let newElements = processNewData(estimates, id, 3);
            dispatch(setEstimates(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Estimate Deleted!', 'The estimate has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setItemQuantity(estimates, index, quantity) {
    estimates[index]['selected_quantity'] = quantity;
    return {
        type: actionTypes.SET_ITEM_QUANTITY,
        payload: estimates
    };
}


export function setEditingCustomer(editing) {
    return {
        type: actionTypes.SET_EDITING_CUSTOMER,
        payload: editing
    };
}

export function clearResults(value) {
    return {
        type: actionTypes.CLEAR_RESULTS,
        payload: value
    };
}

export function setProduct(products, productId) {
    let product = null;
    for (let i = 0; i < products.length; i++) {
        if (parseInt(products[i]['id']) === parseInt(productId)) {
            product = products[i];
        }
    }

    return {
        type: actionTypes.SET_PRODUCT,
        payload: product
    };
}

export function setAccessories(accessories, id, checked) {
    if (checked) {
        accessories.push(id);
    } else {
        for (let i = 0; i < accessories.length; i++) {
            if (parseInt(accessories[i]) === parseInt(id)) {
                accessories.splice(i, 1);
                break;
            }
        }
    }

    return {
        type: actionTypes.SET_ACCESSORIES,
        payload: accessories
    };
}

export function setFiles(files) {
    return {
        type: actionTypes.SET_FILES,
        payload: files
    };
}

export function setProgress(progress, type) {
    return {
        type: actionTypes.SET_PROGRESS,
        payload: {
            progress: numeral(progress).format('0'),
            type: type
        }
    };
}

export function uploadFiles(files, userId, estimateId, estimates) {
    return (dispatch, getState) => {
        let data = new FormData();

        for (let i = 0; i < files.length; i++) {
            data.append('file_' + i, files[i], files[i].name);
        }

        data.append('estimateId', estimateId);
        data.append('userId', userId);
        dispatch(setProgress(0, 'Uploading'));
        dispatch(setUploadingFile(true));

        dispatch({ type: actionTypes.UPLOAD_FILE, payload: null });
        axios({
            method: 'post',
            url: apiUrls.uploadFiles,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent) {
                let progress = progressEvent.loaded * 100 / progressEvent.total;
                dispatch(setProgress(progress, 'Uploading'));

                if (progress >= 100) {
                    interval = setInterval(function () {
                        /*axios({
                            method: 'get',
                            url: apiUrls.progress,
                            params: { userId }
                        }).then(function (response) {
                            if (!response.error) {
                                dispatch(setProgress(response.data, 'Processing'));
                            }
                        });*/
                    }, 500);
                }
            }
        }).then((response) => {
            clearInterval(interval);
            interval = null;
            if (response.error) {
                dispatch(uploadFileFailure());
                if (typeof response.data.error.message != 'undefined' && response.data.error.message != null) {
                    if (response.data.error.message.indexOf('Maximum execution time') !== -1) {
                        showNotification('File Too Big', 'Sorry the file is too big', 'info');
                    } else {
                        showNotification('Error', response.data.error.message, 'danger');
                    }
                } else {
                    showNotification('Error', 'Sorry a problem has occurred uploading the file', 'danger');
                }
            } else {
                for (let i = 0; i < estimates.length; i++) {
                    if (parseInt(estimates[i]['id']) === parseInt(response.data['estimateId'])) {
                        dispatch(addEstimateArtwork(i, response.data['files']));
                        break;
                    }
                }

                showNotification('File Loaded successfully', 'The file was uploaded successfully', 'success')
                dispatch(uploadFileSuccess());
                dispatch(dialog.actions.hideDialog('upload-artworks'));
            }
        }).catch(function (error) {
            clearInterval(interval);
            interval = null;
            showNotification('Error', 'Sorry a problem has occurred uploading the file', 'danger');
            dispatch(uploadFileFailure());
        });
    };
}

export function uploadFileSuccess() {
    return {
        type: actionTypes.UPLOAD_FILE_SUCCESS,
        payload: null
    };
}

export function uploadFileFailure() {
    return {
        type: actionTypes.UPLOAD_FILE_FAILURE,
        payload: ''
    };
}

export function setUploadingFile(value) {
    return {
        type: actionTypes.SET_UPLOADING_FILE,
        payload: value
    };
}

export function clearFiles() {
    return {
        type: actionTypes.CLEAR_FILES,
        payload: null
    };
}

export function setFileEstimateId(id) {
    return {
        type: actionTypes.SET_FILE_ESTIMATE_ID,
        payload: id
    };
}

export function setFileIdToDelete(id) {
    return {
        type: actionTypes.SED_FILE_ID_TO_DELETE,
        payload: id
    };
}

export const deleteFile = (id, estimates) => dispatch => {
    dispatch({ type: actionTypes.DELETE_FILE, payload: null });
    axios.delete(apiUrls.deleteFile + '?id=' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_FILE_SUCCESS, payload: response.data });
            for (let i = 0; i < estimates.length; i++) {
                if (parseInt(estimates[i]['id']) === parseInt(response.data['estimateId'])) {
                    dispatch(deleteEstimateArtwork(i, response.data['files']));
                    break;
                }
            }

            dispatch(dialog.actions.hideDialog('delete-file'));
            showNotification('Estimate Deleted!', 'The estimate has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_FILE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function addEstimateArtwork(index, files) {
    return {
        type: actionTypes.ADD_ESTIMATE_ARTWORK,
        payload: { index, files }
    };
}

export function deleteEstimateArtwork(index, files) {
    return {
        type: actionTypes.DELETE_ESTIMATE_ARTWORK,
        payload: { index, files }
    };
}

export const sendToProduction = (orderId, estimates) => (dispatch, getState) => {
    for (let i = 0; i < estimates.length; i++) {
        if (estimates[i]['have_artworks']) {
            continue;
        }

        if (estimates[i]['estimateFiles'].length === 0) {
            showNotification('No Artworks', 'One or more estimates do not have artworks', 'info');
            return;
        }

        if (parseInt(estimates[i]['status_id']) !== 2) {
            showNotification('Artworks Not Approved', 'One or more estimates are not approved', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SEND_TO_PRODUCTION, payload: null });
    axios.post(apiUrls.sendToProduction, { orderId },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SEND_TO_PRODUCTION_SUCCESS, payload: response.data });
            showNotification('Order in Production', 'Order moved to production successfully', 'success');
            const history = selectors.getHistory(getState());
            history.push('/production-order/update/' + response.data.id);
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SEND_TO_PRODUCTION_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function addOutsideService(value) {
    return {
        type: actionTypes.ADD_OUTSIDE_SERVICE,
        payload: value
    };
}

export function deleteOutsideService(index) {
    return {
        type: actionTypes.DELETE_OUTSIDE_SERVICE,
        payload: index
    };
}

export function updateOutsideService(index, value) {
    return {
        type: actionTypes.UPDATE_OUTSIDE_SERVICE,
        payload: { index, value }
    };
}

export function addMailingElement(name, value) {
    return {
        type: actionTypes.ADD_MAILING_ELEMENT,
        payload: { name, value }
    };
}

export function updateMailingElement(name, attribute, index, value) {
    return {
        type: actionTypes.UPDATE_MAILING_ELEMENT,
        payload: { name, attribute, index, value }
    };
}

export function removeMailingElement(name, index) {
    return {
        type: actionTypes.REMOVE_MAILING_ELEMENT,
        payload: { name, index }
    };
}

export function setOptions(name, data) {
    return {
        type: actionTypes.SET_OPTIONS,
        payload: { name, data }
    };
}

export function loadOptions(data, useNone = false) {
    let options = [];
    if (useNone) {
        options.push({ label: 'None', value: '' });
    } else {
        options.push({ label: 'Select option', value: '' });
    }

    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['name'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadCustomers(data, useNone = false) {
    let options = [];
    if (useNone) {
        options.push({ label: 'None', value: '' });
    } else {
        options.push({ label: 'Select option', value: '' });
    }

    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['display_name'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadEnvelopePaperSizesOptions(data) {
    let keys = [];

    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        const widths = (data[i]['size_width'].toString()).split('.');
        const heights = (data[i]['size_height'].toString()).split('.');
        let width = data[i]['size_width'];
        let height = data[i]['size_height'];

        if (widths.length === 2) {
            let widthFraction = widths[1];
            if (parseInt(widths[1]) === 125) {
                widthFraction = '1/8';
                width = widths[0] + ' ' + widthFraction;
            } else if (parseInt(widths[1]) === 25) {
                widthFraction = '1/4';
                width = widths[0] + ' ' + widthFraction;
            } else if (parseInt(widths[1]) === 375) {
                widthFraction = '3/8';
                width = widths[0] + ' ' + widthFraction;
            } else if (parseInt(widths[1]) === 5) {
                widthFraction = '1/2';
                width = widths[0] + ' ' + widthFraction;
            } else if (parseInt(widths[1]) === 625) {
                widthFraction = '5/8';
                width = widths[0] + ' ' + widthFraction;
            } else if (parseInt(widths[1]) === 75) {
                widthFraction = '3/4';
                width = widths[0] + ' ' + widthFraction;
            } else if (parseInt(widths[1]) === 875) {
                widthFraction = '7/8';
                width = widths[0] + ' ' + widthFraction;
            } else {
                width = widths[0] + '.' + widthFraction;
            }
        }

        if (heights.length === 2) {
            let heightFraction = heights[1];
            if (parseInt(heights[1]) === 125) {
                heightFraction = '1/8';
                height = heights[0] + ' ' + heightFraction;
            } else if (parseInt(heights[1]) === 25) {
                heightFraction = '1/4';
                height = heights[0] + ' ' + heightFraction;
            } else if (parseInt(heights[1]) === 375) {
                heightFraction = '3/8';
                height = heights[0] + ' ' + heightFraction;
            } else if (parseInt(heights[1]) === 5) {
                heightFraction = '1/2';
                height = heights[0] + ' ' + heightFraction;
            } else if (parseInt(heights[1]) === 625) {
                heightFraction = '5/8';
                height = heights[0] + ' ' + heightFraction;
            } else if (parseInt(heights[1]) === 75) {
                heightFraction = '3/4';
                height = heights[0] + ' ' + heightFraction;
            } else if (parseInt(heights[1]) === 875) {
                heightFraction = '7/8';
                height = heights[0] + ' ' + heightFraction;
            } else {
                height = heights[0] + '.' + heightFraction;
            }
        }

        const key = width + ' x ' + height;
        if (!keys.includes(key)) {
            keys.push(key);
            options.push({
                label: key,
                value: data[i]['id'],
                element: data[i]
            });
        }
    }
    return options;
}

export function loadEnvelopePaperTypesOptions(data) {
    let keys = [];
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        const key = data[i]['envelope_type_name'];
        if (!keys.includes(key)) {
            keys.push(key);
            options.push({
                label: key,
                value: data[i]['id'],
                element: data[i]
            });
        }
    }
    return options;
}

export function loadEnvelopePaperStylesOptions(data) {
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['style'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadEnvelopePaperWeightsOptions(data) {
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['base_weight'] + '#',
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadCustomerDiscountOptions(data) {
    let options = [{ label: 'None', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['long_name'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadPackagesOptions(data) {
    let options = [{ label: 'None', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['width'] + 'x' + data[i]['length'] + 'x' + data[i]['height'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadProductCategoriesOptions(data) {
    let options = [{ label: 'Select option', value: '', type: 0 }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['name'],
            value: data[i]['id'],
            type: data[i]['type'],
            element: data[i]
        });
    }
    return options;
}

export function loadPaperCategoriesOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i]['category_name'],
                value: data[i]['id'],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPaperCategories' : 'paperCategories', options));
    };
}

export function loadPaperWeightsOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i] + '#',
                value: data[i],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPaperWeights' : 'paperWeights', options));
    };
}

export function loadPapersOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '', coating_one_side: 0 }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i]['paper_name'] + ' ' + data[i]['width'] + 'x' + data[i]['length'],
                value: data[i]['id'],
                coating_one_side: data[i]['coating_one_side'],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPapers' : 'papers', options));
    };
}

export function loadDataProcessingOptions(data) {
    let options = [{ label: 'Select option', value: '', type: 0 }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['name'],
            value: data[i]['id'],
            type: data[i]['type'],
            element: data[i]
        });
    }
    return options;
}

export function intersectPresses() {
    return (dispatch, getState) => {
        let information = selectors.getInformation(getState());

        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < information['presses'].length; i++) {
            options.push({ label: information['presses'][i]['name'], value: information['presses'][i]['id'] });
        }

        dispatch(setOptions('presses', options));
    };
}

export const intersectPaperCategories = (formValues, isCover = false) => dispatch => {
    dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES, payload: null });
    axios.post(apiUrls.intersectPaperCategories,
        {
            isCover: isCover ? 1 : 0,
            type: formValues['estimate_type'],
            pressId: isCover ? formValues['estimate_cover_press_id'] : formValues['estimate_press_id'],
            secondPressId: isCover ? null : formValues['estimate_second_press_id'],
            digitalMachineId: formValues['estimate_digital_machine_id'],
            productId: formValues['estimate_product_id']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES_SUCCESS, payload: response.data });
            dispatch(setLoading(isCover ? 'coverPaperCategories' : 'paperCategories', false));
            dispatch(loadPaperCategoriesOptions(response.data, isCover));
        })
        .catch(error => {
            dispatch(setLoading(isCover ? 'coverPaperCategories' : 'paperCategories', false));
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const intersectPaperWeights = (formValues, isCover = false) => dispatch => {
    dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS, payload: null });
    axios.post(apiUrls.intersectPaperWeights,
        {
            isCover: isCover ? 1 : 0,
            type: formValues['estimate_type'],
            pressId: isCover ? formValues['estimate_cover_press_id'] : formValues['estimate_press_id'],
            secondPressId: isCover ? null : formValues['estimate_second_press_id'],
            digitalMachineId: formValues['estimate_digital_machine_id'],
            productId: formValues['estimate_product_id'],
            paperCategoryId: isCover ? formValues['estimate_cover_paper_category_id'] : formValues['estimate_paper_category_id']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS_SUCCESS, payload: response.data });
            dispatch(setLoading(isCover ? 'coverPaperWeights' : 'paperWeights', false));
            dispatch(loadPaperWeightsOptions(response.data, isCover));
        })
        .catch(error => {
            dispatch(setLoading(isCover ? 'coverPaperWeights' : 'paperWeights', false));
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const intersectPapers = (formValues, isCover = false) => dispatch => {
    dispatch({ type: actionTypes.INTERSECT_PAPERS, payload: null });
    axios.post(apiUrls.intersectPapers,
        {
            isCover: isCover ? 1 : 0,
            type: formValues['estimate_type'],
            pressId: isCover ? formValues['estimate_cover_press_id'] : formValues['estimate_press_id'],
            secondPressId: isCover ? null : formValues['estimate_second_press_id'],
            digitalMachineId: formValues['estimate_digital_machine_id'],
            productId: formValues['estimate_product_id'],
            paperCategoryId: isCover ? formValues['estimate_cover_paper_category_id'] : formValues['estimate_paper_category_id'],
            paperWeight: isCover ? formValues['estimate_cover_paper_weight'] : formValues['estimate_paper_weight']
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPERS_SUCCESS, payload: response.data });
            dispatch(setLoading(isCover ? 'coverPapers' : 'papers', false));
            dispatch(loadPapersOptions(response.data, isCover));
        })
        .catch(error => {
            dispatch(setLoading(isCover ? 'coverPapers' : 'papers', false));
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPERS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function setLoading(name, value) {
    return {
        type: actionTypes.SET_LOADING,
        payload: { name, value }
    };
}

export function resetOptions(name) {
    return {
        type: actionTypes.RESET_OPTIONS,
        payload: name
    };
}

export function updateState(values) {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: values
    };
}

export function updateEstimateStatus(index, value) {
    return {
        type: actionTypes.UPDATE_ESTIMATE_STATUS,
        payload: { index, value }
    };
}

export function updateEstimateHaveArtworks(index, value) {
    return {
        type: actionTypes.UPDATE_ESTIMATE_HAVE_ARTWORKS,
        payload: { index, value }
    };
}

export const calculateShipping = (shippingMethod) => (dispatch, getState) => {
    let values = selectors.getFormValues(getState());

    if (values['shipping_method'] == null || values['shipping_method'] === '') {
        return;
    }

    dispatch({ type: actionTypes.CALCULATE_SHIPPING, payload: null });
    axios.post(apiUrls.calculateShipping,
        { orderId: values['id'], shippingMethod: shippingMethod },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.CALCULATE_SHIPPING_SUCCESS, payload: response.data });
            if (response.data === -1) {
                showNotification('Method Not Available', 'The selected shipping method is not available for your order, please select a different method', 'info');
            }
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.CALCULATE_SHIPPING_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchEstimate = id => (dispatch, getState) => {
    let formValues = selectors.getFormValues(getState());
    let extraFields = ['estimateResults', 'estimateFiles'];

    dispatch({ type: actionTypes.FETCH_ESTIMATE, payload: null });
    axios.get(apiUrls.estimates + '/' + id + '?expand=' + extraFields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ESTIMATE_SUCCESS, payload: response.data });
            dispatch(fetchEstimateInformation(response.data.type, response.data));

            let fetching = {};
            let options = {};
            let newState = {};

            fetching.estimate = false;
            dispatch(setActiveElement(response.data));

            if ([1, 2, 3].includes(response.data.type)) {
                formValues = selectors.getFormValues(getState());
                dispatch(intersectPresses());
                dispatch(intersectPaperCategories(formValues));
                dispatch(intersectPaperWeights(formValues));
                dispatch(intersectPapers(formValues));
                fetching.paperCategories = true;
                fetching.paperWeights = true;
                fetching.papers = true;
                options.paperCategories = [];
                options.paperWeights = [];
                options.papers = [];

                if ([2, 3, 6, 7].includes(response.data['multi_page_type'])) {
                    dispatch(intersectPaperCategories(formValues, true));
                    dispatch(intersectPaperWeights(formValues, true));
                    dispatch(intersectPapers(formValues, true));
                    fetching.coverPaperCategories = true;
                    fetching.coverPaperWeights = true;
                    fetching.coverPapers = true;
                    options.coverPaperCategories = [];
                    options.coverPaperWeights = [];
                    options.coverPapers = [];
                }
            }

            let keys = Object.keys(fetching);
            if (keys.length > 0) {
                newState.fetching = fetching;
            }

            keys = Object.keys(options);
            if (keys.length > 0) {
                newState.options = options;
            }

            dispatch(updateState(newState));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ESTIMATE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const changeEstimateStatus = (id, value) => (dispatch, getState) => {
    let information = selectors.getInformation(getState());
    let fields = [
        'id',
        'type',
        'notes',
        'front_inks',
        'back_inks',
        'interior_inks',
        'multi_page_type',
        'product_category_name',
        'selected_quantity',
        'presses_names',
        'digital_machine_name',
        'large_format_machine_name',
        'substrate_name',
        'laminate_name',
        'paper_names',
        'folding_machine_name',
        'accessories_names',
        'binding_machine_name',
        'status_id',
        'approved_by',
        'approved_by_name',
        'approved_at',
        'have_artworks',
        'product_description',
        'data_processing_elements_ids',
        'data_processing_elements',
        'inkjet_elements_ids',
        'inkjet_elements',
        'inserting_elements_ids',
        'inserting_elements',
        'perfect_match_elements_ids',
        'perfect_match_elements',
        'art_elements_ids',
        'art_elements',
        'handwork_elements_ids',
        'handwork_elements',
        'pressure_seal_elements_ids',
        'pressure_seal_elements',
        'index_tab_elements_ids',
        'index_tab_elements',
        'label_elements_ids',
        'label_elements',
        'ncr_form_elements_ids',
        'ncr_form_elements',
        'estimateResults.quantity',
        'estimateResults.subtotal',
        'estimateResults.customer_discount',
        'estimateResults.tax',
        'estimateResults.rush_fee',
        'estimateResults.order_discount',
        'estimateResults.discount',
        'estimateResults.total',
        'estimateFiles.*',
        'estimates.envelope_press_id',
        'estimates.envelope_press_name',
        'estimates.envelope_size',
        'estimates.envelope_paper_style',
        'estimates.envelope_paper_weight',
        'estimates.envelope_paper_id',
        'estimates.envelope_paper_name',
        'estimates.customer_provide',
        'estimates.print_on_flap',
    ];
    let extraFields = ['estimateResults', 'estimateFiles'];

    dispatch({ type: actionTypes.CHANGE_ESTIMATE_STATUS, payload: null });
    axios.post(apiUrls.changeStatus + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
        { id, value },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.CHANGE_ESTIMATE_STATUS_SUCCESS, payload: response.data });
            let newElements = processNewData(information['estimates'], response.data, 2);
            dispatch(setEstimates(newElements));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.CHANGE_ESTIMATE_STATUS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const changeEstimateHaveArtworks = (id, value) => (dispatch, getState) => {
    let information = selectors.getInformation(getState());
    let fields = [
        'id',
        'type',
        'notes',
        'front_inks',
        'back_inks',
        'interior_inks',
        'multi_page_type',
        'product_category_name',
        'selected_quantity',
        'presses_names',
        'digital_machine_name',
        'large_format_machine_name',
        'substrate_name',
        'laminate_name',
        'paper_names',
        'folding_machine_name',
        'accessories_names',
        'binding_machine_name',
        'status_id',
        'approved_by',
        'approved_by_name',
        'approved_at',
        'have_artworks',
        'product_description',
        'data_processing_elements_ids',
        'data_processing_elements',
        'inkjet_elements_ids',
        'inkjet_elements',
        'inserting_elements_ids',
        'inserting_elements',
        'perfect_match_elements_ids',
        'perfect_match_elements',
        'art_elements_ids',
        'art_elements',
        'handwork_elements_ids',
        'handwork_elements',
        'pressure_seal_elements_ids',
        'pressure_seal_elements',
        'index_tab_elements_ids',
        'index_tab_elements',
        'label_elements_ids',
        'label_elements',
        'ncr_form_elements_ids',
        'ncr_form_elements',
        'estimateResults.quantity',
        'estimateResults.subtotal',
        'estimateResults.customer_discount',
        'estimateResults.tax',
        'estimateResults.rush_fee',
        'estimateResults.order_discount',
        'estimateResults.discount',
        'estimateResults.total',
        'estimateFiles.*',
        'estimates.envelope_press_id',
        'estimates.envelope_press_name',
        'estimates.envelope_size',
        'estimates.envelope_paper_style',
        'estimates.envelope_paper_weight',
        'estimates.envelope_paper_id',
        'estimates.envelope_paper_name',
        'estimates.customer_provide',
        'estimates.print_on_flap',
    ];
    let extraFields = ['estimateResults', 'estimateFiles'];

    dispatch({ type: actionTypes.CHANGE_ESTIMATE_HAVE_ARTWORKS, payload: null });
    axios.post(apiUrls.changeHaveArtworks + '?fields=' + fields.join(',') + '&expand=' + extraFields.join(','),
        { id, value },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.CHANGE_ESTIMATE_HAVE_ARTWORKS_SUCCESS, payload: response.data });
            let newElements = processNewData(information['estimates'], response.data, 2);
            dispatch(setEstimates(newElements));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.CHANGE_ESTIMATE_HAVE_ARTWORKS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function calculateDataProcessingPrice(formValues, options, type) {
    let price = 0;
    for (let i = 0; i < formValues['estimate_data_processing_elements'].length; i++) {
        let dataElement = formValues['estimate_data_processing_elements'][i];
        if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
            let selectedElement = null;
            for (let j = 0; j < options['dataProcessingElements'].length; j++) {
                let element = options['dataProcessingElements'][j]['element'];
                if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id']) && parseInt(type) === parseInt(dataElement['type'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement == null) {
                continue;
            }

            let total = selectedElement['unit_price'] * dataElement['quantity'];
            if (parseInt(selectedElement['unit']) === 1) {
                total = selectedElement['unit_price'] * (Math.ceil(dataElement['quantity'] / 1000));
            }

            for (let i = 0; i < selectedElement['discounts'].length; i++) {
                if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                    && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                    total -= total * selectedElement['discounts'][i]['discount'];
                    break;
                }

            }

            if (total < selectedElement['minimum_charge']) {
                total = parseFloat(selectedElement['minimum_charge']);
            }

            price += total * (1 + parseFloat(selectedElement['markup']));
        }
    }
    return numeral(price).format('0.00');
}

export function calculateMailingPrice(formValues, options, formName, optionsName) {
    let price = 0;
    for (let i = 0; i < formValues[formName].length; i++) {
        let dataElement = formValues[formName][i];
        if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
            let selectedElement = null;
            for (let j = 0; j < options[optionsName].length; j++) {
                let element = options[optionsName][j]['element'];
                if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement == null) {
                continue;
            }

            let total = selectedElement['unit_price'] * dataElement['quantity'];
            if (parseInt(selectedElement['unit']) === 1) {
                total = selectedElement['unit_price'] * (Math.ceil(dataElement['quantity'] / 1000));
            }

            for (let i = 0; i < selectedElement['discounts'].length; i++) {
                if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                    && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                    total -= total * selectedElement['discounts'][i]['discount'];
                    break;
                }

            }

            if (total < selectedElement['minimum_charge']) {
                total = parseFloat(selectedElement['minimum_charge']);
            }

            price += total * (1 + parseFloat(selectedElement['markup']));
        }
    }
    return numeral(price).format('0.00');
}

export function calculateMailingPriceTables(formValues, options, formName, optionsName) {
    let price = 0;
    for (let i = 0; i < formValues[formName].length; i++) {
        let dataElement = formValues[formName][i];
        if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
            let selectedElement = null;
            for (let j = 0; j < options[optionsName].length; j++) {
                let element = options[optionsName][j]['element'];
                if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement == null) {
                continue;
            }

            let total = null;
            let costElement = null;
            if (selectedElement != null) {
                for (let i = 0; i < selectedElement['costs'].length; i++) {
                    if (dataElement['quantity'] >= parseInt(selectedElement['costs'][i]['quantity_start'])
                        && dataElement['quantity'] <= parseInt(selectedElement['costs'][i]['quantity_end'])) {
                        costElement = selectedElement['costs'][i];
                        break;
                    }
                }

                if (costElement != null) {
                    total = parseFloat(costElement['cost']);

                    for (let i = 0; i < selectedElement['discounts'].length; i++) {
                        if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                            && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                            total -= total * selectedElement['discounts'][i]['discount'];
                            break;
                        }

                    }

                    price += total * (1 + parseFloat(selectedElement['markup']));
                }
            }
        }
    }
    return numeral(price).format('0.00');
}

export function updateMailingPrices() {
    return (dispatch, getState) => {
        let formValues = selectors.getFormValues(getState());
        let options = selectors.getOptions(getState());
        let information = selectors.getInformation(getState());

        let dataProcessingPrice1 = calculateDataProcessingPrice(formValues, options, 1);
        let dataProcessingPrice2 = calculateDataProcessingPrice(formValues, options, 2);
        let dataProcessingPrice3 = calculateDataProcessingPrice(formValues, options, 3);
        let inkjetPrice = calculateMailingPrice(formValues, options, 'estimate_inkjet_elements', 'inkjetElements');
        let insertingPrice = calculateMailingPrice(formValues, options, 'estimate_inserting_elements', 'insertingElements');
        let perfectMatchPrice = calculateMailingPrice(formValues, options, 'estimate_perfect_match_elements', 'perfectMatchElements');
        let artPrice = calculateMailingPrice(formValues, options, 'estimate_art_elements', 'artElements');
        let handworkPrice = calculateMailingPrice(formValues, options, 'estimate_handwork_elements', 'handworkElements');

        let pressureSealPrices = [0, 0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < formValues['estimate_pressure_seal_elements'].length; i++) {
            let dataElement = formValues['estimate_pressure_seal_elements'][i];
            if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
                let selectedElement = null;
                for (let j = 0; j < options['pressureSealElements'].length; j++) {
                    let element = options['pressureSealElements'][j]['element'];
                    if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                        selectedElement = element;
                        break;
                    }
                }

                let total = null;
                if (selectedElement != null) {
                    for (let i = 0; i < selectedElement['costs'].length; i++) {
                        if (dataElement['quantity'] >= parseInt(selectedElement['costs'][i]['quantity_start'])
                            && dataElement['quantity'] <= parseInt(selectedElement['costs'][i]['quantity_end'])) {
                            total = parseFloat(selectedElement['costs'][i]['cost']);

                            for (let i = 0; i < selectedElement['discounts'].length; i++) {
                                if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                                    && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                                    total -= total * selectedElement['discounts'][i]['discount'];
                                    break;
                                }
                            }

                            pressureSealPrices[selectedElement['costs'][i]['type'] - 1] += total;
                        }
                    }
                }
            }
        }
        for (let i = 0; i < pressureSealPrices.length; i++) {
            pressureSealPrices[i] = numeral(pressureSealPrices[i]).format('0.00');
            pressureSealPrices[i] = numeral(pressureSealPrices[i]).format('0.00');
            pressureSealPrices[i] = numeral(pressureSealPrices[i]).format('0.00');
        }

        let indexTabPrice = calculateMailingPriceTables(formValues, options, 'estimate_index_tab_elements', 'indexTabElements');
        let labelPrice = calculateMailingPriceTables(formValues, options, 'estimate_label_elements', 'labelElements');
        let ncrFormPrice = calculateMailingPriceTables(formValues, options, 'estimate_ncr_form_elements', 'ncrFormElements');

        let outsideServicesPrice = 0;
        for (let i = 0; i < formValues['estimate_outside_services'].length; i++) {
            let element = formValues['estimate_outside_services'][i];
            if (element['description'] !== '' && element['cost'] !== '' && element['percentage_markup'] !== '') {
                outsideServicesPrice += parseFloat(element['cost'])
                    * (1 + parseFloat(element['percentage_markup']));
            }
        }

        let discount = 0;
        if (formValues['estimate_customer_discount_id'] !== '') {
            let customerDiscount = null;
            for (let i = 0; i < information['customerDiscounts'].length; i++) {
                if (parseInt(information['customerDiscounts'][i]['id']) === parseInt(formValues['estimate_customer_discount_id'])) {
                    customerDiscount = information['customerDiscounts'][i];
                    break;
                }
            }

            if (customerDiscount != null) {
                if (parseInt(customerDiscount['type']) === 1) {
                    discount = -customerDiscount['value'];
                } else {
                    discount = customerDiscount['value'];
                }
            }
        }

        dispatch({
            type: actionTypes.UPDATE_MAILING_PRICES,
            payload: {
                estimate_data_processing_price_1: dataProcessingPrice1,
                estimate_data_processing_price_2: dataProcessingPrice2,
                estimate_data_processing_price_3: dataProcessingPrice3,
                estimate_inkjet_price: inkjetPrice,
                estimate_inserting_price: insertingPrice,
                estimate_perfect_match_price: perfectMatchPrice,
                estimate_art_price: artPrice,
                estimate_handwork_price: handworkPrice,
                estimate_pressure_seal_price_1: pressureSealPrices[0],
                estimate_pressure_seal_price_2: pressureSealPrices[1],
                estimate_pressure_seal_price_3: pressureSealPrices[2],
                estimate_pressure_seal_price_4: pressureSealPrices[3],
                estimate_pressure_seal_price_5: pressureSealPrices[4],
                estimate_pressure_seal_price_6: pressureSealPrices[5],
                estimate_pressure_seal_price_7: pressureSealPrices[6],
                estimate_pressure_seal_price_8: pressureSealPrices[7],
                estimate_index_tab_price: indexTabPrice,
                estimate_label_price: labelPrice,
                estimate_ncr_form_price: ncrFormPrice,
                estimate_outside_services_price: outsideServicesPrice,
                estimate_discount_percentage: discount
            }
        });
    };
}

export function setHistory(history) {
    return {
        type: actionTypes.SET_HISTORY,
        payload: history
    };
}

export const fetchCustomers = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_CUSTOMERS, payload: null });
    axios.get(apiUrls.customers + '?fields=id,name,display_name',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_CUSTOMERS_SUCCESS, payload: response.data });
            dispatch(updateState({ options: { customers: loadCustomers(response.data) } }));
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_CUSTOMERS_FAILURE));
        });
}

export const fetchCustomer = id => dispatch => {
    dispatch({ type: actionTypes.FETCH_CUSTOMER, payload: null });
    axios.get(apiUrls.customers + '/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_CUSTOMER_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_CUSTOMER_FAILURE));
        });
}

export const filterEnvelopePaperStyles = value => (dispatch, getState) => {
    const information = selectors.getInformation(getState());
    let selectedElement = null;
    let styles = [];
    let papers = [];

    for (let i = 0; i < information.envelopePapers.length; i++) {
        const element = information.envelopePapers[i];
        if (parseInt(element.id) === parseInt(value)) {
            selectedElement = element;
            break;
        }
    }

    if (selectedElement === null) {
        return;
    }

    for (let i = 0; i < information.envelopePapers.length; i++) {
        const element = information.envelopePapers[i];
        if (element.envelope_type_id === selectedElement.envelope_type_id && !styles.includes(element.style)) {
            styles.push(element.style);
            papers.push(element);
        }
    }

    dispatch(updateState({
        options: {
            envelopePaperStyles: loadEnvelopePaperStylesOptions(papers)
        }
    }));
}

export const filterEnvelopePaperSizes = value => (dispatch, getState) => {
    const information = selectors.getInformation(getState());
    let selectedElement = null;
    let sizes = [];
    let papers = [];

    for (let i = 0; i < information.envelopePapers.length; i++) {
        const element = information.envelopePapers[i];
        if (parseInt(element.id) === parseInt(value)) {
            selectedElement = element;
            break;
        }
    }

    if (selectedElement === null) {
        return;
    }

    for (let i = 0; i < information.envelopePapers.length; i++) {
        const element = information.envelopePapers[i];
        const key = element.size_width + ' x ' + element.size_height;
        if (element.envelope_type_id === selectedElement.envelope_type_id && element.style === selectedElement.style && !sizes.includes(key)) {
            sizes.push(key);
            papers.push(element);
        }
    }

    dispatch(updateState({
        options: {
            envelopePaperSizes: loadEnvelopePaperSizesOptions(papers)
        }
    }));
}

export const filterEnvelopePaperWeights = value => (dispatch, getState) => {
    const information = selectors.getInformation(getState());
    let selectedElement = null;
    let weights = [];
    let papers = [];

    for (let i = 0; i < information.envelopePapers.length; i++) {
        const element = information.envelopePapers[i];
        if (parseInt(element.id) === parseInt(value)) {
            selectedElement = element;
            break;
        }
    }

    if (selectedElement === null) {
        return;
    }

    for (let i = 0; i < information.envelopePapers.length; i++) {
        const element = information.envelopePapers[i];
        if (element.envelope_type_id === selectedElement.envelope_type_id && element.size_width === selectedElement.size_width
            && element.size_height === selectedElement.size_height && element.style === selectedElement.style && !weights.includes(element.base_weight)) {
            weights.push(element.base_weight);
            papers.push(element);
        }
    }

    dispatch(updateState({
        options: {
            envelopePaperWeights: loadEnvelopePaperWeightsOptions(papers)
        }
    }));
}
