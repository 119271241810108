import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeFilter: PropTypes.func,
        user: PropTypes.object,
        filterFields: PropTypes.object
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, isFetchingElements, handleShowForm, handleShowDelete } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="12"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements.length === 0) {
            return (
                <tr>
                    <td colSpan="12">No Results</td>
                </tr>
            );
        }

        let data = [];
        for (let i = 0; i < elements.length; i++) {
            let className = null;
            if (elements[i].todo && elements[i].expired && !elements[i].completed) {
                className = 'expired'
            }

            data.push(
                <tr key={i} className={className}>
                    <td>{moment(elements[i].date).format('MM/DD/YYYY')}</td>
                    <td>{elements[i].method}</td>
                    <td>{elements[i].todo ? 'Yes' : 'No'}</td>
                    <td>{elements[i].present}</td>
                    <td>{elements[i].topic}</td>
                    <td>{elements[i].notes}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements[i].id, null)} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, elements[i].id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    handleKey(name, event) {
        const { handleChangeFilter } = this.props;
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onBlur={handleChangeFilter.bind(this, name)} onKeyPress={this.handleKey.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { totalPages, selectedPage, elementsPerPage, totalItems, handleChangeFilter, handleSetActivePage } = this.props;
        return (
            <div id="timeline-logs-table" className="production-container timeline-logs-table">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('Date', 'date', { width: 160 })}
                                {this.renderTableHeader('Method', 'method', { width: 120 })}
                                {this.renderTableHeader('To-Do', 'todo', { width: 80 })}
                                {this.renderTableHeader('People Present', 'present')}
                                {this.renderTableHeader('Topic', 'topic')}
                                {this.renderTableHeader('Notes', 'notes')}
                                <th style={{ width: 80 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="search-th multiple-date">
                                    <DayPickerInput
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder=""
                                        format="MM/DD/YY"
                                        onDayChange={handleChangeFilter.bind(this, 'date')}
                                    />
                                </th>
                                {this.renderSearchField('method')}
                                {this.renderSearchSelect('todo', [
                                    { label: '', value: '' },
                                    { label: 'Yes', value: 1 },
                                    { label: 'No', value: 0 }
                                ])}
                                {this.renderSearchField('present')}
                                {this.renderSearchField('topic')}
                                {this.renderSearchField('notes')}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={totalItems}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                        handleSetActivePage={handleSetActivePage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
