import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pagination extends Component {
    static propTypes = {
        totalItems: PropTypes.number,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        setActivePage: PropTypes.func,
        handleSetNextPage: PropTypes.func,
        handleSetPrevPage: PropTypes.func
    };

    renderPages() {
        const { totalPages, selectedPage, setActivePage } = this.props;

        let startIndex = null;
        if (totalPages <= 7) {
            startIndex = 1;
        } else {
            startIndex = selectedPage - 3;
            if (startIndex < 1) {
                startIndex = 1;
            }
        }

        let pages = [];
        for (let i = startIndex; i <= totalPages && i <= startIndex + 6; i++) {
            pages.push(<li className={parseInt(selectedPage) === i ? 'active' : ''} onClick={setActivePage.bind(this, i)}>{i}</li>);
        }
        return pages;
    }

    render() {
        const { totalItems, handleSetNextPage, handleSetPrevPage, selectedPage, elementsPerPage } = this.props;

        let startElement = 0;
        let endElement = 0;
        if (totalItems > 0) {
            startElement = (selectedPage - 1) * elementsPerPage + 1;
            endElement = startElement + elementsPerPage - 1;
            if (endElement > totalItems) {
                endElement = totalItems;
            }
        }

        return (
            <div className="pagination-container">
                <ul>
                    <li onClick={handleSetPrevPage}><i className="fas fa-chevron-left"></i></li>
                    {this.renderPages()}
                    <li onClick={handleSetNextPage}><i className="fas fa-chevron-right"></i></li>
                </ul>
                <div className="total-items">Showing {startElement}-{endElement} of {totalItems} items</div>
                <div className="clearfix"></div>
            </div>
        );
    }
}

export default Pagination;
