import React, { Component } from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class EstimateMailingPricing extends Component {
    loadPrices(name) {
        const { formValues } = this.props;

        let prices = [];
        for (let i = 0; i < 3; i++) {
            if (formValues[name + (i + 1)] !== '' && formValues[name + (i + 1)] != null) {
                prices.push(parseFloat(formValues[name + (i + 1)]));
            } else {
                prices.push(0);
            }
        }

        return prices;
    }

    renderTooltip(cost, tooltipHtml) {
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(cost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderMailingTooltip(cost, name) {
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {name}</div>
            </div>
        );
        return this.renderTooltip(cost, tooltipHtml);
    }

    getDataProcessingCostAndNames(type) {
        const { formValues, options } = this.props;

        let cost = 0;
        let names = [];
        for (let i = 0; i < formValues['estimate_data_processing_elements'].length; i++) {
            let dataElement = formValues['estimate_data_processing_elements'][i];
            if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
                let selectedElement = null;
                for (let j = 0; j < options['dataProcessingElements'].length; j++) {
                    let element = options['dataProcessingElements'][j]['element'];
                    if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id']) && parseInt(type) === parseInt(dataElement['type'])) {
                        selectedElement = element;
                        break;
                    }
                }

                if (selectedElement == null) {
                    continue;
                }

                let total = selectedElement['unit_price'] * dataElement['quantity'];
                if (parseInt(selectedElement['unit']) === 1) {
                    total = selectedElement['unit_price'] * (Math.ceil(dataElement['quantity'] / 1000));
                }

                for (let i = 0; i < selectedElement['discounts'].length; i++) {
                    if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                        && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                        total -= total * selectedElement['discounts'][i]['discount'];
                        break;
                    }
                }

                if (total < selectedElement['minimum_charge']) {
                    total = parseFloat(selectedElement['minimum_charge']);
                }

                cost += total;
                names.push(selectedElement['name']);
            }
        }

        return { cost: cost, names: names };
    }

    getMailingCostAndNames(name, optionsName) {
        const { formValues, options } = this.props;

        let cost = 0;
        let names = [];
        for (let i = 0; i < formValues[name].length; i++) {
            let dataElement = formValues[name][i];
            if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
                let selectedElement = null;
                for (let j = 0; j < options[optionsName].length; j++) {
                    let element = options[optionsName][j]['element'];
                    if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                        selectedElement = element;
                        break;
                    }
                }

                if (selectedElement == null) {
                    continue;
                }

                let total = selectedElement['unit_price'] * dataElement['quantity'];
                if (parseInt(selectedElement['unit']) === 1) {
                    total = selectedElement['unit_price'] * (Math.ceil(dataElement['quantity'] / 1000));
                }

                for (let i = 0; i < selectedElement['discounts'].length; i++) {
                    if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                        && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                        total -= total * selectedElement['discounts'][i]['discount'];
                        break;
                    }
                }

                if (total < selectedElement['minimum_charge']) {
                    total = parseFloat(selectedElement['minimum_charge']);
                }

                cost += total;
                names.push(selectedElement['name']);
            }
        }

        return { cost: cost, names: names };
    }

    getMailingCostAndNamesTables(name, optionsName) {
        const { formValues, options } = this.props;

        let cost = 0;
        let names = [];
        for (let i = 0; i < formValues[name].length; i++) {
            let dataElement = formValues[name][i];
            if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
                let selectedElement = null;
                for (let j = 0; j < options[optionsName].length; j++) {
                    let element = options[optionsName][j]['element'];
                    if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                        selectedElement = element;
                        break;
                    }
                }

                let total = null;
                let costElement = null;
                if (selectedElement != null) {
                    for (let i = 0; i < selectedElement['costs'].length; i++) {
                        if (dataElement['quantity'] >= parseInt(selectedElement['costs'][i]['quantity_start'])
                            && dataElement['quantity'] <= parseInt(selectedElement['costs'][i]['quantity_end'])) {
                            costElement = selectedElement['costs'][i];
                            break;
                        }
                    }

                    if (costElement != null) {
                        total = parseFloat(costElement['cost']);

                        for (let i = 0; i < selectedElement['discounts'].length; i++) {
                            if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                                && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                                total -= total * selectedElement['discounts'][i]['discount'];
                                break;
                            }
                        }

                        cost += total;
                        names.push(selectedElement['name']);
                    }
                }
            }
        }

        return { cost: cost, names: names };
    }

    renderResults() {
        const { formValues, handleUpdateFormValue, options } = this.props;

        let hasPricing = false;
        let modules = [
            'estimate_data_processing_elements',
            'estimate_inkjet_elements',
            'estimate_inserting_elements',
            'estimate_perfect_match_elements',
            'estimate_art_elements',
            'estimate_handwork_elements',
            'estimate_pressure_seal_elements',
            'estimate_index_tab_elements',
            'estimate_label_elements',
            'estimate_ncr_form_elements'
        ];
        for (let i = 0; i < modules.length; i++) {
            if (formValues[modules[i]].length > 0) {
                for (let j = 0; j < formValues[modules[i]].length; j++) {
                    if (formValues[modules[i]][j]['element_id'] !== '' && formValues[modules[i]][j]['quantity'] !== '') {
                        hasPricing = true;
                        break;
                    }
                }
            }
        }

        if (formValues['estimate_outside_services'].length > 0) {
            hasPricing = true;
        }

        if (!hasPricing) {
            return (
                <tr>
                    <td colSpan="4">No results</td>
                </tr>
            );
        }

        let result = null;
        let totalCost = 0;
        let totalPrice = 0;

        result = this.getDataProcessingCostAndNames(1);
        let dataProcessingCost1 = result['cost'];
        let dataProcessingNames1 = result['names'];
        totalCost += dataProcessingCost1;
        if (formValues['estimate_data_processing_price_1'] != null) {
            totalPrice += parseFloat(formValues['estimate_data_processing_price_1']);
        }

        result = this.getDataProcessingCostAndNames(2);
        let dataProcessingCost2 = result['cost'];
        let dataProcessingNames2 = result['names'];
        totalCost += dataProcessingCost2;
        if (formValues['estimate_data_processing_price_2'] != null) {
            totalPrice += parseFloat(formValues['estimate_data_processing_price_2']);
        }

        result = this.getDataProcessingCostAndNames(3);
        let dataProcessingCost3 = result['cost'];
        //let dataProcessingNames3 = result['names'];
        totalCost += dataProcessingCost3;
        if (formValues['estimate_data_processing_price_3'] != null) {
            totalPrice += parseFloat(formValues['estimate_data_processing_price_3']);
        }

        result = this.getMailingCostAndNames('estimate_inkjet_elements', 'inkjetElements');
        let inkjetCost = result['cost'];
        let inkjetNames = result['names'];
        totalCost += inkjetCost;
        if (formValues['estimate_inkjet_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_inkjet_price']);
        }

        result = this.getMailingCostAndNames('estimate_inserting_elements', 'insertingElements');
        let insertingCost = result['cost'];
        let insertingNames = result['names'];
        totalCost += insertingCost;
        if (formValues['estimate_inserting_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_inserting_price']);
        }

        result = this.getMailingCostAndNames('estimate_perfect_match_elements', 'perfectMatchElements');
        let perfectMatchCost = result['cost'];
        let perfectMatchNames = result['names'];
        totalCost += perfectMatchCost;
        if (formValues['estimate_perfect_match_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_perfect_match_price']);
        }

        result = this.getMailingCostAndNames('estimate_art_elements', 'artElements');
        let artCost = result['cost'];
        let artNames = result['names'];
        totalCost += artCost;
        if (formValues['estimate_art_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_art_price']);
        }

        result = this.getMailingCostAndNames('estimate_handwork_elements', 'handworkElements');
        let handworkCost = result['cost'];
        let handworkNames = result['names'];
        totalCost += handworkCost;
        if (formValues['estimate_handwork_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_handwork_price']);
        }

        let pressureSealCosts = [0, 0, 0, 0, 0, 0, 0, 0];
        let pressureSealName = null;
        for (let i = 0; i < formValues['estimate_pressure_seal_elements'].length; i++) {
            let dataElement = formValues['estimate_pressure_seal_elements'][i];
            if (dataElement['element_id'] !== '' && dataElement['quantity'] !== '') {
                let selectedElement = null;
                for (let j = 0; j < options['pressureSealElements'].length; j++) {
                    let element = options['pressureSealElements'][j]['element'];
                    if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                        selectedElement = element;
                        break;
                    }
                }

                if (selectedElement != null) {
                    pressureSealName = selectedElement['name'];
                    for (let i = 0; i < selectedElement['costs'].length; i++) {
                        if (dataElement['quantity'] >= parseInt(selectedElement['costs'][i]['quantity_start'])
                            && dataElement['quantity'] <= parseInt(selectedElement['costs'][i]['quantity_end'])) {
                            let total = parseFloat(selectedElement['costs'][i]['cost']);

                            for (let i = 0; i < selectedElement['discounts'].length; i++) {
                                if (parseInt(selectedElement['discounts'][i]['start_quantity']) <= dataElement['quantity']
                                    && parseInt(selectedElement['discounts'][i]['end_quantity']) >= dataElement['quantity']) {
                                    total -= total * selectedElement['discounts'][i]['discount'];
                                    break;
                                }
                            }

                            pressureSealCosts[selectedElement['costs'][i]['type'] - 1] += total;
                        }
                    }
                }
            }
        }
        for (let i = 0; i < pressureSealCosts.length; i++) {
            totalCost += pressureSealCosts[i];
        }
        if (formValues['estimate_pressure_seal_price_1'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_1']);
        }
        if (formValues['estimate_pressure_seal_price_2'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_2']);
        }
        if (formValues['estimate_pressure_seal_price_3'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_3']);
        }
        if (formValues['estimate_pressure_seal_price_4'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_4']);
        }
        if (formValues['estimate_pressure_seal_price_5'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_5']);
        }
        if (formValues['estimate_pressure_seal_price_6'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_6']);
        }
        if (formValues['estimate_pressure_seal_price_7'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_7']);
        }
        if (formValues['estimate_pressure_seal_price_8'] != null) {
            totalPrice += parseFloat(formValues['estimate_pressure_seal_price_8']);
        }

        result = this.getMailingCostAndNamesTables('estimate_index_tab_elements', 'indexTabElements');
        let indexTabCost = result['cost'];
        let indexTabNames = result['names'];
        totalCost += indexTabCost;
        if (formValues['estimate_index_tab_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_index_tab_price']);
        }

        result = this.getMailingCostAndNamesTables('estimate_label_elements', 'labelElements');
        let labelCost = result['cost'];
        let labelNames = result['names'];
        totalCost += labelCost;
        if (formValues['estimate_label_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_label_price']);
        }

        result = this.getMailingCostAndNamesTables('estimate_ncr_form_elements', 'ncrFormElements');
        let ncrFormCost = result['cost'];
        let ncrFormNames = result['names'];
        totalCost += ncrFormCost;
        if (formValues['estimate_ncr_form_price'] != null) {
            totalPrice += parseFloat(formValues['estimate_ncr_form_price']);
        }

        let dataProcessing1Row = null;
        if (dataProcessingNames1.length > 0) {
            dataProcessing1Row = (
                <tr key="data-processing-1">
                    <td>Data Processing Department</td>
                    <td>{dataProcessingNames1.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(dataProcessingCost1, 'Data Processing Department')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_data_processing_price_1']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let dataProcessing2Row = null;
        if (dataProcessingNames2.length > 0) {
            dataProcessing2Row = (
                <tr key="data-processing-2">
                    <td>Printing Mailing Labels</td>
                    <td>{dataProcessingNames2.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(dataProcessingCost2, 'Printing Mailing Labels')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_data_processing_price_2']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let inkjetRow = null;
        if (inkjetNames.length > 0) {
            inkjetRow = (
                <tr key="inkjet">
                    <td>Inkjetting Department</td>
                    <td>{inkjetNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(inkjetCost, 'Inkjetting Department')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_inkjet_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let insertingRow = null;
        if (insertingNames.length > 0) {
            insertingRow = (
                <tr key="inserting">
                    <td>Inserting Department</td>
                    <td>{insertingNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(insertingCost, 'Inserting Department')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_inserting_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let perfectMatchRow = null;
        if (perfectMatchNames.length > 0) {
            perfectMatchRow = (
                <tr key="perfect-match">
                    <td>Perfect Match</td>
                    <td>{perfectMatchNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(perfectMatchCost, 'Perfect Match')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_perfect_match_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let artRow = null;
        if (artNames.length > 0) {
            artRow = (
                <tr key="art">
                    <td>Art Department</td>
                    <td>{artNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(artCost, 'Art Department')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_art_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let handworkRow = null;
        if (handworkNames.length > 0) {
            handworkRow = (
                <tr key="handwork">
                    <td>Handwork Department</td>
                    <td>{handworkNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(handworkCost, 'Handwork Department')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_handwork_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let pressureSealRows = [];
        if (pressureSealName != null) {
            for (let i = 1; i <= 7; i++) {
                if (i === 1 && pressureSealCosts[i - 1] === 0) {
                    continue;
                }

                let type = null;
                if (i === 1) {
                    type = '4/0 Printing';
                } else if (i === 2) {
                    type = 'Data Processing';
                } else if (i === 3) {
                    type = 'Variable Set Up';
                } else if (i === 4) {
                    type = 'Variable Color Laser Both Sides';
                } else if (i === 5) {
                    type = 'Pressure Seal Material';
                } else if (i === 6) {
                    type = 'Fold';
                } else if (i === 7) {
                    type = 'Seal';
                } else if (i === 7) {
                    type = 'Deliver to P.O.';
                }

                pressureSealRows.push(
                    <tr key={'pressure-seal-' + i}>
                        <td>Pressure Seal Forms</td>
                        <td>{type}</td>
                        <td className="text-right cost-td">{this.renderMailingTooltip(pressureSealCosts[i - 1], 'Pressure Seal Forms')}</td>
                        <td className="text-right price-td">{numeral(formValues['estimate_pressure_seal_price_' + i]).format('$0,0.00')}</td>
                    </tr>
                );
            }
        }

        let indexTabRow = null;
        if (indexTabNames.length > 0) {
            indexTabRow = (
                <tr key="index-tab">
                    <td>Index Tab</td>
                    <td>{indexTabNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(indexTabCost, 'Index Tab')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_index_tab_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let labelRow = null;
        if (labelNames.length > 0) {
            labelRow = (
                <tr key="label">
                    <td>Standard Labels</td>
                    <td>{labelNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(labelCost, 'Standard Labels')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_label_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let ncrFormRow = null;
        if (ncrFormNames.length > 0) {
            ncrFormRow = (
                <tr key="ncr-form">
                    <td>NCR Form</td>
                    <td>{ncrFormNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(ncrFormCost, 'NCR Form')}</td>
                    <td className="text-right price-td">{numeral(formValues['estimate_ncr_form_price']).format('$0,0.00')}</td>
                </tr>
            );
        }

        let outsideServicesRow = null;
        if (formValues['estimate_outside_services'].length > 0) {
            let outsideServicesNames = [];
            let outsideServicesCost = 0;
            for (let i = 0; i < formValues['estimate_outside_services'].length; i++) {
                let element = formValues['estimate_outside_services'][i];
                if (element['description'] !== '' && element['cost'] !== '' && element['percentage_markup'] !== '') {
                    outsideServicesNames.push(element['description']);
                    outsideServicesCost += parseFloat(element['cost']);
                }
            }

            if (outsideServicesCost > 0) {
                outsideServicesRow = (
                    <tr key="outside-services">
                        <td>Outside Services</td>
                        <td>{outsideServicesNames.join(', ')}</td>
                        <td className="text-right cost-td">{this.renderMailingTooltip(outsideServicesCost, 'Outside Services')}</td>
                        <td className="price-td">
                            $<input type="text" name="estimate_outside_services_price" value={formValues['estimate_outside_services_price']}
                                onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                        </td>
                    </tr>
                );

                totalCost += outsideServicesCost;
                if (formValues['estimate_outside_services_price'] != null) {
                    totalPrice += parseFloat(formValues['estimate_outside_services_price']);
                }
            }
        }

        let discountRow = null;
        if (formValues['estimate_discount_percentage'] !== 0) {
            let discountType = 'Discount';
            if (formValues['estimate_discount_percentage'] > 0) {
                discountType = 'Markup';
            }

            discountRow = (
                <tr key="discount">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>{discountType}:</td>
                    <td className="text-right cost-td">{numeral(totalCost * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(totalPrice * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                </tr>
            );
        }

        totalCost += totalCost * formValues['estimate_discount_percentage'];
        totalPrice += totalPrice * formValues['estimate_discount_percentage'];

        return [
            dataProcessing1Row,
            dataProcessing2Row,
            inkjetRow,
            insertingRow,
            perfectMatchRow,
            artRow,
            handworkRow,
            indexTabRow,
            labelRow,
            ncrFormRow,
            pressureSealRows,
            outsideServicesRow,
            discountRow,
            <tr key="total">
                <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Total:</td>
                <td className="text-right cost-td">{numeral(totalCost).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrice).format('$0,0.00')}</td>
            </tr>
        ];
    }

    render() {
        return (
            <div className="card mb-2 mr-1 pb-2" style={{ width: '100%' }}>
                <div className="card-header">
                    Pricing
                </div>
                <div className="card-body p-1 pb-0">
                    <table id="estimate-pricing-table" className="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <th>Department</th>
                                <th>Name</th>
                                <th className="cost-td">Cost</th>
                                <th className="price-td">Price</th>
                            </tr>
                            {this.renderResults()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default EstimateMailingPricing;
