import React, { Component } from 'react';
import { Dialog } from './../../common/dialog';

class MakeReadyTable extends Component {
    renderData() {
        const { makeReadyTable, handleUpdateMakeReadyTable, handleDeleteMakeReadyTable } = this.props;
        return makeReadyTable.map((makeReady, index) => {
            return (
                <tr key={index}>
                    <td>
                        <input type="text" value={makeReady.run_length}
                            onChange={handleUpdateMakeReadyTable.bind(this, index, 'run_length')} />
                    </td>
                    <td>
                        <input type="text" value={makeReady.make_ready_percentage}
                            onChange={handleUpdateMakeReadyTable.bind(this, index, 'make_ready_percentage')} />
                    </td>
                    <td>
                        <div className="action-icon">
                            <i className="fas fa-trash" onClick={handleDeleteMakeReadyTable.bind(this, index)} />
                        </div>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { hideMakeReadyTable, handleSaveMakeReadyTable, isSavingMakeReadyTable, handleAddMakeReadyTable } = this.props;

        let data = (
            <div>
                <table className="table table-sm table-striped data-table">
                    <thead>
                        <tr>
                            <th>Run Length</th>
                            <th>Percentage</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                </table>
                <div className="add-button" onClick={handleAddMakeReadyTable} style={{ width: 120, fontSize: 13 }}><i className="fas fa-plus" /> Add Row</div>
            </div>
        );

        let saveButtonLabel = 'Save';
        if (isSavingMakeReadyTable) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveMakeReadyTable} disabled={isSavingMakeReadyTable}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideMakeReadyTable}>Close</button>
        ];
        return (
            <Dialog
                name="make-ready-table"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default MakeReadyTable;
