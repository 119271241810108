import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import moment from 'moment';

class Accept extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleAccept: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideAccept: PropTypes.func,
        isAccepting: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        data: PropTypes.object,
        handleNotificationClick: PropTypes.func
    };

    renderInput(label, name, type, required, readOnly = false, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    readOnly={readOnly}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    render() {
        const { hideAccept, handleAccept, isAccepting, formValues, isFetchingElement } = this.props;

        let saveButtonLabel = 'Accept';
        if (isAccepting) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">Accept</div>
                    <div className="w-100"></div>
                    <div className="col" style={{ fontSize: 14, marginTop: 10 }}>
                        <b>Customer:</b> {formValues['customer']} <br />
                        <b>Description:</b> {formValues['description']} <br />
                        <b>Quantity:</b> {formValues['quantity']} <br />
                        <b>PM #:</b> {formValues['pm_number']} <br />
                        <b>PS #:</b> {formValues['ps_number']} <br />
                        <b>Notes:</b> {formValues['notes']} <br />
                        <b>Location:</b> {formValues['location']} <br />
                        <b>Received By:</b> {formValues['received_by_name']} <br />
                        <b>Received At:</b> {moment(formValues['received_at']).subtract(4, 'hours').format('MM/DD/YYYY')} <br />
                        <b>Receiver Comments:</b> {formValues['receive_description']} <br />
                    </div>
                    <div className="w-100"></div>
                    {this.renderInput('Notes', 'accept_description', 'text', true)}
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleAccept} disabled={isAccepting}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideAccept}>Close</button>
        ];
        return (
            <Dialog
                name="accept"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Accept;
