import { NAME } from './constants';

export const FETCH_ELEMENTS = `${NAME}/FETCH_ELEMENTS`;
export const FETCH_ELEMENTS_SUCCESS = `${NAME}/FETCH_ELEMENTS_SUCCESS`;
export const FETCH_ELEMENTS_FAILURE = `${NAME}/FETCH_ELEMENTS_FAILURE`;
export const FETCH_DATA = `${NAME}/FETCH_DATA`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const SET_SORT = `${NAME}/SET_SORT`;
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const SET_ACTIVE_PAGE = `${NAME}/SET_ACTIVE_PAGE`;
export const SET_PRODUCT_TYPE = `${NAME}/SET_PRODUCT_TYPE`;
export const SET_CUSTOMER_ID = `${NAME}/SET_CUSTOMER_ID`;
