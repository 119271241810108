import React from 'react';
import numeral from 'numeral';

const AccountingForm = (props) => {
    const handleAddAccountingItem = () => {
        props.addAccountItem(props.department);
    };

    const handleRemoveAccountingItem = (index) => {
        props.removeAccountItem(props.department, index);
    };

    const handleChangeAccountItem = (index, field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        } else {
            value = event.target.value;
        }

        props.changeAccountItem(props.department, index, field, value);
    };

    const renderAccountingFields = () => {
        return props.accounting.items.map((item, index) => {
            return (
                <div className="accounting-item" key={index}>
                    <div className="accounting-field">
                        <div className="label">Invoice:</div>
                        <div className="text-center">
                            <input type="checkbox" value={item.invoice} checked={item.invoice} onChange={handleChangeAccountItem.bind(this, index, 'invoice')} />
                        </div>
                    </div>
                    <div className="accounting-field description">
                        <div className="label">Description:</div>
                        <input type="text" value={item.description} onChange={handleChangeAccountItem.bind(this, index, 'description')} />
                    </div>
                    <div className="accounting-field">
                        <div className="label">Quantity:</div>
                        <input type="number" value={item.quantity} onChange={handleChangeAccountItem.bind(this, index, 'quantity')} />
                    </div>
                    <div className="accounting-field">
                        <div className="label">Price:</div>
                        <input type="number" value={item.price} onChange={handleChangeAccountItem.bind(this, index, 'price')} />
                    </div>
                    <div className="accounting-field">
                        <div className="label">Unit:</div>
                        <select value={item.unit} onChange={handleChangeAccountItem.bind(this, index, 'unit')}>
                            <option value="1">Ea.</option>
                            <option value="2">Lot</option>
                            <option value="3">per 100</option>
                            <option value="4">per 1000</option>
                        </select>
                    </div>
                    <div className="accounting-field">
                        <div className="label">Total:</div>
                        {numeral(item.quantity * item.price).format('$0,0.00')}
                    </div>
                    <div className="accounting-field remove">
                        <div className="text-center"><i className="fas fa-trash" onClick={handleRemoveAccountingItem.bind(this, index)} /></div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="form">
            <div className="divider"></div>
            <h3 className="accounting-header">
                <div>Accounting</div>
                <div className="icons">
                    <div className="add-icon" onClick={handleAddAccountingItem}>Add <i className="far fa-plus-square" /></div>
                </div>
            </h3>
            <div className="form-section accounting-configuration">
                {renderAccountingFields()}
            </div>
        </div>
    );
};

export default AccountingForm;