import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getModel = state => state[NAME];

/** elements **/
export const getElements = createSelector(
    getModel,
    (model) => model.elements.data
);
export const getIsFetchingElements = createSelector(
    getModel,
    (model) => model.elements.isFetching
);
export const getProductCategoryId = createSelector(
    getModel,
    (model) => model.elements.productCategory
);
export const getProductType = createSelector(
    getModel,
    (model) => model.elements.productType
);
export const getCustomerId = createSelector(
    getModel,
    (model) => model.elements.customerId
);

/** pagination **/
export const getTotalItems = createSelector(
    getModel,
    (model) => model.pagination.totalItems
);
export const getTotalPages = createSelector(
    getModel,
    (model) => model.pagination.totalPages
);
export const getSelectedPage = createSelector(
    getModel,
    (model) => model.pagination.selectedPage
);
export const getElementsPerPage = createSelector(
    getModel,
    (model) => model.pagination.elementsPerPage
);

/** sort **/
export const getSortColumn = createSelector(
    getModel,
    (model) => model.sort.column
);
export const getSortType = createSelector(
    getModel,
    (model) => model.sort.type
);

/** data **/
export const getProductCategories = createSelector(
    getModel,
    (model) => model.data.productCategories
);
export const getCustomers = createSelector(
    getModel,
    (model) => model.data.customers
);
export const getIsFetchingData = createSelector(
    getModel,
    (model) => model.data.isFetching
);