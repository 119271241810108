import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Rect, Stage } from 'react-konva';

class FlatDrawImposition extends React.Component {
    static propTypes = {
        result: PropTypes.object
    };

    drawPoints() {
        const { result } = this.props;

        let originWidth = ((result.paper_information.pss.pss.width * 6 - result.paper_information.pss.ps.width * 6) / 2) + 1;
        let originLeft = ((result.paper_information.pss.pss.length * 6 - result.paper_information.pss.ps.length * 6) / 2) + 1;

        let bleed = (result.paper_information.fss_with_bleed.width - result.paper_information.fss.width) * 5;
        return result.paper_information.pss.points.map((point) => {
            let rects = [];
            rects.push(
                <Rect
                    x={point.origin[0] * 6 + originLeft}
                    y={point.origin[1] * 6 + originWidth}
                    width={point.rectangle[0] * 6}
                    height={point.rectangle[1] * 6}
                    fill={bleed > 0 ? '#F48FB1' : '#ffffff'}
                    stroke={bleed > 0 ? '#F48FB1' : '#000000'}
                />
            );

            if (bleed > 0) {
                rects.push(
                    <Rect
                        x={point.origin[0] * 6 + originLeft + bleed}
                        y={point.origin[1] * 6 + originWidth + bleed}
                        width={point.rectangle[0] * 6 - bleed * 2}
                        height={point.rectangle[1] * 6 - bleed * 2}
                        fill={'#ffffff'}
                        stroke={'#ffffff'}
                    />
                );
            } else {
                rects.push(
                    <Rect
                        x={point.origin[0] * 6 + originLeft + 1}
                        y={point.origin[1] * 6 + originWidth + 1}
                        width={point.rectangle[0] * 6 - 2}
                        height={point.rectangle[1] * 6 - 2}
                        fill={'#ffffff'}
                        stroke={'#ffffff'}
                    />
                );
            }


            return rects;
        });
    }

    render() {
        const { result } = this.props;
        let width = result.paper_information.pss.pss.width * 6;
        let length = result.paper_information.pss.pss.length * 6;
        return (
            <Stage width={length + 2} height={width + 2}>
                <Layer>
                    <Rect
                        x={1}
                        y={1}
                        width={length}
                        height={width}
                        fill="#ededed"
                        stroke="#000000"
                    />
                    {this.drawPoints()}
                </Layer>
            </Stage>
        );
    }
}

export default FlatDrawImposition;
