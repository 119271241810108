import { NAME } from './constants';

export const FETCH_ELEMENTS = `${NAME}/FETCH_ELEMENTS`;
export const FETCH_ELEMENTS_SUCCESS = `${NAME}/FETCH_ELEMENTS_SUCCESS`;
export const FETCH_ELEMENTS_FAILURE = `${NAME}/FETCH_ELEMENTS_FAILURE`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_ELEMENT = `${NAME}/DELETE_ELEMENT`;
export const DELETE_ELEMENT_SUCCESS = `${NAME}/DELETE_ELEMENT_SUCCESS`;
export const DELETE_ELEMENT_FAILURE = `${NAME}/DELETE_ELEMENT_FAILURE`;

export const SAVE_ELEMENT = `${NAME}/SAVE_ELEMENT`;
export const SAVE_ELEMENT_SUCCESS = `${NAME}/SAVE_ELEMENT_SUCCESS`;
export const SAVE_ELEMENT_FAILURE = `${NAME}/SAVE_ELEMENT_FAILURE`;

export const FETCH_ELEMENT = `${NAME}/FETCH_ELEMENT`;
export const FETCH_ELEMENT_SUCCESS = `${NAME}/FETCH_ELEMENT_SUCCESS`;
export const FETCH_ELEMENT_FAILURE = `${NAME}/FETCH_ELEMENT_FAILURE`;

export const FETCH_INFORMATION = `${NAME}/FETCH_INFORMATION`;
export const FETCH_INFORMATION_SUCCESS = `${NAME}/FETCH_INFORMATION_SUCCESS`;
export const FETCH_INFORMATION_FAILURE = `${NAME}/FETCH_INFORMATION_FAILURE`;

export const UPDATE_FORM_VALUE = `${NAME}/UPDATE_FORM_VALUE`;
export const SET_SORT = `${NAME}/SET_SORT`;
export const SET_ELEMENTS = `${NAME}/SET_ELEMENTS`;
export const CHANGE_ATTRIBUTE = `${NAME}/CHANGE_ATTRIBUTE`;
export const SET_ACTIVE_ELEMENT = `${NAME}/SET_ACTIVE_ELEMENT`;
export const SET_ID_FOR_DELETE = `${NAME}/SET_ID_FOR_DELETE`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_FORM_ERRORS = `${NAME}/SET_FORM_ERRORS`;
export const SET_FORM_ERROR = `${NAME}/SET_FORM_ERROR`;
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const CHANGE_FILTER = `${NAME}/CHANGE_FILTER`;
export const PROCESS_ELEMENTS = `${NAME}/PROCESS_ELEMENTS`;
export const CHECK_PERMISSION = `${NAME}/CHECK_PERMISSION`;
export const CHECK_MAIN_PERMISSION = `${NAME}/CHECK_MAIN_PERMISSION`;
export const CHECK_ALL_PERMISSIONS = `${NAME}/CHECK_ALL_PERMISSIONS`;
export const SETUP_PERMISSIONS = `${NAME}/SETUP_PERMISSIONS`;
export const TOGGLE_PRODUCT_WINDOW = `${NAME}/TOGGLE_PRODUCT_WINDOW`;
