import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    static propTypes = {

    };

    renderInput(label, name, type, required, colClass = '') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={'field-container ' + colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderPassword(label, name, type, required, colClass = '') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={'field-container ' + colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    type="password"
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = '') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={'field-container ' + colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    renderPermissionsElements(modules) {
        const { permissions, checkPermission, checkMainPermission, formValues } = this.props;
        return permissions.map((permission, index) => {
            if (!modules.includes(permission['name'])) {
                return null;
            }

            if (permission['name'] === 'Timeline Log' && formValues.id !== 61 && formValues.id !== 64) {
                return null;
            }

            let mainSelected = true;
            let someSelected = false;
            for (let i = 0; i < permission['subnames'].length; i++) {
                if (!permission['subnames'][i]['selected']) {
                    mainSelected = false;
                } else {
                    someSelected = true;
                }
            }

            let style = null;
            if (someSelected) {
                style = { color: '#43a047' };
            }

            let permissionLabel = permission['label'];
            if (permission['label'] === 'PQT') {
                permissionLabel = 'Stagecoach'
            }

            return (
                <div className="permission-element" key={'permission-element' + index}>
                    <input type="checkbox" onClick={(event) => checkMainPermission(permission['name'], event.target.checked ? 1 : 0)}
                        checked={mainSelected} /> <b style={style}>{permissionLabel}</b>
                    {permission['subnames'].map((subname, index) => {
                        let subnameName = subname['name'];
                        if (permission['name'] === 'PQT' && subname['name'] === 'Worker') {
                            subnameName = 'Operator';
                        }

                        return (
                            <div key={'permission-subelement-' + index}>
                                <input type="checkbox" onClick={(event) => checkPermission(permission['name'], subname['name'], event.target.checked ? 1 : 0)}
                                    checked={subname['selected']} /> {subnameName}
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    renderPermissionsList() {
        return (
            <div className="permissions-list-container">
                <div className="card mb-2 complete-card">
                    <div className="card-header p-2">Main</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Employees', 'Dashboard', 'Quotes', 'Orcers', 'Users', 'Deposits', 'Production', 'Shipping', 'Vendors', 'Invoices'])}
                    </div>
                </div>
                <div className="card mb-2 complete-card">
                    <div className="card-header p-2">Customers Discount | Turnaround | Industries | Departments | S/R | Change Order</div>
                    <div className="card-body p-2 pt-0 secondary-permissions">
                        {this.renderPermissionsElements(['Customers', 'Customer Discounts', 'Turnaround', 'Indutries', 'Departments', 'Shipping/Receiving', 'Change Order'])}
                    </div>
                </div>
                <div className="card mb-2 products-card">
                    <div className="card-header p-2">Products | Product Categories | Product Dashboard | Product Catalog</div>
                    <div className="card-body p-2 pt-0 secondary-permissions">
                        {this.renderPermissionsElements(['Product Categories', 'Products', 'Product Dashboards', 'Products Catalog'])}
                    </div>
                </div>
                <div className="card mb-2 envelopes-card">
                    <div className="card-header p-2">Envelopes Config</div>
                    <div className="card-body p-2 pt-0 secondary-permissions">
                        {this.renderPermissionsElements(['Envelope Presses', 'Envelope Papers', 'Envelope Types'])}
                    </div>
                </div>
                <div className="card mb-2 printing-config">
                    <div className="card-header p-2">Printing Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Presses', 'Inks'])}
                    </div>
                </div>
                <div className="card mb-2 digital-config">
                    <div className="card-header p-2">Digital Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Digital'])}
                    </div>
                </div>
                <div className="card mb-2 paper-config">
                    <div className="card-header p-2">Paper Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Paper Categories', 'Paper Vendors', 'Papers'])}
                    </div>
                </div>
                <div className="card mb-2 cutting-config">
                    <div className="card-header p-2">Cutting | Die-Cut | Scoring | Drilling | Padding</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Cutting', 'Die-Cutting', 'Drilling', 'Scoring', 'Padding'])}
                    </div>
                </div>
                <div className="card mb-2 folding-config">
                    <div className="card-header p-2">Folding Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Folding Machines', 'Folding Types'])}
                    </div>
                </div>
                <div className="card mb-2 bindery-config">
                    <div className="card-header p-2">Bindery Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Saddle Stitching Machines', 'Perfect Binding Machines', 'Coil Binding Machines',
                            'Corner Staple Machines', 'Side Staple Machines', 'Spiral Binding Machines'])}
                    </div>
                </div>
                <div className="card mb-2 large-format-config">
                    <div className="card-header p-2">Large Format Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Large Format Machines', 'Substrates', 'Laminates', 'Accessories'])}
                    </div>
                </div>
                <div className="card mb-2 shrink-wrapping-config">
                    <div className="card-header p-2">Shrink-Wrapping Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Shrink Wrapping Machines', 'Shrink Wrapping Types', 'Shrink Wrapping Categories'])}
                    </div>
                </div>
                <div className="card mb-2 banding-config">
                    <div className="card-header p-2">Banding Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Banding Machines', 'Banding Types'])}
                    </div>
                </div>
                <div className="card mb-2 wrapping-config">
                    <div className="card-header p-2">Wrapping Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Wrapping Machines', 'Wrapping Types'])}
                    </div>
                </div>
                <div className="card mb-2 package-config">
                    <div className="card-header p-2">Package Config</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Packages', 'Mailing Operations'])}
                    </div>
                </div>
                <div className="card mb-2 hand-config">
                    <div className="card-header p-2">Hand Work | Counting</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Flat Hand Gathering', 'Multi-Page Hand Gathering', 'Counting'])}
                    </div>
                </div>
                <div className="card mb-2 data-config">
                    <div className="card-header p-2">Data | Inkjet | Inserting | Perfect Match</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Data Processing', 'Inkjet', 'Inserting', 'Perfect Match'])}
                    </div>
                </div>
                <div className="card mb-2 other-mail-card">
                    <div className="card-header p-2">Other Mail Services</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['Wafer Seal', 'Affix Labels', 'Art', 'Handwork', 'Index Tabs',
                            'Labels', 'NCR Forms', 'Pressure Seal Forms'])}
                    </div>
                </div>
                <div className="card mb-2 dpt-config">
                    <div className="card-header p-2">Departmental Processing Time</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['DPT Data Processing', 'DPT Digital', 'DPT Press', 'DPT Bindery', 'DPT Inkjet',
                            'DPT Inkjet Material', 'DPT Inserting', 'DPT Outside Service', 'DPT Hand'])}
                    </div>
                </div>
                <div className="card mb-2 other-card">
                    <div className="card-header p-2">Other</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderPermissionsElements(['PQT', 'AC Sales Report', 'Timeline Log', 'UTHealth', 'DPT Calculator'])}
                    </div>
                </div>
            </div>
        );
    }

    renderProductWindowssList() {
        const { information, selectedProductWindows, toggleProductWindow } = this.props;
        return information['productWindows'].map((productWindow, index) => {
            let checked = false;
            for (let i = 0; i < selectedProductWindows.length; i++) {
                if (parseInt(selectedProductWindows[i]) === parseInt(productWindow['id'])) {
                    checked = true;
                    break;
                }
            }

            return (
                <div className="permission-element" key={'permission-element' + index}>
                    <input type="checkbox" checked={checked} onClick={toggleProductWindow.bind(this, productWindow['id'])} /> {productWindow['name']}
                </div>
            );
        });
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, formValues,
            checkAllPermissions, information } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let userTypesOptions = [
            { label: 'Select option', value: '' },
            { label: 'Accounting', value: 1 },
            { label: 'CSR', value: 2 },
            { label: 'Data', value: 3 },
            { label: 'Sales', value: 4 },
            { label: 'Purchasing', value: 5 },
            { label: 'Prepress', value: 6 },
            { label: 'Graphics', value: 7 },
            { label: 'Large Format', value: 8 },
            { label: 'Operation', value: 9 },
            { label: 'Production', value: 10 },
            { label: 'Lettershop', value: 11 },
            { label: 'Management', value: 12 },
            { label: 'Warehouse', value: 13 },
            { label: 'CEO', value: 14 },
            { label: 'President', value: 15 },
            { label: 'Marketing', value: 16 },
            { label: 'CFO', value: 17 },
            { label: 'Laser', value: 18 },
            { label: 'Products Only', value: 19 },
            { label: 'PQT Department View', value: 20 },
        ];

        let form = null;
        if (parseInt(formValues['user_type_1']) === 19) {   // Products Only
            form = (
                <form>
                    <div className="subtitle">User Data</div>
                    <div className="card mb-2">
                        <div className="card-header p-2">User Information</div>
                        <div className="card-body p-2 pt-0">
                            {this.renderInput('Name', 'name', 'text', true)}
                            {this.renderInput('Email', 'email', 'text', true)}
                            {this.renderInput('Username', 'username', 'text', true)}
                            {this.renderPassword('Password', 'password', 'text', formValues['id'] === '')}
                            {this.renderInput('Phone', 'phone', 'text', false)}
                            {this.renderSelect('Main Role', 'user_type_1', userTypesOptions, true)}
                            {this.renderSelect('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true)}
                        </div>
                    </div>


                    <div className="w-100"></div>
                    <div className="subtitle">
                        Products
                    </div>
                    <div className="w-100"></div>
                    <div className="col">
                        {this.renderProductWindowssList()}
                    </div>
                </form>
            );
        } else if (parseInt(formValues['user_type_1']) === 20) {    // PQT Department View
            let departmentsOptions = [{ label: 'Select option', value: '' }];
            for (let i = 0; i < information.departments.length; i++) {
                departmentsOptions.push({ label: information.departments[i].name, value: information.departments[i].id });
            }

            form = (
                <form>
                    <div className="subtitle">User Data</div>
                    <div className="card mb-2">
                        <div className="card-header p-2">User Information</div>
                        <div className="card-body p-2 pt-0">
                            {this.renderInput('Name', 'name', 'text', true)}
                            {this.renderInput('Email', 'email', 'text', true)}
                            {this.renderInput('Username', 'username', 'text', true)}
                            {this.renderPassword('Password', 'password', 'text', formValues['id'] === '')}
                            {this.renderInput('Phone', 'phone', 'text', false)}
                            {this.renderSelect('Main Role', 'user_type_1', userTypesOptions, true)}
                            {this.renderSelect('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true)}
                            {this.renderSelect('Department', 'department_id', departmentsOptions, true)}
                            <div className="field-container"></div>
                        </div>
                    </div>
                </form>
            );
        } else {
            form = (
                <form>
                    <div className="subtitle">User Data</div>
                    <div className="card mb-2">
                        <div className="card-header p-2">User Information</div>
                        <div className="card-body p-2 pt-0">
                            {this.renderInput('Name', 'name', 'text', true)}
                            {this.renderInput('Email', 'email', 'text', true)}
                            {this.renderInput('Username', 'username', 'text', true)}
                            {this.renderPassword('Password', 'password', 'text', formValues['id'] === '')}
                            {this.renderInput('Phone', 'phone', 'text', false)}
                            {this.renderSelect('Main Role', 'user_type_1', userTypesOptions, true)}
                            {this.renderSelect('Second Role', 'user_type_2', userTypesOptions, false)}
                            {this.renderSelect('Third Role', 'user_type_3', userTypesOptions, false)}
                            {this.renderSelect('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true)}
                        </div>
                    </div>
                    <div className="subtitle">
                        <div className="name">Permissions</div>
                        <div id="check-all" className="check-all"><input type="checkbox" onClick={(event) => checkAllPermissions(event.target.checked ? 1 : 0)} /> Check All</div>
                    </div>
                    <div className="clearfix"></div>
                    {this.renderPermissionsList()}
                </form>
            );
        }

        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 1000, maxWidth: 1000 }}
                bodyStyle={{ overflow: 'initial' }}
                className="large-dialog"
            >
                <div className="form-container users-form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
