import Immutable from 'immutable';
import * as types from './Invoices.types';

const initialState = {
    invoices: [],
    fetching: false,
    sort: {
        column: 'created_at',
        type: 'asc'
    },
    filter: {
        fields: []
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    activeId: null,
    editing: false,
    saving: false,
    deleting: false,
    pushing: false,
    converting: false,
    data: {
        customers: [],
        salesReps: [],
        csrs: [],
        fetching: false,
    }
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_INVOICES:
            return state.setIn(['fetching'], true)
                .setIn(['invoices'], [])
                .toJS();

        case types.FETCH_INVOICES_SUCCESS:
            return state.setIn(['fetching'], false)
                .setIn(['invoices'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage)
                .toJS();

        case types.FETCH_INVOICES_ERROR:
            return state.setIn(['fetching'], false).toJS();

        case types.FETCH_DATA:
            return state.setIn(['data', 'fetching'], true).toJS();

        case types.FETCH_DATA_SUCCESS:
            return state.setIn(['data', 'fetching'], false)
                .setIn(['data', 'customers'], action.payload.customers)
                .setIn(['data', 'salesReps'], action.payload.salesReps)
                .setIn(['data', 'csrs'], action.payload.csrs)
                .toJS();

        case types.FETCH_DATA_ERROR:
            return state.setIn(['data', 'fetching'], false).toJS();

        case types.SET_EDITING:
            return state.setIn(['editing'], action.payload).toJS();

        case types.SET_ACTIVE_ID:
            return state.setIn(['activeId'], action.payload).toJS();

        case types.SAVE_INVOICE:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_INVOICE_SUCCESS:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_INVOICE_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.DELETE_INVOICE:
            return state.setIn(['deleting'], true).toJS();

        case types.DELETE_INVOICE_SUCCESS:
            return state.setIn(['deleting'], false).toJS();

        case types.DELETE_INVOICE_ERROR:
            return state.setIn(['deleting'], false).toJS();

        case types.PUSH_INVOICE_TO_QB:
            return state.setIn(['pushing'], true).toJS();

        case types.PUSH_INVOICE_TO_QB_SUCCESS:
            return state.setIn(['pushing'], false).toJS();

        case types.PUSH_INVOICE_TO_QB_ERROR:
            return state.setIn(['pushing'], false).toJS();

        case types.CONVERT_TO_JOB:
            return state.setIn(['converting'], true).toJS();

        case types.CONVERT_TO_JOB_SUCCESS:
            return state.setIn(['converting'], false).toJS();

        case types.CONVERT_TO_JOB_ERROR:
            return state.setIn(['converting'], false).toJS();

        default:
            return state.toJS();
    }
}