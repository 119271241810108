import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    elements: {
        data: [],
        isFetching: false,
        productCategory: '',
        productType: '',
        customerId: null
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'desc'
    },
    data: {
        productCategories: [],
        customers: [],
        isFetching: false
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.FETCH_DATA:
            state = state.setIn(['data', 'isFetching'], true)
                .setIn(['data', 'productCategories'], [])
                .setIn(['data', 'customers'], []);
            break;

        case actionTypes.FETCH_DATA_SUCCESS:
            state = state.setIn(['data', 'isFetching'], false)
                .setIn(['data', 'productCategories'], action.payload.productCategories)
                .setIn(['data', 'customers'], action.payload.customers);
            break;

        case actionTypes.FETCH_DATA_FAILURE:
            state = state.setIn(['data', 'isFetching'], false);
            break;

        case actionTypes.SET_PRODUCT_TYPE:
            state = state.setIn(['elements', 'productCategory'], action.payload.productCategoryId)
                .setIn(['elements', 'productType'], action.payload.productType);
            break;

        case actionTypes.SET_CUSTOMER_ID:
            state = state.setIn(['elements', 'customerId'], action.payload);
            break;

        default:
            break;
    }

    return state.toJS();
}
