import { NAME } from './constants';

export const FETCH_ELEMENTS = `${NAME}/FETCH_ELEMENTS`;
export const FETCH_ELEMENTS_SUCCESS = `${NAME}/FETCH_ELEMENTS_SUCCESS`;
export const FETCH_ELEMENTS_FAILURE = `${NAME}/FETCH_ELEMENTS_FAILURE`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_ELEMENT = `${NAME}/DELETE_ELEMENT`;
export const DELETE_ELEMENT_SUCCESS = `${NAME}/DELETE_ELEMENT_SUCCESS`;
export const DELETE_ELEMENT_FAILURE = `${NAME}/DELETE_ELEMENT_FAILURE`;

export const SAVE_ELEMENT = `${NAME}/SAVE_ELEMENT`;
export const SAVE_ELEMENT_SUCCESS = `${NAME}/SAVE_ELEMENT_SUCCESS`;
export const SAVE_ELEMENT_FAILURE = `${NAME}/SAVE_ELEMENT_FAILURE`;

export const FETCH_DATA = `${NAME}/FETCH_DATA`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;

export const ADD_PAYOR = `${NAME}/ADD_PAYOR`;
export const ADD_PAYOR_SUCCESS = `${NAME}/ADD_PAYOR_SUCCESS`;
export const ADD_PAYOR_FAILURE = `${NAME}/ADD_PAYOR_FAILURE`;

export const SEND_EMAIL = `${NAME}/SEND_EMAIL`;
export const SEND_EMAIL_SUCCESS = `${NAME}/SEND_EMAIL_SUCCESS`;
export const SEND_EMAIL_FAILURE = `${NAME}/SEND_EMAIL_FAILURE`;

export const FETCH_ELEMENT = `${NAME}/FETCH_ELEMENT`;
export const FETCH_ELEMENT_SUCCESS = `${NAME}/FETCH_ELEMENT_SUCCESS`;
export const FETCH_ELEMENT_FAILURE = `${NAME}/FETCH_ELEMENT_FAILURE`;

export const EXPORT_EXCEL = `${NAME}/EXPORT_EXCEL`;
export const UPDATE_FORM_VALUE = `${NAME}/UPDATE_FORM_VALUE`;
export const SET_SORT = `${NAME}/SET_SORT`;
export const SET_ELEMENTS = `${NAME}/SET_ELEMENTS`;
export const CHANGE_ATTRIBUTE = `${NAME}/CHANGE_ATTRIBUTE`;
export const SET_ACTIVE_ELEMENT = `${NAME}/SET_ACTIVE_ELEMENT`;
export const SET_ID_FOR_DELETE = `${NAME}/SET_ID_FOR_DELETE`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_FORM_ERRORS = `${NAME}/SET_FORM_ERRORS`;
export const SET_FORM_ERROR = `${NAME}/SET_FORM_ERROR`;
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const CHANGE_FILTER = `${NAME}/CHANGE_FILTER`;
export const SHOW_LIST = `${NAME}/SHOW_LIST`;
export const SHOW_FORM = `${NAME}/SHOW_FORM`;
export const SHOW_EMAIL = `${NAME}/SHOW_EMAIL`;
export const ADD_PM = `${NAME}/ADD_PM`;
export const SET_PM = `${NAME}/SET_PM`;
export const SET_EMAIL = `${NAME}/SET_EMAIL`;
export const SET_ACTIVE_PAGE = `${NAME}/SET_ACTIVE_PAGE`;
export const RESET_FILTER = `${NAME}/RESET_FILTER`;
