import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table, Form, Delete, ActiveLaminates, ActiveAccessories, DiscountTable } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import numeral from 'numeral';
import { Helmet } from 'react-helmet';
import { showNotification } from './../../utils';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        changeAttribute: PropTypes.func,
        updateAttribute: PropTypes.func,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        fetchInformation: PropTypes.func,
        formErrorMessages: PropTypes.object,
        isFetchingInformation: PropTypes.bool,
        productCategories: PropTypes.array,
        activeElement: PropTypes.object,
        activeLaminates: PropTypes.array,
        isSavingActiveLaminates: PropTypes.bool,
        showActiveLaminates: PropTypes.func,
        hideActiveLaminates: PropTypes.func,
        setActiveLaminates: PropTypes.func,
        saveActiveLaminates: PropTypes.func,
        substrates: PropTypes.array,
        laminates: PropTypes.array,
        accessories: PropTypes.array,
        showActiveAccessories: PropTypes.func,
        hideActiveAccessories: PropTypes.func,
        setActiveAccessories: PropTypes.func,
        saveActiveAccessories: PropTypes.func,
        addActiveData: PropTypes.func,
        concatActiveData: PropTypes.func,
        deleteActiveData: PropTypes.func,
        addMultipleValue: PropTypes.func,
        clearMultipleValue: PropTypes.func,
        concatMultipleValue: PropTypes.func,
        activeAccessories: PropTypes.array,
        isSavingActiveAccessories: PropTypes.bool,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        handleShowDiscountTable: PropTypes.func
    };

    componentDidMount() {
        const { fetchElements, sortColumn, sortType, filterFields, fetchInformation } = this.props;
        fetchInformation();
        fetchElements(1, sortColumn, sortType, filterFields);
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { updateFormValue, setFormError } = this.props;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        updateFormValue(event.target.name, value);

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        setFormError(event.target.name, error, errorMessage);
    }

    handleMultipleChange(name, event) {
        const { concatMultipleValue, clearMultipleValue } = this.props;
        clearMultipleValue(name);

        let values = [];
        for (let i = 0; i < event.target.options.length; i++) {
            if (event.target.options[i].selected) {
                values.push(parseInt(event.target.options[i].value));
            }
        }

        concatMultipleValue(name, values);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, fetchElements, filterFields } = this.props;
        let type = 'asc';
        if (sortColumn === column) {
            type = sortType === 'asc' ? 'desc' : 'asc';
            setSort(column, type);
        } else {
            setSort(column, 'asc');
        }
        fetchElements(1, column, type, filterFields);
    }

    handleInlineChange(index, attribute, value) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(value, elements, index, attribute);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleSaveElement() {
        const { saveElement, formValues, elements } = this.props;
        saveElement(formValues, elements);
    }

    handleShowForm(id) {
        const { showForm, resetForm, fetchElement } = this.props;

        if (id === null) {
            resetForm(id);
        } else {
            fetchElement(id);
        }

        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete, elements } = this.props;
        deleteElement(idForDelete, elements);
    }

    handleChangeAttribute(index, attribute, type, value) {
        const { processedElements, changeAttribute, updateAttribute } = this.props;

        let valueToSave = value;
        if (type === 'money') {
            valueToSave = numeral(value).format('0.00');
            value = numeral(value).format('$0,0.00');
        } else if (type === 'number') {
            if (parseInt(value).toString().match(/^[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        } else if (type === 'date') {
            valueToSave = moment(value).format('YYYY-MM-DD');
            value = valueToSave;
        } else if (type === 'decimal') {
            if (parseInt(value).toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        }

        changeAttribute(value, processedElements, index, attribute);
        updateAttribute(processedElements[index].id, attribute, valueToSave, processedElements, index);
    }

    handleSaveActiveLaminates() {
        const { saveActiveLaminates, activeLaminates, activeElement, elements } = this.props;
        saveActiveLaminates(activeLaminates, elements, activeElement['id']);
    }

    handleAddActiveLaminate() {
        let { formValues, concatActiveData, laminates, showInfoNotification, updateFormValue } = this.props;
        let newLaminates = [];
        for (let i = 0; i < laminates.length; i++) {
            if (formValues['active_laminate_ids'].includes(laminates[i]['id'])) {
                newLaminates.push(laminates[i]);
            }
        }

        if (newLaminates.length === 0) {
            showInfoNotification('Select a laminate');
        } else {
            concatActiveData('activeLaminates', newLaminates);
            updateFormValue('active_laminate_ids', []);
        }
    }

    handleDeleteActiveLaminate(id) {
        let { activeLaminates, deleteActiveData } = this.props;

        for (let i = 0; i < activeLaminates.length; i++) {
            if (activeLaminates[i]['id'] === id) {
                deleteActiveData(i, 'activeLaminates');
                break;
            }
        }
    }

    handleSaveActiveAccessories() {
        const { saveActiveAccessories, activeAccessories, activeElement, elements } = this.props;
        saveActiveAccessories(activeAccessories, elements, activeElement['id']);
    }

    handleAddActiveAccessory() {
        let { formValues, concatActiveData, accessories, showInfoNotification, updateFormValue } = this.props;
        let newAccessories = [];
        for (let i = 0; i < accessories.length; i++) {
            if (formValues['active_accessory_ids'].includes(accessories[i]['id'])) {
                newAccessories.push(accessories[i]);
            }
        }

        if (newAccessories.length === 0) {
            showInfoNotification('Select an accessory');
        } else {
            concatActiveData('activeAccessories', newAccessories);
            updateFormValue('active_accessory_ids', []);
        }
    }

    handleDeleteActiveAccessory(id) {
        let { activeAccessories, deleteActiveData } = this.props;

        for (let i = 0; i < activeAccessories.length; i++) {
            if (activeAccessories[i]['id'] === id) {
                deleteActiveData(i, 'activeAccessories');
                break;
            }
        }
    }

    handleAddDiscount() {
        let { addDiscount } = this.props;
        addDiscount();
    }

    handleDeleteDiscount(ind) {
        let { deleteDiscount } = this.props;
        deleteDiscount(ind);
    }

    handleShowActiveLaminates(id) {
        const { showActiveLaminates, fetchElement } = this.props;
        fetchElement(id);
        showActiveLaminates();
    }

    handleShowActiveAccessories(id) {
        const { showActiveAccessories, fetchElement } = this.props;
        fetchElement(id);
        showActiveAccessories();
    }

    handleShowDiscountTable(id) {
        const { showDiscountTable, fetchElement } = this.props;
        fetchElement(id);
        showDiscountTable();
    }

    handleChangeFilter(name, event) {
        const { changeFilter, fetchElements, sortColumn, sortType, filterFields } = this.props;

        let value = null;
        let filter = filterFields;
        if (name === 'date') {
            value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else {
            value = event.target.value;
            changeFilter(name, value);
        }

        let fetch = false;
        const index = filter.findIndex(element => element['name'] === name);
        if (index === -1 && value !== '') {
            filter.push({ name: name, value: value });
            fetch = true;
        } else if (index !== -1 && value === '') {
            filter.splice(index, 1);
            fetch = true;
        } else if (index !== -1 && value !== '' && filter[index]['value'] !== value) {
            filter[index]['value'] = value;
            fetch = true;
        }

        if (fetch) {
            fetchElements(1, sortColumn, sortType, filter);
        }
    }

    handleSaveDiscountTable() {
        const { saveDiscountTable, discountTable, activeElement, elements } = this.props;
        saveDiscountTable(discountTable, elements, activeElement['id']);
    }

    handleSetActivePage(page) {
        const { fetchElements, setActivePage, sortColumn, sortType, filterFields } = this.props;
        setActivePage(page);
        fetchElements(page, sortColumn, sortType, filterFields);
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, hideForm, isDeleting, hideDelete, formErrorMessages,
            laminates, isFetchingInformation, productCategories, hideActiveLaminates, activeLaminates,
            isSavingActiveLaminates, accessories, activeAccessories, isSavingActiveAccessories,
            hideActiveAccessories, processedElements, discountTable, isSavingDiscountTable,
            hideDiscountTable, activeElement, updateDiscount } = this.props;
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Dashboard</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Substrates"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            <div className="upload-button" onClick={this.handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add</div>
                            <Table
                                elements={elements}
                                isFetchingElements={isFetchingElements}
                                totalItems={this.props.totalItems}
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                elementsPerPage={elementsPerPage}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                user={user}
                                processedElements={processedElements}
                                hideActiveLaminates={hideActiveLaminates.bind(this)}
                                handleSort={this.handleSort.bind(this)}
                                handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                handleInlineChange={this.handleInlineChange.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleDayChange={this.handleDayChange.bind(this)}
                                handleShowActiveLaminates={this.handleShowActiveLaminates.bind(this)}
                                handleShowActiveAccessories={this.handleShowActiveAccessories.bind(this)}
                                handleShowDiscountTable={this.handleShowDiscountTable.bind(this)}
                                handleChangeFilter={this.handleChangeFilter.bind(this)}
                                handleSetActivePage={this.handleSetActivePage.bind(this)}
                            />
                            <Form
                                activeLaminates={activeLaminates}
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                productCategories={productCategories}
                                laminates={laminates}
                                isFetchingInformation={isFetchingInformation}
                                accessories={accessories}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={hideForm.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                            />
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                            <ActiveLaminates
                                activeLaminates={activeLaminates}
                                isSavingActiveLaminates={isSavingActiveLaminates}
                                isFetchingInformation={isFetchingInformation}
                                laminates={laminates}
                                formValues={formValues}
                                isFetchingElement={isFetchingElement}
                                hideActiveLaminates={hideActiveLaminates.bind(this)}
                                handleSaveActiveLaminates={this.handleSaveActiveLaminates.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleAddActiveLaminate={this.handleAddActiveLaminate.bind(this)}
                                handleDeleteActiveLaminate={this.handleDeleteActiveLaminate.bind(this)}
                                handleMultipleChange={this.handleMultipleChange.bind(this)}
                            />
                            <ActiveAccessories
                                activeAccessories={activeAccessories}
                                isSavingActiveAccessories={isSavingActiveAccessories}
                                isFetchingInformation={isFetchingInformation}
                                accessories={accessories}
                                formValues={formValues}
                                isFetchingElement={isFetchingElement}
                                hideActiveAccessories={hideActiveAccessories.bind(this)}
                                handleSaveActiveAccessories={this.handleSaveActiveAccessories.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleAddActiveAccessory={this.handleAddActiveAccessory.bind(this)}
                                handleDeleteActiveAccessory={this.handleDeleteActiveAccessory.bind(this)}
                                handleMultipleChange={this.handleMultipleChange.bind(this)}
                            />
                            <DiscountTable
                                activeElement={activeElement}
                                discountTable={discountTable}
                                isSavingDiscountTable={isSavingDiscountTable}
                                isFetchingInformation={isFetchingInformation}
                                isFetchingElement={isFetchingElement}
                                hideDiscountTable={hideDiscountTable.bind(this)}
                                handleSaveDiscountTable={this.handleSaveDiscountTable.bind(this)}
                                handleAddDiscount={this.handleAddDiscount.bind(this)}
                                handleDeleteDiscount={this.handleDeleteDiscount.bind(this)}
                                updateDiscount={updateDiscount.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isFetchingElement: selectors.getIsFetchingElement,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalItems: selectors.getTotalItems,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    isFetchingInformation: selectors.getIsFetchingInformation,
    activeLaminates: selectors.getActiveLaminates,
    activeAccessories: selectors.getActiveAccessories,
    discountTable: selectors.getDiscountTable,
    isSavingActiveLaminates: selectors.getIsSavingActiveLaminates,
    isSavingActiveAccessories: selectors.getIsSavingActiveAccessories,
    isSavingDiscountTable: selectors.getIsSavingDiscountTable,
    activeElement: selectors.getActiveElement,
    laminates: selectors.getLaminates,
    accessories: selectors.getAccessories,
    filterFields: selectors.getFilterFields,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: (selectedPage, sortColumn, sortType, filterFields) => dispatch(actions.fetchElements(selectedPage, sortColumn, sortType, filterFields)),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (elements, index) => dispatch(actions.deleteElement(elements, index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: (values, elements) => dispatch(actions.saveElement(values, elements)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        changeAttribute: (value, elements, index, attribute) => dispatch(actions.changeAttribute(value, elements, index, attribute)),
        updateAttribute: (id, attribute, value, elements, index) => dispatch(actions.updateAttribute(id, attribute, value, elements, index)),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        fetchInformation: () => dispatch(actions.fetchInformation()),
        showActiveLaminates: () => dispatch(dialog.actions.showDialog('active-laminates')),
        hideActiveLaminates: () => dispatch(dialog.actions.hideDialog('active-laminates')),
        saveActiveLaminates: (activeLaminates, elements, productId) => dispatch(actions.saveActiveLaminates(activeLaminates, elements, productId)),
        showActiveAccessories: () => dispatch(dialog.actions.showDialog('active-accessories')),
        hideActiveAccessories: () => dispatch(dialog.actions.hideDialog('active-accessories')),
        saveActiveAccessories: (activeAccessories, elements, productId) => dispatch(actions.saveActiveAccessories(activeAccessories, elements, productId)),
        showDiscountTable: () => dispatch(dialog.actions.showDialog('discount-table')),
        hideDiscountTable: () => dispatch(dialog.actions.hideDialog('discount-table')),
        saveDiscountTable: (discountTable, elements, productId) => dispatch(actions.saveDiscountTable(discountTable, elements, productId)),
        addActiveData: (data, dataName) => dispatch(actions.addActiveData(data, dataName)),
        deleteActiveData: (index, dataName) => dispatch(actions.deleteActiveData(index, dataName)),
        concatActiveData: (name, data) => dispatch(actions.concatActiveData(name, data)),
        addMultipleValue: (name, value) => dispatch(actions.addMultipleValue(name, value)),
        clearMultipleValue: (name) => dispatch(actions.clearMultipleValue(name)),
        concatMultipleValue: (name, data) => dispatch(actions.concatMultipleValue(name, data)),
        resetState: () => dispatch(actions.resetState()),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        addDiscount: () => dispatch(actions.addDiscount()),
        deleteDiscount: (index) => dispatch(actions.deleteDiscount(index)),
        updateDiscount: (index, field, value) => dispatch(actions.updateDiscount(index, field, value)),
        fetchElement: (id) => dispatch(actions.fetchElement(id)),
        setActivePage: (page) => dispatch(actions.setActivePage(page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
