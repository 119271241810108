const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/product-windows`,
    information: `${API_URL}/product-window/information`,
    intersectPaperCategories: `${API_URL}/paper-category/intersect`,
    intersectPaperWeights: `${API_URL}/paper/intersect-weights`,
    intersectPapers: `${API_URL}/paper/intersect-papers`,
    productCategories: `${API_URL}/product-categories`
};

export default apiUrls;
