import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class Customers extends Component {
    static propTypes = {
        customers: PropTypes.array
    };

    render() {
        let form = null;
        if (this.props.fetching['customers']) {
            form = (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin" /> Loading...
                </div>
            );
        } else {
            let customers = this.props.customers.sort((a, b) => a.display_name > b.display_name ? 1 : -1);
            form = (
                <div>
                    <b>Customer:</b>
                    <select name="active" value={this.props.customerId} onChange={this.props.handleSetCustomerId} className="ml-1">
                        <option value="">Select customer</option>
                        {customers.map(customer => <option key={'customer' + customer.id} value={customer.id}>{customer.display_name}</option>)}
                    </select>
                </div>
            );
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={this.props.changeCustomerHandler}><i className="fas fa-save" /> Save</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={this.props.hideCustomersHandler}>Close</button>
        ];
        return (
            <Dialog
                name="customers"
                modal={false}
                actions={actions}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Customers;
