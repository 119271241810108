import React, { useState } from 'react';
import { showNotification } from './../../../utils';
import PaperCategories from './../Form/PaperCategories';
import PaperWeights from './../Form/PaperWeights';
import Papers from './../Form/Papers';
import DigitalMachines from './../Form/DigitalMachines';
import FoldingTypes from './../Form/FoldingTypes';
import axios from 'axios';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const DigitalForm = (props) => {
    const [digitalCount, setDigitalCount] = useState(1);
    const [binderyCount, setBinderyCount] = useState(200);

    const [quantity, setQuantity] = useState('');
    const [digitalMachineId, setDigitalMachineId] = useState('');
    const [flatWidth, setFlatWidth] = useState('');
    const [flatHeight, setFlatHeight] = useState('');
    const [finishWidth, setFinishWidth] = useState('');
    const [finishHeight, setFinishHeight] = useState('');
    const [color, setColor] = useState('1');
    const [sides, setSides] = useState('2');
    const [paperCategoryId, setPaperCategoryId] = useState('');
    const [paperWeight, setPaperWeight] = useState('');
    const [paperId, setPaperId] = useState('');
    const [foldingTypeId, setFoldingTypeId] = useState('');
    const [useShrinkWrapping, setUseShrinkWrapping] = useState('0');
    const [perBundle, setPerBundle] = useState('');
    const [useCounting, setUseCounting] = useState('0');
    const [useDieCutting, setUseDieCutting] = useState('0');
    const [drillingHoles, setDrillingHoles] = useState('0');
    const [drillingHolesLocation, setDrillingHolesLocation] = useState('');
    const [usePadding, setUsePadding] = useState('0');
    const [sheetsToPad, setSheetsToPad] = useState('');

    const [digitalMachines, setDigitalMachines] = useState([]);
    const [paperCategories, setPaperCategories] = useState([]);
    const [paperWeights, setPaperWeights] = useState([]);
    const [papers, setPapers] = useState([]);
    const [foldingTypes, setFoldingTypes] = useState([]);
    const [isEstimating, setIsEstimating] = useState(false);

    const [quantity2, setQuantity2] = useState('');
    const [digitalMachineId2, setDigitalMachineId2] = useState('');
    const [color2, setColor2] = useState('1');
    const [sides2, setSides2] = useState('2');
    const [paperCategoryId2, setPaperCategoryId2] = useState('');
    const [paperWeight2, setPaperWeight2] = useState('');
    const [paperId2, setPaperId2] = useState('');
    const [paperCategories2, setPaperCategories2] = useState([]);
    const [paperWeights2, setPaperWeights2] = useState([]);
    const [papers2, setPapers2] = useState([]);

    const onChangeHandler = (callback, event) => {
        callback(event.target.value);
    };

    const getUnit = (unit, plural = true) => {
        if (parseInt(unit) === 1) {
            return plural ? 'seconds' : 'second';
        } else if (parseInt(unit) === 2) {
            return plural ? 'minutes' : 'minute';
        } else if (parseInt(unit) === 3) {
            return plural ? 'hours' : 'hour';
        } else if (parseInt(unit) === 4) {
            return plural ? 'days' : 'day';
        } else if (parseInt(unit) === 5) {
            return plural ? 'min per boxes' : 'min per box';
        } else {
            return 'N/A';
        }
    };

    const getDigitalTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.digital_machine_information.id}.</div>
                <div>Name: {result.digital_machine_information.name}.</div>
                <div>Number-Up: {numeral(result.paper_information.pss.up).format('0,0')}.</div>
                <div>Pss Required: {numeral(result.paper_information.pss.number_pss).format('0,0')}.</div>
                <div>Bindery Make Ready: {numeral(result.paper_information.pss.bindery_make_ready).format('0,0')}.</div>
                <div>Paper Waste: {numeral(result.paper_information.pss.paper_waste).format('0,0')}.</div>
                <div>Total Pss: {numeral(result.paper_information.pss.total_pss).format('0,0')}.</div>
                <div>Total Pars: {numeral(result.paper_information.total_pars).format('0,0')}.</div>
                <div>Speed: {numeral(result.digital_machine_information.speed).format('0,0')}.</div>
                <div>Setup Time: {numeral(result.digital_machine_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total Time: {numeral(Math.ceil(result.digital_machine_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getCuttingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.cutting_information.id}.</div>
                <div>Name: {result.cutting_information.name}.</div>
                <div>Quantity: {numeral(result.cutting_information.quantity * result.paper_information.pss.up).format('0,0')}.</div>
                <div>Setup Time: {numeral(result.cutting_information.makeready_time * 60).format('0,0')} minutes.</div>
                <div>Total Time: {numeral(Math.ceil(result.cutting_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getFoldingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.folding_information.id}.</div>
                <div>Name: {result.folding_information.name}.</div>
                <div>Quantity: {numeral(result.folding_information.quantity).format('0,0')}.</div>
                <div>Speed: {numeral(result.folding_information.speed).format('0,0')}.</div>
                <div>Setup Time: {numeral(result.folding_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total Time: {numeral(Math.ceil(result.folding_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getCountingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.counting_information.id}.</div>
                <div>Quantity: {numeral(result.counting_information.total_pieces).format('0,0')}.</div>
                <div>Timer/M-Pieces: {numeral(result.counting_information.time_per_m_pieces * 60).format('0,0')} minutes.</div>
                <div>Setup time: {numeral(result.counting_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total time: {numeral(Math.ceil(result.counting_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getDrillingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.drilling_information.id}.</div>
                <div>Quantity: {numeral(result.drilling_information.quantity).format('0,0')}.</div>
                <div>Paper Thickness: {result.drilling_information.thickness}.</div>
                <div>Time/Hole: {numeral(result.drilling_information.time_per_hole * 60).format('0,0')} minutes.</div>
                <div>Time/Additional Hole: {numeral(result.drilling_information.time_per_additional_hole * 60).format('0,0')} minutes.</div>
                <div>Setup time: {numeral(result.drilling_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total time: {numeral(Math.ceil(result.drilling_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const getPaddingTooltipInfo = (result) => {
        return (
            <div className="text-left">
                <div>ID: {result.padding_information.id}.</div>
                <div>Quantity: {numeral(result.padding_information.quantity).format('0,0')}.</div>
                <div>Time/M-Sheets: {numeral(result.padding_information.time_per_m_sheets * 60).format('0,0')} minutes.</div>
                <div>Setup time: {numeral(result.padding_information.setup_time * 60).format('0,0')} minutes.</div>
                <div>Total time: {numeral(Math.ceil(result.padding_information.total_time * 60)).format('0,0')} minutes.</div>
            </div>
        );
    };

    const generateResultSummary = (result, label, digitalCountTemp, binderyCountTemp) => {
        if (result != null) {
            if (result.digital_machine_information.total_time) {
                props.setSummaryElement(
                    'dptDigital',
                    digitalCountTemp + result.digital_machine_information.id,
                    result.digital_machine_information.name.trim() + label + ' [Digital]',
                    result.digital_machine_information.quantity,
                    Math.ceil(result.digital_machine_information.total_time * 60),
                    getDigitalTooltipInfo(result),
                    'digital'
                );
                digitalCountTemp++;
            }

            if (result.cutting_information.total_time) {
                props.setSummaryElement(
                    'dptBindery',
                    binderyCountTemp + result.cutting_information.id,
                    'Cutting' + label + ' [Digital]',
                    result.cutting_information.quantity * result.paper_information.pss.up,
                    Math.ceil(result.cutting_information.total_time * 60),
                    getCuttingTooltipInfo(result),
                    'digital'
                );
                binderyCountTemp++;
            }

            if (result.folding_information.total_time) {
                props.setSummaryElement(
                    'dptBindery',
                    binderyCountTemp + result.folding_information.id,
                    'Folding' + label + ' [Digital]',
                    result.folding_information.quantity,
                    Math.ceil(result.folding_information.total_time * 60),
                    getFoldingTooltipInfo(result),
                    'digital'
                );
                binderyCountTemp++;
            }

            if (result.counting_information.total_time) {
                props.setSummaryElement(
                    'dptBindery',
                    binderyCountTemp + result.counting_information.id,
                    'Counting' + label + ' [Digital]',
                    result.counting_information.total_pieces,
                    Math.ceil(result.counting_information.total_time * 60),
                    getCountingTooltipInfo(result),
                    'digital'
                );
                binderyCountTemp++;
            }

            if (result.drilling_information.total_time) {
                props.setSummaryElement(
                    'dptBindery',
                    binderyCountTemp + result.drilling_information.id,
                    'Drilling' + label + ' [Digital]',
                    result.drilling_information.quantity,
                    Math.ceil(result.drilling_information.total_time * 60),
                    getDrillingTooltipInfo(result),
                    'digital'
                );
                binderyCountTemp++;
            }

            if (result.padding_information.total_time) {
                props.setSummaryElement(
                    'dptBindery',
                    binderyCountTemp + result.padding_information.id,
                    'Padding' + label + ' [Digital]',
                    result.padding_information.quantity,
                    Math.ceil(result.padding_information.total_time * 60),
                    getPaddingTooltipInfo(result),
                    'digital'
                );
                binderyCountTemp++;
            }

            if (parseInt(useShrinkWrapping)) {
                const element = props.dptBindery.find((element) => parseInt(element.id) === 5);

                let unit = 0;
                if (parseInt(element.unit) === 1) {     // Seconds
                    unit = (1 / 60) / element.time_per;
                } else if (parseInt(element.unit) === 2) {      // Minutes
                    unit = 1 / element.time_per;
                } else if (parseInt(element.unit) === 3) {      // Hours
                    unit = 60 / element.time_per;
                } else if (parseInt(element.unit) === 4) {      // Days
                    unit = 480 / element.time_per;     // 8 Hours a day
                }

                let makeReadyTime = 0;
                if (parseInt(element.makeready_unit) === 1) {     // Seconds
                    makeReadyTime = element.makeready_time * 1 / 60;
                } else if (parseInt(element.makeready_unit) === 2) {      // Minutes
                    makeReadyTime = element.makeready_time * 1;
                } else if (parseInt(element.makeready_unit) === 3) {      // Hours
                    makeReadyTime = element.makeready_time * 60;
                } else if (parseInt(element.makeready_unit) === 4) {      // Days
                    makeReadyTime = element.makeready_time * 480;     // 8 Hours a day
                }

                const quantity = result.cutting_information.quantity * result.paper_information.pss.up;
                const timeInMins = quantity * unit + makeReadyTime;

                let timePerLabel = null;
                if (parseInt(element.unit) === 5) {
                    timePerLabel = numeral(element.time_per).format('0,0') + ' ' + getUnit(element.unit, false);
                } else {
                    timePerLabel = numeral(element.time_per).format('0,0') + ' per ' + getUnit(element.unit, false);
                }

                const tooltipInfo = (
                    <div className="text-left">
                        <div>Make Ready: {element.makeready_time} {getUnit(element.makeready_unit, false)}.</div>
                        <div>Production Time: {timePerLabel}.</div>
                    </div>
                );

                props.setSummaryElement(
                    'dptBindery',
                    binderyCountTemp + 100,
                    'Shrink Wrap' + label + ' [Digital]',
                    quantity,
                    timeInMins,
                    tooltipInfo,
                    'digital'
                );
                binderyCountTemp++;
            }

            if (parseInt(useDieCutting)) {
                const element = props.dptOutsideService.find((element) => parseInt(element.id) === 3);
                let unit = 0;
                if (parseInt(element.unit) === 1) {     // Seconds
                    unit = element.time_per * 1 / 60;
                } else if (parseInt(element.unit) === 2) {      // Minutes
                    unit = element.time_per * 1;
                } else if (parseInt(element.unit) === 3) {      // Hours
                    unit = element.time_per * 60;
                } else if (parseInt(element.unit) === 4) {      // Days
                    unit = element.time_per * 480;     // 8 Hours a day
                }

                let unitTooltip = getUnit(element.unit);
                const tooltipInfo = (
                    <div className="text-left">
                        <div>ProProduction Time: {numeral(element.time_per).format('0,0')} {unitTooltip}.</div>
                    </div>
                );

                props.setSummaryElement(
                    'dptOutsideService',
                    3,
                    'Die Cutting' + label + ' [Digital]',
                    1,
                    unit,
                    tooltipInfo,
                    'digital'
                );
            }
        }

        return [digitalCountTemp, binderyCountTemp];
    };

    const estimateHandler = () => {
        if (
            quantity === '' ||
            digitalMachineId === '' ||
            flatWidth === '' ||
            flatHeight === '' ||
            finishWidth === '' ||
            finishHeight === '' ||
            color === '' ||
            sides === '' ||
            paperCategoryId === '' ||
            paperWeight === '' ||
            paperId === '' ||
            (drillingHoles !== '0' && drillingHolesLocation === '') ||
            (parseInt(usePadding) && sheetsToPad === '') ||
            (parseInt(useShrinkWrapping) && perBundle === '')
        ) {
            showNotification('Complete Fields', 'Please complete all the fields before continue', 'info');
            return;
        }

        setIsEstimating(true);
        props.resetEstimateDepartment('digital');
        axios.post(process.env.REACT_APP_API_URL + '/dpt-estimate/estimate',
            {
                product_type: props.productType,
                quantity: quantity,
                color: color,
                sides: sides,
                fss_width: finishWidth,
                fss_length: finishHeight,
                flat_width: flatWidth,
                flat_length: flatHeight,
                digital_machine_id: digitalMachineId,
                paper_category_id: paperCategoryId,
                paper_weight: paperWeight,
                paper_id: paperId,
                fold_type_id: foldingTypeId,
                use_counting: useCounting,
                use_shrink_wrapping: useShrinkWrapping,
                drilling_holes: drillingHoles,
                use_padding: usePadding,
                sheets_to_pad: sheetsToPad,
                quantity2: quantity2,
                digital_machine_id2: digitalMachineId2,
                color2: color2,
                sides2: sides2,
                paper_category_id2: paperCategoryId2,
                paper_weight2: paperWeight2,
                paper_id2: paperId2,
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                let result1 = null;
                let result2 = null;
                let label1 = '';
                let label2 = '';

                if (response.data.length === 1) {
                    result1 = response.data[0].results.length > 0 ? response.data[0].results[0] : null;
                } else if (response.data.length === 2) {
                    result1 = response.data[0].results.length > 0 ? response.data[0].results[0] : null;
                    result2 = response.data[1].results.length > 0 ? response.data[1].results[0] : null;
                }

                if (result1 != null && result2 != null) {
                    label1 = ' (Machine 1)';
                    label2 = ' (Machine 2)';
                }

                if (result1 == null && result2 == null) {
                    showNotification('No Results', 'There are no results for this job configuration, please try again with a different one.', 'info');
                }

                let result = generateResultSummary(result1, label1, digitalCount, binderyCount);
                result = generateResultSummary(result2, label2, result[0], result[1]);

                setDigitalCount(result[0]);
                setBinderyCount(result[1]);

                setIsEstimating(false);
            })
            .catch((error) => {
                console.log(error)
                showNotification('Error', 'An error has occurred', 'danger')
                setIsEstimating(false);
            });
    };

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.digital) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    const resetDepartmentHandler = () => {
        props.resetEstimateDepartment('digital');

        setDigitalCount(1);
        setBinderyCount(200);

        setQuantity('');
        setDigitalMachineId('');
        setFlatWidth('');
        setFlatHeight('');
        setFinishWidth('');
        setFinishHeight('');
        setColor('1');
        setSides('2');
        setPaperCategoryId('');
        setPaperWeight('');
        setPaperId('');
        setFoldingTypeId('');
        setUseShrinkWrapping('0');
        setPerBundle('');
        setUseCounting('0');
        setUseDieCutting('0');
        setDrillingHoles('0');
        setDrillingHolesLocation('');
        setUsePadding('0');
        setSheetsToPad('');

        setPaperCategories([]);
        setPaperWeights([]);
        setPapers([]);

        setDigitalMachineId2('');
        setColor2('1');
        setSides2('2');
        setPaperCategoryId2('');
        setPaperWeight2('');
        setPaperId2('');
        setPaperCategories2([]);
        setPaperWeights2([]);
        setPapers2([]);
    };

    const colorsOptions = [<option value="1" key="bw">Black/White</option>];
    const machine = digitalMachines.find((machine) => parseInt(machine.id) === parseInt(digitalMachineId));
    if (machine && parseFloat(machine.cost_click_color) !== 0) {
        colorsOptions.push(<option value="2" key="color">Color</option>);
    }

    const colorsOptions2 = [<option value="1" key="bw">Black/White</option>];
    const machine2 = digitalMachines.find((machine) => parseInt(machine.id) === parseInt(digitalMachineId2));
    if (machine2 && parseFloat(machine2.cost_click_color) !== 0) {
        colorsOptions2.push(<option value="2" key="color">Color</option>);
    }

    return (
        <div className="estimate-form digital-form">
            <h2>
                <div>Digital</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={resetDepartmentHandler}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'digital')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.digital ? {} : { 'display': 'none' }}>
                <h3>Product Configuration</h3>
                <div className="form">
                    <div className="divider"></div>
                    <div className="form-section product-configuration">
                        <div className="field">
                            <div className="label">Flat Width:</div>
                            <input type="number" min="0" value={flatWidth} onChange={onChangeHandler.bind(this, setFlatWidth)} />
                        </div>
                        <div className="field">
                            <div className="label">Flat Height:</div>
                            <input type="number" min="0" value={flatHeight} onChange={onChangeHandler.bind(this, setFlatHeight)} />
                        </div>
                        <div className="arrows"><i className="fas fa-angle-double-right" /></div>
                        <div className="field">
                            <div className="label">Finish Width:</div>
                            <input type="number" min="0" value={finishWidth} onChange={onChangeHandler.bind(this, setFinishWidth)} />
                        </div>
                        <div className="field">
                            <div className="label">Finish Height:</div>
                            <input type="number" min="0" value={finishHeight} onChange={onChangeHandler.bind(this, setFinishHeight)} />
                        </div>
                    </div>
                    <div className="machines-container">
                        <div className="machine-configuration machine1-configuration">
                            <h3>Machine 1</h3>
                            <div className="field">
                                <div className="label">Quantity:</div>
                                <input type="number" min="0" value={quantity} onChange={onChangeHandler.bind(this, setQuantity)} />
                            </div>
                            <DigitalMachines
                                productType={props.productType}
                                digitalMachineId={digitalMachineId}
                                digitalMachines={digitalMachines}
                                setDigitalMachineId={setDigitalMachineId}
                                setDigitalMachines={setDigitalMachines}
                                setPaperCategories={setPaperCategories}
                                setPaperCategoryId={setPaperCategoryId}
                                setPaperWeights={setPaperWeights}
                                setPaperWeight={setPaperWeight}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                                setColor={setColor}
                            />
                            <div className="multi-field-container">
                                <div className="half-field">
                                    <div className="label">Color:</div>
                                    <select value={color} onChange={onChangeHandler.bind(this, setColor)}>
                                        {colorsOptions}
                                    </select>
                                </div>
                                <div className="half-field">
                                    <div className="label">Sides:</div>
                                    <select value={sides} onChange={onChangeHandler.bind(this, setSides)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </select>
                                </div>
                            </div>
                            <PaperCategories
                                productType={props.productType}
                                digitalMachineId={digitalMachineId}
                                paperCategoryId={paperCategoryId}
                                paperCategories={paperCategories}
                                setPaperCategories={setPaperCategories}
                                setPaperCategoryId={setPaperCategoryId}
                                setPaperWeights={setPaperWeights}
                                setPaperWeight={setPaperWeight}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                            />
                            <PaperWeights
                                productType={props.productType}
                                digitalMachineId={digitalMachineId}
                                paperCategoryId={paperCategoryId}
                                paperWeight={paperWeight}
                                paperWeights={paperWeights}
                                setPaperWeights={setPaperWeights}
                                setPaperWeight={setPaperWeight}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                            />
                            <Papers
                                productType={props.productType}
                                digitalMachineId={digitalMachineId}
                                paperCategoryId={paperCategoryId}
                                paperWeight={paperWeight}
                                paperId={paperId}
                                papers={papers}
                                setPapers={setPapers}
                                setPaperId={setPaperId}
                            />
                        </div>
                        <div className="machine-configuration machine2-configuration">
                            <h3>Machine 2</h3>
                            <div className="field">
                                <div className="label">Quantity:</div>
                                <input type="number" min="0" value={quantity2} onChange={onChangeHandler.bind(this, setQuantity2)} />
                            </div>
                            <DigitalMachines
                                productType={props.productType}
                                digitalMachineId={digitalMachineId2}
                                digitalMachines={digitalMachines}
                                setDigitalMachineId={setDigitalMachineId2}
                                setDigitalMachines={setDigitalMachines}
                                setPaperCategories={setPaperCategories2}
                                setPaperCategoryId={setPaperCategoryId2}
                                setPaperWeights={setPaperWeights2}
                                setPaperWeight={setPaperWeight2}
                                setPapers={setPapers2}
                                setPaperId={setPaperId2}
                                setColor={setColor2}
                            />
                            <div className="multi-field-container">
                                <div className="half-field">
                                    <div className="label">Color:</div>
                                    <select value={color2} onChange={onChangeHandler.bind(this, setColor2)}>
                                        {colorsOptions2}
                                    </select>
                                </div>
                                <div className="half-field">
                                    <div className="label">Sides:</div>
                                    <select value={sides2} onChange={onChangeHandler.bind(this, setSides2)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </select>
                                </div>
                            </div>
                            <PaperCategories
                                productType={props.productType}
                                digitalMachineId={digitalMachineId2}
                                paperCategoryId={paperCategoryId2}
                                paperCategories={paperCategories2}
                                setPaperCategories={setPaperCategories2}
                                setPaperCategoryId={setPaperCategoryId2}
                                setPaperWeights={setPaperWeights2}
                                setPaperWeight={setPaperWeight2}
                                setPapers={setPapers2}
                                setPaperId={setPaperId2}
                            />
                            <PaperWeights
                                productType={props.productType}
                                digitalMachineId={digitalMachineId2}
                                paperCategoryId={paperCategoryId2}
                                paperWeight={paperWeight2}
                                paperWeights={paperWeights2}
                                setPaperWeights={setPaperWeights2}
                                setPaperWeight={setPaperWeight2}
                                setPapers={setPapers2}
                                setPaperId={setPaperId2}
                            />
                            <Papers
                                productType={props.productType}
                                digitalMachineId={digitalMachineId2}
                                paperCategoryId={paperCategoryId2}
                                paperWeight={paperWeight2}
                                paperId={paperId2}
                                papers={papers2}
                                setPapers={setPapers2}
                                setPaperId={setPaperId2}
                            />
                        </div>
                    </div>
                    <h3>Additional Bindery</h3>
                    <div className="form-section bindery-configuration">
                        <FoldingTypes
                            productType={props.productType}
                            foldingTypeId={foldingTypeId}
                            foldingTypes={foldingTypes}
                            setFoldingTypeId={setFoldingTypeId}
                            setFoldingTypes={setFoldingTypes}
                        />
                        <div className="field">
                            <div className="label">Shrink Wrap:</div>
                            <select value={useShrinkWrapping} onChange={onChangeHandler.bind(this, setUseShrinkWrapping)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Per Bundle:</div>
                            <select value={perBundle} onChange={onChangeHandler.bind(this, setPerBundle)}>
                                <option value="">Select option</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="250">250</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Counting:</div>
                            <select value={useCounting} onChange={onChangeHandler.bind(this, setUseCounting)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Drilling Holes:</div>
                            <select value={drillingHoles} onChange={onChangeHandler.bind(this, setDrillingHoles)}>
                                <option value="0">None</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Location:</div>
                            <select value={drillingHolesLocation} onChange={onChangeHandler.bind(this, setDrillingHolesLocation)}>
                                <option value="">Select option</option>
                                <option value="1">Top Left</option>
                                <option value="2">Top Center</option>
                                <option value="3">Top Right</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Die Cutting:</div>
                            <select value={useDieCutting} onChange={onChangeHandler.bind(this, setUseDieCutting)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Padding:</div>
                            <select value={usePadding} onChange={onChangeHandler.bind(this, setUsePadding)}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <div className="field">
                            <div className="label">Sht/per pad:</div>
                            <select value={sheetsToPad} onChange={onChangeHandler.bind(this, setSheetsToPad)}>
                                <option value="">Select option</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="button-container">
                    <button onClick={estimateHandler} disabled={isEstimating}>{isEstimating ? <i className="fas fa-circle-notch fa-spin" /> : 'Estimate'}</button>
                </div>
                <AccountingForm
                    department="digital"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default DigitalForm;