import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSavePassword: PropTypes.func,
        handleForgotPassword: PropTypes.func,
        isSaving: PropTypes.bool
    };

    render() {
        const { formValues, formErrors, handleUpdateFormValue, handleSavePassword, isSaving } = this.props;

        let loginButtonLabel = 'Save';
        if (isSaving) {
            loginButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        return (
            <form>
                <h4>Enter your new password</h4>
                <div className="row">
                    <div className="col">
                        <label>New Password <span className="required">*</span></label>
                        <Input
                            name="password"
                            type="password"
                            required
                            formValues={formValues}
                            formErrors={formErrors}
                            handleUpdateFormValue={handleUpdateFormValue}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Confirm Password <span className="required">*</span></label>
                        <Input
                            name="confirm_password"
                            type="password"
                            required
                            formValues={formValues}
                            formErrors={formErrors}
                            handleUpdateFormValue={handleUpdateFormValue}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <button key="save-button" type="button" className="btn btn-light btn-sm action-button mt-3"
                            onClick={handleSavePassword} disabled={isSaving}>{loginButtonLabel}</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default Form;
