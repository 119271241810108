import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            barcode: '',
            customer: '',
            description: '',
            quantity: '',
            pm_number: '',
            ps_number: '',
            notes: '',
            accepted_at: '',
            status: 1,
            receive_description: '',
            accept_description: '',
            received_by: '',
            received_at: '',
            rep_id: '',
            location: '',
            containers_number: '',
            containers_type: '',
            containers_pieces: '',
            aisle: '',
            rack: '',
            level: '',
            notifications: [],
            notifications_names: []
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    accept: {
        id: null,
        isAccepting: false
    },
    receive: {
        id: null,
        isReceiving: false
    },
    elements: {
        data: {
            receive: [],
            accept: [],
            unclaim: [],
            receiveHistory: []
        },
        processedElements: {
            receive: [],
            accept: [],
            unclaim: [],
            receiveHistory: []
        },
        fetching: {
            receive: false,
            accept: false,
            unclaim: false,
            receiveHistory: false
        },
        isFetching: false
    },
    pagination: {
        receive: {
            totalItems: 0,
            totalPages: 0,
            selectedPage: 1
        },
        accept: {
            totalItems: 0,
            totalPages: 0,
            selectedPage: 1
        },
        unclaim: {
            totalItems: 0,
            totalPages: 0,
            selectedPage: 1
        },
        receiveHistory: {
            totalItems: 0,
            totalPages: 0,
            selectedPage: 1
        }
    },
    sort: {
        receive: {
            column: 'id',
            type: 'desc'
        },
        accept: {
            column: 'id',
            type: 'desc'
        },
        unclaim: {
            column: 'id',
            type: 'desc'
        },
        receiveHistory: {
            column: 'id',
            type: 'desc'
        }
    },
    filter: {
        receive: {
            fields: []
        },
        accept: {
            fields: []
        },
        unclaim: {
            fields: []
        },
        receiveHistory: {
            fields: []
        }
    },
    data: {
        users: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data', 'receive'], [])
                .setIn(['elements', 'data', 'accept'], [])
                .setIn(['elements', 'data', 'unclaim'], [])
                .setIn(['elements', 'data', 'receiveHistory'], [])
                .setIn(['elements', 'processedElements', 'receive'], [])
                .setIn(['elements', 'processedElements', 'accept'], [])
                .setIn(['elements', 'processedElements', 'unclaim'], [])
                .setIn(['elements', 'processedElements', 'receiveHistory'], [])
                .setIn(['pagination', 'accepted', 'selectedPage'], 1)
                .setIn(['pagination', 'received', 'selectedPage'], 1)
                .setIn(['pagination', 'unclaimed', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data', 'receive'], action.payload.receive)
                .setIn(['elements', 'data', 'accept'], action.payload.accept)
                .setIn(['elements', 'data', 'unclaim'], action.payload.unclaim)
                .setIn(['elements', 'data', 'receiveHistory'], action.payload.receiveHistory)
                .setIn(['pagination', 'accepted', 'selectedPage'], 1)
                .setIn(['pagination', 'received', 'selectedPage'], 1)
                .setIn(['pagination', 'unclaimed', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT:
            state = state.setIn(['activeElement', 'isFetching'], true);
            break;

        case actionTypes.FETCH_ELEMENT_SUCCESS:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT_FAILURE:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ACCEPT_ELEMENTS:
            state = state.setIn(['elements', 'fetching', 'accept'], true)
                .setIn(['elements', 'data', 'accept'], []);
            break;

        case actionTypes.FETCH_ACCEPT_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'fetching', 'accept'], false)
                .setIn(['elements', 'data', 'accept'], action.payload.elements)
                .setIn(['pagination', 'accept', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'accept', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'accept', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ACCEPT_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'fetching', 'accept'], false);
            break;

        case actionTypes.FETCH_RECEIVE_ELEMENTS:
            state = state.setIn(['elements', 'fetching', 'receive'], true)
                .setIn(['elements', 'data', 'receive'], []);
            break;

        case actionTypes.FETCH_RECEIVE_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'fetching', 'receive'], false)
                .setIn(['elements', 'data', 'receive'], action.payload.elements)
                .setIn(['pagination', 'receive', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'receive', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'receive', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_RECEIVE_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'fetching', 'receive'], false);
            break;

        case actionTypes.FETCH_UNCLAIM_ELEMENTS:
            state = state.setIn(['elements', 'fetching', 'unclaim'], true)
                .setIn(['elements', 'data', 'unclaim'], []);
            break;

        case actionTypes.FETCH_UNCLAIM_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'fetching', 'unclaim'], false)
                .setIn(['elements', 'data', 'unclaim'], action.payload.elements)
                .setIn(['pagination', 'unclaim', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'unclaim', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'unclaim', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_UNCLAIM_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'fetching', 'unclaim'], false);
            break;

        case actionTypes.FETCH_HISTORY_ELEMENTS:
            state = state.setIn(['elements', 'fetching', 'receiveHistory'], true)
                .setIn(['elements', 'data', 'receiveHistory'], []);
            break;

        case actionTypes.FETCH_HISTORY_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'fetching', 'receiveHistory'], false)
                .setIn(['elements', 'data', 'receiveHistory'], action.payload.elements)
                .setIn(['pagination', 'receiveHistory', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'receiveHistory', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'receiveHistory', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_HISTORY_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'fetching', 'receiveHistory'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', action.payload.name, 'selectedPage'], action.payload.page);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', action.payload.name, 'column'], action.payload.column)
                .setIn(['sort', action.payload.name, 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data', action.payload.name], action.payload.elements);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                barcode: '',
                customer: '',
                description: '',
                quantity: '',
                pm_number: '',
                ps_number: '',
                notes: '',
                accepted_at: '',
                status: 1,
                receive_description: '',
                accept_description: '',
                received_by: '',
                received_at: '',
                rep_id: '',
                location: '',
                containers_number: '',
                containers_type: '',
                containers_pieces: '',
                aisle: '',
                rack: '',
                level: '',
                notifications: [],
                notifications_names: []
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues'], action.payload);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.updateIn(['filter', action.payload.filterName, 'fields'], (fields) => {
                const index = fields.findIndex(field => field.get('name') === action.payload.name);
                if (action.payload.value === '') {
                    return fields.delete(index);
                } else {
                    if (index === -1) {
                        return fields.push({ name: action.payload.name, value: action.payload.value });
                    } else {
                        return fields.set(index, { name: action.payload.name, value: action.payload.value });
                    }
                }
            });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements', 'receive'], action.payload.receive)
                .setIn(['elements', 'processedElements', 'accept'], action.payload.accept)
                .setIn(['elements', 'processedElements', 'unclaim'], action.payload.unclaim)
                .setIn(['elements', 'processedElements', 'receiveHistory'], action.payload.receiveHistory);
            break;

        case actionTypes.PROCESS_ACCEPT_ELEMENTS:
            state = state.setIn(['elements', 'processedElements', 'accept'], action.payload);
            break;

        case actionTypes.PROCESS_RECEIVE_ELEMENTS:
            state = state.setIn(['elements', 'processedElements', 'receive'], action.payload);
            break;

        case actionTypes.PROCESS_UNCLAIM_ELEMENTS:
            state = state.setIn(['elements', 'processedElements', 'unclaim'], action.payload);
            break;

        case actionTypes.PROCESS_HISTORY_ELEMENTS:
            state = state.setIn(['elements', 'processedElements', 'receiveHistory'], action.payload);
            break;

        case actionTypes.FETCH_DATA:
            state = state.setIn(['data', 'users'], []);
            break;

        case actionTypes.FETCH_DATA_SUCCESS:
            state = state.setIn(['data', 'users'], action.payload.users);
            break;

        case actionTypes.FETCH_DATA_FAILURE:
            break;

        case actionTypes.ADD_NOTIFICATION:
            state = state.updateIn(['form', 'formValues', 'notifications'], (notifications) => {
                return notifications.push(action.payload);
            });
            break;

        case actionTypes.REMOVE_NOTIFICATION:
            state = state.updateIn(['form', 'formValues', 'notifications'], (notifications) => {
                let index = notifications.indexOf(action.payload);
                return notifications.delete(index);
            });
            break;

        case actionTypes.ACCEPT:
            state = state.setIn(['accept', 'isAccepting'], true);
            break;

        case actionTypes.ACCEPT_SUCCESS:
            state = state.setIn(['accept', 'isAccepting'], false);
            break;

        case actionTypes.ACCEPT_FAILURE:
            state = state.setIn(['accept', 'isAccepting'], false);
            break;

        case actionTypes.SET_ID_TO_ACCEPT:
            state = state.setIn(['accept', 'id'], action.payload);
            break;

        case actionTypes.RECEIVE:
            state = state.setIn(['receive', 'isReceiving'], true);
            break;

        case actionTypes.RECEIVE_SUCCESS:
            state = state.setIn(['receive', 'isReceiving'], false);
            break;

        case actionTypes.RECEIVE_FAILURE:
            state = state.setIn(['receive', 'isReceiving'], false);
            break;

        case actionTypes.SET_ID_TO_RECEIVE:
            state = state.setIn(['receive', 'id'], action.payload);
            break;

        default:
            break;
    }

    return state.toJS();
}
