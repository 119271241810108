const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/timeline-logs`,
    search: `${API_URL}/timeline-log/search`,
    sendEmail: `${API_URL}/timeline-log/send-email`,
    printPdf: `${API_URL}/timeline-log/print-pdf`
};

export default apiUrls;
