import React from 'react';
import { Dialog } from './../../common/dialog';

const Form = React.memo((props) => {
    let saveButtonLabel = 'Save';
    if (props.isSavingForm) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let statesOptions = [{ label: 'Select', value: '' }];
    let states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas',
        'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia',
        'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana',
        'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
        'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
        'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
        'Oklahoma', 'Oregon', 'Other Links', 'Pennsylvania', 'Puerto Rico',
        'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas',
        'Tribal Governments', 'U.S. Virgin Islands', 'Utah', 'Vermont', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
    for (let i = 0; i < states.length; i++) {
        statesOptions.push({ label: states[i], value: states[i] });
    }

    let termsOptions = [{ label: 'Select Option', value: '' }];
    for (let i = 0; i < props.terms.length; i++) {
        termsOptions.push({ label: props.terms[i].label, value: props.terms[i].value });
    }

    const handleFieldChange = (event) => {
        props.handleUpdateFormValue('text', false, event);

        if (event.target.name === 'display_name' && props.formValues['use_display_name_for_check']) {
            props.handleUpdateFormValueSimple('print_on_check_name', event.target.value);
        }
        if (event.target.name === 'use_display_name_for_check' && event.target.value) {
            props.handleUpdateFormValueSimple('print_on_check_name', props.formValues['display_name']);
        }
    };

    const renderTextField = (name, options = {}) => {
        let classes = ['text-field'];
        if (options.className != null) {
            classes.push(options.className);
        }

        return (
            <div className={classes.join(' ')}>
                <input
                    type="text"
                    name={name}
                    disabled={options.disabled}
                    placeholder={options.placeholder}
                    value={props.formValues[name]}
                    onChange={handleFieldChange}
                />
            </div>
        );
    };

    const renderCheckboxField = (name, config = {}) => {
        let classes = ['checkbox-field'];
        if (config.className != null) {
            classes.push(config.className);
        }

        return (
            <input
                type="checkbox"
                name={name}
                className={classes.join(' ')}
                value={props.formValues[name]}
                checked={props.formValues[name]}
                disabled={config.disabled}
                onChange={handleFieldChange}
            />
        );
    };

    const renderSelectField = (name, options = [], config = {}) => {
        let classes = ['text-field'];
        if (config.className != null) {
            classes.push(config.className);
        }

        return (
            <div className={classes.join(' ')}>
                <select name={name} value={props.formValues[name]} onChange={handleFieldChange} disabled={config.disabled}>
                    {options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
                </select>
            </div>
        );
    };


    const renderTextareaField = (name, options = {}) => {
        let classes = ['text-field'];
        if (options.className != null) {
            classes.push(options.className);
        }

        return (
            <div className={classes.join(' ')}>
                <textarea
                    type="text"
                    name={name}
                    disabled={options.disabled}
                    placeholder={options.placeholder}
                    value={props.formValues[name]}
                    onChange={handleFieldChange}
                />
            </div>
        );
    };

    let form = (
        <form>
            <div className="subtitle">Vendor Information</div>
            <div className="main-container">
                <div className="column1">
                    <div className="field-container title-field">
                        <label>Title</label>
                        {renderTextField('title')}
                    </div>
                    <div className="field-container first-name-field">
                        <label>First Name <span className="required">*</span></label>
                        {renderTextField('first_name')}
                    </div>
                    <div className="field-container middle-name-field">
                        <label>Middle Name</label>
                        {renderTextField('middle_name')}
                    </div>
                    <div className="field-container last-name-field">
                        <label>Last Name</label>
                        {renderTextField('last_name')}
                    </div>
                    <div className="field-container suffix-field">
                        <label>Suffix</label>
                        {renderTextField('suffix')}
                    </div>
                    <div className="field-container company-field">
                        <label>Company <span className="required">*</span></label>
                        {renderTextField('company')}
                    </div>
                    <div className="field-container display-name-field">
                        <label>Display name as <span className="required">*</span></label>
                        {renderTextField('display_name')}
                    </div>
                    <div className="field-container use-display-name-field">
                        <label>{renderCheckboxField('use_display_name_for_check')} Use display name</label>
                    </div>
                    <div className="field-container print-on-check-name-field">
                        <label>Print on check as</label>
                        {renderTextField('print_on_check_name', { disabled: props.formValues['use_display_name_for_check'] })}
                    </div>
                    <div className="address-label">Address</div>
                    {renderTextField('address', { className: 'address-field', placeholder: 'Street' })}
                    {renderTextField('city', { className: 'city-field', placeholder: 'City/Town' })}
                    {renderTextField('state', { className: 'state-field', placeholder: 'State/Province' })}
                    {renderTextField('zip', { className: 'zip-field', placeholder: 'ZIP Code' })}
                    {renderTextField('country', { className: 'country-field', placeholder: 'Country' })}
                </div>
                <div className="column2">
                    <div className="field-container email-field">
                        <label>Email</label>
                        {renderTextField('email')}
                    </div>
                    <div className="field-container phone-field">
                        <label>Phone</label>
                        {renderTextField('phone')}
                    </div>
                    <div className="field-container mobile-field">
                        <label>Mobile</label>
                        {renderTextField('mobile')}
                    </div>
                    <div className="field-container fax-field">
                        <label>Fax</label>
                        {renderTextField('fax')}
                    </div>
                    <div className="field-container other-field">
                        <label>Other</label>
                        {renderTextField('other')}
                    </div>
                    <div className="field-container website-field">
                        <label>Website</label>
                        {renderTextField('website')}
                    </div>
                    <div className="field-container bill-rate-field">
                        <label>Bill Rate(/hr)</label>
                        {renderTextField('bill_rate')}
                    </div>
                    <div className="field-container terms-field">
                        <label>Terms</label>
                        {renderSelectField('terms', termsOptions)}
                    </div>
                    <div className="field-container account-field">
                        <label>Account no.</label>
                        {renderTextField('account_number')}
                    </div>
                    <div className="notes-tab-container">
                        <label><b>Notes</b></label>
                        {renderTextareaField('notes', { placeholder: 'Notes...' })}
                    </div>
                </div>
            </div>
        </form>
    );
    if (props.isFetchingElement) {
        form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
    }

    let actions = [
        <button key="save-button" className="btn btn-light btn-sm action-button" onClick={props.handleSaveElement} disabled={props.isSavingForm}>{saveButtonLabel}</button>,
        <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideForm}>Close</button>
    ];
    return (
        <Dialog
            name="form"
            modal={false}
            actions={actions}
            contentStyle={{ width: 1100, maxWidth: 1100 }}
            bodyStyle={{ overflow: 'initial' }}
            className="large-dialog vendor-form"
        >
            <div className="form-container vendors-form-container">{form}</div>
        </Dialog>
    );
});

export default Form;