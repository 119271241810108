export const FETCH_DATA = 'DPT_CALCULATOR/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'DPT_CALCULATOR/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'DPT_CALCULATOR/FETCH_DATA_ERROR';
export const CHECK_ELEMENT = 'DPT_CALCULATOR/CHECK_ELEMENT';
export const SET_QUANTITY = 'DPT_CALCULATOR/SET_QUANTITY';
export const SET_INCHES_LONG = 'DPT_CALCULATOR/SET_INCHES_LONG';
export const SET_INSERTS = 'DPT_CALCULATOR/SET_INSERTS';
export const SET_SHOW = 'DPT_CALCULATOR/SET_SHOW';
export const SET_WAFER_SEALS = 'DPT_CALCULATOR/SET_WAFER_SEALS';
export const SET_DEPARTMENT = 'DPT_CALCULATOR/SET_DEPARTMENT';
export const SET_DEPARTMENT_ELEMENT = 'DPT_CALCULATOR/SET_DEPARTMENT_ELEMENT';
export const SET_ENTIRE_DEPARTMENT = 'DPT_CALCULATOR/SET_ENTIRE_DEPARTMENT';
export const REPLACE_DEPARTMENT = 'DPT_CALCULATOR/REPLACE_DEPARTMENT';
export const RESET_DATA = 'DPT_CALCULATOR/RESET_DATA';
export const HIDE_ALL = 'DPT_CALCULATOR/HIDE_ALL';
export const SET_SUMMARY_ELEMENT = 'DPT_CALCULATOR/SET_SUMMARY_ELEMENT';
export const REMOVE_SUMMARY_ELEMENT = 'DPT_CALCULATOR/REMOVE_SUMMARY_ELEMENT';
export const SET_DIFFICULTY_LEVEL_DP = 'DPT_CALCULATOR/SET_DIFFICULTY_LEVEL_DP';
export const SET_PRESORT_DP = 'DPT_CALCULATOR/SET_PRESORT_DP';
export const RESET_SUMMARY_DEPARTMENTS = 'DPT_CALCULATOR/RESET_SUMMARY_DEPARTMENTS';
export const TOGGLE_SHOW = 'DPT_CALCULATOR/TOGGLE_SHOW';
export const RESET_DEPARTMENT = 'DPT_CALCULATOR/RESET_DEPARTMENT';
export const RESET_ESTIMATE_DEPARTMENT = 'DPT_CALCULATOR/RESET_ESTIMATE_DEPARTMENT';
export const SET_DEPARTMENT_MAIN_QUANTITY = 'DPT_CALCULATOR/SET_DEPARTMENT_MAIN_QUANTITY';
export const CHANGE_ACCOUNT_FIELD = 'DPT_CALCULATOR/CHANGE_ACCOUNT_FIELD';
export const CHANGE_ACCOUNT_ITEM = 'DPT_CALCULATOR/CHANGE_ACCOUNT_ITEM';
export const ADD_ACCOUNT_ITEM = 'DPT_CALCULATOR/ADD_ACCOUNT_ITEM';
export const REMOVE_ACCOUNT_ITEM = 'DPT_CALCULATOR/REMOVE_ACCOUNT_ITEM';