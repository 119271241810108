import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table } from './components';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        user: PropTypes.object
    };

    render() {
        const { user } = this.props;
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid admin-module-container">
                    <Helmet>
                        <title>Dashboard</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Admin"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            <Table
                                user={user}
                                history={this.props.history}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
