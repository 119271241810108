import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = (selectedPage, sortColumn, sortType, productCategoryId) => dispatch => {
    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.search + '?selectedPage=' + selectedPage + '&sort=' + column
        + '&product-category-id=' + productCategoryId + '&summary=1',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchData = () => (dispatch, getState) => {
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());

    dispatch({ type: actionTypes.FETCH_DATA, payload: null });
    axios.get(apiUrls.data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_DATA_SUCCESS, payload: response.data });
            if (response.data.productCategories.length > 0) {
                dispatch(fetchElements(1, sortColumn, sortType, response.data.productCategories[0].id));
                dispatch(setProductType(response.data.productCategories[0].id, response.data.productCategories[0].type));
            }

            if (response.data.customers.length > 0) {
                dispatch(setCustomerId(response.data.customers[0].id));
            }
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_DATA_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function setActivePage(page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: page
    };
}

export function setProductType(productCategoryId, productType) {
    return {
        type: actionTypes.SET_PRODUCT_TYPE,
        payload: { productCategoryId, productType }
    };
}

export function setCustomerId(id) {
    return {
        type: actionTypes.SET_CUSTOMER_ID,
        payload: id
    };
}
