import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = () => dispatch => {
    let fields = ['id', 'name', 'email', 'username', 'is_active', 'phone',
        'user_type', 'user_type_name', 'user_type_names_text', 'is_vdp'];
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements + '?fields=' + fields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const saveElement = (values, elements) => (dispatch, getState) => {
    const permissions = selectors.getPermissions(getState());
    const selectedProductWindows = selectors.getSelectedProductWindows(getState());

    let errors = {};
    let errorMessages = {};
    if (values.name === '' || values.name === null) {
        errors.name = true;
    }
    if (values.email === '' || values.email === null) {
        errors.email = true;
    }
    if (values.username === '' || values.username === null) {
        errors.username = true;
    }
    if (values.id === '' && (values.password === '' || values.password === null)) {
        errors.password = true;
    }
    if (values.is_active === '' || values.is_active === null) {
        errors.is_active = true;
    }
    if (values.user_type_1 === '' || values.user_type_1 === null) {
        errors.user_type_1 = true;
    }
    if (parseInt(values.user_type_1) === 20 && (values.department_id === '' || values.department_id === null)) {   // PQT Department View
        errors.department_id = true;
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    values['permissions'] = permissions;
    values['selectedProductWindows'] = selectedProductWindows;

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, values.id === '' ? 1 : 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
                dispatch(processElements());
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export function checkPermission(name, subname, value) {
    return {
        type: actionTypes.CHECK_PERMISSION,
        payload: { name, subname, value }
    };
}

export function checkMainPermission(name, value) {
    return {
        type: actionTypes.CHECK_MAIN_PERMISSION,
        payload: { name, value }
    };
}

export function checkAllPermissions(value) {
    return {
        type: actionTypes.CHECK_ALL_PERMISSIONS,
        payload: { value }
    };
}

export function processElements() {
    return (dispatch, getState) => {
        const elements = selectors.getElements(getState());
        const filterFields = selectors.getFilterFields(getState());
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());

        let filteredElements = [];
        for (let i = 0; i < elements.length; i++) {
            let add = true;
            for (let j = 0; j < filterFields.length; j++) {
                let attribute = String(elements[i][filterFields[j]['name']]).toLowerCase();
                let filterValue = String(filterFields[j]['value']).toLowerCase();
                if (!attribute.includes(filterValue)) {
                    add = false;
                    break;
                }
            }

            if (add) {
                filteredElements.push(elements[i]);
            }
        }

        let sortedElements = filteredElements.sort((a, b) => {
            if (typeof a[sortColumn] === 'number') {
                if (sortType === 'asc') {
                    if (a[sortColumn] < b[sortColumn]) return -1;
                    if (a[sortColumn] > b[sortColumn]) return 1;
                    if (a['id'] < b['id']) return -1;
                    if (a['id'] > b['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn] < a[sortColumn]) return -1;
                    if (b[sortColumn] > a[sortColumn]) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            } else {
                if (sortType === 'asc') {
                    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) return -1;
                    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn].toLowerCase() < a[sortColumn].toLowerCase()) return -1;
                    if (b[sortColumn].toLowerCase() > a[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            }
        });

        dispatch({
            type: actionTypes.PROCESS_ELEMENTS,
            payload: sortedElements
        });
    };
}

export function setupPermissions(elements, id) {
    return (dispatch, getState) => {
        let element = null;
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === id) {
                element = elements[i];
                break;
            }
        }

        dispatch({
            type: actionTypes.SETUP_PERMISSIONS,
            payload: element['permissions']
        });
    };
}

export const fetchElement = (id) => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement([response.data], id));
            dispatch(setupPermissions([response.data], id));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchInformation = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_INFORMATION_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function toggleProductWindow(id) {
    return {
        type: actionTypes.TOGGLE_PRODUCT_WINDOW,
        payload: id
    };
}

