export const FETCH_INVOICES = 'INVOICES/FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'INVOICES/FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'INVOICES/FETCH_INVOICES_ERROR';
export const FETCH_DATA = 'INVOICES/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'INVOICES/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'INVOICES/FETCH_DATA_ERROR';
export const SAVE_INVOICE = 'INVOICES/SAVE_INVOICE';
export const SAVE_INVOICE_SUCCESS = 'INVOICES/SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_ERROR = 'INVOICES/SAVE_INVOICE_ERROR';
export const DELETE_INVOICE = 'INVOICES/DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'INVOICES/DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'INVOICES/DELETE_INVOICE_ERROR';
export const PUSH_INVOICE_TO_QB = 'INVOICES/PUSH_INVOICE_TO_QB';
export const PUSH_INVOICE_TO_QB_SUCCESS = 'INVOICES/PUSH_INVOICE_TO_QB_SUCCESS';
export const PUSH_INVOICE_TO_QB_ERROR = 'INVOICES/PUSH_INVOICE_TO_QB_ERROR';
export const CONVERT_TO_JOB = 'INVOICES/CONVERT_TO_JOB';
export const CONVERT_TO_JOB_SUCCESS = 'INVOICES/CONVERT_TO_JOB_SUCCESS';
export const CONVERT_TO_JOB_ERROR = 'INVOICES/CONVERT_TO_JOB_ERROR';
export const SET_SORT = 'INVOICES/SET_SORT';
export const CHANGE_FILTER = 'INVOICES/CHANGE_FILTER';
export const SET_ACTIVE_PAGE = 'INVOICES/SET_ACTIVE_PAGE';
export const SET_EDITING = 'INVOICES/SET_EDITING';
export const SET_ACTIVE_ID = 'INVOICES/SET_ACTIVE_ID';