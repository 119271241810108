import { SHOW_DIALOG, HIDE_DIALOG } from './../actionTypes';

export function showDialog(name) {
    return {
        type: SHOW_DIALOG,
        payload: name
    };
}

export function hideDialog(name) {
    return {
        type: HIDE_DIALOG,
        payload: name
    };
}
