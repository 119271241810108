import Immutable from 'immutable';
import * as types from './DptCalculator.types';

const dataInitialValue = {
    dptBindery: [],
    dptDataProcessing: [],
    dptDigital: [],
    dptInkjet: [],
    dptInkjetMaterial: [],
    dptInserting: [],
    dptOutsideService: [],
    dptPress: [],
    dptHand: [],
    dptLargeFormat: [],
};

const showInitialValue = {
    flat: true,
    multiPage: false,
    digital: false,
    largeFormat: false,
    dataProcessing: false,
    inkjet: false,
    inserting: false,
    hand: false,
    outsideService: false,
};

const summaryValues = {
    dptBindery: [],
    dptDataProcessing: [],
    dptDigital: [],
    dptInkjet: [],
    dptInkjetMaterial: [],
    dptInserting: [],
    dptOutsideService: [],
    dptPress: [],
    dptHand: [],
    dptLargeFormat: [],
};

const itemInitialValue = {
    invoice: false,
    description: '',
    quantity: '',
    unit: '1',
    price: '',
    total: '',
};

const accountingItemIntialValue = {
    items: [itemInitialValue],
};

const accountingInitialValues = {
    flat: accountingItemIntialValue,
    multiPage: accountingItemIntialValue,
    digital: accountingItemIntialValue,
    largeFormat: accountingItemIntialValue,
    dataProcessing: accountingItemIntialValue,
    inkjet: accountingItemIntialValue,
    inserting: accountingItemIntialValue,
    hand: accountingItemIntialValue,
    outsideService: accountingItemIntialValue,
};

const initialState = {
    isFetchingData: false,
    data: dataInitialValue,
    show: showInitialValue,
    summary: summaryValues,
    accounting: accountingInitialValues,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_DATA:
            return state.setIn(['isFetchingData'], true).toJS();

        case types.FETCH_DATA_SUCCESS:
            return state.setIn(['isFetchingData'], false)
                .setIn(['data'], action.payload)
                .toJS();

        case types.FETCH_DATA_ERROR:
            return state.setIn(['isFetchingData'], false).toJS();

        case types.CHECK_ELEMENT:
            return state.updateIn(['data', action.payload.name], (elements) => {
                const index = elements.findIndex((element) => parseInt(element.get('id')) === parseInt(action.payload.id));
                const element = elements.get(index);
                return elements.set(index, element.setIn(['checked'], action.payload.value));
            }).toJS();

        case types.SET_QUANTITY:
            return state.updateIn(['data', action.payload.name], (elements) => {
                const index = elements.findIndex((element) => parseInt(element.get('id')) === parseInt(action.payload.id));
                const element = elements.get(index);
                return elements.set(index, element.setIn(['selected_quantity'], action.payload.value));
            }).toJS();

        case types.SET_INCHES_LONG:
            return state.updateIn(['data', action.payload.name], (elements) => {
                const index = elements.findIndex((element) => parseInt(element.get('id')) === parseInt(action.payload.id));
                const element = elements.get(index);
                return elements.set(index, element.setIn(['inches_long'], action.payload.value));
            }).toJS();

        case types.SET_WAFER_SEALS:
            return state.updateIn(['data', action.payload.name], (elements) => {
                const index = elements.findIndex((element) => parseInt(element.get('id')) === parseInt(action.payload.id));
                const element = elements.get(index);
                return elements.set(index, element.setIn(['wafer_seals'], action.payload.value));
            }).toJS();

        case types.SET_INSERTS:
            return state.updateIn(['data', action.payload.name], (elements) => {
                const index = elements.findIndex((element) => parseInt(element.get('id')) === parseInt(action.payload.id));
                const element = elements.get(index);
                return elements.set(index, element.setIn(['inserts'], action.payload.value));
            }).toJS();

        case types.SET_SHOW:
            return state.setIn(['show', action.payload.name], action.payload.value).toJS();

        case types.SET_DEPARTMENT:
            return state.setIn(['data', action.payload.name], action.payload.value).toJS();

        case types.SET_DEPARTMENT_ELEMENT:
            return state.updateIn(['data', action.payload.name], (elements) => {
                const index = elements.findIndex((element) => parseInt(element.get('id')) === parseInt(action.payload.id));
                return elements.set(index, action.payload.value);
            }).toJS();

        case types.SET_ENTIRE_DEPARTMENT:
            return state.updateIn(['data', action.payload.name], (data) => {
                const newElements = data.map((element) =>
                    element.setIn(['checked'], 0).setIn(['readOnly'], 0).setIn(['selected_quantity'], '')
                );

                let elements = newElements.toJS();
                for (let i = 0; i < action.payload.indexes.length; i++) {
                    const index = action.payload.indexes[i];
                    const value = action.payload.values[i];
                    for (const key in value) {
                        elements[index][key] = value[key];
                    }
                }

                return elements;
            })
                .setIn(['show', action.payload.showName], true)
                .toJS();

        case types.REPLACE_DEPARTMENT:
            return state.setIn(['data', action.payload.name], action.payload.value)
                .setIn(['show', action.payload.showName], true)
                .toJS();

        case types.RESET_DATA:
            return state.setIn(['data'], dataInitialValue)
                .setIn(['show'], showInitialValue)
                .toJS();

        case types.HIDE_ALL:
            return state.setIn(['show'], showInitialValue).toJS();

        case types.SET_SUMMARY_ELEMENT:
            return state.updateIn(['summary', action.payload.departmentName], (elements) => {
                const index = elements.findIndex((element) => parseInt(element.get('id')) === parseInt(action.payload.id));
                const element = {
                    id: action.payload.id,
                    name: action.payload.name,
                    quantity: action.payload.quantity,
                    totalTime: action.payload.totalTime,
                    tooltipInfo: action.payload.tooltipInfo,
                    department: action.payload.department,
                };
                if (index === -1) {
                    return elements.push(element);
                } else {
                    return elements.set(index, element);
                }
            }).toJS();

        case types.REMOVE_SUMMARY_ELEMENT:
            return state.updateIn(['summary', action.payload.departmentName], (elements) => {
                return elements.filter((element) => parseInt(element.get('id')) !== parseInt(action.payload.id));
            }).toJS();

        case types.SET_DIFFICULTY_LEVEL_DP:
            return state.updateIn(['summary', 'dptDataProcessing'], (elements) => {
                const filteredElements = elements.filter((element) => ![20, 21, 22, 23].includes(parseInt(element.get('id'))));
                return filteredElements.push({
                    id: action.payload.id,
                    name: action.payload.name,
                    quantity: 1,
                    totalTime: action.payload.totalTime,
                    tooltipInfo: action.payload.tooltipInfo,
                });
            }).toJS();

        case types.SET_PRESORT_DP:
            return state.updateIn(['summary', 'dptDataProcessing'], (elements) => {
                const filteredElements = elements.filter((element) => ![14, 15, 16].includes(parseInt(element.get('id'))));
                return filteredElements.push({
                    id: action.payload.id,
                    name: action.payload.name,
                    quantity: 1,
                    totalTime: action.payload.totalTime,
                    tooltipInfo: action.payload.tooltipInfo,
                });
            }).toJS();

        case types.RESET_SUMMARY_DEPARTMENTS:
            return state.updateIn(['summary'], (summary) => {
                const newSummary = summary.toJS();
                if (action.payload.includes('dptPress')) {
                    newSummary['dptPress'] = [];
                }
                if (action.payload.includes('dptBindery')) {
                    newSummary['dptBindery'] = [];
                }
                if (action.payload.includes('dptLargeFormat')) {
                    newSummary['dptLargeFormat'] = [];
                }
                return newSummary;
            }).toJS();

        case types.TOGGLE_SHOW:
            return state.updateIn(['show', action.payload], (value) => !value).toJS();

        case types.RESET_DEPARTMENT:
            return state.setIn(['summary', action.payload], [])
                .updateIn(['data', action.payload], (data) => {
                    const elements = data.toJS();
                    for (const element of elements) {
                        element['checked'] = 0;
                        element['selected_quantity'] = '';
                        delete element['inserts'];
                        delete element['inches_long'];
                        delete element['wafer_seals'];
                    }
                    return elements;
                })
                .toJS();

        case types.RESET_ESTIMATE_DEPARTMENT:
            return state.updateIn(['summary'], (summary) => {
                const departments = summary.toJS();
                for (const key in departments) {
                    departments[key] = departments[key].filter((element) => (element.department && element.department !== action.payload) || !element.department);
                }
                return departments;
            }).toJS();

        case types.SET_DEPARTMENT_MAIN_QUANTITY:
            return state.updateIn(['data', action.payload.department], (data) => {
                const elements = data.toJS();
                for (const element of elements) {
                    if (action.payload.ids.includes(parseInt(element.id))) {
                        element['selected_quantity'] = action.payload.quantity;
                    }
                }
                return elements;
            }).toJS();

        case types.CHANGE_ACCOUNT_FIELD:
            return state.setIn(['accounting', action.payload.department, action.payload.field], action.payload.value).toJS();

        case types.CHANGE_ACCOUNT_ITEM:
            return state.updateIn(['accounting', action.payload.department, 'items'], (items) => {
                const elements = items.toJS();
                elements[action.payload.index][action.payload.field] = action.payload.value;
                return elements;
            }).toJS();

        case types.ADD_ACCOUNT_ITEM:
            return state.updateIn(['accounting', action.payload.department, 'items'], (items) => {
                return items.push(itemInitialValue);
            }).toJS();

        case types.REMOVE_ACCOUNT_ITEM:
            return state.updateIn(['accounting', action.payload.department, 'items'], (items) => {
                const elements = items.toJS();
                elements.splice(action.payload.index, 1);
                return elements;
            }).toJS();

        default:
            return state.toJS();
    }
}