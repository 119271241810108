import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import moment from 'moment';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            type: '',
            batch: '',
            date: moment().format('MM/DD/YYYY'),
            check: '',
            payor_id: '',
            sales_id: '',
            csr_id: '',
            has_postage: 0,
            has_pm: 0,
            amount: '',
            acct_rec: '',
            pstg: '',
            other: '',
            total: '',
            pm_invoices: ['', '', ''],
            notes: '',
            name: '',
            approval_code: '',
            convenience_fee: '',
            ac_bank: '',
            audit_history: [],
            email_csr: 0,
            email_sales: 0,
            email_subject: '',
            email_notes: '',
            emails: []
        },
        formErrors: {},
        isSaving: false,
        isSendingEmail: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false,
        amount: 0,
        acct: 0,
        pstg: 0,
        other: 0
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'date',
        type: 'desc'
    },
    filter: {
        fields: []
    },
    data: {
        payors: [],
        sales: [],
        csrs: [],
        mainList: []
    },
    show: {
        list: true,
        form: false,
        email: false
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage)
                .setIn(['elements', 'amount'], action.payload.amount)
                .setIn(['elements', 'acct'], action.payload.acct)
                .setIn(['elements', 'pstg'], action.payload.pstg)
                .setIn(['elements', 'other'], action.payload.other)
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                type: '',
                batch: '',
                date: moment().format('MM/DD/YYYY'),
                check: '',
                payor_id: '',
                sales_id: '',
                csr_id: '',
                has_postage: 0,
                has_pm: 0,
                amount: '',
                acct_rec: '',
                pstg: '',
                other: '',
                total: '',
                pm_invoices: ['', '', ''],
                notes: '',
                name: '',
                approval_code: '',
                convenience_fee: '',
                ac_bank: '',
                audit_history: [],
                email_csr: 0,
                email_sales: 0,
                email_subject: '',
                email_notes: '',
                emails: []
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'type'], action.payload.type)
                .setIn(['form', 'formValues', 'batch'], action.payload.batch)
                .setIn(['form', 'formValues', 'date'], moment(action.payload.date).format('MM/DD/YYYY'))
                .setIn(['form', 'formValues', 'check'], action.payload.check)
                .setIn(['form', 'formValues', 'payor_id'], action.payload.payor_id)
                .setIn(['form', 'formValues', 'sales_id'], action.payload.sales_id)
                .setIn(['form', 'formValues', 'csr_id'], action.payload.csr_id)
                .setIn(['form', 'formValues', 'has_postage'], action.payload.has_postage)
                .setIn(['form', 'formValues', 'has_pm'], action.payload.has_pm)
                .setIn(['form', 'formValues', 'amount'], action.payload.amount)
                .setIn(['form', 'formValues', 'acct_rec'], action.payload.acct_rec)
                .setIn(['form', 'formValues', 'pstg'], action.payload.pstg)
                .setIn(['form', 'formValues', 'other'], action.payload.other)
                .setIn(['form', 'formValues', 'total'], action.payload.total)
                .setIn(['form', 'formValues', 'notes'], action.payload.notes)
                .setIn(['form', 'formValues', 'approval_code'], action.payload.approval_code)
                .setIn(['form', 'formValues', 'convenience_fee'], action.payload.convenience_fee)
                .setIn(['form', 'formValues', 'ac_bank'], action.payload.ac_bank)
                .setIn(['form', 'formValues', 'pm_invoices'], JSON.parse(action.payload.pm_invoices))
                .setIn(['form', 'formValues', 'audit_history'], action.payload.audit_history);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.FETCH_DATA:
            state = state.setIn(['data', 'payors'], [])
                .setIn(['data', 'sales'], [])
                .setIn(['data', 'csrs'], [])
                .setIn(['data', 'mainList'], []);
            break;

        case actionTypes.FETCH_DATA_SUCCESS:
            state = state.setIn(['data', 'payors'], action.payload.payors)
                .setIn(['data', 'sales'], action.payload.sales)
                .setIn(['data', 'csrs'], action.payload.csrs)
                .setIn(['data', 'mainList'], action.payload.mainList);
            break;

        case actionTypes.FETCH_DATA_FAILURE:
            break;

        case actionTypes.SHOW_LIST:
            state = state.setIn(['show', 'list'], true)
                .setIn(['show', 'form'], false)
                .setIn(['show', 'email'], false);
            break;

        case actionTypes.SHOW_FORM:
            state = state.setIn(['show', 'list'], false)
                .setIn(['show', 'form'], true)
                .setIn(['show', 'email'], false);
            break;

        case actionTypes.SHOW_EMAIL:
            state = state.setIn(['show', 'list'], false)
                .setIn(['show', 'form'], false)
                .setIn(['show', 'email'], true);
            break;

        case actionTypes.ADD_PM:
            state = state.updateIn(['form', 'formValues', 'pm_invoices'], (pm_invoices) => pm_invoices.push(''));
            break;

        case actionTypes.SET_PM:
            state = state.updateIn(['form', 'formValues', 'pm_invoices'], (pm_invoices) => pm_invoices.set(action.payload.index, action.payload.value));
            break;

        case actionTypes.SEND_EMAIL:
            state = state.setIn(['form', 'isSendingEmail'], true);
            break;

        case actionTypes.SEND_EMAIL_SUCCESS:
            state = state.setIn(['form', 'isSendingEmail'], false)
                .setIn(['form', 'formValues', 'email_csr'], 0)
                .setIn(['form', 'formValues', 'email_sales'], 0)
                .setIn(['form', 'formValues', 'email_subject'], '')
                .setIn(['form', 'formValues', 'email_notes'], '')
                .setIn(['form', 'formValues', 'emails'], []);
            break;

        case actionTypes.SEND_EMAIL_FAILURE:
            state = state.setIn(['form', 'isSendingEmail'], false);
            break;

        case actionTypes.SET_EMAIL:
            state = state.updateIn(['form', 'formValues', 'emails'], (emails) => {
                if (action.payload.value) {
                    return emails.push(action.payload.email);
                } else {
                    const index = emails.indexOf(action.payload.email);
                    return emails.delete(index);
                }
            });
            break;

        case actionTypes.RESET_FILTER:
            state = state.setIn(['filter', 'fields'], []);
            break;

        default:
            break;
    }

    return state.toJS();
}
