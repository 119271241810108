import { createSelector } from 'reselect';

export const getComponentState = state => state.login;

export const getToken = createSelector(
    getComponentState,
    (state) => state.token
);

export const getIsLogging = createSelector(
    getComponentState,
    (state) => state.isLogging
);

export const getForm = createSelector(
    getComponentState,
    (state) => state.form
);

export const getRedirect = createSelector(
    getComponentState,
    (state) => state.redirect
);

export const getIsAuthenticated = createSelector(
    getComponentState,
    (state) => state.isAuthenticated
);

export const getRedirectToLogin = createSelector(
    getComponentState,
    (state) => state.redirectToLogin
);

export const getUser = createSelector(
    getComponentState,
    (state) => state.user
);
