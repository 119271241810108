import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AdminHeader extends Component
{
    static propTypes = {
        title: PropTypes.string
    };

    render() {
        const { title } = this.props;
        return (
            <div id="admin-header">
                <div className="row">
                    <div className="col">
                        <div id="section-title">{title}</div>
                    </div>
                    <div className="col">
                        <div className="powered">Powered By <b style={{color: '#66bc45'}}>PrintQuoteNow</b></div>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
        );
    }
}

export default AdminHeader;
