import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import moment from 'moment';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            date: moment().format('MM/DD/YYYY'),
            method: '',
            todo: 0,
            topic: '',
            present: [],
            staff: '',
            notes: '',
            completed_by: moment().format('MM/DD/YYYY'),
            completed: 0
        },
        formErrors: {},
        isSaving: false,
        isSendingEmail: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'date',
        type: 'desc'
    },
    filter: {
        fields: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                date: moment().format('MM/DD/YYYY'),
                method: '',
                todo: 0,
                topic: '',
                present: [],
                staff: '',
                notes: '',
                completed_by: moment().format('MM/DD/YYYY'),
                completed: 0
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'date'], moment(action.payload.date).format('MM/DD/YYYY'))
                .setIn(['form', 'formValues', 'method'], action.payload.method)
                .setIn(['form', 'formValues', 'todo'], action.payload.todo)
                .setIn(['form', 'formValues', 'topic'], action.payload.topic)
                .setIn(['form', 'formValues', 'present'], action.payload.present.split(', '))
                .setIn(['form', 'formValues', 'staff'], action.payload.staff)
                .setIn(['form', 'formValues', 'notes'], action.payload.notes)
                .setIn(['form', 'formValues', 'completed_by'], action.payload.completed_by)
                .setIn(['form', 'formValues', 'completed'], action.payload.completed);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.RESET_FILTER:
            state = state.setIn(['filter', 'fields'], []);
            break;

        case actionTypes.CHECK_PRESENT:
            state = state.updateIn(['form', 'formValues', 'present'], (present) => {
                if (action.payload.checked) {
                    return present.push(action.payload.label);
                } else {
                    const index = present.findIndex((element) => element === action.payload.label);
                    return present.delete(index);
                }
            });
            break;

        case actionTypes.CHECK_METHOD:
            state = state.updateIn(['form', 'formValues', 'method'], (method) => {
                if (action.payload.checked) {
                    return action.payload.label;
                } else {
                    return '';
                }
            });
            break;

        case actionTypes.SET_OTHER_METHOD:
            state = state.setIn(['form', 'formValues', 'method'], action.payload);
            break;

        case actionTypes.FETCH_ELEMENT:
            state = state.setIn(['activeElement', 'isFetching'], true);
            break;

        case actionTypes.FETCH_ELEMENT_SUCCESS:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT_FAILURE:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.SEND_EMAIL:
            state = state.setIn(['form', 'isSendingEmail'], true);
            break;

        case actionTypes.SEND_EMAIL_SUCCESS:
            state = state.setIn(['form', 'isSendingEmail'], false);
            break;

        case actionTypes.SEND_EMAIL_FAILURE:
            state = state.setIn(['form', 'isSendingEmail'], false);
            break;

        default:
            break;
    }

    return state.toJS();
}
