import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import {
    Table, Form, Delete, FlatConfiguration, DigitalConfiguration,
    LargeFormatConfiguration, MultiPageConfiguration
} from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        activeElement: PropTypes.object,
        showConfigurationOptions: PropTypes.func,
        hideConfigurationOptions: PropTypes.func,
        setConfiguration: PropTypes.func,
        saveConfiguration: PropTypes.func,
        configuration: PropTypes.array,
        isSavingConfiguration: PropTypes.bool,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        processElements: PropTypes.func
    };

    componentDidMount() {
        const { fetchElements, fetchProductCategories } = this.props;
        fetchElements();
        fetchProductCategories();
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { updateFormValue, setFormError, setInitialConfiguration } = this.props;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        updateFormValue(event.target.name, value);

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        if (event.target.name === 'product_type') {
            setInitialConfiguration(value);
        }

        setFormError(event.target.name, error, errorMessage);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, processElements } = this.props;
        if (sortColumn === column) {
            setSort(column, sortType === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(column, 'asc');
        }
        processElements();
    }

    handleSaveElement() {
        const { saveElement, formValues, elements } = this.props;
        saveElement(formValues, elements);
    }

    handleShowForm(id) {
        const { showForm, resetForm, setActiveElement, elements } = this.props;

        if (id == null) {
            resetForm(id);
        } else {
            setActiveElement(elements, id);
        }

        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete, elements } = this.props;
        deleteElement(idForDelete, elements);
    }

    handleShowConfiguration(id) {
        const { setEditing, setActiveElement, elements, fetchElement, fetchInformation } = this.props;

        let element = null;
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === id) {
                element = elements[i];
                break;
            }
        }

        fetchInformation(element['product_type']);
        setActiveElement(elements, id);
        fetchElement(id);
        setEditing(true);
    }

    handleChangeFilter(name, event) {
        const { changeFilter, processElements } = this.props;
        changeFilter(name, event.target.value);
        processElements();
    }

    handleUpdateConfiguration(attributes, value) {
        const { updateConfiguration, intersectPaperCategories, intersectPaperWeights, intersectPapers, formValues } = this.props;
        updateConfiguration(attributes, value);

        if (attributes[5] === 'show' || attributes[5] === 'lock') {
            return;
        }

        if (parseInt(formValues['product_type']) === 2) {   // Multi-page
            if (attributes[4] === 'paper_category_id') {
                setTimeout(() => {
                    updateConfiguration(['paper_options', 'paper_weight', 'default'], '');
                    updateConfiguration(['paper_options', 'paper_id', 'default'], '');
                    intersectPaperWeights();
                }, 50);
            } else if (attributes[4] === 'paper_weight') {
                setTimeout(() => {
                    updateConfiguration(['paper_options', 'paper_id', 'default'], '');
                    intersectPapers();
                }, 50);
            } else if (attributes[4] === 'press_id' || attributes[4] === 'second_press_id') {
                setTimeout(() => {
                    updateConfiguration(['paper_options', 'paper_category_id', 'default'], '');
                    updateConfiguration(['paper_options', 'paper_weight', 'default'], '');
                    updateConfiguration(['paper_options', 'paper_id', 'default'], '');
                    intersectPaperCategories()
                }, 50);
            } else if (attributes[4] === 'cover_paper_category_id') {
                setTimeout(() => {
                    updateConfiguration(['paper_options', 'cover_paper_weight', 'default'], '');
                    updateConfiguration(['paper_options', 'cover_paper_id', 'default'], '');
                    intersectPaperWeights(true);
                }, 50);
            } else if (attributes[4] === 'cover_paper_weight') {
                setTimeout(() => {
                    updateConfiguration(['paper_options', 'cover_paper_id', 'default'], '');
                    intersectPapers(true);
                }, 50);
            } else if (attributes[4] === 'cover_press_id') {
                setTimeout(() => {
                    updateConfiguration(['paper_options', 'cover_paper_category_id', 'default'], '');
                    updateConfiguration(['paper_options', 'cover_paper_weight', 'default'], '');
                    updateConfiguration(['paper_options', 'cover_paper_id', 'default'], '');
                    intersectPaperCategories(true)
                }, 50);
            }
        } else if (parseInt(formValues['product_type']) === 1 || parseInt(formValues['product_type']) === 3) {     // Flat or Digital
            if (attributes[4] === 'paper_category_id') {
                setTimeout(() => {
                    updateConfiguration(['product_options', 'paper_weight', 'default'], '');
                    updateConfiguration(['product_options', 'paper_id', 'default'], '');
                    intersectPaperWeights();
                }, 50);
            } else if (attributes[4] === 'paper_weight') {
                setTimeout(() => {
                    updateConfiguration(['product_options', 'paper_id', 'default'], '');
                    intersectPapers();
                }, 50);
            } else if (attributes[4] === 'press_id' || attributes[4] === 'digital_machine_id') {
                setTimeout(() => {
                    updateConfiguration(['product_options', 'paper_category_id', 'default'], '');
                    updateConfiguration(['product_options', 'paper_weight', 'default'], '');
                    updateConfiguration(['product_options', 'paper_id', 'default'], '');
                    intersectPaperCategories()
                }, 50);
            }
        }
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, hideForm, isDeleting, hideDelete, formErrorMessages,
            configuration, processedElements, editing, activeElement, information, setEditing, options, configurationJson,
            checkAccessory, productCategories } = this.props;

        let activeConfiguration = null;
        if (editing && activeElement !== null) {
            if (parseInt(activeElement['product_type']) === 1) {    // Flat
                activeConfiguration = (
                    <FlatConfiguration
                        activeElement={activeElement}
                        configuration={configuration}
                        isSavingForm={isSavingForm}
                        editing={editing}
                        options={options}
                        configurationJson={configurationJson}
                        information={information}
                        handleUpdateConfiguration={this.handleUpdateConfiguration.bind(this)}
                        setEditing={setEditing.bind(this)}
                        handleSaveElement={this.handleSaveElement.bind(this)}
                    />
                );
            } else if (parseInt(activeElement['product_type']) === 2) {    // Multi-page
                activeConfiguration = (
                    <MultiPageConfiguration
                        activeElement={activeElement}
                        configuration={configuration}
                        isSavingForm={isSavingForm}
                        editing={editing}
                        options={options}
                        configurationJson={configurationJson}
                        information={information}
                        handleUpdateConfiguration={this.handleUpdateConfiguration.bind(this)}
                        setEditing={setEditing.bind(this)}
                        handleSaveElement={this.handleSaveElement.bind(this)}
                    />
                );
            } else if (parseInt(activeElement['product_type']) === 3) {    // Digital
                activeConfiguration = (
                    <DigitalConfiguration
                        activeElement={activeElement}
                        configuration={configuration}
                        isSavingForm={isSavingForm}
                        editing={editing}
                        options={options}
                        configurationJson={configurationJson}
                        information={information}
                        handleUpdateConfiguration={this.handleUpdateConfiguration.bind(this)}
                        setEditing={setEditing.bind(this)}
                        handleSaveElement={this.handleSaveElement.bind(this)}
                    />
                );
            } else if (parseInt(activeElement['product_type']) === 4) {    // Large Format
                activeConfiguration = (
                    <LargeFormatConfiguration
                        activeElement={activeElement}
                        configuration={configuration}
                        isSavingForm={isSavingForm}
                        editing={editing}
                        options={options}
                        configurationJson={configurationJson}
                        information={information}
                        handleUpdateConfiguration={this.handleUpdateConfiguration.bind(this)}
                        setEditing={setEditing.bind(this)}
                        checkAccessory={checkAccessory.bind(this)}
                        handleSaveElement={this.handleSaveElement.bind(this)}
                    />
                );
            }
        }

        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Products</title>
                        <meta name="description" content="Products" />
                    </Helmet>
                    <AdminHeader
                        title="Products"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            <div className="upload-button" onClick={this.handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add</div>
                            {!editing && <Table
                                elements={elements}
                                isFetchingElements={isFetchingElements}
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                elementsPerPage={elementsPerPage}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                user={user}
                                processedElements={processedElements}
                                handleSort={this.handleSort.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleShowConfiguration={this.handleShowConfiguration.bind(this)}
                                handleChangeFilter={this.handleChangeFilter.bind(this)}
                            />}
                            <Form
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                productCategories={productCategories}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={hideForm.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                            />
                            {activeConfiguration}
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    activeElement: selectors.getActiveElement,
    user: loginSelectors.getUser,
    editing: selectors.getEditing,
    options: selectors.getOptions,
    configurationJson: selectors.getConfigurationJson,
    productCategories: selectors.getProductCategories,
    information: selectors.getInformation
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: () => dispatch(actions.fetchElements()),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (elements, index) => dispatch(actions.deleteElement(elements, index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: (values, elements) => dispatch(actions.saveElement(values, elements)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        resetState: () => dispatch(actions.resetState()),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        processElements: () => dispatch(actions.processElements()),
        setEditing: (value) => dispatch(actions.setEditing(value)),
        updateConfiguration: (attributes, value) => dispatch(actions.updateConfiguration(attributes, value)),
        fetchInformation: (type) => dispatch(actions.fetchInformation(type)),
        intersectPaperCategories: (isCover = false) => dispatch(actions.intersectPaperCategories(isCover)),
        intersectPaperWeights: (isCover = false) => dispatch(actions.intersectPaperWeights(isCover)),
        intersectPapers: (isCover = false) => dispatch(actions.intersectPapers(isCover)),
        fetchElement: (id) => dispatch(actions.fetchElement(id)),
        setInitialConfiguration: (type) => dispatch(actions.setInitialConfiguration(type)),
        checkAccessory: (id) => dispatch(actions.checkAccessory(id)),
        fetchProductCategories: () => dispatch(actions.fetchProductCategories())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
