import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import moment from 'moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleDayChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleChangeFilter: PropTypes.func,
        processedElements: PropTypes.array
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { processedElements, isFetchingElements, handleShowForm, handleShowDelete, selectedPage, elementsPerPage } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (processedElements.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No Results</td>
                </tr>
            );
        }

        let data = [];
        let startIndex = (selectedPage - 1) * elementsPerPage;
        for (let i = startIndex; i < processedElements.length && i < startIndex + elementsPerPage; i++) {
            let productNames = [];
            for (let j = 0; j < processedElements[i]['products_names'].length; j++) {
                productNames.push(<li>{processedElements[i]['products_names'][j]}</li>);
            }

            let machineNames = [];
            for (let j = 0; j < processedElements[i]['machines_names'].length; j++) {
                machineNames.push(<li>{processedElements[i]['machines_names'][j]}</li>);
            }

            data.push(
                <tr key={i}>
                    <td>{processedElements[i].id}</td>
                    <td>{processedElements[i].name}</td>
                    <td>{processedElements[i].customer_name}</td>
                    <td>{processedElements[i].due_date == null ? 'N/A' : moment(processedElements[i].due_date).format('MM/DD/YYYY')}</td>
                    <td>{processedElements[i].total_jobs}</td>
                    <td>{productNames}</td>
                    <td>{machineNames}</td>
                    <td>{numeral(processedElements[i].run_length).format('0,0')}</td>
                    <td>{numeral(processedElements[i].total_time).format('0,0.[00]')} hrs</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, processedElements[i].id, null)} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, processedElements[i].id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { processedElements, totalPages, selectedPage, elementsPerPage } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm">
                        <thead>
                            <tr>
                                {this.renderTableHeader('ID', 'id', { width: '6%' }, false)}
                                {this.renderTableHeader('Title', 'name', { width: 'calc(20% - 80px)' })}
                                {this.renderTableHeader('Customer', 'customer_name', { width: '10%' })}
                                {this.renderTableHeader('Due Date', 'due_date', { width: '8%' })}
                                {this.renderTableHeader('Jobs', 'total_jobs', { width: '8%' })}
                                {this.renderTableHeader('Product', 'products_names ', { width: '18%' })}
                                {this.renderTableHeader('Machine', 'machine_names ', { width: '14%' })}
                                {this.renderTableHeader('Run Length', 'run_length', { width: '8%' })}
                                {this.renderTableHeader('Time', 'total_time', { width: '8%' })}
                                <th style={{ width: 80 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {this.renderSearchField('id')}
                                {this.renderSearchField('name')}
                                {this.renderSearchField('customer_name')}
                                {this.renderSearchField('due_date')}
                                {this.renderSearchField('total_jobs')}
                                {this.renderSearchField('products_names')}
                                {this.renderSearchField('machine_names')}
                                {this.renderSearchField('run_length')}
                                {this.renderSearchField('total_time')}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={processedElements.length}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
