import { SET_ACTIVE_PAGE, SET_ACTIVE_PAGE_MULTIPLE } from './actionTypes';

export function setActivePage(page) {
    return {
        type: SET_ACTIVE_PAGE,
        payload: page
    };
}

export function setActivePageMultiple(name, page) {
    return {
        type: SET_ACTIVE_PAGE_MULTIPLE,
        payload: {name, page}
    };
}
