import { createSelector } from 'reselect';

export const getComponentState = state => state.dptCalculator;

export const getData = createSelector(
    getComponentState,
    (state) => state.data
);
export const getIsFetchingData = createSelector(
    getComponentState,
    (state) => state.isFetchingData
);
export const getShow = createSelector(
    getComponentState,
    (state) => state.show
);
export const getSummary = createSelector(
    getComponentState,
    (state) => state.summary
);
export const getAccounting = createSelector(
    getComponentState,
    (state) => state.accounting
);