import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Dropzone from 'react-dropzone';

class UploadArtworks extends Component {
    static propTypes = {
        hideUpload: PropTypes.func,
        handleDropFile: PropTypes.func,
        handleUpload: PropTypes.func,
        isUploadingFile: PropTypes.bool,
        uploadingProgress: PropTypes.number,
        files: PropTypes.array
    };

    renderFiles() {
        const { files } = this.props;
        return files.map((file, index) => {
            return <li key={index}>{file['name']}</li>;
        });
    }

    render() {
        const { hideUpload, isUploadingFile, handleDropFile, uploadingProgress, files, handleUpload } = this.props;

        let saveButtonLabel = 'Upload File';
        if (isUploadingFile) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let selectedFiles = null;
        if (files.length > 0) {
            selectedFiles = (
                <div className="files-list">
                    Selected Files:
                    <ul>{this.renderFiles()}</ul>
                </div>
            );
        }

        let actions = [
            <button key="upload-button" className="btn btn-light btn-sm action-button-cancel" onClick={handleUpload}
                disabled={isUploadingFile}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideUpload}>Close</button>
        ];
        return (
            <Dialog
                name="upload-artworks"
                modal={false}
                actions={actions}
                contentStyle={{ width: 420 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">
                    <div className="row">
                        <div className="col">
                            <div style={{ marginBottom: 15 }}>Upload Artworks</div>
                            <Dropzone onDrop={handleDropFile} accept={'.pdf,.ai,.jpg,.png'}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <button className="btn btn-light btn-sm upload-excel" disabled={isUploadingFile}>Select File</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            <div className="clearfix"></div>
                            <div className="progress" hidden={!isUploadingFile}>
                                <div className="progress-bar" role="progressbar" style={{ width: uploadingProgress + '%' }}
                                    aria-valuenow={uploadingProgress} aria-valuemin="0" aria-valuemax="100">
                                    {uploadingProgress}%
                                </div>
                            </div>
                            {selectedFiles}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default UploadArtworks;
