import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';
import moment from 'moment';

export const fetchElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let fields = [
        'id',
        'type',
        'pm_invoices',
        'edited',
        'notes',
        'date',
        'check',
        'approval_code',
        'convenience_fee',
        'ac_bank',
        'payor_name',
        'csr_name',
        'sales_name',
        'has_postage',
        'amount',
        'acct_rec',
        'pstg',
        'other',
        'audit_history'
    ];

    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',')
    }

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.search + '?fields=' + fields.join(',') + '&selectedPage=' + selectedPage
        + '&sort=' + column + '&filter=' + filter + '&summary=1',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const saveElement = (values) => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());

    if (formValues['type'] === '' || formValues['type'] === null) {
        showNotification('Complete the Information', 'Enter the type', 'info');
        return;
    }
    if (formValues['date'] === '' || formValues['date'] === null) {
        showNotification('Complete the Information', 'Enter the date', 'info');
        return;
    }
    if (formValues['type'] === 3 && (formValues['check'] === '' || formValues['check'] === null)) {
        showNotification('Complete the Information', 'Enter the check', 'info');
        return;
    }
    if (formValues['payor_id'] === '' || formValues['payor_id'] === null) {
        showNotification('Complete the Information', 'Select the payor', 'info');
        return;
    }
    if (formValues['sales_id'] === '' || formValues['sales_id'] === null) {
        showNotification('Complete the Information', 'Select the sales', 'info');
        return;
    }
    if (formValues['amount'] === '' || formValues['amount'] === null) {
        showNotification('Complete the Information', 'Enter the amount', 'info');
        return;
    }
    if (formValues['acct_rec'] === '' || formValues['acct_rec'] === null) {
        showNotification('Complete the Information', 'Enter the acct_rec', 'info');
        return;
    }
    if (formValues['pstg'] === '' || formValues['pstg'] === null) {
        showNotification('Complete the Information', 'Enter the pstg', 'info');
        return;
    }
    if (formValues['other'] === '' || formValues['other'] === null) {
        showNotification('Complete the Information', 'Enter the other field', 'info');
        return;
    }
    if (formValues['type'] === 6 || formValues['type'] === 7) {
        if (formValues['approval_code'] === '' || formValues['approval_code'] === null) {
            showNotification('Complete the Information', 'Enter the Approval Code field', 'info');
            return;
        }
        if (formValues['convenience_fee'] === '' || formValues['convenience_fee'] === null) {
            showNotification('Complete the Information', 'Enter the Convenience Fee field', 'info');
            return;
        }
        if (formValues['ac_bank'] === '' || formValues['ac_bank'] === null) {
            showNotification('Complete the Information', 'Enter the AC or ACM Bank field', 'info');
            return;
        }
    }

    let endpoint = null;
    let method = null;
    if (formValues['id'] === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + formValues['id'];
        method = 'PUT';
    }

    formValues['date'] = moment(formValues['date']).format('YYYY-MM-DD');

    let invoices = [];
    for (let i = 0; i < formValues['pm_invoices'].length; i++) {
        if (formValues['pm_invoices'][i] !== '') {
            invoices.push(formValues['pm_invoices'][i]);
        }
    }
    formValues['pm_invoices'] = invoices;

    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: formValues,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            if (formValues['id'] === '') {
                dispatch(resetForm());
            } else {
                dispatch(setActiveElement(response.data));
            }
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export const deleteElement = (id) => (dispatch, getState) => {
    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(element) {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export const fetchData = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_DATA, payload: null });
    axios.get(apiUrls.data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_DATA_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_DATA_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const addPayor = (values) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ADD_PAYOR, payload: null });
    axios.post(apiUrls.payors, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ADD_PAYOR_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('add-payor'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(fetchData());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.ADD_PAYOR_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const sendEmail = () => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());

    if (formValues['email_subject'] === '' || formValues['email_subject'] === null) {
        showNotification('Complete the Information', 'Enter the subject', 'info');
        return;
    }

    if (formValues['email_notes'] === '' || formValues['email_notes'] === null) {
        showNotification('Complete the Information', 'Enter the notes', 'info');
        return;
    }

    let filterNames = [];
    let filterValues = [];
    for (let i = 0; i < filterFields.length; i++) {
        filterNames.push(filterFields[i]['name']);
        filterValues.push(filterFields[i]['value']);
    }

    dispatch({ type: actionTypes.SEND_EMAIL, payload: null });
    axios.post(apiUrls.sendEmail,
        {
            email_csr: formValues['email_csr'],
            email_sales: formValues['email_sales'],
            subject: formValues['email_subject'],
            notes: formValues['email_notes'],
            emails: formValues['emails'],
            'sort-column': sortColumn,
            'sort-type': sortType,
            'filter-names': filterNames.join(','),
            'filter-values': filterValues.join(',')
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SEND_EMAIL_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('send-email'));
            showNotification('Element Sent!', 'The email has been sent successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SEND_EMAIL_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function exportExcel() {
    return (dispatch, getState) => {
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());
        const filterFields = selectors.getFilterFields(getState());

        let filterNames = [];
        let filterValues = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterNames.push(filterFields[i]['name']);
            filterValues.push(filterFields[i]['value']);
        }

        window.location.href = apiUrls.export + '?sort-column=' + sortColumn + '&sort-type='
            + sortType + '&filter-names=' + filterNames.join(',') + '&filter-values='
            + filterValues.join(',');
    };
}

export function showList(name, value) {
    return {
        type: actionTypes.SHOW_LIST,
        payload: null
    };
}

export function showForm(name, value) {
    return {
        type: actionTypes.SHOW_FORM,
        payload: null
    };
}

export function showEmail(name, value) {
    return {
        type: actionTypes.SHOW_EMAIL,
        payload: null
    };
}

export function addPm() {
    return {
        type: actionTypes.ADD_PM,
        payload: null
    };
}

export function setPm(index, value) {
    return {
        type: actionTypes.SET_PM,
        payload: { index, value }
    };
}

export function setEmail(email, value) {
    return {
        type: actionTypes.SET_EMAIL,
        payload: { email, value }
    };
}

export function setActivePage(page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: page
    };
}

export function resetFilter() {
    return {
        type: actionTypes.RESET_FILTER,
        payload: null
    };
}

export const fetchElement = (id) => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement(response.data));
            dispatch(showForm());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}
