import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table, Form, Delete, SpeedTable, DifficultyTable, OverageTable } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import numeral from 'numeral';
import { Helmet } from 'react-helmet';
import cloneDeep from 'lodash/cloneDeep';
import { showNotification } from './../../utils';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        changeAttribute: PropTypes.func,
        updateAttribute: PropTypes.func,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        showSpeedTable: PropTypes.func,
        activeElement: PropTypes.func,
        hideSpeedTable: PropTypes.func,
        setSpeedTable: PropTypes.func,
        setDifficultyTable: PropTypes.func,
        saveSpeedTable: PropTypes.func,
        speedTable: PropTypes.array,
        isSavingSpeedTable: PropTypes.bool,
        difficultyTable: PropTypes.array,
        isSavingDifficultyTable: PropTypes.bool,
        hideMakeReadyMarkups: PropTypes.func,
        fetchInformation: PropTypes.func,
        hideDifficultyTable: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        showDifficultyTable: PropTypes.func,
        saveDifficultyTable: PropTypes.func,
        concatActiveData: PropTypes.func,
        deleteActiveData: PropTypes.func,
        clearMultipleValue: PropTypes.func,
        concatMultipleValue: PropTypes.func,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        options: PropTypes.object,
        fetching: PropTypes.object,
        resetOptions: PropTypes.func,
        updateState: PropTypes.func,
        information: PropTypes.object
    };

    componentDidMount() {
        const { fetchElements, sortColumn, sortType, filterFields, fetchInformation } = this.props;
        fetchInformation();
        fetchElements(1, sortColumn, sortType, filterFields);
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { setFormError, formValues, updateState } = this.props;

        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let values = {};
        values[event.target.name] = value;
        let newValues = cloneDeep(formValues);
        newValues[event.target.name] = value;

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        let fetching = {};
        let options = {};

        let newState = { formValues: values };

        let keys = Object.keys(fetching);
        if (keys.length > 0) {
            newState.fetching = fetching;
        }

        keys = Object.keys(options);
        if (keys.length > 0) {
            newState.options = options;
        }

        updateState(newState);
        setFormError(event.target.name, error, errorMessage);
    }

    handleMultipleChange(name, event) {
        const { clearMultipleValue, concatMultipleValue } = this.props;
        clearMultipleValue(name);

        let values = [];
        for (let i = 0; i < event.target.options.length; i++) {
            if (event.target.options[i].selected) {
                values.push(parseInt(event.target.options[i].value));
            }
        }

        concatMultipleValue(name, values);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, fetchElements, filterFields } = this.props;
        let type = 'asc';
        if (sortColumn === column) {
            type = sortType === 'asc' ? 'desc' : 'asc';
            setSort(column, type);
        } else {
            setSort(column, 'asc');
        }
        fetchElements(1, column, type, filterFields);
    }

    handleInlineChange(index, attribute, value) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(value, elements, index, attribute);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleSaveElement() {
        const { saveElement, formValues, elements } = this.props;
        saveElement(formValues, elements);
    }

    handleShowForm(id) {
        const { showForm, resetForm, fetchElement } = this.props;

        if (id === null) {
            resetForm(id);
        } else {
            fetchElement(id);
        }

        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete, elements } = this.props;
        deleteElement(idForDelete, elements);
    }

    handleChangeAttribute(index, attribute, type, value) {
        const { processedElements, changeAttribute, updateAttribute } = this.props;

        let valueToSave = value;
        if (type === 'money') {
            valueToSave = numeral(value).format('0.00');
            value = numeral(value).format('$0,0.00');
        } else if (type === 'number') {
            if (parseInt(value).toString().match(/^[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        } else if (type === 'date') {
            valueToSave = moment(value).format('YYYY-MM-DD');
            value = valueToSave;
        } else if (type === 'decimal') {
            if (parseInt(value).toString().match(/^([0-9]*[.])?[0-9]+$/) == null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        }

        changeAttribute(value, processedElements, index, attribute);
        updateAttribute(processedElements[index].id, attribute, valueToSave, processedElements, index);
    }

    handleShowSpeedTable(id) {
        const { showSpeedTable, fetchElement } = this.props;
        fetchElement(id);
        showSpeedTable();
    }

    handleShowDifficultyTable(id) {
        const { showDifficultyTable, fetchElement } = this.props;
        fetchElement(id);
        showDifficultyTable();
    }

    handleShowOverageTable(id) {
        const { showOverageTable, fetchElement } = this.props;
        fetchElement(id);
        showOverageTable();
    }

    handleAddSpeedTable() {
        let { setSpeedTable, speedTable } = this.props;
        speedTable.push({ id: '', run_length: '', speed: '' });
        setSpeedTable(speedTable);
    }

    handleUpdateSpeedTable(index, attribute, event) {
        let { setSpeedTable, speedTable } = this.props;
        speedTable[index][attribute] = event.target.value;
        setSpeedTable(speedTable);
    }

    handleDeleteSpeedTable(index) {
        let { setSpeedTable, speedTable } = this.props;
        speedTable.splice(index, 1);
        setSpeedTable(speedTable);
    }

    handleAddDifficultyTable() {
        let { setDifficultyTable, difficultyTable } = this.props;
        difficultyTable.push({ id: '', envelope_type_id: '', markup: '' });
        setDifficultyTable(difficultyTable);
    }

    handleUpdateDifficultyTable(index, attribute, event) {
        let { setDifficultyTable, difficultyTable } = this.props;
        difficultyTable[index][attribute] = event.target.value;
        setDifficultyTable(difficultyTable);
    }

    handleDeleteDifficultyTable(index) {
        let { setDifficultyTable, difficultyTable } = this.props;
        difficultyTable.splice(index, 1);
        setDifficultyTable(difficultyTable);
    }

    handleAddOverageTable() {
        let { setOverageTable, overageTable } = this.props;
        overageTable.push({ id: '', start_quantity: '', finish_quantity: '', overage: '' });
        setOverageTable(overageTable);
    }

    handleUpdateOverageTable(index, attribute, event) {
        let { setOverageTable, overageTable } = this.props;
        overageTable[index][attribute] = event.target.value;
        setOverageTable(overageTable);
    }

    handleDeleteOverageTable(index) {
        let { setOverageTable, overageTable } = this.props;
        overageTable.splice(index, 1);
        setOverageTable(overageTable);
    }

    handleSaveSpeedTable() {
        const { saveSpeedTable, speedTable, activeElement, elements } = this.props;
        saveSpeedTable(speedTable, elements, activeElement['id']);
    }

    handleSaveOverageTable() {
        const { saveOverageTable, overageTable, activeElement, elements } = this.props;
        saveOverageTable(overageTable, elements, activeElement['id']);
    }

    handleSaveDifficultyTable() {
        const { saveDifficultyTable, difficultyTable, activeElement, elements } = this.props;
        saveDifficultyTable(difficultyTable, elements, activeElement['id']);
    }

    handleChangeFilter(name, event) {
        const { changeFilter, fetchElements, sortColumn, sortType, filterFields } = this.props;

        let value = null;
        let filter = filterFields;
        if (name === 'date') {
            value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else {
            value = event.target.value;
            changeFilter(name, value);
        }

        let fetch = false;
        const index = filter.findIndex(element => element['name'] === name);
        if (index === -1 && value !== '') {
            filter.push({ name: name, value: value });
            fetch = true;
        } else if (index !== -1 && value === '') {
            filter.splice(index, 1);
            fetch = true;
        } else if (index !== -1 && value !== '' && filter[index]['value'] !== value) {
            filter[index]['value'] = value;
            fetch = true;
        }

        if (fetch) {
            fetchElements(1, sortColumn, sortType, filter);
        }
    }

    handleSetActivePage(page) {
        const { fetchElements, setActivePage, sortColumn, sortType, filterFields } = this.props;
        setActivePage(page);
        fetchElements(page, sortColumn, sortType, filterFields);
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, hideForm, isDeleting, hideDelete, formErrorMessages,
            speedTable, hideSpeedTable, isSavingSpeedTable, isSavingDifficultyTable, overageTable, hideOverageTable, isSavingOverageTable,
            difficultyTable, activeElement, hideDifficultyTable, processedElements, information, options, fetching } = this.props;
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Dashboard</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Envelope Presses"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            <div className="upload-button" onClick={this.handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add</div>
                            <Table
                                elements={elements}
                                isFetchingElements={isFetchingElements}
                                totalItems={this.props.totalItems}
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                elementsPerPage={elementsPerPage}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                user={user}
                                processedElements={processedElements}
                                handleSort={this.handleSort.bind(this)}
                                handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                handleInlineChange={this.handleInlineChange.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleDayChange={this.handleDayChange.bind(this)}
                                handleShowSpeedTable={this.handleShowSpeedTable.bind(this)}
                                handleShowDifficultyTable={this.handleShowDifficultyTable.bind(this)}
                                handleShowOverageTable={this.handleShowOverageTable.bind(this)}
                                handleChangeFilter={this.handleChangeFilter.bind(this)}
                                handleSetActivePage={this.handleSetActivePage.bind(this)}
                            />
                            <Form
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                options={options}
                                information={information}
                                fetching={fetching}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={hideForm.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                            />
                            <SpeedTable
                                speedTable={speedTable}
                                isSavingSpeedTable={isSavingSpeedTable}
                                isFetchingElement={isFetchingElement}
                                hideSpeedTable={hideSpeedTable.bind(this)}
                                handleAddSpeedTable={this.handleAddSpeedTable.bind(this)}
                                handleUpdateSpeedTable={this.handleUpdateSpeedTable.bind(this)}
                                handleDeleteSpeedTable={this.handleDeleteSpeedTable.bind(this)}
                                handleSaveSpeedTable={this.handleSaveSpeedTable.bind(this)}
                            />
                            <DifficultyTable
                                activeElement={activeElement}
                                difficultyTable={difficultyTable}
                                isSavingDifficultyTable={isSavingDifficultyTable}
                                isFetchingElement={isFetchingElement}
                                information={information}
                                hideDifficultyTable={hideDifficultyTable.bind(this)}
                                handleUpdateDifficultyTable={this.handleUpdateDifficultyTable.bind(this)}
                                handleSaveDifficultyTable={this.handleSaveDifficultyTable.bind(this)}
                                handleAddDifficultyTable={this.handleAddDifficultyTable.bind(this)}
                                handleDeleteDifficultyTable={this.handleDeleteDifficultyTable.bind(this)}
                            />
                            <OverageTable
                                overageTable={overageTable}
                                isSavingOverageTable={isSavingOverageTable}
                                isFetchingElement={isFetchingElement}
                                hideOverageTable={hideOverageTable.bind(this)}
                                handleAddOverageTable={this.handleAddOverageTable.bind(this)}
                                handleUpdateOverageTable={this.handleUpdateOverageTable.bind(this)}
                                handleDeleteOverageTable={this.handleDeleteOverageTable.bind(this)}
                                handleSaveOverageTable={this.handleSaveOverageTable.bind(this)}
                            />
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isFetchingElement: selectors.getIsFetchingElement,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalItems: selectors.getTotalItems,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    activeElement: selectors.getActiveElement,
    speedTable: selectors.getSpeedTable,
    isSavingSpeedTable: selectors.getIsSavingSpeedTable,
    overageTable: selectors.getOverageTable,
    isSavingOverageTable: selectors.getIsSavingOverageTable,
    difficultyTable: selectors.getDifficultyTable,
    isSavingDifficultyTable: selectors.getIsSavingDifficultyTable,
    isFetchingInformation: selectors.getIsFetchingInformation,
    information: selectors.getInformation,
    options: selectors.getOptions,
    fetching: selectors.getFetching,
    filterFields: selectors.getFilterFields,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: (selectedPage, sortColumn, sortType, filterFields) => dispatch(actions.fetchElements(selectedPage, sortColumn, sortType, filterFields)),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (elements, index) => dispatch(actions.deleteElement(elements, index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        showSpeedTable: () => dispatch(dialog.actions.showDialog('speed-table')),
        hideSpeedTable: () => dispatch(dialog.actions.hideDialog('speed-table')),
        showDifficultyTable: () => dispatch(dialog.actions.showDialog('difficulty-table')),
        hideDifficultyTable: () => dispatch(dialog.actions.hideDialog('difficulty-table')),
        showOverageTable: () => dispatch(dialog.actions.showDialog('overage-table')),
        hideOverageTable: () => dispatch(dialog.actions.hideDialog('overage-table')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: (values, elements) => dispatch(actions.saveElement(values, elements)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        changeAttribute: (value, elements, index, attribute) => dispatch(actions.changeAttribute(value, elements, index, attribute)),
        updateAttribute: (id, attribute, value, elements, index) => dispatch(actions.updateAttribute(id, attribute, value, elements, index)),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        setSpeedTable: (speedTable) => dispatch(actions.setSpeedTable(speedTable)),
        saveSpeedTable: (speedTable, elements, pressId) => dispatch(actions.saveSpeedTable(speedTable, elements, pressId)),
        setOverageTable: (overageTable) => dispatch(actions.setOverageTable(overageTable)),
        saveOverageTable: (overageTable, elements, pressId) => dispatch(actions.saveOverageTable(overageTable, elements, pressId)),
        saveDifficultyTable: (difficultyTable, elements, pressId) => dispatch(actions.saveDifficultyTable(difficultyTable, elements, pressId)),
        fetchInformation: () => dispatch(actions.fetchInformation()),
        setDifficultyTable: (difficultyTable) => dispatch(actions.setDifficultyTable(difficultyTable)),
        addActiveData: (data, dataName) => dispatch(actions.addActiveData(data, dataName)),
        deleteActiveData: (index, dataName) => dispatch(actions.deleteActiveData(index, dataName)),
        concatActiveData: (name, data) => dispatch(actions.concatActiveData(name, data)),
        addMultipleValue: (name, value) => dispatch(actions.addMultipleValue(name, value)),
        clearMultipleValue: (name) => dispatch(actions.clearMultipleValue(name)),
        concatMultipleValue: (name, data) => dispatch(actions.concatMultipleValue(name, data)),
        resetState: () => dispatch(actions.resetState()),
        setLoading: (name, value) => dispatch(actions.setLoading(name, value)),
        resetOptions: (name) => dispatch(actions.resetOptions(name)),
        updateState: (values) => dispatch(actions.updateState(values)),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        fetchElement: (id) => dispatch(actions.fetchElement(id)),
        setActivePage: (page) => dispatch(actions.setActivePage(page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
