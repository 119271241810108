import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import moment from 'moment';

class Table extends Component {
    static propTypes = {

    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { processedElements, isFetchingElements, handleShowForm, handleShowDelete, selectedPage, elementsPerPage } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="7"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (processedElements.length === 0) {
            return (
                <tr>
                    <td colSpan="7">No Results</td>
                </tr>
            );
        }

        const downloadPdf = (url) => {
            window.open(url);
        };

        let data = [];
        let startIndex = (selectedPage - 1) * elementsPerPage;
        for (let i = startIndex; i < processedElements.length && i < startIndex + elementsPerPage; i++) {
            let status = '';
            if (parseInt(processedElements[i]['status']) === 1) {
                status = 'Pending';
            } else if (parseInt(processedElements[i]['status']) === 2) {
                status = 'On Production';
            } else if (parseInt(processedElements[i]['status']) === 3) {
                status = 'Finished';
            } else if (parseInt(processedElements[i]['status']) === 4) {
                status = 'Delivered';
            }

            let cardFor = null;
            if (processedElements[i]['variables'] && processedElements[i]['variables'] !== '') {
                let json = JSON.parse(processedElements[i]['variables']);
                cardFor = json.name;
            }

            data.push(
                <tr key={i}>
                    <td>{processedElements[i]['id']}</td>
                    <td>{numeral(processedElements[i]['quantity']).format('0,0')}</td>
                    <td>{cardFor}</td>
                    <td>{processedElements[i]['code']}</td>
                    <td>{moment(processedElements[i]['created_at']).format('MM/DD/YYYY')}</td>
                    <td>{status}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Order Information" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-info-circle" onClick={handleShowForm.bind(this, processedElements[i]['id'], null)} />
                            </Tooltip>
                            <Tooltip title="Download High Res File" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="far fa-file-pdf" onClick={downloadPdf.bind(this, processedElements[i]['pdf_url'])} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, processedElements[i]['id'])} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { processedElements, totalPages, selectedPage, elementsPerPage } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('Order #', 'id')}
                                {this.renderTableHeader('Quantity', 'quantity')}
                                {this.renderTableHeader('Card For', 'variables')}
                                {this.renderTableHeader('Code ', 'code')}
                                {this.renderTableHeader('Date', 'created_at')}
                                {this.renderTableHeader('Status', 'status')}
                                <th style={{ width: 110 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {this.renderSearchField('id')}
                                {this.renderSearchField('quantity')}
                                {this.renderSearchField('variables')}
                                {this.renderSearchField('created_at')}
                                {this.renderSearchField('code')}
                                {this.renderSearchSelect('status', [
                                    { label: '', value: '' },
                                    { label: 'Pending', value: 1 },
                                    { label: 'On Production', value: 2 },
                                    { label: 'Finished', value: 3 },
                                    { label: 'Delivered', value: 4 },
                                ])}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={processedElements.length}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
