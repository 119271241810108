import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification, validateAuthInResponse } from './../../utils';
import axios from 'axios';

export const fetchElements = (selectedPage, sortColumn, sortType, filterFields) => dispatch => {
    let fields = [
        'id',
        'name',
        'max_paper_width',
        'max_paper_length',
        'min_paper_width',
        'min_paper_length',
        'labor_rate',
        'labor_markup_rate',
        'setup_cost',
        'ink_units',
        'wash_up_cost',
        'gripper_margin',
        'left_right_tail_trim',
        'plate_change_cost',
        'plate_cost',
        'is_active',
        'is_perfecting',
        'is_default',
        'color_bar_margin',
        'paper_category_id',
        'paper_weight',
        'setup_time',
        'running_spoilage_flat',
        'makeready_spoilage_flat',
        'running_spoilage_wt',
        'makeready_spoilage_wt',
        'running_spoilage_sw',
        'makeready_spoilage_sw',
    ];

    let column = sortColumn;
    if (sortType === 'desc') {
        column = '-' + sortColumn;
    }

    let filter = '-';
    if (filterFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterElements.push(filterFields[i]['name'] + '=' + filterFields[i]['value']);
        }
        filter = filterElements.join(',');
    }

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.search + '?fields=' + fields.join(',') + '&selectedPage=' + selectedPage
        + '&sort=' + column + '&filter=' + filter + '&summary=1',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENTS_FAILURE));
        });
}

export const saveElement = (values, elements) => (dispatch, getState) => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name', 'max_paper_width', 'max_paper_length', 'min_paper_width', 'min_paper_length',
        'labor_rate', 'labor_markup_rate', 'setup_cost', 'ink_units', 'gripper_margin',
        'left_right_tail_trim', 'plate_change_cost', 'plate_cost', 'is_active', 'is_perfecting',
        'is_default', 'color_bar_margin', 'setup_time', 'running_spoilage_flat', 'makeready_spoilage_flat',
        'running_spoilage_wt', 'makeready_spoilage_wt', 'running_spoilage_sw', 'makeready_spoilage_sw'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_ELEMENT_FAILURE));
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        const selectedPage = selectors.getSelectedPage(getState());
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());
        const filterFields = selectors.getFilterFields(getState());

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
                dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            })
            .catch(error => {
                dispatch(validateAuthInResponse(error, actionTypes.SAVE_ELEMENT_FAILURE));
            });
    };
}

export const deleteElement = (id, elements) => (dispatch, getState) => {
    const selectedPage = selectors.getSelectedPage(getState());
    const sortColumn = selectors.getSortColumn(getState());
    const sortType = selectors.getSortType(getState());
    const filterFields = selectors.getFilterFields(getState());
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            dispatch(fetchElements(selectedPage, sortColumn, sortType, filterFields));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.DELETE_ELEMENT_FAILURE));
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(element) {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function setSpeedTable(speedTable) {
    return {
        type: actionTypes.SET_SPEED_TABLE,
        payload: speedTable
    };
}

export function setMakeReadyMarkups(makeReadyMarkups) {
    return {
        type: actionTypes.SET_MAKE_READY_MARKUPS,
        payload: makeReadyMarkups
    };
}

export function setBhrMarkups(bhrMarkups) {
    return {
        type: actionTypes.SET_BHR_MARKUPS,
        payload: bhrMarkups
    };
}

export const saveSpeedTable = (speedTable, elements, pressId) => dispatch => {
    for (let i = 0; i < speedTable.length; i++) {
        if (speedTable[i]['run_length'] === '' || speedTable[i]['speed'] === '') {
            showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_SPEED_TABLE, payload: null });
    axios.post(apiUrls.saveSpeedTable, { pressId, speedTable },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_SPEED_TABLE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('speed-table'));
            showNotification('Speed Table Saved!', 'The speed table has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_SPEED_TABLE_FAILURE));
        });
}

export const saveMakeReadyMarkups = (makeReadyMarkups, elements, pressId) => dispatch => {
    for (let i = 0; i < makeReadyMarkups.length; i++) {
        if (makeReadyMarkups[i]['running_style'] === '' || makeReadyMarkups[i]['markup'] === '') {
            showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_MAKE_READY_MARKUPS, payload: null });
    axios.post(apiUrls.saveMakeReadyMarkups, { pressId, makeReadyMarkups }, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_MAKE_READY_MARKUPS_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('make-ready-markups'));
            showNotification('Make Ready Markups Saved!', 'The make ready markups table has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_MAKE_READY_MARKUPS_FAILURE));
        });
}

export const saveBhrMarkups = (bhrMarkups, elements, pressId) => dispatch => {
    for (let i = 0; i < bhrMarkups.length; i++) {
        if (bhrMarkups[i]['running_style'] === '' || bhrMarkups[i]['markup'] === '') {
            showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_BHR_MARKUPS, payload: null });
    axios.post(apiUrls.saveBhrMarkups, { pressId, bhrMarkups },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_BHR_MARKUPS_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('bhr-markups'));
            showNotification('BHR Markups Saved!', 'The BHR markups has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_BHR_MARKUPS_FAILURE));
        });
}

export const saveActivePapers = (activePapers, elements, pressId) => dispatch => {
    dispatch({ type: actionTypes.SAVE_ACTIVE_PAPERS, payload: null });
    axios.post(apiUrls.saveActivePapers, { pressId, activePapers },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ACTIVE_PAPERS_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('active-papers'));
            showNotification('Active Papers Saved!', 'The active papers has been saved successfully', 'success');
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.SAVE_ACTIVE_PAPERS_FAILURE));
        });
}

export const fetchInformation = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_INFORMATION_FAILURE));
        });
}

export function addActiveData(data, dataName) {
    return {
        type: actionTypes.ADD_ACTIVE_DATA,
        payload: { name: dataName, value: data }
    };
}

export function deleteActiveData(index, dataName) {
    return {
        type: actionTypes.DELETE_ACTIVE_DATA,
        payload: { name: dataName, value: index }
    };
}

export function concatActiveData(name, data) {
    return {
        type: actionTypes.CONCAT_ACTIVE_DATA,
        payload: { name, data }
    };
}

export function addMultipleValue(name, value) {
    return {
        type: actionTypes.ADD_MULTIPLE_VALUE,
        payload: { name, value }
    };
}

export function clearMultipleValue(name) {
    return {
        type: actionTypes.CLEAR_MULTIPLE_VALUE,
        payload: name
    };
}

export function concatMultipleValue(name, data) {
    return {
        type: actionTypes.CONCAT_MULTIPLE_VALUE,
        payload: { name, data }
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function setOptions(name, data) {
    return {
        type: actionTypes.SET_OPTIONS,
        payload: { name, data }
    };
}

export function loadOptions(data) {
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({ label: data[i]['name'], value: data[i]['id'] });
    }
    return options;
}

export function loadPaperCategoriesOptions(data, type = 1) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({ label: data[i]['category_name'], value: data[i]['id'] });
        }

        let typeName = null;
        if (type === 1) {
            typeName = 'paperCategories';
        } else if (type === 2) {
            typeName = 'coverPaperCategories';
        } else if (type === 3) {
            typeName = 'activePaperCategories';
        }
        dispatch(setOptions(typeName, options));
    };
}

export function loadPaperWeightsOptions(data, type = 1) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({ label: data[i] + '#', value: data[i] });
        }

        let typeName = null;
        if (type === 1) {
            typeName = 'paperWeights';
        } else if (type === 2) {
            typeName = 'coverPaperWeights';
        } else if (type === 3) {
            typeName = 'activePaperWeights';
        }
        dispatch(setOptions(typeName, options));
    };
}

export function loadPapersOptions(data, type = 1) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({ label: data[i]['paper_name'] + ' ' + data[i]['width'] + 'x' + data[i]['length'], value: data[i]['id'] });
        }

        let typeName = null;
        if (type === 1) {
            typeName = 'papers';
        } else if (type === 2) {
            typeName = 'coverPapers';
        } else if (type === 3) {
            typeName = 'activePapers';
        }
        dispatch(setOptions(typeName, options));
    };
}

export function setLoading(name, value) {
    return {
        type: actionTypes.SET_LOADING,
        payload: { name, value }
    };
}

export function resetOptions(name) {
    return {
        type: actionTypes.RESET_OPTIONS,
        payload: name
    };
}

export const fetchPaperCategories = () => dispatch => {
    dispatch(setLoading('activePaperCategories', true));
    dispatch(resetOptions('activePaperCategories'));
    dispatch({ type: actionTypes.FETCH_PAPER_CATEGORIES, payload: null });
    axios.get(apiUrls.paperCategories + '?fields=id,category_name',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PAPER_CATEGORIES_SUCCESS, payload: response.data });
            dispatch(setLoading('activePaperCategories', false));
            dispatch(loadPaperCategoriesOptions(response.data, 3));
        })
        .catch(error => {
            dispatch(setLoading('activePaperCategories', false));
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_PAPER_CATEGORIES_FAILURE));
        });
}

export const fetchPaperWeights = paperCategoryId => dispatch => {
    dispatch({ type: actionTypes.FETCH_PAPER_WEIGHTS, payload: null });
    axios.get(apiUrls.papers + '?fields=basis_weight&paper_category_id=' + paperCategoryId,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PAPER_WEIGHTS_SUCCESS, payload: response.data });
            dispatch(setLoading('activePaperWeights', false));
            let weights = [];
            for (let i = 0; i < response.data.length; i++) {
                let basisWeight = parseFloat(response.data[i]['basis_weight']);
                if (!weights.includes(basisWeight)) {
                    weights.push(basisWeight);
                }
            }
            weights.sort();
            dispatch(loadPaperWeightsOptions(weights, 3));
        })
        .catch(error => {
            dispatch(setLoading('activePaperWeights', false));
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_PAPER_WEIGHTS_FAILURE));
        });
}

export const fetchPapers = (paperCategoryId, paperWeight) => dispatch => {
    dispatch({ type: actionTypes.FETCH_PAPERS, payload: null });
    axios.get(apiUrls.papers + '?fields=id,paper_name,basis_weight,width,length&paper_category_id=' + paperCategoryId + '&basis_weight=' + paperWeight,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PAPERS_SUCCESS, payload: response.data });
            dispatch(setLoading('activePapers', false));
            dispatch(loadPapersOptions(response.data, 3));
        })
        .catch(error => {
            dispatch(setLoading('activePapers', false));
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_PAPERS_FAILURE));
        });
}

export function updateState(values) {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: values
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export const fetchElement = id => dispatch => {
    let fields = [
        'id',
        'name',
        'max_paper_width',
        'max_paper_length',
        'min_paper_width',
        'min_paper_length',
        'labor_rate',
        'labor_markup_rate',
        'setup_cost',
        'ink_units',
        'wash_up_cost',
        'gripper_margin',
        'left_right_tail_trim',
        'plate_change_cost',
        'plate_cost',
        'is_active',
        'is_perfecting',
        'is_default',
        'color_bar_margin',
        'paper_category_id',
        'paper_weight',
        'setup_time',
        'running_spoilage_flat',
        'makeready_spoilage_flat',
        'running_spoilage_wt',
        'makeready_spoilage_wt',
        'running_spoilage_sw',
        'makeready_spoilage_sw',
        'wastes',
        'speeds',
        'pressMakeReadies',
        'makeReadyMarkups',
        'bhrMarkups',
        'activePapers.id',
        'activePapers.basis_weight',
        'activePapers.paper_name',
        'activePapers.width',
        'activePapers.length'
    ];

    dispatch({ type: actionTypes.FETCH_ELEMENT, payload: null });
    axios.get(apiUrls.elements + '/' + id + '?fields=' + fields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENT_SUCCESS, payload: response.data });
            dispatch(setActiveElement(response.data));
        })
        .catch(error => {
            dispatch(validateAuthInResponse(error, actionTypes.FETCH_ELEMENT_FAILURE));
        });
}

export function setActivePage(page) {
    return {
        type: actionTypes.SET_ACTIVE_PAGE,
        payload: page
    };
}
