const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/large-format-machines`,
    search: `${API_URL}/large-format-machine/search`,
    information: `${API_URL}/large-format-machine/information`,
    saveActiveSubstrates: `${API_URL}/large-format-machine/save-active-substrates`
};

export default apiUrls;
