import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';
import moment from 'moment';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            date: moment().format('MM/DD/YYYY'),
            pm_number: '',
            customer: '',
            sales_id: '',
            csr_id: '',
            department_id: '',
            chargeable: '',
            quantity: '',
            reason: '',
            outside_cost: '',
            suggestion: '',
            postage: '',
            freight: '',
            audit_history: [],
            notifications: [],
            notifications_names: []
        },
        costs: [
            {
                type: 1,
                name: 'art',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 2,
                name: 'laser',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 3,
                name: 'data',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 4,
                name: 'handwork',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 5,
                name: 'prepress',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 6,
                name: 'fulfillment',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 7,
                name: 'small',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 8,
                name: 'inserting',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 9,
                name: 'large',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 10,
                name: 'bindery',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 11,
                name: 'ink',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 12,
                name: 'labeling',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 13,
                name: 'postage',
                checked: false,
                rate: '',
                value: ''
            },
            {
                type: 14,
                name: 'freight',
                checked: false,
                rate: '',
                value: ''
            }
        ],
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'id',
        type: 'desc'
    },
    filter: {
        fields: []
    },
    data: {
        sales: [],
        csrs: [],
        departments: [],
        notificationUsers: []
    },
    show: {
        list: true,
        form: false
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['elements', 'processedElements'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                date: moment().format('MM/DD/YYYY'),
                pm_number: '',
                customer: '',
                sales_id: '',
                csr_id: '',
                department_id: '',
                chargeable: '',
                quantity: '',
                reason: '',
                outside_cost: '',
                suggestion: '',
                postage: '',
                freight: '',
                audit_history: [],
                notifications: [],
                notifications_names: []
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'date'], action.payload.date)
                .setIn(['form', 'formValues', 'pm_number'], action.payload.pm_number)
                .setIn(['form', 'formValues', 'customer'], action.payload.customer)
                .setIn(['form', 'formValues', 'sales_id'], action.payload.sales_id)
                .setIn(['form', 'formValues', 'csr_id'], action.payload.csr_id)
                .setIn(['form', 'formValues', 'department_id'], action.payload.department_id)
                .setIn(['form', 'formValues', 'chargeable'], action.payload.chargeable)
                .setIn(['form', 'formValues', 'quantity'], action.payload.quantity)
                .setIn(['form', 'formValues', 'reason'], action.payload.reason)
                .setIn(['form', 'formValues', 'outside_cost'], action.payload.outside_cost)
                .setIn(['form', 'formValues', 'suggestion'], action.payload.suggestion)
                .setIn(['form', 'formValues', 'postage'], action.payload.postage)
                .setIn(['form', 'formValues', 'freight'], action.payload.freight)
                .setIn(['form', 'formValues', 'audit_history'], action.payload.audit_history)
                .setIn(['form', 'formValues', 'notifications'], action.payload.notifications)
                .setIn(['form', 'formValues', 'notifications_names'], action.payload.notifications_names)
                .updateIn(['form', 'costs'], (costs) => {
                    costs = costs.map(cost => cost.setIn(['checked'], false)
                        .setIn(['rate'], '')
                        .setIn(['value'], ''));

                    for (let i = 0; i < action.payload.rework_records.length; i++) {
                        let index = costs.findIndex(cost => cost.get('type') === action.payload.rework_records[i]['service_id']);
                        let cost = costs.get(index);
                        costs = costs.set(index, cost.setIn(['checked'], true)
                            .setIn(['rate'], action.payload.rework_records[i]['rate'])
                            .setIn(['value'], action.payload.rework_records[i]['value']));
                    }
                    return costs;
                });
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20));
            break;

        case actionTypes.FETCH_DATA:
            state = state.setIn(['data', 'sales'], [])
                .setIn(['data', 'csrs'], [])
                .setIn(['data', 'departments'], [])
                .setIn(['data', 'notificationUsers'], []);
            break;

        case actionTypes.FETCH_DATA_SUCCESS:
            state = state.setIn(['data', 'sales'], action.payload.sales)
                .setIn(['data', 'csrs'], action.payload.csrs)
                .setIn(['data', 'departments'], action.payload.departments)
                .setIn(['data', 'notificationUsers'], action.payload.notificationUsers);
            break;

        case actionTypes.FETCH_DATA_FAILURE:
            break;

        case actionTypes.SHOW_LIST:
            state = state.setIn(['show', 'list'], true)
                .setIn(['show', 'form'], false);
            break;

        case actionTypes.SHOW_FORM:
            state = state.setIn(['show', 'list'], false)
                .setIn(['show', 'form'], true);
            break;

        case actionTypes.ADD_NOTIFICATION:
            state = state.updateIn(['form', 'formValues', 'notifications'], (notifications) => {
                return notifications.push(parseInt(action.payload));
            });
            break;

        case actionTypes.REMOVE_NOTIFICATION:
            state = state.updateIn(['form', 'formValues', 'notifications'], (notifications) => {
                let index = notifications.indexOf(parseInt(action.payload));
                return notifications.delete(index);
            });
            break;

        case actionTypes.SET_COST:
            state = state.updateIn(['form', 'costs'], (costs) => {
                const index = costs.findIndex(cost => cost.get('name') === action.payload.name);
                const cost = costs.get(index);
                return costs.set(index, cost.setIn([action.payload.type], action.payload.value));
            });
            break;

        default:
            break;
    }

    return state.toJS();
}
