import React from 'react';
import { Dialog } from './../../common/dialog';
import Input from './../../Form/Input';
import Select from './../../Form/Select';

const FrontForm = React.memo((props) => {
    let content = (
        <form action="">
            <Input
                fields={props.fields}
                label="Name"
                field="name"
                required
                setField={props.setField}
            />
            <Input
                fields={props.fields}
                label="Description"
                field="description"
                required
                setField={props.setField}
            />
            <Select
                fields={props.fields}
                label="Run On"
                field="dpt_inkjet_id"
                required
                setField={props.setField}
                options={[
                    { value: '', label: 'Select option' },
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' },
                ]}
            />
            <Select
                fields={props.fields}
                label="Active"
                field="active"
                required
                setField={props.setField}
                options={[
                    { value: '', label: 'Select option' },
                    { value: 1, label: 'Yes' },
                    { value: 0, label: 'No' },
                ]}
            />
        </form>
    )
    if (props.isFetchingElement) {
        content = <div><i className="fas fa-circle-notch fa-spin" /> Loading Data...</div>;
    }

    let label = 'Save';
    if (props.isSaving) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={props.hideForm}>Cancel</button>,
        <button key="delete-button" className="btn btn-light btn-sm action-button" disabled={props.isSaving} onClick={props.save}>{label}</button>
    ];
    return (
        <Dialog
            name="form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="form-modal"
        >
            {content}
        </Dialog >
    );
});

FrontForm.propTypes = {

}

export default FrontForm;
