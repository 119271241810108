import React from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const HandForm = (props) => {
    const checkElementHandler = (id, event) => {
        props.checkElement('dptHand', id, event.target.checked ? 1 : 0);

        if (!event.target.checked) {
            props.removeSummaryElement('dptHand', id);
            props.setQuantity('dptHand', id, '');
            props.setInserts('dptHand', id, '');
        }
    };

    const validateIsReady = (id, quantity, inserts) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element.checked || !quantity || (parseInt(id) <= 3 && !inserts)) {
            props.removeSummaryElement('dptHand', id);
            return;
        }

        let timePer = 0;
        if (parseInt(id) <= 3) {
            if (parseInt(inserts) <= 5) {
                timePer = element.time_per1;
            } else {
                timePer = element.time_per2;
            }
        } else {
            timePer = element.time_per1;
        }

        let unit = 0;
        if (parseInt(element.unit) === 1) {     // Seconds
            unit = timePer / (1 / 60);
        } else if (parseInt(element.unit) === 2) {      // Minutes
            unit = timePer / 1;
        } else if (parseInt(element.unit) === 3) {      // Hours
            unit = timePer / 60;
        } else if (parseInt(element.unit) === 4) {      // Days
            unit = timePer / 480;     // 8 Hours a day
        }

        const timeInMins = quantity / unit;

        let tooltipInfo = null;
        if (parseInt(element.id) <= 3) {
            tooltipInfo = (
                <div className="text-left">
                    <div>{numeral(element.time_per1).format('0,0')} per hour for 1-5 inserts.</div>
                    <div>{numeral(element.time_per2).format('0,0')} per hour for 6-10 inserts.</div>
                </div>
            );
        } else {
            tooltipInfo = (
                <div className="text-left">
                    <div>Production Time: {numeral(element.time_per1).format('0,0')} per hour.</div>
                </div>
            );
        }

        props.setSummaryElement('dptHand', id, element.category + ' - ' + element.name, quantity, timeInMins, tooltipInfo);
    };

    const setInsertsHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setInserts('dptHand', id, value);

        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        validateIsReady(id, element.selected_quantity, value);
    };

    const setQuantityHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setQuantity('dptHand', id, value);

        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        validateIsReady(id, value, element.inserts);
    };

    const renderElement = (id) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element) {
            return null;
        }

        let value = '';
        if (element.selected_quantity) {
            value = element.selected_quantity;
        }

        let inserts = '';
        if (element.inserts) {
            inserts = element.inserts;
        }

        let inserField = null;
        if (parseInt(id) <= 3) {
            inserField = (
                <input
                    type="number"
                    min="0"
                    placeholder="Inserts"
                    value={inserts}
                    disabled={!element.checked}
                    onChange={setInsertsHandler.bind(this, element.id)}
                    className="inserts"
                />
            );
        }

        return (
            <div className="field">
                <input
                    type="checkbox"
                    checked={element.checked ? 1 : 0}
                    onChange={checkElementHandler.bind(this, id)}
                />
                <input
                    type="number"
                    min="0"
                    placeholder="Qty"
                    className="quantity-field"
                    value={value}
                    disabled={!element.checked}
                    onChange={setQuantityHandler.bind(this, id)}
                />
                {inserField}
                {element.name}
            </div>
        );
    };

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.hand) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    return (
        <div className="department-configuration hand-form">
            <h2>
                <div>Hand</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={props.resetDepartment.bind(this, 'dptHand')}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'hand')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.hand ? {} : { 'display': 'none' }}>
                <div className="form">
                    <div className="form-section">
                        <h6>Hand Matching:</h6>
                        {renderElement(1)}
                        {renderElement(2)}
                        {renderElement(3)}
                    </div>
                    <div className="form-section">
                        <h6>Hand Sort:</h6>
                        {renderElement(7)}
                        {renderElement(8)}
                    </div>
                    <div className="form-section">
                        <h6>Hand Nest:</h6>
                        {renderElement(4)}
                        {renderElement(5)}
                    </div>
                    <div className="form-section">
                        <h6>Misc:</h6>
                        {renderElement(6)}
                    </div>
                </div>
                <AccountingForm
                    department="hand"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default HandForm;