import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            email: '',
            company: '',
            phone: '',
            notes: '',
            active: 1,
            address: '',
            city: '',
            state: '',
            country: 'United States',
            zip: '',
            qb_id: null,
            title: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            display_name: '',
            use_display_name_for_check: 1,
            print_on_check_name: '',
            mobile: '',
            fax: '',
            other: '',
            website: '',
            terms: '',
            account_number: '',
            vendor1099: 0,
            bill_rate: 0,
            enable_stagecoach: 0,
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'display_name',
        type: 'asc'
    },
    filter: {
        fields: []
    },
    information: {
        departments: [],
        terms: [],
        isFetching: false
    },
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/vendors') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                email: '',
                company: '',
                phone: '',
                notes: '',
                active: 1,
                address: '',
                city: '',
                state: '',
                country: 'United States',
                zip: '',
                qb_id: null,
                title: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',
                display_name: '',
                use_display_name_for_check: 1,
                print_on_check_name: '',
                mobile: '',
                fax: '',
                other: '',
                website: '',
                terms: '',
                account_number: '',
                vendor1099: 0,
                bill_rate: 0,
                enable_stagecoach: 0,
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'email'], action.payload.email)
                .setIn(['form', 'formValues', 'company'], action.payload.company)
                .setIn(['form', 'formValues', 'phone'], action.payload.phone)
                .setIn(['form', 'formValues', 'notes'], action.payload.notes)
                .setIn(['form', 'formValues', 'active'], action.payload.active)
                .setIn(['form', 'formValues', 'address'], action.payload.address)
                .setIn(['form', 'formValues', 'city'], action.payload.city)
                .setIn(['form', 'formValues', 'state'], action.payload.state)
                .setIn(['form', 'formValues', 'country'], action.payload.country)
                .setIn(['form', 'formValues', 'zip'], action.payload.zip)
                .setIn(['form', 'formValues', 'qb_id'], action.payload.qb_id)
                .setIn(['form', 'formValues', 'title'], action.payload.title)
                .setIn(['form', 'formValues', 'first_name'], action.payload.first_name)
                .setIn(['form', 'formValues', 'middle_name'], action.payload.middle_name)
                .setIn(['form', 'formValues', 'last_name'], action.payload.last_name)
                .setIn(['form', 'formValues', 'suffix'], action.payload.suffix)
                .setIn(['form', 'formValues', 'display_name'], action.payload.display_name)
                .setIn(['form', 'formValues', 'use_display_name_for_check'], action.payload.use_display_name_for_check)
                .setIn(['form', 'formValues', 'print_on_check_name'], action.payload.print_on_check_name)
                .setIn(['form', 'formValues', 'mobile'], action.payload.mobile)
                .setIn(['form', 'formValues', 'fax'], action.payload.fax)
                .setIn(['form', 'formValues', 'other'], action.payload.other)
                .setIn(['form', 'formValues', 'website'], action.payload.website)
                .setIn(['form', 'formValues', 'account_number'], action.payload.account_number)
                .setIn(['form', 'formValues', 'vendor1099'], action.payload.vendor1099)
                .setIn(['form', 'formValues', 'bill_rate'], action.payload.bill_rate)
                .setIn(['form', 'formValues', 'terms'], action.payload.terms);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'departments'], [])
                .setIn(['information', 'terms'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'departments'], action.payload.salesReps)
                .setIn(['information', 'terms'], action.payload.terms);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        default:
            break;
    }

    return state.toJS();
}
