import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const PaperWeights = (props) => {
    const [fetchingPaperWeights, setFetchingPaperWeights] = useState(false);

    const productType = props.productType;
    const pressId = props.pressId;
    const secondPressId = props.secondPressId;
    const digitalMachineId = props.digitalMachineId;
    const paperCategoryId = props.paperCategoryId;
    const setPaperWeights = props.setPaperWeights;
    useEffect(() => {
        const productTypeInt = parseInt(productType);
        if (productTypeInt === 1 && pressId === '') {
            return;
        } else if (productTypeInt === 2 && (pressId === '' || secondPressId === '')) {
            return;
        } else if (productTypeInt === 3 && digitalMachineId === '') {
            return;
        }

        if (paperCategoryId === '') {
            return;
        }

        let url = process.env.REACT_APP_API_URL + '/dpt-estimate/paper-weights?type=' + productType + '&paperCategoryId=' + paperCategoryId;
        if(productTypeInt === 1) {
            url += '&pressId=' + pressId;
        } else if (productTypeInt === 2) {
            url += '&isCover=0&pressId=' + pressId + '&secondPressId=' + secondPressId;
        } else if(productTypeInt === 3) {
            url += '&digitalMachineId=' + digitalMachineId;
        }

        setFetchingPaperWeights(true);
        axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setPaperWeights(response.data);
                setFetchingPaperWeights(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingPaperWeights(false);
            });
    }, [productType, pressId, secondPressId, digitalMachineId, paperCategoryId, setPaperWeights]);

    const changeHandler = (event) => {
        props.setPaperWeight(event.target.value);

        props.setPapers([]);
        props.setPaperId('');
    };

    let options = [];
    if (props.paperCategoryId === '') {
        options.push(<option key="0" value="">Select a Category</option>);
    } else {
        options = props.paperWeights.map((paperWeight) => <option key={paperWeight} value={paperWeight}>{paperWeight}#</option>);
        options.unshift(<option key="0" value="">Select option</option>);
    }

    return (
        <div className="field">
            <div className="label">{props.label ? props.label : 'Weight'}:</div>
            <select value={props.paperWeight} onChange={changeHandler} disabled={props.paperCategoryId === ''}>
                {options}
            </select>
            {fetchingPaperWeights && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default PaperWeights;