export const SET_FIELD = 'DPT_PRESS/SET_FIELD';
export const FETCH_DATA = 'DPT_PRESS/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'DPT_PRESS/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'DPT_PRESS/FETCH_DATA_ERROR';
export const SAVE = 'DPT_PRESS/SAVE';
export const SAVE_SUCCESS = 'DPT_PRESS/SAVE_SUCCESS';
export const SAVE_ERROR = 'DPT_PRESS/SAVE_ERROR';
export const FETCH_ELEMENT = 'DPT_PRESS/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'DPT_PRESS/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'DPT_PRESS/FETCH_ELEMENT_ERROR';
export const SET_ACTIVE_PAGE = 'DPT_PRESS/SET_ACTIVE_PAGE';
export const RESET_FORM = 'DPT_PRESS/RESET_FORM';