import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Pagination } from './components';
import * as actions from './actions';
import PropTypes from 'prop-types';

class Container extends Component {
    static propTypes = {
        multiple: PropTypes.bool,
        name: PropTypes.string,
        totalItems: PropTypes.number,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        setActivePage: PropTypes.func,
        setActivePageMultiple: PropTypes.func
    };

    handleSetPrevPage() {
        const { setActivePage, selectedPage, multiple, name, setActivePageMultiple, handleSetActivePage } = this.props;
        if (selectedPage > 1) {
            if (handleSetActivePage !== undefined && handleSetActivePage != null) {
                handleSetActivePage(parseInt(selectedPage) - 1);
            } else {
                if (multiple) {
                    setActivePageMultiple(name, parseInt(selectedPage) - 1);
                } else {
                    setActivePage(parseInt(selectedPage) - 1);
                }
            }
        }
    }

    handleSetNextPage() {
        const { setActivePage, selectedPage, totalPages, multiple, name, setActivePageMultiple, handleSetActivePage } = this.props;
        if (selectedPage < totalPages) {
            if (handleSetActivePage !== undefined && handleSetActivePage != null) {
                handleSetActivePage(parseInt(selectedPage) + 1);
            } else {
                if (multiple) {
                    setActivePageMultiple(name, parseInt(selectedPage) + 1);
                } else {
                    setActivePage(parseInt(selectedPage) + 1);
                }
            }
        }
    }

    render() {
        const { totalItems, totalPages, selectedPage, elementsPerPage, setActivePage, handleSetActivePage } = this.props;

        let setActivePageFunc = setActivePage;
        if (handleSetActivePage !== undefined && handleSetActivePage != null) {
            setActivePageFunc = handleSetActivePage;
        }

        return (
            <Pagination
                totalItems={totalItems}
                totalPages={totalPages}
                selectedPage={selectedPage}
                elementsPerPage={elementsPerPage}
                setActivePage={setActivePageFunc}
                handleSetNextPage={this.handleSetNextPage.bind(this)}
                handleSetPrevPage={this.handleSetPrevPage.bind(this)}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({

});

const mapDispatchToProps = (dispatch) => {
    return {
        setActivePage: (page) => dispatch(actions.setActivePage(page)),
        setActivePageMultiple: (name, page) => dispatch(actions.setActivePageMultiple(name, page))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
