import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class DiscountTable extends Component {
    static propTypes = {
        discountTable: PropTypes.array,
        hideDiscountTable: PropTypes.func,
        isSavingDiscountTable: PropTypes.bool,
        handleSaveDiscountTable: PropTypes.func,
        handleAddActiveLaminate: PropTypes.func,
        handleDeleteDiscount: PropTypes.func,
        formValues: PropTypes.object,
        handleMultipleChange: PropTypes.func,
        updateDiscount: PropTypes.func
    };

    renderDiscountTable() {
        const { discountTable, handleDeleteDiscount, updateDiscount } = this.props;
        return (
            <table className="table table-sm discount-table">
                <thead>
                    <tr>
                        <th>Start Quantity</th>
                        <th>End Quantity</th>
                        <th>Discount</th>
                        <th style={{ width: 40 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {discountTable.map((discount, index) => {
                        return (
                            <tr>
                                <td><input type="text" value={discount['start_quantity']} onChange={(event) => updateDiscount(index, 'start_quantity', event.target.value)} /></td>
                                <td><input type="text" value={discount['end_quantity']} onChange={(event) => updateDiscount(index, 'end_quantity', event.target.value)} /></td>
                                <td><input type="text" value={discount['discount']} onChange={(event) => updateDiscount(index, 'discount', event.target.value)} /></td>
                                <td><i className="fas fa-trash" onClick={handleDeleteDiscount.bind(this, index)}></i></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    render() {
        const { hideDiscountTable, handleSaveDiscountTable, isSavingDiscountTable, handleAddDiscount, activeElement } = this.props;

        if (activeElement == null) {
            return null;
        }

        let data = (
            <div className="row">
                <div className="col">
                    Discount Table ({activeElement['name']})
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="active-presses">
                        {this.renderDiscountTable()}
                    </div>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div onClick={handleAddDiscount} style={{ fontSize: 14, float: 'right', cursor: 'pointer' }}><i className="fas fa-plus"></i> Add</div>
                </div>
            </div>
        );

        let saveButtonLabel = 'Save';
        if (isSavingDiscountTable) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveDiscountTable} disabled={isSavingDiscountTable}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideDiscountTable}>Close</button>
        ];
        return (
            <Dialog
                name="discount-table"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default DiscountTable;
