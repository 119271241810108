import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { stringifyEqualityCheck } from './../../utils';
import Table from './Table';
import Form from './Form';
import * as actions from './DptHand.actions';
import * as selectors from './DptHand.selectors';
import Layout from './../Layout';
import * as dialog from '../common/dialog';
import { Helmet } from 'react-helmet';
import AdminHeader from './../common/components/AdminHeader';
import Tabs from './../common/components/Tabs';
import * as loginSelectors from './../Login/Login.selectors';
import './DptHand.scss';

const DptHand = (props) => {
    /* State to props */
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const data = useSelector(selectors.getData, stringifyEqualityCheck);
    const isFetchingData = useSelector(selectors.getIsFetchingData);
    const isFetchingElement = useSelector(selectors.getIsFetchingElement);
    const isSaving = useSelector(selectors.getIsSaving);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    
    /* Dispatch to props */
    const dispatch = useDispatch();
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const fetchData = useCallback(() => dispatch(actions.fetchData()), [dispatch]);
    const fetchElement = useCallback((id) => dispatch(actions.fetchElement(id)), [dispatch]);
    const resetForm = useCallback(() => dispatch(actions.resetForm()), [dispatch]);
    const save = useCallback(() => dispatch(actions.save()), [dispatch]);
    const setActivePage = useCallback((page) => dispatch(actions.setActivePage(page)), [dispatch]);
    const showForm = useCallback(() => dispatch(dialog.actions.showDialog('form-modal')), [dispatch]);
    const hideForm = useCallback(() => dispatch(dialog.actions.hideDialog('form-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        hideForm();
        fetchData();
    }, [fetchData, hideForm]);

    const showFormHandler = (id) => {
        if (id === null) {
            resetForm(id);
        } else {
            fetchElement(id);
        }

        showForm();
    }

    return (
        <Layout
            title={'DPT Hand'}
            history={props.history}
        >
            <div id="dpt-hand-container" className="container-fluid">
                <Helmet>
                    <title>DPT Hand</title>
                    <meta name="description" content="" />
                </Helmet>
                <AdminHeader
                    title="DPT Hand"
                />
                <div className="row">
                    <div className="col">
                        <Tabs
                            user={user}
                        />
                        <Table
                            data={data}
                            isFetchingData={isFetchingData}
                            pagination={pagination}
                            showFormHandler={showFormHandler}
                            setActivePage={setActivePage}
                        />
                    </div>
                </div>
            </div>
            <Form
                fields={fields}
                isSaving={isSaving}
                isFetchingElement={isFetchingElement}
                setField={setField}
                hideForm={hideForm}
                save={save}
            />
        </Layout>
    );
};

DptHand.propTypes = {

};

export default DptHand;
