import { NAME } from './constants';

export const FETCH_ELEMENTS = `${NAME}/FETCH_ELEMENTS`;
export const FETCH_ELEMENTS_SUCCESS = `${NAME}/FETCH_ELEMENTS_SUCCESS`;
export const FETCH_ELEMENTS_FAILURE = `${NAME}/FETCH_ELEMENTS_FAILURE`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_ELEMENT = `${NAME}/DELETE_ELEMENT`;
export const DELETE_ELEMENT_SUCCESS = `${NAME}/DELETE_ELEMENT_SUCCESS`;
export const DELETE_ELEMENT_FAILURE = `${NAME}/DELETE_ELEMENT_FAILURE`;

export const SAVE_ELEMENT = `${NAME}/SAVE_ELEMENT`;
export const SAVE_ELEMENT_SUCCESS = `${NAME}/SAVE_ELEMENT_SUCCESS`;
export const SAVE_ELEMENT_FAILURE = `${NAME}/SAVE_ELEMENT_FAILURE`;

export const SAVE_COSTS_TABLE = `${NAME}/SAVE_COSTS_TABLE`;
export const SAVE_COSTS_TABLE_SUCCESS = `${NAME}/SAVE_COSTS_TABLE_SUCCESS`;
export const SAVE_COSTS_TABLE_FAILURE = `${NAME}/SAVE_COSTS_TABLE_FAILURE`;

export const SAVE_DISCOUNT_TABLE = `${NAME}/SAVE_DISCOUNT_TABLE`;
export const SAVE_DISCOUNT_TABLE_SUCCESS = `${NAME}/SAVE_DISCOUNT_TABLE_SUCCESS`;
export const SAVE_DISCOUNT_TABLE_FAILURE = `${NAME}/SAVE_DISCOUNT_TABLE_FAILURE`;

export const UPDATE_FORM_VALUE = `${NAME}/UPDATE_FORM_VALUE`;
export const SET_SORT = `${NAME}/SET_SORT`;
export const SET_ELEMENTS = `${NAME}/SET_ELEMENTS`;
export const CHANGE_ATTRIBUTE = `${NAME}/CHANGE_ATTRIBUTE`;
export const SET_ACTIVE_ELEMENT = `${NAME}/SET_ACTIVE_ELEMENT`;
export const SET_ID_FOR_DELETE = `${NAME}/SET_ID_FOR_DELETE`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_FORM_ERRORS = `${NAME}/SET_FORM_ERRORS`;
export const SET_FORM_ERROR = `${NAME}/SET_FORM_ERROR`;
export const SET_COSTS_TABLE = `${NAME}/SET_COSTS_TABLE`;
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const CHANGE_FILTER = `${NAME}/CHANGE_FILTER`;
export const PROCESS_ELEMENTS = `${NAME}/PROCESS_ELEMENTS`;
export const ADD_DISCOUNT = `${NAME}/ADD_DISCOUNT`;
export const DELETE_DISCOUNT = `${NAME}/DELETE_DISCOUNT`;
export const UPDATE_DISCOUNT = `${NAME}/UPDATE_DISCOUNT`;
