import React from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const OutsideServiceForm = (props) => {
    const getUnit = (unit, plural = true) => {
        if (parseInt(unit) === 1) {
            return plural ? 'seconds' : 'second';
        } else if (parseInt(unit) === 2) {
            return plural ? 'minutes' : 'minute';
        } else if (parseInt(unit) === 3) {
            return plural ? 'hours' : 'hour';
        } else if (parseInt(unit) === 4) {
            return plural ? 'days' : 'day';
        } else if (parseInt(unit) === 5) {
            return plural ? 'min per boxes' : 'min per box';
        } else {
            return 'N/A';
        }
    };

    const checkElementHandler = (id, event) => {
        props.checkElement('dptOutsideService', id, event.target.checked ? 1 : 0);

        if (!event.target.checked) {
            props.removeSummaryElement('dptOutsideService', id);
            props.setQuantity('dptOutsideService', id, '');
        } else {
            props.setQuantity('dptOutsideService', id, 1);

            const unit = getUnitInMins(id);
            const timeInMins = 1 * unit;
            const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));

            let unitTooltip = getUnit(element.unit);
            const tooltipInfo = (
                <div className="text-left">
                    <div>ProProduction Time: {numeral(element.time_per).format('0,0')} {unitTooltip}.</div>
                </div>
            );

            props.setSummaryElement('dptOutsideService', id, element.name, 1, timeInMins, tooltipInfo);
        }
    };

    const getUnitInMins = (id) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (parseInt(element.unit) === 1) {     // Seconds
            return element.time_per * 1 / 60;
        } else if (parseInt(element.unit) === 2) {      // Minutes
            return element.time_per * 1;
        } else if (parseInt(element.unit) === 3) {      // Hours
            return element.time_per * 60;
        } else if (parseInt(element.unit) === 4) {      // Days
            return element.time_per * 480;     // 8 Hours a day
        } else {
            return 0;
        }
    };

    const renderElement = (id) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element) {
            return null;
        }

        return (
            <div className="field">
                <input
                    type="checkbox"
                    checked={element.checked ? 1 : 0}
                    onChange={checkElementHandler.bind(this, id)}
                />
                {element.name}
            </div>
        );
    };

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.outsideService) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    return (
        <div className="department-configuration outside-service-form">
            <h2>
                <div>Outside</div>
                <div className="outside-fields" style={props.show.outsideService ? {} : { 'display': 'none' }}>
                    <div className="field">Vendor: <input type="text" /></div>
                    <div className="field">Job Number: <input type="text" /></div>
                    <div className="field">Estimate: <input type="text" /></div>
                    <div className="field">Our PO#: <input type="text" /></div>
                </div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={props.resetDepartment.bind(this, 'dptOutsideService')}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'outsideService')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.outsideService ? {} : { 'display': 'none' }}>
                <div className="form">
                    <div className="form-section">
                        {renderElement(1)}
                        {renderElement(3)}
                        {renderElement(5)}
                    </div>
                    <div className="form-section">
                        {renderElement(2)}
                        {renderElement(4)}
                    </div>
                </div>
                <AccountingForm
                    department="outsideService"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default OutsideServiceForm;