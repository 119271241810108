import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class ActiveAccessories extends Component {
    static propTypes = {
        activeAccessories: PropTypes.array,
        hideActiveAccessories: PropTypes.func,
        isSavingActiveAccessories: PropTypes.bool,
        handleSaveActiveAccessories: PropTypes.func,
        handleAddActiveAccessory: PropTypes.func,
        handleDeleteActiveAccessory: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        accessories: PropTypes.array,
        formValues: PropTypes.object,
        handleMultipleChange: PropTypes.object
    };

    renderActiveAccessories() {
        const { activeAccessories, handleDeleteActiveAccessory } = this.props;

        let accessories = [];
        for (let i = 0; i < activeAccessories.length; i++) {
            accessories.push({ id: activeAccessories[i]['id'], name: activeAccessories[i]['name'] });
        }

        accessories.sort(function (a, b) {
            return a['name'] < b['name'] ? -1 : 1;
        });

        return accessories.map((accessory, index) => {
            return (
                <div key={index} className="float-left mr-2 mt-1">
                    <span className="badge badge-info bold">
                        {accessory['name']} <i className="fas fa-times" onClick={handleDeleteActiveAccessory.bind(this, accessory['id'])}></i>
                    </span>
                </div>
            );
        });
    }

    isAccessoryActive(accessoryId) {
        const { activeAccessories } = this.props;

        for (let i = 0; i < activeAccessories.length; i++) {
            if (activeAccessories[i]['id'] === accessoryId) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { hideActiveAccessories, handleSaveActiveAccessories, isSavingActiveAccessories, handleAddActiveAccessory,
            isFetchingInformation, accessories, formValues, handleMultipleChange, isFetchingElement } = this.props;

        let accessoryOptions = [];
        for (let i = 0; i < accessories.length; i++) {
            if (!this.isAccessoryActive(accessories[i]['id'])) {
                accessoryOptions.push(<option value={accessories[i]['id']}>{accessories[i]['name']}</option>);
            }
        }

        let data = (
            <div className="row">
                <div className="col-5">
                    Accessories:
                    <select name="active_accessory_ids" multiple size={5} value={formValues['active_accessory_ids']}
                        onChange={handleMultipleChange.bind(this, 'active_accessory_ids')}>
                        {accessoryOptions}
                    </select>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="add-button mt-2" onClick={handleAddActiveAccessory} style={{ width: 100, fontSize: 13 }}><i className="fas fa-plus" /> Add</div>
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <div className="active-presses">
                        {this.renderActiveAccessories()}
                    </div>
                </div>
            </div>
        );
        if (isFetchingElement) {
            data = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        if (isFetchingInformation) {
            data = null;
        }

        let saveButtonLabel = 'Save';
        if (isSavingActiveAccessories) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveActiveAccessories} disabled={isSavingActiveAccessories}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideActiveAccessories}>Close</button>
        ];
        return (
            <Dialog
                name="active-accessories"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default ActiveAccessories;
