import React, { useState, useEffect } from 'react';
import { showNotification } from './../../../utils';
import axios from 'axios';

const Substrates = (props) => {
    const [fetchingSubstrates, setFetchingSubstrates] = useState(false);

    const productType = props.productType;
    const setSubstrates = props.setSubstrates;
    const largeFormatMachineId = props.largeFormatMachineId;
    useEffect(() => {
        if (parseInt(productType) !== 4 || largeFormatMachineId === '') {
            return;
        }

        setFetchingSubstrates(true);
        axios.get(process.env.REACT_APP_API_URL + '/dpt-estimate/substrates?large-format-machine-id=' + largeFormatMachineId,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then((response) => {
                setSubstrates(response.data);
                setFetchingSubstrates(false);
            })
            .catch((error) => {
                showNotification('Error', 'An error has occurred', 'danger')
                setFetchingSubstrates(false);
            });
    }, [productType, largeFormatMachineId, setSubstrates]);

    const changeHandler = (event) => {
        props.setSubstrateId(event.target.value);

        props.setLaminates([]);
        props.setLaminateId('');
    };

    return (
        <div className="field">
            <div className="label">Substrate:</div>
            <select value={props.substrateId} onChange={changeHandler}>
                <option value="">Select option</option>
                {props.substrates.map((substrate) => <option key={substrate.id} value={substrate.id}>{substrate.name}</option>)}
            </select>
            {fetchingSubstrates && <div className="spinner"><i className="fas fa-circle-notch fa-spin fetching-field" /></div>}
        </div>
    );
};

export default Substrates;