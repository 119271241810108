import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from './../../common/components/Select';
import numeral from 'numeral';
import moment from 'moment';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        saveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        data: PropTypes.object,
        showList: PropTypes.func,
        resetForm: PropTypes.func,
        handleUpdateFormDateValue: PropTypes.func,
        handleNotificationClick: PropTypes.func,
        setCost: PropTypes.func,
        permission: PropTypes.object,
        costs: PropTypes.object
    };

    renderInput(name, type, required, readOnly = false) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <Input
                type="text"
                name={name}
                required={required}
                formValues={formValues}
                formErrors={formErrors}
                formErrorMessages={formErrorMessages}
                readOnly={readOnly}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
            />
        );
    }

    renderCheckbox(name, type, required) {
        const { formValues, handleUpdateFormValue } = this.props;
        return (
            <input type="checkbox" checked={formValues[name]} id={name} name={name}
                onClick={handleUpdateFormValue.bind(type, required, this)} />
        );
    }

    renderTextarea(name, type, required, readOnly = false) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <TextArea
                name={name}
                required={required}
                formValues={formValues}
                formErrors={formErrors}
                formErrorMessages={formErrorMessages}
                readOnly={readOnly}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
            />
        );
    }

    renderSelect(name, options, required) {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <Select
                name={name}
                required={required}
                options={options}
                formValues={formValues}
                formErrors={formErrors}
                handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
            />
        );
    }

    renderAuditHistory() {
        const { formValues } = this.props;
        return formValues['audit_history'].map((element, index) => {
            let json = JSON.parse(element['description']);
            let newValues = json['new_values'];

            let record = [<span key={'record-1'} className={this.hasChange(json, 'date') ? 'changed' : ''}>{moment(newValues['date']).format('MM/DD/YYYY')}: </span>];

            if (json['is_new']) {
                record.push(<span key={'record-2'} className={this.hasChange(json, 'created_by_name') ? 'changed' : ''}>{newValues['created_by_name']} created this change order. </span>);
            } else {
                record.push(<span key={'record-2'} className={this.hasChange(json, 'updated_by_name') ? 'changed' : ''}>{newValues['updated_by_name']} updated this change order. </span>);
            }

            record.push(<span key={'record-3'} className={this.hasChange(json, 'pm_number') ? 'changed' : ''}>PM #: {newValues['pm_number']}. </span>);
            record.push(<span key={'record-4'} className={this.hasChange(json, 'customer') ? 'changed' : ''}>Customer: {newValues['customer']}. </span>);
            record.push(<span key={'record-5'} className={this.hasChange(json, 'sales_name') ? 'changed' : ''}>Salesperson: {newValues['sales_name']}. </span>);
            record.push(<span key={'record-6'} className={this.hasChange(json, 'csr_name') ? 'changed' : ''}>CSR: {newValues['csr_name']}. </span>);
            record.push(<span key={'record-7'} className={this.hasChange(json, 'department_name') ? 'changed' : ''}>Department: {newValues['department_name']}. </span>);
            record.push(<span key={'record-8'} className={this.hasChange(json, 'chargeable') ? 'changed' : ''}>Chargeable: {newValues['chargeable'] ? 'Yes' : 'No'}. </span>);
            record.push(<span key={'record-9'} className={this.hasChange(json, 'quantity') ? 'changed' : ''}>Quantity: {newValues['quantity']}. </span>);

            return (
                <div className="audit-element" key={'audit-' + index}>
                    {record}
                </div>
            );
        });
    }

    hasChange(json, attribute) {
        if (json['is_new']) {
            return false;
        }

        return json['new_values'][attribute] !== json['old_values'][attribute];
    }

    getUsersByType(roleId) {
        const { data, formValues, handleNotificationClick } = this.props;

        let users = [];
        for (let i = 0; i < data['notificationUsers'].length; i++) {
            if (data['notificationUsers'][i]['user_type_1'] !== roleId) {
                continue;
            }

            let checked = formValues['notifications'].includes(parseInt(data['notificationUsers'][i]['id']));
            users.push(
                <div className="user-notification" key={i}>
                    <input type="checkbox" checked={checked} onClick={handleNotificationClick.bind(this, data['notificationUsers'][i]['id'])} /> {data['notificationUsers'][i]['name']}
                </div>
            );
        }
        return users;
    }

    renderNotificationsUsers() {
        let csrs = this.getUsersByType(1);
        let csrRow = null;
        if (csrs.length > 0) {
            csrRow = (
                <div className="">
                    <div className="in-out-role-name">Accounting</div>
                    {csrs}
                </div>
            );
        }

        let saless = this.getUsersByType(2);
        let salesRow = null;
        if (saless.length > 0) {
            salesRow = (
                <div className="">
                    <div className="in-out-role-name">CSR</div>
                    {saless}
                </div>
            );
        }

        let accountings = this.getUsersByType(3);
        let accountingRow = null;
        if (accountings.length > 0) {
            accountingRow = (
                <div className="">
                    <div className="in-out-role-name">Data</div>
                    {accountings}
                </div>
            );
        }

        let datas = this.getUsersByType(4);
        let dataRow = null;
        if (datas.length > 0) {
            dataRow = (
                <div className="">
                    <div className="in-out-role-name">Sales</div>
                    {datas}
                </div>
            );
        }

        let purchasings = this.getUsersByType(5);
        let purchasingRow = null;
        if (purchasings.length > 0) {
            purchasingRow = (
                <div className="">
                    <div className="in-out-role-name">Purchasing</div>
                    {purchasings}
                </div>
            );
        }

        let prepresses = this.getUsersByType(6);
        let prepressRow = null;
        if (prepresses.length > 0) {
            prepressRow = (
                <div className="">
                    <div className="in-out-role-name">PrePress</div>
                    {prepresses}
                </div>
            );
        }

        let graphics = this.getUsersByType(7);
        let graphicsRow = null;
        if (graphics.length > 0) {
            graphicsRow = (
                <div className="">
                    <div className="in-out-role-name">Graphics</div>
                    {graphics}
                </div>
            );
        }

        let largeFormats = this.getUsersByType(8);
        let largeFormatRow = null;
        if (largeFormats.length > 0) {
            largeFormatRow = (
                <div className="">
                    <div className="in-out-role-name">Large Format</div>
                    {largeFormats}
                </div>
            );
        }

        let productions = this.getUsersByType(9);
        let productionRow = null;
        if (productions.length > 0) {
            productionRow = (
                <div className="">
                    <div className="in-out-role-name">Operation</div>
                    {productions}
                </div>
            );
        }

        let operations = this.getUsersByType(10);
        let operationsRow = null;
        if (operations.length > 0) {
            operationsRow = (
                <div className="">
                    <div className="in-out-role-name">Production</div>
                    {operations}
                </div>
            );
        }

        let lettershops = this.getUsersByType(11);
        let lettershopRow = null;
        if (lettershops.length > 0) {
            lettershopRow = (
                <div className="">
                    <div className="in-out-role-name">Lettershop</div>
                    {lettershops}
                </div>
            );
        }

        let manages = this.getUsersByType(12);
        let manageRow = null;
        if (manages.length > 0) {
            manageRow = (
                <div className="">
                    <div className="in-out-role-name">Management</div>
                    {manages}
                </div>
            );
        }

        let ceo = this.getUsersByType(14);
        let ceoRow = null;
        if (ceo.length > 0) {
            ceoRow = (
                <div className="">
                    <div className="in-out-role-name">CEO</div>
                    {ceo}
                </div>
            );
        }

        let president = this.getUsersByType(15);
        let presidentRow = null;
        if (president.length > 0) {
            presidentRow = (
                <div className="">
                    <div className="in-out-role-name">President</div>
                    {president}
                </div>
            );
        }

        let cfo = this.getUsersByType(16);
        let cfoRow = null;
        if (cfo.length > 0) {
            cfoRow = (
                <div className="">
                    <div className="in-out-role-name">CFO</div>
                    {cfo}
                </div>
            );
        }

        let marketings = this.getUsersByType(17);
        let marketingsRow = null;
        if (marketings.length > 0) {
            marketingsRow = (
                <div className="">
                    <div className="in-out-role-name">Marketing</div>
                    {marketings}
                </div>
            );
        }

        return (
            <div>
                {csrRow}
                <div className="clearfix"></div>
                {salesRow}
                <div className="clearfix"></div>
                {accountingRow}
                <div className="clearfix"></div>
                {dataRow}
                <div className="clearfix"></div>
                {purchasingRow}
                <div className="clearfix"></div>
                {prepressRow}
                <div className="clearfix"></div>
                {graphicsRow}
                <div className="clearfix"></div>
                {largeFormatRow}
                <div className="clearfix"></div>
                {productionRow}
                <div className="clearfix"></div>
                {operationsRow}
                <div className="clearfix"></div>
                {lettershopRow}
                <div className="clearfix"></div>
                {manageRow}
                <div className="clearfix"></div>
                {ceoRow}
                <div className="clearfix"></div>
                {presidentRow}
                <div className="clearfix"></div>
                {cfoRow}
                <div className="clearfix"></div>
                {marketingsRow}
            </div>
        );
    }

    renderCostColumns(rateOptions, name, label) {
        const { setCost, costs } = this.props;

        let checked = false;
        let rate = '';
        let value = '';
        for (let i = 0; i < costs.length; i++) {
            if (costs[i]['name'] === name) {
                checked = costs[i]['checked'];
                rate = costs[i]['rate'];
                value = costs[i]['value'];
            }
        }

        let columns = [];
        columns.push(
            <td style={{ width: '20%' }}>
                <input type="checkbox" checked={checked} onClick={(event) => setCost(name, 'checked', event.target.checked ? 1 : 0)} /> {label}
            </td>
        );
        columns.push(
            <td style={{ width: '14%' }}>
                <select value={rate} onChange={(event) => setCost(name, 'rate', event.target.value)}>
                    {rateOptions.map((option, index) => {
                        return <option key={index} value={option['value']}>{option['label']}</option>;
                    })}
                </select>
            </td>
        );
        columns.push(
            <td style={{ width: '14%' }}>
                <input type="text" value={value} onChange={(event) => setCost(name, 'value', event.target.value)} />
            </td>
        );

        return columns;
    }

    render() {
        const { saveElement, isSavingForm, data, formValues, showList, resetForm, handleUpdateFormDateValue,
            permission, setCost, costs } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let salesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['sales'].length; i++) {
            salesOptions.push({ label: data['sales'][i]['name'], value: data['sales'][i]['id'] });
        }

        let csrsOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['csrs'].length; i++) {
            csrsOptions.push({ label: data['csrs'][i]['name'], value: data['csrs'][i]['id'] });
        }

        let departmentsOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['departments'].length; i++) {
            departmentsOptions.push({ label: data['departments'][i]['name'], value: data['departments'][i]['id'] });
        }

        let rateOptions = [
            { label: 'Select option', value: '' },
            { label: 'Hours', value: 1 },
            { label: 'Lot', value: 2 },
            { label: 'Each', value: 3 },
            { label: '1000', value: 4 }
        ];

        let costsTable = null;
        if (permission != null && permission['Enter Costs'] && formValues['id'] !== '') {
            let totalCost = 0;
            let postageChecked = false;
            let postageValue = '';
            let freightChecked = false;
            let freightValue = '';
            for (let i = 0; i < costs.length; i++) {
                if (costs[i]['checked'] && costs[i]['value'] !== '') {
                    totalCost += parseFloat(costs[i]['value']);
                }

                if (costs[i]['name'] === 'postage') {
                    postageChecked = costs[i]['checked'];
                    postageValue = costs[i]['value'];
                } else if (costs[i]['name'] === 'freight') {
                    freightChecked = costs[i]['checked'];
                    freightValue = costs[i]['value'];
                }
            }

            costsTable = (
                <table className="table costs">
                    <tr>
                        <td style={{ width: '20%' }}>Services</td>
                        <td style={{ width: '14%' }}>Rate</td>
                        <td style={{ width: '14%' }}>Value</td>
                        <td style={{ width: '4%' }}></td>
                        <td style={{ width: '20%' }}>Services</td>
                        <td style={{ width: '14%' }}>Rate</td>
                        <td style={{ width: '14%' }}>Value</td>
                    </tr>
                    <tr>
                        {this.renderCostColumns(rateOptions, 'art', 'Art Dept.')}
                        <td style={{ width: '4%' }}></td>
                        {this.renderCostColumns(rateOptions, 'laser', 'Laser')}
                    </tr>
                    <tr>
                        {this.renderCostColumns(rateOptions, 'data', 'Data Processing')}
                        <td style={{ width: '4%' }}></td>
                        {this.renderCostColumns(rateOptions, 'handwork', 'Handwork')}
                    </tr>
                    <tr>
                        {this.renderCostColumns(rateOptions, 'prepress', 'Pre-press')}
                        <td style={{ width: '4%' }}></td>
                        {this.renderCostColumns(rateOptions, 'fulfillment', 'Fulfillment')}
                    </tr>
                    <tr>
                        {this.renderCostColumns(rateOptions, 'small', 'Small Press')}
                        <td style={{ width: '4%' }}></td>
                        {this.renderCostColumns(rateOptions, 'inserting', 'Inserting')}
                    </tr>
                    <tr>
                        {this.renderCostColumns(rateOptions, 'large', 'Large Press')}
                        <td style={{ width: '4%' }}></td>
                        {this.renderCostColumns(rateOptions, 'ink', 'Ink Jetting')}
                    </tr>
                    <tr>
                        {this.renderCostColumns(rateOptions, 'bindery', 'Bindery')}
                        <td style={{ width: '4%' }}></td>
                        {this.renderCostColumns(rateOptions, 'labeling', 'Labeling')}
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <b>Additional Charges</b>
                            <div className="clearfix"></div>
                            <div className="float-left" style={{ width: '50%' }}>
                                <input type="checkbox" onClick={(event) => setCost('postage', 'checked', event.target.checked ? 1 : 0)}
                                    checked={postageChecked} /> Postage <input type="text" style={{ width: '90%' }} value={postageValue}
                                        onChange={(event) => setCost('postage', 'value', event.target.value)} />
                            </div>
                            <div className="float-left" style={{ width: '50%' }}>
                                <input type="checkbox" onClick={(event) => setCost('freight', 'checked', event.target.checked ? 1 : 0)}
                                    checked={freightChecked} /> Freight <input type="text" style={{ width: '90%' }} value={freightValue}
                                        onChange={(event) => setCost('freight', 'value', event.target.value)} />
                            </div>
                        </td>
                        <td style={{ width: '4%' }}></td>
                        <td colSpan="3" style={{ fontSize: 15, paddingTop: 15 }}>
                            <b>Total re-work cost: {numeral(totalCost).format('$0,0.00')}</b>
                        </td>
                    </tr>
                </table>
            );
        }

        return (
            <form id="change-order-form">
                <div className="mt-2">
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        Date:
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={handleUpdateFormDateValue.bind(this, 'date', true)}
                            value={formValues['date'] === '0000-00-00' ? 'N/A' : moment(formValues['date']).format('MM/DD/YYYY')}
                        />
                    </div>
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        PM #:
                        {this.renderInput('pm_number', 'text', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        Customer:
                        {this.renderInput('customer', 'text', false)}
                    </div>
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        Salesperson:
                        {this.renderSelect('sales_id', salesOptions, false)}
                    </div>
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        CSR:
                        {this.renderSelect('csr_id', csrsOptions, false)}
                    </div>
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        Department:
                        {this.renderSelect('department_id', departmentsOptions, false)}
                    </div>
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        Chargeable:
                        {this.renderSelect('chargeable', [
                            { label: 'Select option', value: '' },
                            { label: 'Yes, charge customer', value: 1 },
                            { label: 'No, our mistake', value: 0 }
                        ], false)}
                    </div>
                    <div className="element" style={{ width: 'calc(12.5% - 15px)' }}>
                        Quantity:
                        {this.renderInput('quantity', 'text', false)}
                    </div>
                </div>
                <div className="mt-2 float-left" style={{ width: '50%' }}>
                    <div className="element mt-1" style={{ width: 'calc(100% - 15px)' }}>
                        Reason for re-work:
                        {this.renderTextarea('reason', 'text', false)}
                    </div>
                    <div className="element mt-2" style={{ width: 'calc(100% - 15px)' }}>
                        Outside cost for re-work:
                        {this.renderTextarea('outside_cost', 'text', false)}
                    </div>
                    <div className="element mt-2" style={{ width: 'calc(100% - 15px)' }}>
                        Suggestion for resolution:
                        {this.renderTextarea('suggestion', 'text', false)}
                    </div>
                    <div className="element mt-2" style={{ width: 'calc(100% - 15px)' }}>
                        {costsTable != null ? <div className="title"><b>Time to re-work</b></div> : null}
                        {costsTable}
                    </div>
                </div>
                <div id="audit-history" className="mt-3 float-left" style={{ width: '50%' }}>
                    <div className="title">Audit History</div>
                    <div className="mt-2">{this.renderAuditHistory()}</div>
                    <div className="title mt-2">Notifications</div>
                    <div>{this.renderNotificationsUsers()}</div>
                    <div className="clearfix"></div>
                    <div style={{ marginTop: 60 }}>
                        <div className="upload-button" onClick={showList}>Exit</div>
                        <div className="upload-button" onClick={resetForm}>Reset</div>
                        <div className="upload-button" onClick={saveElement}>{saveButtonLabel}</div>
                    </div>
                </div>
            </form>
        );
    }
}

export default Form;
