import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DialogComponent from './../components/DialogComponent';
import * as selectors from './../selectors';
import { hideDialog } from './../actions';
import PropTypes from 'prop-types';

class Root extends Component {
    static propTypes = {
        state: PropTypes.object,
        title: PropTypes.string,
        actions: PropTypes.array,
        modal: PropTypes.bool,
        hideDialog: PropTypes.func,
        children: PropTypes.object,
        actionsContainerStyle: PropTypes.object,
        contentStyle: PropTypes.object,
        bodyStyle: PropTypes.object,
        style: PropTypes.object,
        name: PropTypes.string
    };

    render() {
        const { state, name, title, actions, modal, hideDialog, bodyStyle, style, actionsContainerStyle,
            contentStyle, className } = this.props;

        let isOpen = false;
        if (typeof state[name] != 'undefined') {
            isOpen = state[name].open;
        }

        return (
            <DialogComponent
                title={title}
                actions={actions}
                modal={modal}
                open={isOpen}
                style={style}
                bodyStyle={bodyStyle}
                actionsContainerStyle={actionsContainerStyle}
                contentStyle={contentStyle}
                className={className}
                onRequestClose={hideDialog.bind(this)}
            >
                {this.props.children}
            </DialogComponent>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    state: selectors.getState
});

const mapDispatchToProps = (dispatch) => {
    return {
        hideDialog: () => {
            dispatch(hideDialog());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
