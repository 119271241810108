import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class CostsTable extends Component {
    static propTypes = {
        costsTable: PropTypes.array,
        hideCostsTable: PropTypes.func,
        handleUpdateCostsTable: PropTypes.func,
        isSavingCostsTable: PropTypes.bool,
        handleSaveCostsTable: PropTypes.func,
        handleAddCostsTable: PropTypes.func,
        handleDeleteCostsTable: PropTypes.func
    };

    renderData() {
        const { costsTable, handleUpdateCostsTable, handleDeleteCostsTable } = this.props;
        return costsTable.map((cost, index) => {
            return (
                <tr key={index}>
                    <td>
                        <select value={cost['type']} onChange={handleUpdateCostsTable.bind(this, index, 'type')}>
                            <option value="">Select option</option>
                            <option value="1">4/0 Printing</option>
                            <option value="2">Data Processing</option>
                            <option value="3">Variable Set Up</option>
                            <option value="4">Variable Color Laser Both Sides</option>
                            <option value="5">Pressure Seal Material</option>
                            <option value="6">Fold</option>
                            <option value="7">Seal</option>
                            <option value="8">Deliver to P.O.</option>
                        </select>
                    </td>
                    <td>
                        <input type="text" value={cost['quantity_start']}
                            onChange={handleUpdateCostsTable.bind(this, index, 'quantity_start')} />
                    </td>
                    <td>
                        <input type="text" value={cost['quantity_end']}
                            onChange={handleUpdateCostsTable.bind(this, index, 'quantity_end')} />
                    </td>
                    <td>
                        <input type="text" value={cost['cost']}
                            onChange={handleUpdateCostsTable.bind(this, index, 'cost')} />
                    </td>
                    <td>
                        <div className="action-icon">
                            <i className="fas fa-trash" onClick={handleDeleteCostsTable.bind(this, index)} />
                        </div>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { hideCostsTable, handleSaveCostsTable, isSavingCostsTable, handleAddCostsTable } = this.props;

        let data = (
            <div>
                <table className="table table-sm table-striped data-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Start Quantity</th>
                            <th>End Quantity</th>
                            <th>Cost</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                </table>
                <div className="add-button" onClick={handleAddCostsTable} style={{ width: 120, fontSize: 13 }}><i className="fas fa-plus" /> Add Row</div>
            </div>
        );

        let saveButtonLabel = 'Save';
        if (isSavingCostsTable) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveCostsTable} disabled={isSavingCostsTable}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideCostsTable}>Close</button>
        ];
        return (
            <Dialog
                name="costs-table"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default CostsTable;
