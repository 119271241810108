import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if(type === 1) {     // Insert
        elements.push(element);
    } else if(type === 2) {     // Update
        for(let i = 0; i < elements.length; i++) {
            if(elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if(type === 3) {     // Delete
        for(let i = 0; i < elements.length; i++) {
            if(elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const saveElement = (values, elements) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name', 'labor_rate', 'labor_markup_rate', 'setup_time_minor', 'setup_time_major'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, values.id === '' ? 1 : 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: {errors, errorMessages}
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: {name, value, errorMessage}
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: {column, type}
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for(let i = 0; i < elements.length; i++) {
        if(elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function setSpeedTable(speedTable) {
    return {
        type: actionTypes.SET_SPEED_TABLE,
        payload: speedTable
    };
}

export const saveSpeedTable = (speedTable, elements, configurationId) => dispatch => {
    for(let i = 0; i < speedTable.length; i++) {
        if(speedTable[i]['signatures'] === '' || speedTable[i]['width'] === '' || speedTable[i]['length'] === ''
            || speedTable[i]['time_per_m_booklets_upright'] === '' || speedTable[i]['time_per_m_booklets_oblong'] === '') {
                showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_SPEED_TABLE, payload: null });
    axios.post(apiUrls.saveSpeedTable, { configurationId, speedTable },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_SPEED_TABLE_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('speed-table'));
            showNotification('Speed Table Saved!', 'The speed table has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_SPEED_TABLE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}
