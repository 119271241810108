import React from 'react';
import numeral from 'numeral';
import FlatForm from './../Configuration/FlatForm';
import MultiPageForm from './../Configuration/MultiPageForm';
import DigitalForm from './../Configuration/DigitalForm';
import LargeFormatForm from './../Configuration/LargeFormatForm';
import DataProcessingForm from './../Configuration/DataProcessingForm';
import InkjetForm from './../Configuration/InkjetForm';
import InsertingForm from './../Configuration/InsertingForm';
import HandForm from './../Configuration/HandForm';
import OutsideServiceForm from './../Configuration/OutsideServiceForm';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

const Calculator = (props) => {
    const renderTime = (time) => {
        let remainingTime = Math.round(time);
        let hours = Math.floor(remainingTime / 60);
        remainingTime = remainingTime - hours * 60;

        let timing = [];
        if (hours > 0) {
            timing.push(numeral(hours).format('0,0') + ' hr');
        }
        if (remainingTime > 0 || parseInt(time) === 0) {
            timing.push(numeral(remainingTime).format('0,0') + ' min');
        }

        return timing.join(' ');
    };

    const renderDepartmentTime = (name, label, elements) => {
        let totalDepartmentTime = 0;
        const summaryElements = elements.map((element) => {
            totalDepartmentTime += element.totalTime;
            const tooltipHtml = (
                <Tooltip html={element.tooltipInfo} position="top" arrow size="small " className="action-tooltip">
                    <span className="more-info"><i className="fas fa-info-circle" /></span>
                </Tooltip>
            );

            return (
                <div className="element" key={element.id}>
                    <div className="name">{element.name} {tooltipHtml}</div>
                    <div className="quantity">{numeral(element.quantity).format('0,0')}</div>
                    <div className="time">{renderTime(element.totalTime)}</div>
                </div>
            );
        });

        if (summaryElements.length === 0) {
            return null;
        }

        return (
            <div>
                <h4>{label}</h4>
                <div className="elements">
                    <div className="element">
                        <div className="name"><b>Name</b></div>
                        <div className="quantity"><b>Quantity</b></div>
                        <div className="time"><b>Time</b></div>
                    </div>
                    {summaryElements}
                    <div className="element">
                        <div className="name"></div>
                        <div className="quantity">Total:</div>
                        <div className="time">{renderTime(totalDepartmentTime)}</div>
                    </div>
                </div>
                <hr />
            </div>
        );
    };

    let totalTime = 0;
    for (const key in props.summary) {
        const department = props.summary[key];
        for (const departmentElement of department) {
            totalTime += departmentElement.totalTime;
        }
    }

    const renderDepartmentCost = (name, label) => {
        let totalCost = 0;
        let accountingElements = props.accounting[name].items.map((item) => {
            if (!item.invoice) {
                return null;
            }

            const itemTotal = parseFloat(item.quantity) * parseFloat(item.price);
            totalCost += itemTotal;
            return (
                <div className="element" key={item.id}>
                    <div className="description">{item.description}</div>
                    <div className="quantity">{numeral(item.quantity).format('0,0')}</div>
                    <div className="price">{numeral(item.price).format('$0,0.00')}</div>
                    <div className="unit">{item.unit}</div>
                    <div className="total">{numeral(itemTotal).format('$0,0.00')}</div>
                </div>
            );
        });
        accountingElements = accountingElements.filter((element) => element !== null);

        if (accountingElements.length === 0) {
            return null;
        }

        return (
            <div>
                <h4>{label}</h4>
                <div className="accounting-elements">
                    <div className="element">
                        <div className="description"><b>Description</b></div>
                        <div className="quantity"><b>Quantity</b></div>
                        <div className="price"><b>Price</b></div>
                        <div className="unit"><b>Unit</b></div>
                        <div className="total"><b>Total</b></div>
                    </div>
                    {accountingElements}
                    <div className="element">
                        <div className="description"></div>
                        <div className="quantity"></div>
                        <div className="price"></div>
                        <div className="unit">Total:</div>
                        <div className="total">{numeral(totalCost).format('$0,0.00')}</div>
                    </div>
                </div>
                <hr />
            </div>
        );
    };

    let totalCost = 0;
    for (const key in props.accounting) {
        const department = props.accounting[key];
        for (const item of department.items) {
            if (item.invoice && item.quantity !== '' && item.price !== '') {
                totalCost += parseFloat(item.quantity) * parseFloat(item.price);
            }
        }
    }

    return (
        <div className="calculator-container">
            <div className="calculator">
                <div className="column1">
                    <FlatForm
                        productType={1}
                        show={props.show}
                        dptBindery={props.data.dptBindery}
                        dptOutsideService={props.data.dptOutsideService}
                        setSummaryElement={props.setSummaryElement}
                        setQuantity={props.setQuantity}
                        resetSummaryDepartments={props.resetSummaryDepartments}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        resetEstimateDepartment={props.resetEstimateDepartment}
                        accounting={props.accounting.flat}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <MultiPageForm
                        productType={2}
                        show={props.show}
                        dptBindery={props.data.dptBindery}
                        dptOutsideService={props.data.dptOutsideService}
                        setSummaryElement={props.setSummaryElement}
                        setQuantity={props.setQuantity}
                        resetSummaryDepartments={props.resetSummaryDepartments}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        resetEstimateDepartment={props.resetEstimateDepartment}
                        accounting={props.accounting.multiPage}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <DigitalForm
                        productType={3}
                        show={props.show}
                        dptBindery={props.data.dptBindery}
                        dptOutsideService={props.data.dptOutsideService}
                        setSummaryElement={props.setSummaryElement}
                        setQuantity={props.setQuantity}
                        resetSummaryDepartments={props.resetSummaryDepartments}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        resetEstimateDepartment={props.resetEstimateDepartment}
                        accounting={props.accounting.digital}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <LargeFormatForm
                        productType={4}
                        show={props.show}
                        setSummaryElement={props.setSummaryElement}
                        setQuantity={props.setQuantity}
                        resetSummaryDepartments={props.resetSummaryDepartments}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        resetEstimateDepartment={props.resetEstimateDepartment}
                        accounting={props.accounting.largeFormat}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <DataProcessingForm
                        show={props.show}
                        elements={props.data.dptDataProcessing}
                        summary={props.summary.dptDataProcessing}
                        checkElement={props.checkElement}
                        setQuantity={props.setQuantity}
                        setSummaryElement={props.setSummaryElement}
                        removeSummaryElement={props.removeSummaryElement}
                        setDifficultyLevelDp={props.setDifficultyLevelDp}
                        setPresortDp={props.setPresortDp}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        setDepartmentMainQuantity={props.setDepartmentMainQuantity}
                        accounting={props.accounting.dataProcessing}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <InkjetForm
                        show={props.show}
                        elements={props.data.dptInkjet}
                        materials={props.data.dptInkjetMaterial}
                        summary={props.summary.dptInkjet}
                        checkElement={props.checkElement}
                        setQuantity={props.setQuantity}
                        setSummaryElement={props.setSummaryElement}
                        removeSummaryElement={props.removeSummaryElement}
                        setInchesLong={props.setInchesLong}
                        setWaferSeals={props.setWaferSeals}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        accounting={props.accounting.inkjet}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <InsertingForm
                        show={props.show}
                        elements={props.data.dptInserting}
                        summary={props.summary.dptInserting}
                        checkElement={props.checkElement}
                        setQuantity={props.setQuantity}
                        setSummaryElement={props.setSummaryElement}
                        removeSummaryElement={props.removeSummaryElement}
                        setInserts={props.setInserts}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        accounting={props.accounting.inserting}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <HandForm
                        show={props.show}
                        elements={props.data.dptHand}
                        summary={props.summary.dptHand}
                        checkElement={props.checkElement}
                        setQuantity={props.setQuantity}
                        setSummaryElement={props.setSummaryElement}
                        removeSummaryElement={props.removeSummaryElement}
                        setInserts={props.setInserts}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        accounting={props.accounting.hand}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                    <OutsideServiceForm
                        show={props.show}
                        elements={props.data.dptOutsideService}
                        summary={props.summary.dptOutsideService}
                        checkElement={props.checkElement}
                        setQuantity={props.setQuantity}
                        setSummaryElement={props.setSummaryElement}
                        removeSummaryElement={props.removeSummaryElement}
                        toggleShow={props.toggleShow}
                        resetDepartment={props.resetDepartment}
                        accounting={props.accounting.outsideService}
                        changeAccountField={props.changeAccountField}
                        changeAccountItem={props.changeAccountItem}
                        addAccountItem={props.addAccountItem}
                        removeAccountItem={props.removeAccountItem}
                    />
                </div>
                <div className="summary">
                    <h3>Summary</h3>
                    {renderDepartmentTime('press', 'Press', props.summary.dptPress)}
                    {renderDepartmentTime('digital', 'Digital', props.summary.dptDigital)}
                    {renderDepartmentTime('largeFormat', 'Large Format', props.summary.dptLargeFormat)}
                    {renderDepartmentTime('bindery', 'Bindery', props.summary.dptBindery)}
                    {renderDepartmentTime('dataProcessing', 'Data Processing', props.summary.dptDataProcessing)}
                    {renderDepartmentTime('inkjet', 'Inkjet', props.summary.dptInkjet)}
                    {renderDepartmentTime('inserting', 'Inserting', props.summary.dptInserting)}
                    {renderDepartmentTime('hand', 'Hand', props.summary.dptHand)}
                    {renderDepartmentTime('outsideService', 'Outside Services', props.summary.dptOutsideService)}
                    <div className="total-time">
                        Total Time: {renderTime(totalTime)}
                    </div>
                    <h3 className="invoice-h3">Invoice</h3>
                    {renderDepartmentCost('flat', 'Flat')}
                    {renderDepartmentCost('multiPage', 'Multi-Page')}
                    {renderDepartmentCost('digital', 'Digital')}
                    {renderDepartmentCost('largeFormat', 'Large Format')}
                    {renderDepartmentCost('dataProcessing', 'Data Processing')}
                    {renderDepartmentCost('inkjet', 'Inkjet')}
                    {renderDepartmentCost('inserting', 'Inserting')}
                    {renderDepartmentCost('hand', 'Hand')}
                    {renderDepartmentCost('outsideService', 'Outside')}
                    <div className="total-cost">
                        Total Cost: {numeral(totalCost).format('$0,0.00')}
                    </div>
                    <div className="save-button text-right">
                        {/* <button onClick={props.showSaveInvoiceModal}>Save Invoice</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

Calculator.propTypes = {

}

export default Calculator;