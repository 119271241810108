import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleOrdersClick: PropTypes.func,
        handleQuotesClick: PropTypes.func,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleDayChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleShowSpeedTable: PropTypes.func
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, isFetchingElements, handleShowForm, selectedPage, elementsPerPage,
            sortColumn, sortType, handleShowSpeedTable } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="8"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No Results</td>
                </tr>
            );
        }

        let sortedElements = elements.sort((a, b) => {
            if (typeof a[sortColumn] === 'number') {
                if (sortType === 'asc') {
                    if (a[sortColumn] < b[sortColumn]) return -1;
                    if (a[sortColumn] > b[sortColumn]) return 1;
                    if (a['id'] < b['id']) return -1;
                    if (a['id'] > b['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn] < a[sortColumn]) return -1;
                    if (b[sortColumn] > a[sortColumn]) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            } else {
                if (sortType === 'asc') {
                    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) return -1;
                    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn].toLowerCase() < a[sortColumn].toLowerCase()) return -1;
                    if (b[sortColumn].toLowerCase() > a[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            }
        });

        let data = [];
        let startIndex = (selectedPage - 1) * elementsPerPage;
        for (let i = startIndex; i < sortedElements.length && i < startIndex + elementsPerPage; i++) {
            data.push(
                <tr key={i}>
                    <td>{sortedElements[i].id}</td>
                    <td>{sortedElements[i].name}</td>
                    <td>{numeral(sortedElements[i].labor_rate).format('$0,0.00')}</td>
                    <td>{numeral(sortedElements[i].labor_markup_rate).format('0.[00]%')}</td>
                    <td>{sortedElements[i].setup_time} hrs.</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, sortedElements[i].id, null)} />
                            </Tooltip>
                            <Tooltip title="Speed Table" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="far fa-clock" onClick={handleShowSpeedTable.bind(this, sortedElements[i].id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    render() {
        const { elements, totalPages, selectedPage, elementsPerPage } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('ID', 'id', { width: '8%' })}
                                {this.renderTableHeader('Name', 'name', { width: 'calc(32% - 120px)' })}
                                {this.renderTableHeader('Labor Rate', 'labor_rate', { width: '20%' })}
                                {this.renderTableHeader('Labor Rate Markup', 'labor_markup_rate', { width: '20%' })}
                                {this.renderTableHeader('Setup Time', 'setup_time', { width: '20%' })}
                                <th style={{ width: 120 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={elements.length}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
