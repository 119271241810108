import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        statuses: PropTypes.array
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle">Customer Data</div>
                    <div className="w-100"></div>
                    {this.renderInput('Name', 'name', 'text', true)}
                    {this.renderInput('Company', 'company', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Shipping Method', 'shipping_method', 'text', true)}
                    {this.renderInput('Notes', 'notes', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Sales Type', 'sales_type', 'text', true)}
                    {this.renderInput('Discount', 'discount', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Reseller ID', 'reseller_id', 'text', false)}
                    {this.renderInput('Tax Code', 'tax_code', 'text', false)}
                    <div className="w-100"></div>
                    <div className="subtitle">Billing Information</div>
                    <div className="w-100"></div>
                    {this.renderInput('Name', 'billing_name', 'text', true)}
                    {this.renderInput('Company', 'billing_company', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Attention', 'billing_attention', 'text', false)}
                    {this.renderInput('Street', 'billing_street', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('City', 'billing_city', 'text', true)}
                    {this.renderInput('State', 'billing_state', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('ZIP', 'billing_zip', 'text', false)}
                    {this.renderInput('Country', 'billing_country', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Phone', 'billing_phone', 'text', false, 'col-6')}
                    <div className="w-100"></div>
                    <div className="subtitle">Shipping Information</div>
                    <div className="w-100"></div>
                    {this.renderInput('Name', 'shipping_name', 'text', true)}
                    {this.renderInput('Company', 'shipping_company', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Attention', 'shipping_attention', 'text', false)}
                    {this.renderInput('Street', 'shipping_street', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('City', 'shipping_city', 'text', true)}
                    {this.renderInput('State', 'shipping_state', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('ZIP', 'shipping_zip', 'text', false)}
                    {this.renderInput('Country', 'shipping_country', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Phone', 'shipping_phone', 'text', false, 'col-6')}
                    <div className="w-100"></div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
