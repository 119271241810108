import React, { Component } from 'react';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    renderInput(label, name, type, required, className = '') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={'input-container ' + className} key={name}>
                <div className="label-input"><label>{label} {required ? <span className="required">*</span> : null}</label></div>
                <div className="field-input">
                    <Input
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                    />
                </div>
            </div>
        );
    }

    renderSelect(label, name, options, required, className = '') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={'input-container ' + className} key={name}>
                <div className="label-input"><label>{label} {required ? <span className="required">*</span> : null}</label></div>
                <div className="field-input">
                    <Select
                        name={name}
                        required={required}
                        options={options}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="subtitle">Press Data</div>
                <div className="card mb-2 costing-information">
                    <div className="card-header p-2">Costing</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderInput('Name', 'name', 'text', true)}
                        {this.renderInput('Labor Rate', 'labor_rate', 'decimal', true, 'half-field')}
                        {this.renderInput('Labor Markup', 'labor_markup_rate', 'decimal', true, 'half-field')}
                        {this.renderInput('Setup Cost', 'setup_cost', 'decimal', true, 'half-field')}
                        {/* {this.renderInput('Wash Up Cost', 'wash_up_cost', 'decimal', true, 'half-field')} */}
                        {this.renderInput('Plate Change Cost', 'plate_change_cost', 'decimal', true, 'half-field')}
                        {this.renderInput('Plate Cost', 'plate_cost', 'decimal', true, 'half-field')}
                    </div>
                </div>
                <div className="card mb-2 running-specifications">
                    <div className="card-header p-2">Running Specifications</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderInput('Ink Units', 'ink_units', 'number', true, 'half-field')}
                        {this.renderInput('Gripper Margin', 'gripper_margin', 'decimal', true, 'half-field')}
                        {this.renderInput('Color Bar Margin', 'color_bar_margin', 'decimal', true, 'half-field')}
                        {this.renderInput('Setup Time (hrs)', 'setup_time', 'decimal', true, 'half-field')}
                        {this.renderInput('Left/Right/Tail Trim', 'left_right_tail_trim', 'decimal', true, 'half-field')}
                        {this.renderSelect('Perfecting', 'is_perfecting', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'half-field')}
                        {this.renderSelect('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'half-field')}
                        {this.renderSelect('Default', 'is_default', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'half-field')}
                    </div>
                </div>
                <div className="card mb-2 paper-specifications">
                    <div className="card-header p-2">Paper Specifications</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderInput('Max. Paper Width', 'max_paper_width', 'decimal', true, 'half-field')}
                        {this.renderInput('Max. Paper Length', 'max_paper_length', 'decimal', true, 'half-field')}
                        {this.renderInput('Min. Paper Width', 'min_paper_width', 'decimal', true, 'half-field')}
                        {this.renderInput('Min. Paper Length', 'min_paper_length', 'decimal', true, 'half-field')}
                    </div>
                </div>
                <div className="card mb-2 spoilage-specifications">
                    <div className="card-header p-2">Spoilage Specifications</div>
                    <div className="card-body p-2 pt-0">
                        {this.renderInput('Flat Makeready (sheets)', 'makeready_spoilage_flat', 'number', true, 'half-field')}
                        {this.renderInput('Flat Running (%)', 'running_spoilage_flat', 'decimal', true, 'half-field')}
                        {this.renderInput('W&T Makeready (sheets)', 'makeready_spoilage_wt', 'number', true, 'half-field')}
                        {this.renderInput('W&T Running (%)', 'running_spoilage_wt', 'decimal', true, 'half-field')}
                        {this.renderInput('SW Makeready (sheets)', 'makeready_spoilage_sw', 'number', true, 'half-field')}
                        {this.renderInput('SW Running (%)', 'running_spoilage_sw', 'decimal', true, 'half-field')}
                    </div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
                className="large-dialog"
            >
                <div className="form-container form-modal-container presses-form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
