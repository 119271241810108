import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from './../../common/components/Select';
import numeral from 'numeral';
import moment from 'moment';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        data: PropTypes.object,
        handleUpdateFormDateValue: PropTypes.func
    };

    renderInput(label, name, type, required, readOnly = false, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    readOnly={readOnly}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, data,
            formValues, handleUpdateFormDateValue } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let sizesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['sizes'].length; i++) {
            sizesOptions.push({ label: data['sizes'][i]['name'], value: data['sizes'][i]['id'] });
        }
        sizesOptions.push({ label: '+ Add', value: -1 });

        let stocksOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['stocks'].length; i++) {
            stocksOptions.push({ label: data['stocks'][i]['name'], value: data['stocks'][i]['id'] });
        }
        stocksOptions.push({ label: '+ Add', value: -1 });

        let machinesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data['machines'].length; i++) {
            machinesOptions.push({ label: data['machines'][i]['name'], value: data['machines'][i]['id'] });
        }
        machinesOptions.push({ label: '+ Add', value: -1 });

        let ac = 0;
        if (formValues['landrys'] !== '' && formValues['larry'] !== '') {
            ac = parseInt(formValues['landrys']) - parseInt(formValues['larry']);
        }

        let robert = ac * 0.05;

        let perUnit = 0;
        if (formValues['landrys'] !== '' && formValues['quantity'] !== '') {
            perUnit = parseInt(formValues['landrys']) / parseInt(formValues['quantity']);
        }

        let acPerUnit = 0;
        if (formValues['quantity'] !== '') {
            acPerUnit = parseInt(ac) / parseInt(formValues['quantity']);
        }

        let larryPerUnit = 0;
        if (formValues['larry'] !== '' && formValues['quantity'] !== '') {
            larryPerUnit = parseInt(formValues['larry']) / parseInt(formValues['quantity']);
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">Invoice Data</div>
                    <div className="w-100"></div>
                    <div className="col">
                        <label>Date <span className="required">*</span></label>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={handleUpdateFormDateValue.bind(this, 'date', true)}
                            value={formValues['date'] === '' ? '' : moment(formValues['date']).format('MM/DD/YYYY')}
                        />
                    </div>
                    {this.renderInput('Description', 'description', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Size', 'landrys_invoice_size_id', sizesOptions, true)}
                    {this.renderSelect('Stock', 'landrys_invoice_stock_id', stocksOptions, true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Ink', 'ink', [
                        { label: 'Select option', value: '' },
                        { label: '4/0', value: 1 },
                        { label: '4/1', value: 3 },
                        { label: '4/4', value: 2 },
                        { label: '1/1', value: 4 },
                        { label: '1/0', value: 5 },
                        { label: '2/0', value: 6 },
                        { label: '5/5', value: 7 }
                    ], true)}
                    {this.renderInput('Quantity', 'quantity', 'number', true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Machine', 'landrys_invoice_machine_id', machinesOptions, true)}
                    {this.renderInput('Landry\'s', 'landrys', 'decimal', true)}
                    <div className="w-100"></div>
                    <div className="col">
                        <label>Landry's/Unit <span className="required">*</span></label>
                        <input type="text" value={numeral(perUnit).format('0.[00]')} readOnly />
                    </div>
                    {this.renderInput('Larry', 'larry', 'decimal', true)}
                    <div className="w-100"></div>
                    <div className="col">
                        <label>Larry/Unit <span className="required">*</span></label>
                        <input type="text" value={numeral(larryPerUnit).format('0.[00]')} readOnly />
                    </div>
                    <div className="col">
                        <label>AC <span className="required">*</span></label>
                        <input type="text" value={numeral(ac).format('0.[00]')} readOnly />
                    </div>
                    <div className="w-100"></div>
                    <div className="col">
                        <label>AC/Unit <span className="required">*</span></label>
                        <input type="text" value={numeral(acPerUnit).format('0.[00]')} readOnly />
                    </div>
                    <div className="col">
                        <label>Robert <span className="required">*</span></label>
                        <input type="text" value={numeral(robert).format('0.[00]')} readOnly />
                    </div>
                    <div className="w-100"></div>
                    {this.renderInput('PM #', 'pm', 'text', true)}
                    {this.renderInput('PS #', 'ps', 'text', true)}
                    <div className="w-100"></div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
