import axios from 'axios';
import * as types from './DptInkjet.types';
import * as selectors from './DptInkjet.selectors';
import * as dialog from '../common/dialog';
import {
    showDataSavedNotification,
    showCompleteDataNotification,
    validateAuthInResponse,
} from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = () => (dispatch, getState) => {
    const pagination = selectors.getPagination(getState());

    dispatch({ type: types.FETCH_DATA, payload: null });
    axios.get(apiUrl + '/dpt-inkjet?active-page=' + pagination.activePage + '&elements-per-page=' + pagination.elementsPerPage,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_DATA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_DATA_ERROR));
        });
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const save = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());

    let attributes = [
        'name',
        'ips',
        'setup_time',
        'two_wafer_seals_reduction',
        'three_wafer_seals_reduction',
        'active',
    ];
    for (let i = 0; i < attributes.length; i++) {
        if (fields[attributes[i]] === undefined || fields[attributes[i]] === '') {
            showCompleteDataNotification();
            return;
        }
    }

    dispatch({ type: types.SAVE, payload: null });
    let promise;
    if (fields.id === undefined) {
        promise = axios.post(apiUrl + '/dpt-inkjet', fields, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    } else {
        promise = axios.put(apiUrl + '/dpt-inkjet/' + fields.id, fields, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } });
    }

    promise
        .then((response) => {
            dispatch({ type: types.SAVE_SUCCESS, payload: response.data });
            showDataSavedNotification();
            dispatch(dialog.actions.hideDialog('form-modal'));
            dispatch(fetchData());
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SAVE_ERROR));
        });
}

export const fetchElement = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ELEMENT, payload: null });
    axios.get(apiUrl + '/dpt-inkjet/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ELEMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ELEMENT_ERROR));
        });
}

export const resetForm = () => (dispatch, getState) => {
    dispatch({
        type: types.RESET_FORM,
        payload: null,
    });
}

export const setActivePage = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchData());
    });
}