import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            quantity: '',
            shipFirstName: '',
            shipLastName: '',
            shipAddress1: '',
            shipAddress2: '',
            shipCity: '',
            shipState: '',
            shipZip: '',
            shipPhone: '',
            shipEmail: '',
            shipNotes: '',
            billFirstName: '',
            billLastName: '',
            billAddress1: '',
            billAddress2: '',
            billCity: '',
            billState: '',
            billZip: '',
            billPhone: '',
            billEmail: '',
            billPo: '',
            billDepartment: '',
            billCall: '',
            billNotes: '',
            variables: '',
            code: '',
            pdf_url: '',
            created_at: '',
            status: '',
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false,
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'id',
        type: 'desc'
    },
    filter: {
        fields: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['elements', 'processedElements'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                quantity: '',
                shipFirstName: '',
                shipLastName: '',
                shipAddress1: '',
                shipAddress2: '',
                shipCity: '',
                shipState: '',
                shipZip: '',
                shipPhone: '',
                shipEmail: '',
                shipNotes: '',
                billFirstName: '',
                billLastName: '',
                billAddress1: '',
                billAddress2: '',
                billCity: '',
                billState: '',
                billZip: '',
                billPhone: '',
                billEmail: '',
                billPo: '',
                billDepartment: '',
                billCall: '',
                billNotes: '',
                variables: '',
                code: '',
                pdf_url: '',
                created_at: '',
                status: '',
                referenceFiles: [],
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'quantity'], action.payload.quantity)
                .setIn(['form', 'formValues', 'shipFirstName'], action.payload.shipFirstName)
                .setIn(['form', 'formValues', 'shipLastName'], action.payload.shipLastName)
                .setIn(['form', 'formValues', 'shipAddress1'], action.payload.shipAddress1)
                .setIn(['form', 'formValues', 'shipAddress2'], action.payload.shipAddress2)
                .setIn(['form', 'formValues', 'shipCity'], action.payload.shipCity)
                .setIn(['form', 'formValues', 'shipState'], action.payload.shipState)
                .setIn(['form', 'formValues', 'shipZip'], action.payload.shipZip)
                .setIn(['form', 'formValues', 'shipPhone'], action.payload.shipPhone)
                .setIn(['form', 'formValues', 'shipEmail'], action.payload.shipEmail)
                .setIn(['form', 'formValues', 'shipNotes'], action.payload.shipNotes)
                .setIn(['form', 'formValues', 'billFirstName'], action.payload.billFirstName)
                .setIn(['form', 'formValues', 'billLastName'], action.payload.billLastName)
                .setIn(['form', 'formValues', 'billAddress1'], action.payload.billAddress1)
                .setIn(['form', 'formValues', 'billAddress2'], action.payload.billAddress2)
                .setIn(['form', 'formValues', 'billCity'], action.payload.billCity)
                .setIn(['form', 'formValues', 'billState'], action.payload.billState)
                .setIn(['form', 'formValues', 'billZip'], action.payload.billZip)
                .setIn(['form', 'formValues', 'billPhone'], action.payload.billPhone)
                .setIn(['form', 'formValues', 'billEmail'], action.payload.billEmail)
                .setIn(['form', 'formValues', 'billPo'], action.payload.billPo)
                .setIn(['form', 'formValues', 'billDepartment'], action.payload.billDepartment)
                .setIn(['form', 'formValues', 'billCall'], action.payload.billCall)
                .setIn(['form', 'formValues', 'billNotes'], action.payload.billNotes)
                .setIn(['form', 'formValues', 'variables'], action.payload.variables)
                .setIn(['form', 'formValues', 'code'], action.payload.code)
                .setIn(['form', 'formValues', 'pdf_url'], action.payload.pdf_url)
                .setIn(['form', 'formValues', 'created_at'], action.payload.created_at)
                .setIn(['form', 'formValues', 'status'], action.payload.status)
                .setIn(['form', 'formValues', 'referenceFiles'], action.payload.referenceFiles);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20));
            break;

        default:
            break;
    }

    return state.toJS();
}
