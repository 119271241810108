export const SET_FIELD = 'DPT_INSERTING/SET_FIELD';
export const FETCH_DATA = 'DPT_INSERTING/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'DPT_INSERTING/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'DPT_INSERTING/FETCH_DATA_ERROR';
export const SAVE = 'DPT_INSERTING/SAVE';
export const SAVE_SUCCESS = 'DPT_INSERTING/SAVE_SUCCESS';
export const SAVE_ERROR = 'DPT_INSERTING/SAVE_ERROR';
export const FETCH_ELEMENT = 'DPT_INSERTING/FETCH_ELEMENT';
export const FETCH_ELEMENT_SUCCESS = 'DPT_INSERTING/FETCH_ELEMENT_SUCCESS';
export const FETCH_ELEMENT_ERROR = 'DPT_INSERTING/FETCH_ELEMENT_ERROR';
export const SET_ACTIVE_PAGE = 'DPT_INSERTING/SET_ACTIVE_PAGE';
export const RESET_FORM = 'DPT_INSERTING/RESET_FORM';