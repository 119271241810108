import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MultiPageConfiguration extends Component {
    static propTypes = {
        configuration: PropTypes.array,
        hideConfiguration: PropTypes.func,
        handleUpdateConfiguration: PropTypes.func,
        isSavingForm: PropTypes.bool,
        handleSaveElement: PropTypes.func,
        handleAddConfiguration: PropTypes.func,
        handleDeleteConfiguration: PropTypes.func
    };

    hasCover() {
        const { configurationJson } = this.props;
        return [2, 5, 7, 9].includes(parseInt(configurationJson['product_options']['multi_page_type']['default']));
    }

    renderProductCategories(type) {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [<option value="">Select Option</option>];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === type) {
                selectOptions.push(<option value={options['productCategories'][i]['value']}>{options['productCategories'][i]['label']}</option>);
            }
        }

        let show = configurationJson['product_options']['product_category_id']['show'];
        let value = configurationJson['product_options']['product_category_id']['default'];
        let lock = configurationJson['product_options']['product_category_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'product_category_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'product_category_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'product_category_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="product-category">Product Category:</label></div>
                <select id="product-category" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderProductDescription() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_description']['show'];
        let value = configurationJson['product_description']['default'];
        let lock = configurationJson['product_description']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_description', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_description', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_description', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="product-description">Product Description:</label></div>
                <input id="product-description" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPublicationType() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['multi_page_type']['show'];
        let value = configurationJson['product_options']['multi_page_type']['default'];
        let lock = configurationJson['product_options']['multi_page_type']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'multi_page_type', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'multi_page_type', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'multi_page_type', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="multi-page-type">Publication Type:</label></div>
                <select id="multi-page-type" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Self-Cover</option>
                    <option value="2">Plus-Cover</option>
                    <option value="3">Perfect Bind</option>
                    <option value="4">Staple Binding No Cover</option>
                    <option value="5">Staple Binding With Cover</option>
                    <option value="6">Coil Binding No Cover</option>
                    <option value="7">Coil Binding With Cover</option>
                    <option value="8">Spiral Binding No Cover</option>
                    <option value="9">Spiral Binding With Cover</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPages() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['pages']['show'];
        let value = configurationJson['product_options']['pages']['default'];
        let lock = configurationJson['product_options']['pages']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'pages', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'pages', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'pages', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="pages">Pages:</label></div>
                <input id="pages" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderBindingEdge() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['binding_edge']['show'];
        let value = configurationJson['product_options']['binding_edge']['default'];
        let lock = configurationJson['product_options']['binding_edge']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'binding_edge', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'binding_edge', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'binding_edge', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="binding-edge">Binding Edge:</label></div>
                <select id="binding-edge" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Width</option>
                    <option value="2">Height</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoverPresses() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let selectOptions = [];
        for (let i = 0; i < options['presses'].length; i++) {
            selectOptions.push(<option value={options['presses'][i]['value']}>{options['presses'][i]['label']}</option>);
        }

        let show = configurationJson['press_options']['cover_press_id']['show'];
        let value = configurationJson['press_options']['cover_press_id']['default'];
        let lock = configurationJson['press_options']['cover_press_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['press_options', 'cover_press_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['press_options', 'cover_press_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['press_options', 'cover_press_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="cover-press-id">Press Cover:</label></div>
                <select id="cover-press-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoverRunningStyle() {
        const { configurationJson, handleUpdateConfiguration, information } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let selectOptions = [<option value="">Select Option</option>];
        if (configurationJson['press_options']['cover_press_id']['default'] !== '') {
            for (let i = 0; i < information['presses'].length; i++) {
                const press = information['presses'][i];
                if (press['id'] === configurationJson['press_options']['cover_press_id']['default']) {
                    if (parseInt(press['is_perfecting'])) {
                        selectOptions.push(<option value="Perfecting">Perfecting</option>);
                    } else {
                        selectOptions.push(<option value="SW">SheetWise</option>);
                        selectOptions.push(<option value="WT">Work and Turn</option>);
                    }
                }
            }
        }

        let show = configurationJson['press_options']['cover_running_style']['show'];
        let value = configurationJson['press_options']['cover_running_style']['default'];
        let lock = configurationJson['press_options']['cover_running_style']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['press_options', 'cover_running_style', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['press_options', 'cover_running_style', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['press_options', 'cover_running_style', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="cover-running-style">Running Style Cover:</label></div>
                <select id="cover-running-style" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPresses() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['presses'].length; i++) {
            selectOptions.push(<option value={options['presses'][i]['value']}>{options['presses'][i]['label']}</option>);
        }

        let show = configurationJson['press_options']['press_id']['show'];
        let value = configurationJson['press_options']['press_id']['default'];
        let lock = configurationJson['press_options']['press_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['press_options', 'press_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['press_options', 'press_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['press_options', 'press_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="press-id">Press Interior:</label></div>
                <select id="press-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderRunningStyle() {
        const { configurationJson, handleUpdateConfiguration, information } = this.props;

        let selectOptions = [<option value="">Select Option</option>];
        if (configurationJson['press_options']['press_id']['default'] !== '') {
            for (let i = 0; i < information['presses'].length; i++) {
                const press = information['presses'][i];
                if (press['id'] === configurationJson['press_options']['press_id']['default']) {
                    if (parseInt(press['is_perfecting'])) {
                        selectOptions.push(<option value="Perfecting">Perfecting</option>);
                    } else {
                        selectOptions.push(<option value="SW">SheetWise</option>);
                        selectOptions.push(<option value="WT">Work and Turn</option>);
                    }
                }
            }
        }

        let show = configurationJson['press_options']['running_style']['show'];
        let value = configurationJson['press_options']['running_style']['default'];
        let lock = configurationJson['press_options']['running_style']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['press_options', 'running_style', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['press_options', 'running_style', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['press_options', 'running_style', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="running-style">Running Style Interior:</label></div>
                <select id="running-style" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderSecondPresses() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['presses'].length; i++) {
            selectOptions.push(<option value={options['presses'][i]['value']}>{options['presses'][i]['label']}</option>);
        }

        let show = configurationJson['press_options']['second_press_id']['show'];
        let value = configurationJson['press_options']['second_press_id']['default'];
        let lock = configurationJson['press_options']['second_press_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['press_options', 'second_press_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['press_options', 'second_press_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['press_options', 'second_press_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="second-press-id">Press Leftover:</label></div>
                <select id="second-press-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderSecondRunningStyle() {
        const { configurationJson, handleUpdateConfiguration, information } = this.props;

        let selectOptions = [<option value="">Select Option</option>];
        if (configurationJson['press_options']['second_press_id']['default'] !== '') {
            for (let i = 0; i < information['presses'].length; i++) {
                const press = information['presses'][i];
                if (press['id'] === configurationJson['press_options']['second_press_id']['default']) {
                    if (parseInt(press['is_perfecting'])) {
                        selectOptions.push(<option value="Perfecting">Perfecting</option>);
                    } else {
                        selectOptions.push(<option value="SW">SheetWise</option>);
                        selectOptions.push(<option value="WT">Work and Turn</option>);
                    }
                }
            }
        }

        let show = configurationJson['press_options']['second_running_style']['show'];
        let value = configurationJson['press_options']['second_running_style']['default'];
        let lock = configurationJson['press_options']['second_running_style']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['press_options', 'second_running_style', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['press_options', 'second_running_style', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['press_options', 'second_running_style', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="second-running-style">Running Style Leftover:</label></div>
                <select id="second-running-style" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFinishWidth() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['finish_width']['show'];
        let value = configurationJson['product_options']['finish_width']['default'];
        let lock = configurationJson['product_options']['finish_width']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'finish_width', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'finish_width', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'finish_width', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="finish-width">Finish Width:</label></div>
                <input id="finish-width" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFinishHeight() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['finish_height']['show'];
        let value = configurationJson['product_options']['finish_height']['default'];
        let lock = configurationJson['product_options']['finish_height']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'finish_height', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'finish_height', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'finish_height', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="finish-height">Finish Height:</label></div>
                <input id="finish-height" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderInteriorInks() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['interior_inks']['show'];
        let value = configurationJson['product_options']['interior_inks']['default'];
        let lock = configurationJson['product_options']['interior_inks']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'interior_inks', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'interior_inks', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'interior_inks', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="interior-inks">Interior Inks:</label></div>
                <select id="interior-inks" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderFrontInks() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let show = configurationJson['product_options']['front_inks']['show'];
        let value = configurationJson['product_options']['front_inks']['default'];
        let lock = configurationJson['product_options']['front_inks']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'front_inks', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'front_inks', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'front_inks', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="front-inks">Cover Front Inks:</label></div>
                <select id="front-inks" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderBackInks() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let show = configurationJson['product_options']['back_inks']['show'];
        let value = configurationJson['product_options']['back_inks']['default'];
        let lock = configurationJson['product_options']['back_inks']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'back_inks', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'back_inks', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'back_inks', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="back-inks">Cover Back Inks:</label></div>
                <select id="back-inks" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderBleed() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['product_options']['bleed']['show'];
        let value = configurationJson['product_options']['bleed']['default'];
        let lock = configurationJson['product_options']['bleed']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['product_options', 'bleed', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['product_options', 'bleed', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['product_options', 'bleed', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="bleed">Bleed:</label></div>
                <select id="bleed" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="0">None</option>
                    <option value="0.125">1/8</option>
                    <option value="0.25">1/4</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoverPaperCategories() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let selectOptions = [];
        for (let i = 0; i < options['coverPaperCategories'].length; i++) {
            selectOptions.push(<option value={options['coverPaperCategories'][i]['value']}>{options['coverPaperCategories'][i]['label']}</option>);
        }

        let show = configurationJson['paper_options']['cover_paper_category_id']['show'];
        let value = configurationJson['paper_options']['cover_paper_category_id']['default'];
        let lock = configurationJson['paper_options']['cover_paper_category_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'cover_paper_category_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'cover_paper_category_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'cover_paper_category_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="cover-paper-category-id">Paper Category for Cover:</label></div>
                <select id="cover-paper-category-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoverPaperWeights() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let selectOptions = [];
        for (let i = 0; i < options['coverPaperWeights'].length; i++) {
            selectOptions.push(<option value={options['coverPaperWeights'][i]['value']}>{options['coverPaperWeights'][i]['label']}</option>);
        }

        let show = configurationJson['paper_options']['cover_paper_weight']['show'];
        let value = configurationJson['paper_options']['cover_paper_weight']['default'];
        let lock = configurationJson['paper_options']['cover_paper_weight']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'cover_paper_weight', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'cover_paper_weight', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'cover_paper_weight', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="cover-paper-weight">Paper Weight for Cover:</label></div>
                <select id="cover-paper-weight" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoverPapers() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let selectOptions = [];
        if (configurationJson['paper_options']['front_coating']['default'] !== '' && configurationJson['paper_options']['back_coating']['default'] !== '') {
            for (let i = 0; i < options['coverPapers'].length; i++) {
                if (options['coverPapers'][i]['coating_one_side'] === 0) {
                    selectOptions.push(options['coverPapers'][i]);
                }
            }
        } else {
            for (let i = 0; i < options['coverPapers'].length; i++) {
                selectOptions.push(<option value={options['coverPapers'][i]['value']}>{options['coverPapers'][i]['label']}</option>);
            }
        }

        let show = configurationJson['paper_options']['cover_paper_id']['show'];
        let value = configurationJson['paper_options']['cover_paper_id']['default'];
        let lock = configurationJson['paper_options']['cover_paper_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'cover_paper_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'cover_paper_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'cover_paper_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="cover-paper-id">Paper for Cover:</label></div>
                <select id="cover-paper-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPaperCategories() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['paperCategories'].length; i++) {
            selectOptions.push(<option value={options['paperCategories'][i]['value']}>{options['paperCategories'][i]['label']}</option>);
        }

        let show = configurationJson['paper_options']['paper_category_id']['show'];
        let value = configurationJson['paper_options']['paper_category_id']['default'];
        let lock = configurationJson['paper_options']['paper_category_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'paper_category_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'paper_category_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'paper_category_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="paper-category-id">Paper Category:</label></div>
                <select id="paper-category-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPaperWeights() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['paperWeights'].length; i++) {
            selectOptions.push(<option value={options['paperWeights'][i]['value']}>{options['paperWeights'][i]['label']}</option>);
        }

        let show = configurationJson['paper_options']['paper_weight']['show'];
        let value = configurationJson['paper_options']['paper_weight']['default'];
        let lock = configurationJson['paper_options']['paper_weight']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'paper_weight', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'paper_weight', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'paper_weight', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="paper-weight">Paper Weight:</label></div>
                <select id="paper-weight" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPapers() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let paperOptions = [];
        if (configurationJson['paper_options']['interior_coating']['default'] !== '') {
            for (let i = 0; i < options['papers'].length; i++) {
                if (options['papers'][i]['coating_one_side'] === 0) {
                    paperOptions.push(<option value={options['papers'][i]['value']}>{options['papers'][i]['label']}</option>);
                }
            }
        } else {
            for (let i = 0; i < options['papers'].length; i++) {
                paperOptions.push(<option value={options['papers'][i]['value']}>{options['papers'][i]['label']}</option>);
            }
        }

        let show = configurationJson['paper_options']['paper_id']['show'];
        let value = configurationJson['paper_options']['paper_id']['default'];
        let lock = configurationJson['paper_options']['paper_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'paper_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'paper_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'paper_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="paper-id">Paper:</label></div>
                <select id="paper-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {paperOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderInteriorCoating() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['inks'].length; i++) {
            selectOptions.push(<option value={options['inks'][i]['value']}>{options['inks'][i]['label']}</option>);
        }

        let show = configurationJson['paper_options']['interior_coating']['show'];
        let value = configurationJson['paper_options']['interior_coating']['default'];
        let lock = configurationJson['paper_options']['interior_coating']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'interior_coating', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'interior_coating', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'interior_coating', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="front-coating">Coating Interior:</label></div>
                <select id="front-coating" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoatingFront() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let selectOptions = [];
        for (let i = 0; i < options['inks'].length; i++) {
            selectOptions.push(<option value={options['inks'][i]['value']}>{options['inks'][i]['label']}</option>);
        }

        let show = configurationJson['paper_options']['front_coating']['show'];
        let value = configurationJson['paper_options']['front_coating']['default'];
        let lock = configurationJson['paper_options']['front_coating']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'front_coating', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'front_coating', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'front_coating', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="front-coating">Cover Coating Front:</label></div>
                <select id="front-coating" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCoatingBack() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        if (!this.hasCover()) {
            return null;
        }

        let selectOptions = [];
        for (let i = 0; i < options['inks'].length; i++) {
            selectOptions.push(<option value={options['inks'][i]['value']}>{options['inks'][i]['label']}</option>);
        }

        let show = configurationJson['paper_options']['back_coating']['show'];
        let value = configurationJson['paper_options']['back_coating']['default'];
        let lock = configurationJson['paper_options']['back_coating']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['paper_options', 'back_coating', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['paper_options', 'back_coating', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['paper_options', 'back_coating', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="back-coating">Cover Coating Back:</label></div>
                <select id="back-coating" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderDrillingHoles() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['bindery_options']['drilling_holes']['show'];
        let value = configurationJson['bindery_options']['drilling_holes']['default'];
        let lock = configurationJson['bindery_options']['drilling_holes']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'drilling_holes', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'drilling_holes', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'drilling_holes', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="drilling-holes">Drilling Holes:</label></div>
                <select id="drilling-holes" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="0">None</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderShrinkWrappingTypes() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['shrinkWrappingTypes'].length; i++) {
            selectOptions.push(<option value={options['shrinkWrappingTypes'][i]['value']}>{options['shrinkWrappingTypes'][i]['label']}</option>);
        }

        let show = configurationJson['bindery_options']['shrink_wrapping_type_id']['show'];
        let value = configurationJson['bindery_options']['shrink_wrapping_type_id']['default'];
        let lock = configurationJson['bindery_options']['shrink_wrapping_type_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['bindery_options', 'shrink_wrapping_type_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['bindery_options', 'shrink_wrapping_type_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['bindery_options', 'shrink_wrapping_type_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="shrink-wrapping-type-id">Shrink-Wrapping:</label></div>
                <select id="shrink-wrapping-type-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderBandingTypes() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['bandingTypes'].length; i++) {
            selectOptions.push(<option value={options['bandingTypes'][i]['value']}>{options['bandingTypes'][i]['label']}</option>);
        }

        let show = configurationJson['handling_options']['banding_type_id']['show'];
        let value = configurationJson['handling_options']['banding_type_id']['default'];
        let lock = configurationJson['handling_options']['banding_type_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'banding_type_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'banding_type_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'banding_type_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="banding-type-id">Banding Type:</label></div>
                <select id="banding-type-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderWrappingTypes() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['wrappingTypes'].length; i++) {
            selectOptions.push(<option value={options['wrappingTypes'][i]['value']}>{options['wrappingTypes'][i]['label']}</option>);
        }

        let show = configurationJson['handling_options']['wrapping_type_id']['show'];
        let value = configurationJson['handling_options']['wrapping_type_id']['default'];
        let lock = configurationJson['handling_options']['wrapping_type_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'wrapping_type_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'wrapping_type_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'wrapping_type_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="wrapping-type-id">Wrapping Type:</label></div>
                <select id="wrapping-type-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderPackages() {
        const { options, configurationJson, handleUpdateConfiguration } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['packages'].length; i++) {
            selectOptions.push(<option value={options['packages'][i]['value']}>{options['packages'][i]['label']}</option>);
        }

        let show = configurationJson['handling_options']['package_id']['show'];
        let value = configurationJson['handling_options']['package_id']['default'];
        let lock = configurationJson['handling_options']['package_id']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'package_id', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'package_id', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'package_id', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="package-id">Package:</label></div>
                <select id="package-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderHandGathering() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['handling_options']['use_hand_gathering']['show'];
        let value = configurationJson['handling_options']['use_hand_gathering']['default'];
        let lock = configurationJson['handling_options']['use_hand_gathering']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['handling_options', 'use_hand_gathering', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['handling_options', 'use_hand_gathering', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['handling_options', 'use_hand_gathering', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="use-hand-gathering">Die-Cutting:</label></div>
                <select id="use-hand-gathering" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderProductionNotes() {
        const { configurationJson, handleUpdateConfiguration } = this.props;

        let show = configurationJson['production_notes']['show'];
        let value = configurationJson['production_notes']['default'];
        let lock = configurationJson['production_notes']['lock'];
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['production_notes', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['production_notes', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['production_notes', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="production-notes">Production Notes:</label></div>
                <input id="production-notes" className="configuration-field" type="text" value={value} onChange={updateConfigurationValue} />
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    renderCustomerDiscounts() {
        const { configurationJson, handleUpdateConfiguration, options } = this.props;

        let selectOptions = [];
        for (let i = 0; i < options['customerDiscounts'].length; i++) {
            selectOptions.push(<option value={options['customerDiscounts'][i]['value']}>{options['customerDiscounts'][i]['label']}</option>);
        }

        let show = 1;
        let value = '';
        let lock = false;
        if (configurationJson['customer_discount']) {
            show = configurationJson['customer_discount']['show'];
            value = configurationJson['customer_discount']['default'];
            lock = configurationJson['customer_discount']['lock'];
        }
        let updateConfigurationValue = (event) => handleUpdateConfiguration(['customer_discount', 'default'], event.target.value);
        let updateConfigurationShow = (event) => handleUpdateConfiguration(['customer_discount', 'show'], event.target.value);
        let updateConfigurationLock = (value) => handleUpdateConfiguration(['customer_discount', 'lock'], value);

        let lockIcon = <i className="fas fa-lock-open" onClick={updateConfigurationLock.bind(this, 1)} />;
        if (lock !== undefined && lock === 1) {
            lockIcon = <i className="fas fa-lock" onClick={updateConfigurationLock.bind(this, 0)} />;
        }

        return (
            <div className="col">
                <div><label htmlFor="package-id">Discount Table:</label></div>
                <select id="package-id" className="configuration-field" value={value} onChange={updateConfigurationValue}>
                    {selectOptions}
                </select>
                <div className="show-field-label">Show:</div>
                <select className="show-field" value={show} onChange={updateConfigurationShow}>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="lock">{lockIcon}</div>
            </div>
        );
    }

    render() {
        const { handleSaveElement, isSavingForm, activeElement, setEditing, configurationJson } = this.props;

        if (configurationJson == null) {
            return (
                <div id="tables-container" className="production-container">
                    <div id="configuration-container" className="table-container table-hover">
                        <div className="form-container" style={{ maxHeight: 'none' }}>
                            <i className="fas fa-circle-notch fa-spin"></i> Loading...
                        </div>
                    </div>
                </div>
            );
        }

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let coilCard = null;
        if (configurationJson['product_options']['multi_page_type']['default'] === 6 || configurationJson['product_options']['multi_page_type']['default'] === 7) {
            coilCard = (
                <div className="card mt-3">
                    <div className="card-header">
                        Coil Options
                    </div>
                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                        <div className="container-fluid">
                            <div className="row">
                                {this.renderCoilOption()}
                                <div className="w-100"></div>
                                {this.renderCoilColor()}
                                <div className="w-100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let stapleCard = null;
        if (configurationJson['product_options']['multi_page_type']['default'] === 4 || configurationJson['product_options']['multi_page_type']['default'] === 5) {
            stapleCard = (
                <div className="card mt-3">
                    <div className="card-header">
                        Staple Options
                    </div>
                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                        <div className="container-fluid">
                            <div className="row">
                                {this.renderStaplesNumber()}
                                <div className="w-100"></div>
                                {this.renderStaplesLocation()}
                                <div className="w-100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let data = (
            <div>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col"><h4 className="mt-1 mb-2">{activeElement.name}</h4></div>
                            <div className="w-100"></div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Product Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderProductCategories(2)}
                                                <div className="w-100"></div>
                                                {this.renderProductDescription()}
                                                <div className="w-100"></div>
                                                {this.renderPublicationType()}
                                                <div className="w-100"></div>
                                                {this.renderPages()}
                                                <div className="w-100"></div>
                                                {this.renderFinishWidth()}
                                                <div className="w-100"></div>
                                                {this.renderFinishHeight()}
                                                <div className="w-100"></div>
                                                {this.renderBindingEdge()}
                                                <div className="w-100"></div>
                                                {this.renderInteriorInks()}
                                                <div className="w-100"></div>
                                                {this.renderBleed()}
                                                <div className="w-100"></div>
                                                {this.renderFrontInks()}
                                                <div className="w-100"></div>
                                                {this.renderBackInks()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header">
                                        Press Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderCoverPresses()}
                                                <div className="w-100"></div>
                                                {this.renderCoverRunningStyle()}
                                                <div className="w-100"></div>
                                                {this.renderPresses()}
                                                <div className="w-100"></div>
                                                {this.renderRunningStyle()}
                                                <div className="w-100"></div>
                                                {this.renderSecondPresses()}
                                                <div className="w-100"></div>
                                                {this.renderSecondRunningStyle()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Paper Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderCoverPaperCategories()}
                                                <div className="w-100"></div>
                                                {this.renderCoverPaperWeights()}
                                                <div className="w-100"></div>
                                                {this.renderCoverPapers()}
                                                <div className="w-100"></div>
                                                {this.renderCoatingFront()}
                                                <div className="w-100"></div>
                                                {this.renderCoatingBack()}
                                                <div className="w-100"></div>
                                                {this.renderPaperCategories()}
                                                <div className="w-100"></div>
                                                {this.renderPaperWeights()}
                                                <div className="w-100"></div>
                                                {this.renderPapers()}
                                                <div className="w-100"></div>
                                                {this.renderInteriorCoating()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {coilCard}
                                {stapleCard}
                                <div className="card mt-3">
                                    <div className="card-header">
                                        Bindery Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderDrillingHoles()}
                                                <div className="w-100"></div>
                                                {this.renderShrinkWrappingTypes()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 col-4">
                                <div className="card">
                                    <div className="card-header">
                                        Handling Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderBandingTypes()}
                                                <div className="w-100"></div>
                                                {this.renderWrappingTypes()}
                                                <div className="w-100"></div>
                                                {this.renderPackages()}
                                                <div className="w-100"></div>
                                                {this.renderHandGathering()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header">
                                        Other Options
                                    </div>
                                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.renderProductionNotes()}
                                                <div className="w-100"></div>
                                                {this.renderCustomerDiscounts()}
                                                <div className="w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div>
                    <button key="save-button" className="btn btn-light btn-sm action-button"
                        onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>
                    <button key="close-button" className="btn btn-light btn-sm action-button-cancel"
                        onClick={setEditing.bind(this, false)} disabled={isSavingForm}>Close</button>
                </div>
            </div>
        );

        return (
            <div id="tables-container" className="production-container">
                <div id="configuration-container" className="table-container table-hover">
                    <div className="form-container" style={{ maxHeight: 'none' }}>{data}</div>
                </div>
            </div>
        );
    }
}

export default MultiPageConfiguration;
