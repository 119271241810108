import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component {
    renderHeader() {
        const { handleClickLogout, user } = this.props;
        const pathname = window.location.pathname;

        if (user.id == null || pathname.includes('/login')) {
            return null;
        }

        return (
            <div id="header" className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div id="logo">
                            <Link to={'/'}>
                                <img src={require('./../images/logo.png')} alt="Absolute Color Mailplex" />
                            </Link>
                        </div>
                        <div className="title-agent"></div>
                        <div id="my-agent-account">
                            <i className="fas fa-user"></i>
                            Welcome&nbsp;
                            <a href="/">{user.name}</a> |&nbsp;
                            <a href="/" onClick={handleClickLogout}>Sign Out</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <header className="main-header">
                {this.renderHeader()}
                <div className="clearfix"></div>
            </header>
        );
    }
}
