import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { dateToTimezone } from './../../../utils';

const Form = (props) => {
    const setFieldHandler = event => {
        props.setField(event.target.name, event.target.value);
    }

    const pqnKeyPressHandler = event => {
        if (event.key === 'Enter') {
            props.login('pqn');
        }
    }

    const pqtKeyPressHandler = event => {
        if (event.key === 'Enter') {
            props.login('pqt');
        }
    }

    const vdpKeyPressHandler = event => {
        if (event.key === 'Enter') {
            props.login('vdp');
        }
    }

    const forgotPasswordHandler = event => {
        event.preventDefault();
        props.restorePassword();
    }

    if (props.location.pathname === '/pqt-login') {
        return (
            <div className="pqt-form-container">
                <Helmet><title>Login - Stagecoach</title></Helmet>
                <div className="welcome">Welcome, please log in:</div>
                <div className="logo"><img src={require('./../images/stagecoach-logo.png')} alt="Stagecoach" /></div>
                <div className="form">
                    <div className="form-field email">
                        Email:
                        <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={pqtKeyPressHandler} />
                    </div>
                    <div className="form-field">
                        Password:
                        <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={pqtKeyPressHandler} />
                    </div>
                    <div>
                        <div className="forgot-password">
                            <a href="/" onClick={forgotPasswordHandler}>Forgot your password?</a>
                        </div>
                        <button onClick={props.login.bind(this, 'pqt')}>{props.isLogging ? <i className="fas fa-circle-notch fa-spin"></i> : 'Login'}</button>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        );
    } else if (props.location.pathname === '/vdp-login') {
        return (
            <div className="lgi-editor-login-container">
                <div className="form">
                    <div className="logo"><img src={require('./../images/lgi-logo.png')} alt="LGI" /></div>
                    <h5>Variable Design Platform (VDP)</h5>
                    <div>
                        User: <br />
                        <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={vdpKeyPressHandler} />
                    </div>
                    <div>
                        Password: <br />
                        <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={vdpKeyPressHandler} />
                    </div>
                    <div className="text-center">
                        <button onClick={props.login.bind(this, 'vdp')}>{props.isLogging ? <i className="fas fa-circle-notch fa-spin"></i> : 'Login'}</button>
                    </div>
                    <div className="text-center security">
                        Your login time and credentials will be added to the security log. {dateToTimezone(null, 'hh:mm a | MM/DD/YYYY')}
                    </div>
                    </div>
                <div className="powered-lgi">Powered by <span>Stagecoach</span></div>
            </div>
        );
    } else {
        return (
            <div className="form-container">
                <Helmet><title>Login - PQN</title></Helmet>
                <div className="logo"><img src={require('./../images/logo.png')} alt="PQN" /></div>
                <div className="form">
                    <h2>Login</h2>
                    <div className="form-field">
                        <label htmlFor="">Email <span className="required">*</span></label>
                        <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={pqnKeyPressHandler} />
                    </div>
                    <div className="form-field">
                        <label htmlFor="">Password <span className="required">*</span></label>
                        <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={pqnKeyPressHandler} />
                    </div>
                    <div className="text-center">
                        <div className="forgot-password">
                            <a href="/" onClick={forgotPasswordHandler}>Forgot your password?</a>
                        </div>
                        <button onClick={props.login.bind(this, 'pqn')}>{props.isLogging ? <i className="fas fa-circle-notch fa-spin"></i> : 'Login'}</button>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}

Form.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    setField: PropTypes.func.isRequired
}

export default Form;
