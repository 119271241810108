import { createSelector } from 'reselect';

export const getComponentState = state => state.dptInkjet;

export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getData = createSelector(
    getComponentState,
    (state) => state.data
);
export const getIsFetchingData = createSelector(
    getComponentState,
    (state) => state.isFetchingData
);
export const getIsSaving = createSelector(
    getComponentState,
    (state) => state.isSaving
);
export const getIsFetchingElement = createSelector(
    getComponentState,
    (state) => state.isFetchingElement
);
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);