import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';

export function savePassword(values) {
    return (dispatch, getState) => {
        if (values.password === '' || values.password == null) {
            showNotification('Complete the Information', 'Enter your new password', 'info');
            return;
        }
        if (values.confirm_password === '' || values.confirm_password == null) {
            showNotification('Complete the Information', 'Confirm your new password', 'info');
            return;
        }
        if (values.password !== values.confirm_password) {
            showNotification('Complete the Information', 'The passwords are not the same', 'info');
            return;
        }

        dispatch({ type: actionTypes.SAVE_PASSWORD, payload: null });
        axios.post(apiUrls.changePassword, values)
            .then(response => {
                dispatch({ type: actionTypes.SAVE_PASSWORD_SUCCESS, payload: response.data });
                showNotification('Password Changed!', 'Your password was changed, please log in using your new password', 'success');
                const history = selectors.getHistory(getState());
                history.push('/login');
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_PASSWORD_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: errors
    };
}

export function setFormError(name, value) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value }
    };
}

export function setToken(token) {
    return {
        type: actionTypes.SET_TOKEN,
        payload: token
    };
}

export function setHistory(history) {
    return {
        type: actionTypes.SET_HISTORY,
        payload: history
    };
}
