import React, { useState } from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import AccountingForm from './AccountingForm';

const DataProcessingForm = (props) => {
    const [quantity, setQuantity] = useState('');
    const [returnFiles, setReturnFiles] = useState(false);
    const [usePostalQualification, setUsePostalQualification] = useState(false);

    const getUnit = (unit, plural = true) => {
        if (parseInt(unit) === 1) {
            return plural ? 'seconds' : 'second';
        } else if (parseInt(unit) === 2) {
            return plural ? 'minutes' : 'minute';
        } else if (parseInt(unit) === 3) {
            return plural ? 'hours' : 'hour';
        } else if (parseInt(unit) === 4) {
            return plural ? 'days' : 'day';
        } else if (parseInt(unit) === 5) {
            return plural ? 'min per boxes' : 'min per box';
        } else {
            return 'N/A';
        }
    };

    const addSummaryElement = (id, quantity) => {
        const unit = getUnitInMins(id);
        const timeInMins = quantity * unit;
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));

        const tooltipInfo = (
            <div className="text-left">
                <div>Production Time: {numeral(element.time_per).format('0,0.[00]')} {getUnit(element.unit)} each.</div>
            </div>
        );

        props.setSummaryElement('dptDataProcessing', id, element.name, quantity, timeInMins, tooltipInfo);
    };

    const checkElementHandler = (id, event) => {
        props.checkElement('dptDataProcessing', id, event.target.checked ? 1 : 0);

        if (!event.target.checked) {
            props.removeSummaryElement('dptDataProcessing', id);
        } else {
            if (parseInt(id) !== 2) {
                addSummaryElement(id, 1);
            } else {
                const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
                addSummaryElement(id, element.selected_quantity);
            }
        }
    };

    const getUnitInMins = (id) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (parseInt(element.unit) === 1) {     // Seconds
            return element.time_per * 1 / 60;
        } else if (parseInt(element.unit) === 2) {      // Minutes
            return element.time_per * 1;
        } else if (parseInt(element.unit) === 3) {      // Hours
            return element.time_per * 60;
        } else if (parseInt(element.unit) === 4) {      // Days
            return element.time_per * 480;     // 8 Hours a day
        } else {
            return 0;
        }
    };

    const setQuantityHandler = (id, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0;
        }
        props.setQuantity('dptDataProcessing', id, value);

        if (parseInt(id) !== 2) {
            return;
        }

        addSummaryElement(id, value);
    };

    const setDifficultyHandler = (event) => {
        const id = event.target.value;
        if (id === '') {
            return;
        }

        const unit = getUnitInMins(id);
        const timeInMins = 1 * unit;
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));

        const tooltipInfo = (
            <div className="text-left">
                <div>Production Time: {numeral(element.time_per).format('0,0.[00]')} {getUnit(element.unit)} each.</div>
            </div>
        );

        props.setDifficultyLevelDp(id, element.name, timeInMins, tooltipInfo);
    };

    const setPresortHandler = (event) => {
        const id = event.target.value;
        if (id === '') {
            return;
        }

        const unit = getUnitInMins(id);
        const timeInMins = 1 * unit;
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));

        const tooltipInfo = (
            <div className="text-left">
                <div>Production Time: {numeral(element.time_per).format('0,0.[00]')} {getUnit(element.unit)} each.</div>
            </div>
        );

        props.setPresortDp(id, element.name, timeInMins, tooltipInfo);
    };

    const renderElement = (id) => {
        const element = props.elements.find((element) => parseInt(element.id) === parseInt(id));
        if (!element) {
            return null;
        }

        let value = '';
        if (element.selected_quantity) {
            value = element.selected_quantity;
        }

        return (
            <div className="field">
                <input
                    type="checkbox"
                    checked={element.checked ? 1 : 0}
                    onChange={checkElementHandler.bind(this, id)}
                />
                <input
                    type="number"
                    min="0"
                    placeholder="Qty"
                    value={value}
                    disabled={!element.checked}
                    onChange={setQuantityHandler.bind(this, id)}
                />
                {element.name}
            </div>
        );
    };

    const getDifficultyValue = () => {
        const element = props.summary.find((element) => [20, 21, 22, 23].includes(parseInt(element.id)));
        if (element) {
            return element.id;
        }
        return '';
    };

    const getPresortValue = () => {
        const element = props.summary.find((element) => [14, 15, 16].includes(parseInt(element.id)));
        if (element) {
            return element.id;
        }
        return '';
    };

    let showIcon = <i className="fas fa-eye" />;
    if (props.show.dataProcessing) {
        showIcon = <i className="fas fa-eye-slash" />;
    }

    const setMainQuantityHandler = (event) => {
        setQuantity(event.target.value);
        props.setDepartmentMainQuantity('dptDataProcessing', event.target.value, [2, 4, 6, 10, 25, 26, 27, 31, 32]);

        const element = props.elements.find((element) => parseInt(element.id) === 2);
        if (element.checked) {
            addSummaryElement(2, event.target.value);
        }
    };

    const setReturnFilesHandler = (event) => {
        setReturnFiles(event.target.checked);

        if (event.target.checked) {
            addSummaryElement(28, 1);
        } else {
            props.removeSummaryElement('dptDataProcessing', 28);
        }
    };

    const setUsePostalQualificationHandler = (event) => {
        setUsePostalQualification(event.target.checked);

        if (event.target.checked) {
            addSummaryElement(24, 1);
        } else {
            props.removeSummaryElement('dptDataProcessing', 24);
        }
    };

    return (
        <div className="department-configuration data-processing-form">
            <h2>
                <div>Data Processing</div>
                <div className="icons">
                    <Tooltip title="Reset" position="top" arrow size="small " className="action-tooltip">
                        <div className="remove-icon" onClick={props.resetDepartment.bind(this, 'dptDataProcessing')}><i className="fas fa-minus-circle" /></div>
                    </Tooltip>
                    <Tooltip title="Show/Hide" position="top" arrow size="small " className="action-tooltip">
                        <div className="show-icon" onClick={props.toggleShow.bind(this, 'dataProcessing')}>{showIcon}</div>
                    </Tooltip>
                </div>
            </h2>
            <div style={props.show.dataProcessing ? {} : { 'display': 'none' }}>
                <div className="form">
                    <div className="form-section">
                        <div className="field quantity-field">
                            Quantity:
                            <input type="number" min="0" value={quantity} onChange={setMainQuantityHandler} />
                        </div>
                        {renderElement(5)}
                        {renderElement(6)}
                        {renderElement(9)}
                        {renderElement(10)}
                        {renderElement(2)}
                        {renderElement(25)}
                        {renderElement(26)}
                        {renderElement(27)}
                        <div className="field return-files-field">
                            <input type="checkbox" checked={returnFiles ? 1 : 0} onChange={setReturnFilesHandler} />
                            Return Files to Customer
                        </div>
                        <div className="field postal-qualification-field">
                            <input type="checkbox" checked={usePostalQualification ? 1 : 0} onChange={setUsePostalQualificationHandler} />
                            Postal Qualification
                        </div>
                    </div>
                    <div className="form-section">
                        {renderElement(7)}
                        {renderElement(29)}
                        {renderElement(3)}
                        {renderElement(4)}
                        {renderElement(11)}
                        {renderElement(8)}
                        {renderElement(12)}
                        {renderElement(30)}
                        {renderElement(13)}
                        {renderElement(31)}
                        {renderElement(32)}
                        <div className="field">
                            Presort:
                            <select value={getPresortValue()} onChange={setPresortHandler}>
                                <option value="">Select option</option>
                                <option value="15">Output for Imaging</option>
                                <option value="14">Output for Inkjet</option>
                                <option value="16">Both</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-section">
                        {renderElement(18)}
                        {renderElement(19)}
                        {renderElement(17)}
                        <div className="field">
                            Difficulty Level:
                            <select value={getDifficultyValue()} onChange={setDifficultyHandler}>
                                <option value="">Select option</option>
                                <option value="20">Simple</option>
                                <option value="21">Medium</option>
                                <option value="22">Difficult</option>
                                <option value="23">Extreme</option>
                            </select>
                        </div>
                    </div>
                </div>
                <AccountingForm
                    department="dataProcessing"
                    accounting={props.accounting}
                    addAccountItem={props.addAccountItem}
                    removeAccountItem={props.removeAccountItem}
                    changeAccountItem={props.changeAccountItem}
                />
            </div>
        </div>
    );
};

export default DataProcessingForm;