import React, { Component } from 'react';
import numeral from 'numeral';
import Select from './../../common/components/Select';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';
import apiUrls from './../apiUrls';
import { dateToTimezone } from './../../../utils';

class Quote extends Component {
    renderInput(label, name, type, required, className = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'quote-information-' + name} className={className}>
                <div className="label-column bold"><label>{label}{required ? <span className="required"> *</span> : null}</label></div>
                <div className="input-column">
                    <Input
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                    />
                </div>
            </div>
        );
    }

    renderTextArea(label, name, required, className = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'quote-information-' + name} className={className}>
                <div className="label-column bold"><label>{label}{required ? <span className="required"> *</span> : null}</label></div>
                <div className="input-column">
                    <TextArea
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    renderSelect(label, name, options, required, className = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div key={'quote-information-' + name} className={className}>
                <div className="label-column bold"><label>{label}{required ? <span className="required"> *</span> : null}</label></div>
                <div className="input-column">
                    <Select
                        name={name}
                        required={required}
                        options={options}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    getMailingNames(estimate, name, optionsName) {
        const { options } = this.props;

        if (estimate[name] == null) {
            return null;
        }

        let names = [];
        let elements = JSON.parse(estimate[name]);
        for (let i = 0; i < elements.length; i++) {
            let selectedElement = null;
            for (let j = 0; j < options[optionsName].length; j++) {
                let element = options[optionsName][j]['element'];
                if (element && element != null && parseInt(element['id']) === parseInt(elements[i]['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement != null) {
                names.push(selectedElement['name']);
            }
        }
        return names;
    }

    renderPrintingEstimates() {
        const { information, handleShowDelete, handleShowForm, handleSetItemQuantity } = this.props;
        const rows = information['estimates'].map((estimate, index) => {
            let pressNames = [];
            let digitalMachineName = null;
            let largeFormatMachineName = null;
            let paperNames = [];
            let inkNames = [];
            let foldingNames = [];
            let bindingName = null;
            let substrateName = null;
            let laminateName = null;
            let digitalSides = null;
            let accessoriesNames = [];
            let envelopePressName = null;
            let envelopePaperName = null;
            let quantities = [null, null, null];
            let totals = [null, null, null];

            let estimateResult1 = estimate['estimateResults'][0];
            let estimateResult2 = estimate['estimateResults'][1];
            let estimateResult3 = estimate['estimateResults'][2];

            totals = [
                estimateResult1['subtotal'] + estimateResult1['discount'],
                estimateResult2['subtotal'] + estimateResult2['discount'],
                estimateResult3['subtotal'] + estimateResult3['discount']
            ];

            if (parseInt(estimate['type']) === 1) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                inkNames.push(estimate['front_inks'] + '/' + estimate['back_inks']);
                foldingNames = estimate['folding_machine_name'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 2) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];
                bindingName = estimate['binding_machine_name'];

                let auxInkName = estimate['interior_inks'] + '/' + estimate['interior_inks'];
                if (!inkNames.includes(auxInkName)) {
                    inkNames.push(auxInkName);
                }

                if ([2, 3, 6, 7].includes(parseInt(estimate['multi_page_type']))) {
                    auxInkName = estimate['front_inks'] + '/' + estimate['back_inks'];
                    if (!inkNames.includes(auxInkName)) {
                        inkNames.push(auxInkName);
                    }
                }

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 3) {
                digitalMachineName = estimate['digital_machine_name'];
                digitalSides = estimate['digital_sides'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 4) {
                largeFormatMachineName = estimate['large_format_machine_name'];
                substrateName = estimate['substrate_name'];
                laminateName = estimate['laminate_name'];
                accessoriesNames = estimate['accessories_names'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 15) {     // Envelopes
                envelopePressName = estimate['envelope_press_name'];
                envelopePaperName = estimate['envelope_paper_name'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else {
                return null;
            }

            let pressRow = null;
            if (pressNames != null && pressNames.length > 0) {
                pressRow = <div>Press: {pressNames.join(', ')}</div>;
            }

            let digitalMachineRow = null;
            if (digitalMachineName != null) {
                digitalMachineRow = <div>Digital Machine: {digitalMachineName}</div>;
            }

            let inkRow = null;
            if (inkNames != null && inkNames.length > 0) {
                inkRow = <div>Inks: {inkNames.join(', ')}</div>;
            }

            let paperRow = null;
            if (paperNames != null && paperNames.length > 0) {
                paperRow = <div>Paper: {paperNames.join(', ')}</div>;
            }

            let foldingRow = null;
            if (foldingNames != null && foldingNames.length > 0) {
                foldingRow = <div>Folding: {foldingNames.join(', ')}</div>;
            }

            let bindingRow = null;
            if (bindingName != null) {
                bindingRow = <div>Binding: {bindingName}</div>;
            }

            let largeFormatMachineRow = null;
            if (largeFormatMachineName != null) {
                largeFormatMachineRow = <div>Large Format Machine: {largeFormatMachineName}</div>;
            }

            let substrateRow = null;
            if (substrateName != null) {
                substrateRow = <div>Substrate: {substrateName}</div>;
            }

            let laminateRow = null;
            if (laminateName != null) {
                laminateRow = <div>Laminate: {laminateName}</div>;
            }

            let digitalSidesRow = null;
            if (digitalSides != null) {
                digitalSidesRow = <div>Sides: {digitalSides}</div>;
            }

            let accessoriesRow = null;
            if (accessoriesNames != null && accessoriesNames.length > 0) {
                accessoriesRow = <div>Accessories: {accessoriesNames.join(', ')}</div>;
            }

            let envelopePressRow = null;
            if (envelopePressName != null) {
                envelopePressRow = <div>Envelope Press: {envelopePressName}</div>;
            }

            let envelopePaperRow = null;
            if (envelopePaperName != null) {
                envelopePaperRow = <div>Envelope Paper: {envelopePaperName}</div>;
            }

            return (
                <tr key={index}>
                    <td>
                        <div className="bold"><b>{estimate['product_category_name']}</b></div>
                        <div className="bold">{estimate['product_description']}</div>
                    </td>
                    <td>{estimate['notes']}</td>
                    <td>
                        {pressRow}
                        {digitalMachineRow}
                        {largeFormatMachineRow}
                        {paperRow}
                        {digitalSidesRow}
                        {inkRow}
                        {foldingRow}
                        {bindingRow}
                        {substrateRow}
                        {laminateRow}
                        {accessoriesRow}
                        {envelopePressRow}
                        {envelopePaperRow}
                    </td>
                    <td className="price text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        <div>
                            <input type="radio" className="mr-1" checked={quantities[0] === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[0])} />
                            {numeral(quantities[0]).format('0,0')}
                        </div>
                        <div>{numeral(totals[0]).format('$0,0.00')}</div>
                    </td>
                    <td className="price text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        <div>
                            <input type="radio" className="mr-1" checked={quantities[1] === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[1])} />
                            {numeral(quantities[1]).format('0,0')}
                        </div>
                        <div>{numeral(totals[1]).format('$0,0.00')}</div>
                    </td>
                    <td className="price text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        <div>
                            <input type="radio" className="mr-1" checked={quantities[2] === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[2])} />
                            {numeral(quantities[2]).format('0,0')}
                        </div>
                        <div>{numeral(totals[2]).format('$0,0.00')}</div>
                    </td>
                    <td className="actions">
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleShowDelete.bind(this, estimate['id'])} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
        return rows.filter(row => row !== null);
    }

    renderMailingEstimates() {
        const { information, handleShowDelete, handleShowForm, handleSetItemQuantity } = this.props;
        const rows = information['estimates'].map((estimate, index) => {
            let dataProcessingDescriptions = [];
            let dataProcessingQuantities = [[], [], []];
            let dataProcessingPrices = [[], [], []];
            let inkjetDescriptions = [];
            let inkjetQuantities = [[], [], []];
            let inkjetPrices = [[], [], []];
            let insertingDescriptions = [];
            let insertingQuantities = [[], [], []];
            let insertingPrices = [[], [], []];
            let artDescriptions = [];
            let artQuantities = [[], [], []];
            let artPrices = [[], [], []];
            let handworkDescriptions = [];
            let handworkQuantities = [[], [], []];
            let handworkPrices = [[], [], []];
            let pressureSealDescriptions = [];
            let pressureSealQuantities = [[], [], []];
            let pressureSealPrices = [[], [], []];
            let indexTabDescriptions = [];
            let indexTabQuantities = [[], [], []];
            let indexTabPrices = [[], [], []];
            let labelDescriptions = [];
            let labelQuantities = [[], [], []];
            let labelPrices = [[], [], []];
            let ncrFormDescriptions = [];
            let ncrFormQuantities = [[], [], []];
            let ncrFormPrices = [[], [], []];
            let totals = [null, null, null];

            let estimateResult1 = estimate['estimateResults'][0];
            let estimateResult2 = estimate['estimateResults'][1];
            let estimateResult3 = estimate['estimateResults'][2];

            totals = [
                estimateResult1['subtotal'] + estimateResult1['discount'],
                estimateResult2['subtotal'] + estimateResult2['discount'],
                estimateResult3['subtotal'] + estimateResult3['discount']
            ];

            if (parseInt(estimate['type']) === 5) {
                for (let i = 0; i < estimate['data_processing_elements'].length; i++) {
                    dataProcessingDescriptions.push(<div>{estimate['data_processing_elements'][i]['name']}</div>);

                    dataProcessingQuantities[0].push(<div>{numeral(estimate['data_processing_elements'][i]['quantity1']).format('0,0')}</div>);
                    dataProcessingQuantities[1].push(<div>{numeral(estimate['data_processing_elements'][i]['quantity2']).format('0,0')}</div>);
                    dataProcessingQuantities[2].push(<div>{numeral(estimate['data_processing_elements'][i]['quantity3']).format('0,0')}</div>);

                    dataProcessingPrices[0].push(<div>{numeral(estimate['data_processing_elements'][i]['price1']).format('$0,0.00')}</div>);
                    dataProcessingPrices[1].push(<div>{numeral(estimate['data_processing_elements'][i]['price2']).format('$0,0.00')}</div>);
                    dataProcessingPrices[2].push(<div>{numeral(estimate['data_processing_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 6) {
                for (let i = 0; i < estimate['inkjet_elements'].length; i++) {
                    inkjetDescriptions.push(<div>{estimate['inkjet_elements'][i]['name']}</div>);

                    inkjetQuantities[0].push(<div>{numeral(estimate['inkjet_elements'][i]['quantity1']).format('0,0')}</div>);
                    inkjetQuantities[1].push(<div>{numeral(estimate['inkjet_elements'][i]['quantity2']).format('0,0')}</div>);
                    inkjetQuantities[2].push(<div>{numeral(estimate['inkjet_elements'][i]['quantity3']).format('0,0')}</div>);

                    inkjetPrices[0].push(<div>{numeral(estimate['inkjet_elements'][i]['price1']).format('$0,0.00')}</div>);
                    inkjetPrices[1].push(<div>{numeral(estimate['inkjet_elements'][i]['price2']).format('$0,0.00')}</div>);
                    inkjetPrices[2].push(<div>{numeral(estimate['inkjet_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 7) {
                for (let i = 0; i < estimate['inserting_elements'].length; i++) {
                    insertingDescriptions.push(<div>{estimate['inserting_elements'][i]['name']}</div>);

                    insertingQuantities[0].push(<div>{numeral(estimate['inserting_elements'][i]['quantity1']).format('0,0')}</div>);
                    insertingQuantities[1].push(<div>{numeral(estimate['inserting_elements'][i]['quantity2']).format('0,0')}</div>);
                    insertingQuantities[2].push(<div>{numeral(estimate['inserting_elements'][i]['quantity3']).format('0,0')}</div>);

                    insertingPrices[0].push(<div>{numeral(estimate['inserting_elements'][i]['price1']).format('$0,0.00')}</div>);
                    insertingPrices[1].push(<div>{numeral(estimate['inserting_elements'][i]['price2']).format('$0,0.00')}</div>);
                    insertingPrices[2].push(<div>{numeral(estimate['inserting_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
                for (let i = 0; i < estimate['perfect_match_elements'].length; i++) {
                    insertingDescriptions.push(<div>{estimate['perfect_match_elements'][i]['name']}</div>);

                    insertingQuantities[0].push(<div>{numeral(estimate['perfect_match_elements'][i]['quantity1']).format('0,0')}</div>);
                    insertingQuantities[1].push(<div>{numeral(estimate['perfect_match_elements'][i]['quantity2']).format('0,0')}</div>);
                    insertingQuantities[2].push(<div>{numeral(estimate['perfect_match_elements'][i]['quantity3']).format('0,0')}</div>);

                    insertingPrices[0].push(<div>{numeral(estimate['perfect_match_elements'][i]['price1']).format('$0,0.00')}</div>);
                    insertingPrices[1].push(<div>{numeral(estimate['perfect_match_elements'][i]['price2']).format('$0,0.00')}</div>);
                    insertingPrices[2].push(<div>{numeral(estimate['perfect_match_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 9) {
                for (let i = 0; i < estimate['art_elements'].length; i++) {
                    artDescriptions.push(<div>{estimate['art_elements'][i]['name']}</div>);

                    artQuantities[0].push(<div>{numeral(estimate['art_elements'][i]['quantity1']).format('0,0')}</div>);
                    artQuantities[1].push(<div>{numeral(estimate['art_elements'][i]['quantity2']).format('0,0')}</div>);
                    artQuantities[2].push(<div>{numeral(estimate['art_elements'][i]['quantity3']).format('0,0')}</div>);

                    artPrices[0].push(<div>{numeral(estimate['art_elements'][i]['price1']).format('$0,0.00')}</div>);
                    artPrices[1].push(<div>{numeral(estimate['art_elements'][i]['price2']).format('$0,0.00')}</div>);
                    artPrices[2].push(<div>{numeral(estimate['art_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 10) {
                for (let i = 0; i < estimate['handwork_elements'].length; i++) {
                    handworkDescriptions.push(<div>{estimate['handwork_elements'][i]['name']}</div>);

                    handworkQuantities[0].push(<div>{numeral(estimate['handwork_elements'][i]['quantity1']).format('0,0')}</div>);
                    handworkQuantities[1].push(<div>{numeral(estimate['handwork_elements'][i]['quantity2']).format('0,0')}</div>);
                    handworkQuantities[2].push(<div>{numeral(estimate['handwork_elements'][i]['quantity3']).format('0,0')}</div>);

                    handworkPrices[0].push(<div>{numeral(estimate['handwork_elements'][i]['price1']).format('$0,0.00')}</div>);
                    handworkPrices[1].push(<div>{numeral(estimate['handwork_elements'][i]['price2']).format('$0,0.00')}</div>);
                    handworkPrices[2].push(<div>{numeral(estimate['handwork_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 11) {
                for (let i = 0; i < estimate['pressure_seal_elements'].length; i++) {
                    pressureSealDescriptions.push(<div>{estimate['pressure_seal_elements'][i]['name']}</div>);

                    pressureSealQuantities[0].push(<div>{numeral(estimate['pressure_seal_elements'][i]['quantity1']).format('0,0')}</div>);
                    pressureSealQuantities[1].push(<div>{numeral(estimate['pressure_seal_elements'][i]['quantity2']).format('0,0')}</div>);
                    pressureSealQuantities[2].push(<div>{numeral(estimate['pressure_seal_elements'][i]['quantity3']).format('0,0')}</div>);

                    let price1 = 0;
                    let price2 = 0;
                    let price3 = 0;
                    for (let j = 0; j < estimate['pressure_seal_elements'][i]['prices'].length; j++) {
                        price1 += estimate['pressure_seal_elements'][i]['prices'][j][0];
                        price2 += estimate['pressure_seal_elements'][i]['prices'][j][1];
                        price3 += estimate['pressure_seal_elements'][i]['prices'][j][2];
                    }

                    pressureSealPrices[0].push(<div>{numeral(price1).format('$0,0.00')}</div>);
                    pressureSealPrices[1].push(<div>{numeral(price2).format('$0,0.00')}</div>);
                    pressureSealPrices[2].push(<div>{numeral(price3).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 12) {
                for (let i = 0; i < estimate['index_tab_elements'].length; i++) {
                    indexTabDescriptions.push(<div>{estimate['index_tab_elements'][i]['name']}</div>);

                    indexTabQuantities[0].push(<div>{numeral(estimate['index_tab_elements'][i]['quantity1']).format('0,0')}</div>);
                    indexTabQuantities[1].push(<div>{numeral(estimate['index_tab_elements'][i]['quantity2']).format('0,0')}</div>);
                    indexTabQuantities[2].push(<div>{numeral(estimate['index_tab_elements'][i]['quantity3']).format('0,0')}</div>);

                    indexTabPrices[0].push(<div>{numeral(estimate['index_tab_elements'][i]['price1']).format('$0,0.00')}</div>);
                    indexTabPrices[1].push(<div>{numeral(estimate['index_tab_elements'][i]['price2']).format('$0,0.00')}</div>);
                    indexTabPrices[2].push(<div>{numeral(estimate['index_tab_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 13) {
                for (let i = 0; i < estimate['label_elements'].length; i++) {
                    labelDescriptions.push(<div>{estimate['label_elements'][i]['name']}</div>);

                    labelQuantities[0].push(<div>{numeral(estimate['label_elements'][i]['quantity1']).format('0,0')}</div>);
                    labelQuantities[1].push(<div>{numeral(estimate['label_elements'][i]['quantity2']).format('0,0')}</div>);
                    labelQuantities[2].push(<div>{numeral(estimate['label_elements'][i]['quantity3']).format('0,0')}</div>);

                    labelPrices[0].push(<div>{numeral(estimate['label_elements'][i]['price1']).format('$0,0.00')}</div>);
                    labelPrices[1].push(<div>{numeral(estimate['label_elements'][i]['price2']).format('$0,0.00')}</div>);
                    labelPrices[2].push(<div>{numeral(estimate['label_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else if (parseInt(estimate['type']) === 14) {
                for (let i = 0; i < estimate['ncr_form_elements'].length; i++) {
                    ncrFormDescriptions.push(<div>{estimate['ncr_form_elements'][i]['name']}</div>);

                    ncrFormQuantities[0].push(<div>{numeral(estimate['ncr_form_elements'][i]['quantity1']).format('0,0')}</div>);
                    ncrFormQuantities[1].push(<div>{numeral(estimate['ncr_form_elements'][i]['quantity2']).format('0,0')}</div>);
                    ncrFormQuantities[2].push(<div>{numeral(estimate['ncr_form_elements'][i]['quantity3']).format('0,0')}</div>);

                    ncrFormPrices[0].push(<div>{numeral(estimate['ncr_form_elements'][i]['price1']).format('$0,0.00')}</div>);
                    ncrFormPrices[1].push(<div>{numeral(estimate['ncr_form_elements'][i]['price2']).format('$0,0.00')}</div>);
                    ncrFormPrices[2].push(<div>{numeral(estimate['ncr_form_elements'][i]['price3']).format('$0,0.00')}</div>);
                }
            } else {
                return null;
            }

            return (
                <tr key={index}>
                    <td>
                        <div className="bold"><b>{estimate['product_category_name']}</b></div>
                        <div className="bold">{estimate['product_description']}</div>
                    </td>
                    <td>{estimate['notes']}</td>
                    <td>
                        {dataProcessingDescriptions}
                        {inkjetDescriptions}
                        {insertingDescriptions}
                        {artDescriptions}
                        {handworkDescriptions}
                        {pressureSealDescriptions}
                        {indexTabDescriptions}
                        {labelDescriptions}
                        {ncrFormDescriptions}
                    </td>
                    <td className="text-center">
                        {dataProcessingQuantities[0]}
                        {inkjetQuantities[0]}
                        {insertingQuantities[0]}
                        {artQuantities[0]}
                        {handworkQuantities[0]}
                        {indexTabQuantities[0]}
                        {labelQuantities[0]}
                        {ncrFormQuantities[0]}
                        {pressureSealQuantities[0]}
                    </td>
                    <td className="text-right" style={{ width: 100, borderRight: '1px solid #dee2e6' }}>
                        {dataProcessingPrices[0]}
                        {inkjetPrices[0]}
                        {insertingPrices[0]}
                        {artPrices[0]}
                        {handworkPrices[0]}
                        {indexTabPrices[0]}
                        {labelPrices[0]}
                        {ncrFormPrices[0]}
                        {pressureSealPrices[0]}
                        <div>
                            <input type="radio" className="mr-1" checked={parseInt(1) === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, parseInt(1))} />
                            <b>{numeral(totals[0]).format('$0,0.00')}</b>
                        </div>
                    </td>
                    <td className="text-center">
                        {dataProcessingQuantities[1]}
                        {inkjetQuantities[1]}
                        {insertingQuantities[1]}
                        {artQuantities[1]}
                        {handworkQuantities[1]}
                        {indexTabQuantities[1]}
                        {labelQuantities[1]}
                        {ncrFormQuantities[1]}
                        {pressureSealQuantities[1]}
                    </td>
                    <td className="text-right" style={{ width: 100, borderRight: '1px solid #dee2e6' }}>
                        {dataProcessingPrices[1]}
                        {inkjetPrices[1]}
                        {insertingPrices[1]}
                        {artPrices[1]}
                        {handworkPrices[1]}
                        {indexTabPrices[1]}
                        {labelPrices[1]}
                        {ncrFormPrices[1]}
                        {pressureSealPrices[1]}
                        <div>
                            <input type="radio" className="mr-1" checked={parseInt(2) === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, parseInt(2))} />
                            <b>{numeral(totals[1]).format('$0,0.00')}</b>
                        </div>
                    </td>
                    <td className="text-center">
                        {dataProcessingQuantities[2]}
                        {inkjetQuantities[2]}
                        {insertingQuantities[2]}
                        {artQuantities[2]}
                        {handworkQuantities[2]}
                        {indexTabQuantities[2]}
                        {labelQuantities[2]}
                        {ncrFormQuantities[2]}
                        {pressureSealQuantities[2]}
                    </td>
                    <td className="text-right" style={{ width: 100, borderRight: '1px solid #dee2e6' }}>
                        {dataProcessingPrices[2]}
                        {inkjetPrices[2]}
                        {insertingPrices[2]}
                        {artPrices[2]}
                        {handworkPrices[2]}
                        {indexTabPrices[2]}
                        {labelPrices[2]}
                        {ncrFormPrices[2]}
                        {pressureSealPrices[2]}
                        <div>
                            <input type="radio" className="mr-1" checked={parseInt(3) === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, parseInt(3))} />
                            <b>{numeral(totals[2]).format('$0,0.00')}</b>
                        </div>
                    </td>
                    <td className="actions">
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleShowDelete.bind(this, estimate['id'])} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
        return rows.filter(row => row !== null);
    }

    renderPrintingTotals() {
        const { information } = this.props;

        let totals = null;
        if (information['estimates'].length > 0) {
            let subtotals = [0, 0, 0];
            let customerDiscounts = [0, 0, 0];
            let quoteDiscounts = [0, 0, 0];
            let taxes = [0, 0, 0];
            let rushFees = [0, 0, 0];
            let totalPrices = [0, 0, 0];
            for (let i = 0; i < information['estimates'].length; i++) {
                if (![1, 2, 3, 4, 15].includes(parseInt(information['estimates'][i]['type']))) {
                    continue;
                }

                subtotals[0] += information['estimates'][i]['estimateResults'][0]['subtotal'] + information['estimates'][i]['estimateResults'][0]['discount'];
                subtotals[1] += information['estimates'][i]['estimateResults'][1]['subtotal'] + information['estimates'][i]['estimateResults'][1]['discount'];
                subtotals[2] += information['estimates'][i]['estimateResults'][2]['subtotal'] + information['estimates'][i]['estimateResults'][2]['discount'];

                customerDiscounts[0] += information['estimates'][i]['estimateResults'][0]['customer_discount'];
                customerDiscounts[1] += information['estimates'][i]['estimateResults'][1]['customer_discount'];
                customerDiscounts[2] += information['estimates'][i]['estimateResults'][2]['customer_discount'];

                quoteDiscounts[0] += information['estimates'][i]['estimateResults'][0]['quote_discount'];
                quoteDiscounts[1] += information['estimates'][i]['estimateResults'][1]['quote_discount'];
                quoteDiscounts[2] += information['estimates'][i]['estimateResults'][2]['quote_discount'];

                taxes[0] += information['estimates'][i]['estimateResults'][0]['tax'];
                taxes[1] += information['estimates'][i]['estimateResults'][1]['tax'];
                taxes[2] += information['estimates'][i]['estimateResults'][2]['tax'];

                rushFees[0] += information['estimates'][i]['estimateResults'][0]['rush_fee'];
                rushFees[1] += information['estimates'][i]['estimateResults'][1]['rush_fee'];
                rushFees[2] += information['estimates'][i]['estimateResults'][2]['rush_fee'];

                totalPrices[0] += information['estimates'][i]['estimateResults'][0]['total'];
                totalPrices[1] += information['estimates'][i]['estimateResults'][1]['total'];
                totalPrices[2] += information['estimates'][i]['estimateResults'][2]['total'];
            }

            let hasCustomerDiscount = customerDiscounts[0] > 0;
            let hasCustomerMarkup = customerDiscounts[0] < 0;
            let hasQuoteDiscount = quoteDiscounts[0] > 0;
            let hasTax = taxes[0] > 0;
            let hasRushFee = rushFees[0] > 0;

            totals = (
                <tr>
                    <td></td>
                    <td></td>
                    <td className="bold text-right">
                        Subtotal <br />
                        {hasTax ? <div>Tax</div> : null}
                        {hasRushFee ? <div>Rush Fee</div> : null}
                        {hasCustomerDiscount ? <div>Customer Discount</div> : null}
                        {hasCustomerMarkup ? <div>Customer Markup</div> : null}
                        {hasQuoteDiscount ? <div>Quote Discount</div> : null}
                        Total <br />
                    </td>
                    <td className="text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        {numeral(subtotals[0]).format('$0,0.00')} <br />
                        {hasTax ? <div>{numeral(taxes[0]).format('$0,0.00')}</div> : null}
                        {hasRushFee ? <div>{numeral(rushFees[0]).format('$0,0.00')}</div> : null}
                        {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[0]).format('$0,0.00')}</div> : null}
                        {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[0]).format('$0,0.00')}</div> : null}
                        {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[0]).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrices[0]).format('$0,0.00')}</b> <br />
                    </td>
                    <td className="text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        {numeral(subtotals[1]).format('$0,0.00')} <br />
                        {hasTax ? <div>{numeral(taxes[1]).format('$0,0.00')}</div> : null}
                        {hasRushFee ? <div>{numeral(rushFees[1]).format('$0,0.00')}</div> : null}
                        {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[1]).format('$0,0.00')}</div> : null}
                        {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[1]).format('$0,0.00')}</div> : null}
                        {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[1]).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrices[1]).format('$0,0.00')}</b> <br />
                    </td>
                    <td className="text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        {numeral(subtotals[2]).format('$0,0.00')} <br />
                        {hasTax ? <div>{numeral(taxes[2]).format('$0,0.00')}</div> : null}
                        {hasRushFee ? <div>{numeral(rushFees[2]).format('$0,0.00')}</div> : null}
                        {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[2]).format('$0,0.00')}</div> : null}
                        {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[2]).format('$0,0.00')}</div> : null}
                        {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[2]).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrices[2]).format('$0,0.00')}</b> <br />
                    </td>
                    <td></td>
                </tr>
            );
        }
        return totals;
    }

    renderMailingTotals() {
        const { information } = this.props;

        let totals = null;
        if (information['estimates'].length > 0) {
            let subtotals = [0, 0, 0];
            let customerDiscounts = [0, 0, 0];
            let quoteDiscounts = [0, 0, 0];
            let taxes = [0, 0, 0];
            let rushFees = [0, 0, 0];
            let totalPrices = [0, 0, 0];
            for (let i = 0; i < information['estimates'].length; i++) {
                if ([1, 2, 3, 4, 15].includes(parseInt(information['estimates'][i]['type']))) {
                    continue;
                }

                subtotals[0] += information['estimates'][i]['estimateResults'][0]['subtotal'] + information['estimates'][i]['estimateResults'][0]['discount'];
                subtotals[1] += information['estimates'][i]['estimateResults'][1]['subtotal'] + information['estimates'][i]['estimateResults'][1]['discount'];
                subtotals[2] += information['estimates'][i]['estimateResults'][2]['subtotal'] + information['estimates'][i]['estimateResults'][2]['discount'];

                customerDiscounts[0] += information['estimates'][i]['estimateResults'][0]['customer_discount'];
                customerDiscounts[1] += information['estimates'][i]['estimateResults'][1]['customer_discount'];
                customerDiscounts[2] += information['estimates'][i]['estimateResults'][2]['customer_discount'];

                quoteDiscounts[0] += information['estimates'][i]['estimateResults'][0]['quote_discount'];
                quoteDiscounts[1] += information['estimates'][i]['estimateResults'][1]['quote_discount'];
                quoteDiscounts[2] += information['estimates'][i]['estimateResults'][2]['quote_discount'];

                taxes[0] += information['estimates'][i]['estimateResults'][0]['tax'];
                taxes[1] += information['estimates'][i]['estimateResults'][1]['tax'];
                taxes[2] += information['estimates'][i]['estimateResults'][2]['tax'];

                rushFees[0] += information['estimates'][i]['estimateResults'][0]['rush_fee'];
                rushFees[1] += information['estimates'][i]['estimateResults'][1]['rush_fee'];
                rushFees[2] += information['estimates'][i]['estimateResults'][2]['rush_fee'];

                totalPrices[0] += information['estimates'][i]['estimateResults'][0]['total'];
                totalPrices[1] += information['estimates'][i]['estimateResults'][1]['total'];
                totalPrices[2] += information['estimates'][i]['estimateResults'][2]['total'];
            }

            let hasCustomerDiscount = customerDiscounts[0] > 0;
            let hasCustomerMarkup = customerDiscounts[0] < 0;
            let hasQuoteDiscount = quoteDiscounts[0] > 0;
            let hasTax = taxes[0] > 0;
            let hasRushFee = rushFees[0] > 0;

            totals = (
                <tr>
                    <td></td>
                    <td></td>
                    <td className="bold text-right">
                        Subtotal <br />
                        {hasTax ? <div>Tax</div> : null}
                        {hasRushFee ? <div>Rush Fee</div> : null}
                        {hasCustomerDiscount ? <div>Customer Discount</div> : null}
                        {hasCustomerMarkup ? <div>Customer Markup</div> : null}
                        {hasQuoteDiscount ? <div>Quote Discount</div> : null}
                        Total <br />
                    </td>
                    <td></td>
                    <td className="text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        {numeral(subtotals[0]).format('$0,0.00')} <br />
                        {hasTax ? <div>{numeral(taxes[0]).format('$0,0.00')}</div> : null}
                        {hasRushFee ? <div>{numeral(rushFees[0]).format('$0,0.00')}</div> : null}
                        {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[0]).format('$0,0.00')}</div> : null}
                        {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[0]).format('$0,0.00')}</div> : null}
                        {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[0]).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrices[0]).format('$0,0.00')}</b> <br />
                    </td>
                    <td className="bold text-right"></td>
                    <td className="text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        {numeral(subtotals[1]).format('$0,0.00')} <br />
                        {hasTax ? <div>{numeral(taxes[1]).format('$0,0.00')}</div> : null}
                        {hasRushFee ? <div>{numeral(rushFees[1]).format('$0,0.00')}</div> : null}
                        {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[1]).format('$0,0.00')}</div> : null}
                        {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[1]).format('$0,0.00')}</div> : null}
                        {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[1]).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrices[1]).format('$0,0.00')}</b> <br />
                    </td>
                    <td className="bold text-right"></td>
                    <td className="text-right" style={{borderRight: '1px solid #dee2e6'}}>
                        {numeral(subtotals[1]).format('$0,0.00')} <br />
                        {hasTax ? <div>{numeral(taxes[1]).format('$0,0.00')}</div> : null}
                        {hasRushFee ? <div>{numeral(rushFees[1]).format('$0,0.00')}</div> : null}
                        {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[1]).format('$0,0.00')}</div> : null}
                        {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[1]).format('$0,0.00')}</div> : null}
                        {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[1]).format('$0,0.00')}</div> : null}
                        <b>{numeral(totalPrices[1]).format('$0,0.00')}</b> <br />
                    </td>
                    <td></td>
                </tr>
            );
        }
        return totals;
    }

    render() {
        const { information, quote, handleShowForm, quoteId, handleSaveElement, isFetchingQuote,
            isFetchingInformation, isSavingForm, isSavingOrder, handleConvertToOrder,
            options, formValues, customerData } = this.props;

        if (isFetchingQuote || isFetchingInformation) {
            return (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin" /> Loading...
                </div>
            );
        }

        let items = null;
        if (quoteId != null) {
            let exportButton = null;
            if (formValues['id'] !== '') {
                exportButton = (
                    <div className="add-button float-right mb-3 mr-2" style={{ width: 140 }}
                        onClick={() => window.open(apiUrls.exportPdf + '?id=' + quoteId, '_blank')}>
                        <div><i className="far fa-file-pdf" /> Export PDF</div>
                    </div>
                );
            }

            let orderButton = null;
            let orderButtonLabel = <div><i className="fas fa-calendar-alt" /> Convert to Order</div>;
            if (isSavingOrder) {
                orderButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
            }
            orderButton = (
                <div className="add-button float-right mb-3 mr-2" onClick={handleConvertToOrder} style={{ width: 160 }}>
                    {orderButtonLabel}
                </div>
            );

            if (information['estimates'].length === 0) {
                orderButton = null;
                exportButton = null;
            }

            items = (
                <div className="items-container">
                    <div className="clearfix"></div>
                    <div className="printing-estimates-table">
                        <b>Printing Items:</b>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Items/Name</th>
                                    <th>Production Notes</th>
                                    <th>Process Description</th>
                                    <th colSpan="3" className="text-center">Quantities</th>
                                    <th className="add">
                                        <div className="add-button float-right mr-0 mb-0" onClick={handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add Item</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderPrintingEstimates()}
                                {this.renderPrintingTotals()}
                            </tbody>
                        </table>
                    </div>
                    <div className="mailing-estimates-table">
                        <b>Mailing Items:</b>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Items/Name</th>
                                    <th>Production Notes</th>
                                    <th>Process Description</th>
                                    <th className="text-center">Quantity</th>
                                    <th className="text-right">Price</th>
                                    <th className="text-center">Quantity</th>
                                    <th className="text-right">Price</th>
                                    <th className="text-center">Quantity</th>
                                    <th className="text-right">Price</th>
                                    <th className="add">
                                        <div className="add-button float-right mr-0 mb-0" onClick={handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add Item</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderMailingEstimates()}
                                {this.renderMailingTotals()}
                            </tbody>
                        </table>
                    </div>
                    {orderButton}
                    {exportButton}
                </div>
            );
        }

        let saveButtonLabel = <div><i className="fas fa-save" /> Save</div>;
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let resellerId = null;
        let taxCode = null;
        let discountTable = null;
        let discountType = null;
        if (customerData != null) {
            if (customerData['reseller']) {
                resellerId = <div><b>Reseller ID:</b> {customerData == null ? null : customerData['reseller_id']}</div>;
                taxCode = <div><b>Tax Code:</b> {customerData == null ? null : customerData['tax_code']}</div>;
            }

            if (parseFloat(customerData['discount']) !== 0) {
                discountTable = <div><b>Discount Table:</b> {customerData['discountName']}</div>;
                discountType = <div><b>{customerData['discount'] < 0 ? 'Markup' : 'Discount'}:</b> {numeral(Math.abs(customerData['discount'])).format('0.[00]%')}</div>;
            }
        }

        const totalQuotes = customerData == null ? null : customerData['total_quotes'];
        const totalOrders = customerData == null ? null : customerData['total_orders'];
        return (
            <div className="quote-container">
                <div className="statistics-container">
                    <div className="text-left title">Quote Information</div>
                    <div className="text-right">Total Quotes: {totalQuotes} | Total Orders: {totalOrders} | In Production: N/A</div>
                </div>
                <div className="quote-information-container">
                    <div className="quote-information">
                        <div className="card mb-2">
                            <div className="card-header">Quote Information</div>
                            <div className="card-body">
                                <div className="row">
                                    {this.renderInput('Title', 'name', 'text', true, 'col-8 title-field')}
                                    {this.renderInput('PO', 'customer_po', 'text', false, 'col-4 po-field')}
                                    <div className="w-100"></div>
                                    {this.renderSelect('CSR', 'customer_service_rep_id', options['customerServiceReps'], true, 'col csr-field')}
                                    {this.renderSelect('Sales Rep', 'sales_rep_id', options['salesReps'], true, 'col sales-field')}
                                    {this.renderSelect('Status', 'status_id', options['statuses'], true, 'col status-field')}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Turnaround', 'turnaround_id', options['turnaround'], true, 'col-4 turnaround-field')}
                                    {this.renderInput('Mail Date', 'due_date', 'text', false, 'col-4 mail-date-field')}
                                    <div className="w-100"></div>
                                    <div className="completion-date">
                                        <div>Completion Date: N/A</div>
                                        <div>Number of days to Mail Date: N/A</div>
                                    </div>
                                    <div className="col float-right">
                                        <div className="add-button float-right mb-0" onClick={handleSaveElement}>
                                            {saveButtonLabel}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-information">
                        <div className="card mb-2">
                            <div className="card-header">Customer Information</div>
                            <div className="card-body">
                                <div><b>ID:</b> {customerData == null ? null : customerData['id']}</div>
                                <div><b>Contact:</b> {customerData == null ? null : customerData['name']}</div>
                                <div><b>Company:</b> {customerData == null ? null : customerData['display_name']}</div>
                                <div><b>Email:</b> {customerData == null ? null : customerData['email']}</div>
                                <div><b>Address:</b> {customerData == null ? null : customerData['street']}</div>
                                <div><b>City:</b> {customerData == null ? null : customerData['city']}</div>
                                <div><b>State:</b> {customerData == null ? null : customerData['state']} <b>Zip:</b> {customerData == null ? null : customerData['zip']}</div>
                                <div><b>Phone:</b> {customerData == null ? null : customerData['phone']}</div>
                                {discountTable}
                                {discountType}
                                {resellerId}
                                {taxCode}
                                <button onClick={this.props.showCustomersHandler}><i className="fas fa-user-cog"></i> Change</button>
                            </div>
                        </div>
                    </div>
                    <div className="quote-log">
                        <div className="card mb-2">
                            <div className="card-header">Quote Log</div>
                            <div className="card-body">
                                <div><b>Quote ID:</b> {quote == null ? null : quote['id']}</div>
                                <div><b>Created At:</b> {quote == null ? null : dateToTimezone(quote['created_at'], 'MM/DD/YYYY hh:mm a')}</div>
                                <div><b>Last Updated At:</b> {quote == null ? null : dateToTimezone(quote['updated_at'], 'MM/DD/YYYY hh:mm a')}</div>
                                <div><b>Created By:</b> {quote == null ? null : quote['created_by_name']}</div>
                                <div><b>Last Updated By:</b> {quote == null ? null : quote['updated_by_name']}</div>
                                <div><b>Price Matched:</b> N/A</div>
                                <div><b>Original Price:</b> N/A</div>
                                <div><b>Approved By:</b> N/A</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100"></div>
                    {items}
                    <div className="w-100"></div>
                    {/*this.renderTextArea('Notes', 'notes', false, 'col notes-field')*/}
                    {/*this.renderTextArea('Private Notes', 'private_notes', false, 'col private-notes-field')*/}
                    <div className="w-100"></div>
                </div>
            </div>
        );
    }
}

export default Quote;
