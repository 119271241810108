import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getModel = state => state[NAME];

/** information **/
export const getIsFetchingInformation = createSelector(
    getModel,
    (model) => model.information.isFetching
);
export const getTags = createSelector(
    getModel,
    (model) => model.information.tags
);
export const getPoints = createSelector(
    getModel,
    (model) => model.information.points
);
export const getAnchors = createSelector(
    getModel,
    (model) => model.information.anchors
);
export const getDistances = createSelector(
    getModel,
    (model) => model.information.distances
);
export const getLines = createSelector(
    getModel,
    (model) => model.information.lines
);
