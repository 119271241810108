const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/landrys-invoices`,
    search: `${API_URL}/landrys-invoice/search`,
    data: `${API_URL}/landrys-invoice/data`,
    sizes: `${API_URL}/landrys-invoice-sizes`,
    stocks: `${API_URL}/landrys-invoice-stocks`,
    machines: `${API_URL}/landrys-invoice-machines`,
    export: `${API_URL}/landrys-invoice/export`
};

export default apiUrls;
