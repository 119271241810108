import { NAME } from './constants';
import reducer from './reducer';
import Container from './Container';
import * as actions from './actions';
import * as selectors from './selectors';
import * as components from './components';

export default {
	NAME,
	reducer,
	actions,
	selectors,
	Container,
	components
};