import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Receive extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleReceive: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideReceive: PropTypes.func,
        isReceiving: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        data: PropTypes.object,
        handleNotificationClick: PropTypes.func
    };

    renderInput(label, name, type, required, readOnly = false, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    readOnly={readOnly}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    render() {
        const { hideReceive, handleReceive, isReceiving, formValues, isFetchingElement } = this.props;

        let saveButtonLabel = 'Notify';
        if (isReceiving) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">Receive</div>
                    <div className="w-100"></div>
                    <div className="col" style={{ fontSize: 14, marginTop: 10 }}>
                        <b>Customer:</b> {formValues['customer']} <br />
                        <b>Description:</b> {formValues['description']} <br />
                        <b>Quantity:</b> {formValues['quantity']} <br />
                        <b>PM #:</b> {formValues['pm_number']} <br />
                        <b>PS #:</b> {formValues['ps_number']} <br />
                        <b>Notes:</b> {formValues['notes']} <br />
                        <b>Created By:</b> {formValues['rep_name']} <br />
                    </div>
                    <div className="w-100"></div>
                    {this.renderInput('Notes', 'receive_description', 'text', true)}
                    {this.renderInput('Location', 'location', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('# of Containers', 'containers_number', 'number', true)}
                    {this.renderInput('Type of Container', 'containers_type', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Pcs per Container', 'containers_pieces', 'number', true)}
                    {this.renderInput('Aisle', 'aisle', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Rack', 'rack', 'text', false)}
                    {this.renderInput('Level', 'level', 'text', false)}
                    <div className="w-100"></div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleReceive} disabled={isReceiving}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideReceive}>Close</button>
        ];
        return (
            <Dialog
                name="receive"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Receive;
