const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/deposits`,
    search: `${API_URL}/deposit/search`,
    data: `${API_URL}/deposit/data`,
    payors: `${API_URL}/payors`,
    export: `${API_URL}/deposit/export`,
    sendEmail: `${API_URL}/deposit/send-email`
};

export default apiUrls;
