import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleShowActivePresses: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleChangeFilter: PropTypes.func
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, isFetchingElements, handleShowForm, handleShowDelete } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No Results</td>
                </tr>
            );
        }

        let data = [];
        for (let i = 0; i < elements.length; i++) {
            data.push(
                <tr key={i}>
                    <td>{elements[i].id}</td>
                    <td>{elements[i].paper_category_name}</td>
                    <td>{elements[i].style}</td>
                    <td>{elements[i].envelope_type_name}</td>
                    <td>{elements[i].size_name}</td>
                    <td>{elements[i].base_weight}</td>
                    <td>{elements[i].per_box}</td>
                    <td>{elements[i].per_cnt}</td>
                    <td>{numeral(elements[i].ten_cnt).format('$0,0.00')}</td>
                    <td>{numeral(elements[i].five_cnt).format('$0,0.00')}</td>
                    <td>{numeral(elements[i].one_cnt).format('$0,0.00')}</td>
                    <td>{numeral(elements[i].box).format('$0,0.00')}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements[i].id, null)} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, elements[i].id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    handleKey(name, event) {
        const { handleChangeFilter } = this.props;
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onBlur={handleChangeFilter.bind(this, name)} onKeyPress={this.handleKey.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { totalItems, totalPages, selectedPage, elementsPerPage, handleSetActivePage } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('ID', 'id', { width: '5%' })}
                                {this.renderTableHeader('Category', 'paper_category_name', { width: 'calc(13% - 35px)' })}
                                {this.renderTableHeader('Style', 'style', { width: 'calc(17% - 35px)' })}
                                {this.renderTableHeader('Type', 'envelope_type_name', { width: '8%' })}
                                {this.renderTableHeader('Size', 'size_name', { width: '7%' })}
                                {this.renderTableHeader('Base Weight', 'base_weight', { width: '8%' })}
                                {this.renderTableHeader('Per Box', 'per_box', { width: '7%' })}
                                {this.renderTableHeader('Per Cnt', 'per_cnt', { width: '7%' })}
                                {this.renderTableHeader('10 Cnt', 'ten_cnt', { width: '7%' })}
                                {this.renderTableHeader('5 Cnt', 'five_cnt', { width: '7%' })}
                                {this.renderTableHeader('1 Cnt', 'one_cnt', { width: '7%' })}
                                {this.renderTableHeader('Box', 'box', { width: '7%' })}
                                <th style={{ width: 70 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {this.renderSearchField('id')}
                                {this.renderSearchField('paper_category_name')}
                                {this.renderSearchField('style')}
                                {this.renderSearchField('envelope_type_name')}
                                {this.renderSearchField('size_name')}
                                {this.renderSearchField('base_weight')}
                                {this.renderSearchField('per_box')}
                                {this.renderSearchField('per_cnt')}
                                {this.renderSearchField('ten_cnt')}
                                {this.renderSearchField('five_cnt')}
                                {this.renderSearchField('one_cnt')}
                                {this.renderSearchField('box')}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={totalItems}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                        handleSetActivePage={handleSetActivePage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
