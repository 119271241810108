import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Quote, Form, Delete, Customers } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import numeral from 'numeral';
import { Helmet } from 'react-helmet';
import cloneDeep from 'lodash/cloneDeep';
import { showNotification } from './../../utils';
import Layout from './../Layout';
import './styles.scss';

class Container extends Component {
    static propTypes = {
        
    };

    componentWillMount() {
        if (this.props.match.params.customerId) {
            this.props.setCustomerId(this.props.match.params.customerId);
        }

        if (this.props.match.params.quoteId) {
            this.props.setQuoteId(this.props.match.params.quoteId);
            this.props.fetchQuote(this.props.match.params.quoteId);
        } else {
            this.props.resetEstimateForm();
            this.props.setEditingCustomer(1);
        }

        this.props.fetchInformation();
        this.props.setHistory(this.props.history);
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { setFormError, clearResults, setProduct, information, intersectPaperCategories,
            intersectPaperWeights, intersectPapers, intersectPresses, formValues, filterEnvelopePaperStyles,
            updateState, updateMailingPrices, filterEnvelopePaperSizes, filterEnvelopePaperWeights } = this.props;

        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let values = {};
        values[event.target.name] = value;
        let newValues = cloneDeep(formValues);
        newValues[event.target.name] = value;

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        let fetching = {};
        let options = {};
        if (event.target.name === 'estimate_paper_category_id') {
            intersectPaperWeights(newValues);
            fetching.paperWeights = true;
            options.paperWeights = [];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
        } else if (event.target.name === 'estimate_paper_weight') {
            intersectPapers(newValues);
            fetching.papers = true;
            options.papers = [];
            values.estimate_paper_id = '';
        } else if (event.target.name === 'estimate_cover_press_id') {
            intersectPaperCategories(newValues, true);
            fetching.coverPaperCategories = true;
            options.coverPaperCategories = [];
            options.coverPaperWeights = [{ label: 'Select option', value: '' }];
            options.coverPapers = [{ label: 'Select option', value: '' }];
            values.estimate_cover_paper_category_id = '';
            values.estimate_cover_paper_weight = '';
            values.estimate_cover_paper_id = '';
            values.estimate_cover_running_style = '';
        } else if (event.target.name === 'estimate_cover_paper_category_id') {
            intersectPaperWeights(newValues, true);
            fetching.coverPaperWeights = true;
            options.coverPaperWeights = [];
            options.coverPapers = [{ label: 'Select option', value: '' }];
            values.estimate_cover_paper_weight = '';
            values.estimate_cover_paper_id = '';
        } else if (event.target.name === 'estimate_cover_paper_weight') {
            intersectPapers(newValues, true);
            fetching.coverPapers = true;
            options.coverPapers = [];
            values.estimate_cover_paper_id = '';
        } else if (event.target.name === 'estimate_type') {
            clearResults(value);
            this.props.fetchEstimateInformation(value);
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
            values.estimate_envelope_paper_style = '';
            values.estimate_envelope_paper_size = '';
            values.estimate_envelope_paper_weight = '';
            values.estimate_front_coating = '';
            values.estimate_back_coating = '';
            values.estimate_interior_coating = '';

            if ([2, 3, 6, 7].includes(formValues['estimate_multi_page_type'])) {
                intersectPaperCategories(newValues, true);
                fetching.coverPaperCategories = true;
                options.coverPaperCategories = [];
                options.coverPaperWeights = [{ label: 'Select option', value: '' }];
                options.coverPapers = [{ label: 'Select option', value: '' }];
                values.estimate_cover_paper_category_id = '';
                values.estimate_cover_paper_weight = '';
                values.estimate_cover_paper_id = '';
            }
        } else if (event.target.name === 'estimate_press_id') {
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
            values.estimate_running_style = '';
        } else if (event.target.name === 'estimate_second_press_id') {
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
            values.estimate_running_style = '';
        } else if (event.target.name === 'estimate_digital_machine_id') {
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
        } else if (event.target.name === 'estimate_multi_page_type') {
            clearResults(value);
        } else if (event.target.name === 'estimate_product_id') {
            setProduct(information['products'], value);

            let product = null;
            for (let i = 0; i < information['products'].length; i++) {
                if (parseInt(information['products'][i]['id']) === parseInt(value)) {
                    product = information['products'][i];
                }
            }

            newValues['estimate_type'] = product['type'];
            newValues['estimate_cover_press_id'] = product['cover_press_id'];
            newValues['estimate_press_id'] = product['press_id'];
            newValues['estimate_second_press_id'] = product['second_press_id'];
            newValues['estimate_digital_machine_id'] = product['digital_machine_id'];
            newValues['estimate_product_id'] = product['product_id'];
            newValues['estimate_cover_paper_category_id'] = product['cover_paper_category_id'];
            newValues['estimate_paper_category_id'] = product['paper_category_id'];
            newValues['estimate_cover_paper_weight'] = product['cover_paper_weight'];
            newValues['estimate_paper_weight'] = product['paper_weight'];

            intersectPresses();
            intersectPaperCategories(newValues);
            intersectPaperWeights(newValues);
            intersectPapers(newValues);
            fetching.paperCategories = true;
            fetching.paperWeights = true;
            fetching.papers = true;
            options.paperCategories = [];
            options.paperWeights = [];
            options.papers = [];

            if ([2, 3, 6, 7].includes(parseInt(product['multi_page_type']))) {
                intersectPaperCategories(newValues, true);
                intersectPaperWeights(newValues, true);
                intersectPapers(newValues, true);
                fetching.coverPaperCategories = true;
                fetching.coverPaperWeights = true;
                fetching.coverPapers = true;
                options.coverPaperCategories = [];
                options.coverPaperWeights = [];
                options.coverPapers = [];
            }
        } else if (event.target.name === 'estimate_customer_discount_id') {
            setTimeout(() => {
                updateMailingPrices();
            }, 50);
        } else if (event.target.name === 'estimate_envelope_paper_type') {
            filterEnvelopePaperStyles(value);
            values.estimate_envelope_paper_style = '';
            values.estimate_envelope_paper_size = '';
            values.estimate_envelope_paper_weight = '';
        } else if (event.target.name === 'estimate_envelope_paper_style') {
            filterEnvelopePaperSizes(value);
            values.estimate_envelope_paper_size = '';
            values.estimate_envelope_paper_weight = '';
        } else if (event.target.name === 'estimate_envelope_size') {
            filterEnvelopePaperWeights(value);
            values.estimate_envelope_paper_weight = '';
        } else if (event.target.name === 'estimate_front_inks' || event.target.name === 'estimate_back_inks') {
            values.estimate_envelope_press_id = '';
        }

        let newState = { formValues: values };

        let keys = Object.keys(fetching);
        if (keys.length > 0) {
            newState.fetching = fetching;
        }

        keys = Object.keys(options);
        if (keys.length > 0) {
            newState.options = options;
        }

        updateState(newState);
        setFormError(event.target.name, error, errorMessage);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType } = this.props;
        if (sortColumn === column) {
            setSort(column, sortType === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(column, 'asc');
        }
    }

    handleInlineChange(index, attribute, value) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(value, elements, index, attribute);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleSaveElement() {
        const { saveElement, formValues, elements, customerId } = this.props;
        saveElement(formValues, elements, customerId);
    }

    handleShowForm(id) {
        const { showForm, resetEstimateForm, information, intersectPaperCategories,
            formValues, updateState, fetchEstimate, updateFormValue } = this.props;

        let fetching = {};
        let options = {};
        if (id == null) {
            this.props.fetchEstimateInformation(1);
            resetEstimateForm(id);
            intersectPaperCategories(formValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];

            if ([2, 3, 6, 7].includes(formValues['estimate_multi_page_type'])) {
                intersectPaperCategories(formValues, true);
                fetching.coverPaperCategories = true;
                options.coverPaperCategories = [];
                options.coverPaperWeights = [{ label: 'Select option', value: '' }];
                options.coverPapers = [{ label: 'Select option', value: '' }];
            }

            if (information['estimates'].length === 0) {
                updateFormValue('estimate_product_description', formValues['name']);
            }
        } else {
            fetchEstimate(id);
        }

        let newState = {};

        let keys = Object.keys(fetching);
        if (keys.length > 0) {
            newState.fetching = fetching;
        }

        keys = Object.keys(options);
        if (keys.length > 0) {
            newState.options = options;
        }

        updateState(newState);
        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteEstimate, idForDelete, information } = this.props;
        deleteEstimate(idForDelete, information['estimates']);
    }

    handleChangeAttribute(index, attribute, type, value) {
        const { elements, changeAttribute, updateAttribute } = this.props;

        let valueToSave = value;
        if (type === 'money') {
            valueToSave = numeral(value).format('0.00');
            value = numeral(value).format('$0,0.00');
        } else if (type === 'number') {
            if (parseInt(value).toString().match(/^[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        } else if (type === 'date') {
            valueToSave = moment(value).format('YYYY-MM-DD');
            value = valueToSave;
        } else if (type === 'decimal') {
            if (parseInt(value).toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        }

        changeAttribute(value, elements, index, attribute);
        updateAttribute(elements[index].id, attribute, valueToSave, elements, index);
    }

    handleEstimate() {
        const { estimate, formValues } = this.props;
        estimate(formValues);
    }

    handleSaveEstimate() {
        const { saveEstimate, formValues, estimateResults, information, quoteId } = this.props;
        saveEstimate(formValues, estimateResults, information['estimates'], quoteId);
    }

    handleConvertToOrder() {
        const { convertToOrder, quoteId, information } = this.props;
        convertToOrder(quoteId, information['estimates']);
    }

    handleSetItemQuantity(index, quantity) {
        const { setItemQuantity, information } = this.props;
        setItemQuantity(information['estimates'], index, quantity);
    }

    handleSetCustomerId(event) {
        const { setCustomerId, setEditingCustomer } = this.props;
        setCustomerId(event.target.value);
        setEditingCustomer(0);
    }

    handleEditingCustomer(editing) {
        const { setEditingCustomer } = this.props;
        setEditingCustomer(editing);
    }

    handleAccessoryClick(id, event) {
        const { setAccessories, estimateAccessories } = this.props;
        setAccessories(estimateAccessories, id, event.target.checked);
    }

    handleAddOutsideService() {
        const { addOutsideService, updateMailingPrices, formValues } = this.props;
        addOutsideService({ cost: '', percentage_markup: '', description: '' });

        if (![1, 2, 3, 4].includes(parseInt(formValues['type']))) {
            setTimeout(() => {
                updateMailingPrices();
            }, 50);
        }
    }

    handleDeleteOutsideService(index) {
        const { deleteOutsideService, updateMailingPrices, formValues } = this.props;
        deleteOutsideService(index);

        if (![1, 2, 3, 4].includes(parseInt(formValues['type']))) {
            setTimeout(() => {
                updateMailingPrices();
            }, 50);
        }
    }

    handleUpdateOutsideService(name, index, event) {
        const { updateOutsideService, formValues, updateMailingPrices } = this.props;
        formValues['estimate_outside_services'][index][name] = event.target.value;
        updateOutsideService(index, formValues['estimate_outside_services'][index]);

        if (![1, 2, 3, 4].includes(parseInt(formValues['type']))) {
            setTimeout(() => {
                updateMailingPrices();
            }, 50);
        }
    }

    handleUpdateMailingElement(name, attribute, index, event) {
        const { updateMailingElement, updateMailingPrices } = this.props;
        updateMailingElement(name, attribute, index, event.target.value);
        setTimeout(() => {
            updateMailingPrices();
        }, 50);
    }

    handleRemoveMailingElement(name, index) {
        const { removeMailingElement, updateMailingPrices } = this.props;
        removeMailingElement(name, index);
        setTimeout(() => {
            updateMailingPrices();
        }, 50);
    }

    showCustomersHandler() {
        this.props.showCustomers();
        this.props.fetchCustomers();
    }

    changeCustomerHandler() {
        this.props.fetchCustomer(this.props.customerId);
        this.props.hideCustomers();
        this.props.updateState({ information: { customers: [] } });
        this.props.saveElement(this.props.formValues, this.props.elements, this.props.customerId);
    }

    hideCustomersHandler() {
        this.props.hideCustomers();
        this.props.updateState({ information: { customers: [] } });
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, hideForm, isDeleting, hideDelete, formErrorMessages,
            quote, customerId, isLoadingEstimate, estimateResults, isSavingEstimate, isFetchingQuote, quoteId,
            isFetchingInformation, isSavingOrder, editingCustomer, activeProduct, estimateAccessories, information,
            options, fetching, addMailingElement, customerData } = this.props;
        return (
            <Layout>
                <div id="quote-container" className="container-fluid quote-module-container">
                    <Helmet>
                        <title>Dashboard</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title={quoteId == null ? 'New Quote' : 'Edit Quote'}
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            <Quote
                                elements={elements}
                                isFetchingElements={isFetchingElements}
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                elementsPerPage={elementsPerPage}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                user={user}
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                information={information}
                                options={options}
                                quote={quote}
                                isFetchingQuote={isFetchingQuote}
                                isFetchingInformation={isFetchingInformation}
                                isSavingOrder={isSavingOrder}
                                customerId={customerId}
                                editingCustomer={editingCustomer}
                                quoteId={quoteId}
                                customerData={customerData}
                                handleSetCustomerId={this.handleSetCustomerId.bind(this)}
                                handleSort={this.handleSort.bind(this)}
                                handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                handleInlineChange={this.handleInlineChange.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleDayChange={this.handleDayChange.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                                handleConvertToOrder={this.handleConvertToOrder.bind(this)}
                                handleSetItemQuantity={this.handleSetItemQuantity.bind(this)}
                                handleEditingCustomer={this.handleEditingCustomer.bind(this)}
                                showCustomersHandler={this.showCustomersHandler.bind(this)}
                            />
                            <Form
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                isLoadingEstimate={isLoadingEstimate}
                                estimateResults={estimateResults}
                                isSavingEstimate={isSavingEstimate}
                                activeProduct={activeProduct}
                                estimateAccessories={estimateAccessories}
                                information={information}
                                options={options}
                                fetching={fetching}
                                isFetchingEstimateInformation={this.props.isFetchingEstimateInformation}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={hideForm.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                                handleEstimate={this.handleEstimate.bind(this)}
                                handleSaveEstimate={this.handleSaveEstimate.bind(this)}
                                handleAccessoryClick={this.handleAccessoryClick.bind(this)}
                                handleAddOutsideService={this.handleAddOutsideService.bind(this)}
                                handleDeleteOutsideService={this.handleDeleteOutsideService.bind(this)}
                                handleUpdateOutsideService={this.handleUpdateOutsideService.bind(this)}
                                addMailingElement={addMailingElement.bind(this)}
                                handleUpdateMailingElement={this.handleUpdateMailingElement.bind(this)}
                                handleRemoveMailingElement={this.handleRemoveMailingElement.bind(this)}
                            />
                            <Customers
                                customers={this.props.information.customers}
                                fetching={this.props.fetching}
                                customerId={this.props.customerId}
                                hideCustomersHandler={this.hideCustomersHandler.bind(this)}
                                changeCustomerHandler={this.changeCustomerHandler.bind(this)}
                                handleSetCustomerId={this.handleSetCustomerId.bind(this)}
                            />
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    customerId: selectors.getCustomerId,
    quoteId: selectors.getQuoteId,
    quote: selectors.getQuote,
    isFetchingQuote: selectors.getIsFetchingQuote,
    isFetchingInformation: selectors.getIsFetchingInformation,
    isLoadingEstimate: selectors.getIsLoadingEstimate,
    estimateResults: selectors.getEstimateResults,
    isSavingEstimate: selectors.getIsSavingEstimate,
    isSavingOrder: selectors.getIsSavingOrder,
    editingCustomer: selectors.getEditingCustomer,
    activeProduct: selectors.getActiveProduct,
    estimateAccessories: selectors.getEstimateAccessories,
    information: selectors.getInformation,
    options: selectors.getOptions,
    fetching: selectors.getFetching,
    customerData: selectors.getCustomerData,
    isFetchingEstimateInformation: selectors.getIsFetchingEstimateInformation,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: () => dispatch(actions.fetchElements()),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (id, elements) => dispatch(actions.deleteElement(id, elements)),
        deleteEstimate: (id, estimates) => dispatch(actions.deleteEstimate(id, estimates)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        showCustomers: () => dispatch(dialog.actions.showDialog('customers')),
        hideCustomers: () => dispatch(dialog.actions.hideDialog('customers')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: (values, elements, customerId) => dispatch(actions.saveElement(values, elements, customerId)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        resetEstimateForm: () => dispatch(actions.resetEstimateForm()),
        changeAttribute: (value, elements, index, attribute) => dispatch(actions.changeAttribute(value, elements, index, attribute)),
        updateAttribute: (id, attribute, value, elements, index) => dispatch(actions.updateAttribute(id, attribute, value, elements, index)),
        estimate: (values) => dispatch(actions.estimate(values)),
        saveEstimate: (values, estimateResults, estimates, quoteId) => dispatch(actions.saveEstimate(values, estimateResults, estimates, quoteId)),
        convertToOrder: (id, estimates) => dispatch(actions.convertToOrder(id, estimates)),
        setItemQuantity: (estimates, index, quantity) => dispatch(actions.setItemQuantity(estimates, index, quantity)),
        setCustomerId: (id) => dispatch(actions.setCustomerId(id)),
        setEditingCustomer: (editing) => dispatch(actions.setEditingCustomer(editing)),
        clearResults: (value) => dispatch(actions.clearResults(value)),
        setProduct: (products, productId) => dispatch(actions.setProduct(products, productId)),
        setAccessories: (accessories, id, checked) => dispatch(actions.setAccessories(accessories, id, checked)),
        fetchInformation: () => dispatch(actions.fetchInformation()),
        addOutsideService: (value) => dispatch(actions.addOutsideService(value)),
        deleteOutsideService: (index) => dispatch(actions.deleteOutsideService(index)),
        updateOutsideService: (index, value) => dispatch(actions.updateOutsideService(index, value)),
        intersectPresses: () => dispatch(actions.intersectPresses()),
        intersectPaperCategories: (formValues, isCover) => dispatch(actions.intersectPaperCategories(formValues, isCover)),
        intersectPaperWeights: (formValues, isCover) => dispatch(actions.intersectPaperWeights(formValues, isCover)),
        intersectPapers: (formValues, isCover) => dispatch(actions.intersectPapers(formValues, isCover)),
        resetState: () => dispatch(actions.resetState()),
        setLoading: (name, value) => dispatch(actions.setLoading(name, value)),
        resetOptions: (name) => dispatch(actions.resetOptions(name)),
        updateState: (values) => dispatch(actions.updateState(values)),
        fetchEstimate: (id) => dispatch(actions.fetchEstimate(id)),
        addMailingElement: (name, value) => dispatch(actions.addMailingElement(name, value)),
        updateMailingElement: (name, attribute, index, value) => dispatch(actions.updateMailingElement(name, attribute, index, value)),
        removeMailingElement: (name, index) => dispatch(actions.removeMailingElement(name, index)),
        updateMailingPrices: () => dispatch(actions.updateMailingPrices()),
        setQuoteId: (id) => dispatch(actions.setQuoteId(id)),
        fetchQuote: (id) => dispatch(actions.fetchQuote(id)),
        setHistory: (history) => dispatch(actions.setHistory(history)),
        fetchCustomers: () => dispatch(actions.fetchCustomers()),
        fetchCustomer: (id) => dispatch(actions.fetchCustomer(id)),
        fetchEstimateInformation: (type) => dispatch(actions.fetchEstimateInformation(type)),
        filterEnvelopePaperStyles: (value) => dispatch(actions.filterEnvelopePaperStyles(value)),
        filterEnvelopePaperSizes: (value) => dispatch(actions.filterEnvelopePaperSizes(value)),
        filterEnvelopePaperWeights: (value) => dispatch(actions.filterEnvelopePaperWeights(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
