import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = () => dispatch => {
    let fields = [
        'id',
        'name',
        'product_type',
        'product_category_id',
        'product_category_name',
        'configuration_json'
    ];

    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements + '?fields=' + fields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchProductCategories = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_PRODUCT_CATEGORIES, payload: null });
    axios.get(apiUrls.productCategories,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PRODUCT_CATEGORIES_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_PRODUCT_CATEGORIES_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const saveElement = (values, elements) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name', 'product_type', 'product_category_id'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';

        if (!validateConfiguration(values, dispatch)) {
            return;
        }
    }
    values.stringify_configuration_json = JSON.stringify(values.configuration_json);

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, values.id === '' ? 1 : 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function displayMissedField(name) {
    return dispatch => {
        showNotification('Complete the Information', 'You selected not to show or lock the "' + name + '" field, enter a default value.', 'info');
    };
}

export function validateConfigurationField(field) {
    return (field['show'] === 0 || field['lock'] === 1) && field['default'] === '';
}

export function validateConfiguration(values, dispatch) {
    const productOptions = values['configuration_json']['product_options'];

    if (validateConfigurationField(productOptions['product_category_id'])) {
        dispatch(displayMissedField('Product Category'));
        return false;
    }
    if (validateConfigurationField(values['configuration_json']['product_description'])) {
        dispatch(displayMissedField('Product Description'));
        return false;
    }
    if (validateConfigurationField(values['configuration_json']['production_notes'])) {
        dispatch(displayMissedField('Production Notes'));
        return false;
    }

    if (parseInt(values['product_type']) === 1) {   // Flat
        if (validateConfigurationField(productOptions['press_id'])) {
            dispatch(displayMissedField('Press'));
            return false;
        }
        if (validateConfigurationField(productOptions['running_style'])) {
            dispatch(displayMissedField('Running Style'));
            return false;
        }
        if (validateConfigurationField(productOptions['flat_width'])) {
            dispatch(displayMissedField('Flat Width'));
            return false;
        }
        if (validateConfigurationField(productOptions['flat_height'])) {
            dispatch(displayMissedField('Flat Height'));
            return false;
        }
        if (validateConfigurationField(productOptions['finish_width'])) {
            dispatch(displayMissedField('Finish Width'));
            return false;
        }
        if (validateConfigurationField(productOptions['finish_height'])) {
            dispatch(displayMissedField('Finish Height'));
            return false;
        }
        if (validateConfigurationField(productOptions['paper_category_id'])) {
            dispatch(displayMissedField('Paper Category'));
            return false;
        }
        if (validateConfigurationField(productOptions['paper_weight'])) {
            dispatch(displayMissedField('Paper Weight'));
            return false;
        }
        if (validateConfigurationField(productOptions['paper_id'])) {
            dispatch(displayMissedField('Paper'));
            return false;
        }
    } else if (parseInt(values['product_type']) === 2) {   // Multi-page
        const pressOptions = values['configuration_json']['press_options'];
        const paperOptions = values['configuration_json']['paper_options'];

        if (validateConfigurationField(productOptions['pages'])) {
            dispatch(displayMissedField('Pages'));
            return false;
        }
        if (validateConfigurationField(productOptions['finish_width'])) {
            dispatch(displayMissedField('Finish Width'));
            return false;
        }
        if (validateConfigurationField(productOptions['finish_height'])) {
            dispatch(displayMissedField('Finish Height'));
            return false;
        }
        if ([6, 7].includes(parseInt(productOptions['multi_page_type']['default']))) {  // Coil
            if (validateConfigurationField(productOptions['coil_option'])) {
                dispatch(displayMissedField('Coil Option'));
                return false;
            }
            if (validateConfigurationField(productOptions['coil_color'])) {
                dispatch(displayMissedField('Coil Color'));
                return false;
            }
        }
        if ([4, 5].includes(parseInt(productOptions['multi_page_type']['default']))) {  // Staple
            if (validateConfigurationField(productOptions['staples_number'])) {
                dispatch(displayMissedField('Staples Number'));
                return false;
            }
            if (validateConfigurationField(productOptions['staples_location'])) {
                dispatch(displayMissedField('Staples Location'));
                return false;
            }
        }
        if (validateConfigurationField(pressOptions['press_id'])) {
            dispatch(displayMissedField('Press'));
            return false;
        }
        if (validateConfigurationField(pressOptions['running_style'])) {
            dispatch(displayMissedField('Running Style'));
            return false;
        }
        if (validateConfigurationField(pressOptions['second_press_id'])) {
            dispatch(displayMissedField('Second Press'));
            return false;
        }
        if (validateConfigurationField(pressOptions['second_running_style'])) {
            dispatch(displayMissedField('Second Running Style'));
            return false;
        }
        if (validateConfigurationField(paperOptions['paper_category_id'])) {
            dispatch(displayMissedField('Paper Category'));
            return false;
        }
        if (validateConfigurationField(paperOptions['paper_weight'])) {
            dispatch(displayMissedField('Paper Weight'));
            return false;
        }
        if (validateConfigurationField(paperOptions['paper_id'])) {
            dispatch(displayMissedField('Paper'));
            return false;
        }
        if ([2, 5, 7, 9].includes(parseInt(productOptions['multi_page_type']['default']))) {      // Has Cover
            if (validateConfigurationField(pressOptions['cover_press_id'])) {
                dispatch(displayMissedField('Cover Press'));
                return false;
            }
            if (validateConfigurationField(pressOptions['cover_running_style'])) {
                dispatch(displayMissedField('Cover Running Style'));
                return false;
            }
            if (validateConfigurationField(paperOptions['cover_paper_category_id'])) {
                dispatch(displayMissedField('Cover Paper Category'));
                return false;
            }
            if (validateConfigurationField(paperOptions['cover_paper_weight'])) {
                dispatch(displayMissedField('Cover Paper Weight'));
                return false;
            }
            if (validateConfigurationField(paperOptions['cover_paper_id'])) {
                dispatch(displayMissedField('Cover Paper'));
                return false;
            }
        }
    } else if (parseInt(values['product_type']) === 3) {   // Digital
        if (validateConfigurationField(productOptions['digital_machine_id'])) {
            dispatch(displayMissedField('Digital Machine'));
            return false;
        }
        if (validateConfigurationField(productOptions['digital_inks'])) {
            dispatch(displayMissedField('Digital Inks'));
            return false;
        }
        if (validateConfigurationField(productOptions['digital_sides'])) {
            dispatch(displayMissedField('Digital Sides'));
            return false;
        }
        if (validateConfigurationField(productOptions['flat_width'])) {
            dispatch(displayMissedField('Flat Width'));
            return false;
        }
        if (validateConfigurationField(productOptions['flat_height'])) {
            dispatch(displayMissedField('Flat Height'));
            return false;
        }
        if (validateConfigurationField(productOptions['finish_width'])) {
            dispatch(displayMissedField('Finish Width'));
            return false;
        }
        if (validateConfigurationField(productOptions['finish_height'])) {
            dispatch(displayMissedField('Finish Height'));
            return false;
        }
        if (validateConfigurationField(productOptions['paper_category_id'])) {
            dispatch(displayMissedField('Paper Category'));
            return false;
        }
        if (validateConfigurationField(productOptions['paper_weight'])) {
            dispatch(displayMissedField('Paper Weight'));
            return false;
        }
        if (validateConfigurationField(productOptions['paper_id'])) {
            dispatch(displayMissedField('Paper'));
            return false;
        }
    } else if (parseInt(values['product_type']) === 4) {   // Large Format
        const substrateOptions = values['configuration_json']['substrate_options'];

        if (validateConfigurationField(productOptions['large_format_machine_id'])) {
            dispatch(displayMissedField('Large Format Machine'));
            return false;
        }
        if (validateConfigurationField(substrateOptions['flat_width'])) {
            dispatch(displayMissedField('Width'));
            return false;
        }
        if (validateConfigurationField(substrateOptions['flat_height'])) {
            dispatch(displayMissedField('Height'));
            return false;
        }
        if (validateConfigurationField(substrateOptions['unit'])) {
            dispatch(displayMissedField('Unit'));
            return false;
        }
        if (validateConfigurationField(substrateOptions['substrate_id'])) {
            dispatch(displayMissedField('Substrate'));
            return false;
        }
    }

    return true;
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export function processElements() {
    return (dispatch, getState) => {
        const elements = selectors.getElements(getState());
        const filterFields = selectors.getFilterFields(getState());
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());

        let filteredElements = [];
        for (let i = 0; i < elements.length; i++) {
            let add = true;
            for (let j = 0; j < filterFields.length; j++) {
                let attribute = String(elements[i][filterFields[j]['name']]).toLowerCase();
                let filterValue = String(filterFields[j]['value']).toLowerCase();
                if (!attribute.includes(filterValue)) {
                    add = false;
                    break;
                }
            }

            if (add) {
                filteredElements.push(elements[i]);
            }
        }

        let sortedElements = filteredElements.sort((a, b) => {
            if (typeof a[sortColumn] === 'number') {
                if (sortType === 'asc') {
                    if (a[sortColumn] < b[sortColumn]) return -1;
                    if (a[sortColumn] > b[sortColumn]) return 1;
                    if (a['id'] < b['id']) return -1;
                    if (a['id'] > b['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn] < a[sortColumn]) return -1;
                    if (b[sortColumn] > a[sortColumn]) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            } else {
                if (sortType === 'asc') {
                    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) return -1;
                    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn].toLowerCase() < a[sortColumn].toLowerCase()) return -1;
                    if (b[sortColumn].toLowerCase() > a[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            }
        });

        dispatch({
            type: actionTypes.PROCESS_ELEMENTS,
            payload: sortedElements
        });
    };
}

export function setEditing(value) {
    return {
        type: actionTypes.SET_EDITING,
        payload: value
    };
}

export function updateConfiguration(attributes, value) {
    attributes.unshift('form', 'formValues', 'configuration_json');
    return {
        type: actionTypes.UPDATE_CONFIGURATION,
        payload: { attributes, value }
    };
}

export const fetchInformation = (type) => dispatch => {
    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information + '?type=' + type,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
            dispatch(updateState({
                options: {
                    presses: loadOptions(response.data.presses),
                    foldTypes: loadOptions(response.data.foldTypes, true),
                    bandingTypes: loadOptions(response.data.bandingTypes, true),
                    wrappingTypes: loadOptions(response.data.wrappingTypes, true),
                    shrinkWrappingTypes: loadOptions(response.data.shrinkWrappingTypes, true),
                    productCategories: loadProductCategoriesOptions(response.data.productCategories),
                    digitalMachines: loadOptions(response.data.digitalMachines),
                    inks: loadOptions(response.data.inks, true),
                    packages: loadPackagesOptions(response.data.packages),
                    customerDiscounts: loadCustomerDiscountOptions(response.data.customerDiscounts)
                }
            }));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_INFORMATION_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function loadOptions(data, useNone = false) {
    let options = [];
    if (useNone) {
        options.push({ label: 'None', value: '' });
    } else {
        options.push({ label: 'Select option', value: '' });
    }

    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['name'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadCustomerDiscountOptions(data) {
    let options = [{ label: 'None', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['long_name'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadPackagesOptions(data) {
    let options = [{ label: 'None', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['width'] + 'x' + data[i]['length'] + 'x' + data[i]['height'],
            value: data[i]['id'],
            element: data[i]
        });
    }
    return options;
}

export function loadProductCategoriesOptions(data) {
    let options = [{ label: 'Select option', value: '', type: 0 }];
    for (let i = 0; i < data.length; i++) {
        options.push({
            label: data[i]['name'],
            value: data[i]['id'],
            type: data[i]['type'],
            element: data[i]
        });
    }
    return options;
}

export function loadPaperCategoriesOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i]['category_name'],
                value: data[i]['id'],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPaperCategories' : 'paperCategories', options));
    };
}

export function loadPaperWeightsOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i] + '#',
                value: data[i],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPaperWeights' : 'paperWeights', options));
    };
}

export function loadPapersOptions(data, isCover = false) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '', coating_one_side: 0 }];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i]['paper_name'] + ' ' + data[i]['width'] + 'x' + data[i]['length'],
                value: data[i]['id'],
                coating_one_side: data[i]['coating_one_side'],
                element: data[i]
            });
        }
        dispatch(setOptions(isCover ? 'coverPapers' : 'papers', options));
    };
}

export function setOptions(name, data) {
    return {
        type: actionTypes.SET_OPTIONS,
        payload: { name, data }
    };
}

export function resetOptions(name) {
    return {
        type: actionTypes.RESET_OPTIONS,
        payload: name
    };
}

export function updateState(values) {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: values
    };
}

export const intersectPaperCategories = (isCover = false) => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());
    const configuration = selectors.getConfigurationJson(getState());

    let bodyToSend = null;
    if (parseInt(formValues['product_type']) === 1) {   // Flat
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: 0,
            pressId: configuration['product_options']['press_id']['default']
        };
    } else if (parseInt(formValues['product_type']) === 2) {   // Multi-page
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: isCover ? 1 : 0,
            pressId: isCover ? configuration['press_options']['cover_press_id']['default'] : configuration['press_options']['press_id']['default'],
            secondPressId: isCover ? null : configuration['press_options']['second_press_id']['default']
        };
    } else if (parseInt(formValues['product_type']) === 3) {   // Digital
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: 0,
            digitalMachineId: configuration['product_options']['digital_machine_id']['default']
        };
    }

    dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES, payload: null });
    axios.post(apiUrls.intersectPaperCategories, bodyToSend,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES_SUCCESS, payload: response.data });
            dispatch(loadPaperCategoriesOptions(response.data, isCover));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPER_CATEGORIES_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const intersectPaperWeights = (isCover = false) => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());
    const configuration = selectors.getConfigurationJson(getState());

    let bodyToSend = null;
    if (parseInt(formValues['product_type']) === 1) {   // Flat
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: 0,
            pressId: configuration['product_options']['press_id']['default'],
            paperCategoryId: configuration['product_options']['paper_category_id']['default']
        };
    } else if (parseInt(formValues['product_type']) === 2) {   // Multi-page
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: isCover ? 1 : 0,
            pressId: isCover ? configuration['press_options']['cover_press_id']['default'] : configuration['press_options']['press_id']['default'],
            secondPressId: isCover ? null : configuration['press_options']['second_press_id']['default'],
            paperCategoryId: isCover ? configuration['paper_options']['cover_paper_category_id']['default'] : configuration['paper_options']['paper_category_id']['default']
        };
    } else if (parseInt(formValues['product_type']) === 3) {   // Digital
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: 0,
            digitalMachineId: configuration['product_options']['digital_machine_id']['default'],
            paperCategoryId: configuration['product_options']['paper_category_id']['default']
        };
    }

    dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS, payload: null });
    axios.post(apiUrls.intersectPaperWeights, bodyToSend,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS_SUCCESS, payload: response.data });
            dispatch(loadPaperWeightsOptions(response.data, isCover));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPER_WEIGHTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const intersectPapers = (isCover = false) => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());
    const configuration = selectors.getConfigurationJson(getState());

    let bodyToSend = null;
    if (parseInt(formValues['product_type']) === 1) {   // Flat
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: 0,
            pressId: configuration['product_options']['press_id']['default'],
            paperCategoryId: configuration['product_options']['paper_category_id']['default'],
            paperWeight: configuration['product_options']['paper_weight']['default']
        };
    } else if (parseInt(formValues['product_type']) === 2) {   // Multi-page
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: isCover ? 1 : 0,
            pressId: isCover ? configuration['press_options']['cover_press_id']['default'] : configuration['press_options']['press_id']['default'],
            secondPressId: isCover ? null : configuration['press_options']['second_press_id']['default'],
            paperCategoryId: isCover ? configuration['paper_options']['cover_paper_category_id']['default'] : configuration['paper_options']['paper_category_id']['default'],
            paperWeight: isCover ? configuration['paper_options']['cover_paper_weight']['default'] : configuration['paper_options']['paper_weight']['default']
        };
    } else if (parseInt(formValues['product_type']) === 3) {   // Digital
        bodyToSend = {
            id: formValues['id'],
            type: formValues['product_type'],
            isCover: 0,
            digitalMachineId: configuration['product_options']['digital_machine_id']['default'],
            paperCategoryId: configuration['product_options']['paper_category_id']['default'],
            paperWeight: configuration['product_options']['paper_weight']['default']
        };
    }

    dispatch({ type: actionTypes.INTERSECT_PAPERS, payload: null });
    axios.post(apiUrls.intersectPapers, bodyToSend,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.INTERSECT_PAPERS_SUCCESS, payload: response.data });
            dispatch(loadPapersOptions(response.data, isCover));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.INTERSECT_PAPERS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchElement = (id) => (dispatch, getState) => {
    const formValues = selectors.getFormValues(getState());
    let fields = ['configuration_json'];

    dispatch({ type: actionTypes.FETCH_CONFIGURATION, payload: null });
    axios.get(apiUrls.elements + '/' + id + '?fields=' + fields.join(','),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_CONFIGURATION_SUCCESS, payload: response.data });
            if (parseInt(formValues['product_type']) === 1 || parseInt(formValues['product_type']) === 2 || parseInt(formValues['product_type']) === 3) {   // Flat
                dispatch(intersectPaperCategories());
                dispatch(intersectPaperWeights());
                dispatch(intersectPapers());
            }
            if (parseInt(formValues['product_type']) === 2 && [2, 3, 6, 7].includes(parseInt(formValues['configuration_json']['product_options']['multi_page_type']['default']))) {
                dispatch(intersectPaperCategories(true));
                dispatch(intersectPaperWeights(true));
                dispatch(intersectPapers(true));
            }
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_CONFIGURATION_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setInitialConfiguration(type) {
    return {
        type: actionTypes.SET_INITIAL_CONFIGURATION,
        payload: type
    };
}

export function checkAccessory(id) {
    return {
        type: actionTypes.CHECK_ACCESSORY,
        payload: id
    };
}
