import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table } from './components';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import * as selectors from './selectors';
import * as actions from './actions';

class Container extends Component {
    static propTypes = {
        user: PropTypes.object,
        fetchInformation: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        tags: PropTypes.array,
        points: PropTypes.array,
        anchors: PropTypes.array,
        distances: PropTypes.array,
        lines: PropTypes.array
    };

    componentDidMount() {
        const { fetchInformation } = this.props;
        const roomId = parseInt(this.props.match.params.roomId) === 1 ? 6 : 7;
        fetchInformation(roomId);
        setInterval(() => {
            fetchInformation(roomId);
        }, 2000);
    }

    render() {
        const { user, isFetchingInformation, tags, points, anchors, distances, lines } = this.props;
        return (
            <div id="admin-dashboard-container" className="container-fluid">
                <Helmet>
                    <title>UWB Demo</title>
                    <meta name="description" content="" />
                </Helmet>
                <div className="row">
                    <div className="col">
                        <Table
                            user={user}
                            isFetchingInformation={isFetchingInformation}
                            tags={tags}
                            points={points}
                            anchors={anchors}
                            distances={distances}
                            lines={lines}
                            showDistances={0}
                            roomId={this.props.match.params.roomId}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: loginSelectors.getUser,
    isFetchingInformation: selectors.getIsFetchingInformation,
    tags: selectors.getTags,
    points: selectors.getPoints,
    anchors: selectors.getAnchors,
    distances: selectors.getDistances,
    lines: selectors.getLines
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInformation: (roomId) => dispatch(actions.fetchInformation(roomId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
