import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table, Form, Delete } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        addPayor: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        showAddPayor: PropTypes.func,
        hideAddPayor: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        activeElement: PropTypes.func,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        fetchData: PropTypes.func,
        filterFields: PropTypes.array
    };

    componentDidMount() {
        const { fetchElements, sortColumn, sortType, filterFields } = this.props;
        fetchElements(1, sortColumn, sortType, filterFields);
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { updateFormValue } = this.props;
        let value = event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;
        updateFormValue(event.target.name, value);
    }

    handleUpdateFormDateValue(name, required, event) {
        const { updateFormValue } = this.props;
        let value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
        updateFormValue(name, value);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, fetchElements, filterFields } = this.props;
        let type = 'asc';
        if (sortColumn === column) {
            type = sortType === 'asc' ? 'desc' : 'asc';
            setSort(column, type);
        } else {
            setSort(column, 'asc');
        }
        fetchElements(1, column, type, filterFields);
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete } = this.props;
        deleteElement(idForDelete);
    }

    handleChangeFilter(name, event) {
        const { changeFilter, fetchElements, sortColumn, sortType, filterFields } = this.props;

        let value = null;
        let filter = filterFields;
        if (name === 'date') {
            value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else {
            value = event.target.value;
            changeFilter(name, value);
        }

        let fetch = false;
        const index = filter.findIndex(element => element['name'] === name);
        if (index === -1 && value !== '') {
            filter.push({ name: name, value: value });
            fetch = true;
        } else if (index !== -1 && value === '') {
            filter.splice(index, 1);
            fetch = true;
        } else if (index !== -1 && value !== '' && filter[index]['value'] !== value) {
            filter[index]['value'] = value;
            fetch = true;
        }

        if (fetch) {
            fetchElements(1, sortColumn, sortType, filter);
        }
    }

    handleNew() {
        const { resetForm, showForm } = this.props;
        resetForm();
        showForm();
    }

    handleSetActivePage(page) {
        const { fetchElements, setActivePage, sortColumn, sortType, filterFields } = this.props;
        setActivePage(page);
        fetchElements(page, sortColumn, sortType, filterFields);
    }

    handleShowForm(id) {
        const { showForm, resetForm, fetchElement } = this.props;

        if (id === null) {
            resetForm(id);
        } else {
            fetchElement(id);
        }

        showForm();
    }

    render() {
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Timeline Log</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Timeline Log"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={this.props.user}
                            />
                            <div className="upload-button" onClick={this.handleNew.bind(this)}><i className="fas fa-plus" /> Add</div>
                            <Table
                                elements={this.props.elements}
                                isFetchingElements={this.props.isFetchingElements}
                                totalItems={this.props.totalItems}
                                totalPages={this.props.totalPages}
                                selectedPage={this.props.selectedPage}
                                elementsPerPage={this.props.elementsPerPage}
                                sortColumn={this.props.sortColumn}
                                sortType={this.props.sortType}
                                user={this.props.user}
                                processedElements={this.props.processedElements}
                                filterFields={this.props.filterFields}
                                handleSort={this.handleSort.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleChangeFilter={this.handleChangeFilter.bind(this)}
                                handleSetActivePage={this.handleSetActivePage.bind(this)}
                            />
                            <Form
                                formValues={this.props.formValues}
                                formErrors={this.props.formErrors}
                                formErrorMessages={this.props.formErrorMessages}
                                isSavingForm={this.props.isSavingForm}
                                isFetchingElement={this.props.isFetchingElement}
                                isSendingEmail={this.props.isSendingEmail}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateFormDateValue={this.handleUpdateFormDateValue.bind(this)}
                                saveElement={this.props.saveElement.bind(this)}
                                resetForm={this.props.resetForm.bind(this)}
                                hideForm={this.props.hideForm.bind(this)}
                                checkPresent={this.props.checkPresent.bind(this)}
                                checkMethod={this.props.checkMethod.bind(this)}
                                sendEmail={this.props.sendEmail.bind(this)}
                                printPdf={this.props.printPdf.bind(this)}
                                setOtherMethod={this.props.setOtherMethod.bind(this)}
                            />
                            <Delete
                                isDeleting={this.props.isDeleting}
                                hideDelete={this.props.hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalItems: selectors.getTotalItems,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    activeElement: selectors.getActiveElement,
    filterFields: selectors.getFilterFields,
    isFetchingElement: selectors.getIsFetchingElement,
    isSendingEmail: selectors.getIsSendingEmail,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: (selectedPage, sortColumn, sortType, filterFields) => dispatch(actions.fetchElements(selectedPage, sortColumn, sortType, filterFields)),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (id) => dispatch(actions.deleteElement(id)),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: () => dispatch(actions.saveElement()),
        sendEmail: () => dispatch(actions.sendEmail()),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        resetState: () => dispatch(actions.resetState()),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        fetchElement: (id) => dispatch(actions.fetchElement(id)),
        setActivePage: (page) => dispatch(actions.setActivePage(page)),
        checkPresent: (label, checked) => dispatch(actions.checkPresent(label, checked)),
        checkMethod: (label, checked) => dispatch(actions.checkMethod(label, checked)),
        printPdf: () => dispatch(actions.printPdf()),
        setOtherMethod: (value) => dispatch(actions.setOtherMethod(value))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
