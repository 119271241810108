import Immutable from 'immutable';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            email: '',
            company: '',
            phone: '',
            shipping_method: '',
            notes: '',
            discount: 0,
            active: 1,
            billing_address: '',
            billing_city: '',
            billing_state: '',
            billing_country: 'United States',
            billing_zip: '',
            po_required: 0,
            sales_rep_id: '',
            csr_id: '',
            qb_id: null,
            title: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            display_name: '',
            use_display_name_for_check: 1,
            print_on_check_name: '',
            mobile: '',
            fax: '',
            other: '',
            website: '',
            is_sub_customer: 0,
            parent_customer_id: null,
            bill_with: 1,
            use_billing_address: 1,
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_zip: '',
            shipping_country: 'United States',
            is_taxable: 1,
            default_tax_code: '',
            preferred_payment_method: '',
            preferred_delivery_method: '',
            terms: '',
            is_postage_payment_exempt: '',
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalItems: 0,
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'display_name',
        type: 'asc'
    },
    information: {
        salesReps: [],
        csrs: [],
        customers: [],
        terms: [],
        taxCodes: [],
        paymentMethods: [],
        isFetching: false
    },
    filter: {
        fields: []
    },
    selectedTab: 1,
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/customers') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], []);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload.elements)
                .setIn(['pagination', 'totalItems'], action.payload.totalItems)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'selectedPage'], action.payload.selectedPage);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case actionTypes.SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                email: '',
                company: '',
                phone: '',
                shipping_method: '',
                notes: '',
                discount: 0,
                active: 1,
                billing_address: '',
                billing_city: '',
                billing_state: '',
                billing_country: 'United States',
                billing_zip: '',
                po_required: 0,
                sales_rep_id: '',
                csr_id: '',
                qb_id: null,
                title: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',
                display_name: '',
                use_display_name_for_check: 1,
                print_on_check_name: '',
                mobile: '',
                fax: '',
                other: '',
                website: '',
                is_sub_customer: 0,
                parent_customer_id: null,
                bill_with: 1,
                use_billing_address: 1,
                shipping_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_zip: '',
                shipping_country: 'United States',
                is_taxable: 1,
                default_tax_code: '',
                preferred_payment_method: '',
                preferred_delivery_method: '',
                terms: '',
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'email'], action.payload.email)
                .setIn(['form', 'formValues', 'company'], action.payload.company)
                .setIn(['form', 'formValues', 'phone'], action.payload.phone)
                .setIn(['form', 'formValues', 'shipping_method'], action.payload.shipping_method)
                .setIn(['form', 'formValues', 'notes'], action.payload.notes)
                .setIn(['form', 'formValues', 'discount'], action.payload.discount)
                .setIn(['form', 'formValues', 'active'], action.payload.active)
                .setIn(['form', 'formValues', 'billing_address'], action.payload.billing_address)
                .setIn(['form', 'formValues', 'billing_city'], action.payload.billing_city)
                .setIn(['form', 'formValues', 'billing_state'], action.payload.billing_state)
                .setIn(['form', 'formValues', 'billing_country'], action.payload.billing_country)
                .setIn(['form', 'formValues', 'billing_zip'], action.payload.billing_zip)
                .setIn(['form', 'formValues', 'po_required'], action.payload.po_required)
                .setIn(['form', 'formValues', 'sales_rep_id'], action.payload.sales_rep_id)
                .setIn(['form', 'formValues', 'csr_id'], action.payload.csr_id)
                .setIn(['form', 'formValues', 'qb_id'], action.payload.qb_id)
                .setIn(['form', 'formValues', 'title'], action.payload.title)
                .setIn(['form', 'formValues', 'first_name'], action.payload.first_name)
                .setIn(['form', 'formValues', 'middle_name'], action.payload.middle_name)
                .setIn(['form', 'formValues', 'last_name'], action.payload.last_name)
                .setIn(['form', 'formValues', 'suffix'], action.payload.suffix)
                .setIn(['form', 'formValues', 'display_name'], action.payload.display_name)
                .setIn(['form', 'formValues', 'use_display_name_for_check'], action.payload.use_display_name_for_check)
                .setIn(['form', 'formValues', 'print_on_check_name'], action.payload.print_on_check_name)
                .setIn(['form', 'formValues', 'mobile'], action.payload.mobile)
                .setIn(['form', 'formValues', 'fax'], action.payload.fax)
                .setIn(['form', 'formValues', 'other'], action.payload.other)
                .setIn(['form', 'formValues', 'website'], action.payload.website)
                .setIn(['form', 'formValues', 'is_sub_customer'], action.payload.is_sub_customer)
                .setIn(['form', 'formValues', 'parent_customer_id'], action.payload.parent_customer_id)
                .setIn(['form', 'formValues', 'bill_with'], action.payload.bill_with)
                .setIn(['form', 'formValues', 'use_billing_address'], action.payload.use_billing_address)
                .setIn(['form', 'formValues', 'shipping_address'], action.payload.shipping_address)
                .setIn(['form', 'formValues', 'shipping_city'], action.payload.shipping_city)
                .setIn(['form', 'formValues', 'shipping_state'], action.payload.shipping_state)
                .setIn(['form', 'formValues', 'shipping_zip'], action.payload.shipping_zip)
                .setIn(['form', 'formValues', 'shipping_country'], action.payload.shipping_country)
                .setIn(['form', 'formValues', 'is_taxable'], action.payload.is_taxable)
                .setIn(['form', 'formValues', 'default_tax_code'], action.payload.default_tax_code)
                .setIn(['form', 'formValues', 'preferred_payment_method'], action.payload.preferred_payment_method)
                .setIn(['form', 'formValues', 'preferred_delivery_method'], action.payload.preferred_delivery_method)
                .setIn(['form', 'formValues', 'terms'], action.payload.terms)
                .setIn(['form', 'formValues', 'is_postage_payment_exempt'], action.payload.is_postage_payment_exempt);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'salesReps'], [])
                .setIn(['information', 'csrs'], [])
                .setIn(['information', 'customers'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .setIn(['information', 'customers'], action.payload.customers)
                .setIn(['information', 'terms'], action.payload.terms)
                .setIn(['information', 'taxCodes'], action.payload.taxCodes)
                .setIn(['information', 'paymentMethods'], action.payload.paymentMethods);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.FETCH_ELEMENT:
            state = state.setIn(['activeElement', 'isFetching'], true);
            break;

        case actionTypes.FETCH_ELEMENT_SUCCESS:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT_FAILURE:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.SET_SELECTED_TAB:
            state = state.setIn(['selectedTab'], action.payload);
            break;

        default:
            break;
    }

    return state.toJS();
}
