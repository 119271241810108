const API_URL = process.env.REACT_APP_API_URL;

let apiUrls = {
    orders: `${API_URL}/orders`,
    elements: `${API_URL}/orders`,
    search: `${API_URL}/order/search`,
    statistics: `${API_URL}/order/statistics`
};

export default apiUrls;
