import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import FlatDrawImposition from './FlatDrawImposition';
import EstimatePricing from './EstimatePricing';

class EstimateDigital extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        handleUpdateOutsideService: PropTypes.func,
        handleAddOutsideService: PropTypes.func,
        handleDeleteOutsideService: PropTypes.func,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col', loadingName = null) {
        const { formValues, formErrors, handleUpdateFormValue, fetching } = this.props;

        let loadingIcon = null;
        if (loadingName != null && fetching[loadingName]) {
            loadingIcon = <div className="loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
                {loadingIcon}
            </div>
        );
    }

    renderTypes() {
        return this.renderSelect('Product Type', 'estimate_type', [
            { label: 'Flat', value: 1 },
            { label: 'Multi-Page', value: 2 },
            { label: 'Digital', value: 3 },
            { label: 'Large Format', value: 4 },
            { label: 'Data Processing', value: 5 },
            { label: 'Inkjet', value: 6 },
            { label: 'Inserting', value: 7 },
            // { label: 'Certified Mail Program', value: 8 },
            { label: 'Art Department', value: 9 },
            { label: 'Handwork Department', value: 10 },
            { label: 'Pressure Seal Forms', value: 11 },
            { label: 'Index Tabs', value: 12 },
            { label: 'Standard Labels', value: 13 },
            { label: 'NCR Forms', value: 14 },
            { label: 'Envelopes', value: 15 },
        ], true);
    }

    renderProductCategories(type) {
        const { options } = this.props;

        let categoriesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === parseInt(type)) {
                categoriesOptions.push(options['productCategories'][i]);
            }
        }

        return this.renderSelect('Product Category', 'estimate_product_category_id', categoriesOptions, true);
    }

    renderFrontInks() {
        const { formValues } = this.props;

        let label = 'Front Inks';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Front Inks';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_front_inks', [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true)
        );
    }

    renderFrontCoatings() {
        const { formValues, information } = this.props;

        let label = 'Coating Front';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Coating Front';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_front_coating', coatingOptions, false)
        );
    }

    renderBackInks() {
        const { formValues } = this.props;

        let label = 'Back Inks';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Back Inks';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_back_inks', [
                { label: '0', value: 0 },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true)
        );
    }

    renderBackCoatings() {
        const { formValues, information } = this.props;

        let label = 'Coating Back';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && (parseInt(formValues['estimate_multi_page_type']) === 2
            || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 6
            || parseInt(formValues['estimate_multi_page_type']) === 7)) {
            label = 'Cover Coating Back';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_back_coating', coatingOptions, false)
        );
    }

    renderOutsideServices() {
        const { handleAddOutsideService, handleDeleteOutsideService, formValues, handleUpdateOutsideService } = this.props;
        let table = null;
        if (formValues['estimate_outside_services'].length !== 0) {
            table = (
                <table id="outside-services-table" className="table table-sm table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Cost</th>
                            <th>Markup</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {formValues['estimate_outside_services'].map((service, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <input type="text" value={service['description']}
                                            onChange={handleUpdateOutsideService.bind(this, 'description', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['cost']}
                                            onChange={handleUpdateOutsideService.bind(this, 'cost', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['percentage_markup']}
                                            onChange={handleUpdateOutsideService.bind(this, 'percentage_markup', index)} />
                                    </td>
                                    <td>
                                        <i className="fas fa-trash" onClick={handleDeleteOutsideService.bind(this, index)} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
        return (
            <div className="card mb-2 mr-1 pb-0">
                <div className="card-header">
                    Outside Services
                </div>
                <div className="card-body pt-1 pb-0">
                    <div className="row">
                        <div className="col">
                            <div className="add-outside-services" onClick={handleAddOutsideService}><i className="fas fa-plus" /> Add</div>
                            <div className="clearfix"></div>
                            {table}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { formValues, handleUpdateFormValue, estimateResults, options, information } = this.props;

        let imposition = null;
        if (estimateResults.length > 0 && estimateResults[0]['results'].length > 0) {
            let results = estimateResults[0]['results'];
            imposition = (
                <div className="col">
                    <label>Imposition:</label>
                    <FlatDrawImposition
                        result={results[0]}
                    />
                </div>
            );
        }

        return (
            <form>
                <div className="row no-gutters mb-2">
                    <div className="col-4" style={{ color: '#0d47a1', fontWeight: 'bold' }}>PQN - Digital</div>
                </div>
                <div className="row no-gutters">
                    <div className="col">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Product Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderTypes()}
                                    {this.renderProductCategories(3)}
                                    <div className="w-100"></div>
                                    <div className="estimate-divider"></div>
                                    <div className="w-100"></div>
                                    {this.renderInput('Description', 'estimate_product_description', 'text', true)}
                                    <div className="w-100"></div>
                                    <div className="estimate-divider"></div>
                                    <div className="w-100"></div>
                                    {this.renderSelect('Digital Machine', 'estimate_digital_machine_id', options['digitalMachines'], true)}
                                    {this.renderSelect('Bleed', 'estimate_bleed', [
                                        { label: 'None', value: 0 },
                                        { label: '1/8', value: 0.125 },
                                        { label: '1/4', value: 0.25 }
                                    ], true)}
                                    <div className="w-100"></div>
                                    <div className="estimate-divider"></div>
                                    <div className="w-100"></div>
                                    {this.renderInput('Flat Width', 'estimate_width', 'decimal', true)}
                                    {this.renderInput('Flat Height', 'estimate_height', 'decimal', true)}
                                    <div className="w-100"></div>
                                    {this.renderInput('Finish Width', 'estimate_finish_width', 'decimal', true)}
                                    {this.renderInput('Finish Height', 'estimate_finish_height', 'decimal', true)}
                                    <div className="w-100"></div>
                                    <div className="estimate-divider"></div>
                                    <div className="w-100"></div>
                                    {this.renderSelect('Color', 'estimate_digital_inks', [
                                        { label: 'Black/White', value: 1 },
                                        { label: 'Color', value: 2 }
                                    ], true)}
                                    {this.renderSelect('Sides', 'estimate_digital_sides', [
                                        { label: 1, value: 1 },
                                        { label: 2, value: 2 }
                                    ], true)}
                                    <div className="w-100"></div>
                                    <div className="estimate-divider"></div>
                                    <div className="w-100"></div>
                                    {this.renderSelect('Paper Category', 'estimate_paper_category_id', options['paperCategories'], true, 'col', 'paperCategories')}
                                    {this.renderSelect('Paper Weight', 'estimate_paper_weight', options['paperWeights'], true, 'col', 'paperWeights')}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Paper', 'estimate_paper_id', options['papers'], true, 'col', 'papers')}
                                    <div className="w-100"></div>
                                    <div className="estimate-divider"></div>
                                    <div className="w-100"></div>
                                    {this.renderFrontCoatings()}
                                    {this.renderBackCoatings()}
                                    <div className="w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Bindery Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderSelect('Folding Type', 'estimate_fold_type_id', options['foldTypes'], false)}
                                    {this.renderSelect('Scoring', 'estimate_requires_scoring', [
                                        { label: 'Yes', value: 1 },
                                        { label: 'No', value: 0 }
                                    ], false)}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Drilling Holes', 'estimate_drilling_holes', [
                                        { label: 'None', value: 0 },
                                        { label: '1', value: 1 },
                                        { label: '2', value: 2 },
                                        { label: '3', value: 3 },
                                        { label: '4', value: 4 }
                                    ], false)}
                                    {this.renderSelect('Shrink-Wrapping', 'estimate_shrink_wrapping_type_id', options['shrinkWrappingTypes'], false)}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Padding', 'estimate_requires_padding', [
                                        { label: 'Yes', value: 1 },
                                        { label: 'No', value: 0 }
                                    ], false)}
                                    {this.renderSelect('Die-Cutting', 'estimate_requires_die_cutting', [
                                        { label: 'Yes', value: 1 },
                                        { label: 'No', value: 0 }
                                    ], false)}
                                    <div className="w-100"></div>
                                    {parseInt(formValues['estimate_requires_padding']) === 1 ? this.renderSelect('Sheets To Pad', 'estimate_sheets_to_pad', [
                                        { label: '10', value: 10 },
                                        { label: '25', value: 25 },
                                        { label: '50', value: 50 },
                                        { label: '100', value: 100 }
                                    ], false, 'col-6') : null}
                                </div>
                            </div>
                        </div>
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Handling Options
                            </div>
                            <div className="card-body pt-1 pb-0 pl-0 pr-0">
                                <div className="row no-gutters">
                                    {this.renderSelect('Banding', 'estimate_banding_type_id', options['bandingTypes'], false)}
                                    {this.renderSelect('Wrapping', 'estimate_wrapping_type_id', options['wrappingTypes'], false)}
                                    <div className="w-100"></div>
                                    {this.renderSelect('Package', 'estimate_package_id', options['packages'], false)}
                                    {this.renderSelect('Hand Gathering', 'estimate_use_hand_gathering', [
                                        { label: 'Yes', value: 1 },
                                        { label: 'No', value: 0 }
                                    ], false)}
                                    <div className="w-100"></div>
                                </div>
                            </div>
                        </div>
                        {this.renderOutsideServices()}
                        <div className="card mb-2 mr-2 pb-2">
                            <div className="card-header">
                                Production Notes
                            </div>
                            <div className="card-body pt-2 pb-0 pl-2 pr-2">
                                <textarea name="estimate_notes" onChange={handleUpdateFormValue.bind(this, 'text', false)} style={{ height: 28 }}>
                                    {formValues['estimate_notes']}
                                </textarea>
                                {this.renderSelect('Discount Table', 'estimate_customer_discount_id', options['customerDiscounts'], false)}
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-2 mr-1 pb-2">
                            <div className="card-header">
                                Imposition
                            </div>
                            <div className="card-body pt-3 pb-0" style={{ minHeight: 557 }}>
                                <div className="row">{imposition}</div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                    </div>
                    <div className="w-100"></div>
                    <EstimatePricing
                        formValues={formValues}
                        estimateResults={estimateResults}
                        information={information}
                        handleUpdateFormValue={handleUpdateFormValue}
                        renderSheets
                    />
                </div>
            </form>
        );
    }
}

export default EstimateDigital;
