import React, { Component } from 'react';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class EstimateMailingPricing extends Component {
    loadPrices(name) {
        const { formValues } = this.props;

        let prices = [];
        for (let i = 0; i < 3; i++) {
            if (formValues[name + (i + 1)] !== '' && formValues[name + (i + 1)] != null) {
                prices.push(parseFloat(formValues[name + (i + 1)]));
            } else {
                prices.push(0);
            }
        }

        return prices;
    }

    renderTooltip(cost, tooltipHtml) {
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(cost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderMailingTooltip(cost, name) {
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {name}</div>
            </div>
        );
        return this.renderTooltip(cost, tooltipHtml);
    }

    getDataProcessingCostAndNames(type) {
        const { formValues, options } = this.props;

        let costs = [0, 0, 0];
        let names = [];
        for (let i = 0; i < formValues['estimate_data_processing_elements'].length; i++) {
            let dataElement = formValues['estimate_data_processing_elements'][i];
            for (let j = 0; j < 3; j++) {
                if (dataElement['element_id'] !== '' && dataElement['quantity' + (j + 1)] !== '') {
                    let selectedElement = null;
                    for (let k = 0; k < options['dataProcessingElements'].length; k++) {
                        let element = options['dataProcessingElements'][k]['element'];
                        if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id']) && parseInt(type) === parseInt(dataElement['type'])) {
                            selectedElement = element;
                            break;
                        }
                    }

                    if (selectedElement == null) {
                        continue;
                    }

                    let total = selectedElement['unit_price'] * dataElement['quantity' + (j + 1)];
                    if (parseInt(selectedElement['unit']) === 1) {
                        total = selectedElement['unit_price'] * (Math.ceil(dataElement['quantity' + (j + 1)] / 1000));
                    }

                    for (let k = 0; k < selectedElement['discounts'].length; k++) {
                        if (parseInt(selectedElement['discounts'][k]['start_quantity']) <= dataElement['quantity' + (j + 1)]
                            && parseInt(selectedElement['discounts'][k]['end_quantity']) >= dataElement['quantity' + (j + 1)]) {
                            total -= total * selectedElement['discounts'][k]['discount'];
                            break;
                        }
                    }

                    if (total < selectedElement['minimum_charge']) {
                        total = parseFloat(selectedElement['minimum_charge']);
                    }

                    costs[j] += total;
                    if (j === 0) {
                        names.push(selectedElement['name']);
                    }
                }
            }
        }

        return { costs, names };
    }

    getMailingCostAndNames(name, optionsName) {
        const { formValues, options } = this.props;

        let costs = [0, 0, 0];
        let names = [];
        for (let i = 0; i < formValues[name].length; i++) {
            let dataElement = formValues[name][i];
            for (let j = 0; j < 3; j++) {
                if (dataElement['element_id'] !== '' && dataElement['quantity' + (j + 1)] !== '') {
                    let selectedElement = null;
                    for (let k = 0; k < options[optionsName].length; k++) {
                        let element = options[optionsName][k]['element'];
                        if (element && element != null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                            selectedElement = element;
                            break;
                        }
                    }

                    if (selectedElement == null) {
                        continue;
                    }

                    let total = selectedElement['unit_price'] * dataElement['quantity' + (j + 1)];
                    if (parseInt(selectedElement['unit']) === 1) {
                        total = selectedElement['unit_price'] * (Math.ceil(dataElement['quantity' + (j + 1)] / 1000));
                    }

                    for (let k = 0; k < selectedElement['discounts'].length; k++) {
                        if (parseInt(selectedElement['discounts'][k]['start_quantity']) <= dataElement['quantity' + (j + 1)]
                            && parseInt(selectedElement['discounts'][k]['end_quantity']) >= dataElement['quantity' + (j + 1)]) {
                            total -= total * selectedElement['discounts'][k]['discount'];
                            break;
                        }
                    }

                    if (total < selectedElement['minimum_charge']) {
                        total = parseFloat(selectedElement['minimum_charge']);
                    }

                    costs[j] += total;
                    if (j === 0) {
                        names.push(selectedElement['name']);
                    }
                }
            }
        }

        return { costs, names };
    }

    getMailingCostAndNamesTables(name, optionsName) {
        const { formValues, options } = this.props;

        let costs = [0, 0, 0];
        let names = [];
        for (let i = 0; i < formValues[name].length; i++) {
            let dataElement = formValues[name][i];
            for (let j = 0; j < 3; j++) {
                if (dataElement['element_id'] !== '' && dataElement['quantity' + (j + 1)] !== '') {
                    let selectedElement = null;
                    for (let k = 0; k < options[optionsName].length; k++) {
                        let element = options[optionsName][k]['element'];
                        if (element && element !== null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                            selectedElement = element;
                            break;
                        }
                    }

                    let total = null;
                    let costElement = null;
                    if (selectedElement != null) {
                        for (let k = 0; k < selectedElement['costs'].length; k++) {
                            if (dataElement['quantity' + (j + 1)] >= parseInt(selectedElement['costs'][k]['quantity_start'])
                                && dataElement['quantity' + (j + 1)] <= parseInt(selectedElement['costs'][k]['quantity_end'])) {
                                costElement = selectedElement['costs'][k];
                                break;
                            }
                        }

                        if (costElement != null) {
                            total = parseFloat(costElement['cost']);

                            for (let k = 0; k < selectedElement['discounts'].length; k++) {
                                if (parseInt(selectedElement['discounts'][k]['start_quantity']) <= dataElement['quantity' + (j + 1)]
                                    && parseInt(selectedElement['discounts'][k]['end_quantity']) >= dataElement['quantity' + (j + 1)]) {
                                    total -= total * selectedElement['discounts'][k]['discount'];
                                    break;
                                }
                            }

                            costs[j] += total;
                            if (j === 0) {
                                names.push(selectedElement['name']);
                            }
                        }
                    }
                }
            }
        }

        return { costs, names };
    }

    renderPriceRow(name, key, names, costs, formName) {
        const { formValues, handleUpdateFormValue } = this.props;
        if (names.length > 0) {
            return (
                <tr key={key}>
                    <td>{name}</td>
                    <td>{names.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(costs[0], name)}</td>
                    <td className="price-td">
                        $<input type="text" name={formName + '_1'} value={formValues[formName + '_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(costs[1], name)}</td>
                    <td className="price-td">
                        $<input type="text" name={formName + '_2'} value={formValues[formName + '_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderMailingTooltip(costs[2], name)}</td>
                    <td className="price-td">
                        $<input type="text" name={formName + '_3'} value={formValues[formName + '_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }
        return null;
    }

    assignCosts(totalCosts, totalPrices, costs, name) {
        const { formValues } = this.props;
        totalCosts[0] += costs[0];
        totalCosts[1] += costs[1];
        totalCosts[2] += costs[2];
        if (formValues[name + '_1'] != null) {
            totalPrices[0] += parseFloat(formValues[name + '_1']);
        }
        if (formValues[name + '_2'] != null) {
            totalPrices[1] += parseFloat(formValues[name + '_2']);
        }
        if (formValues[name + '_3'] != null) {
            totalPrices[2] += parseFloat(formValues[name + '_3']);
        }
    }

    renderResults() {
        const { formValues, handleUpdateFormValue, options } = this.props;

        let hasPricing = false;
        let modules = [
            'estimate_data_processing_elements',
            'estimate_inkjet_elements',
            'estimate_inserting_elements',
            'estimate_perfect_match_elements',
            'estimate_art_elements',
            'estimate_handwork_elements',
            'estimate_pressure_seal_elements',
            'estimate_index_tab_elements',
            'estimate_label_elements',
            'estimate_ncr_form_elements'
        ];
        for (let i = 0; i < modules.length; i++) {
            if (formValues[modules[i]].length > 0) {
                for (let j = 0; j < formValues[modules[i]].length; j++) {
                    if (formValues[modules[i]][j]['element_id'] !== '' && (formValues[modules[i]][j]['quantity1'] !== ''
                        || formValues[modules[i]][j]['quantity2'] !== '' || formValues[modules[i]][j]['quantity3'] !== '')
                    ) {
                        hasPricing = true;
                        break;
                    }
                }
            }
        }

        if (formValues['estimate_outside_services'].length > 0) {
            hasPricing = true;
        }

        if (!hasPricing) {
            return (
                <tr>
                    <td colSpan="8">No results</td>
                </tr>
            );
        }

        let result = null;
        let totalCosts = [0, 0, 0];
        let totalPrices = [0, 0, 0];

        result = this.getDataProcessingCostAndNames(1);
        let dataProcessingCosts1 = result['costs'];
        let dataProcessingNames1 = result['names'];
        this.assignCosts(totalCosts, totalPrices, dataProcessingCosts1, 'estimate_data_processing_price_1');

        result = this.getDataProcessingCostAndNames(2);
        let dataProcessingCosts2 = result['costs'];
        let dataProcessingNames2 = result['names'];
        this.assignCosts(totalCosts, totalPrices, dataProcessingCosts2, 'estimate_data_processing_price_2');

        result = this.getMailingCostAndNames('estimate_inkjet_elements', 'inkjetElements');
        let inkjetCosts = result['costs'];
        let inkjetNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, inkjetCosts, 'estimate_inkjet_price');

        result = this.getMailingCostAndNames('estimate_inserting_elements', 'insertingElements');
        let insertingCosts = result['costs'];
        let insertingNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, insertingCosts, 'estimate_inserting_price');

        result = this.getMailingCostAndNames('estimate_perfect_match_elements', 'perfectMatchElements');
        let perfectMatchCosts = result['costs'];
        let perfectMatchNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, perfectMatchCosts, 'estimate_perfect_match_price');

        result = this.getMailingCostAndNames('estimate_art_elements', 'artElements');
        let artCosts = result['costs'];
        let artNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, artCosts, 'estimate_art_price');

        result = this.getMailingCostAndNames('estimate_handwork_elements', 'handworkElements');
        let handworkCosts = result['costs'];
        let handworkNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, handworkCosts, 'estimate_handwork_price');

        result = this.getMailingCostAndNamesTables('estimate_index_tab_elements', 'indexTabElements');
        let indexTabCosts = result['costs'];
        let indexTabNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, indexTabCosts, 'estimate_index_tab_price');

        result = this.getMailingCostAndNamesTables('estimate_label_elements', 'labelElements');
        let labelCosts = result['costs'];
        let labelNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, labelCosts, 'estimate_label_price');

        result = this.getMailingCostAndNamesTables('estimate_ncr_form_elements', 'ncrFormElements');
        let ncrFormCosts = result['costs'];
        let ncrFormNames = result['names'];
        this.assignCosts(totalCosts, totalPrices, ncrFormCosts, 'estimate_ncr_form_price');

        let pressureSealCosts = [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
        ];
        let pressureSealName = null;
        for (let i = 0; i < formValues['estimate_pressure_seal_elements'].length; i++) {
            let dataElement = formValues['estimate_pressure_seal_elements'][i];
            for (let j = 0; j < 3; j++) {
                if (dataElement['element_id'] !== '' && dataElement['quantity' + (j + 1)] !== '') {
                    let selectedElement = null;
                    for (let k = 0; k < options['pressureSealElements'].length; k++) {
                        let element = options['pressureSealElements'][k]['element'];
                        if (element && element !== null && parseInt(element['id']) === parseInt(dataElement['element_id'])) {
                            selectedElement = element;
                            break;
                        }
                    }

                    if (selectedElement != null) {
                        pressureSealName = selectedElement['name'];
                        for (let k = 0; k < selectedElement['costs'].length; k++) {
                            if (dataElement['quantity' + (j + 1)] >= parseInt(selectedElement['costs'][k]['quantity_start'])
                                && dataElement['quantity' + (j + 1)] <= parseInt(selectedElement['costs'][k]['quantity_end'])) {
                                let total = parseFloat(selectedElement['costs'][k]['cost']);

                                for (let l = 0; l < selectedElement['discounts'].length; l++) {
                                    if (parseInt(selectedElement['discounts'][l]['start_quantity']) <= dataElement['quantity' + (j + 1)]
                                        && parseInt(selectedElement['discounts'][l]['end_quantity']) >= dataElement['quantity' + (j + 1)]) {
                                        total -= total * selectedElement['discounts'][l]['discount'];
                                        break;
                                    }
                                }

                                pressureSealCosts[selectedElement['costs'][k]['type'] - 1][j] += total;
                            }
                        }
                    }
                }
            }
        }
        for (let i = 0; i < pressureSealCosts.length; i++) {
            totalCosts[0] += pressureSealCosts[i][0];
            totalCosts[1] += pressureSealCosts[i][1];
            totalCosts[2] += pressureSealCosts[i][2];
        }
        if (formValues['estimate_pressure_seal_price_1_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_1_1']);
        }
        if (formValues['estimate_pressure_seal_price_1_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_1_2']);
        }
        if (formValues['estimate_pressure_seal_price_1_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_1_3']);
        }
        
        if (formValues['estimate_pressure_seal_price_2_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_2_1']);
        }
        if (formValues['estimate_pressure_seal_price_2_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_2_2']);
        }
        if (formValues['estimate_pressure_seal_price_2_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_2_3']);
        }
        
        if (formValues['estimate_pressure_seal_price_3_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_3_1']);
        }
        if (formValues['estimate_pressure_seal_price_3_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_3_2']);
        }
        if (formValues['estimate_pressure_seal_price_3_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_3_3']);
        }
        
        if (formValues['estimate_pressure_seal_price_4_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_4_1']);
        }
        if (formValues['estimate_pressure_seal_price_4_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_4_2']);
        }
        if (formValues['estimate_pressure_seal_price_4_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_4_3']);
        }
        
        if (formValues['estimate_pressure_seal_price_5_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_5_1']);
        }
        if (formValues['estimate_pressure_seal_price_5_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_5_2']);
        }
        if (formValues['estimate_pressure_seal_price_5_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_5_3']);
        }
        
        if (formValues['estimate_pressure_seal_price_6_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_6_1']);
        }
        if (formValues['estimate_pressure_seal_price_6_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_6_2']);
        }
        if (formValues['estimate_pressure_seal_price_6_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_6_3']);
        }
        
        if (formValues['estimate_pressure_seal_price_7_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_7_1']);
        }
        if (formValues['estimate_pressure_seal_price_7_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_7_2']);
        }
        if (formValues['estimate_pressure_seal_price_7_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_7_3']);
        }
        
        if (formValues['estimate_pressure_seal_price_8_1'] != null) {
            totalPrices[0] += parseFloat(formValues['estimate_pressure_seal_price_8_1']);
        }
        if (formValues['estimate_pressure_seal_price_8_2'] != null) {
            totalPrices[1] += parseFloat(formValues['estimate_pressure_seal_price_8_2']);
        }
        if (formValues['estimate_pressure_seal_price_8_3'] != null) {
            totalPrices[2] += parseFloat(formValues['estimate_pressure_seal_price_8_3']);
        }

        let dataProcessing1Row = this.renderPriceRow('Data Processing Department', 'data-processing-1', dataProcessingNames1, dataProcessingCosts1, 'estimate_data_processing_price_1');
        let dataProcessing2Row = this.renderPriceRow('Printing Mailing Labels', 'data-processing-2', dataProcessingNames2, dataProcessingCosts2, 'estimate_data_processing_price_2');
        let inkjetRow = this.renderPriceRow('Inkjetting Department', 'inkjet', inkjetNames, inkjetCosts, 'estimate_inkjet_price');
        let insertingRow = this.renderPriceRow('Inserting Department', 'inserting', insertingNames, insertingCosts, 'estimate_inserting_price');
        let perfectMatchRow = this.renderPriceRow('Perfect Match', 'perfect-match', perfectMatchNames, perfectMatchCosts, 'estimate_perfect_match_price');
        let artRow = this.renderPriceRow('Art Department', 'art', artNames, artCosts, 'estimate_art_price');
        let handworkRow = this.renderPriceRow('Handwork Department', 'handwork', handworkNames, handworkCosts, 'estimate_handwork_price');
        let indexTabRow = this.renderPriceRow('Index Tab', 'index-tab', indexTabNames, indexTabCosts, 'estimate_index_tab_price');
        let labelRow = this.renderPriceRow('Standard Labels', 'label', labelNames, labelCosts, 'estimate_label_price');
        let ncrFormRow = this.renderPriceRow('NCR Form', 'ncr-form', ncrFormNames, ncrFormCosts, 'estimate_ncr_form_price');

        let pressureSealRows = [];
        if (pressureSealName !== null) {
            for (let i = 1; i <= 8; i++) {
                if (i === 1) {
                    continue;
                }

                let type = null;
                if (i === 1) {
                    type = '4/0 Printing';
                } else if (i === 2) {
                    type = 'Data Processing';
                } else if (i === 3) {
                    type = 'Variable Set Up';
                } else if (i === 4) {
                    type = 'Variable Color Laser Both Sides';
                } else if (i === 5) {
                    type = 'Pressure Seal Material';
                } else if (i === 6) {
                    type = 'Fold';
                } else if (i === 7) {
                    type = 'Seal';
                } else if (i === 8) {
                    type = 'Deliver to P.O.';
                }

                pressureSealRows.push(
                    <tr key={'pressure-seal-' + i}>
                        <td>Pressure Seal Forms</td>
                        <td>{type}</td>
                        <td className="text-right cost-td">{this.renderMailingTooltip(pressureSealCosts[i - 1][0], 'Pressure Seal Forms')}</td>
                        <td className="price-td">
                            $<input type="text" name={'estimate_pressure_seal_price_' + i + '_1'} value={formValues['estimate_pressure_seal_price_' + i + '_1']}
                                onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                        </td>
                        <td className="text-right cost-td">{this.renderMailingTooltip(pressureSealCosts[i - 1][1], 'Pressure Seal Forms')}</td>
                        <td className="price-td">
                            $<input type="text" name={'estimate_pressure_seal_price_' + i + '_2'} value={formValues['estimate_pressure_seal_price_' + i + '_2']}
                                onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                        </td>
                        <td className="text-right cost-td">{this.renderMailingTooltip(pressureSealCosts[i - 1][2], 'Pressure Seal Forms')}</td>
                        <td className="price-td">
                            $<input type="text" name={'estimate_pressure_seal_price_' + i + '_3'} value={formValues['estimate_pressure_seal_price_' + i + '_3']}
                                onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                        </td>
                    </tr>
                );
            }
        }

        let outsideServicesRow = null;
        if (formValues['estimate_outside_services'].length > 0) {
            let outsideServicesNames = [];
            let outsideServicesCost = 0;
            for (let i = 0; i < formValues['estimate_outside_services'].length; i++) {
                let element = formValues['estimate_outside_services'][i];
                if (element['description'] !== '' && element['cost'] !== '' && element['percentage_markup'] !== '') {
                    outsideServicesNames.push(element['description']);
                    outsideServicesCost += parseFloat(element['cost']);
                }
            }

            if (outsideServicesCost > 0) {
                outsideServicesRow = (
                    <tr key="outside-services">
                        <td>Outside Services</td>
                        <td>{outsideServicesNames.join(', ')}</td>
                        <td className="text-right cost-td">{this.renderMailingTooltip(outsideServicesCost, 'Outside Services')}</td>
                        <td className="price-td">
                            $<input type="text" name="estimate_outside_services_price_1" value={formValues['estimate_outside_services_price_1']}
                                onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                        </td>
                    </tr>
                );

                totalCosts[0] += outsideServicesCost;
                totalCosts[1] += outsideServicesCost;
                totalCosts[2] += outsideServicesCost;
                if (formValues['estimate_outside_services_price_1'] != null) {
                    totalPrices[0] += parseFloat(formValues['estimate_outside_services_price_1']);
                    totalPrices[1] += parseFloat(formValues['estimate_outside_services_price_1']);
                    totalPrices[2] += parseFloat(formValues['estimate_outside_services_price_1']);
                }
            }
        }

        let discountRow = null;
        if (formValues['estimate_discount_percentage'] !== 0) {
            let discountType = 'Discount';
            if (formValues['estimate_discount_percentage'] > 0) {
                discountType = 'Markup';
            }

            discountRow = (
                <tr key="discount">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>{discountType}:</td>
                    <td className="text-right cost-td">{numeral(totalCosts[0] * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                    <td className="text-right cost-td">{numeral(totalCosts[1] * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                    <td className="text-right cost-td">{numeral(totalCosts[2] * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(totalPrices[0] * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(totalPrices[1] * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(totalPrices[2] * formValues['estimate_discount_percentage']).format('$0,0.00')}</td>
                </tr>
            );
        }

        totalCosts[0] += totalCosts[0] * formValues['estimate_discount_percentage'];
        totalCosts[1] += totalCosts[1] * formValues['estimate_discount_percentage'];
        totalCosts[2] += totalCosts[2] * formValues['estimate_discount_percentage'];
        totalPrices[0] += totalPrices[0] * formValues['estimate_discount_percentage'];
        totalPrices[1] += totalPrices[1] * formValues['estimate_discount_percentage'];
        totalPrices[2] += totalPrices[2] * formValues['estimate_discount_percentage'];

        return [
            dataProcessing1Row,
            dataProcessing2Row,
            inkjetRow,
            insertingRow,
            perfectMatchRow,
            artRow,
            handworkRow,
            indexTabRow,
            labelRow,
            ncrFormRow,
            pressureSealRows,
            outsideServicesRow,
            discountRow,
            <tr key="total">
                <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Total:</td>
                <td className="text-right cost-td">{numeral(totalCosts[0]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[0]).format('$0,0.00')}</td>
                <td className="text-right cost-td">{numeral(totalCosts[1]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[1]).format('$0,0.00')}</td>
                <td className="text-right cost-td">{numeral(totalCosts[2]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[2]).format('$0,0.00')}</td>
            </tr>
        ];
    }

    render() {
        return (
            <div className="card mb-2 mr-1 pb-2" style={{ width: '100%' }}>
                <div className="card-header">
                    Pricing
                </div>
                <div className="card-body p-1 pb-0">
                    <table id="estimate-pricing-table" className="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan="2" className="price-td text-center">Quantity 1</th>
                                <th colSpan="2" className="price-td text-center">Quantity 2</th>
                                <th colSpan="2" className="price-td text-center">Quantity 3</th>
                            </tr>
                            <tr>
                                <th>Department</th>
                                <th>Name</th>
                                <th className="cost-td">Cost</th>
                                <th className="price-td">Price</th>
                                <th className="cost-td">Cost</th>
                                <th className="price-td">Price</th>
                                <th className="cost-td">Cost</th>
                                <th className="price-td">Price</th>
                            </tr>
                            {this.renderResults()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default EstimateMailingPricing;
